import { useQuery } from 'react-query';

import { ApplicationsToScholarship } from '@sharedComponents/ScholarshipApplications.interface';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

const CURRENT_QUERY_KEY = 'applicationsToScholarshipQuery';

function useApplicationsToScholarship(
  scholarshipID: number,
  activeCategory,
  textFilter,
  applicationCycle,
  pageIndex,
  sort
) {
  const applicationClient = useApplicationClient();

  return useQuery<ApplicationsToScholarship, Error>(
    [CURRENT_QUERY_KEY, scholarshipID, activeCategory, textFilter, applicationCycle, pageIndex, sort],
    () =>
      applicationClient.getApplicationsToScholarship(
        scholarshipID,
        activeCategory,
        textFilter,
        applicationCycle,
        pageIndex,
        sort
      ),
    {
      staleTime: 1000 * 60 * 2,
      refetchOnWindowFocus: false
    }
  );
}

export default { QUERY_KEY: CURRENT_QUERY_KEY, useApplicationsToScholarship };
