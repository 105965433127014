import { useState, useEffect } from 'react';

interface ClientFetchResult<T> {
  isLoading: boolean;
  isLoaded: boolean;
  response: T | undefined;
  isError: boolean;
  error?: string | undefined;
}

/** Hook to simplify work with backend requests. Simply adds loading/error state and provides utility methods
 * @deprecated, use react query instead
 */
function useClientFetching<T>(
  appPromise: () => Promise<any>,
  delayed = false
): [
  T | undefined,
  {
    isLoading: boolean;
    isLoaded: boolean;
    isError: boolean;
    error?: string | undefined;
    reload: () => Promise<void>;
    load: () => Promise<void>;
  }
] {
  const [clientFetchResult, setClientFetchResult] = useState<ClientFetchResult<T>>({
    isLoading: delayed ? false : true,
    isLoaded: false,
    response: undefined,
    isError: false
  });

  async function fetchData() {
    try {
      const data = await appPromise();

      setClientFetchResult({
        isLoading: false,
        isLoaded: true,
        response: data,
        isError: false
      });
    } catch (err: any) {
      setClientFetchResult({
        isLoading: false,
        isLoaded: false,
        response: undefined,
        isError: true,
        error: err.message
      });
    }
  }

  useEffect(() => {
    if (!delayed) {
      fetchData();
    }
  }, [delayed]);

  const reload = async () => {
    setClientFetchResult({
      isLoading: true,
      isLoaded: false,
      response: undefined,
      isError: false
    });

    await fetchData();
  };

  return [
    clientFetchResult.response,
    {
      isLoading: clientFetchResult.isLoading,
      isLoaded: clientFetchResult.isLoaded,
      isError: clientFetchResult.isError,
      error: clientFetchResult.error,
      reload,
      load: reload
    }
  ];
}

export default useClientFetching;
