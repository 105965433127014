import React from 'react';

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui';
import FileField from '@sharedComponents/formElements/combined/FileField';
import { MailingModelSchema } from '@sharedComponents/schemas/MailingModelSchema';

// TODO use temp files
const MailingCSV = () => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          Upload a .CSV file of mailing addresses, where the letter will be sent. <br />
          For spreadsheet formatting requirements, see this{' '}
          <EuiLink target="_blank" href="/csv/MailingAddressesExample.csv">
            Addresses template
          </EuiLink>
          .
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <FileField
          fieldName={'address_file_id'}
          rowProps={{
            label: 'Adresses spreadsheet',
            helpText: '',
            fullWidth: true
          }}
          fieldProps={{
            accept: '.csv'
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

MailingCSV.validationRules = MailingModelSchema.pick(['address_file_id']);

export default MailingCSV;
