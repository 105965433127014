import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import { formatFloat } from '@sharedComponents/base/MarkdownLite';
import { ScholarshipModelShape } from '@sharedComponents/models';

export default function formatGpaRange(scholarship: ScholarshipModel | ScholarshipModelShape) {
  if (scholarship.data.gpaTo !== 4) {
    return `${formatFloat(scholarship.data.gpaFrom)} - ${formatFloat(scholarship.data.gpaTo)}`;
  } else if (scholarship.data.gpaFrom !== 0) {
    return `at least ${formatFloat(scholarship.data.gpaFrom)}`;
  } else {
    return 'Any';
  }
}
