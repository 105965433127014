import { EuiFlexGroup, EuiFlexItem, EuiEmptyPrompt, EuiLoadingLogo, EuiCallOut, EuiLink, EuiText } from '@elastic/eui';
import React, { useEffect } from 'react';
import prerenderQuery from './queries/prerenderQuery';

const PrerenderView = ({ url, callbackOnComplete }) => {
  const { isSuccess } = prerenderQuery.usePrerender(url);
  // we can actually use data for a preview here

  useEffect(() => {
    if (isSuccess) {
      callbackOnComplete();
    }
  }, [isSuccess]);

  if (isSuccess) {
    return (
      <EuiCallOut title="Your preview is ready!" color="success" iconType="image">
        <EuiText size="m">
          <EuiLink href={url} target="_blank" external>
            Visit preview URL
          </EuiLink>
        </EuiText>
      </EuiCallOut>
    );
  }

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>Your preview is now being generated by LOB. Please, be patient...</EuiFlexItem>
      <EuiFlexItem>
        <EuiEmptyPrompt icon={<EuiLoadingLogo logo={'upgradeAssistantApp'} size="xl" />} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default PrerenderView;
