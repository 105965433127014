import { parseInt } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';

import BasicModal from '@donors/components/BasicModal';
import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiForm, EuiText } from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';

import { RecurrentPaymentsConfigurationForm } from './RecurringAwardsSetup.interface';
import RecurrentNotificationEmailConfiguration from './RecurringAwardsSetup/RecurrentNotificationEmailConfiguration';
import RecurrentPaymentsConfiguration from './RecurringAwardsSetup/RecurrentPaymentsConfiguration';
import RecurrentPaymentsCSV from './RecurringAwardsSetup/RecurrentPaymentsCSV';
import RecurrentPaymentsVerification from './RecurringAwardsSetup/RecurrentPaymentsVerification';
import RecurrentPaymentsSummary from './RecurringAwardsSetup/RecurrentPaymentsSummary';
import recurrentPaymentsQuery from './RecurringAwardsSetup/queries/recurrentPaymentsQuery';

const STANDALONE_APPLICATIONS_STEPS = {
  1: RecurrentPaymentsCSV,
  2: RecurrentPaymentsVerification,
  3: RecurrentPaymentsConfiguration,
  4: RecurrentNotificationEmailConfiguration,
  5: RecurrentPaymentsSummary
};

export default function RecurringAwardsSetup({ scholarshipID }: { scholarshipID: number }) {
  const router = useRouter();
  const createModal = useModalCreate();
  const [wizardInstance, setWizardInstance] = useState<StepWizardChildProps>();
  const [currentStep, setCurrentStep] = useState(1);

  const CurrentStepComponent = STANDALONE_APPLICATIONS_STEPS[currentStep];

  const formMethods = useForm<RecurrentPaymentsConfigurationForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      headers: [],
      records: [],
      notificationEmail: {
        subject: '',
        body: ''
      }
    },
    resolver: yupResolver(CurrentStepComponent.validationRules),
    shouldUnregister: false // to collect data between steps
  });

  const { mutateAsync: submitRecurrentPaymentConfigurationAsync, isLoading } =
    recurrentPaymentsQuery.useSubmitRecurrentPaymentConfiguration();

  const returnUrl = `/scholarships/${scholarshipID}/applications`;
  const returnBackHandler = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      wizardInstance?.goToStep(currentStep - 1);
    } else {
      router.push(returnUrl);
    }
  };

  const LAST_STEP_NUMBER = parseInt(
    Object.keys(STANDALONE_APPLICATIONS_STEPS)[Object.keys(STANDALONE_APPLICATIONS_STEPS).length - 1]
  );

  const nextStepHandler = formMethods.handleSubmit(
    async entry => {
      if (currentStep !== LAST_STEP_NUMBER) {
        setCurrentStep(currentStep + 1);

        wizardInstance?.nextStep();
      } else if (currentStep === LAST_STEP_NUMBER) {
        await submitRecurrentPaymentConfigurationAsync({ scholarshipID, configuration: entry });

        createModal(({ closeModal }) => {
          const closeModalHandler = () => {
            closeModal();
            router.push(returnUrl);
          };

          return (
            <BasicModal closeModal={closeModalHandler} title={'Success'}>
              <EuiText>Recurrent payment schedule was successfully created.</EuiText>
            </BasicModal>
          );
        });
      }
    },
    e => {
      // eslint-disable-next-line no-console
      console.log('submit error:', e);
    }
  );

  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText size="s">
                Step {currentStep} out of {LAST_STEP_NUMBER}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <FormProvider {...formMethods}>
            <EuiForm component="form" onSubmit={nextStepHandler}>
              <StepWizard
                instance={instance => setWizardInstance(instance as StepWizardChildProps)}
                isHashEnabled={false}
                isLazyMount={true}
                initialStep={1}
              >
                <RecurrentPaymentsCSV />
                <RecurrentPaymentsVerification />
                <RecurrentPaymentsConfiguration />
                <RecurrentNotificationEmailConfiguration />
                <RecurrentPaymentsSummary />
              </StepWizard>
            </EuiForm>
          </FormProvider>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={returnBackHandler} iconType="arrowLeft" size="xs" isDisabled={isLoading}>
                Go Back
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>{/* Spacer */}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                color="primary"
                onClick={nextStepHandler}
                iconType="arrowRight"
                iconSide="right"
                fill={currentStep === LAST_STEP_NUMBER}
                isLoading={isLoading}
                size="s"
              >
                {currentStep === LAST_STEP_NUMBER ? 'Submit' : 'Next'}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
}
