import { EuiFieldSearch } from '@elastic/eui';
import { useEffect, useState } from 'react';

import useDebounce from '@sharedComponents/hooks/useDebounce';

/**
 * @description Abstract search input component with debounce
 */
export default function SearchInput({
  placeholder = '',
  value = '',
  isLoading,
  onValueChange
}: {
  placeholder?: string;
  value?: string;
  isLoading?: boolean;
  onValueChange: (value: string) => void;
}) {
  const [searchTerm, setSearchTerm] = useState(value);

  // Debounce search term so that it only gives us latest value if searchTerm has not been updated within some time
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 750);

  useEffect(
    () => {
      debouncedSearchTerm && onValueChange(debouncedSearchTerm);
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  // to process reset/clean instantly without debouncing
  useEffect(() => {
    if (searchTerm === '' && debouncedSearchTerm !== searchTerm) {
      onValueChange('');
    }
  }, [searchTerm]);

  return (
    <EuiFieldSearch
      placeholder={placeholder}
      value={searchTerm}
      isLoading={isLoading}
      onChange={e => setSearchTerm(e.target.value)}
      aria-autocomplete="list"
      aria-haspopup="listbox"
      name="searchterm"
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="false"
      spellCheck={false}
      maxLength={255}
    />
  );
}
