import React from 'react';
import { AreaSeries, Chart, Settings } from '@elastic/charts';
import { EUI_CHARTS_THEME_LIGHT, EUI_SPARKLINE_THEME_PARTIAL } from '@elastic/eui/dist/eui_charts_theme';
import moment from 'moment';
import { mapKeys } from 'lodash';
import { ScholarshipModelShape } from '@sharedComponents/models';
import { max } from 'lodash';
import { EuiSpacer, EuiText } from '@elastic/eui';

const getStatusMessage = (value, dateRangeFilter, action) => {
  let sincePart = '';
  switch (dateRangeFilter) {
    case 1:
      sincePart = 'yesterday';
      break;
    case 7:
      sincePart = 'last week';
      break;
    case 30:
      sincePart = 'last month';
      break;
  }

  return `${value >= 0 ? '+' : '-'} ${Math.abs(value)} ${
    Math.abs(value) === 1 ? action : `${action}s`
  } since ${sincePart}`;
};

export default function SimpleStatChart({
  action,
  data,
  dateRangeFilter,
  selectedScholarship,
  periodDifference
}: {
  action: string;
  data: { count: number; date: string }[];
  dateRangeFilter: number;
  selectedScholarship: ScholarshipModelShape | null;
  periodDifference: number;
}) {
  let chartData;

  // adding 0 values to days with no info
  if (data.length) {
    chartData = [];

    let dataMapByDays = mapKeys(data, el => moment(el.date).format('MM/DD/YYYY'));

    let dateFromMoment = moment().subtract(dateRangeFilter - 1, 'days');
    let dayIterator = moment();

    // if 'Lifetime' is selected - counting from scholarship 'createdAt'
    if (dateRangeFilter === 3650 && selectedScholarship) {
      dateFromMoment = moment(selectedScholarship.created_at);
    }

    while (dateFromMoment.isSameOrBefore(dayIterator)) {
      let dayKey = dayIterator.format('MM/DD/YYYY');

      chartData.push(dataMapByDays[dayKey] ? dataMapByDays[dayKey].count : 0);

      dayIterator.subtract(1, 'day');
    }

    chartData = chartData.reverse().map((item, index) => [index, item]);

    if (chartData.length === 1) {
      chartData = [chartData[0], [2, chartData[0][1]]]; // copy the element value in order for chart to render at least something
    }
  } else {
    // showing empty chart in case if there is no data
    chartData = [
      [0, 0],
      [1, 0]
    ];
  }

  // adding an extra buffer to the bottom of the chart
  const yAxisBuffer = max((chartData as any[]).map(el => el[1])) / 3 || 1;
  chartData = chartData.map(el => [el[0], el[1] + yAxisBuffer]);

  return (
    <>
      <Chart size={{ height: 50 }} renderer={'canvas'}>
        <Settings
          theme={[EUI_SPARKLINE_THEME_PARTIAL, EUI_CHARTS_THEME_LIGHT.theme]}
          showLegend={false}
          tooltip="none"
        />
        <AreaSeries
          id="simpleStatChart"
          data={chartData}
          xAccessor={0}
          yAccessors={[1]}
          color={['#BD271E']}
          xScaleType={'linear'}
          yScaleType={'linear'}
        />
      </Chart>
      {dateRangeFilter !== 3650 && (
        <>
          <EuiSpacer size="s" />
          <EuiText size="xs" color="danger" textAlign={'right'}>
            {getStatusMessage(periodDifference, dateRangeFilter, action)}
          </EuiText>
        </>
      )}
    </>
  );
}
