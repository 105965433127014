import { nonAwardedAwardStatuses } from '@sharedClients/validating/AwardStatusValidation';
import { default as React } from 'react';
import './Application.scss';
import { isList, isString, toList, toString } from './cast';
import { getPath } from './getPath';
import NodeProps from './FormNodeProps';
import { FormNode } from './FormNode';
import FormDataNode from '@sharedClients/types/FormDataNode';
import { isEmpty } from 'lodash';

/**
 * Pages are a grouping of sections. They should only be used on the top level and may not be nested.
 */
export default function FormPage(props: NodeProps) {
  const { node } = props;

  return isPageVisible(props.node, props.fullApplication) ? (
    <div className="page">
      {node.title ? (
        <div className="title-container">
          <h2>{toString(node.title)}</h2>
          {renderChildren(props)}
        </div>
      ) : (
        renderChildren(props)
      )}
    </div>
  ) : null;
}

function isAcceptanceForm(page: FormDataNode) {
  return page.userType === 'awarded'; // ? better "path": "awardAcceptance",
}

// duplicated in apply FormNode
function isPageVisible(page: FormDataNode, application: any) {
  const isAwarded =
    application && application.awardStatus && !nonAwardedAwardStatuses.includes(application.awardStatus);

  const isShownToAwarded = isAcceptanceForm(page);

  return !isShownToAwarded || isAwarded;
}

export function FormSection(props: NodeProps) {
  const { node } = props;

  const className = [
    'FormSection',
    node.title ? 'title-container' : '',
    // "wide" means full page width on wide resolutions
    props.node.wide ? ' wide' : ''
  ].join(' ');

  return (
    <div className={className}>
      {node.title ? <h2>{toString(node.title)}</h2> : null}
      {node.help ? <div className="help">{node.help}</div> : null}
      {node.warn ? <div className="warn">{node.warn}</div> : null}
      {renderChildren(props)}
    </div>
  );
}

export function FormList(props: NodeProps) {
  const { node, application } = props;
  const help = node.help;
  const section = getApplicationSection(application, node);
  const label = toString(node.label);

  return (
    <div className="list field title-container">
      {node.title ? <h2>{toString(node.title)}</h2> : null}
      {label ? <label>{label}</label> : null}
      {help ? <div className="help">{help}</div> : null}

      {renderList(
        toList(section),
        (listItem, i) => (
          <div className="list-item" key={i}>
            {renderChildren({
              ...props,
              // we've already evaluated the path entry, so remove it.
              node: { ...node, path: undefined },
              application: listItem
            })}
          </div>
        ),
        () => (
          <div>None</div>
        )
      )}
    </div>
  );
}

/**
 * Render the children of a node that has child nodes.
 */
function renderChildren(props: NodeProps) {
  const node = props.node;

  if (isList(node.children)) {
    return <ChildList {...props} node={node.children} application={getApplicationSection(props.application, node)} />;
  } else if (isList(node.columns)) {
    return <ChildColumns {...props} />;
  } else {
    return null;
  }
}

export function getApplicationSection(application, node) {
  if (isString(node.path) && !isEmpty(node.path)) {
    return getPath(application, toString(node.path).split('/'));
  } else {
    return application;
  }
}

function renderList(list, renderItem, noItems) {
  list = toList(list);

  return list.length ? list.map(renderItem) : noItems();
}

export function ChildList(props: any) {
  const { node, application } = props;

  return (
    <React.Fragment>
      {toList(node).map((child, i) => (
        <FormNode {...props} application={application} key={i} node={child} />
      ))}
    </React.Fragment>
  );
}

function ChildColumns(props) {
  const { node, application } = props;

  return (
    <div className={node.wide ? 'wide-columns' : 'columns'}>
      {toList(node.columns).map((column, i) => (
        <div className="column" key={i}>
          <FormNode {...props} application={getApplicationSection(application, node)} node={column} />
        </div>
      ))}
    </div>
  );
}
