import React, { useEffect, useState } from 'react';

import { EuiButton, EuiModalBody, EuiModalFooter, EuiButtonEmpty, EuiRadioGroup } from '@elastic/eui';
import { EuiRadioGroupOption } from '@elastic/eui/src/components/form/radio/radio_group';
import { isNil } from 'lodash';
import { RequestReviewStepProps } from '../../FormReviewRequestConfirmationModal';

const DonorOrganizationSelectionStep = ({
  form,
  selectedDonorId,
  setSelectedDonorId,
  closeModal,
  nextStep
}: RequestReviewStepProps) => {
  const [donorOptions, setDonorOptions] = useState<EuiRadioGroupOption[]>();

  useEffect(() => {
    if (form.changeRequest) {
      const newDonorOptions = [{ id: form.changeRequest.donor_id.toString() }];
      setDonorOptions(newDonorOptions);
      setSelectedDonorId(newDonorOptions[0].id);
    } else {
      let newDonorOptionsMap = {};

      form.scholarships!.forEach(scholarship => {
        let donorId = scholarship.donor.id.toString();

        if (!newDonorOptionsMap[donorId]) {
          newDonorOptionsMap[donorId] = {
            id: donorId,
            label: scholarship.donor.name
          };
        }
      });

      const newDonorOptions = Object.values(newDonorOptionsMap) as EuiRadioGroupOption[];

      setDonorOptions([
        ...newDonorOptions
        // , { id: '111', label: 'Test Donor 1' }, { id: '222', label: 'Test Donor 2' }
      ]);
      setSelectedDonorId(newDonorOptions[0].id);
    }
  }, [form]);

  return (
    <>
      <EuiModalBody>
        {!!donorOptions?.length &&
          (donorOptions.length === 1 ? (
            <p>
              {!form.changeRequest ? (
                <>
                  Are you sure you want to send this form to "<b>{donorOptions[0].label}</b>"
                </>
              ) : (
                <>Are you sure you want to resend this form</>
              )}
              ?
            </p>
          ) : (
            <EuiRadioGroup
              options={donorOptions}
              idSelected={selectedDonorId}
              onChange={nestedRadio => setSelectedDonorId(nestedRadio)}
              legend={{ children: 'Donor Organization' }}
            />
          ))}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton onClick={nextStep} disabled={isNil(selectedDonorId)} fill>
          Next
        </EuiButton>
      </EuiModalFooter>
    </>
  );
};

export default DonorOrganizationSelectionStep;
