export default [
  {
    type: 'section',
    title: 'Applicant',
    children: [
      { field: 'name', label: 'First name', required: true },
      { field: 'lastName', required: true },
      {
        field: 'email',
        label: 'Email',
        type: 'email',
        required: true
      },
      {
        field: 'phone',
        width: 14,
        required: true,
        type: 'phone'
      },
      {
        path: 'school',
        children: [
          {
            field: 'school',
            required: true,
            autocomplete: 'schools'
          },
          {
            field: 'state',
            type: 'state',
            width: 14,
            required: true
          }
        ]
      }
    ]
  }
];
