import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

// import { DevTool } from '@hookform/devtools';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiSpacer,
  EuiSwitch,
  EuiTabbedContent,
  EuiText
} from '@elastic/eui';
import {
  ReviewCriteriaConfiguration,
  ReviewCriteriaRoundConfiguration
} from '@sharedComponents/interfaces/Scholarships.interface';

import CriteriasList from './ReviewCriteriaModal/CriteriasList';

enum WEIGHT_TYPE {
  TALLY = 'TALLY',
  WEIGHTED = 'WEIGHTED'
}

const ReviewCriteriaModal = ({
  reviewCriteriasConfiguration,
  closeModal
}: {
  reviewCriteriasConfiguration: ReviewCriteriaConfiguration;

  closeModal: (isSave: boolean, data?: ReviewCriteriaConfiguration) => void;
}) => {
  // Tab represents one round configuration.
  const [tabConfiguration, setTabConfiguration] = useState<
    Array<{
      id: string;
      round: number;
      // scale: number;
      criterias: ReviewCriteriaRoundConfiguration;
      name: JSX.Element;
      content: JSX.Element;
    }>
  >([]);

  const [selectedTab, setSelectedTab] = useState(tabConfiguration[0] || null);

  useEffect(() => {
    // if tab configuration length changes, we always select last tab(no matter its removal or adding)
    setSelectedTab(tabConfiguration[tabConfiguration.length - 1]);
  }, [tabConfiguration]);

  // TODO:IMP yup validation
  const { control, register, handleSubmit, watch, setValue } = useForm({
    defaultValues: Object.assign(
      {},
      ...tabConfiguration
        .map(tabConfig => tabConfig.round)
        .map(round => {
          const currentRoundConfig = tabConfiguration.find(tabConfig => tabConfig.round === round);
          // const scale = Math.max(...Object.values(currentRoundConfig?.criterias || {}).map(c => c.scale || 0));

          return {
            [round]: [
              ...Object.values(currentRoundConfig?.criterias || {}).map(criteriaConfig => ({
                name: criteriaConfig.name,
                weight: criteriaConfig.weight || null,
                scale: criteriaConfig.scale || 10
              }))
            ]
          };
        })
    ),
    // * Format for values:
    // defaultValues: {
    //   [roundID]: Object.keys(roundConfiguration).map(key => ({
    //     name: roundConfiguration[key].name,
    //     weight: roundConfiguration[key].weight
    //   }))
    // },
    mode: 'onChange',
    shouldUnregister: false
  });

  const criteriaFields = watch();

  const roundTabContent = round => (
    <EuiFlexGroup direction="column" gutterSize="m" key={round} style={{ minHeight: 300 }}>
      <EuiFlexItem grow={false}>
        <EuiSpacer size="xs" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <CriteriasList round={round} control={control} formWatch={watch} register={register} />
      </EuiFlexItem>
      <EuiFlexItem> </EuiFlexItem>
    </EuiFlexGroup>
  );

  const roundIntoTabConfiguration = (roundConfig: { round: string; criterias: ReviewCriteriaRoundConfiguration }) => {
    return {
      id: `id_${roundConfig.round}`,
      round: roundConfig.round,
      name: <>Round {roundConfig.round} </>,
      criterias: roundConfig.criterias,
      content: roundTabContent(roundConfig.round)
    };
  };

  useEffect(() => {
    // if there is no scholarship yet = no configuration = we put a placeholder for first round
    const configurationToUse = reviewCriteriasConfiguration || { 1: {} };

    updateTabConfigurations([
      ...Object.keys(configurationToUse).map(roundKey =>
        roundIntoTabConfiguration({ round: roundKey, criterias: { ...configurationToUse[roundKey] } })
      )
    ]);
  }, [reviewCriteriasConfiguration]);

  const updateTabConfigurations = newTabConfiguration => {
    // update titles
    const preparedTabConfiguration = [...newTabConfiguration].map(tabConf => {
      return {
        ...tabConf,
        name: (
          <>
            Round {tabConf.round}
            {+tabConf.round === newTabConfiguration.length ? '+' : ''}
          </>
        )
      };
    });

    setTabConfiguration(preparedTabConfiguration);
  };

  const onTabClick = selectedTab => {
    setSelectedTab(selectedTab);
  };

  const handleTabRemoval = (id: string) => {
    const updatedConfiguration = [...tabConfiguration.filter(config => config.id !== id)];

    updateTabConfigurations(updatedConfiguration);
  };

  const handleAddingNewRound = () => {
    updateTabConfigurations([
      ...tabConfiguration,
      roundIntoTabConfiguration({
        round: `${tabConfiguration.length + 1}`,
        criterias: {}
      })
    ]);
  };

  // we continue our hack with weight and scale options. That value exists in each criteria, but only first criteria per round matters, we base our value on that first field only per round
  const weightTypeForSelectedRound =
    selectedTab?.round && criteriaFields && criteriaFields[selectedTab.round] && criteriaFields[selectedTab.round][0]
      ? criteriaFields[selectedTab.round][0]?.weight > 0
        ? WEIGHT_TYPE.WEIGHTED
        : WEIGHT_TYPE.TALLY
      : null;

  // forcing tab refreshing when needed
  const [tabKey, setTabKey] = useState<number>(1);
  const forceRerenderTabs = () => {
    setTabKey(tabKey + 1);
  };

  return (
    <EuiModal onClose={() => closeModal(false)} style={{ minWidth: 768 }} maxWidth={1024}>
      {/* <DevTool control={control} /> */}
      <EuiModalHeader>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <h2>Review criteria setup per round</h2>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="s">
              These criteria will describe to the reviewers, what type of information about the student should be
              considered when providing a score for their application.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSpacer />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row" alignItems="center">
              <EuiFlexItem> </EuiFlexItem>

              <EuiFlexItem grow={false}>
                {weightTypeForSelectedRound ? (
                  <EuiSwitch
                    labelProps={{}}
                    label={weightTypeForSelectedRound === WEIGHT_TYPE.TALLY ? 'Tally' : 'Weighted'}
                    checked={weightTypeForSelectedRound === WEIGHT_TYPE.WEIGHTED}
                    onChange={e => {
                      // switching weight for all round values
                      if (criteriaFields[selectedTab.round]?.length) {
                        for (let index = 0; index < criteriaFields[selectedTab.round].length; index++) {
                          setValue(`${selectedTab.round}.${index}.weight`, e.target.checked ? 10 : null);
                        }

                        forceRerenderTabs();
                      }
                    }}
                  />
                ) : null}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {tabConfiguration && tabConfiguration.length > 1 ? (
                  <EuiButton
                    color="danger"
                    iconType="minusInCircle"
                    size="s"
                    onClick={() => handleTabRemoval(tabConfiguration[tabConfiguration.length - 1].id)}
                  >
                    Remove round {tabConfiguration[tabConfiguration.length - 1].round} configuration
                  </EuiButton>
                ) : null}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton color="primary" onClick={handleAddingNewRound} size="s" fill>
                  <EuiIcon type="plusInCircle" /> Add new round configuration
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem key={tabKey}>
            <EuiTabbedContent onTabClick={onTabClick} selectedTab={selectedTab} tabs={tabConfiguration} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem>
            <EuiHorizontalRule />
            <EuiFlexGroup direction="row">
              <EuiFlexItem> </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => closeModal(false)} size="s">
                  Discard
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  color="primary"
                  onClick={handleSubmit(data => {
                    // filter removed pages from data, since we dont unregister our inputs on unmount
                    const changedData = {};
                    for (const tabConfig of tabConfiguration) {
                      if (tabConfig?.round) {
                        const round = +tabConfig.round;
                        if (data[round]?.length) {
                          changedData[round] = [
                            ...data[round].map(criteriaConfig => ({
                              name: criteriaConfig.name,
                              weight: criteriaConfig.weight,
                              scale: criteriaConfig.scale
                            }))
                          ];
                        }
                      }
                    }

                    closeModal(true, changedData);
                  })}
                  size="s"
                  fill
                >
                  Save
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default ReviewCriteriaModal;
