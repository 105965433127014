import './ScholarshipEntryForm.scss';

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm } from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DraftScholarshipModelShape } from '@sharedComponents/models';
import useModal from '@sharedComponents/hooks/useModal';

import AbstractEntryStep from './ScholarshipEntryFormSteps/AbstractEntryStep';
import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function ScholarshipEntryForm({
  applicationClient,
  scholarshipClient,
  currentStepObject,
  defaultValues,
  isLoading,
  onSave,
  handleDelete,
  handlePublication,
  prevStepifAvailable,
  nextStepifAvailable,
  currentStep
}: {
  applicationClient: ApplicationClient;
  scholarshipClient;
  currentStepObject: AbstractEntryStep;
  currentStep: SCHOLARSHIP_ENTRY_STEPS;
  defaultValues: Partial<DraftScholarshipModelShape>;
  isLoading: boolean;
  onSave: (validatedStepValues: any) => Promise<boolean>;
  handleDelete: () => Promise<void>;
  handlePublication: () => Promise<void>;
  prevStepifAvailable: (() => void) | false;
  nextStepifAvailable: (() => void) | false;
}) {
  const formMethods = useForm<DraftScholarshipModelShape>({
    defaultValues: { ...JSON.parse(JSON.stringify(defaultValues)) }, // to avoid TS issue with deep&infinite instantiation
    reValidateMode: 'onChange',
    resolver: yupResolver(currentStepObject.validationSchema),
    shouldUnregister: false // in order to have dirty status not changed on wizard step changes
  });

  // making sure formState is read before render to enable the Proxy
  const isDirty = formMethods.formState.isDirty;

  // reset default values in useForm if property changes, since its cached
  React.useEffect(() => {
    // TODO: when empty object, form is not being reset
    formMethods.reset({ ...defaultValues } as any /** Type instantiation is excessively deep and possibly infinite */);
  }, [defaultValues]);

  // ? move them away from this component
  const isDeleted = !!formMethods.watch('deleted_at');

  const [removalModal, openRemovalModal] = useModal({
    confirmButtonText: isDeleted ? 'Restore' : 'Delete',
    title: `${isDeleted ? 'Restore' : 'Delete'} "${defaultValues.name}" scholarship?`,
    onConfirm: handleDelete,
    modalProps: {
      buttonColor: isDeleted ? 'success' : 'danger'
    }
  });

  const controls =
    currentStep === SCHOLARSHIP_ENTRY_STEPS.PUBLISH ? (
      <>
        <EuiFlexItem>
          <EuiButton
            isDisabled={!prevStepifAvailable}
            isLoading={isLoading}
            onClick={() => (prevStepifAvailable ? prevStepifAvailable() : null)}
          >
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton color={isDeleted ? 'success' : 'danger'} isLoading={isLoading} onClick={openRemovalModal}>
            {isDeleted ? 'Restore' : 'Delete'}
          </EuiButton>
          {removalModal}
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton color="primary" isLoading={isLoading} onClick={handlePublication} fill>
            Publish
          </EuiButton>
        </EuiFlexItem>
      </>
    ) : (
      <>
        <EuiFlexItem>
          <EuiButton
            isDisabled={!prevStepifAvailable}
            isLoading={isLoading}
            onClick={
              isDirty
                ? formMethods.handleSubmit(async validatedStepData => {
                    if (!prevStepifAvailable) {
                      return;
                    }

                    await onSave(validatedStepData);
                    await prevStepifAvailable();
                  })
                : async () => (prevStepifAvailable ? await prevStepifAvailable() : () => {})
            }
          >
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton
            isDisabled={!isDirty}
            isLoading={isLoading}
            onClick={formMethods.handleSubmit(
              // TODO:IMP: DRY
              async validatedStepData => {
                await onSave(validatedStepData);
              },
              async () => {
                // eslint-disable-next-line no-console
                console.log(formMethods.errors);
              }
            )}
            fill
          >
            Save
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton
            isDisabled={!nextStepifAvailable}
            isLoading={isLoading}
            onClick={formMethods.handleSubmit(async validatedStepData => {
              // TODO:IMP: DRY
              if (!nextStepifAvailable) {
                return;
              }

              if (isDirty) {
                await onSave(validatedStepData);
              }

              await nextStepifAvailable();
            })}
          >
            Next
          </EuiButton>
        </EuiFlexItem>
      </>
    );

  return (
    <EuiFlexGroup className="scholarshipEntryForm" direction="column" alignItems="center" gutterSize="m">
      <EuiFlexItem className="scholarshipEntryForm-wrapper">
        <FormProvider {...formMethods}>
          <EuiForm>
            {currentStepObject.render(
              { fullWidth: true, display: 'columnCompressed' },
              formMethods,
              scholarshipClient,
              applicationClient
            )}
          </EuiForm>
        </FormProvider>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">{controls}</EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
