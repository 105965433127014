export interface RecurrentPaymentsVerificationResult {
  isSuccess: boolean;
  recordsCount: number;
  headers: string[];
  records: Record<string, string>[];
}

export interface RecurrentPaymentsConfigurationForm {
  file_id: number;
  headers: string[];
  records: Record<'name' | 'last_name' | 'name' | 'email' | string | 'isRegistered', string>[];
  disbursementsSchedule: Array<{
    date: Date;
    amount: number;
    form: {
      id: number;
      name: string;
    };
  }>;
  notificationEmail: {
    subject: string;
    body: string;
  };
}

export const DeadlineMarkupReplaceToken = '{:deadlineDate}';
