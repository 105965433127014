import * as Sentry from '@sentry/react';

let listeners = [];

export default function warn(err) {
  if (listeners.length) {
    listeners.forEach(listener => listener(err));
  } else {
    if (!err.isUserError) {
      Sentry.withScope(scope => {
        if (err.fingerprint) {
          scope.setFingerprint(err.fingerprint);
        }

        Sentry.captureException(err);
      });
    }
  }
}

export function addListener(messageListener) {
  listeners.push(messageListener);
}

export function clearListeners() {
  listeners = [];
}
