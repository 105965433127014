import QuestionModel from '@sharedClients/types/QuestionModel';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import { History } from 'history';
import React from 'react';
import formatDate from '@sharedComponents/utils/formatDate';
import './QuestionList.scss';
import UserModel from '@sharedContract/UserModel';
import preventDefault from '@donors/base/preventDefault';
import { EuiButton } from '@elastic/eui';

export default function QuestionList({
  questions,
  history,
  onDelete,
  onAnswer,
  onSend,
  questionsBeingSent,
  user
}: {
  questions: QuestionModel[];
  history: History;
  onAnswer: (q: QuestionModel) => void;
  onSend: (q: QuestionModel) => void;
  questionsBeingSent: number[];
  onDelete: (q: QuestionModel) => Promise<void>;
  user: UserModel;
}) {
  function openScholarship(scholarship: ScholarshipModel) {
    history.push(`/scholarships/${scholarship.id}`);
  }

  function openUser(user: UserModel) {
    history.push(`/users/${user.id}`);
  }

  const isAdmin = user.type === 'admin';

  return (
    <div className="QuestionList">
      <div className="question header">
        <div className="text">Question</div>
        <div className="scholarship">Scholarship</div>
        <div className="delete" />
      </div>

      {questions.map(question => (
        <div key={question.id} className={'question ' + (question.status !== 'answered' ? ' unanswered' : '')}>
          <div className="text">
            <div className="q">{question.text}</div>
            <form onSubmit={preventDefault(() => openUser(question.user))}>
              <button className="button link">
                {question.user.data.name || question.user.name}
                <span className="date">{formatDate(question.createdAt)}</span>
              </button>
            </form>

            {question.answer ? (
              <div>
                <div className="a">
                  {question.answer}{' '}
                  <button onClick={preventDefault(() => onAnswer(question))} className="button link">
                    Edit
                  </button>
                  <form onSubmit={preventDefault(() => isAdmin && openUser(question.answeredBy as UserModel))}>
                    {question.answeredBy ? (
                      <button className="button link">
                        {question.answeredBy.data.name || question.answeredBy.name}
                        {question.answeredAt ? <span className="date">{formatDate(question.answeredAt)}</span> : null}
                      </button>
                    ) : null}
                  </form>
                </div>
              </div>
            ) : (
              <div className="options-container">
                <EuiButton color="primary" onClick={preventDefault(() => onAnswer(question))} fill>
                  Answer
                </EuiButton>
                {isAdmin && (question.status === 'new' || (question.isSent && question.status !== 'declined')) && (
                  <EuiButton
                    onClick={preventDefault(() => onSend(question))}
                    isLoading={questionsBeingSent.includes(question.id)}
                  >
                    {question.isSent ? 'Resend' : 'Send to Donor'}
                  </EuiButton>
                )}
              </div>
            )}
          </div>
          <a
            href={`/scholarships/${question.scholarship.id}`}
            className="scholarship"
            onClick={preventDefault(() => openScholarship(question.scholarship))}
          >
            {question.scholarship.name}
          </a>
          <div className="delete" onClick={() => onDelete(question)}>
            <svg viewBox="0 0 100 125">
              <rect x="36.1" y="11.8" width="27.8" height="6" />
              <path d="M13.6,29.4h8l5,56a3,3,0,0,0,3,2.8H70.4a3,3,0,0,0,3-2.8l5-56h8v-6H13.6Z" />
            </svg>
          </div>
        </div>
      ))}

      {questions.length === 0 ? (
        <div className="question">
          <div className="text">Any questions that students ask about your scholarships will be displayed here.</div>
        </div>
      ) : (
        []
      )}
    </div>
  );
}
