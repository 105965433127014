import React from 'react';
import FormFieldSelect from './FormFieldSelect';
import { FormNodeProps } from '../FormNodeProps';
import { toString } from '../../model/cast';
import FormFieldLabel from './FormFieldLabel';

// * Dont mind that its a new field, that's a quite messy implementation just as it is and all those formfields would be refactored at some point (or not xD)
// Represents dropdown or radio button control which stores prequalifying
export default function FormScreeningField(props: FormNodeProps) {
  const { node, onError } = props;

  // Screening field has custom format for options: "text:weight,text:weight,..."
  let options;
  try {
    options = toString(node.options, onError)
      .split(/([^:]+:[\d.]+)[,]?/gm) // TODO:IMP:DRY: this is repeating from upsertApplicationForScholarship
      .map(s => s.split(':')[0])
      .filter(str => str.length > 0)
      .map(s => s.trim()); // transform into common for fieldselect options and pass into select
  } catch (e: any) {
    onError(e);
    return null;
  }

  // at this point this field could be rendered as FormFieldSelect or as dropdown?
  return (
    <FormFieldLabel {...props}>
      <FormFieldSelect {...props} customOptions={options} />
    </FormFieldLabel>
  );
}
