import './BackgroundFileUploader.scss';

import { isEmpty, some } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import fileIcon from '@apply/assets/icons/file.svg';
import {
  EuiButtonEmpty,
  EuiEmptyPrompt,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiPanel,
  EuiText
} from '@elastic/eui';
import track from '@sharedComponents/base/track';
import ImageRenderer from '@sharedComponents/common/ImageRenderer/ImageRenderer';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import useLoading from '@sharedComponents/hooks/useLoading';

// ? unify interface for different cases?
export type BackgroundFileUploaderProps = {
  onChange: (value: number | number[] | null) => void;
  accept?: string;
  forcedMimeType?: string;

  name?: string;
  value?: number | number[] | null;
  publicUpload?: boolean;
  label?: string;
  disabled?: boolean;
  multiple?: boolean; // can be computed from maxFiles?

  donorId?: number | null;
  applicantId?: number | null;
  maxFiles?: number;

  /** Works for single mode only */
  isTempFile?: boolean;
  validUntil?: Date; // to be moved into tempFileOptions
  tempFileOptions?: {
    restrictImageHeight?: number;
  };
};

const mime = require('mime-types');

/**
 * component for uploading files in a background
 * @TODO some refactoring will be needed since too much options inside
 */
export default function BackgroundFileUploader({
  accept,
  forcedMimeType,
  name,
  value,
  onChange,
  publicUpload,
  label,
  disabled,
  multiple,
  maxFiles,
  donorId = null,
  applicantId = null,
  isTempFile = false,
  validUntil,
  tempFileOptions = {}
}: BackgroundFileUploaderProps) {
  const applicationClient = useApplicationClient();
  const [isUploading, setUploading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [filesInfo, setFilesInfo] = useState<{ name: string; mimeType: string; url: string }[]>([]);
  const loadingState = useLoading(!isEmpty(value)); // only for loading value

  if (!label) {
    label = `Select or drag and drop ${multiple ? 'files' : 'a file'}`;
  }

  async function upload(files) {
    if (!files?.length) {
      return;
    }

    try {
      if (maxFiles && files.length > maxFiles) {
        throw new Error(`Maximum number of files is ${maxFiles}`);
      }

      if (
        forcedMimeType &&
        some(files, file => {
          return file.type !== mime.lookup(forcedMimeType);
        })
      ) {
        throw new Error(`File format is unsupported`);
      }

      setUploading(true);

      const uploadedFileIds: number[] = [];

      if (multiple) {
        const createdFilesInfo = await applicationClient.createFiles(files, {
          donorId,
          applicantId,
          isPublic: !!publicUpload
        });
        uploadedFileIds.push(...createdFilesInfo.map(item => item.id));

        track('application-uploadedFiles');
      } else {
        const { id } = isTempFile
          ? await applicationClient.createTempFile(
              files[0],
              moment(validUntil).add(1, 'month').toDate().toISOString(),
              tempFileOptions
            )
          : await applicationClient.createFile(files[0], {
              donorId,
              applicantId,
              isPublic: !!publicUpload
            });

        uploadedFileIds.push(id);

        track('application-uploadedFile');
      }

      onChange(multiple ? uploadedFileIds : uploadedFileIds[0]);
      setError(null);
    } catch (e: any) {
      setError(e);
    } finally {
      setUploading(false);
    }
  }

  const onChangeHandler = async files => {
    setError(null);

    if (files.length) {
      upload(files);
    } else {
      onChange(multiple ? [] : null);
    }
  };

  useEffect(() => {
    const getFilesInfo = async v => {
      const info: any = [];
      if (multiple) {
        for (const id of v) {
          info.push(await applicationClient.getFileUrl(id));
        }
      } else {
        if (isTempFile) {
          info.push(await applicationClient.getFileUrl(v, undefined, false, true));
        } else {
          info.push(await applicationClient.getFileUrl(v));
        }
      }

      loadingState.stopLoading();
      setFilesInfo(info);
    };

    if (multiple ? Array.isArray(value) && value.length : value) {
      loadingState.startLoading();
      getFilesInfo(value);
    } else {
      setFilesInfo([]);
    }
  }, [value]);

  const onRemoveHandler = () => {
    onChangeHandler([]);

    // ? delete from backend?
  };

  return (
    <div className="BackgroundFileUploader">
      <div className="uploadContent">
        {filesInfo && filesInfo.length ? (
          <EuiPanel borderRadius="m" hasBorder={true} hasShadow={false} paddingSize={'l'}>
            <EuiFlexGroup responsive={false} direction={'row'} justifyContent="spaceAround" gutterSize={'xs'}>
              {!multiple && /image\//.test(filesInfo[0].mimeType) && (
                <EuiFlexItem grow={false}>
                  <EuiPanel borderRadius="m" hasBorder={true} hasShadow={false} paddingSize={'none'}>
                    <ImageRenderer
                      block={'100px'}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      fileId={value! as number}
                      applicationClient={applicationClient}
                      paddingSize={'none'}
                      fileUrl={!publicUpload ? filesInfo[0].url : undefined} // send url only if file is not public
                    />
                  </EuiPanel>
                </EuiFlexItem>
              )}
              <EuiFlexGroup
                responsive={false}
                alignItems={'center'}
                direction={'column'}
                justifyContent="center"
                gutterSize={'xs'}
                className={'fileInfo'}
              >
                <EuiFlexItem grow={false}>
                  {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                  <EuiFlexGroup
                    responsive={false}
                    alignItems={'center'}
                    direction={'column'}
                    justifyContent="center"
                    gutterSize={'xs'}
                  >
                    <EuiFlexItem>
                      <EuiIcon type={fileIcon} size={'xl'} color="success" />
                    </EuiFlexItem>
                    {filesInfo.map((fileInfo, fiIndex) => {
                      return (
                        !!fileInfo.name && (
                          <EuiFlexItem className={'fileName'} key={fileInfo.name + fiIndex}>
                            <EuiLink
                              color={'text'}
                              type={'button'}
                              onClick={() => applicationClient.downloadFile(multiple && value ? value[fiIndex] : value)}
                            >
                              <EuiText size={'s'} color="success">
                                <strong>{fileInfo.name}</strong>
                              </EuiText>
                            </EuiLink>
                          </EuiFlexItem>
                        )
                      );
                    })}
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty size={'s'} onClick={onRemoveHandler} isDisabled={disabled}>
                    Remove
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexGroup>
          </EuiPanel>
        ) : loadingState.isLoading ? (
          <EuiEmptyPrompt icon={<EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />} />
        ) : (
          <EuiFilePicker
            fullWidth
            onChange={onChangeHandler}
            accept={accept}
            name={name}
            isLoading={isUploading}
            isInvalid={!!error}
            initialPromptText={label}
            disabled={disabled}
            multiple={multiple}
          />
        )}
      </div>
      {!!error && (
        <EuiText color="danger" size="s">
          {error.message || 'Something went wrong.'}
        </EuiText>
      )}
    </div>
  );
}
