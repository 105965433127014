// import { USER_TYPE } from '@sharedComponents/constants';
// import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { ScholarshipListingFilterType } from '@sharedComponents/schemas/ScholarshipListingFilter.schema';

import scholarshipsListingQuery from '../queries/scholarshipsListingQuery';

export default function useScholarshipListing(filter: ScholarshipListingFilterType, page: number, pageSize: number) {
  // const user = useLoggedInUser()!;

  // if (user.type === USER_TYPE.DONOR || user.type === USER_TYPE.REVIEWER) {
  //   // Todo those are only browsing own organization scholarships
  //   return ?
  // }

  return scholarshipsListingQuery.useScholarshipsListing(filter, page, pageSize);
}
