import { useState } from 'react';

import { EuiButton, EuiContextMenuItem, EuiContextMenuPanel, EuiPopover } from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';

const StandaloneApplicationButton = ({ scholarshipId }) => {
  const router = useRouter();
  const [isPopoverOpen, setPopover] = useState(false);

  const onCreateStandaloneApplicationClick = () => {
    closePopover();

    router.push('/standalone-application/' + scholarshipId);
  };

  const onMassStandaloneApplicationClick = () => {
    closePopover();

    router.push('/standalone-applications/' + scholarshipId);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const items = [
    <EuiContextMenuItem key="edit" icon="pencil" onClick={onCreateStandaloneApplicationClick}>
      Create single application
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="share" icon="share" onClick={onMassStandaloneApplicationClick}>
      Upload applications CSV
    </EuiContextMenuItem>
  ];

  const onButtonClick = () => {
    setPopover(true);
  };

  return (
    <EuiPopover
      button={
        <EuiButton
          color="text"
          size="s"
          iconSide="right"
          iconType="boxesVertical"
          aria-label="More"
          onClick={onButtonClick}
        >
          Standalone applications
        </EuiButton>
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};

export default StandaloneApplicationButton;
