/*global Cypress*/
const CUSTOM_DOMAIN_PROJECT_IDS = ['scholarsapp-prod', 'scholarsapp-stage'];
const PROJECT_IDS_ON_FIREBASE = ['scholarsapp-feature', 'scholarsapp-stage'];

const STAGE_PROJECT_ID = 'scholarsapp-stage';
const PROD_PROJECT_ID = 'scholarsapp-prod';
const LOCAL_PROJECT_ID = 'scholarsapp-localhost';

const FRONTEND_SERVICES = ['apply', 'donors'];
const FIREBASE_DEFAULT_SERVICE = 'apply';

const APP_ENGINE_DOMAIN = '.appspot.com';
const PROD_DOMAIN = '.scholarsapp.com';
const STAGE_DOMAIN = '.stage.scholarsapp.com';
const FIREBASE_DOMAIN = '.web.app';

export function isProd() {
  return !isInSystemTests() && getProjectId() === 'scholarsapp-prod';
}

export function getHostedUrl(service, projectId) {
  if (
    FRONTEND_SERVICES.includes(service) &&
    (CUSTOM_DOMAIN_PROJECT_IDS.includes(projectId) || PROJECT_IDS_ON_FIREBASE.includes(projectId))
  ) {
    if (CUSTOM_DOMAIN_PROJECT_IDS.includes(projectId)) {
      return getCustomDomain(service, projectId);
    } else {
      return getFirebaseDomain(service, projectId);
    }
  } else if (service === 'students') {
    return `https://${projectId}${APP_ENGINE_DOMAIN}`;
  } else {
    return `https://${service}-dot-${projectId}${APP_ENGINE_DOMAIN}`;
  }
}

function getCustomDomain(service, projectId) {
  if (STAGE_PROJECT_ID === projectId) {
    return `https://${service}${STAGE_DOMAIN}`;
  } else if (PROD_PROJECT_ID === projectId) {
    return `https://${service}${PROD_DOMAIN}`;
  } else {
    throw new Error(`No custom domain for project ${projectId}`);
  }
}

function getFirebaseDomain(service, projectId) {
  let suffix = '';

  if (service !== FIREBASE_DEFAULT_SERVICE) {
    suffix = '-' + service;
  }

  return `https://${projectId + suffix}${FIREBASE_DOMAIN}`;
}

function getProjectIdFromQueryParameter() {
  if (typeof document !== 'undefined') {
    const urlParams = new URLSearchParams(document.location.search);

    return urlParams.get('project');
  }
}

function getEnvVar(variable) {
  // process env will always exist since create-react-app generates one
  // typeof process is unfortunately 'undefined', so we can't check that.
  return process.env[variable];
}

function getProjectEnvVariableInReact() {
  // when starting the service, we copy value of the environment variable GOOGLE_CLOUD_PROJECT into REACT_APP_PROJECT
  // (in the start script in package.json)
  // create-react-app script exposes all environment variables starting with REACT_APP_ so we can read it.
  // https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
  // to set the project locally, therefore set GOOGLE_CLOUD_PROJECT
  return getEnvVar('REACT_APP_PROJECT');
}

function getProjectEnvVariableInNode() {
  // to set the project locally, set this variable. on App Engine it's set as part of the node environment:
  // https://cloud.google.com/appengine/docs/standard/nodejs/runtime#environment_variables
  return getEnvVar('GOOGLE_CLOUD_PROJECT');
}

/**
 * Used when running on Node on App Engine or locally, or when running on React scripts locally.
 */
function getProjectIdFromEnv() {
  return getProjectEnvVariableInReact() || getProjectEnvVariableInNode();
}

/**
 * Used when running a React project on App Engine.
 * Since we are just static assets we don't have access to any environment.
 * If we introduce custom URLs later this will obviously fail.
 */
function getProjectIdFromHostedUrl() {
  if (typeof document != 'undefined') {
    const hostname = document.location.hostname;

    return getProjectIdFromHostname(hostname);
  }
}

export function getProjectIdFromHostname(hostname) {
  if (hostname.endsWith(APP_ENGINE_DOMAIN)) {
    const hostnameWithoutService = hostname.replace(/^.*-dot-/, '');

    return hostnameWithoutService.replace(APP_ENGINE_DOMAIN, '');
  }

  if (hostname.endsWith(STAGE_DOMAIN)) {
    return STAGE_PROJECT_ID;
  } else if (hostname.endsWith(PROD_DOMAIN) || '.' + hostname === PROD_DOMAIN) {
    return PROD_PROJECT_ID;
  }

  if (hostname.endsWith(FIREBASE_DOMAIN)) {
    const prefix = hostname.replace(FIREBASE_DOMAIN, '');

    const components = prefix.split('-');

    if (FRONTEND_SERVICES.includes(components.pop())) {
      return components.slice(0, components.length).join('-');
    } else {
      return prefix;
    }
  }
}

function getProjectIdFromFirebaseUrl() {
  if (typeof document != 'undefined') {
    const hostname = document.location.hostname;

    const FIREBASE_DOMAIN = '.web.app';

    if (hostname.endsWith(FIREBASE_DOMAIN)) {
      return hostname.replace(FIREBASE_DOMAIN, '');
    }
  }
}

function getProjectIdInCypress() {
  if (typeof Cypress != 'undefined') {
    return getProjectIdInSystemTest();
  }
}

function calculateProjectId() {
  return [
    getProjectIdFromQueryParameter,
    getProjectIdFromEnv,
    getProjectIdFromHostedUrl,
    getProjectIdFromFirebaseUrl,
    getProjectIdInCypress
  ]
    .map(calculator => calculator())
    .find(projectId => !!projectId);
}

const getProjectId = (() => {
  let projectId = null;

  return () => {
    if (!projectId) {
      projectId = calculateProjectId();
    }

    return projectId;
  };
})();

export function getServiceUrl(service, projectId) {
  if (isInSystemTests()) {
    return getSystemTestUrl(service);
  } else if (isDevelopingOffline() && !projectId) {
    return getLocalServiceUrl(service);
  } else {
    return getHostedServiceUrl(service, projectId);
  }
}

function isDevelopingOffline() {
  // same logic as for GOOGLE_CLOUD_PROJECT (above): either we're in node and we can access the actual
  // environment variable DEV_OFFLINE or we're in React, in which case we copy that variable into something
  // prefixed by REACT_
  return parseBoolean(
    // getEnvVar does not work with parcel, so we must access DEV_OFFLINE in an explicit way
    process.env.DEV_OFFLINE || getEnvVar('REACT_APP_OFFLINE') || isInUnitTests()
  );
}
// function isDevelopingOfflineRemote() {

//   return parseBoolean(
//     process.env.DEV_REMOTE_OFFLINE || getEnvVar('DEV_REMOTE_OFFLINE')
//   );

// }
function isInUnitTests() {
  return getEnvVar('NODE_ENV') === 'test';
}

function getLocalServiceUrl(service) {
  const port = {
    applications: 3100,
    apply: 3300,
    donors: 3000,
    emailecho: 3400,
    students: 3500
  }[service];

  if (port) {
    return `http://localhost:${port}`;
  } else {
    throw new Error(`Unknown service "${service}"`);
  }
}

function isInSystemTests() {
  return typeof Cypress !== 'undefined';
}

function getSystemTestUrl(service) {
  if (isSystemTestOffline()) {
    return getLocalServiceUrl(service);
  } else {
    return getHostedUrl(service, getProjectIdInSystemTest());
  }
}

function getHostedServiceUrl(service, projectId) {
  projectId = projectId || getProjectId();

  if (projectId) {
    const serviceUrl = getHostedUrl(service, projectId);

    return serviceUrl;
  } else {
    throw new Error(
      `Could not determine project ID. If you are running locally, set the environment variable GOOGLE_CLOUD_PROJECT ` +
        `or add project=<PRODUCT_ID> to the URL. If you want to use a local service, set DEV_OFFLINE=true. ` +
        `React variable: "${getProjectEnvVariableInReact()}", App engine variable: "${getProjectEnvVariableInNode()}"`
    );
  }
}

function isSystemTestOffline() {
  const offlineFlag = Cypress ? Cypress.env('DEV_OFFLINE') : process.env.DEV_OFFLINE;

  return offlineFlag && offlineFlag !== 'false';
}

function getProjectIdInSystemTest() {
  const projectId = branchToProjectId(Cypress.env('BRANCH_NAME'));

  if (!projectId) {
    if (isSystemTestOffline()) {
      return LOCAL_PROJECT_ID;
    } else {
      throw new Error('Could not determine project ID. Set the environment variable GOOGLE_CLOUD_PROJECT.');
    }
  }

  return projectId;
}

// this logic must match the one in scripts/branch_to_project_id.py
function branchToProjectId(branch) {
  if (branch === 'release') {
    return PROD_PROJECT_ID;
  } else if (branch && branch[0] === '_') {
    return STAGE_PROJECT_ID;
  } else {
    return branch;
  }
}

function parseBoolean(str) {
  return str && str !== 'false';
}

export function getUserTypeFromCustomDomain(url) {
  const subdomain = new URL(url).hostname.split('.')[0];

  if (subdomain === 'recommenders') {
    return 'recommender';
  } else if (subdomain === 'counselors') {
    return 'counselor';
  } else if (subdomain === 'donors') {
    return 'donor';
  } else if (subdomain === 'apply') {
    return 'applicant';
  } else {
    throw new Error(`Unknown URL ${url}`);
  }
}

export function getUserTypeFromUrl(url, projectId) {
  projectId = projectId || getProjectId();

  if (url.startsWith(getServiceUrl('donors', projectId))) {
    return 'donor';
  } else {
    const urlParams = new URLSearchParams(new URL(url).search);

    const userType = urlParams.get('usertype');

    if (userType) {
      return userType;
    } else {
      if (CUSTOM_DOMAIN_PROJECT_IDS.includes(projectId)) {
        return getUserTypeFromCustomDomain(url);
      } else {
        return 'applicant'; // FYI can also be a parent or professional at this point
      }
    }
  }
}

function getUserHomeUrlFromCustomDomain(userType, domain, projectId) {
  if (userType === 'recommender') {
    return 'https://recommenders' + domain;
  } else if (userType === 'counselor') {
    return 'https://counselors' + domain;
  } else if (['donor', 'admin', 'reviewer', 'dataEntry'].includes(userType)) {
    return getHostedUrl('donors', projectId);
  } else if (userType === 'applicant' || userType === 'parent' || userType === 'professional') {
    return 'https://apply' + domain;
  } else {
    throw new Error(`Unknown user type "${userType}"`);
  }
}

export function getUserHomeUrl(userType, options) {
  options = options || {};
  let { path, query, projectId, hash } = options;

  if (!isInUnitTests()) {
    projectId = projectId || getProjectId();
  }

  let result;

  if (STAGE_PROJECT_ID === projectId) {
    result = getUserHomeUrlFromCustomDomain(userType, STAGE_DOMAIN, projectId);
  } else if (PROD_PROJECT_ID === projectId) {
    result = getUserHomeUrlFromCustomDomain(userType, PROD_DOMAIN, projectId);
  } else {
    if (userType === 'applicant' || userType === 'parent' || userType === 'professional') {
      result = getServiceUrl('apply', options.projectId) + '?usertype=' + userType;
    } else if (['donor', 'admin', 'reviewer', 'dataEntry'].includes(userType)) {
      result = getServiceUrl('donors', options.projectId);
    } else if (userType === 'recommender') {
      result = getServiceUrl('apply', options.projectId) + '?usertype=recommender';
    } else if (userType === 'counselor') {
      result = getServiceUrl('apply', options.projectId) + '?usertype=counselor';
    } else {
      throw new Error(`Unknown user type[2] "${userType}"`);
    }
  }

  if (path || query) {
    const url = new URL(result);

    if (path) {
      url.pathname = path;
    }

    if (query) {
      if (url.search) {
        url.search = url.search + '&' + query;
      } else {
        url.search = query;
      }
    }

    result = url.toString();
  }

  return result + (hash ? `#${hash}` : '');
}
