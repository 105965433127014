import { TranscriptsModelShape } from '@backend/models/TranscriptsModel';
import {
  DetailedReviewRoundResponse,
  ScholarshipApplicationsDocuments
} from '@sharedComponents/interfaces/Applications.interface';
import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import {
  Invitation,
  InvitationConfirmation,
  InvitationRelationship,
  VerifiedInvitation
} from '@sharedComponents/interfaces/Invitation.interface';
import {
  ApplicationModelShape,
  CounselorNotificationStatusModelShape,
  QuestionModelShape,
  RecurringAwardModelShape,
  ReviewModelShape,
  ScholarshipModelShape,
  SupportModelShape,
  UserModelShape,
  VettedModelShape
} from '@sharedComponents/models';
import { TranscriptSubmitModalSchemaType } from '@sharedComponents/schemas/TranscriptModelSchema';
import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';

import UserModel from '../contract/UserModel';
import { getServiceUrl } from './getServiceUrl';
import { handleError, handleResult } from './handleResult.js';
import ApplicationModel, {
  AwardStatus,
  CounselorNotificationStatus,
  RecommenderNotificationStatus
} from './types/ApplicationModel';
import EligibilityModel from './types/EligibilityModel';
import FaxModel, { FaxReceiveModel } from './types/FaxModel';
import ReviewModel, { ReviewDataModel } from './types/ReviewModel';
import SupportModel from './types/SupportModel';
import { getStaticHeaders, wrapToAddReferer } from './UserClient';

// TODO move into interfaces
interface ApplicationStatus {
  scholarship: {
    name: string;
    description: string;
    id: number;
    deadline: Date | string;
    awardAmountFrom: number;
    awardAmountTo: number;
    applyUrl: string;
  };
  application?: {
    id: number;
    submittedAt: Date | string;
  };
  date: Date | string;
}

// works in the browser, not on node
const browserFormDataFactory = (value, multiple = false) => {
  const formData = new FormData();
  if (multiple) {
    if (value.length) {
      for (let i = 0; i < value.length; i++) {
        formData.append('files', value[i]);
      }
    }
  } else {
    formData.append('file', value);
  }

  return formData;
};

// TODO: find where those are used and remove!!
export const filterCandidates = 'candidates';
export const filterSubmitted = 'submitted';
export const filterEliminated = 'eliminated';
export const filterLeads = 'leads';
export const filterReviewed = 'reviewed';
export const filterUnreviewed = 'unreviewed';
export const filterAwarded = 'awarded';

export class ApplicationClient {
  fetch;
  baseUrl;
  jwt;
  formDataFactory;

  /**
   * @param fetch The fetch method. Should be window.fetch in the browser or node-fetch on node.
   */
  constructor(fetch, baseUrl, jwt: string | null = null, formDataFactory = null) {
    this.fetch = wrapToAddReferer(fetch);

    this.baseUrl = baseUrl || getServiceUrl('applications');
    this.jwt = jwt;
    // abstracts away the differences in FormData between
    // frontend and backend
    // ? what is that?
    this.formDataFactory = formDataFactory || browserFormDataFactory;
  }

  async getApplications(fields?: string[], filter?: string): Promise<ApplicationModel[]> {
    const res = await this.fetch(
      addQuery(this.baseUrl + '/v1/applications', {
        fields: fields ? fields.join(',') : null,
        filter
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting applications');
  }
  async generatePdf(payload: { content: string }) {
    try {
      const res = await this.fetch(this.baseUrl + '/v1/generatePdf', {
        method: 'POST',
        body: JSON.stringify(payload),
        ...this.getHeaders()
      });
      return res;
      // return handleResult(res, 'generating your pdf');
    } catch (error) {
      return null;
    }
  }

  async getMyApplications(fields?: string[]): Promise<ApplicationModelShape[]> {
    const res = await this.fetch(
      addQuery(this.baseUrl + '/v2/applications/mine', {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting your applications');
  }

  async getApplicationsOfUser(applicantId: number, fields: string[]): Promise<ApplicationModel[]> {
    const res = await this.fetch(
      addQuery(this.baseUrl + `/v1/applications/of/${applicantId}`, {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting your applications');
  }

  /** @deprecated use getStudentDetails */
  async getApplicationStatus(applicantId: number): Promise<ApplicationStatus[]> {
    const res = await this.fetch(`${this.baseUrl}/v1/application-status/${applicantId}`, this.getHeaders());

    return handleResult(res, 'getting application status');
  }

  async getApplicationsToSupport(): Promise<Partial<ApplicationModelShape>[]> {
    const res = await this.fetch(this.baseUrl + '/v2/applications/to-support', this.getHeaders());

    return handleResult(res, 'getting pending recommendations');
  }

  async getApplication(id: number, fields?: string[]): Promise<ApplicationModel> {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/applications/${id}`, {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting application');
  }

  async getRecurringAwardApplication(id: number): Promise<RecurringAwardModelShape> {
    const res = await this.fetch(`${this.baseUrl}/v2/recurring-awards/${id}`, this.getHeaders());

    return handleResult(res, 'getting recurring awards application');
  }

  async upsertRecurringAwardApplication(applicationID: number, application: ApplicationModel) {
    const res = await this.fetch(`${this.baseUrl}/v2/recurring-awards/${applicationID}`, {
      method: 'PUT',
      body: JSON.stringify({ application }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating application');
  }

  async deleteApplication(id: number): Promise<void> {
    const res = await this.fetch(`${this.baseUrl}/v1/applications/${id}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting application');
  }

  async getApplicationsToSomething(urlPath, action, scholarshipId, filter, searchText, applicationCycle) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/applications/${urlPath}/${scholarshipId}`, {
        filter,
        q: searchText,
        applicationCycle
      }),
      this.getHeaders()
    );

    const applications = await handleResult(res, action);
    return applications;
  }

  async getApplicationsToScholarship(
    scholarshipId: number,
    filter: APPLICATION_TYPE,
    searchText?: string,
    applicationCycle?: number,
    pageIndex = 0,
    sort = 'ASC'
  ) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/applications/to-scholarship/${scholarshipId}`, {
        filter,
        q: searchText,
        applicationCycle,
        pageIndex,
        sort
      }),
      this.getHeaders()
    );

    const applications = await handleResult(res, 'getting applications to scholarship');

    return applications;
  }

  async getAllCandidatesToScholarship(scholarshipId: number, searchText?: string, applicationCycle?: number) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/candidates/to-scholarship/${scholarshipId}`, {
        q: searchText,
        applicationCycle
      }),
      this.getHeaders()
    );

    const applications = await handleResult(res, 'getting applications to scholarship');

    return applications;
  }

  async getApplicationForScholarship(scholarshipId: number, fields?): Promise<ApplicationModel & { isNew: boolean }> {
    // This request is actually upserts application in case it wasnt created. When react-query will be used here, we have to invalidate vettedQueryKey cuz of that, since it affects that data
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/applications/for-scholarship/${scholarshipId}`, {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting application');
  }

  async getApplicationsDocumentsForScholarship(scholarshipId: number): Promise<ScholarshipApplicationsDocuments> {
    const res = await this.fetch(
      `${this.baseUrl}/v2/applications/for-scholarship/${scholarshipId}/documents`,
      this.getHeaders()
    );

    return handleResult(res, 'getting applications documents for scholarship');
  }

  async upsertApplicationForScholarship(
    application: ApplicationModel,
    scholarshipId: number
  ): Promise<{
    applicationId: number;
    formId: number;
    recommenderFormId: number;
    donorId: number;
    userId: number;
    application: ApplicationModel;
    isNew: boolean;
  }> {
    const res = await this.fetch(`${this.baseUrl}/v1/applications/for-scholarship/${scholarshipId}`, {
      method: 'PUT',
      body: JSON.stringify({ application, scholarshipId }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating application');
  }

  async updateApplication(applicationId: number, application: ApplicationModel) {
    const res = await this.fetch(`${this.baseUrl}/v1/applications/${applicationId}`, {
      method: 'PUT',
      body: JSON.stringify({ application }),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating application');
  }

  async setAwardStatus(applicationId: number, awardStatus: AwardStatus) {
    const res = await this.fetch(`${this.baseUrl}/v1/applications/${applicationId}/award-status/${awardStatus}`, {
      method: 'PUT',
      body: JSON.stringify({}),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating award status');
  }

  async awardApplications(applicationsAwards, emailSubject, emailContent) {
    const res = await this.fetch(`${this.baseUrl}/v1/applications/award`, {
      method: 'POST',
      body: JSON.stringify({ applicationsAwards, emailSubject, emailContent }),
      ...this.getHeaders()
    });

    return handleResult(res, 'award applications');
  }

  async notifyNonAwardedStudents(scholarshipID, emailSubject, emailContent) {
    const res = await this.fetch(`${this.baseUrl}/v2/applications/notify-rejected/${scholarshipID}`, {
      method: 'POST',
      body: JSON.stringify({ emailSubject, emailContent }),
      ...this.getHeaders()
    });

    return handleResult(res, 'notify non awarded students');
  }

  async getNonAwardedStudentsPreview(scholarshipID): Promise<{
    count: number;
    recordExample: UserModelShape | null;
  }> {
    const res = await this.fetch(`${this.baseUrl}/v2/applications/notify-rejected/${scholarshipID}`, {
      method: 'GET',
      ...this.getHeaders()
    });

    return handleResult(res, 'get notify non awarded students preview');
  }

  async getMySubmittedSupports(): Promise<
    (SupportModelShape & {
      application: ApplicationModelShape;
    } & { scholarship: ScholarshipModelShape })[]
  > {
    const res = await this.fetch(`${this.baseUrl}/v2/supports`, this.getHeaders());

    return handleResult(res, 'getting recommendations');
  }

  async getSupport(supportId: number): Promise<SupportModel> {
    const res = await this.fetch(`${this.baseUrl}/v1/supports/${supportId}`, this.getHeaders());

    return handleResult(res, 'getting recommendation by ID');
  }

  async updateSupport(supportId: number, support: SupportModel): Promise<any> {
    const res = await this.fetch(`${this.baseUrl}/v1/supports/${supportId}`, {
      method: 'PUT',
      body: JSON.stringify({ support }),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating recommendation');
  }

  /** Retrieves all supports for the application */
  async getSupportsForApplication(applicationId: number): Promise<SupportModel[]> {
    const res = await this.fetch(`${this.baseUrl}/v1/supports/for-application/${applicationId}`, this.getHeaders());

    return handleResult(res, 'getting supports for application');
  }

  /** Retrieves my supports for the application */
  async getSupportForApplication(applicationId: number): Promise<SupportModel> {
    const res = await this.fetch(
      `${this.baseUrl}/v1/supports/for-application/${applicationId}/mine`,
      this.getHeaders()
    );

    return handleResult(res, 'getting support for application');
  }

  async upsertSupportForApplication(support: SupportModel, applicationId: number): Promise<any> {
    const res = await this.fetch(`${this.baseUrl}/v1/supports/for-application/${applicationId}`, {
      method: 'PUT',
      body: JSON.stringify({ support }),
      ...this.getHeaders()
    });

    return handleResult(res, 'upserting support for application');
  }

  // ? where this is used, except of tests?
  async getEligibility(criteria: string[]): Promise<EligibilityModel[]> {
    const res = await this.fetch(`${this.baseUrl}/v1/get-eligibility`, {
      method: 'POST',
      body: JSON.stringify(criteria),
      ...this.getHeaders()
    });

    return handleResult(res, 'getting eligibility');
  }

  // ? where this is used, except of tests?
  async updateEligibility(eligibilities: { criterion: string; pass: boolean }[]): Promise<void> {
    const res = await this.fetch(`${this.baseUrl}/v1/eligibility`, {
      method: 'PUT',
      body: JSON.stringify(eligibilities),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating eligibility');
  }

  async getVetted(pass: boolean | null = null): Promise<VettedModelShape[]> {
    const res = await this.fetch(`${this.baseUrl}/v1/vetted` + (pass != null ? `?pass=${pass}` : ''), {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting vetted');
  }

  async upsertVetted(scholarshipId: number, pass: boolean) {
    const res = await this.fetch(`${this.baseUrl}/v1/vetted/${scholarshipId}?pass=${pass ? 'true' : 'false'}`, {
      method: 'PUT',
      ...this.getHeaders()
    });

    return handleResult(res, 'updating vetted');
  }

  async deleteVetted(scholarshipId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/vetted/${scholarshipId}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting vetted');
  }

  async removeFromShortlist(scholarshipId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/shortlisted/${scholarshipId}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'removing shortlisted');
  }

  async getShortlisters(
    scholarshipId: number,
    limit?: number
  ): Promise<{
    users: UserModel[];
    count: number;
  }> {
    const res = await this.fetch(addQuery(`${this.baseUrl}/v1/shortlisters/${scholarshipId}`, { limit }), {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting shortlisters');
  }

  async getSubmittedTranscripts(): Promise<(TranscriptsModelShape & { applicant: UserModelShape })[]> {
    const res = await this.fetch(`${this.baseUrl}/v2/transcripts`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting transcripts');
  }

  async getTranscriptForApplicant(applicantId: number): Promise<TranscriptsModelShape> {
    const res = await this.fetch(`${this.baseUrl}/v2/transcripts/for-applicant/${applicantId}`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting transcript of applicant');
  }

  async submitTranscriptForApplicant(data: TranscriptSubmitModalSchemaType, applicantId: number): Promise<any> {
    const res = await this.fetch(`${this.baseUrl}/v2/transcripts/for-applicant/${applicantId}`, {
      method: 'PUT',
      body: JSON.stringify({ data: data }),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating transcript of applicant');
  }

  async getApplicantsNeedingTranscript(): Promise<
    (CounselorNotificationStatusModelShape & { scholarship: ScholarshipModelShape })[]
  > {
    const res = await this.fetch(`${this.baseUrl}/v2/transcripts/applicants-needing`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting applicants needing transcript');
  }

  async createQuestion(text: string, scholarshipId: number): Promise<{ id: number }> {
    const res = await this.fetch(`${this.baseUrl}/v1/questions/` + scholarshipId, {
      method: 'POST',
      body: JSON.stringify({ text }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating question');
  }

  async sendQuestion(questionId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/questions/${questionId}/send`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'sending question');
  }

  async answerQuestion(text: string, questionId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/questions/${questionId}/answer`, {
      method: 'POST',
      body: JSON.stringify({ text }),
      ...this.getHeaders()
    });

    return handleResult(res, 'answering question');
  }

  async deleteQuestion(questionId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/questions/` + questionId, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting question');
  }

  async getQuestions(pageIndex: number, filter: string | null = null) {
    const res = await this.fetch(addQuery(`${this.baseUrl}/v1/questions`, { page: pageIndex, filter }), {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting questions');
  }

  async getQuestionsForScholarship(
    scholarshipId: number
  ): Promise<Array<QuestionModelShape & { applicantName: string }>> {
    const res = await this.fetch(`${this.baseUrl}/v2/questions/for-scholarship/${scholarshipId}`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting questions for scholarship');
  }

  async getUnreadQuestions(): Promise<number> {
    const res = await this.fetch(`${this.baseUrl}/v1/questions/unread`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting question count');
  }

  async verifyUser(userID: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${userID}/verify`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'verifying user');
  }

  // is a weird method which has to be reviewed
  async getFileUrl(
    fileId: number,
    applicationId?: number,
    isPublic = false,
    isTempFile = false
  ): Promise<{ name: string; url: string }> {
    if (isTempFile) {
      const res = await this.fetch(`${this.baseUrl}/v2/temp_files/${fileId}`, {
        ...this.getHeaders()
      });

      return handleResult(res, 'getting temp file URL');
    }

    if (isPublic) {
      return {
        url: `/v1/files/content/${fileId}`,
        name: ''
      };
    }

    const res = await this.fetch(
      `${this.baseUrl}/v1/files/${fileId}` + (applicationId ? `?applicationId=${applicationId}` : ''),
      {
        ...this.getHeaders()
      }
    );

    return handleResult(res, 'getting file URL');
  }

  async downloadFile(fileId: number, applicationId?: number) {
    const fileObj = await this.getFileUrl(fileId, applicationId);

    const res = await this.fetch(this.baseUrl + fileObj.url);

    const resBlob = await res.blob();
    const fileName = fileObj.name || 'file';

    const url = window.URL.createObjectURL(resBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   * @deprecated
   */
  async createThumbnail(fileId, type, applicationId) {
    return this.thumbnailRequest(fileId, type, applicationId, 'POST');
  }

  /**
   * @deprecated
   */
  async getThumbnail(fileId, type, applicationId) {
    return this.thumbnailRequest(fileId, type, applicationId, 'GET');
  }

  /**
   * @deprecated
   */
  async thumbnailRequest(fileId, type, applicationId, method) {
    const res = await this.fetch(
      `${this.baseUrl}/v1/thumbnails/${fileId}/${type}` + (applicationId ? `?applicationId=${applicationId}` : ''),
      {
        method,
        hideSpinner: true,
        headers: {
          Authorization: this.jwt
        }
      }
    );

    const result: any[] = [];

    if (res.ok) {
      const buffer = await res.arrayBuffer();

      const maxImageCount = 10;
      const header = new Uint32Array(buffer.slice(0, (maxImageCount + 1) * 4));

      const pageCount = header[0];

      let atByte = (pageCount + 1) * 4;

      for (let page = 0; page < pageCount; page++) {
        const fileLength = header[page + 1];

        result.push(buffer.slice(atByte, atByte + fileLength));

        atByte += fileLength;
      }
    } else {
      await handleError(res, (method === 'GET' ? 'getting' : 'creating') + ' PDF thumbnail');
    }

    return result;
  }

  async createTempFile(
    file: any,
    validUntil: string /** ISO string */,
    fileOptions: {
      restrictImageHeight?: number;
    } = {}
  ) {
    const data = this.formDataFactory(file);
    data.append('valid_until', validUntil);

    data.append('fileOptions', JSON.stringify(fileOptions));

    const res = await this.fetch(`${this.baseUrl}/v2/temp_files`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: this.jwt
      }
    });

    return handleResult(res, 'uploading temp file');
  }

  async createFile(
    file: any,
    { donorId, applicantId, isPublic }: { donorId: number | null; applicantId: number | null; isPublic?: boolean }
  ): Promise<{ id: number; name: string }> {
    const data = this.formDataFactory(file);

    if (donorId != null) {
      data.append('donorId', donorId);
    }

    if (applicantId != null) {
      data.append('applicantId', applicantId);
    }

    if (isPublic) {
      data.append('isPublic', isPublic);
    }

    const res = await this.fetch(`${this.baseUrl}/v1/files`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: this.jwt
      }
    });

    return handleResult(res, 'uploading file');
  }

  async createFiles(files, { donorId, applicantId, isPublic = false }) {
    const data = this.formDataFactory(files, true);

    if (donorId != null) {
      data.append('donorId', donorId);
    }

    if (applicantId != null) {
      data.append('applicantId', applicantId);
    }

    if (isPublic) {
      data.append('isPublic', isPublic);
    }

    const res = await this.fetch(`${this.baseUrl}/v2/filesMultiple`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: this.jwt
      }
    });

    return handleResult(res, 'uploading files');
  }

  async createQRcode(text: string): Promise<string> {
    const res = await this.fetch(`${this.baseUrl}/v2/qr`, {
      method: 'POST',
      body: JSON.stringify({ text }),
      ...this.getHeaders()
    });

    return res.ok ? res.text() : '';
  }

  async getFieldDistributions(fields) {
    const res = await this.fetch(
      addQuery(this.baseUrl + '/v1/field-distributions', {
        fields: (fields || []).join(',')
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting field distributions');
  }

  async getAutocomplete(table: string, query: string): Promise<{ id: number; name: string }[]> {
    const res = await this.fetch(
      this.baseUrl + `/v2/autocomplete/${table}/${encodeURIComponent(query)}`,
      this.getHeaders()
    );

    return handleResult(res, 'autocompleting schools');
  }

  async addSchool(school: { name; state; city }): Promise<{ id: number }> {
    const res = await this.fetch(this.baseUrl + `/v1/schools`, {
      method: 'POST',
      body: JSON.stringify(school),
      ...this.getHeaders()
    });

    return handleResult(res, 'adding school');
  }

  async sentFax(payload: FaxModel): Promise<{ id: number }> {
    const res = await this.fetch(this.baseUrl + '/v1/fax/sent', {
      method: 'POST',
      body: JSON.stringify(payload),
      ...this.getHeaders()
    });

    return handleResult(res, 'sent fax');
  }
  async createfaxdocument(form: FormData): Promise<{ documentPath: string }> {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', this.jwt);
    const res = await this.fetch(this.baseUrl + '/v1/fax/createdocument', {
      method: 'POST',
      body: form,
      // ...this.getHeadersFormData()
      headers: myHeaders
    });

    return handleResult(res, 'creating fax document');
  }
  async reciveFax(): Promise<FaxReceiveModel[]> {
    const res = await this.fetch(this.baseUrl + `/v1/fax/list`, this.getHeaders());

    return handleResult(res, 'getting inbox');
  }

  async getMyReviewForApplication(applicationId: number): Promise<ReviewModelShape> {
    const res = await this.fetch(this.baseUrl + `/v1/reviews/${applicationId}/mine`, this.getHeaders());

    return handleResult(res, 'getting review');
  }

  async getReviews(applicationId: number): Promise<ReviewModel[]> {
    const res = await this.fetch(this.baseUrl + `/v1/reviews/${applicationId}`, this.getHeaders());

    return handleResult(res, 'getting reviews');
  }

  async getScholarshipReviewStatus(scholarshipId: number): Promise<any> {
    const res = await this.fetch(this.baseUrl + `/v1/scholarship/review-status/${scholarshipId}`, this.getHeaders());

    return handleResult(res, 'getting reviews');
  }

  async getReviewRoundDetails(reviewRoundID: number): Promise<DetailedReviewRoundResponse | null> {
    if (!reviewRoundID) {
      // ? is thats the case?
      return null;
    }

    const res = await this.fetch(this.baseUrl + `/v1/review-round/${reviewRoundID}`, this.getHeaders());

    return handleResult(res, 'getting reviews');
  }

  async downloadScores(scholarshipId: number) {
    const res = await this.fetch(`${this.baseUrl}/v1/scores/to-scholarship/${scholarshipId}`, this.getHeaders());

    let fileName: any = /filename="([^"]+)"/.exec(res.headers.get('Content-Disposition'));
    fileName = (fileName && fileName[1]) || 'scores.csv';

    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async setupReviewRoundSetup(scholarshipID: number, currentRound = 1, maxRounds = 1, assignations?: any) {
    const res = await this.fetch(this.baseUrl + `/v1/review-status/${scholarshipID}/${currentRound}/${maxRounds}`, {
      method: 'POST',
      body: assignations ? JSON.stringify(assignations) : null,
      ...this.getHeaders()
    });

    return handleResult(res, 'setuping review round');
  }

  async upsertReview(
    review: { data: ReviewDataModel; notes?: string },
    applicationId: number
  ): Promise<ReviewModelShape> {
    const res = await this.fetch(this.baseUrl + `/v1/reviews/${applicationId}`, {
      method: 'PUT',
      body: JSON.stringify(review),
      ...this.getHeaders()
    });

    return handleResult(res, 'upserting review');
  }

  async notifyRecommender(
    applicationId: number,
    { name, email, phone, content }: { name: string; email: string; phone?: string; content?: string }
  ): Promise<{ status: RecommenderNotificationStatus }> {
    const res = await this.fetch(this.baseUrl + `/v1/notify/recommender/${applicationId}`, {
      method: 'POST',
      body: JSON.stringify({ name, email, phone: phone || '', content: content || '' }),
      ...this.getHeaders()
    });

    return handleResult(res, 'notifying recommender');
  }

  async notifyCounselor(
    {
      name,
      email,
      phone,
      content,
      skipEmail
    }: { name: string; email: string; phone: string; content?: string; skipEmail: boolean },
    applicationId: number
  ): Promise<CounselorNotificationStatus> {
    const res = await this.fetch(this.baseUrl + `/v1/notify/counselor`, {
      method: 'POST',
      body: JSON.stringify({
        counselor: { name, email, phone: phone || '' },
        application: { id: applicationId },
        content: content || '',
        skipEmail // ? assuming thats made for tests or what
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'notifying counselor');
  }

  async sanityCheckIdSeq() {
    const res = await this.fetch(this.baseUrl + '/system-test/id-seq', this.getHeaders());

    return handleResult(res, 'checking ID sequences in DB');
  }
  async createTranscriptRequestForm(transcriptFile) {
    const data = new FormData();
    data.append('file', transcriptFile);
    const myHeaders = new Headers();
    myHeaders.append('Authorization', this.jwt);
    const res = await this.fetch(`${this.baseUrl}/v1/transcriptRequest/counselor`, {
      method: 'POST',
      body: data,
      // koa-body doesn't supports application/pdf
      headers: myHeaders
    });
    return handleResult(res, 'transcript request file uploading');
  }
  async getTranscriptRequestForm() {
    const resp = await this.fetch(`${this.baseUrl}/v1/transcriptRequest/list`, this.getHeaders());
    return handleResult(resp, 'transcript form list');
  }
  async deleteTranscriptRequestForm(transcriptId) {
    const res = await this.fetch(`${this.baseUrl}/v1/transcriptRequest/${transcriptId}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });
    return handleResult(res, 'transcript request form deleting');
  }
  async assignTranscriptRequestForm(transcriptId, isProcessed) {
    const res = await this.fetch(`${this.baseUrl}/v1/service/formstack/transcriptRequest/${transcriptId}/assignment`, {
      method: 'POST',
      body: JSON.stringify({
        dataEntryCompleted: isProcessed
      }),
      ...this.getHeaders()
    });
    return handleResult(res, 'assigning transcript request form');
  }
  async createTranscriptRequestFormStudent(transcriptId) {
    const res = await this.fetch(`${this.baseUrl}/v1/transcriptRequest/student/${transcriptId}`, {
      method: 'POST',
      ...this.getHeaders()
    });
    return handleResult(res, 'requesting transcript request form');
  }
  async getSignedTranscriptRequestList() {
    const res = await this.fetch(`${this.baseUrl}/v1/transcriptRequest/signed`, {
      method: 'GET',
      ...this.getHeaders()
    });

    return handleResult(res, 'signed transcript request form');
  }
  async getSignedTranscriptRequest(formstackId) {
    const res = await this.fetch(`${this.baseUrl}/v1/service/formstack/transcriptRequest/${formstackId}/download`, {
      method: 'GET',
      ...this.getHeaders()
    });
    return handleResult(res, 'transcript request download');
  }

  async updateTranscriptRequestLender(
    id,
    data: {
      transcriptSent?: boolean;
      note?: string;
    }
  ) {
    const res = await this.fetch(this.baseUrl + `/v1/transcriptRequestLender/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...this.getHeaders()
    });
    return handleResult(res, 'Updating transcriptRequest Lender');
  }

  async verifyInvitations(invitations: Invitation[]): Promise<{
    invitationStatuses: VerifiedInvitation[];
  }> {
    const existanceData = await this.fetch(
      addQuery(this.baseUrl + '/v1/invitations/verify', {
        invitations: JSON.stringify(invitations)
      }),
      {
        ...this.getHeaders()
      }
    );

    return handleResult(existanceData, 'Invitations verify');
  }

  async processInvitations(invitations: InvitationConfirmation[]): Promise<boolean> {
    const invitationResult = await this.fetch(`${this.baseUrl}/v1/invitations`, {
      method: 'POST',
      body: JSON.stringify(invitations),
      ...this.getHeaders()
    });

    return handleResult(invitationResult, 'Process invitations');
  }

  // same as usual invitations. On backend it will be handled differently due to legacy status
  async processLegacyInvitation(invitation: InvitationConfirmation): Promise<{ result: string }> {
    const invitationResult = await this.fetch(`${this.baseUrl}/v1/invitations/legacy`, {
      method: 'POST',
      body: JSON.stringify(invitation),
      ...this.getHeaders()
    });

    return handleResult(invitationResult, 'Process invitations');
  }

  async removeRelationship(relationship: InvitationRelationship): Promise<void> {
    const invitationResult = await this.fetch(`${this.baseUrl}/v1/invitations/unrelate`, {
      method: 'POST',
      body: JSON.stringify(relationship),
      ...this.getHeaders()
    });

    return handleResult(invitationResult, 'Remove relationship');
  }

  getHeaders() {
    return {
      headers: {
        ...(this.jwt ? { Authorization: this.jwt } : null),
        ...getStaticHeaders()
      }
    };
  }
  getHeadersFormData() {
    return {
      headers: {
        Authorization: this.jwt || ''
        // ...getStaticHeaders()
      }
    };
  }
}

function addQuery(url, params) {
  const query = Object.keys(params)
    .map(k => (params[k] != null ? encodeURIComponent(k) + '=' + encodeURIComponent(params[k]) : null))
    .filter(q => !!q)
    .join('&');

  return query ? url + '?' + query : url;
}
