import { array, boolean, date, InferType, number, object, string } from 'yup';

import college_types from '@sharedComponents/models/enumValues/college_types';
import academic_years from '@sharedComponents/models/enumValues/academic_years';
import athletics from '@sharedComponents/models/enumValues/athletics';
import miscellaneous from '@sharedComponents/models/enumValues/miscellaneous';
import genders from '@sharedComponents/models/enumValues/genders';
import religious from '@sharedComponents/models/enumValues/religious';
import selfIdentification from '@sharedComponents/models/enumValues/selfIdentification';
import veteran_status from '@sharedComponents/models/enumValues/veteran_status';
import ethnicities from '@sharedComponents/models/enumValues/ethnicities';
import allAreas from '@sharedContract/areas';

export const ScholarshipModelDataApplicationMaterialSchema = object().shape({
  essay: string().oneOf(['Yes', 'No', null]).nullable(),
  letterofRecommendation: string().oneOf(['Yes', 'No', null]).nullable(),
  transcript: string().oneOf(['Yes', 'No', null]).nullable(),
  notes: string().nullable()
});

// scholarship data schema
export const ScholarshipModelDataSchema = object({})
  .shape({
    startDate: date()
      .label('Start Date')
      .typeError('Scholarship start date and deadline are required')
      .required('Scholarship start date and deadline are required'),
    faqQustionAnswer: array(
      object().shape({
        question: string(),
        answer: string()
      })
    ).nullable(),

    ApplicationMaterial: ScholarshipModelDataApplicationMaterialSchema,

    // school eligibility based on schoolsMatching virtual
    schools: array(
      object().shape({
        id: number(),
        name: string()
      })
    ).nullable(),
    schoolStates: array(string().min(2).max(2)).nullable(),
    schoolCounties: array(string()).nullable(),

    // colleges eligibility based on collegeMatching virtual
    colleges: array(
      object({
        id: number(),
        name: string()
      })
    ).nullable(),
    collegeStates: array(string().min(2).max(2)).nullable(),
    collegeCounties: array(string()).nullable(),
    collegeTypes: array(string().oneOf(college_types)).nullable(),

    // residency eligibility
    residency: array(string().min(2).max(2)).nullable(),
    residencyCounties: array(string()).nullable(),

    // general eligibility
    genders: array(string().oneOf(genders)).nullable(),
    ethnicities: array(string().oneOf(ethnicities)).nullable(),
    religious: array(string().oneOf(religious)).nullable(),
    selfIdentification: array(string().oneOf(selfIdentification)).nullable(),
    veteranStatus: array(string().oneOf(veteran_status)).nullable(),
    athletics: array(string().oneOf(athletics)).nullable(),
    miscellaneous: array(string().oneOf(miscellaneous)).nullable(),
    academicYears: array(string().oneOf(academic_years)).nullable(),

    areas: array(string().oneOf(allAreas)).optional().nullable(),

    // GPA
    gpaFrom: number().label('GPA').min(0).max(5).nullable(),
    gpaTo: number().label('GPA').min(0).max(5).nullable(),

    // booleans
    lowIncome: boolean().nullable(),
    communityService: boolean().nullable(),
    firstGen: boolean().nullable(),

    // donor scholarship self-listing utility form field
    hasOnlineApplication: boolean().nullable(),
    application_example: number().nullable(),
    award_recipients_number: number().nullable(),
    self_listing_step: number().optional(),

    // binding to donor organization
    donor_id: number().optional() // not really optional, but thats partial schema
  })
  .default({});

export type ScholarshipModelDataType = InferType<typeof ScholarshipModelDataSchema>;

/**
  The "shared" schema is effectively the API schema. These are the fields exchanged in the API, common to client and server.
 */
export const ScholarshipModelSchema = object({}).shape({
  // none of these fields are required
  // since they are not actually set until saved to the database
  id: number().integer().notRequired(),
  created_at: date().default(new Date()),
  updated_at: date().default(new Date()).optional(),
  // end of system fields

  name: string().label('Scholarship Name').min(6).required(),
  description: string().label('Scholarship description').min(50).required(),
  apply_url: string().label('Application URL').url().nullable(),

  deadline: date().typeError('Scholarship deadline is required').nullable(),
  candidates_limit: number().integer().nullable(),
  award_amount_to: number().label('Award Amount').typeError('Award Amount is required'),

  data: ScholarshipModelDataSchema.clone(),

  /** Virtuals */
  schoolsMatching: string().oneOf(['Any', 'Specific', 'State', 'County']),
  deadlineDate: date().label('Deadline').typeError('Scholarship deadline is required').required()
  // TODO more fields from scholarship model...
});

// yup magically creates TypeScript type definition here
export type ScholarshipModelType = InferType<typeof ScholarshipModelSchema>;
