import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import BooleanRadioGroupField from '@sharedComponents/formElements/combined/BooleanRadioGroupField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';

import AbstractEntryStep from './AbstractEntryStep';

const EntryStepEligibility: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    data: yup.object().shape({
      guidestarUrl: yup.string().url('Invalid GuideStar URL').optional(),
      isNonProfitOrganization: yup.boolean(),
      isBasedInUSA: yup.boolean(),
      doesSellProducts: yup.boolean(),
      isSuspiciouslyShort: yup.boolean(),
      haveReviewProcess: yup.boolean(),
      canFindPastRecepients: yup.boolean()
    })
  }),

  render: function (rowProps) {
    return (
      <EuiFlexGroup className="entryStepVerification" direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Verification</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="l">
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <TextField
                    fieldName={'data.guidestarUrl'}
                    fieldProps={{
                      placeholder: 'https://'
                    }}
                    rowProps={{ label: 'GuideStar URL', ...rowProps }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false}>
                  <BooleanRadioGroupField
                    fieldName="data.isNonProfitOrganization"
                    rowProps={{
                      ...rowProps,
                      label: 'Is this organization a non-profit?',
                      className: 'm-longLabel' // custom modifier-class to extend form labels width
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <BooleanRadioGroupField
                    fieldName="data.isBasedInUSA"
                    rowProps={{
                      ...rowProps,
                      label: 'Is this organization based in the US?',
                      className: 'm-longLabel'
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <BooleanRadioGroupField
                    fieldName="data.doesSellProducts"
                    rowProps={{
                      ...rowProps,
                      label: 'Does this organization sell products/services to college students?',
                      className: 'm-longLabel'
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <BooleanRadioGroupField
                    fieldName="data.isSuspiciouslyShort"
                    rowProps={{
                      ...rowProps,
                      label: 'Is the scholarship application suspiciously short?',
                      className: 'm-longLabel'
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <BooleanRadioGroupField
                    fieldName="data.haveReviewProcess"
                    rowProps={{
                      ...rowProps,
                      label: 'Does the scholarship have a review process?',
                      className: 'm-longLabel'
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <BooleanRadioGroupField
                    fieldName="data.canFindPastRecepients"
                    rowProps={{
                      ...rowProps,
                      label: 'Can you find past recipients for this scholarship?',
                      className: 'm-longLabel'
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepEligibility;
