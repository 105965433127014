import { atom } from 'recoil';

import { FormStateInternals } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationPageType } from '@sharedComponents/schemas/FormNodesSchema';

export interface FormbuilderState {
  formName: string;
  formID: number | null;
  pages: (ApplicationPageType & FormStateInternals)[];
  activePage: number;
  isDirty: boolean;
}

export const formStateAtomHistory: Array<{
  undo: () => void;
}> = [];

export function cleanHistory() {
  formStateAtomHistory.length = 0;
}

const historyEffect =
  () =>
  ({ trigger, setSelf, onSet }) => {
    onSet((newValue, oldValue) => {
      if (oldValue.formID !== null) {
        formStateAtomHistory.push({
          undo: () => {
            setSelf(oldValue);
          }
        });
      }
    });
  };

export const formStateAtom = atom<FormbuilderState>({
  key: 'formStateAtom',
  default: {
    formName: '',
    pages: [],
    activePage: 0,
    formID: null,
    isDirty: false
  },
  effects_UNSTABLE: [historyEffect()]
});
