/* eslint-disable react-hooks/exhaustive-deps */
import ButtonBar from '@donors/components/ButtonBar';
import { validateEmail, validateRequired, validatePhone } from '@donors/components/validations';
import UserModel, { CoreUserFields, DonorUserData } from '@sharedContract/UserModel';
import React, { useState } from 'react';
import ValidatingField, { useSubmittable } from '../components/ValidatingField';
import useSingleSubmit from '../useSingleSubmit';
import DonorWidget, { SelectedDonor } from '@donors/components/DonorWidget';
import UserClient from '@sharedClients/UserClient';
import './EditDonorUser.scss';

export default function EditDonorUser({
  onSave,
  onCancel,
  onError,
  user,
  userClient,
  currentUser,
  type
}: {
  onSave: (user: CoreUserFields) => any;
  onCancel: () => void;
  onError: (e: Error) => void;
  user?: CoreUserFields;
  userClient: UserClient;
  currentUser: UserModel;
  type: string;
}) {
  user = user || ({ data: {} } as CoreUserFields);

  const [name, setName] = useState<string>(user.data.name || '');
  const [lastName, setLastName] = useState<string>(user.data.lastName || '');
  const [email, setEmail] = useState<string>(user.name || '');
  const [phone, setPhone] = useState<string>(user.data.phone || '');
  const [donor, setDonor] = useState<SelectedDonor>(
    user.donor && user.donor.name
      ? { isExisting: true, id: user.donor.id, name: user.donor.name || '' }
      : { isExisting: false }
  );

  const [isPremium, setPremium] = useState<boolean>((user.data as DonorUserData).isPremium || false);

  const [singleSubmit, isSubmitting] = useSingleSubmit();

  function onSubmit(e: Event) {
    e.preventDefault();
    setSubmitted(true);
    if (isSubmittable()) {
      const data: DonorUserData = {
        name: name || 'Unnamed',
        lastName,
        phone,
        isPremium
      };
      const editedUser: CoreUserFields = {
        name: email,
        type,
        donor: {
          id: donor.id as number,
          name: donor.name
        },
        data
      };

      return onSave(editedUser);
    }
  }

  const { isSubmittable, validatingFieldDefaults, setSubmitted } = useSubmittable();

  return (
    <form onSubmit={singleSubmit(onSubmit)} className="EditDonorUser">
      <div className="pageTitle">
        <h4>{user.name ? 'Edit' : 'New'} User</h4>
        <h2>{user.name || 'New ' + type}</h2>
      </div>
      <ValidatingField
        label="Email"
        id="email"
        value={email}
        setValue={setEmail}
        validate={validateEmail}
        renderChildren={props => <input {...props} disabled={user && user.name} type="text" />}
        {...validatingFieldDefaults}
      />

      <ValidatingField
        label="First Name"
        id="name"
        value={name}
        setValue={setName}
        validate={validateRequired}
        renderChildren={props => <input {...props} type="text" />}
        {...validatingFieldDefaults}
      />

      <ValidatingField
        label="Last Name"
        id="lastName"
        value={lastName}
        setValue={setLastName}
        validate={validateRequired}
        renderChildren={props => <input {...props} type="text" />}
        {...validatingFieldDefaults}
      />

      <ValidatingField
        label="Phone"
        id="phone"
        value={phone}
        setValue={setPhone}
        validate={validatePhone}
        renderChildren={props => <input {...props} type="text" maxLength={13} />}
        {...validatingFieldDefaults}
      />

      {currentUser.type === 'admin' ? (
        <DonorWidget
          initialValue={donor}
          onChange={setDonor}
          onError={onError}
          user={currentUser}
          userClient={userClient}
          type={user.type}
          {...validatingFieldDefaults}
        />
      ) : null}

      {type === 'donor' ? (
        <div className="field">
          <div className="checkbox">
            <input id="premium" type="checkbox" checked={isPremium} onChange={e => setPremium(e.target.checked)} />
            <label htmlFor="premium">Premium account</label>
          </div>
        </div>
      ) : null}

      <ButtonBar>
        <button className="button" disabled={isSubmitting}>
          Save
        </button>
        <button
          className="button"
          onClick={e => {
            onCancel();
            e.preventDefault();
          }}
        >
          Cancel
        </button>
      </ButtonBar>
    </form>
  );
}
