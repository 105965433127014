import { useState } from 'react';

export type LoadingStatus = {
  message?: string;
  isLoading: boolean;
  startLoading: (message?: string) => void;
  stopLoading: () => void;
};

/**
 * Hook for managing loading indicator state.
 * Allows you to start and stop the loading boolean, and manage the loading indicator message.
 * @param initState Initial loading state. Defaults to false.
 * @param initMessage Initial message. Defaults to undefined.
 * ? extend this one to use loading prompts and optional connection with react-query?
 */
const useLoading = (initState: boolean = false, initMessage?: string): LoadingStatus => {
  const [isLoading, setIsLoading] = useState<boolean>(initState);
  const [message, setMessage] = useState<string | undefined>(initMessage);

  const start = (message?: string) => {
    setIsLoading(true);
    setMessage(message);
  };

  const stop = () => {
    setIsLoading(false);
    setMessage(undefined);
  };

  return { message, isLoading, startLoading: start, stopLoading: stop };
};

export default useLoading;
