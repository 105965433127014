import { useModalCreate } from '@sharedComponents/contexts/modalContext';

import DraftRestoreModal from '../../../../../shared/components/routes/ScholarshipListingWizard/DraftRestoreModal';
import unfinishedDraftScholarshipQuery from '../../../../../shared/components/routes/ScholarshipListingWizard/queries/unfinishedDraftScholarshipQuery';

function useDraftScholarshipRestore(isEnabled: boolean) {
  const createModal = useModalCreate();

  const { isFetching: isUnfinishedDraftFetching } = unfinishedDraftScholarshipQuery.useUnfinishedDraftScholarshipEntry({
    enabled: isEnabled,
    onSuccess: draftEntry => {
      // in case we have unfinished draft, we should continue that one or discard it
      if (draftEntry?.id && draftEntry?.name) {
        createModal(({ closeModal }) => <DraftRestoreModal closeModal={closeModal} draftEntry={draftEntry} />);
      }
    }
  });

  return isUnfinishedDraftFetching;
}

export default useDraftScholarshipRestore;
