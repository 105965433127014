import moment from 'moment';
import { Controller, useFormContext, FieldError } from 'react-hook-form';

import { EuiDatePicker, EuiDatePickerProps, EuiDatePickerRange } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function DateRangeField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: [string, string];
  fieldProps?: Partial<EuiDatePickerProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const [dateFromFieldName, dateToFieldName] = fieldName;
  const dateFromError = getValueByDottedPath<FieldError>(dateFromFieldName, errors);
  const dateToError = getValueByDottedPath<FieldError>(dateToFieldName, errors);

  const field = (
    <EuiDatePickerRange
      startDateControl={
        <Controller
          control={control}
          name={dateFromFieldName}
          isInvalid={!!dateFromError}
          error={dateFromError?.message || ''}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <EuiDatePicker
                name={name}
                selected={value ? moment.utc(value) : undefined}
                isInvalid={!!dateFromError}
                onBlur={onBlur}
                popoverPlacement="bottom-start"
                {...fieldProps} // before onChange to make sure its handled by controller
                onChange={val => {
                  onChange(val);
                }}
                autoComplete="off"
                utcOffset={0}
                ref={ref}
              />
            );
          }}
        />
      }
      endDateControl={
        <Controller
          control={control}
          name={dateToFieldName}
          isInvalid={!!dateToError}
          error={dateToError?.message || ''}
          render={({ onChange, onBlur, value, name, ref }) => {
            return (
              <>
                <EuiDatePicker
                  name={name}
                  selected={value ? moment.utc(value) : undefined}
                  isInvalid={!!dateToError}
                  onBlur={onBlur}
                  popoverPlacement="bottom-end"
                  {...fieldProps} // before onChange to make sure its handled by controller
                  onChange={val => {
                    onChange(val);
                  }}
                  autoComplete="off"
                  utcOffset={0}
                  ref={ref}
                />
              </>
            );
          }}
        />
      }
      fullWidth={!!fieldProps?.fullWidth}
    />
  );

  const isInvalid = !!dateFromError?.message || !!dateToError?.message || false;
  return rowProps ? (
    <FormRow
      rowKey={fieldName.join('_')}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={dateToError?.message || dateFromError?.message || ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
