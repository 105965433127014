import './Application.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FormNode } from '@donors/application/FormNode';
import { notFoundIsUserError } from '@donors/donor/Donor';
import { fullFormId } from '@donors/form/Form';
import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import ApplicationModel, { AwardStatus } from '@sharedClients/types/ApplicationModel';
import { FormData } from '@sharedClients/types/FormDataNode';
import FormModel from '@sharedClients/types/FormModel';
import SupportModel from '@sharedClients/types/SupportModel';
import { nonAwardedAwardStatuses } from '@sharedClients/validating/AwardStatusValidation';
import addStandardFields from '@sharedComponents/base/addStandardFields';
import NextPrevNavigation from '@sharedComponents/base/components/NextPrevNavigation';
import preventDefault from '@sharedComponents/base/preventDefault';
import { useErrorReporter } from '@sharedComponents/base/useErrorReporter';
import { USER_TYPE } from '@sharedComponents/constants';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { applicationsFromList } from '@sharedComponents/ScholarshipApplications/ApplicationsList';
import scholarshipWithReviewStatusQuery from '@sharedComponents/ScholarshipApplications/queries/scholarshipWithReviewStatusQuery';
import { getScholarshipLink } from '@sharedComponents/ScholarshipList/ScholarshipList';
import {
    useCurrentUserJWT, useLoggedInUser
} from '@sharedComponents/selectors/loggedInUserSelectors';
import { useAllClients } from '@sharedComponents/selectors/useAllClients';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';

import externalApplicationForm from './externalApplicationForm';
import LeaveReviewPanel from './LeaveReviewPanel';
import ReviewsPanel from './ReviewsPanel';

export const noValue = '—';

/**
 * Counselors, donors, reviews and admin-like users view for application
 */
const Application = ({ applicationId }: { applicationId: number }) => {
  const { applicationClient, scholarshipClient } = useAllClients();
  const [application, setApplication] = useState<ApplicationModel | null>(null);
  const webToken = useCurrentUserJWT();
  const user = useLoggedInUser()!;
  const { history } = useRouter();
  const [defaultForm, setDefaultForm] = useLocalStorage<FormModel | null>('defaultForm', null);

  const formData: any | null = getForm(application, defaultForm);

  const [supports, setSupports] = useState<SupportModel[] | undefined>(undefined);

  const [onError, errorMessage] = useErrorReporter();
  const [deleteState, setDeleteState] = useState<'not' | 'confirm' | 'gone'>('not');

  async function deleteApplication() {
    if (deleteState === 'not') {
      setDeleteState('confirm');
    } else if (deleteState === 'confirm') {
      applicationClient
        .deleteApplication(applicationId)
        .then(() => {
          setDeleteState('gone');
        })
        .catch(onError);
    }
  }

  // TODO:IMP: Hey, use react-query here, please
  useEffect(() => {
    applicationClient
      .getApplication(applicationId)
      .then(application => {
        setApplication(application);

        // ! thats shitty moment here - form getting applied into application and if form changes, we dont display those changes. we have at least update this on edit
        if (!application.form) {
          scholarshipClient.getForm(fullFormId).then(setDefaultForm).catch(onError);
        }

        document.title = `${getUserFullNameFromData(application)} at Scholar's App`;
      })
      .catch(notFoundIsUserError(onError));

    applicationClient.getSupportsForApplication(applicationId).then(setSupports).catch(onError);
  }, [applicationId]);

  const { data: scholarshipModel } = scholarshipWithReviewStatusQuery.useScholarshipWithReviewStatus(
    applicationClient,
    application?.scholarship.id
  );

  // ? any optimization for this one
  const isApplicationInCurrentReviewProcess =
    application?.id &&
    scholarshipModel?.mostRelevantReviewProcess?.reduce((isResult: boolean, reviewRound) => {
      if (isResult || !reviewRound.assignations) {
        return isResult;
      }

      // check all active rounds to find if there is this application
      const { assignations } = reviewRound;
      return assignations && Object.keys(assignations).includes(application.id.toString());
    }, false);

  const isApplicationInCurrentRound =
    application?.id &&
    scholarshipModel?.reviewRound?.assignations[application.id] &&
    scholarshipModel.reviewRound.assignations[application.id].includes(user.id);

  function setAwardStatus(awardStatus: AwardStatus) {
    return applicationClient
      .setAwardStatus(applicationId, awardStatus)
      .then(() => {
        setApplication({
          ...(application as ApplicationModel),
          awardStatus
        });
      })
      .catch(onError);
  }

  const currentIndex = applicationsFromList.indexOf(applicationId);

  const isInternal = application && !application['isExternal'];
  const canDonorReview = user.donor && user.donor.canReview;
  const canSetAwardStatus =
    isInternal && ((canDonorReview && user.type === USER_TYPE.DONOR) || user.type === USER_TYPE.ADMIN);
  const isAwarded =
    application && application.awardStatus && !nonAwardedAwardStatuses.includes(application.awardStatus);

  const isACandidate = isCandidate(application as ApplicationModel);

  const canReview = user.type === USER_TYPE.REVIEWER && isACandidate && canDonorReview;

  const canSeeReviews = isInternal && (canDonorReview || user.type === USER_TYPE.ADMIN);

  const applicationName = (application && getUserFullNameFromData(application)) || '';

  const isCurrentUserAdmin = user.type === USER_TYPE.ADMIN;
  return (
    <div className="application" key={`application_${applicationId}`}>
      <div className={'content' + (user.type === USER_TYPE.REVIEWER ? ' m-shiftedToTheLeft' : '')}>
        {errorMessage}

        <NextPrevNavigation
          prev={currentIndex > 0 ? '/applications/' + applicationsFromList[currentIndex - 1] : undefined}
          next={
            currentIndex < applicationsFromList.length - 1
              ? '/applications/' + applicationsFromList[currentIndex + 1]
              : undefined
          }
          history={history}
        >
          <div className="pageTitle">
            <h4>
              {application && application['isExternal'] ? 'Applicant' : 'Application'}
              {application && application.scholarship ? (
                <span>
                  {' '}
                  to <Link to={getScholarshipLink(application.scholarship, user)}>{application.scholarship.name}</Link>
                </span>
              ) : null}
            </h4>
            <h2>{applicationName}</h2>
          </div>
        </NextPrevNavigation>
        <EuiFlexGroup direction="column" gutterSize="xs">
          {application ? (
            <EuiFlexItem>
              <EuiFlexGroup direction="row">
                <EuiFlexItem grow={false}>
                  <EuiBadge color={getStatusColor(application)}>{getStatusName(application)}</EuiBadge>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          ) : null}

          <EuiFlexItem>
            <EuiFlexGroup direction="row" gutterSize="s">
              {isCurrentUserAdmin && application?.scholarship?.id ? (
                <EuiFlexItem grow={false}>
                  <EuiButton
                    size="s"
                    color="text"
                    onClick={() =>
                      history.push(`/standalone-application/${application.scholarship.id}/${application.id}`)
                    }
                  >
                    Edit application
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {canSetAwardStatus && !isAwarded ? (
                isACandidate ? (
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" onClick={() => setAwardStatus('noCandidate')}>
                      Eliminate from candidates
                    </EuiButton>
                  </EuiFlexItem>
                ) : (
                  <EuiFlexItem grow={false}>
                    <EuiButton size="s" onClick={() => setAwardStatus('candidate')}>
                      Make candidate
                    </EuiButton>
                  </EuiFlexItem>
                )
              ) : null}

              {user && user.type === USER_TYPE.ADMIN && application ? (
                <EuiFlexItem grow={false}>
                  <EuiButton
                    size="s"
                    onClick={() => {
                      history.push(`/users/${application.userId}`);
                    }}
                  >
                    User Profile
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {user && user.type === USER_TYPE.ADMIN && application ? (
                <EuiFlexItem grow={false}>
                  <EuiButton color="danger" size="s" onClick={preventDefault(deleteApplication)}>
                    {deleteState === 'not'
                      ? 'Delete'
                      : deleteState === 'confirm'
                      ? 'Really delete the application?'
                      : 'Deleted'}
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size="s" />
        {canReview &&
        scholarshipModel &&
        isApplicationInCurrentRound &&
        scholarshipModel.currentRoundReviewCriteriasConfiguration ? (
          <LeaveReviewPanel
            client={applicationClient}
            applicationId={applicationId}
            reviewCriteria={scholarshipModel.currentRoundReviewCriteriasConfiguration}
            reviewInstructions={scholarshipModel.review_instructions}
          />
        ) : null}

        {canSeeReviews && scholarshipModel?.reviewCriteriasConfiguration && isApplicationInCurrentReviewProcess ? (
          <ReviewsPanel
            client={applicationClient}
            applicationId={applicationId}
            scholarship={scholarshipModel}
            isReviewer={user.type === USER_TYPE.REVIEWER}
          />
        ) : null}

        {application && application['isExternal'] ? (
          <div className="help">
            The application is not hosted by Scholar's App, but the applicant has stated they applied.
          </div>
        ) : null}

        {formData && application ? (
          <div className="form">
            <FormNode
              application={application}
              user={user}
              jwt={webToken || undefined}
              // "applicaton" only passes the relevant section to each field.
              // "fullApplication" passes the complete application
              fullApplication={application}
              node={formData}
              onError={onError}
              applicationClient={applicationClient}
              supports={supports}
            />
          </div>
        ) : (
          []
        )}
      </div>
    </div>
  );
};

export default Application;

function getForm(application: ApplicationModel | null, defaultForm: FormModel | null) {
  if (application && application.form) {
    return addStandardFields(
      application.form.standardFields,
      application.form.data,
      application.standardForm as FormData
    );
  } else if (application && application['isExternal']) {
    // ? is thats ever a case? tried to reproduce but failed
    return externalApplicationForm.concat(
      application.scholarship.requireTranscript
        ? [
            {
              type: 'section',
              title: 'Documents',
              children: [{ type: 'counselor', field: 'counselor' }]
            }
          ]
        : ([] as any)
    );
  } else if (defaultForm) {
    return defaultForm.data;
  } else {
    return null;
  }
}

function isCandidate(a: ApplicationModel) {
  if (a && a.awardStatus === 'noCandidate') {
    return false;
  }

  return true;
}

function getStatusColor(application: ApplicationModel) {
  const status = application.awardStatus || (isCandidate(application) ? 'candidate' : 'noCandidate');
  return {
    candidate: 'primary',
    noCandidate: 'danger',
    awardAccepted: 'success',
    awardNotified: 'success',
    awardRejected: 'danger',
    rejectNotified: 'warning'
  }[status];
}

// TODO:IMP: replace with getAwardStatusName
function getStatusName(application: ApplicationModel) {
  const status = application.awardStatus || (isCandidate(application) ? 'candidate' : 'noCandidate');

  return {
    candidate: 'Candidate',
    noCandidate: 'Eliminated',
    awardAccepted: 'Accepted award',
    awardNotified: 'Awarded; notified',
    awardRejected: 'Rejected award',
    rejectNotified: 'Eliminated; notified'
  }[status];
}
