import React from 'react';
import * as yup from 'yup';

import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import FieldList from '@sharedComponents/formElements/combined/FieldList';
import TextAreaField from '@sharedComponents/formElements/simpleFields/TextAreaField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';

import AbstractEntryStep from './AbstractEntryStep';

const EntryStepResearchSecond: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    original_url: yup.string().url('Invalid Donor URL').optional(),
    data: yup.object().shape({
      faqQustionAnswer: yup
        .array(
          yup.object().shape({
            question: yup.string(),
            answer: yup.string()
          })
        )
        .default([]),
      notes: yup.string().optional()
    })
  }),

  render: function (rowProps) {
    return (
      <EuiFlexGroup direction="column" alignItems="center">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Donor Information</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem style={{ minWidth: 600 }}>
          <TextField
            fieldName={'original_url'}
            fieldProps={{ autoComplete: 'off', placeholder: 'https://' }}
            rowProps={{ label: 'Donor Organization URL', ...rowProps }}
          />
          <EuiSpacer size="m" />
          <FieldList
            fieldName={'data.faqQustionAnswer'}
            recordDefaultValue={{ question: '', answer: '' }}
            minListItems={0}
            fieldFactoryFn={(fieldNamePrefix, register, field) => {
              return (
                <>
                  <EuiFieldText
                    type="text"
                    name={`${fieldNamePrefix}question`}
                    inputRef={register}
                    defaultValue={field['question']}
                    placeholder={'Question'}
                    fullWidth
                  />
                  <EuiFieldText
                    type="text"
                    name={`${fieldNamePrefix}answer`}
                    inputRef={register}
                    defaultValue={field['answer']}
                    placeholder={'Answer'}
                    fullWidth
                  />
                </>
              );
            }}
            rowProps={{ label: 'FAQ (Question & Answer)', ...rowProps }}
          />
          <TextAreaField
            fieldName={'data.notes'}
            rowProps={{ label: 'Notes', helpText: 'Internal notes visible to the donor.', ...rowProps }}
            fieldProps={{ placeholder: '' }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepResearchSecond;
