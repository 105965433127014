import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { EuiFlexGroup, EuiFlexItem, formatDate, EuiBadge } from '@elastic/eui';
import AbstractListing, { Column } from '@sharedComponents/common/AbstractListing';
import SearchInput from '@sharedComponents/common/SearchInput';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import { DonorModelShape } from '@sharedComponents/models';

import organizationsQuery from './Organizations/queries/organizationsQuery';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';

const organizationUrl = id => `/donors/${id}`;

const Organizations = ({ history }) => {
  const [textFilter, setTextFilter] = useLocalStorage<string>('organizations.textFilter', '');

  const [pageIndex, setPageIndex] = useState(0);
  const { data, isLoading } = organizationsQuery.useDonorOrganizations(pageIndex, textFilter);

  const columns: Column[] = [
    {
      id: 'name',
      label: 'Organization Name',
      render: (organization: DonorModelShape) => {
        return (
          <EuiFlexGroup direction="row" gutterSize="none">
            <EuiFlexItem>
              <Link to={organizationUrl(organization.id)} key={organization.id}>
                {organization.name}
              </Link>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {organization.can_review ? (
                <EuiBadge color="default" style={{ marginLeft: '8px' }}>
                  can review
                </EuiBadge>
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }
    }
  ];

  columns.push({
    id: 'created_at',
    label: 'Created At',
    align: 'right',
    render: (organization: DonorModelShape) => {
      return `${formatDate(organization.created_at, 'LL')}`;
    }
  });

  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SearchInput
                value={textFilter}
                placeholder="Organization Name"
                onValueChange={setTextFilter}
                isLoading={isLoading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <AbstractListing
            isLoading={isLoading}
            columns={columns}
            records={data?.results}
            total={data?.total}
            onRowClick={organization => {
              history.push(organizationUrl(organization.id));
            }}
            pageIndex={pageIndex}
            onPageChange={pageIndex => setPageIndex(pageIndex)}
            pageSize={50}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

const OrganizationsWithRouter = withRouter(Organizations);

export default OrganizationsWithRouter;
