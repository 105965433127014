import React from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { TextField } from '@sharedComponents/formElements';
import FileField from '@sharedComponents/formElements/combined/FileField';
import { ScholarshipModelDataSchema, ScholarshipModelSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep3 = () => {
  const { watch } = useFormContext();

  const prevStepResponse = watch('data.hasOnlineApplication');

  if (prevStepResponse === null) {
    return (
      <EuiText>
        You selected you are unsure if there is an online application form for your scholarship. A Scholar's App team
        member will contact you via phone/email to clarify your application process.
      </EuiText>
    );
  }

  return (
    <EuiFlexGroup direction="column">
      {prevStepResponse === false ? (
        <>
          <EuiFlexItem>
            <FileField
              fieldName={'data.application_example'}
              rowProps={{
                label: "Please upload your scholarship's application",
                helpText: ''
              }}
              fieldProps={{
                accept: '.pdf'
              }}
            />
          </EuiFlexItem>
        </>
      ) : prevStepResponse === true ? (
        <EuiFlexItem>
          <TextField
            fieldName={'apply_url'}
            rowProps={{ label: 'Scholarship Application URL', fullWidth: true }}
            fieldProps={{ fullWidth: true, autoComplete: 'off', placeholder: 'https://' }}
          />
        </EuiFlexItem>
      ) : null}
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep3.validationRules = ScholarshipModelSchema.pick(['apply_url'])
  .shape({
    data: ScholarshipModelDataSchema.pick(['hasOnlineApplication, applicationExample'])
  })
  .test('apply_url', 'Application URL must be a valid URL', value => {
    const hasOnlineApplication = value?.data?.hasOnlineApplication;

    // making apply_url required in case its requried by previous step results
    return !hasOnlineApplication || (value?.apply_url?.length && yup.string().url().isType(value.apply_url));
  });

export default SelfListingScholarshipStep3;
