import React from 'react';

import { EuiFieldTextProps } from '@elastic/eui';
import phoneMask from '@sharedComponents/masks/phoneMask';

import { TextField } from '../';

const PhoneField = ({
  fieldName = 'data.phone',
  label = 'Phone *',
  fieldProps = {}
}: {
  fieldName?: string;
  label?: string;
  fieldProps?: EuiFieldTextProps;
}) => (
  <TextField
    fieldName={fieldName}
    fieldProps={{ fullWidth: true, placeholder: '(000) 000-0000', ...fieldProps, onChange: phoneMask.onChange }}
    rowProps={{ label: label, fullWidth: true }}
  />
);

export default PhoneField;
