import warn from '../base/warn';

export function getPath(object, path) {
  if (object == null || path.length === 0) {
    return object;
  }

  const firstPathSegment = path[0];

  if (path.length === 1) {
    return object[firstPathSegment];
  } else {
    return getPath(object[firstPathSegment], path.slice(1));
  }
}

export function isList(object) {
  return object != null && object.length != null;
}

function clonePathObject(object, field) {
  if (typeof field == 'number') {
    if (isList(object)) {
      return object.slice(0);
    } else {
      if (object != null) {
        warn('There was an object where an array was expected.');
      }
      return [];
    }
  } else {
    return { ...object };
  }
}

export function setPath(object, path, value) {
  let field = path[0];

  const cloned = clonePathObject(object, field);

  if (path.length === 0) {
    warn('Invalid path');
  } else if (path.length === 1) {
    cloned[field] = value;
  } else {
    cloned[field] = setPath(object && object[field], path.slice(1), value);
  }

  return cloned;
}
