const debounceDelay = 150;

export default class Debouncer<T, R> {
  loader: (t: T) => Promise<R>;
  set: (r: R, t: T) => void;
  onError: (e: Error) => void;

  currentGen = 0;
  currentValueGen = 0;

  constructor(loader: (t: T) => Promise<R>, set: (r: R, t: T) => void, onError: (e: Error) => void) {
    this.loader = loader;
    this.set = set;
    this.onError = onError;
  }

  load(params: T, { immediate }: { immediate: boolean } = { immediate: false }) {
    this.currentGen++;
    const genWhenStarted = this.currentGen;

    setTimeout(
      () => {
        if (this.currentGen === genWhenStarted) {
          this.loader(params)
            .then((r: R) => {
              if (this.currentValueGen < genWhenStarted) {
                this.set(r, params);

                this.currentValueGen = genWhenStarted;
              }
            })
            .catch(this.onError);
        }
      },
      immediate ? 0 : debounceDelay
    );
  }
}
