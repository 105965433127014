import React from 'react';

import { EuiText } from '@elastic/eui';
import { HTMLTemplatePagesCount, MAILTYPE_OPTIONS } from '@sharedComponents/interfaces/Mailing.interface';
import { MailingModelShape } from '@sharedComponents/models';

// Hardcoded pricings for Lob since we use custom billing
// Per unit costs
const COSTS = {
  COLORED: {
    usps_first_class: 0.57,
    usps_standard: 0.42
  },
  BLACK_AND_WHITE: {
    usps_first_class: 0.54,
    usps_standard: 0.39
  }
};

const ADDITIONAL_PAGES_COST = {
  COLORED: 0.13,
  BLACK_AND_WHITE: 0.06
};

export default function getMailingPricing(mailingModel: Partial<MailingModelShape>) {
  const costPerOne =
    COSTS[mailingModel.color ? 'COLORED' : 'BLACK_AND_WHITE'][mailingModel.mail_type || MAILTYPE_OPTIONS[0]];

  let totalCost = costPerOne * (mailingModel.recipients?.length || 0);

  const pagesSize = mailingModel.isHTMLtemplateUsed ? HTMLTemplatePagesCount : mailingModel.pdf_page_size;
  if (pagesSize && pagesSize > 1) {
    const totalExtraPagesCost =
      ADDITIONAL_PAGES_COST[mailingModel.color ? 'COLORED' : 'BLACK_AND_WHITE'] *
      (mailingModel.recipients?.length || 0);

    totalCost += totalExtraPagesCost * (pagesSize - 1);
  }

  const formattedCost = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCost);
  return (
    <EuiText size="m">
      <strong>{formattedCost}</strong>
    </EuiText>
  );
}
