import React, { useRef } from 'react';
import { useState } from 'react';
import warn from './warn';

import { ErrorMessage } from './components/ErrorMessage';

interface UseErrorReporterProps {
  title: string;
}

/**
 * Use this class for errors that should not be reported in Sentry
 */
export class UserError extends Error {
  isUserError = true;
}

/** Questinable */
export function useErrorReporter(
  props?: UseErrorReporterProps
): [(e: Error) => void, JSX.Element | null, () => void, (...args: any) => any] {
  const [title, setTitle] = useState((props || { title: null }).title);
  const [error, reallySetError] = useState();

  const errorRef = useRef<any>();

  const setError = (e: any) => {
    errorRef.current = e;
    reallySetError(e);
  };

  const onError = (e: any, newTitle?: string) => {
    // this avoids infinite updates if an error is triggered on render.
    // we must look at a ref because "error" might have an old value
    if (errorRef.current && e.message === errorRef.current.message) {
      return;
    }

    if (!e.isUserError) {
      warn(e);
    }

    setError(e);

    if (newTitle != null) {
      setTitle(newTitle);
    }
  };

  function wrapToTrackErrors<P extends [], R>(
    func: (...args: P) => Promise<R>,
    title: string
  ): (...args: P) => Promise<R | undefined> {
    return async (...args) => {
      try {
        return await func(...args);
      } catch (e) {
        onError(e, title);
      }
    };
  }

  function clearError() {
    setError(null);
  }

  return [
    onError,
    error != null ? <ErrorMessage {...props} title={title} error={error} onClose={clearError} /> : null,
    clearError,
    wrapToTrackErrors
  ];
}
