import React from 'react';

import { NumberField, MaskedTextField } from '@sharedComponents/formElements';
import { ScholarshipModelSchema, ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';
import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import thousandsMask from '@sharedComponents/masks/thousandsMask';

const SelfListingScholarshipStep5 = () => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>How much is the award amount across all of your scholarships?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <MaskedTextField
          fieldName={'award_amount_to'}
          mask={thousandsMask}
          fieldProps={{ fullWidth: true, prepend: '$', autoComplete: 'off' }}
          rowProps={{ label: 'Total Award Amount' }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <NumberField
          fieldName={'data.award_recipients_number'}
          rowProps={{ label: 'Number of Award Recipients' }}
          fieldProps={{
            fullWidth: true,
            autoComplete: 'off'
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep5.validationRules = ScholarshipModelSchema.pick(['award_amount_to']).shape({
  data: ScholarshipModelDataSchema.pick(['award_recipients_number'])
});

export default SelfListingScholarshipStep5;
