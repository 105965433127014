import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import UserModel from '@sharedContract/UserModel';

const { persistAtom } = recoilPersist({
  key: 'auth' // this is used cross service-wide, todo expose
});

export interface LoggedInUserStateType {
  jwt: string | null;
  user?: UserModel; // TODO: better written interface for this type
}

// rename into loggedInUserStateAtom?
export const loggedInUserState = atom<LoggedInUserStateType>({
  key: 'loggedInUserState',

  default: {
    jwt: null,
    user: undefined
  },

  effects_UNSTABLE: [persistAtom]
});
