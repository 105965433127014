import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from '@elastic/eui';

const BasicModal = ({
  closeModal,
  title = '',
  className = '',
  isConfirmation = false,
  children
}: {
  closeModal: (isConfirm?: boolean) => void;
  title?: string;
  className?: string;
  isConfirmation?: boolean;
  children;
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <EuiModal onClose={handleCloseModal} style={{ minWidth: 400 }} className={className}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>{children}</EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup direction="row" gutterSize="s" responsive={false}>
          <EuiFlexItem>
            <EuiButton onClick={handleCloseModal}>Close</EuiButton>
          </EuiFlexItem>
          {isConfirmation ? (
            <EuiFlexItem>
              <EuiButton onClick={() => closeModal(true)} color="primary" fill={true}>
                Confirm
              </EuiButton>
            </EuiFlexItem>
          ) : null}
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default BasicModal;
