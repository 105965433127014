import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { EuiButtonGroup, EuiText } from '@elastic/eui';
import { getApplicationCycleFromDate } from '@sharedComponents/utils/applicationCycleUtils';

/**
 * Application cycles is a requirement from T3557/T3555
 * "Application cycle"= range of dates when a scholarship opens and closes. This is the cycle in which the scholarship is active and accepting student applications. Ex: 10/1/2018 - 6/1/2019
 * "Application deadline"= the last date that a student can apply for the scholarship.
 * Oct of each year is generally when scholarship cycles open, with a deadline of next year. Ex: 10/1/2018 scholarship cycle opens, deadline 6/1/2019
However, sometimes donors open the scholarship cycle at the last min of same year. Ex: 3/1/2019 scholarship cycle opens, deadline 6/1/2019
 */
export default function ApplicationCycleSelect({
  startDate,
  endDate,
  applicationCycle,
  onApplicationCycleSelect
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  applicationCycle?: number | null;
  onApplicationCycleSelect: (number) => void;
}) {
  // We should figure out which years to display as application cycle suggestions
  const [options, setOptions] = useState<number[]>([]);
  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    if (!startDate || !endDate) {
      setOptions([]);
      setIdSelected(null);
      onApplicationCycleSelect(null);
    } else {
      // If both dates represent different years, then its the case
      if (startDate.year() !== endDate.year()) {
        setOptions([startDate.year(), endDate.year()]);
      } else {
        const applicationCycleYear = getApplicationCycleFromDate(endDate.toDate());
        if (applicationCycleYear > moment().year()) {
          setOptions([applicationCycleYear - 1, applicationCycleYear]);
        } else {
          setOptions([applicationCycleYear, applicationCycleYear + 1]);
        }
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (options.length > 1) {
      // initial value if thats edit of scholarships
      if (applicationCycle && options.includes(applicationCycle)) {
        setIdSelected(applicationCycle);
        onApplicationCycleSelect(applicationCycle);
      } else {
        setIdSelected(options[1]);
        onApplicationCycleSelect(options[1]);
      }
    }
  }, [options, applicationCycle]);

  if (!options.length || options.length <= 1) {
    return null;
  }

  return (
    <>
      <label>Application Cycle</label>
      <EuiButtonGroup
        buttonSize="m"
        type="single"
        legend="Application Cycle"
        color="primary"
        options={options.map(year => ({
          id: year.toString(),
          label: year.toString()
        }))}
        idSelected={idSelected ? idSelected.toString() : ''}
        onChange={optionId => {
          setIdSelected(+optionId);
          onApplicationCycleSelect(+optionId);
        }}
        isFullWidth
      />
      <EuiText size="xs">
        All the applications submitted during that date range, will be assigned to the <strong>{idSelected}</strong>{' '}
        application cycle.
      </EuiText>
    </>
  );
}
