import React from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';

import AbstractDeclineStep from './AbstractDeclineStep';
import { NOTIFY_DECLINED_STEPS } from './config';

const NotifyDeclinedStepEmailPreview: AbstractDeclineStep = {
  headerTitle: 'Preview and Send',
  actions(changeStep) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.EMAIL_DRAFTING)}>
          Edit Email
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.NOTIFY_COMPLETED)} fill>
          Send
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ compiledEmailContent }) {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          Review the email and click "Send" if everything is correct. <br /> If you'd like to make edits to the email,
          click "Edit Email".
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText grow={false}>
            <pre>
              <code dangerouslySetInnerHTML={{ __html: compiledEmailContent }}></code>
            </pre>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default NotifyDeclinedStepEmailPreview;
