import { max } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Axis, BarSeries, Chart, Settings } from '@elastic/charts';
import { ProjectionClickListener } from '@elastic/charts/dist/specs/settings';
import { Datum } from '@elastic/charts/dist/utils/common';
import { EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';

export default function NQSChart({
  data,
  projectionClickHandler
}: {
  data: Datum[];
  projectionClickHandler?: ProjectionClickListener;
}) {
  const [yMax, setYMax] = useState<number | undefined>(undefined);

  useEffect(() => {
    let newYMax: number | undefined = undefined;

    if (data?.length) {
      newYMax = max(data.map(item => item[1]));
      newYMax = newYMax! + Math.ceil(newYMax! / 5); // adding 20% to max Y-Axis number, but not less than 1
    }

    setYMax(newYMax || 1);
  }, [data]);

  return (
    <Chart>
      <Settings
        onProjectionClick={projectionClickHandler ? projectionClickHandler : undefined}
        theme={EUI_CHARTS_THEME_LIGHT.theme}
      />
      <BarSeries id="students" data={data} xAccessor={0} yAccessors={[1]} />
      <Axis
        id="bottom-axis"
        position="bottom"
        showGridLines={false}
        style={undefined}
        tickFormat={date => moment(date).format('MM/DD/YY')}
        title={'Date'}
      />
      <Axis
        id="left-axis"
        position="left"
        style={undefined}
        integersOnly={true}
        title={'Students'}
        domain={yMax ? { min: 0, max: yMax } : undefined}
      />
    </Chart>
  );
}
