import React, { useEffect } from 'react';

import { getServiceUrl, getUserHomeUrl } from '@sharedClients/main';
import UserClient from '@sharedClients/UserClient';
import UserModel, { LoginOptions } from '@sharedContract/UserModel';

import { UserError } from '../../base/useErrorReporter';
import warn from '../../base/warn';
import { LoginFormProps } from './LoginSignupForm/config';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { Fetch } from '@sharedComponents/utils/wrappedFetch';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import { useAuth } from '@sharedComponents/selectors/useAuth';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import DonorsInvitationModal from '@sharedComponents/modals/DonorsInvitationModal';

type RenderChildren = (
  jwt: string | null,
  fetch: Fetch,
  options: {
    userClient: UserClient;
    onLogout: () => void;
    user: UserModel;
  }
) => JSX.Element;

type LoginFormComponentType = (props: LoginFormProps) => JSX.Element;

type LoginContainerProps = {
  loginFormComponent: LoginFormComponentType;
  userType: string;
  renderChildren: RenderChildren;
  fetch?: Fetch; // !deprecated
  help?: string | JSX.Element; // ! is not anymore used?
  initialJwt?: string;
  initialUser?: UserModel;
  baseUrl?: string;
  isLoginOptional?: boolean;
};

/**
 * Wraps a component to display only if there is a logged in user. If not, displays a login form.
 * TODO: This has to be sorted out properly
 * ? Assuming this eventually will become more like a HOC wrapper, instead of login component rendering
 */
function LoginContainer({ loginFormComponent, userType, renderChildren, help, isLoginOptional }: LoginContainerProps) {
  const authState = useAuth();

  const createModal = useModalCreate();

  const router = useRouter();

  // also executed after signup
  function onLoginExecuter({ jwt, user, loginOptions }: { jwt: string; user: UserModel; loginOptions?: LoginOptions }) {
    if (!redirectIfWrongDomain(user, jwt)) {
      // proccess redirect url in case it was passed into params(case when user has to be returned to landing/homepage)
      const _urlParams = new URLSearchParams(window.location.search);
      const returnUrl = _urlParams.get('returnUrl');
      if (returnUrl) {
        authState.loginExecute(jwt, user);

        // then redirect
        window.location.replace(returnUrl);
        return;
      }

      authState.loginExecute(jwt, user);

      if (loginOptions?.startingUrl?.length) {
        router.push(loginOptions?.startingUrl);
      } else if (loginOptions?.scholarshipsToInvite?.length) {
        createModal(({ closeModal }) => {
          return <DonorsInvitationModal scholarships={loginOptions.scholarshipsToInvite!} closeModal={closeModal} />;
        });
      }
    }
  }

  /** @deprecated */
  function onLogout() {
    authState.logoutExecute();
  }

  function onEvent(event: MessageEvent) {
    const { data, origin } = event;

    if (isIterable(data)) {
      const expectedOrigin = getServiceUrl('donors');

      if (
        origin === expectedOrigin ||
        // system tests
        origin === getCurrentDomain()
      ) {
        const [command, userAndJwt] = data;

        // ? assuming thats the case for form preview?
        if (command === 'login') {
          onLoginExecuter(userAndJwt);
        }
      }
    }
  }

  // ? figure out use cases
  useEffect(() => {
    window.addEventListener('message', onEvent, false);

    if (window.opener) {
      window.opener.postMessage('ready', getServiceUrl('donors'));
    }
  }, []);

  const userClient = useUserClient();
  const applicationClient = useApplicationClient();

  useEffect(() => {
    if (document.location.hash.length) {
      logInUserFromHash(userClient, (...p) => {
        onLoginExecuter(...p);
      });
    }
  }, []);

  const LoginFormComponent: LoginFormComponentType = loginFormComponent;

  // ? get rid of this?
  // setSystemTestHook({
  //   userClient,
  //   setJwt: (jwt: string | null, newUser: UserModel | null) => setUserJwt([newUser || user, jwt]),
  //   user: loggedInUser.user
  // });

  const urlParams = new URLSearchParams(document.location.search);

  const props: any /** ex LoginFormProps */ = {
    userClient,
    onLogin: onLoginExecuter,
    onLogout,
    help,
    userType: userType as any,
    applicationClient,
    email: urlParams.get('email') || undefined,
    phone: urlParams.get('phone') || undefined,
    name: urlParams.get('name') || undefined,
    state: urlParams.get('state') || undefined,
    schoolId: parseInt(urlParams.get('schoolid') || '') || undefined,
    schoolName: urlParams.get('schoolname') || undefined,
    token: urlParams.get('token') || undefined
  };

  if (authState.isLoggedIn || isLoginOptional) {
    return (
      <React.Fragment>
        {renderChildren(authState.jwt, fetch, {
          ...props,
          user: authState.user
        })}
      </React.Fragment>
    );
  }

  return <LoginFormComponent {...props} />;
}

/**
 * Allows the system tests to simulate the JWT expiring.
 * See system-test/cypress/integration/applications.spec.js
 */
// function setSystemTestHook({
//   userClient,
//   setJwt,
//   user
// }: {
//   userClient: UserClient;
//   setJwt: (jwt: string | null, user: UserModel | null) => void;
//   user?: UserModel | null;
// }) {
//   window['expireJwt'] = async () => {
//     const expiredJwt = await userClient.getExpiredJwt();

//     setJwt(expiredJwt, null);
//   };

//   window['logout'] = () => {
//     setJwt(null, null);
//   };

//   window['getUser'] = () => user;

//   window['setJwt'] = setJwt;
// }

function getCurrentDomain() {
  return document.location.protocol + '//' + document.location.host;
}

function logInUserFromHash(userClient: UserClient, onLogin: ({ jwt, user }: { jwt: string; user: UserModel }) => any) {
  let hash = document.location.hash;

  if (hash.startsWith('#')) {
    hash = hash.substr(1);
  }

  const hashComponents = hash.split('&');
  const jwtComponent = hashComponents.find(c => c.startsWith('jwt-'));

  if (jwtComponent) {
    const jwt = jwtComponent.substr(4);

    if (jwt) {
      userClient
        .validateJwt(jwt)
        .then(
          ({ valid, user }: { valid: boolean; user: UserModel }) => {
            if (valid) {
              onLogin({ jwt, user });
            } else {
              // eslint-disable-next-line no-console
              console.warn('Invalid JWT provided in hash');
            }
          },
          (e: Error) => {
            const message = `While logging in from hash: ${e.message}`;

            const logE = e['isUserError'] ? new UserError(message) : new Error(message);
            logE['fingerprint'] = e['fingerprint'];

            warn(logE);
          }
        )
        .then(() => {
          // clear the hash so we don't show the JWT
          history.replaceState(null, '', ' ');
        });
    }
  }
}

function redirectIfWrongDomain(user: UserModel, jwt: string) {
  const onDomain = document.location.host;
  const shouldBeDomain = getHost(getUserHomeUrl(user.type));

  const urlSearchParams = new URLSearchParams(document.location.search);
  const ignoreDomain = urlSearchParams.get('ignoreDomain') == 'true';

  if (shouldBeDomain !== onDomain && !ignoreDomain) {
    // if we do a redirect in cypress, cypress won't be able to clear the local storage of the new
    // domain. this can be super confusing. so don't do unexpected redirects
    if (navigator.userAgent.includes('Cypress') && !window['redirectExpected']) {
      const message = `User ${user.name} of type ${user.type} is on wrong domain (${onDomain} rather than ${shouldBeDomain}).`;

      throw new Error(message);
    }
    const redirect = window['redirect'] || ((url: string) => (window.location.href = url));

    const newUrl = new URL(getUserHomeUrl(user.type));

    newUrl.pathname = document.location.pathname;
    newUrl.hash = '#jwt-' + jwt;

    redirect(newUrl.toString());
    return true;
  }

  return false;
}

function getHost(url: string) {
  return new URL(url).host;
}

function isIterable(obj: any) {
  return typeof obj[Symbol.iterator] === 'function';
}

export default LoginContainer;
