import { get } from 'lodash';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@sharedComponents/formElements';
import { DELIVERABILITY_STATUS, LobAddressVerificationError } from '@sharedComponents/interfaces/Mailing.interface';
import {
  AddressVerificationType,
  MailingAddressSchema,
  MailingAddressType
} from '@sharedComponents/schemas/MailingModelSchema';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

const EditAddressModal = ({
  title,
  onModalSubmit,
  record
}: {
  title;
  onModalSubmit: (verifiedAddress?: AddressVerificationType) => void;
  record: AddressVerificationType;
}) => {
  const userClient = useUserClient();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const formMethods = useForm<MailingAddressType>({
    reValidateMode: 'onChange',
    resolver: yupResolver(MailingAddressSchema),
    defaultValues: {
      recipient: get(record, 'recipient', ''),
      primary_line: get(record, 'primary_line', ''),
      secondary_line: get(record, 'secondary_line', ''),
      city: get(record, 'address.city', ''),
      state: get(record, 'address.state', ''),
      zip_code: get(record, 'address.zip_code', '')
    }
  });

  const onCancelHandler = () => {
    onModalSubmit();
  };

  const onSubmitHandler = formMethods.handleSubmit(
    async (entry: MailingAddressType) => {
      setIsLoading(true);

      const validationResult = await userClient.revalidateMailingAddress(entry);
      if ((validationResult as LobAddressVerificationError).error) {
        setError((validationResult as LobAddressVerificationError).error?.message);
      } else {
        // validated record
        if ((validationResult as AddressVerificationType).deliverability === DELIVERABILITY_STATUS.NOT_OK) {
          setError('Provided address was recognized, but identified as undeliverable');
        } else {
          setError('');
          onModalSubmit(validationResult as AddressVerificationType);
        }
      }

      setIsLoading(false);
    },
    e => {
      // eslint-disable-next-line no-console
      console.error('submit error:', e);
    }
  );

  return (
    <EuiModal onClose={onCancelHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <EuiText size="s">Edit recipient information to pass validation.</EuiText>
            <EuiSpacer size="s" />
          </EuiFlexItem>
          <EuiFlexItem>
            <FormProvider {...formMethods}>
              <EuiFlexGroup direction="column" gutterSize="s">
                <EuiFlexItem>
                  <TextField fieldName="recipient" rowProps={{ label: 'Recipient', fullWidth: true }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <TextField fieldName="primary_line" rowProps={{ label: 'Primary Line', fullWidth: true }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <TextField fieldName="secondary_line" rowProps={{ label: 'Secondary Line', fullWidth: true }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <TextField fieldName="city" rowProps={{ label: 'City', fullWidth: true }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <TextField fieldName="state" rowProps={{ label: 'State', fullWidth: true }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <TextField fieldName="zip_code" rowProps={{ label: 'ZIP', fullWidth: true }} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </FormProvider>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiSpacer />
            <EuiText color="danger" size="s">
              {error}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onCancelHandler} size="s" disabled={isLoading}>
          Cancel
        </EuiButtonEmpty>
        <EuiButton color="primary" onClick={onSubmitHandler} size="s" isLoading={isLoading}>
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditAddressModal;
