import { parseInt } from 'lodash';
import { Route, Switch } from 'react-router-dom';

import ScholarshipApplicationsPage from '@donors/pages/ScholarshipApplicationsPage';
import RecurringAwardsSetup from '@donors/routes/RecurringAwardsSetup';
import AddUser from '@donors/users/AddUser';
import User from '@donors/users/User';
import Users from '@donors/users/Users';
import Application from '@sharedComponents/Application/Application';
import { USER_TYPE } from '@sharedComponents/constants';
import Scholarship from '@sharedComponents/Scholarship';
import AddScholarship from '@sharedComponents/Scholarship/AddScholarship';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { DonorUserData } from '@sharedContract/UserModel';

import Applications from '../../applications/Applications';
import { ErrorMessage } from '../../base/components/ErrorMessage';
import Donor from '../../donor/Donor';
import EditDonorPage from '../../donor/EditDonorPage';
import AddForm from '../../form/AddForm';
import Form from '../../form/Form';
import Questions from '../../questions/Questions';
import DonorHomepage from '../../routes/DonorHomepage';
import FormBuilder from '../../routes/FormBuilder';
import FormReview from '../../routes/FormReview';
import Forms from '../../routes/Forms';
import Mailings from '../../routes/Mailings';
import Organizations from '../../routes/Organizations';
import ScholarshipListing from '../../routes/ScholarshipListing';
import StandaloneApplication from '../../routes/StandaloneApplication';
import StandaloneApplications from '../../routes/StandaloneApplications';
import ScholarshipEntryFormContainer from '../../scholarship-entry/ScholarshipEntryFormContainer';
import Scholarships from '../../scholarships/Scholarships';
import ScholarshipsToCheck from '../../scholarshipsToCheck/ScholarshipsToCheck';
import TranscriptRequestForms from '../../transcriptRequestForms/TranscriptRequestForms';
import RecurringAwardUserView from '@donors/routes/RecurringAwardUserView';

export function ContentBody({ jwt, onLogout }) {
  const user = useLoggedInUser();

  if (!user) {
    return null;
  }

  const isDonorVerified = (user.data as DonorUserData)?.isVerified;

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={({ history }) => {
          const urlParams = new URLSearchParams(document.location.search);
          if (user.type === 'donor' && !(user.data as DonorUserData)?.isPremium) {
            return <DonorHomepage />;
          } else if (user.type !== USER_TYPE.REVIEWER && user.type !== USER_TYPE.DATAENTRY) {
            return <Applications history={history} filter={urlParams.get('filter')} />;
          } else {
            history.push('/scholarships');
          }
        }}
      />
      <Route
        path="/applications/:id"
        render={({ match, history }) => (
          <>
            <Application applicationId={parseInt(match.params.id)} />
          </>
        )}
      />
      <Route exact path="/formReview" render={() => <FormReview user={user} jwt={jwt} />} />
      <Route exact path="/forms" render={() => <Forms />} />
      <Route
        exact
        path="/forms/add"
        render={() => {
          const urlParams = new URLSearchParams(document.location.search);

          return <AddForm onLogout={onLogout} baseOnForm={urlParams.get('basedon')} user={user} />;
        }}
      />
      <Route
        exact
        path="/forms/:id"
        render={({ match }) => <Form onLogout={onLogout} user={user} formId={parseInt(match.params.id)} />}
      />
      <Route
        exact
        path="/scholarships"
        render={() => (
          <>
            <Scholarships user={user} jwt={jwt} onLogout={onLogout} />
          </>
        )}
      />
      <Route exact path="/organizations" render={() => <Organizations />} />
      <Route
        exact
        path={['/mailings', '/mailings/create', '/mailings/view/:id']}
        render={() => {
          return (
            <>
              <Route exact path="/mailings" render={() => <Mailings />} />
              <Route exact path="/mailings/create" render={() => <Mailings.Create />} />
              <Route exact path="/mailings/view/:id" render={({ match }) => <Mailings.View id={match.params.id} />} />
            </>
          );
        }}
      />
      <Route exact path="/scholarships/add" render={() => <AddScholarship user={user} />} />

      <Route
        exact
        path="/scholarships/:id"
        render={childProps => <Scholarship onLogout={onLogout} {...{ ...childProps }} />}
      />
      <Route
        exact
        path="/scholarships/drafts/:id"
        render={childProps => <Scholarship onLogout={onLogout} {...{ ...childProps, isDraft: true }} />}
      />
      <Route
        exact
        path="/scholarships/:id/applications"
        render={({ match, history }) => (
          <ScholarshipApplicationsPage
            onLogout={onLogout}
            user={user}
            jwt={jwt}
            history={history}
            scholarshipId={parseInt(match.params.id)}
          />
        )}
      />
      <Route
        exact
        path="/donors/:id"
        render={({ match, history }) => (
          <Donor onLogout={onLogout} user={user} history={history} donorId={parseInt(match.params.id)} />
        )}
      />
      <Route
        exact
        path="/donors/:id/edit"
        render={({ match, history }) => (
          <EditDonorPage onLogout={onLogout} user={user} history={history} donorId={parseInt(match.params.id)} />
        )}
      />
      <Route
        exact
        path="/questions"
        render={() => (
          <Questions
            decreaseUnreadQuestions={() => {
              // eslint-disable-next-line no-console
              console.log('todo');
            }}
            onLogout={onLogout}
            user={user}
          />
        )}
      />
      <Route
        exact
        path="/users/add/:type"
        render={({ match }) => <AddUser onLogout={onLogout} user={user} type={match.params.type} />}
      />
      <Route
        path="/users/:type"
        render={({ match, history }) =>
          isFinite(parseInt(match.params.type)) ? (
            <User onLogout={onLogout} user={user} jwt={jwt} userId={parseInt(match.params.type)} history={history} />
          ) : (
            <Users onLogout={onLogout} user={user} jwt={jwt} type={match.params.type} />
          )
        }
      />
      <Route exact path="/users" render={() => <Users jwt={jwt} onLogout={onLogout} user={user} />} />
      <Route
        exact
        path="/transcriptRequestForms"
        render={() => <TranscriptRequestForms onLogout={onLogout} user={user} />}
      />
      <Route exact path="/sclrsToCheck" render={() => <ScholarshipsToCheck onLogout={onLogout} user={user} />} />
      <Route
        exact
        path={['/scholarship-entry', '/scholarship-entry/:id', '/scholarship-entry/:id/:step']}
        render={({ match }: { match: { params?: { id?: string; step?: string } } }) => {
          return (
            <ScholarshipEntryFormContainer
              draftID={match.params?.id ? parseInt(match.params.id) : 0}
              initialStep={match.params?.step ? parseInt(match.params?.step) : 0}
            />
          );
        }}
      />
      <Route
        exact
        path={['/scholarship-self-listing', '/scholarship-self-listing/:id']}
        render={({ match }: { match: { params?: { id?: string } } }) => {
          return <ScholarshipListing draftID={match.params?.id ? parseInt(match.params?.id) : null} />;
        }}
      />
      {user.type === USER_TYPE.ADMIN || (user.type === USER_TYPE.DONOR && isDonorVerified) ? (
        <Route
          exact
          path={['/scholarship-listing/:id']}
          render={({ match }: { match: { params: { id: string } } }) => {
            return <ScholarshipListing scholarshipID={parseInt(match.params.id)} />;
          }}
        />
      ) : null}

      <Route exact path="/form-builder/:id" render={({ match }) => <FormBuilder formID={match.params.id} />} />
      <Route
        exact
        path="/standalone-application/:scholarshipID/:applicationID?"
        render={({ match }) => (
          <StandaloneApplication
            scholarshipID={parseInt(match.params.scholarshipID)}
            applicationID={match.params.applicationID ? parseInt(match.params.applicationID) : null}
          />
        )}
      />
      <Route
        exact
        path="/standalone-applications/:scholarshipID"
        render={({ match }) => <StandaloneApplications scholarshipID={parseInt(match.params.scholarshipID)} />}
      />
      <Route
        exact
        path="/recurring-awards/:scholarshipID"
        render={({ match }) => <RecurringAwardsSetup scholarshipID={parseInt(match.params.scholarshipID)} />}
      />
      <Route
        exact
        path="/recurring-awards/:scholarshipID/:recordID"
        render={({ match }) => (
          <RecurringAwardUserView
            scholarshipID={parseInt(match.params.scholarshipID)}
            recordID={parseInt(match.params.recordID)}
          />
        )}
      />
      <Route render={() => <ErrorMessage error={new Error('Invalid address.')} />} />
    </Switch>
  );
}
