import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiLoadingSpinner, EuiToolTip } from '@elastic/eui';
import { AllEventsData, EVENT_TYPE } from '@sharedComponents/interfaces/Events.interface';
import { get, some } from 'lodash';
import { DraftScholarshipModelShape, ScholarshipModelShape } from '@sharedComponents/models';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { getScholarshiListingURL } from '@sharedComponents/utils/getScholarshiListingURL';
import { DonorUserData } from '@sharedContract/UserModel';

export default function PageViewsBlock({
  selectedScholarship,
  eventsData,
  dateRangeFilter,
  isLoading
}: {
  selectedScholarship: ScholarshipModelShape | null;
  eventsData: AllEventsData | undefined;
  dateRangeFilter: number;
  isLoading: boolean;
}) {
  const loggedInUser = useLoggedInUser();
  const hasUnpublishedDrafts = some(
    selectedScholarship?.unpublishedDrafts || [],
    (draftScholarship: DraftScholarshipModelShape) => draftScholarship.user_id === loggedInUser!.id
  );

  const [value, setValue] = useState(0);

  useEffect(() => {
    const newValue = get(eventsData, `events.${EVENT_TYPE.SCHOLARSHIP_PAGE_VIEW}`) || 0;

    setValue(newValue);
  }, [eventsData]);

  const isLifetimeRangeSelected = dateRangeFilter === 3650;
  const isVerified = (loggedInUser?.data as DonorUserData)?.isVerified;

  return (
    <div className={'DonorHomepage__statBlock pageViewsBlock'}>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem className={'pageViewsBlock__image'}>
          <img src="/images/scholarship-listing-template.svg" alt="scholarship-listing-template" />
        </EuiFlexItem>
        <EuiFlexItem className={'pageViewsBlock__statData'}>
          <EuiFlexGroup responsive={false} direction={'column'}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup responsive={false} direction={'column'} className={'DonorHomepage__statInfo'}>
                <EuiFlexItem className={'DonorHomepage__statNumber'}>
                  {isLoading ? <EuiLoadingSpinner size={'l'} /> : <>{value}</>}
                </EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statName'}>Page Views</EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statDescription'}>
                  The{isLifetimeRangeSelected && ' total'} number of times your Scholar's App listing page was viewed
                  {!isLifetimeRangeSelected && ` in the past ${dateRangeFilter} day${dateRangeFilter === 1 ? '' : 's'}`}
                  .
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem className={'pageViewsBlock__links'}>
              {!!selectedScholarship && (
                <EuiFlexGroup responsive={false}>
                  <EuiFlexItem>
                    {!isVerified ? (
                      <>
                        <EuiToolTip content="Your account is awaiting verification.">
                          <EuiLink disabled={true}>Edit your listing</EuiLink>
                        </EuiToolTip>
                      </>
                    ) : hasUnpublishedDrafts ? (
                      <>
                        <EuiToolTip content="You have already submitted changes for publication.">
                          <EuiLink disabled={true}>Edit your listing</EuiLink>
                        </EuiToolTip>
                      </>
                    ) : (
                      <>
                        <EuiLink href={`/scholarship-listing/${selectedScholarship.id}`}>Edit your listing</EuiLink>
                      </>
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiLink href={getScholarshiListingURL(selectedScholarship)} target="_blank">
                      View your listing
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
