import { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { cloneDeep, set } from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import RichTextEditor from '@sharedComponents/common/RichTextEditor/RichTextEditor';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import { TextField } from '@sharedComponents/formElements';
import FileField from '@sharedComponents/formElements/combined/FileField';
import useLoading from '@sharedComponents/hooks/useLoading';
import { transformHTMLforLOB } from '@sharedComponents/interfaces/Mailing.interface';
import { MailingTemplateVariablesType } from '@sharedComponents/schemas/MailingModelSchema';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

import PrerenderView from './HTMLTemplateSetup/PrerenderView';
import TemplatePreviewModal from './HTMLTemplateSetup/TemplatePreviewModal';

const HTMLTemplateSetup = ({ nextStepHandler }) => {
  const formContext = useFormContext();
  const loadingState = useLoading();
  const createModal = useModalCreate();
  const applicationClient = useApplicationClient();
  const userClient = useUserClient();

  // lob test response
  const [prerenderingResponse, setPrerenderingResponse] = useState<{
    errors: boolean;
    results: Array<any>;
  }>();

  const [mainLetterContent, setMainLetterContent] = useState<RawDraftContentState>({
    blocks: [],
    entityMap: {}
  });

  const [eligibilityContent, setEligibilityContent] = useState<RawDraftContentState>({
    blocks: [],
    entityMap: {}
  });

  const [isPrerenderComplete, setIsPrerenderComplete] = useState(false);

  const _html_variables: MailingTemplateVariablesType | undefined = useWatch({ name: 'html_variables' });
  const dirtyIsValid =
    _html_variables &&
    Object.values(_html_variables).every(str => {
      return !!str;
    });

  const qRFileAsImg = fileData => {
    return `<img src="${fileData}" />`;
  };

  // transform data into suitable format
  const handleTemplateSubmit = async () => {
    loadingState.startLoading();

    const letter_content = transformHTMLforLOB(draftToHtml(mainLetterContent));
    if (letter_content.length) {
      formContext.setValue('html_variables.letter_content', letter_content);
    }

    const eligibility_text = transformHTMLforLOB(draftToHtml(eligibilityContent));
    if (eligibility_text.length) {
      formContext.setValue('html_variables.eligibility_text', eligibility_text);
    }

    // at this point watched context is not yet updated, so we predict that update
    if (_html_variables) {
      const previewData: MailingTemplateVariablesType = cloneDeep(_html_variables);
      set(previewData, 'letter_content', letter_content);
      set(previewData, 'eligibility_text', eligibility_text);

      const qrFileData = await applicationClient.createQRcode(
        `${_html_variables.qr_code_url}?r=TEST KEYS DO NOT VERIFY ADDRESSES&c=SAN FRANCISCO&s=CA&mid=0`
      );

      const qrFileImg = qRFileAsImg(qrFileData);
      set(previewData, 'qr_code', qrFileImg);

      createModal(({ closeModal }) => {
        const onModalSubmitHandler = async (isOk: boolean) => {
          closeModal();

          if (isOk) {
            // we agreed on prerender - now lets do real rendering preview
            const previewDetails = await userClient.submitPreviewMailing(previewData);
            setPrerenderingResponse(previewDetails);
          }

          loadingState.stopLoading();
        };

        return <TemplatePreviewModal onModalSubmit={onModalSubmitHandler} previewData={previewData} />;
      });
    }
  };

  if (prerenderingResponse) {
    // final preview for template, rendered by LOB
    if (prerenderingResponse.errors) {
      return (
        <>
          <EuiFlexItem>
            <EuiCallOut title={'There was an error during rendering'} color="danger" iconType="alert">
              {JSON.stringify(prerenderingResponse.results)}
            </EuiCallOut>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton onClick={() => setPrerenderingResponse(undefined)}>Edit Template</EuiButton>
          </EuiFlexItem>
        </>
      );
    }

    const result = prerenderingResponse.results[0];
    const { url } = result;

    return (
      <>
        <EuiFlexItem>
          <EuiFlexGroup direction="column" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiText>
                Your template has passed validation and now being processed by LOB. <br />
                Please, wait for confirmation to ensure the template has been successfully processed (up to 5 minutes).
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <PrerenderView url={url} callbackOnComplete={() => setIsPrerenderComplete(true)} />
            </EuiFlexItem>
            <EuiFlexItem>If the template is correct, click "Upload Mailing Addresses"</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" responsive={false}>
            <EuiFlexItem>
              <EuiButton onClick={() => setPrerenderingResponse(undefined)}>Edit Template</EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton onClick={nextStepHandler} color="success" isDisabled={!isPrerenderComplete}>
                Upload Mailing Addresses
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </>
    );
  }

  return (
    <>
      <EuiFlexItem>
        <EuiText>All fields are required. Configure all template settings before proceeding.</EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size="xs">
          <strong>Cover Letter Text: *</strong>
        </EuiText>
        <RichTextEditor
          value={mainLetterContent}
          onContentStateChange={mainLetterContentState => {
            setMainLetterContent(mainLetterContentState);
          }}
          config={{ hideWordsCounter: true, minHeight: 300, options: ['inline'] }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <FileField
          fieldName={'html_variables.logo_img_ID'}
          rowProps={{
            label: 'Scholarship Logo Image (will be resized to 164 pixes height) *',
            fullWidth: true
          }}
          fieldProps={{
            accept: '.png',
            isTempFile: true,
            tempFileOptions: {
              restrictImageHeight: 164
            }
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'html_variables.scholarship_name'}
          rowProps={{
            label: 'Scholarship Name *',
            fullWidth: true
          }}
          fieldProps={{
            fullWidth: true
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'html_variables.qr_code_url'}
          rowProps={{
            label: 'URL that QR code will redirect to *',
            fullWidth: true
          }}
          fieldProps={{
            fullWidth: true,
            placeholder: 'https://'
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'html_variables.who_text'}
          rowProps={{
            label: 'Who is the scholarship for? *',
            fullWidth: true
          }}
          fieldProps={{
            fullWidth: true
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size="xs">
          <strong>Eligibility: *</strong>
        </EuiText>
        <RichTextEditor
          value={eligibilityContent}
          onContentStateChange={contentState => {
            setEligibilityContent(contentState);
          }}
          config={{ hideWordsCounter: true, minHeight: 300, options: ['inline'] }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiButton onClick={handleTemplateSubmit} isLoading={loadingState.isLoading} isDisabled={!dirtyIsValid}>
          Submit & Preview Template
        </EuiButton>
      </EuiFlexItem>
    </>
  );
};

export default HTMLTemplateSetup;
