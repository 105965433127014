import React from 'react';
import * as yup from 'yup';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLink, EuiTitle } from '@elastic/eui';
import CheckboxField from '@sharedComponents/formElements/simpleFields/CheckboxField';

import AbstractEntryStep from './AbstractEntryStep';

const EntryStepQASecond: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    isReadyForPublishing: yup.boolean()
  }),
  render: function (_rowProps, formMethods) {
    const id = formMethods.getValues('id');
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>QA Confirmation</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCallOut title="You're about to confirm draft scholarship entry information!" iconType="pagesSelect">
            <p>
              Please, take a look on all previously filled steps and make sure that they contain correct data which is
              qualitative enough to be published.
            </p>
            <p>
              <EuiLink href={`/scholarship-entry/${id}/0`}>Start review</EuiLink>
            </p>
            <p>Once scholarship data is verified, confirm that it's ready for publication.</p>
            <CheckboxField
              fieldName="isReadyForPublishing"
              fieldProps={{ label: 'Scholarship is ready for publishing' }}
            />
          </EuiCallOut>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepQASecond;
