import React from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexItem } from '@elastic/eui';
import {
  CheckboxField,
  ComboboxField,
  SchoolLookupField,
  NumberField,
  PhoneField,
  TextField
} from '@sharedComponents/formElements';

function CounselorFormFields({ formMethods }) {
  const { watch } = useFormContext();

  const registrationSource = watch('data.source');

  return (
    <>
      <EuiFlexItem>
        <TextField
          fieldName={'data.name'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'First name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.lastName'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Last name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <SchoolLookupField fieldName={'data.school'} formMethods={formMethods} isRequired />
      </EuiFlexItem>
      <EuiFlexItem>
        <PhoneField />
      </EuiFlexItem>
      <EuiFlexItem>
        <NumberField
          fieldName={'data.seniorCount'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Number of seniors in your school', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.postHigh'}
          fieldProps={{ label: "I'm a post-high / college counselor" }}
          rowProps={{}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.smsnofification'}
          fieldProps={{ label: 'Would you like to receive text message notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.emailNotification'}
          fieldProps={{ label: 'Would you like to receive email notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        {/** TODO: DRY */}
        <ComboboxField
          fieldName={'data.source'}
          options={['Social Media', 'Google', 'Friend', 'Email', 'Other']}
          fieldProps={{ fullWidth: true, placeholder: 'Select one', singleSelection: true }}
          rowProps={{ label: 'How did you hear about us? *', fullWidth: true }}
        />
        {registrationSource === 'Other' ? (
          <TextField
            fieldName={'data.source_other'}
            fieldProps={{ fullWidth: true }}
            rowProps={{ label: 'Please specify how did you hear about us', fullWidth: true }}
          />
        ) : null}
      </EuiFlexItem>
    </>
  );
}

CounselorFormFields.validationRules = yup.object().shape({
  data: yup.object().shape({
    name: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    school: yup
      .object()
      .shape({
        id: yup.number(),
        name: yup.string()
      })
      .required(),
    seniorCount: yup.number(),
    postHigh: yup.boolean(),
    phone: yup.string().label('Phone').required(),
    source: yup.string().label('Source').required(),
    source_other: yup.string().label('Source'), // todo based on source?
    smsnofification: yup.boolean().default(true),
    emailNotification: yup.boolean().default(true)
  })
});

export default CounselorFormFields;
