import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPagination,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  htmlIdGenerator
} from '@elastic/eui';
import { LEFT_ALIGNMENT, RIGHT_ALIGNMENT, CENTER_ALIGNMENT } from '@elastic/eui/lib/services';
import React from 'react';

const MIN_AMOUNT_TO_SHOW_TOTAL = 10;
export interface Column {
  id: string;
  label: string;
  width?: string;
  align?: LEFT_ALIGNMENT | RIGHT_ALIGNMENT | CENTER_ALIGNMENT;
  render: Function;
}

export default function AbstractListing<T>({
  columns,
  isLoading,
  onRowClick,
  records,
  total,
  onPageChange,
  pageSize = 50,
  pageIndex = 0
}: {
  columns: Column[];
  isLoading?: boolean;
  records?: T[];
  onRowClick?: (record: T) => void;
  onPageChange: (pageIndex: number) => void;
  total?: number;
  pageSize?: number;
  pageIndex?: number;
}) {
  const renderHeaderCells = () => {
    const headers: JSX.Element[] = [];

    columns.forEach(column => {
      headers.push(
        <EuiTableHeaderCell
          style={{ width: column.width || 'auto' }}
          key={column.id}
          align={column.align || LEFT_ALIGNMENT}
        >
          {column.label}
        </EuiTableHeaderCell>
      );
    });

    return headers.length ? headers : null;
  };

  const renderRows = () => {
    if (isLoading || !records?.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length}>
            <EuiText size="s" textAlign="center" grow>
              {(isLoading && 'Loading...') || 'No results.'}
            </EuiText>
          </EuiTableRowCell>
        </EuiTableRow>
      );
    }

    const renderRow = (item: T) => {
      const cells = columns.map(column => {
        return (
          <EuiTableRowCell
            textOnly={false}
            key={column.id}
            align={column.align || LEFT_ALIGNMENT}
            mobileOptions={{
              header: column.label
            }}
          >
            {column.render(item)}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow
          key={htmlIdGenerator()()}
          style={{ cursor: onRowClick ? 'pointer' : 'inherit' }}
          onClick={() => (onRowClick ? onRowClick(item) : null)}
        >
          {cells}
        </EuiTableRow>
      );
    };

    const rows: JSX.Element[] = [];

    for (const record of records) {
      rows.push(renderRow(record));
    }

    return rows;
  };

  const pageCount = total ? Math.ceil(total / pageSize) : 0;

  return (
    <EuiFlexGroup justifyContent="spaceAround">
      <EuiFlexItem grow={false}>
        <EuiTable compressed={false} tableLayout="fixed">
          <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>

          <EuiTableBody>{renderRows()}</EuiTableBody>
        </EuiTable>
        <EuiSpacer size="m" />

        <EuiFlexGroup justifyContent="spaceBetween">
          {total && total > MIN_AMOUNT_TO_SHOW_TOTAL && !isLoading ? (
            <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
              <EuiText size="s">
                Total: <strong>{total}</strong>
              </EuiText>
            </EuiFlexItem>
          ) : null}
          {pageCount > 1 && !isLoading ? (
            <EuiFlexItem grow={false}>
              <EuiPagination
                pageCount={pageCount}
                activePage={pageIndex}
                onPageClick={pageIndex => onPageChange(pageIndex)}
              />
            </EuiFlexItem>
          ) : null}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
