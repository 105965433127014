import React from 'react';

import { EuiLoadingContent } from '@elastic/eui';
import { capitalize } from '@sharedComponents/Scholarship/EditScholarship';

function PrivateFormFieldValue({ label }: { label: string }) {
  return (
    <div className="field">
      <label>{capitalize(label)}</label>
      <div className="value">
        <EuiLoadingContent className="m-static" lines={1} />
      </div>
    </div>
  );
}

export default PrivateFormFieldValue;
