import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { ApplicationClient, ScholarshipClient } from '@sharedClients/main';
import UserClient from '@sharedClients/UserClient';
import { loggedInUserState } from '@sharedComponents/atoms/loggedInUserState';
import { wrapToCatch401s } from '@sharedComponents/utils/wrappedFetch';

// for cases when multiple clients needed
export function useAllClients() {
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);

  const clientsFromCallback = useMemo(() => {
    const wrappedFetch = wrapToCatch401s(window.fetch, () =>
      setLoggedInUser(() => ({
        jwt: null,
        user: undefined
      }))
    );

    const userClient = new UserClient(wrappedFetch, null, loggedInUser.jwt);
    const scholarshipClient = new ScholarshipClient(
      wrappedFetch,
      null,
      loggedInUser.jwt as any /** fixme ts version */
    );
    const applicationClient = new ApplicationClient(wrappedFetch, null, loggedInUser.jwt, null);

    return { userClient, scholarshipClient, applicationClient };
  }, [loggedInUser.jwt]);

  return clientsFromCallback;
}
