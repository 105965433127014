import React from 'react';
import './PageContentWrapper.scss';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection
} from '@elastic/eui';

/**
 * Optional wrapper for content, mostly for root level routes
 * Can control and limit content representation(width/heigth/etc)
 */
export default function PageContentWrapper({
  children,
  centeredContent,
  fullHeight,
  className,
  restrictWidth = true
}: {
  children: JSX.Element;
  fullHeight?: boolean;
  centeredContent?: boolean;
  restrictWidth?: boolean;
  className?: string;
}) {
  const wrappedIntoFullHeight = page =>
    fullHeight ? <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>{page}</div> : page;

  return wrappedIntoFullHeight(
    <EuiPage className={className || ''} restrictWidth={restrictWidth} grow={fullHeight}>
      <EuiPageBody component="div">
        <EuiPageContent paddingSize="none" hasShadow={false} verticalPosition={centeredContent ? 'center' : undefined}>
          <EuiPageContentBody>{children}</EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
}
