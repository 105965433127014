import { AddressVerificationType } from '@sharedComponents/schemas/MailingModelSchema';
import { atom, selector } from 'recoil';

const addressVerificationState = atom<AddressVerificationType[]>({
  key: 'addressVerificationState',
  default: []
});

export const recipientsSetForMailing = selector({
  key: 'recipientsSetForMailing',
  get: ({ get }) => {
    const recipients = get(addressVerificationState);

    return recipients.filter(recipient => recipient.is_selected_for_sending);
  }
});

export default addressVerificationState;
