import React from 'react';
import { EuiButton, EuiFlexItem, EuiText, htmlIdGenerator, EuiFlexGroup } from '@elastic/eui';
import { AWARD_STEPS } from './config';
import AbstractAwardStep from './AbstractAwardStep';

const AwardStepAmountConfirmation: AbstractAwardStep = {
  headerTitle: 'Confirmation',
  actions(changeAwardingStep) {
    const actions: React.ReactNode[] = [];
    actions.push(
      <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.AWARD_AMOUNT_SELECTION);
          }}
        >
          Back
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.EMAIL_DRAFTING);
          }}
          fill
        >
          Confirm
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ selection }) {
    let summ: string | number = selection.reduce((prev, curr) => {
      return prev + curr.awardAmount;
    }, 0); // thousands separator

    if (summ > 999) {
      summ = summ.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            You are about to award a total of ${summ} to {selection.length}{' '}
            {selection.length === 1 ? 'candidate' : 'candidates'}.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepAmountConfirmation;
