import React from 'react';

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer } from '@elastic/eui';
import { ScholarshipModelSchema, ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';
import { DateRangeField } from '@sharedComponents/formElements';

const SelfListingScholarshipStep4 = () => {
  const fieldNames: [string, string] = ['data.startDate', 'deadlineDate'];

  return (
    <EuiFlexGroup direction="column" gutterSize="xs">
      <EuiFlexItem>
        <EuiText>
          <strong>What is the start date and deadling of your scholarship?</strong>
        </EuiText>
        <EuiSpacer size="s" />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row" justifyContent="spaceBetween">
          <EuiFlexItem>
            <EuiText size="s" style={{ color: '#5295ff', fontWeight: 'bold' }}>
              Start Date
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="s" style={{ color: '#5295ff', fontWeight: 'bold' }}>
              Deadline
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <DateRangeField
          fieldName={fieldNames}
          fieldProps={{
            inline: true
          }}
          rowProps={{
            fullWidth: true,
            display: 'rowCompressed'
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSpacer />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep4.validationRules = ScholarshipModelSchema.pick(['deadlineDate']).shape({
  data: ScholarshipModelDataSchema.pick(['startDate'])
});

export default SelfListingScholarshipStep4;
