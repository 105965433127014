import React from 'react';
import './Chevron.scss';

export default function ChevronLeft() {
  return (
    <svg viewBox="0 0 12 12" className="Chevron" alt="Previous">
      <path d="M 8 11 L 2 6  L 8 1" />
    </svg>
  );
}
