import * as React from 'react';
import { EuiDataGridColumn } from '@elastic/eui';
import {
  ApplicationWithScores,
  AWARD_STATUS,
  DetailedReviewRoundResponse
} from '@sharedComponents/interfaces/Applications.interface';

export enum VIEW_MODE {
  /** first run, no rounds happens at all */
  ROUNDS_SETUP,
  /** viewing/editing current round */
  ROUND_EDIT,
  /** setting up next round */
  NEXT_ROUND_SETUP,
  /** finished review rounds */
  PAST_VIEW
}

export interface AwardingApplicationDataEntry extends ApplicationGridDataEntry {
  index: number; // unique index assigned to every application to make them easier display numeric position
  awardAmount: number;
}

export interface ApplicationGridDataEntry {
  name: string;
  email: string;
  gpa: number | undefined;
  academicYear: string | undefined;
  school: string | undefined;
  id: number;
  reviewers?: any[];
  totalAverageScore?: number;
  award_status?: AWARD_STATUS;
  prequalify_score?: number;
  roundsStats?: ApplicationWithScores['roundsStats'];
  num?: number;
}

export function getDataGridColumns(
  viewMode: VIEW_MODE,
  reviewRoundDetails?: DetailedReviewRoundResponse,
  applicationGridData?: ApplicationGridDataEntry[]
) {
  const dataGridColumns: (EuiDataGridColumn & { hiddenByDefault?: boolean })[] = [
    {
      id: 'name',
      isExpandable: false,
      isResizable: true,
      actions: false,
      displayAsText: 'Name',
      display: <strong>Name</strong>
    },
    {
      id: 'email',
      isExpandable: true,
      isResizable: true,
      hiddenByDefault: true,
      displayAsText: 'Email',
      actions: {
        showHide: true
      },
      display: <strong>Email</strong>
    }
  ];

  // we have reviewRoundDetails in case its already setted review round or applicationGridData in case its first round setup
  // for both cases we check if we have prequalify_score in order to display its tab
  const hasPrequalifyScores = reviewRoundDetails
    ? reviewRoundDetails?.applications.some(
        application => application?.prequalify_score && application?.prequalify_score > 0
      )
    : applicationGridData?.some(application => application?.prequalify_score && application?.prequalify_score > 0);

  if (hasPrequalifyScores) {
    dataGridColumns.push({
      id: 'prequalify_score',
      displayAsText: 'Auto Score',
      isExpandable: false,
      isResizable: false,
      isSortable: true,
      hiddenByDefault: false,
      schema: 'stringNumber',
      actions: {
        showHide: true
      },
      display: <strong>Auto Score</strong>
    });
  }

  if ([VIEW_MODE.ROUND_EDIT, VIEW_MODE.NEXT_ROUND_SETUP, VIEW_MODE.ROUNDS_SETUP].includes(viewMode)) {
    dataGridColumns.push({
      id: 'gpa',
      displayAsText: 'GPA',
      isExpandable: false,
      isResizable: false,
      isSortable: true,
      hiddenByDefault: true,
      schema: 'stringNumber',
      actions: {
        showHide: true
      },
      display: <strong>GPA</strong>
    });

    dataGridColumns.push({
      id: 'academicYear',
      displayAsText: 'Academic Year',
      isExpandable: false,
      isResizable: false,
      isSortable: true,
      hiddenByDefault: true,
      actions: {
        showHide: true
      },
      display: <strong>Academic Year</strong>
    });

    dataGridColumns.push({
      id: 'school',
      displayAsText: 'School',
      isExpandable: false,
      isResizable: false,
      hiddenByDefault: true,
      actions: {
        showHide: true
      },
      display: <strong>School</strong>
    });

    dataGridColumns.push({
      id: 'reviewers',
      displayAsText: 'Reviewer(s)',
      isExpandable: true,
      isResizable: false,
      actions: false,
      display: <strong>Reviewer(s)</strong>
    });
  }

  if (
    (viewMode === VIEW_MODE.ROUND_EDIT ||
      viewMode === VIEW_MODE.NEXT_ROUND_SETUP ||
      viewMode === VIEW_MODE.PAST_VIEW) &&
    reviewRoundDetails
  ) {
    for (let index = reviewRoundDetails.currentReviewRounds.length - 1; index >= 0; index--) {
      const round = reviewRoundDetails.currentReviewRounds[index];
      dataGridColumns.push({
        id: 'round_' + round.id,
        displayAsText: 'Round ' + round.currentRound + ' Score',
        schema: 'scores',
        isExpandable: true,
        isResizable: true,
        display: <strong>Round {round.currentRound} Score</strong>
      });
    }

    if (reviewRoundDetails.currentRound > 1) {
      dataGridColumns.push({
        id: 'totalAverageScore',
        displayAsText: 'Total Average Score',
        schema: 'stringNumber',
        isExpandable: false,
        isResizable: false,
        isSortable: true,
        initialWidth: 190,
        display: <strong>Total Average Score</strong>
      });
    }
  }

  return dataGridColumns;
}
