export function getPath(object, path) {
  if (object == null || path.length === 0) {
    return object;
  }

  const firstPathSegment = path[0];

  if (path.length === 1) {
    return object[firstPathSegment];
  } else {
    return getPath(object[firstPathSegment], path.slice(1));
  }
}
