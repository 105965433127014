import React, { useEffect, useState } from 'react';
import { ArrayField, useFieldArray, useFormContext, FieldError, Controller } from 'react-hook-form';

import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiNotificationBadge } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/**
 * Represents wrapper for single text fields list,
 * thats alternative version of FieldList component without usage of react-hook-form's useFieldArray, as it don't supports string arrays, only objects
 * adds append/removal functionality to those
 * TODO:IMP: there are a bit of repeating from FieldList which could be solved
 */
export default function TextInputsList({
  fieldName,
  rowProps
}: {
  fieldName: string;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const renderedFieldList = (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={[]}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        const appendOne = () => {
          onChange([...(value || []), '']);
        };

        const removeOne = index => {
          const changedArrayList = [...value];
          changedArrayList.splice(index, 1);
          onChange(changedArrayList);
        };

        return (
          <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="s">
                {value?.length
                  ? value.map((field, index) => (
                      <EuiFlexItem key={`${fieldName}_${index}`}>
                        <EuiFlexGroup direction="row" gutterSize="xs">
                          <EuiFlexItem>
                            <EuiFieldText
                              type="text"
                              name={`${fieldName}[${index}]`}
                              value={field}
                              onChange={e => {
                                const changedArrayList = [...value];
                                changedArrayList[index] = e.target.value;
                                onChange([...changedArrayList]);
                              }}
                              fullWidth={false}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                              aria-label="Remove item"
                              color="danger"
                              iconType="minus"
                              onClick={() => removeOne(index)}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    ))
                  : null}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonIcon
                aria-label="Add item"
                color="primary"
                style={{ alignSelf: 'flex-end' }}
                iconType="plus"
                onClick={appendOne}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {renderedFieldList}
    </FormRow>
  ) : (
    renderedFieldList
  );
}
