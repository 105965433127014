import { useQuery, UseQueryOptions } from 'react-query';

import { QuestionModelShape } from '@sharedComponents/models';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'questionsForScholarship';

type Questions = Array<QuestionModelShape & { applicantName: string }>;

/**
 * disabled while selectedScholarshipId is null
 */
function useQuestionsForScholarship(
  selectedScholarshipId: number | null,
  options: UseQueryOptions<Questions, Error, Questions> = {}
) {
  const applicationClient = useApplicationClient();
  const query = {
    ...useQuery<Questions, Error>(
      [CURRENT_QUERY_KEY, selectedScholarshipId], // depend cache on IDs
      () => applicationClient.getQuestionsForScholarship(selectedScholarshipId as any),
      {
        staleTime: 1000 * 45,
        ...options,
        // if query disabled from outside - respect this, else relay on selectedScholarshipId availability
        enabled: options.enabled === false ? false : selectedScholarshipId !== null
      }
    )
  };

  return query;
}

const questionsForScholarshipQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useQuestionsForScholarship };
export default questionsForScholarshipQuery;
