import React, { useState } from 'react';
import draftToHtml from 'draftjs-to-html';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText
} from '@elastic/eui';
import RichTextEditor from '@sharedComponents/common/RichTextEditor/RichTextEditor';

const PersonalizedInvitationModal = ({ onModalSubmit, title }) => {
  const [personalizedInvitation, setPersonalizedInvitation] = useState<any>();

  const onModalSubmitHandler = () => {
    const markup = draftToHtml(personalizedInvitation);
    onModalSubmit(markup);
  };

  const onCancelHandler = () => {
    onModalSubmit();
  };

  return (
    <EuiModal onClose={onCancelHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <EuiText size="s">Write only text for the body of the email, no need to include a subject line.</EuiText>
            <EuiSpacer size="s" />
          </EuiFlexItem>
          <EuiFlexItem>
            <RichTextEditor
              value={personalizedInvitation}
              onContentStateChange={contentState => {
                setPersonalizedInvitation(contentState);
              }}
              config={{ hideWordsCounter: true, minHeight: 300 }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onCancelHandler} size="s">
          Cancel
        </EuiButtonEmpty>
        <EuiButton color="primary" onClick={onModalSubmitHandler} size="s">
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
export default PersonalizedInvitationModal;
