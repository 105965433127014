import React from 'react';
import { useState, useEffect } from 'react';
import './FormFieldState.scss';
import { toNumber } from '../../model/cast';
import stateByAbbreviation from '@sharedComponents/models/enumValues/states';

export default function FormFieldState({ node, application, onFieldChange, onFormError }) {
  const value = node.getValue(application);

  const [typed, setTyped] = useState<string>(stateByAbbreviation[value] || '');
  const [candidate, setCandidate] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setTyped(stateByAbbreviation[value]);
      setCandidate(null);
    }
  }, [value]);

  let inputClass = 'narrow';

  function onType(text: string) {
    setTyped(text);

    text = text.toLowerCase();

    if (text !== '') {
      const candidates = statesLowerCase
        .filter(([key, name]) => name.startsWith(text))
        .sort(([key1, name1], [key2, name2]) => name1.length - name2.length);

      const candidate = candidates[0];

      if (candidate) {
        const [abbreviation] = candidate;

        setCandidate(abbreviation);
      } else {
        setCandidate(null);
      }
    } else {
      setCandidate(null);
    }
  }

  function onBlur() {
    let abbreviation = candidate;

    if (!abbreviation && typed && stateByAbbreviation[typed.toUpperCase()]) {
      abbreviation = typed.toUpperCase();
    }

    if (abbreviation) {
      onFieldChange(abbreviation, node);
      setTyped(stateByAbbreviation[abbreviation]);
    } else if (typed === '') {
      onFieldChange('', node);
      setTyped('');
    } else {
      setTyped('');
    }

    setCandidate(null);
  }

  return (
    <div className="FormFieldState">
      <input
        id={node.getInputElementId()}
        type="text"
        value={typed}
        onChange={e => onType(e.target.value)}
        onBlur={onBlur}
        maxLength={node.width ? toNumber(node.width, onFormError) : undefined}
        className={inputClass}
      />
      {candidate && typed != null ? (
        <div className="candidate">
          <span className="typed">{typed}</span>
          <span className="suggestion">{stateByAbbreviation[candidate].substr(typed.length)}</span>
        </div>
      ) : null}
    </div>
  );
}

const statesLowerCase = Object.entries(stateByAbbreviation).map(([key, name]) => [key, name.toLowerCase()]);
