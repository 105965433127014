import { USER_TYPE } from '../constants';

export function capitalize(str: string = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getLastName(name: string = '') {
  const [first] = name.split(' ');

  return name
    .replace(first, '')
    .trim()
    .split(' ')
    .map(s => capitalize(s))
    .join(' ');
}

export function getFirstName(name: string = '') {
  const [first] = name.split(' ');

  return capitalize(first);
}

export function stripPhoneToNumbersOnly(phone: string) {
  return phone.replace(/[^\d]/g, '');
}

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth'
];
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export function stringifyNumber(n) {
  if (n < 20) {
    return special[n];
  }

  if (n % 10 === 0) {
    return deca[Math.floor(n / 10) - 2] + 'ieth';
  }

  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export function getPublicRoleName(role: USER_TYPE) {
  switch (role) {
    case USER_TYPE.APPLICANT: {
      return 'student';
    }

    case USER_TYPE.PARENT: {
      return 'parent';
    }

    case USER_TYPE.COUNSELOR: {
      return 'counselor';
    }

    case USER_TYPE.RECOMMENDER: {
      return 'recommender';
    }

    case USER_TYPE.PROFESSIONAL: {
      return 'professional';
    }

    case USER_TYPE.ADMIN: {
      return 'admin';
    }

    case USER_TYPE.DONOR: {
      return 'donor';
    }

    case USER_TYPE.DATAENTRY: {
      return 'data-admin';
    }

    default: {
      return role;
    }
  }
}

export function pluralize(count, noun, includeNumber?, plural?) {
  if (!plural) {
    plural = noun + 's';
  }

  return `${includeNumber ? `${count} ` : ''}${count === 1 ? noun : plural}`;
}

export function _addParagraphsToText(text: string) {
  const startP = '<p style="margin-bottom: 1em">';
  const endP = '</p>\n';

  return startP + text.replace(/\u21b5/g, endP + startP).replace(/\n\r?/g, endP + startP) + endP;
}

export function addLinksToText(text: string) {
  const urlPattern = /\b(https?:\/\/[\w./?=\-_&%]*|[\w.]+\.(com|org|edu|net|info)\/[\w./?=\-_&%]*)/g;

  const urls = text.match(urlPattern);

  if (urls) {
    for (const url of urls) {
      text = text.replace(url, `<a href="${url}">${url}</a>`);
    }
  }

  return text;
}

export function toHtml(text: string) {
  return _addParagraphsToText(addLinksToText(text));
}

export function makeHtmlSafe(html: string) {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;').replace(/"/g, '&quot;');
}
