import { Dictionary, filter, parseInt, reduce } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';

import { EuiButtonIcon, EuiDataGrid, EuiDataGridColumn } from '@elastic/eui';
import { DEFAULT_DATE_FORMAT } from '@sharedComponents/config';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { RecurringAwardModelShape } from '@sharedComponents/models';

import { RecurrentAwardsListingRecord } from './RecurrentAwardsListing.interface';

function RecurrentAwardsListing({
  rewardedUsers
}: {
  rewardedUsers: Dictionary<[RecurringAwardModelShape, ...RecurringAwardModelShape[]]>;
}) {
  const router = useRouter();
  const [recordsToRender, setRecordsToRender] = useState<RecurrentAwardsListingRecord[]>([]);

  useMemo(() => {
    const userEmails = Object.keys(rewardedUsers);
    const _recordsToRender: RecurrentAwardsListingRecord[] = [];
    for (const email of userEmails) {
      const disbursements = rewardedUsers[email];
      // todo figure out which are submitted and which are awarded?
      const disbursementsDone = filter(disbursements, disbursement => disbursement.is_submitted); // those are already handled via our platform

      const disbursementsLeft = filter(disbursements, disbursement => !disbursement.is_submitted);

      // also we migth imported some of previous payments with initial csv import
      const importedRecord = disbursements[0].record;
      const importedDisbursementsDoneCount = parseInt(importedRecord?.previous_repayments) || 0;
      const importedDisbursementsAmount = parseInt(importedRecord?.previous_amount) || 0;

      _recordsToRender.push({
        id: email,
        payment_done: disbursementsDone.length + importedDisbursementsDoneCount,
        amount_granted:
          reduce(
            disbursementsDone,
            (prev, disbursement) => {
              return prev + disbursement.disbursement_amount;
            },
            0
          ) + importedDisbursementsAmount,
        payments_left: disbursementsLeft.length,
        next_payment: disbursementsLeft.length ? disbursementsLeft[0].disbursement_date : null,
        scholarshipID: disbursements[0].scholarship_id,
        recordID: disbursements[0].id
      });
    }

    setRecordsToRender(_recordsToRender);
  }, [rewardedUsers]);

  // initial columns set to required ones
  const columns: EuiDataGridColumn[] = [
    {
      id: 'id',
      displayAsText: 'Student Email',
      actions: false
    },
    {
      id: 'payment_done',
      displayAsText: '# previously made repayments',
      schema: 'numeric',
      actions: false,
      isExpandable: false
    },
    {
      id: 'amount_granted',
      displayAsText: 'Amount granted',
      schema: 'currency',
      actions: false,
      isExpandable: false
    },
    {
      id: 'payments_left',
      displayAsText: '# of disbursements left',
      schema: 'numeric',
      actions: false,
      isExpandable: false
    },
    {
      id: 'next_payment',
      schema: 'datetime',
      displayAsText: 'Next disbursement date',
      actions: false,
      isExpandable: false
    }
  ];

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  );

  const onChangePage = useCallback(
    pageIndex => setPagination(pagination => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId }) => {
      if (columnId === 'amount_granted') {
        if (recordsToRender[rowIndex]) {
          const numeric = recordsToRender[rowIndex][columnId] ? parseInt(recordsToRender[rowIndex][columnId]) : 0;
          return numeric ? `$ ${numeric.toLocaleString()}` : '-';
        }
      } else if (columnId === 'next_payment' && recordsToRender[rowIndex]) {
        const record = recordsToRender[rowIndex];
        const value = record[columnId];
        if (!value) {
          return '-';
        }

        const date = moment(value);
        return `${date.format(DEFAULT_DATE_FORMAT)}`;
      }

      function getFormatted(_rowIndex, _columnId) {
        return recordsToRender[_rowIndex][_columnId] ? recordsToRender[_rowIndex][_columnId] : '-';
      }

      return recordsToRender[rowIndex] ? getFormatted(rowIndex, columnId) : null;
    };
  }, [recordsToRender]);

  const ViewRowCell = ({ rowIndex }) => {
    const value = recordsToRender[rowIndex];
    const handleRecurrentPaymentView = () => {
      router.push('/recurring-awards/' + value.scholarshipID + '/' + value.recordID);
    };

    return (
      <>
        <EuiButtonIcon
          color="text"
          iconType="eye"
          iconSize="s"
          aria-label="View details"
          onClick={handleRecurrentPaymentView}
        />
      </>
    );
  };

  const trailingControlColumns = [
    {
      id: 'View',
      width: 36,
      headerCellRender: () => null,
      rowCellRender: ViewRowCell
    }
  ];

  return (
    <EuiDataGrid
      aria-label="Recurrent payments records"
      columns={columns}
      columnVisibility={{ visibleColumns, setVisibleColumns }}
      rowCount={recordsToRender.length}
      renderCellValue={renderCellValue}
      inMemory={{ level: 'enhancements' }}
      trailingControlColumns={trailingControlColumns}
      pagination={{
        ...pagination,
        pageSizeOptions: [10, 25],
        onChangeItemsPerPage: onChangeItemsPerPage,
        onChangePage: onChangePage
      }}
    />
  );
}

export default RecurrentAwardsListing;
