import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import { EuiFieldPassword, EuiFieldPasswordProps } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/** Wrapped EuiFieldPassword to have support for hook-form context and inbound formRow rendering */
export default function PasswordField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: EuiFieldPasswordProps;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { register, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const field = (
    <EuiFieldPassword type="password" name={fieldName} inputRef={register} isInvalid={isInvalid} {...fieldProps} />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
