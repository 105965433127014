import { get } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import useToast from '@sharedComponents/hooks/useToast';
import {
  FORM_CHANGE_REQUEST_STATUSES,
  FORM_REVIEW_STATUSES,
  FORM_REVIEWER_TYPES
} from '@sharedComponents/interfaces/Scholarships.interface';
import { FormChangeRequestModelShape } from '@sharedComponents/models';

import approveFormQuery from './queries/approveFormQuery';
import requestFormChangesQuery from './queries/requestFormChangesQuery';
import RequestChangesModal from './ReviewControls/RequestChangesModal';

export default function ReviewControls({
  changeRequest,
  onChangeRequestUpdate
}: {
  changeRequest: FormChangeRequestModelShape;
  onChangeRequestUpdate: (FormChangeRequestModel) => void;
}) {
  const { mutate: mutateApproveForm, isLoading: isFormBeingApproved } = approveFormQuery.useMutateApproveForm();
  const { mutate: mutateRequestFormChanges, isLoading: areFormChangesBeingRequested } =
    requestFormChangesQuery.useMutateRequestFormChanges();
  const [isNoChangesNeededRequest, setIsNoChangesNeededRequest] = useState(false);
  const [deadlineMoment, setDeadlineMoment] = useState<Moment>();

  const createModal = useModalCreate();
  const { addToast } = useToast();

  const review = get(changeRequest, 'reviews[0]');

  useEffect(() => {
    setDeadlineMoment(moment(changeRequest.deadline));
  }, [changeRequest]);

  const approveForm = async () => {
    mutateApproveForm(
      { formId: changeRequest.form_id },
      {
        onSuccess: updatedChangeRequest => {
          addToast({
            id: htmlIdGenerator()(),
            color: 'success',
            title: 'The form is approved!'
          });
          onChangeRequestUpdate(updatedChangeRequest);
        },
        onError: err => {
          addToast({
            id: htmlIdGenerator()(),
            color: 'danger',
            title: 'Something went wrong!'
          });
        }
      }
    );
  };

  const requestFormChanges = async (changes, onlySave = false, successCallback?, errorCallback?) => {
    const isNoChangesNeeded = changes === '-';
    if (isNoChangesNeeded) {
      // means no changes needed
      setIsNoChangesNeededRequest(true);
    }

    mutateRequestFormChanges(
      { formId: changeRequest.form_id, changes, onlySave },
      {
        onSuccess: updatedChangeRequest => {
          if (!onlySave) {
            addToast({
              id: htmlIdGenerator()(),
              color: 'success',
              title: isNoChangesNeeded ? 'The form is approved' : 'The changes are requested!'
            });
          }
          onChangeRequestUpdate(updatedChangeRequest);

          if (successCallback) {
            successCallback();
          }

          setIsNoChangesNeededRequest(false);
        },
        onError: err => {
          addToast({
            id: htmlIdGenerator()(),
            color: 'danger',
            title: 'Something went wrong!'
          });

          if (errorCallback) {
            errorCallback();
          }

          setIsNoChangesNeededRequest(false);
        }
      }
    );
  };

  function showRequestChangesModal() {
    createModal(({ closeModal }) => (
      <RequestChangesModal
        closeModal={closeModal}
        changeRequest={changeRequest}
        requestFormChanges={requestFormChanges}
      />
    ));
  }

  const isDeadlinePassed = moment().diff(deadlineMoment) > 0;
  const isSuperDonor = review.user_type === FORM_REVIEWER_TYPES.SUPER;

  const changesCanBeRequested = review.status === FORM_REVIEW_STATUSES.REVIEW_REQUESTED;

  return deadlineMoment && review && changeRequest.status !== FORM_CHANGE_REQUEST_STATUSES.APPROVED ? (
    <>
      <EuiSpacer />
      <EuiFlexGroup responsive={false} direction={'column'} gutterSize={'s'}>
        {changesCanBeRequested && (
          <EuiFlexItem>
            <EuiButton color={'text'} onClick={showRequestChangesModal} disabled={isDeadlinePassed} fullWidth fill>
              Request Changes
            </EuiButton>
            {isDeadlinePassed && isSuperDonor && (
              <>
                <EuiSpacer size={'xs'} />
                <EuiText color={'danger'} size={'xs'} textAlign={'center'}>
                  Form review deadline has expired.
                </EuiText>
                <EuiSpacer size={'s'} />
              </>
            )}
          </EuiFlexItem>
        )}
        {isSuperDonor ? (
          <EuiFlexItem>
            <EuiButton
              color={'success'}
              onClick={approveForm}
              isLoading={isFormBeingApproved}
              disabled={!isDeadlinePassed}
              fullWidth
              fill
            >
              Approve
            </EuiButton>
          </EuiFlexItem>
        ) : (
          changesCanBeRequested && (
            <EuiFlexItem>
              <EuiButton
                color={'success'}
                onClick={() => requestFormChanges('-', false)}
                isLoading={areFormChangesBeingRequested && isNoChangesNeededRequest}
                disabled={isDeadlinePassed}
                fullWidth
                fill
              >
                No Changes Needed
              </EuiButton>
              {isDeadlinePassed && (
                <>
                  <EuiSpacer size={'xs'} />
                  <EuiText color={'danger'} size={'xs'} textAlign={'center'}>
                    Form review deadline has expired.
                  </EuiText>
                </>
              )}
            </EuiFlexItem>
          )
        )}
      </EuiFlexGroup>
    </>
  ) : null;
}
