import { capitalize, replace } from 'lodash';
import moment from 'moment';

import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingContent,
  EuiText,
  EuiTitle,
  formatDate
} from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';

import mailingQuery from './queries/mailingQuery';

const PastMailings = () => {
  const router = useRouter();
  const { data, isLoading } = mailingQuery.useMailingsListing();

  let content;

  if (isLoading) {
    content = (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiLoadingContent lines={10} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (!data?.length) {
    content = <EuiText size="s">No mailings.</EuiText>;
  } else {
    const columns = [
      {
        field: 'id',
        name: 'ID'
      },
      {
        field: 'description',
        name: 'Description',
        truncateText: false,
        mobileOptions: {
          show: false
        }
      },
      {
        field: 'date',
        name: 'Date',
        dataType: 'date',
        render: date => formatDate(date, 'dobLong')
      },
      {
        field: 'status',
        name: 'Status',
        render: str => capitalize(replace(str || '', /[_]/g, ' '))
      }
    ];

    const items = data?.map(mailing => ({
      id: mailing.id,
      description: mailing.description,
      date: moment(mailing.created_at).format('MM-DD-YYYY HH:mm'),
      href: `/mailings/view/${mailing.id}`,
      status: mailing.status
    }));

    const getRowProps = item => {
      const { id, href } = item;
      return {
        'data-test-subj': `row-${id}`,
        onClick: () => router.push(href)
      };
    };

    content = (
      <EuiBasicTable
        tableLayout="auto"
        tableCaption="Past Mailings"
        items={items}
        rowHeader="firstName"
        columns={columns}
        rowProps={getRowProps}
      />
    );
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem>
        <EuiTitle size="s">
          <h5>Mailings:</h5>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{content}</EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default PastMailings;
