import { ValidationError } from '../FormFieldText';
import Pattern from './Pattern';

const phoneNumberPattern: Pattern = {
  placeholder: '(000) 000-0000',

  correctPartial(value) {
    const corrected = value.replace(/[^0-9()+\- ]/g, '');

    return corrected;
  },

  correctComplete(value) {
    value = value || '';

    const numbers = value.replace(/[^0-9]/g, '');

    if (value.startsWith('+1')) {
      value = value.substr(2);
    }

    if (value.startsWith('+')) {
      if (numbers.length < 7) {
        throw new ValidationError(`Number seems too short.`);
      } else {
        return value;
      }
    } else {
      if (numbers.length !== 10) {
        throw new ValidationError('Domestic numbers: include 10 digits. International numbers: start with a "+" sign.');
      }

      return `(${numbers.substr(0, 3)}) ${numbers.substr(3, 3)}-${numbers.substr(6)}`;
    }
  }
};

export default phoneNumberPattern;
