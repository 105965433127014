import React from 'react';
import { useState, useEffect } from 'react';

import './ErrorMessage.scss';

export function ErrorMessage(props) {
  const { onClose, error } = props;

  const detail = (error && error.message) || props.detail;
  const isUnauthorized = error && error.isUnauthorized;

  const animationTime = props.animationTime || 200;

  const d = 180;
  const e = 512 - d;

  const [className, setClassName] = useState('');

  useEffect(() => {
    Promise.resolve().then(setClassName('show'));
  }, []);

  function close() {
    setClassName('');

    soon(animationTime).then(onClose);
  }

  const title = props.title !== '' ? props.title || (isUnauthorized ? 'Unauthorized' : 'An error occurred') : null;

  function logOut() {
    window.logout();
    window.location = '/';
  }

  return (
    <div className={'ErrorMessage ' + className}>
      <div className="top">{title}</div>
      <div className="bottom">
        {detail || ''}

        {isUnauthorized ? (
          <div className="unauthorizedUser">
            {error.user ? (
              <span>
                You are {error.user.name} ({error.user.type})
                <form
                  className="buttonContainer"
                  onSubmit={e => {
                    e.preventDefault();
                    logOut();
                  }}
                >
                  <button className="button link invisible">Log out</button>
                </form>
              </span>
            ) : (
              <span>You are not logged in.</span>
            )}
          </div>
        ) : null}
        {onClose ? (
          <div>
            <svg
              className="close"
              viewBox="0 0 512 512"
              strokeWidth="40"
              strokeLinecap="round"
              strokeMiterlimit="10"
              onClick={close}
            >
              <g>
                <circle cx="256" cy="256" r="224" />
                <line className="cross" x1={d} y1={e} x2={e} y2={d} />
                <line className="cross" x1={d} y1={d} x2={e} y2={e} />
              </g>
            </svg>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function soon(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
