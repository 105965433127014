export default [
  'African',
  'African American',
  'Alaska Native',
  'Arab',
  'Asian',
  'Australian',
  'Canadian',
  'Caucasian',
  'Central American',
  'Central European',
  'Chinese',
  'Eastern European',
  'Filipino',
  'First Nation',
  'Hispanic or Latino',
  'Italian',
  'Italian American',
  'Japanese',
  'Mexican',
  'Native American',
  'Native Hawaiian',
  'Northern European',
  'Pacific Islander',
  'Portuguese',
  'Puerto Rican',
  'South American',
  'South Asian',
  'Southern European',
  'Southwest European',
  'Western European'
];
