import {
  StandaloneApplicationParams,
  StandAloneApplicationsFileValidationResult
} from '../../StandaloneApplications.interface';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';
import { useMutation, useQuery } from 'react-query';

const CURRENT_QUERY_KEY = 'standaloneApplications';

const useSubmitStandaloneApplications = () => {
  const userClient = useUserClient();

  return useMutation<
    any, // returned result result by our api
    Error, // error type
    any
    // parameters accepted by mutation
  >(
    ({
      scholarshipID,
      standaloneApplicationParams
    }: {
      scholarshipID: number;
      standaloneApplicationParams: StandaloneApplicationParams;
    }) => userClient.submitStandaloneApplications(scholarshipID, standaloneApplicationParams)
  );
};

function useStandaloneApplicationsValidation(scholarshipID: number, fileID: number) {
  const userClient = useUserClient();
  const query = {
    ...useQuery<StandAloneApplicationsFileValidationResult, Error>([CURRENT_QUERY_KEY, fileID], () =>
      userClient.getStandaloneApplicationsValidation(scholarshipID, fileID)
    )
  };

  return query;
}

const standaloneApplicationsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useSubmitStandaloneApplications,
  useStandaloneApplicationsValidation
};

export default standaloneApplicationsQuery;
