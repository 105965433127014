import { USER_TYPE } from '../constants';

// basic invitation object
export interface Invitation {
  name?: string;
  email: string;
  // userType is very tricky and cannot be trusted. It's shown for both, parent and children, but depends on WHO was the inviter
  userType: USER_TYPE; // describes role of invitED user(not the one who is inviting!)
}

// once verified from backend user existance, status explains itself
export interface VerifiedInvitation extends Invitation {
  status: INVITATION_STATUS;
}

export enum INVITATION_STATUS {
  USER_NOT_EXIST = 'USER_NOT_EXIST',
  USER_EXIST = 'USER_EXIST',
  WRONG_RELATIONSHIP = 'WRONG_RELATIONSHIP' // Attempt to invite user with wrong role, f.e. invite admin as your parent
}

// after verification user still can decide to invite or not
export interface InvitationConfirmation extends VerifiedInvitation {
  isInviting: boolean;
}

// Represent relationship status(obviously)
export enum RELATIONSHIP_STATUS {
  NOT_CONFIRMED, // pending invitations to parents/students
  CONFIRMED, // accepted parent relationships or recommender/counselor relationships
  LEGACY_RECORD // old data which supposed to get migrated with student's action
}

// already processed invitations becomes a relationship
export interface InvitationRelationship extends Invitation {
  userID?: number;
  status: RELATIONSHIP_STATUS;
}
