import { capitalize, parseInt, replace, sumBy } from 'lodash';
import { Fragment, useState } from 'react';

import { RecurrentPaymentsVerificationResult } from '@donors/routes/RecurringAwardsSetup.interface';
import {
  CENTER_ALIGNMENT,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiScreenReaderOnly,
  EuiStat,
  HorizontalAlignment,
  htmlIdGenerator,
  LEFT_ALIGNMENT,
  RIGHT_ALIGNMENT
} from '@elastic/eui';

export const ParsedRecurrentRecords = ({
  headers,
  records
}: {
  /**
   * Must have:
0: "name"
1: "last_name"
2: "email"
3: "previous_repayments"
4: "previous_amount"
   */
  headers: RecurrentPaymentsVerificationResult['headers'];
  records: RecurrentPaymentsVerificationResult['records'];
}) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});

  const columns: EuiBasicTableColumn<typeof records[0]>[] = [
    {
      field: 'name',
      name: 'First Name',
      align: LEFT_ALIGNMENT as HorizontalAlignment,
      footer: <span>{records.length} records</span>,
      truncateText: false,
      mobileOptions: {
        show: false
      }
    },
    {
      field: 'last_name',
      name: 'Last Name',
      align: LEFT_ALIGNMENT as HorizontalAlignment,
      truncateText: false,
      mobileOptions: {
        show: false
      }
    },
    {
      field: 'email',
      name: 'Email',
      width: '300',
      align: LEFT_ALIGNMENT as HorizontalAlignment,
      mobileOptions: {
        show: false
      }
    },
    {
      field: 'previous_repayments',
      name: 'Number of previous repayments',
      align: CENTER_ALIGNMENT as HorizontalAlignment,
      footer: ({ items }) => (
        <span>
          {sumBy<typeof records[0]>(items, item => {
            return item.previous_repayments ? parseInt(item.previous_repayments) : 0;
          })}
        </span>
      )
    },
    {
      field: 'previous_amount',
      name: 'Amount of previous repayments',
      align: CENTER_ALIGNMENT as HorizontalAlignment,
      footer: ({ items }) => (
        <span>
          $
          {sumBy<typeof records[0]>(items, item => {
            return item.previous_amount ? parseInt(item.previous_amount) : 0;
          }).toLocaleString()}
        </span>
      )
    }
  ];

  // adding optional columns as extended data
  if (headers.length > 5) {
    const extraHeaders: string[] = [];
    for (let index = 5; index < headers.length; index++) {
      const header = headers[index];

      extraHeaders.push(header);
    }

    const toggleDetails = item => {
      const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
      if (itemIdToExpandedRowMapValues[item.email]) {
        delete itemIdToExpandedRowMapValues[item.email];
      } else {
        const listItems = extraHeaders.map(header => ({
          title: capitalize(replace(header, /[_]/g, ' ')),
          description: item[header] || '-'
        }));

        itemIdToExpandedRowMapValues[item.email] = (
          <EuiFlexGroup direction="row" responsive={false}>
            {listItems.map(_item => (
              <EuiFlexItem key={htmlIdGenerator()()}>
                <EuiStat title={_item.description} description={_item.title} color="primary" titleSize="xs" />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      }

      setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
    };

    columns.push({
      align: RIGHT_ALIGNMENT as HorizontalAlignment,
      width: '40px',
      isExpander: true,
      name: (
        <EuiScreenReaderOnly>
          <span>Expand rows</span>
        </EuiScreenReaderOnly>
      ),
      render: item => (
        <EuiButtonIcon
          onClick={() => toggleDetails(item)}
          aria-label={itemIdToExpandedRowMap[item.email] ? 'Collapse' : 'Expand'}
          iconType={itemIdToExpandedRowMap[item.email] ? 'arrowUp' : 'arrowDown'}
        />
      )
    });
  }

  return (
    <Fragment>
      <EuiBasicTable
        tableCaption="Parsed recurrent awards students data"
        items={records}
        columns={columns}
        itemId="email"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      />
    </Fragment>
  );
};
