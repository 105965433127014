import './ApplicationsList.scss';

import { find } from 'lodash';

import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiToolTip,
  HorizontalAlignment
} from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { ScholarshipWithReviewStatus } from '@sharedComponents/interfaces/Scholarships.interface';
import { ApplicationModelShape } from '@sharedComponents/models';
import formatDate, { isAfter } from '@sharedComponents/utils/formatDate';
import { doRepeat } from '@sharedComponents/utils/misc';

import { APPLICATION_TYPE } from '../ScholarshipApplications.interface';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';

export let applicationsFromList: ApplicationModelShape['id'][] = [];
export const ApplicationsList = ({
  scholarship,
  applications,
  onPageChange,
  pageIndex,
  onSortChange,
  extraColumns,
  sort,
  type
}: {
  scholarship?: ScholarshipWithReviewStatus;
  applications: Page<ApplicationModelShape>;
  onPageChange: any;
  pageIndex: number;
  onSortChange: any;
  sort: string;
  type: APPLICATION_TYPE;
  extraColumns?: EuiBasicTableColumn<ApplicationModelShape>[];
}) => {
  applicationsFromList = applications.results.map(a => a.id); // Tricky way to provide Next\Prev navigation for applications. Should be done via context instead, TODO:IMP
  const router = useRouter();

  const columns: EuiBasicTableColumn<ApplicationModelShape>[] = [
    {
      field: '',
      name: 'Applicant',
      sortable: false,
      mobileOptions: {
        header: false
      },
      footer: () => <span>{applications.total} total</span>,
      render: (application: ApplicationModelShape) => {
        // Some scholarships may announce first/last name as separated fields.
        const applicantName = getUserFullNameFromData(application.data) || 'Applicant ' + application.id;

        return (
          <>
            <EuiLink href={'/applications/' + application.id}>{applicantName}</EuiLink>

            <div className="highSchool">
              {application.data.school && application.data.school.school ? application.data.school.school.name : ''}
            </div>
          </>
        );
      }
    }
  ];

  const { mostRelevantReviewProcess = [] } = scholarship || {};

  if (type === APPLICATION_TYPE.REVIEWED_PAST_ROUNDS) {
    columns.push({
      field: '',
      name: 'Review Round',
      align: 'center',
      render: (application: ApplicationModelShape) => {
        const { reviews } = application;
        const reviewRoundID = (reviews || []).reduce((reviewRound, review) => {
          return review?.review_round && review?.review_round > reviewRound ? review.review_round : reviewRound;
        }, 0);

        if (reviewRoundID && mostRelevantReviewProcess?.length) {
          const round = find(mostRelevantReviewProcess, reviewRound => reviewRound.id === reviewRoundID);

          if (round) {
            return round.currentRound;
          }
        }

        return '';
      }
    });
  }

  if (type === APPLICATION_TYPE.REVIEWED) {
    columns.push({
      field: '',
      name: 'Score',
      render: (application: ApplicationModelShape) => {
        return application.reviews && application.reviews[0]?.score?.toFixed(2);
      }
    });
  }

  columns.push({
    field: '',
    name: 'Documents',
    sortable: false,
    render: (application: ApplicationModelShape) => {
      return (
        <EuiFlexGroup gutterSize="s">
          {application.hasTranscript ? (
            <EuiFlexItem>
              <EuiToolTip content="Transcript was submitted">
                <EuiBadge color="primary">Tscp</EuiBadge>
              </EuiToolTip>
            </EuiFlexItem>
          ) : null}
          {doRepeat(
            key => (
              <EuiFlexItem key={key}>
                <EuiToolTip content="Letter of recommendation was submitted" key={key}>
                  <EuiBadge color="accent">LoR</EuiBadge>
                </EuiToolTip>
              </EuiFlexItem>
            ),
            application.supportsCount
          )}
        </EuiFlexGroup>
      );
    }
  });

  if (extraColumns?.length) {
    columns.push(...extraColumns);
  }

  columns.push({
    field: '',
    name: 'Submitted',
    sortable: false,
    align: 'right' as HorizontalAlignment,
    render: (application: ApplicationModelShape) => {
      if (!application.submitted_at) {
        return <></>;
      }

      return (
        <EuiText>
          {formatDate(application.submitted_at)}
          <br />
          {application.updatedAt && isAfter(application.updatedAt, application.submitted_at) ? (
            <EuiText size="xs">(updated {formatDate(application.updatedAt)})</EuiText>
          ) : null}
        </EuiText>
      );
    }
  });

  const getRowProps = item => {
    const { id } = item;
    return {
      'data-test-subj': `row-${id}`,
      className: 'customRowClass',
      onClick: () => router.push('/applications/' + id)
    };
  };

  const getCellProps = (item, column) => {
    const { id } = item;
    const { field } = column;
    return {
      className: 'customCellClass',
      'data-test-subj': `cell-${id}-${field}`,
      textOnly: true
    };
  };

  const pagination = {
    pageIndex,
    pageSize: 25,
    totalItemCount: applications.total,
    hidePerPageOptions: true
  };

  return (
    <div className="ApplicationsList">
      {type === APPLICATION_TYPE.REVIEWED && (
        <p className="sorter">
          Sort Applications by Score:{' '}
          <span className={sort === 'DESC' ? 'selected' : ''} onClick={() => onSortChange('DESC')}>
            High to Low
          </span>{' '}
          |{' '}
          <span className={sort === 'ASC' ? 'selected' : ''} onClick={() => onSortChange('ASC')}>
            Low to High
          </span>
        </p>
      )}
      <EuiBasicTable
        tableLayout={'auto'}
        items={applications.results}
        rowHeader="firstName"
        columns={columns}
        pagination={pagination}
        rowProps={getRowProps}
        cellProps={getCellProps}
        onChange={onPageChange}
      />
    </div>
  );
};
