import './LoginRoleSelector.scss';

import React, { useState } from 'react';

import { EuiLink, EuiListGroup, EuiPopover, EuiText } from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import { capitalize, getPublicRoleName } from '@sharedComponents/utils/stringUtils';

export default function LoginRoleSelector({ userType, setUserType }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <EuiPopover
      className="LoginRoleSelector"
      ownFocus={false}
      button={
        <EuiLink
          href="#"
          onClick={e => {
            e.preventDefault();
            setIsPopoverOpen(true);
          }}
        >
          <EuiText className="LoginRoleSelector-text" size="s">Not a {getPublicRoleName(userType)}?</EuiText>
        </EuiLink>
      }
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
    >
      <EuiListGroup
        className="LoginRoleSelector-roles"
        flush
        gutterSize="s"
        size="l"
        listItems={[
          USER_TYPE.APPLICANT,
          USER_TYPE.PARENT,
          USER_TYPE.DONOR,
          USER_TYPE.COUNSELOR,
          USER_TYPE.REVIEWER,
          USER_TYPE.RECOMMENDER,
          USER_TYPE.PROFESSIONAL
        ].map(role => ({
          label: capitalize(getPublicRoleName(role)),
          href: '#',
          isActive: userType === role,
          isDisabled: userType === role,
          iconType: undefined,
          size: 's',
          onClick: e => {
            e.preventDefault();
            setIsPopoverOpen(false);
            setUserType(role);
          }
        }))}
      />
    </EuiPopover>
  );
}
