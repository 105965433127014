import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiComboBox, EuiComboBoxOptionOption, EuiComboBoxProps } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/**
 * EuiComboBox wrapped to work with react-hook-form and perform values transformation
 * EuiComboBox supposed to work with EuiComboBoxOptionOption, while in our case we are only in need of simplier approach, to have values sent as array of strings
 */
export default function ComboboxField({
  fieldName,
  options,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  // Could be array of strings(labels) or full EuiComboBoxOptionOption options or even async function which will return EuiComboBoxOptionOption[]
  options: string[] | EuiComboBoxOptionOption[];
  fieldProps?: Partial<EuiComboBoxProps<string>>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();
  const [comboBoxOptions, setComboBoxOptions] = useState<EuiComboBoxOptionOption<string>[]>([]);

  // if options passed into components as array, we transform those into EuiComboBoxOptionOption
  useEffect(() => {
    if (!fieldProps?.isLoading && options?.length) {
      const transformedOptions: EuiComboBoxOptionOption<string>[] = [];
      if (options.length && typeof options[0] === 'string') {
        transformedOptions.push(...options.map(optionValue => ({ label: optionValue })));
      } else {
        transformedOptions.push(...(options as EuiComboBoxOptionOption<string>[]));
      }

      setComboBoxOptions(transformedOptions);
    }
  }, [fieldProps?.isLoading, options]);

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const getOptionValue = (option: EuiComboBoxOptionOption) => {
    // option.value === 0 is accepable value
    return option.value === 0 ? option.value : option.value || option.label;
  };

  const field = (
    <Controller
      control={control}
      key={fieldName}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        // Since we might send just strings as param, we have to search currently selected options(they are generated from strings) this way
        let selectedOptions = // letting zero to be acceptable
          comboBoxOptions.length && value !== undefined && value !== null
            ? [
                ...comboBoxOptions.filter(option =>
                  fieldProps?.singleSelection
                    ? getOptionValue(option) === value
                    : value.includes(getOptionValue(option))
                )
              ]
            : [];

        // handling when we render our combobox and prefilling previously selected values
        if (!selectedOptions?.length && value) {
          selectedOptions = fieldProps?.singleSelection
            ? [
                {
                  label: value.toString()
                }
              ]
            : value.map(val => ({
                label: val.toString()
              }));
        }

        // if no values selected, give a chance for default value sent by fieldProps
        if (!selectedOptions.length && fieldProps?.defaultValue && typeof fieldProps.defaultValue === 'string') {
          selectedOptions = [{ label: fieldProps?.defaultValue }];
        }

        return (
          <EuiComboBox
            selectedOptions={selectedOptions}
            isInvalid={!!fieldError}
            options={comboBoxOptions}
            onChange={values => {
              // we execute change event resulting only with options values or labels if no labels available
              const changedValues = values.map(option => getOptionValue(option));
              onChange(fieldProps?.singleSelection ? changedValues[0] : changedValues);
            }}
            {...fieldProps}
            // forcing asPlainText for any sigleSelection, since its most common way to use it around and its problematic to send this object via fieldProps somehow...
            {...(fieldProps?.singleSelection ? { singleSelection: { asPlainText: true } } : {})}
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={!!fieldError}
      errorMessage={fieldError ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
