/**
 * The field type "recommenders" expands to this definition.
 * Unfortunately duplicated in donors.
 */
export default function recommendersForm({ help }: { help?: string | JSX.Element }) {
  return {
    type: 'recommenders-list',
    title: 'Recommenders',
    path: 'recommenders',
    help,
    columns: [
      [
        {
          type: 'address',
          children: [
            { field: 'name', required: true },
            {
              field: 'relationship',
              label: 'Relationship to applicant',
              required: false
            },
            { field: 'email', type: 'email', required: true },
            { field: 'phone', type: 'phone', width: 14, required: false }
          ]
        }
      ],
      [{ type: 'recommenderStatus', field: 'status' }]
    ]
  };
}
