import { FormsModelShape } from '@sharedComponents/models';
import { transformPagesForInternalUsage } from './formStatePagesSelector';
import { DefaultValue, selector } from 'recoil';
import { FormbuilderState, formStateAtom } from '../atoms/formState';

const formStateSelector = selector<FormbuilderState>({
  key: 'formStateSelector',
  get: ({ get: getRecoilValue }) => {
    return getRecoilValue(formStateAtom);
  },
  // ! sending FormbuilderState|DefaultValue is not the usecase, its made just for types
  // used only for initial form load! modify parts by using sub-selectors
  set: ({ set: setRecoilState }, newState: FormsModelShape | FormbuilderState | DefaultValue) => {
    if (newState instanceof DefaultValue) {
      setRecoilState(formStateAtom, newState);
    } else if (newState) {
      const formModelShape = newState as FormsModelShape;

      // FormsModelShape
      const _newState: FormbuilderState = {
        formName: formModelShape.name,
        pages: transformPagesForInternalUsage(formModelShape.data),
        activePage: 0,
        formID: formModelShape.id,
        isDirty: false
      };

      setRecoilState(formStateAtom, _newState);
    }
  }
});

export default formStateSelector;
