import React, { useState, useEffect } from 'react';
import { EuiPanel, PanelPaddingSize, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import './ImageRenderer.scss';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function ImageRenderer({
  fileId,
  fileUrl,
  applicationClient,
  block = '100%',
  imageAlt,
  paddingSize = 'l'
}: {
  fileId: number | null;
  applicationClient: ApplicationClient;
  block?: string;
  fileUrl?: string;
  imageAlt?: string;
  paddingSize?: PanelPaddingSize;
}) {
  const [isLoading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!fileId) {
      return;
    }

    const loadImage = async () => {
      setLoading(true);

      try {
        let url;
        if (fileUrl) {
          url = fileUrl;
        } else {
          url = (await applicationClient.getFileUrl(fileId, undefined, true)).url;
        }

        setImageUrl(applicationClient['baseUrl'] + url);
      // eslint-disable-next-line no-empty
      } catch (err) {}

      setLoading(false);
    };

    loadImage();
  }, [fileId]);

  return isLoading || imageUrl ? (
    <div className={'imageRenderer'} style={block ? { width: block } : undefined}>
      <div className="heightFiller" style={block ? { paddingTop: block } : { display: 'none' }} />
      <div className="rendererContent">
        {isLoading ? (
          <EuiPanel paddingSize={paddingSize} hasShadow={false}>
            <EuiFlexItem>
              <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
            </EuiFlexItem>
          </EuiPanel>
        ) : (
          <EuiPanel paddingSize={paddingSize} hasShadow={false} className={'imageWrapper'}>
            <div>
              <img alt={imageAlt} src={imageUrl as string} />
            </div>
          </EuiPanel>
        )}
      </div>
    </div>
  ) : null;
}
