import React from 'react';
import { EuiFlexItem } from '@elastic/eui';
import { TextField } from '@sharedComponents/formElements';

export const NodeBodyPage = (
  <>
    <EuiFlexItem>
      <TextField fieldName="title" rowProps={{ label: 'Title:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
  </>
);
