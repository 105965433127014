import { FormNodeProps } from '../../apply/FormNodeProps';
import React from 'react';
import 'react-slidedown/lib/slidedown.css';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import FormList from './FormList';
import './FormList.scss';

export default function FormRecommendersList(props: FormNodeProps) {
  const scholarship: ScholarshipModel = (props.application && props.application.scholarship) || {};

  return (
    <FormList
      {...props}
      required={scholarship.minRecommenders || 0}
      canHaveZeroEntries={true}
      help={scholarship.allowedRecommenders || undefined}
    />
  );
}
