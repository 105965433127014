import React, { useState } from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { MailingModelSchema } from '@sharedComponents/schemas/MailingModelSchema';
import HTMLTemplateSetup from './MailingTemplate/HTMLTemplateSetup';
import PDFTemplateSetup from './MailingTemplate/PDFTemplateSetup';
import { useFormContext } from 'react-hook-form';

enum TEMPLATE_TYPE {
  PDF = 'PDF',
  HTML = 'HTML'
}

const MailingTemplate = ({ nextStepHandler }) => {
  const formContext = useFormContext();
  const [templateType, setTemplateType] = useState<TEMPLATE_TYPE>();

  const handleTemplateChange = (_templateType: TEMPLATE_TYPE) => {
    setTemplateType(_templateType);

    formContext.setValue('isHTMLtemplateUsed', _templateType === TEMPLATE_TYPE.HTML);
  };

  return (
    <EuiFlexGroup direction="column">
      {!templateType ? (
        <>
          <EuiFlexItem>Select which type of flyer to use:</EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>
                <EuiButton onClick={() => handleTemplateChange(TEMPLATE_TYPE.PDF)}>Upload PDF</EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={() => handleTemplateChange(TEMPLATE_TYPE.HTML)}>Use Template & QR Code</EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </>
      ) : null}
      <EuiFlexItem>
        <EuiFlexGroup direction="column">
          {templateType === TEMPLATE_TYPE.PDF ? (
            <PDFTemplateSetup />
          ) : templateType === TEMPLATE_TYPE.HTML ? (
            <HTMLTemplateSetup nextStepHandler={nextStepHandler} />
          ) : null}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

MailingTemplate.validationRules = MailingModelSchema.pick([
  'pdf_file_id',
  'pdf_page_size',
  'html_variables',
  'isHTMLtemplateUsed'
]);

export default MailingTemplate;
