import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiRadioGroup, EuiRadioGroupOption, EuiRadioGroupProps } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function RadioGroupField({
  fieldName,
  fieldProps,
  radios,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<EuiRadioGroupProps>;
  radios: (Required<Pick<EuiRadioGroupOption, 'value'>> & Omit<EuiRadioGroupOption, 'id'>)[];
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        return (
          <EuiRadioGroup
            options={radios.map(radio => ({
              ...radio,
              label: radio.label || radio.value,
              id: `${fieldName}_${radio.value}`
            }))}
            idSelected={`${fieldName}_${value}`}
            onChange={id => {
              onChange(id.replace(`${fieldName}_`, ''));
            }}
            {...fieldProps}
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow rowKey={fieldName} rowProps={{ ...rowProps }} isInvalid={!!fieldError} errorMessage={fieldError?.message}>
      {field}
    </FormRow>
  ) : (
    field
  );
}
