import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiSwitch, EuiSwitchProps, htmlIdGenerator } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function SwitchField({
  label,
  fieldName,
  fieldProps,
  rowProps
}: {
  label: string;
  fieldName: string;
  fieldProps?: Partial<EuiSwitchProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      defaultValue={false}
      render={({ onChange, value, name }) => (
        <EuiSwitch
          label={label}
          id={htmlIdGenerator()()}
          onChange={e => onChange(e.target.checked)}
          name={name}
          checked={!!value}
          {...fieldProps}
        />
      )}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={!!fieldError}
      errorMessage={fieldError ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
