import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';
import LoadingPrompt from '@sharedComponents/common/LoadingPrompt';

import recurrentAwardApplicationQuery from './RecurringAwardUserView/queries/recurrentAwardApplicationQuery';
import RecurrentAwardCard from './RecurringAwardUserView/RecurrentAwardCard';
import RecurrentAwardUserDetails from './RecurringAwardUserView/RecurrentAwardUserDetails';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import scholarshipEntryQuery from '@sharedComponents/queries/scholarshipEntryQuery';
import ScholarshipApplicationsBreadcrumbs from '@sharedComponents/ScholarshipApplications/ScholarshipApplicationsBreadcrumbs';

function RecurringAwardUserView({ recordID, scholarshipID }: { recordID: number; scholarshipID: number }) {
  const {
    data: recurrentAwardApplications,
    isLoading: isApplicationsLoading,
    isError: isApplicationsError
  } = recurrentAwardApplicationQuery.useRecurrentAwards(recordID, scholarshipID);

  const { data: scholarshipEntry } = scholarshipEntryQuery.useScholarshipEntry(scholarshipID, false);

  return (
    <PageContentWrapper restrictWidth={true}>
      <LoadingPrompt isError={isApplicationsError} isLoading={isApplicationsLoading}>
        <EuiFlexGroup direction="column" responsive={false} gutterSize="l">
          <EuiFlexItem grow={false}>
            <ScholarshipApplicationsBreadcrumbs
              scholarship={scholarshipEntry}
              viewMode={0}
              onViewModeReset={() => {}}
              extraItems={[{ text: 'Recurrent award record', href: '' }]}
            />
          </EuiFlexItem>

          {recurrentAwardApplications?.length ? (
            <>
              <EuiFlexItem grow={false}>
                <EuiTitle size="xs">
                  <h4>Recurrent payment: {recurrentAwardApplications[0].email}</h4>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <RecurrentAwardUserDetails
                  record={recurrentAwardApplications[0].record}
                  user={recurrentAwardApplications[0].user as any}
                />
              </EuiFlexItem>
            </>
          ) : null}
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" responsive={false} gutterSize="l">
              <EuiFlexItem grow={false}>
                <EuiText color={'black'} size="m">
                  Payments schedule:
                </EuiText>
              </EuiFlexItem>
              {recurrentAwardApplications?.map(recurrentAward => (
                <EuiFlexItem key={recurrentAward.id} grow={false}>
                  <RecurrentAwardCard recurrentAward={recurrentAward} />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </LoadingPrompt>
    </PageContentWrapper>
  );
}

export default RecurringAwardUserView;
