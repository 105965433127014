import './LayoutHeader.scss';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import unreadQuestionsCount from '@donors/queries/unreadQuestionsCountQuery';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPopover,
  EuiText
} from '@elastic/eui';
import LogoSVG from '@sharedAssets/main_logo_small.svg';
import { USER_TYPE } from '@sharedComponents/constants';
import { useAuthStatus, useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';
import UserModel, { DonorUserData } from '@sharedContract/UserModel';

const UserMenuLink = ({ user }: { user: UserModel }) => {
  const userRepresentative = getUserFullNameFromData(user?.data || {}) || user.name;
  return (
    <EuiText color="#5295ff" size="s">
      {userRepresentative}
    </EuiText>
  );
};

const UserRepresentation = ({ user }: { user: UserModel }) => {
  const userFullName = getUserFullNameFromData(user?.data || {});
  return (
    <EuiText color="subdued" size="s" style={{ wordBreak: 'break-word' }}>
      {userFullName}, {user.name}
    </EuiText>
  );
};

// todo move onLogout to state
// todo cleanup from legacy
const LayoutHeader = ({ onLogout }: { onLogout? }) => {
  // todo grab those from single selector
  const user: UserModel | undefined = useLoggedInUser();
  const isLoggedIn = useAuthStatus();

  const { data: unreadQuestions } = unreadQuestionsCount.useUnreadQuestionsCount({
    enabled: !!(user && [USER_TYPE.ADMIN, USER_TYPE.DONOR].includes(user.type as USER_TYPE))
  });

  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  const onLogoutHandler = () => {
    setIsUserMenuVisible(false);
    onLogout && onLogout();
  };

  const userMenu = user ? (
    <>
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiPopover
            id="userMenu"
            button={
              <EuiHeaderSectionItemButton
                aria-controls="userMenu"
                aria-expanded={isUserMenuVisible}
                aria-haspopup="true"
                aria-label="User menu"
                onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
              >
                <UserMenuLink user={user} />
              </EuiHeaderSectionItemButton>
            }
            isOpen={isUserMenuVisible}
            anchorPosition="downRight"
            closePopover={() => setIsUserMenuVisible(false)}
          >
            <div style={{ width: 280 }}>
              <EuiFlexGroup direction="column" gutterSize="s">
                <EuiFlexItem>
                  <UserRepresentation user={user} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton color="danger" onClick={onLogoutHandler} size="s" fullWidth={false}>
                    Log out
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  ) : (
    <></>
  );

  const isAdmin = user && user.type === 'admin';
  const isReviewer = user && user.type === 'reviewer';
  const isDonor = user && user.type === 'donor';
  const hasQuestions = user && ['admin', 'donor'].includes(user.type);
  const isDataEntry = user && user.type === 'dataEntry';

  type Tab = [string, string | null, string, string?];

  const tabs: Tab[] = (!isReviewer && !isDataEntry ? [['Recent Applications', null, '/applications', '/'] as Tab] : [])
    .concat(isDataEntry ? [['Scholarship Entry', null, '/scholarship-entry'] as Tab] : [])
    .concat([['Scholarships', null, '/scholarships'] as Tab])
    .concat(
      isDonor && (user?.data as DonorUserData).isPremium
        ? [['Application Form Review', null, '/formReview'] as Tab]
        : []
    )
    .concat(hasQuestions && !isReviewer ? [['Questions', null, '/questions'] as Tab] : [])
    .concat(
      isAdmin
        ? [
            ['Forms', null, '/forms'] as Tab,
            ['Organizations', null, '/organizations'] as Tab,
            ['Mailings', null, '/mailings'] as Tab
          ]
        : []
    )
    .concat(
      (isDonor && user?.donor?.canReview && (user?.data as any).isPremium) || isAdmin
        ? [['Users', null, '/users'] as Tab]
        : []
    )
    .concat(isDataEntry || isAdmin ? [['Scholarships to Check', null, '/sclrsToCheck'] as Tab] : [])
    .concat(isDataEntry ? [['Forms', null, '/transcriptRequestForms'] as Tab] : []);

  const currentPath = window.location.pathname;

  let hasTabs = isLoggedIn;

  if (user && user.type === 'donor' && !(user.data as any)?.isPremium) {
    hasTabs = false;
  }

  const headerTheme = hasTabs && isLoggedIn ? 'dark' : 'default';
  return (
    <div className="LayoutHeader">
      <EuiHeader
        theme={headerTheme}
        position="static"
        className="LayoutHeader-top"
        sections={[
          {
            items: [
              <Link to="/" key="logo_link" className="LayoutHeader-logo">
                <EuiIcon className="LayoutHeader-logo_icon" type={LogoSVG} size={'original'} width="200" height="46" />
              </Link>
            ],
            borders: 'none'
          },
          {
            items: [userMenu],
            borders: 'none'
          }
        ]}
      />
      {/** Legacy */}
      {hasTabs ? (
        <div className="LayoutHeader-tabs">
          <div className="LayoutHeader-spacer">&nbsp;</div>
          {tabs.map(([label, abbr, path, linkTo]) => {
            const highlight = path === '/questions' && unreadQuestions;
            return (
              <Link key={path} to={linkTo || path}>
                <div
                  className={
                    'tab' +
                    (currentPath.startsWith(path) || currentPath === linkTo ? ' active' : '') +
                    (highlight ? ' highlight' : '')
                  }
                >
                  <span className="long">{label}</span>
                  <span className="short">{abbr || label}</span>
                  {highlight ? <span className="counter">{unreadQuestions}</span> : null}
                </div>
              </Link>
            );
          })}
          <div className="LayoutHeader-end-spacer">&nbsp;</div>
        </div>
      ) : null}
    </div>
  );
};

export default LayoutHeader;
