import React from 'react';
import './Select.scss';

export default function Select(props) {
  return (
    <div className="Select">
      <select {...props}>{props.children}</select>
      <CaretDown />
    </div>
  );
}

export function CaretDown() {
  return (
    <svg viewBox="0 0 300 300" className="Caret">
      <path d="M30,100L150,200L270,100" />
    </svg>
  );
}
