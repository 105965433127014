import { useMutation, useQuery, UseQueryOptions } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';
import { MailingModelShape } from '@sharedComponents/models';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

const CURRENT_QUERY_KEY = 'mailing';

function useMailing(mailingID: number, options: UseQueryOptions<MailingModelShape, Error, MailingModelShape> = {}) {
  const userClient = useUserClient();

  return {
    ...useQuery<MailingModelShape, Error>([CURRENT_QUERY_KEY, mailingID], () => userClient.getMailing(mailingID), {
      staleTime: 1000 * 60 * 1,
      ...options
    })
  };
}

function useMailingsListing() {
  const userClient = useUserClient();

  return {
    ...useQuery<Omit<MailingModelShape, 'recipients' | 'service_response' | 'html_variables' | 'conversions'>[], Error>(
      [CURRENT_QUERY_KEY, 'listing'],
      () => userClient.getMailingListing(),
      {
        staleTime: 1000 * 60
      }
    )
  };
}

const useSubmitMailing = () => {
  const userClient = useUserClient();

  return {
    ...useMutation<
      MailingModelShape, // returned result result by our api
      Error, // error type
      Partial<MailingModelShape>
      // parameters accepted by mutation
    >((mailing: Partial<MailingModelShape>) => userClient.createMailing(mailing))
  };
};

const useResendMailing = () => {
  const fetcher = useFetcher();

  return {
    ...useMutation<
      MailingModelShape, // returned result result by our api
      Error, // error type
      number
      // parameters accepted by mutation
    >((mailingID: number) => {
      return fetcher().url('/v3/mailings/resend').json({ mailingID: mailingID }).post();
    })
  };
};

const mailingQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useSubmitMailing,
  useMailingsListing,
  useMailing,
  useResendMailing
};
export default mailingQuery;
