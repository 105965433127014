import ScholarshipModel, { ScholarshipPost, FormSelection } from '@sharedClients/types/ScholarshipModel';

// ? TODO is not used anymmore?
export function toScholarshipPost(scholarship: ScholarshipModel): ScholarshipPost {
  const {
    name,
    recommenderInstructions,
    allowedRecommenders,
    essayInstructions,
    minRecommenders,
    deadline,
    description,
    eligibility,
    awardAmountFrom,
    awardAmountTo,
    requireTranscript,
    allowQuestions,
    reviewCriteria,
    applyUrl,
    // guidestarUrl,
    // question,
    // answer,
    originalUrl,
    data,
    donor
  } = scholarship;

  return {
    name,
    recommenderInstructions,
    allowedRecommenders,
    essayInstructions,
    minRecommenders,
    deadline,
    description,
    eligibility,
    awardAmountFrom,
    awardAmountTo,
    requireTranscript,
    allowQuestions,
    reviewCriteria,
    applyUrl,
    // guidestarUrl,
    // question,
    // answer,
    originalUrl,
    data,
    donor,
    form: toFormSelection(scholarship.form),
    recommenderForm: toFormSelection(scholarship.recommenderForm),
    application_cycle: scholarship.application_cycle
  } as any;
}

export function toFormSelection(form: FormSelection | undefined | null): FormSelection | null {
  if (form != null) {
    const { id, name } = form;

    return { id, name };
  } else {
    return null;
  }
}
