import { capitalize } from 'lodash';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiBadge, EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, EuiTitle } from '@elastic/eui';
import { DatePickerField, FieldList, LookupField } from '@sharedComponents/formElements';
import { SPECIAL_FORM_TYPE } from '@sharedComponents/interfaces/Forms.interface';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

import { RecurrentPaymentsConfigurationForm } from '../RecurringAwardsSetup.interface';

function RecurrentPaymentsConfiguration() {
  const formContext = useFormContext<RecurrentPaymentsConfigurationForm>();

  const records = formContext.watch('records');
  const scholarshipClient = useScholarshipClient();

  return (
    <EuiFlexGroup direction="column" responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiTitle size="s">
          <h3>Further disbursements configuration</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>Cohort of students to be assigned to current configuration: </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {records.map((student, key) => (
          <EuiText color={'primary'} key={student.email}>
            <strong>
              {key + 1}. {capitalize(student.name)} {capitalize(student.last_name)}
            </strong>
            , {student.email}{' '}
            <EuiBadge color={student.isRegistered ? 'success' : 'default'}>
              {student.isRegistered ? 'student exists' : 'will be invited'}
            </EuiBadge>
          </EuiText>
        ))}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiTitle size="s">
          <h4>Disbursements schedule</h4>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <FieldList
          fieldName={'disbursementsSchedule'}
          recordDefaultValue={{ date: undefined, amount: 0, form: null }}
          minListItems={1}
          fieldFactoryFn={(fieldNamePrefix, register, field, control) => {
            return (
              <EuiFlexGroup
                direction="row"
                justifyContent="center"
                alignItems="center"
                responsive={false}
                style={{ width: '100%' }}
              >
                <EuiFlexItem>
                  {/* <EuiSuperDatePicker
                    onTimeChange={onTimeChange}
                    start="now-30m"
                    end="now"
                    showUpdateButton={false}
                    isQuickSelectOnly={true}
                    customQuickSelectPanels={[{ title: 'string', content: <>'ReactElement'</> }]}
                  /> */}
                  <DatePickerField
                    fieldName={`${fieldNamePrefix}date`}
                    fieldProps={{
                      minDate: moment.utc().add(1, 'd'),
                      showTimeSelect: false
                    }}
                    rowProps={{
                      label: 'Date',
                      fullWidth: true
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFormRow label="Upcoming disbursement amount" fullWidth={true}>
                    <EuiFieldNumber
                      name={`${fieldNamePrefix}amount`}
                      inputRef={register}
                      defaultValue={field['amount']}
                      title="Amount"
                      prefix="$"
                      fullWidth
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <LookupField
                    lookupFn={async val => {
                      const forms = await scholarshipClient.getForms({
                        page: 0,
                        textFilter: val,
                        fields: [],
                        special_type: SPECIAL_FORM_TYPE.RECURRENT_APPLICATION as any
                      });

                      return forms?.results?.length
                        ? forms.results.map(form => ({ id: form.id, name: form.name }))
                        : [];
                    }}
                    fieldName={`${fieldNamePrefix}form`}
                    fieldProps={{ fullWidth: true }}
                    rowProps={{ label: 'Repayment criteria form' }}
                    searchWithEmptyValue={true}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }}
          rowProps={{ label: 'Disbursement setup', fullWidth: true }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

RecurrentPaymentsConfiguration.validationRules = yup.object({
  disbursementsSchedule: yup.array(
    yup.object().shape({
      date: yup.date(),
      amount: yup.number(),
      form: yup.object().shape({
        id: yup.number(),
        name: yup.string()
      })
    })
  )
});

export default RecurrentPaymentsConfiguration;
