import './LeaveReviewPanel.scss'; // ? why its here?
import './ReviewsPanel.scss';

import React, { useEffect, useState } from 'react';

import {
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiPanel,
  EuiPopover,
  EuiProgress,
  EuiSwitch,
  EuiText,
  EuiToolTip,
  formatDate
} from '@elastic/eui';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import {
  ReviewCriteriaRoundConfiguration,
  ScholarshipWithReviewStatus
} from '@sharedComponents/interfaces/Scholarships.interface';
import applicationReviewsQuery from './queries/applicationReviewsQuery';
import { groupBy } from 'lodash';
import { ReviewModelShape, UserModelShape } from '@sharedComponents/models';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

enum VIEW_MODE {
  MODE_BUTTONS,
  MODE_INPUTS
}

const NO_RESULTS_MESSAGE = 'No-one has reviewed this application yet.';
const NO_RESULTS_MESSAGE_REVIEWER = 'Review not yet provided in this round.';

const reviewerNameRenderer = (item: ReviewModelShape & { user: UserModelShape }) => {
  return (
    <EuiToolTip
      content={`${item.user?.data?.name} submitted this review on ${formatDate(
        item.updated_at || item.created_at,
        'LLL'
      )}`}
    >
      <>{item.user?.data?.name}</>
    </EuiToolTip>
  );
};

/**
 * Rendering applications reviews for latest finished review round or currently active one
 */
export default function ReviewsPanel({
  client,
  applicationId,
  scholarship,
  isReviewer
}: {
  client: ApplicationClient;
  applicationId: number;
  scholarship: ScholarshipWithReviewStatus;
  isReviewer: boolean;
}) {
  const { mostRelevantReviewProcess } = scholarship;
  const { reviewCriteriasConfiguration } = scholarship;

  const [isSettingsPopoverOpen, setIsPopoverOpen] = useState(false);
  const [viewMode, setViewMode] = useLocalStorage<VIEW_MODE>('reviewsPanel.viewMode', VIEW_MODE.MODE_BUTTONS);
  const [roundPanelsExpandStatus, setRoundPanelsExpandStatus] = useState({});

  useEffect(() => {
    if (mostRelevantReviewProcess && Object.keys(mostRelevantReviewProcess).length) {
      const _roundPanelsExpandStatus = {};
      const roundsIDs = mostRelevantReviewProcess.map(reviewRound => reviewRound.id);
      for (const roundID of roundsIDs) {
        _roundPanelsExpandStatus[roundID] = true;
      }

      setRoundPanelsExpandStatus(_roundPanelsExpandStatus);
    }
  }, [mostRelevantReviewProcess]);

  const { data: applicationReviews, isLoading, isError } = applicationReviewsQuery.useApplicationReviews(
    client,
    applicationId
  );

  let content;
  if (isLoading || !applicationReviews?.length || isError || !mostRelevantReviewProcess?.length) {
    content = (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          {isLoading ? (
            <EuiLoadingSpinner size="m" style={{ alignSelf: 'center' }} />
          ) : (
            <>{isReviewer ? NO_RESULTS_MESSAGE_REVIEWER : NO_RESULTS_MESSAGE}</>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    // At this point we received all reviews, but we only need to filter actual review process reviews or last review process if first one is null
    const actualRoundsIDS = mostRelevantReviewProcess?.map(reviewRound => reviewRound.id);
    const actualReviews = applicationReviews.filter(
      review => review.review_round && actualRoundsIDS?.includes(review.review_round)
    );

    const reviewsByRound = groupBy(actualReviews, 'review_round');

    // Building table with per-round reviews
    const buildReviewsTable = (reviews: ReviewModelShape[], criteriasConfig: ReviewCriteriaRoundConfiguration) => {
      const columns: EuiBasicTableColumn<any>[] = [
        {
          id: 'name',
          name: '',
          render: reviewerNameRenderer
        }
      ];

      const maxScale = Object.keys(criteriasConfig).reduce((maximumScale: number, criterion: string) => {
        const criteria = criteriasConfig[criterion];
        return criteria && criteria.scale > maximumScale ? criteria.scale : maximumScale;
      }, 0);

      for (const key of Object.keys(criteriasConfig)) {
        const criterion = criteriasConfig[key];
        columns.push({
          id: key,
          name: (
            <EuiToolTip content={`${criterion.name}`}>
              <>{criterion.name}</>
            </EuiToolTip>
          ),
          render: item =>
            viewMode === VIEW_MODE.MODE_BUTTONS ? (
              <EuiProgress value={item.data[key] || 0} max={maxScale} size="m" color="primary" title={item.data[key]} />
            ) : (
              <EuiText size="s">{item.data[key] || '-'}</EuiText>
            )
        });
      }

      columns.push({
        id: 'total',
        name: 'Total Score',
        align: 'right',
        width: '100px',
        render: item => {
          return (
            <EuiText size="s">
              <strong>{item.score || '-'}</strong>
            </EuiText>
          );
        }
      });

      const itemIdToExpandedRowMap = {};
      reviews.forEach((review, key) => {
        if (review.notes) {
          itemIdToExpandedRowMap[key] = (
            <EuiText size="xs">
              <strong>Notes: </strong>
              {review.notes}
            </EuiText>
          );
        }
      });

      return (
        <EuiBasicTable
          items={reviews}
          columns={columns}
          noItemsMessage={isReviewer ? NO_RESULTS_MESSAGE_REVIEWER : NO_RESULTS_MESSAGE}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        />
      );
    };

    content = (
      <EuiFlexGroup direction="column" gutterSize="s">
        {mostRelevantReviewProcess.map(reviewRound => {
          const roundApplications = reviewsByRound[reviewRound.id];

          const toggleRoundExpand = () => {
            const _roundPanelsExpandStatus = { ...roundPanelsExpandStatus };
            _roundPanelsExpandStatus[reviewRound.id] = !_roundPanelsExpandStatus[reviewRound.id];
            setRoundPanelsExpandStatus(_roundPanelsExpandStatus);
          };

          const isRoundPanelExtended = roundPanelsExpandStatus[reviewRound.id];

          return (
            <EuiFlexItem key={reviewRound.currentRound}>
              <EuiPanel paddingSize="s">
                <EuiFlexGroup direction="column" gutterSize="s">
                  <EuiFlexItem>
                    <EuiFlexGroup direction="row" gutterSize="xl">
                      <EuiFlexItem>
                        <EuiFlexGroup gutterSize="none" direction="row">
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                              style={{ alignItems: 'flex-start' }}
                              onClick={toggleRoundExpand}
                              aria-label="Expand/Collapse reviews"
                              title="Expand/Collapse reviews"
                              iconType={isRoundPanelExtended ? 'arrowUp' : 'arrowDown'}
                              size="s"
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiToolTip
                              content={`Review round ${
                                reviewRound.is_completed ? 'finished' : 'started'
                              } on ${formatDate(
                                reviewRound.is_completed ? reviewRound.updated_at : reviewRound.created_at,
                                'LLL'
                              )}`}
                            >
                              <EuiText size="m">
                                <strong>Round {reviewRound.currentRound}</strong>
                              </EuiText>
                            </EuiToolTip>
                          </EuiFlexItem>
                          <EuiFlexItem> </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                      <EuiFlexItem></EuiFlexItem>

                      <EuiFlexItem grow={false}>
                        {!reviewRound.is_completed ? <EuiBadge color="success">In progress</EuiBadge> : null}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {isRoundPanelExtended
                      ? buildReviewsTable(
                          roundApplications || [],
                          // we grab configuration for specific round if it exists or latest available round
                          reviewCriteriasConfiguration[reviewRound.currentRound] ||
                            reviewCriteriasConfiguration[
                              Object.keys(reviewCriteriasConfiguration)[
                                Object.keys(reviewCriteriasConfiguration).length - 1
                              ]
                            ]
                        )
                      : null}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
            </EuiFlexItem>
          );
        })}
      </EuiFlexGroup>
    );
  }

  return (
    <EuiFlexGroup className="reviewsPanel" direction="column">
      <EuiFlexItem>
        <h2>
          Reviews
          <EuiPopover
            ownFocus
            button={
              <EuiButtonIcon
                iconType="gear"
                onClick={() => setIsPopoverOpen(isSettingsPopoverOpen => !isSettingsPopoverOpen)}
                aria-label="Open review panel settings"
              ></EuiButtonIcon>
            }
            isOpen={isSettingsPopoverOpen}
            closePopover={() => setIsPopoverOpen(false)}
          >
            <EuiFormRow label="Review panel settings">
              <EuiSwitch
                name="toggleNumeric"
                label="Show numeric scores"
                checked={viewMode === VIEW_MODE.MODE_INPUTS}
                onChange={() => {
                  setViewMode(viewMode === VIEW_MODE.MODE_INPUTS ? VIEW_MODE.MODE_BUTTONS : VIEW_MODE.MODE_INPUTS);
                }}
              />
            </EuiFormRow>
          </EuiPopover>
        </h2>
      </EuiFlexItem>
      <EuiFlexItem>{content}</EuiFlexItem>
    </EuiFlexGroup>
  );
}
