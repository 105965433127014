import { useQuery, UseQueryOptions } from 'react-query';
import { ReviewModelShape } from '@sharedComponents/models';

// unique key for this query
const CURRENT_QUERY_KEY = 'reviews';

function useApplicationReviews(
  client,
  applicationId: number,
  cacheKey = CURRENT_QUERY_KEY,
  options: UseQueryOptions<ReviewModelShape[], Error, ReviewModelShape[]> = {}
) {
  const query = {
    ...useQuery<ReviewModelShape[], Error>([cacheKey, applicationId], () => client.getReviews(applicationId), {
      staleTime: 1000 * 60 * 1,
      refetchOnWindowFocus: false,
      ...options
    })
  };

  return query;
}

export default { QUERY_KEY: CURRENT_QUERY_KEY, useApplicationReviews };
