import moment from 'moment';
import React, { useEffect, useState } from 'react';

import {
  EuiBasicTable,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPagination,
  EuiToolTip
} from '@elastic/eui';
import { DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import {
  APPLICATION_DOCUMENT,
  ScholarshipApplicationsDocuments
} from '@sharedComponents/interfaces/Applications.interface';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

import applicationsDocumentsForScholarshipQuery from './queries/applicationsDocumentsForScholarship';

const ITEMS_PER_PAGE = 8;

export default function StudentDocumentationBlock({
  selectedScholarshipId,
  areScholarshipsLoading
}: {
  selectedScholarshipId: number | null;
  areScholarshipsLoading: boolean;
}) {
  let {
    data,
    isLoading: areDocumentsLoading,
    isError,
    error
  } = applicationsDocumentsForScholarshipQuery.useApplicationsDocumentsForScholarship(selectedScholarshipId);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const applicationClient = useApplicationClient();

  const downloadDocument = (e, fileId) => {
    e.preventDefault();
    applicationClient.downloadFile(fileId);
  };

  useEffect(() => {
    setSelectedPageIndex(0);
  }, [selectedScholarshipId, data]);

  let dataSet: ScholarshipApplicationsDocuments = [];
  if (data?.length) {
    let startFrom = selectedPageIndex * ITEMS_PER_PAGE;
    dataSet = data.slice(startFrom, startFrom + ITEMS_PER_PAGE);
  }

  let pageCount = 0;
  if (data?.length) {
    pageCount = Math.ceil(data!.length / ITEMS_PER_PAGE);
  }

  return (
    <div className={'DonorHomepage__statBlock studentDocumentationBlock'}>
      <EuiFlexGroup direction={'column'} responsive={false}>
        <EuiFlexItem className={'DonorHomepage__statBlockHeader'} grow={false}>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={false}>STUDENT DOCUMENTS</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className={'DonorHomepage__statBlockBody'}>
          {!!dataSet?.length ? (
            <EuiFlexGroup responsive={false} direction={'column'} justifyContent={'spaceBetween'}>
              <EuiFlexItem>
                <EuiBasicTable
                  className={'DonorHomepage__basicTable'}
                  items={dataSet}
                  columns={[
                    {
                      field: 'user',
                      name: 'Student',
                      truncateText: true,
                      width: '30%'
                    },
                    {
                      field: 'date',
                      name: 'Date Uploaded',
                      truncateText: true,
                      render: date => moment(date).format('MM/DD/YYYY'),
                      width: '20%'
                    },
                    {
                      field: 'type',
                      name: 'Type',
                      truncateText: true,
                      render: type => {
                        switch (type) {
                          case APPLICATION_DOCUMENT.LETTERS_OF_RECOMMENDATION:
                            return 'Letter of Recommendation';
                          case APPLICATION_DOCUMENT.APPLICATION:
                            return 'Application';
                          default:
                            return 'Document';
                        }
                      },
                      width: '30%'
                    },
                    {
                      field: 'file',
                      name: 'Download',
                      truncateText: true,
                      render: (
                        fileId // TODO hadle own state and display loading
                      ) => (
                        <div className={'studentDocumentationBlock__downloadFile'}>
                          <EuiToolTip content="Click to download the document.">
                            <a href={'/#'} onClick={e => downloadDocument(e, fileId)}>
                              <img src="/images/download.png" alt="download" />
                            </a>
                          </EuiToolTip>
                        </div>
                      ),
                      width: '20%'
                    }
                  ]}
                />
              </EuiFlexItem>
              {pageCount > 1 && (
                <EuiFlexItem grow={false}>
                  <EuiPagination
                    aria-label="pagination"
                    pageCount={pageCount}
                    activePage={selectedPageIndex}
                    onPageClick={pageIndex => setSelectedPageIndex(pageIndex)}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup
              justifyContent={'center'}
              alignItems={'center'}
              className={'DonorHomepage__statBlockEmptyContent'}
              responsive={false}
            >
              {isError ? (
                <EuiFlexItem className={'DonorHomepage__statBlockError'} grow={false}>
                  <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
                    {error?.message || ''}
                  </EuiCallOut>
                </EuiFlexItem>
              ) : areScholarshipsLoading || areDocumentsLoading ? (
                <EuiFlexItem grow={false}>
                  <EuiLoadingSpinner size={'l'} />
                </EuiFlexItem>
              ) : (
                <EuiFlexItem grow={false}>No documents found</EuiFlexItem>
              )}
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
