import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { ApplicationModelShape } from '@sharedComponents/models';
import { ApplicationsToScholarship } from '@sharedComponents/ScholarshipApplications.interface';

import applicationsToScholarshipQuery from '../queries/applicationsToScholarshipQuery';
import useActiveCategory from './useActiveCategory';

// ? those params eventually may be in atom with activeCategory
export default function useActiveCategoryApplications(
  scholarshipId,
  textFilter,
  applicationCycle,
  pageIndex,
  sort
): {
  applications;
  applicationsToScholarship: ApplicationsToScholarship | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { value: activeCategory } = useActiveCategory();

  const {
    data: applicationsToScholarship,
    isLoading,
    isError
  } = applicationsToScholarshipQuery.useApplicationsToScholarship(
    scholarshipId,
    activeCategory,
    textFilter,
    applicationCycle,
    pageIndex,
    sort
  );

  return {
    applications:
      !isLoading && !isError && applicationsToScholarship
        ? (applicationsToScholarship[activeCategory] as Page<ApplicationModelShape>)
        : { results: [], total: 0 },
    applicationsToScholarship: applicationsToScholarship,
    isLoading,
    isError
  };
}
