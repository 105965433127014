import NodeProps from './FormNodeProps';
import { transformToNumberRangeFieldValue } from '@apply/apply/fields/FormFieldNumberRange';

export default function FormFieldValueNumberRange(props: NodeProps & { value: any }) {
  const { value } = props;

  let formattedValue = transformToNumberRangeFieldValue(value);

  if (formattedValue.every(v => !v)) {
    return '—';
  } else {
    return `${formattedValue[0] || '...'} - ${formattedValue[1] || '...'}`;
  }
}
