import { isNil } from 'lodash';

import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { FormStateInternals } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationPageType, ApplicationSectionSchema } from '@sharedComponents/schemas/FormNodesSchema';

import FormNodeBuilder from '../FormNodeBuilder';
import { useFormNodeAdding } from '../hooks/useFormNodeModifiers';

const PageChildrenBuilder = ({ pageNode }: { pageNode: ApplicationPageType & FormStateInternals }) => {
  const addFormNodeByPath = useFormNodeAdding();

  const { children } = pageNode;

  if (isNil(children?.length)) {
    // eslint-disable-next-line no-console
    console.error('Wrong field pass into children builder');
    return null;
  }

  const addNewSectionHandler = () => {
    // TODO types and better way to access children
    addFormNodeByPath(ApplicationSectionSchema.getDefault(), `${pageNode.internalPath}.children`);
  };

  return (
    <EuiFlexGroup direction="column" responsive={false}>
      {children.map((pageNode, index) => (
        <EuiFlexItem key={`field_${index}`} grow={false}>
          <FormNodeBuilder node={pageNode} />
        </EuiFlexItem>
      ))}
      <EuiFlexItem>
        <EuiButton color="accent" onClick={addNewSectionHandler}>
          Add new section
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

PageChildrenBuilder.validatePage = (pageNode: ApplicationPageType) => {
  // validate page
  const properDescenders = FormNodeBuilder.getDeepDescendants(pageNode, false);
  const dirtyDescenders = FormNodeBuilder.getDeepDescendants(pageNode, true);

  const isValid = properDescenders.length === dirtyDescenders.length;
  return {
    isValid,
    message: !isValid
      ? `This page contains ${dirtyDescenders.length} fields, but we can recognize only ${properDescenders.length}. Please, correct this page manually.`
      : null
  };
};

export default PageChildrenBuilder;
