import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { ScholarshipClient } from '@sharedClients/main';
import { loggedInUserState } from '@sharedComponents/atoms/loggedInUserState';
import { wrapToCatch401s } from '@sharedComponents/utils/wrappedFetch';

export function useScholarshipClient() {
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const scholarshipClientCallback = useMemo(() => {
    const wrappedFetch = wrapToCatch401s(window.fetch, () =>
      setLoggedInUser(() => ({
        jwt: null,
        user: undefined
      }))
    );

    const scholarshipClient = new ScholarshipClient(
      wrappedFetch,
      null,
      loggedInUser.jwt as any /** fixme ts version */
    );
    return scholarshipClient;
  }, [loggedInUser.jwt]);

  return scholarshipClientCallback;
}
