import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { useRecoilValue } from 'recoil';

import { EuiFlexGroup, EuiFlexItem, EuiForm, EuiText } from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { MAILING_LAST_STEP_NUMBER } from '@sharedComponents/interfaces/Mailing.interface';
import { MailingModelShape } from '@sharedComponents/models';
import { MailingModelSchema } from '@sharedComponents/schemas/MailingModelSchema';

import { recipientsSetForMailing } from './CreateMailing/atoms/addressVerificationState';
import MailingAddressVerification from './CreateMailing/MailingAddressVerification';
import MailingCSV from './CreateMailing/MailingCSV';
import MailingNavigation from './CreateMailing/MailingNavigation';
import MailingTemplate from './CreateMailing/MailingTemplate';
import PrintOptions from './CreateMailing/PrintOptions';
import SummaryPage from './CreateMailing/SummaryPage';
import mailingQuery from './queries/mailingQuery';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import BasicModal from '../../components/BasicModal';

const MAILING_STEPS = {
  1: MailingTemplate,
  2: MailingCSV,
  3: MailingAddressVerification,
  4: PrintOptions,
  5: SummaryPage
};

const CreateMailing = () => {
  const createModal = useModalCreate();
  const queryClient = useQueryClient();
  const router = useRouter();
  const [wizardInstance, setWizardInstance] = useState<StepWizardChildProps>();
  const [currentStep, setCurrentStep] = useState(1);

  const CurrentStepComponent = MAILING_STEPS[currentStep];

  const formMethods = useForm<Partial<MailingModelShape>>({
    reValidateMode: 'onChange',
    resolver: yupResolver(CurrentStepComponent.validationRules),
    defaultValues: {
      ...MailingModelSchema.getDefault()
    },
    shouldUnregister: false // to collect data between steps
  });

  // subscribing to recipients atom changes
  const recipients = useRecoilValue(recipientsSetForMailing);

  useEffect(() => {
    // once we retrieve out results, instantly update form with values which are already ready for sending
    formMethods.setValue('recipients', recipients);
  }, [recipients]);

  const { mutateAsync: submitMailingAsync, isLoading } = mailingQuery.useSubmitMailing();

  const returnBackHandler = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);

      wizardInstance?.goToStep(currentStep - 1);
    } else {
      router.push('/mailings');
    }
  };

  const nextStepHandler = formMethods.handleSubmit(
    async entry => {
      if (currentStep !== MAILING_LAST_STEP_NUMBER) {
        setCurrentStep(currentStep + 1);

        wizardInstance?.nextStep();
      } else if (currentStep === MAILING_LAST_STEP_NUMBER) {
        await submitMailingAsync(entry);

        createModal(({ closeModal }) => {
          const closeModalHandler = () => {
            closeModal();
            queryClient.invalidateQueries(mailingQuery.QUERY_KEY);
            router.push('/mailings');
          };

          return (
            <BasicModal closeModal={closeModalHandler} title={'Mailing was sent to LOB'}>
              <>
                <EuiText>Your mail was sent to LOB for processing.</EuiText>
                <EuiText>It will take a while in order to get it fully created.</EuiText>
                <EuiText>You can check LOB dashboard or mailings detail view once it will be completed.</EuiText>
              </>
            </BasicModal>
          );
        });
      }
    },
    e => {
      // eslint-disable-next-line no-console
      console.log('submit error:', e);
    }
  );

  const isHTMLtemplateUsed = formMethods.watch('isHTMLtemplateUsed');
  const hasOwnNavigation = isHTMLtemplateUsed === true && currentStep === 1; // html template handles itself
  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText size="s">
                Step {currentStep} out of {MAILING_LAST_STEP_NUMBER}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <FormProvider {...formMethods}>
            <EuiForm component="form" onSubmit={nextStepHandler}>
              <StepWizard
                instance={instance => setWizardInstance(instance as StepWizardChildProps)}
                isHashEnabled={false}
                isLazyMount={true}
                initialStep={1}
              >
                <MailingTemplate nextStepHandler={nextStepHandler} />
                <MailingCSV />
                <MailingAddressVerification />
                <PrintOptions />
                <SummaryPage />
              </StepWizard>
            </EuiForm>
          </FormProvider>
        </EuiFlexItem>
        {!hasOwnNavigation ? (
          <EuiFlexItem>
            <MailingNavigation
              currentStep={currentStep}
              nextStepHandler={nextStepHandler}
              returnBackHandler={returnBackHandler}
              isLoading={isLoading}
            />
          </EuiFlexItem>
        ) : null}
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

export default CreateMailing;
