import React from 'react';

import formatDate from '@sharedComponents/utils/formatDate';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import UserModel from '@sharedContract/UserModel';
import DraftIcon from '@sharedAssets/draft.png';
import './ScholarshipList.scss';

const defaultEmptyMessage = 'No scholarships found. Click the plus button at the bottom right to create one.';

export default function ScholarshipList({
  scholarships,
  user,
  emptyMessage
}: {
  scholarships: ScholarshipModel[];
  emptyMessage?: string;
  user: UserModel;
}) {
  const isAdmin = user && user.type === 'admin';

  return (
    <div className="scholarship-list">
      {/** TODO: class consistency */}
      <div className="scholarship header">
        <div className="name">Scholarship</div>
        <div className="applicationCount">Applied</div>
        <div className="deadline">Deadline</div>
        <div className="createdAt">Date</div>
        <div className="isDraft">Draft</div>
      </div>
      {scholarships.map((scholarship, index) => (
        <a href={getScholarshipLink(scholarship, user)} key={index} className="scholarship selectable">
          <div className="name">
            {scholarship.name}
            {isAdmin && scholarship.donor ? <div className="line2">{scholarship.donor.name}</div> : null}
          </div>
          <div className="applicationCount">
            {scholarship.applyUrl ? (
              <div className="external">ext</div>
            ) : (scholarship.applicationCount as number) > 0 ? (
              scholarship.applicationCount
            ) : (
              ''
            )}
          </div>
          <div className="deadline">{formatDate(scholarship.deadline)}</div>
          <div className="createdAt">
            {scholarship.createdAt ? formatDate(scholarship.createdAt) : '-'}
            <div className="line2">{scholarship.updatedAt ? 'edited ' + formatDate(scholarship.updatedAt) : ''}</div>
          </div>
          <div className="isDraft">
            {scholarship.isDraft ? <img className="isDraft-icon" src={DraftIcon} title="Is draft" alt="draft" /> : null}
          </div>
        </a>
      ))}
      {scholarships.length === 0 ? (
        <div className="scholarship selectable">
          <div className="line2">{emptyMessage || defaultEmptyMessage}</div>
        </div>
      ) : (
        []
      )}
    </div>
  );
}

// TODO move to shared
export function getScholarshipLink(scholarship: ScholarshipModel, user?: UserModel) {
  const { id } = scholarship;
  const authorizedRoles = ['admin', 'dataEntry', 'counselor'];
  return (
    // ? This 'authorizedRoles' part is tricky. Its not a scholarship link at all, its applications link.
    `/scholarships/${scholarship.isDraft ? 'drafts/' : ''}${id}` +
    (user && !authorizedRoles.includes(user.type) ? '/applications' : '')
  );
}
