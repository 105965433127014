import React from 'react';

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiLoadingSpinner, EuiTitle } from '@elastic/eui';

import mailingQuery from './queries/mailingQuery';
import Mailing from './ViewMailing/Mailing';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';

const ViewMailing = ({ id }) => {
  const { data, isLoading } = mailingQuery.useMailing(id);

  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem>
          <EuiFlexGroup direction="row" responsive={false} gutterSize="xs">
            <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
              <EuiLink href="/mailings">
                <EuiIcon type="returnKey" />
              </EuiLink>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiTitle size="s">
                <h4>Mailing ID: {id}</h4>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          {isLoading ? (
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : data ? (
            <Mailing mailing={data} />
          ) : null}
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

export default ViewMailing;
