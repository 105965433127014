import warn from '@donors/base/warn';

export function toList(object: any): any[] {
  if (object == null) {
    return [];
  } else if (isList(object)) {
    return object;
  } else {
    warn('Object found where list expected');

    return [];
  }
}

export function isString(object: any) {
  return typeof object == 'string';
}

export function toString(object: any) {
  return (object && object.toString()) || '';
}

export function isList(object: any) {
  return typeof object == 'object' && object != null && object.length != null;
}
