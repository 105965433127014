import { DetailedReviewRoundResponse } from '@sharedComponents/interfaces/Applications.interface';
import { useQuery, UseQueryOptions } from 'react-query';

// unique key for this query
const CURRENT_QUERY_KEY = 'reviewRoundDetails';

function useReviewRoundDetailsQuery(
  applicationClient,
  roundID: number = 0, // due to 'enabled' value it wont be fetched with no param, but lets such syntax to exist
  cacheKey = CURRENT_QUERY_KEY,
  options: UseQueryOptions<DetailedReviewRoundResponse, Error, DetailedReviewRoundResponse> = {}
) {
  const query = {
    ...useQuery<DetailedReviewRoundResponse, Error>(
      [cacheKey, roundID],
      () => applicationClient.getReviewRoundDetails(roundID),
      {
        enabled: roundID > 0, // 0 is the case with optional param when no param yet provided
        ...options
      }
    )
  };

  return query;
}

const reviewRoundDetailsQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useReviewRoundDetailsQuery };
export default reviewRoundDetailsQuery;
