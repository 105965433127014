import React, { useEffect, useState } from 'react';
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import { AllEventsData, EVENT_AUTHORIZED_TYPE } from '@sharedComponents/interfaces/Events.interface';
import { sumBy, get, mapKeys } from 'lodash';
import moment from 'moment';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import NQSChartModal from './NewQualifyingStudentsNumberBlock/NQSChartModal';
import NQSChart from './NewQualifyingStudentsNumberBlock/NQSChart';
import { ScholarshipModelShape } from '@sharedComponents/models';

export default function NewQualifyingStudentsNumberBlock({
  eventsData,
  dateRangeFilter,
  selectedScholarship,
  isLoading
}: {
  eventsData: AllEventsData | undefined;
  dateRangeFilter: number;
  selectedScholarship: ScholarshipModelShape | null;
  isLoading: boolean;
}) {
  const [value, setValue] = useState<[string, number][]>([]);
  const createModal = useModalCreate();

  useEffect(() => {
    const data: any = get(eventsData, `eventsAuthorized.${EVENT_AUTHORIZED_TYPE.SCHOLARSHIP_VETTED}`) || [];

    if (!data.length || data.every(el => !el.count)) {
      setValue([]);
    } else {
      let chartData;

      // adding 0 values to days with no info
      chartData = [];

      let dataMapByDays = mapKeys(data, el => moment(el.date).format('MM/DD/YYYY'));
      let dateFromMoment = moment().subtract(dateRangeFilter - 1, 'days');
      let dayIterator = moment();

      // if 'Lifetime' is selected - counting from scholarship 'createdAt'
      if (dateRangeFilter === 3650 && selectedScholarship) {
        dateFromMoment = moment(selectedScholarship.created_at);
      }

      while (dateFromMoment.isSameOrBefore(dayIterator)) {
        let dayKey = dayIterator.format('MM/DD/YYYY');

        chartData.push([dayKey, dataMapByDays[dayKey] ? dataMapByDays[dayKey].count : 0]);

        dayIterator.subtract(1, 'day');
      }

      chartData = chartData.reverse();

      setValue(chartData);
    }
  }, [eventsData]);

  const count = sumBy(value, '[1]');

  const showChartModal = () => {
    createModal(({ closeModal }) => <NQSChartModal closeModal={closeModal} data={value} />);
  };

  const isLifetimeRangeSelected = dateRangeFilter === 3650;

  return (
    <div className={'DonorHomepage__statBlock newQualifyingStudentsNumberBlock'}>
      <EuiFlexGroup responsive={false} direction={'column'}>
        <EuiFlexItem grow={false} className={'newQualifyingStudentsNumberBlock__statData'}>
          <EuiFlexGroup responsive={false} direction={'column'}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup responsive={false} direction={'column'} className={'DonorHomepage__statInfo'}>
                <EuiFlexItem className={'DonorHomepage__statNumber'}>
                  {isLoading ? <EuiLoadingSpinner size={'l'} /> : <>{count}</>}
                </EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statName'}>New Qualifying Students</EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statDescription'}>
                  The{isLifetimeRangeSelected && ' total'} number of students who answered "yes" to all of your
                  pre-qualification questions
                  {!isLifetimeRangeSelected && ` in the past ${dateRangeFilter} day${dateRangeFilter === 1 ? '' : 's'}`}
                  .
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {!!value?.length ? (
          <EuiFlexItem className={'newQualifyingStudentsNumberBlock__chart'}>
            <EuiFlexGroup direction={'column'} responsive={false}>
              <EuiFlexItem grow={false} className={'newQualifyingStudentsNumberBlock__modalBtn'}>
                <EuiButtonIcon iconType="popout" aria-label="modal" onClick={showChartModal} />
              </EuiFlexItem>
              <EuiFlexItem className={'newQualifyingStudentsNumberBlock__chartContainer'}>
                <NQSChart data={value} projectionClickHandler={showChartModal} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem className={'newQualifyingStudentsNumberBlock__emptyChartBlock'}>
            No data to display. <br />
            Check back soon for updates!
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  );
}
