import { array, boolean, date, InferType, mixed, number, object, string } from 'yup';

import allAreas from '../../contract/areas';

import { USER_TYPE } from '../constants';
import academic_years from '../models/enumValues/academic_years';
import athletics from '../models/enumValues/athletics';
import college_types from '../models/enumValues/college_types';
import ethnicities from '../models/enumValues/ethnicities';
import genders from '../models/enumValues/genders';
import miscellaneous from '../models/enumValues/miscellaneous';
import religious from '../models/enumValues/religious';
import selfIdentification from '../models/enumValues/selfIdentification';
import allStates from '../models/enumValues/states';
import studyRates from '../models/enumValues/study_rates';
import veteran_status from '../models/enumValues/veteran_status';

// represents schema for user data jsonb
export const UserModelDataSchema = object({
  name: string().label('First Name'),
  lastName: string().label('Last Name'),
  phone: string().label('Phone').required(), // TODO:IMP better schema to cover our mask?
  occupation: string(),

  // main student's profile
  academicYear: string()
    .label('Academic year')
    .oneOf([...academic_years, null])
    .nullable(),
  gpa: number()
    .optional()
    .label('GPA')
    .min(0)
    .max(5)
    .transform(val => parseFloat(val) || null)
    .nullable(),
  gender: string()
    .label('Gender')
    .oneOf([...genders, null])
    .nullable(),

  smsnofification: boolean().default(true),
  emailNotification: boolean().default(true),

  school: object().shape({
    //!  TODO: that field format is different between counselors and students
    school: object()
      .label('School name')
      .shape({
        id: number(),
        name: string(),
        state: string().optional()
      })
      .nullable(),
    graduationyear: number().min(2000).max(2050).nullable() // TODO: figure out graduationYear or graduationyear
  }),
  collegeType: array(string().oneOf(college_types)).nullable(),
  studyRates: array(string().oneOf(studyRates)).optional().nullable(),
  area: array(string().oneOf(allAreas)).optional().nullable(),
  college: object().shape({
    firstGen: boolean(),
    // !!! Somehow some students had this college field as object, but not an array! FIXME!
    applyingTo: array(
      object().shape({
        college: object().shape({
          name: string(),
          id: number(),
          type: string().optional()
        }),
        state: string(),
        city: string()
      })
    )
  }),
  lowIncome: boolean().nullable(),
  communityService: boolean().nullable(),

  // field was migrated, we have to clear not supported values
  ethnicity: array()
    .of(string().oneOf(ethnicities))
    .compact(function (v) {
      return !ethnicities.includes(v);
    })
    .nullable(),
  religious: array(string().oneOf(religious)).nullable(),
  selfIdentification: array(string().oneOf(selfIdentification)).nullable(),
  veteranStatus: array(string().oneOf(veteran_status)).nullable(),
  athletics: array(string().oneOf(athletics)).nullable(),
  miscellaneous: array(string().oneOf(miscellaneous)).nullable(),

  // student's address
  state: string().label('State').oneOf(Object.keys(allStates)),
  address: object().shape({
    residency: object().shape({
      street: string().label('Street'),
      street2: string(),
      city: object()
        .label('City')
        .shape({
          name: string().label('City')
        })
        .typeError('City is a required field'), // happens when empty value transformed
      zip: string().label('ZIP'),
      county: object()
        .label('County')
        .shape({
          name: string().label('City')
        })
        .typeError('County is a required field') // happens when empty value transformed
    })
  }),
  counselortype: string().oneOf([
    ...['College Counselors', 'Post High Counselors', 'Grade Level Counselors', 'Academy Counselors'],
    null
  ]),
  numberofstudents: number()
    .integer()
    .transform(val => parseFloat(val) || null)
    .nullable(),

  /**
   * @counselors @donors
   * undefined by default for legacy created donors
   */
  isVerified: boolean().optional()
});

export type UserModelDataSchemaType = InferType<typeof UserModelDataSchema>;

/**
  The "shared" schema is effectively the API schema. These are the fields exchanged in the API, common to client and server.
 */
export const UserModelSchema = object({
  // none of these fields are required
  // since they are not actually set until saved to the database
  id: number().integer().notRequired(),
  createdAt: date().notRequired(),
  updatedAt: date().notRequired(),
  // end of system fields

  name: string().email().required(), // User Email actually
  donor_id: number().nullable(),
  type: mixed<USER_TYPE>().oneOf(Object.values(USER_TYPE)).required(),

  /** @counselors */
  is_email_confirmed: boolean().default(false),

  data: UserModelDataSchema.clone()
  // ... TODO more fields
});

// yup magically creates TypeScript type definition here
export type UserModelType = InferType<typeof UserModelSchema>;

// ? Any way to make properties optionally available with less efford than
export const UserListingFilterSchema = object({
  name: string().notRequired().default(undefined),
  graduationYears: array(number()).notRequired().default(undefined)
});

export type UserListingFilterType = InferType<typeof UserListingFilterSchema>;
