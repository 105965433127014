import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';
import BackgroundFileUploader, { BackgroundFileUploaderProps } from '@sharedComponents/common/BackgroundFileUploader';

/** Represents File Input with background file uploading */
export default function FileField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<BackgroundFileUploaderProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const errorObject = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!errorObject;
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={isInvalid}
      error={errorObject?.message || ''}
      render={({ onChange, value }) => <BackgroundFileUploader {...fieldProps} onChange={onChange} value={value} />}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? errorObject?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
