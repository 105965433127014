import { parseInt } from 'lodash';
import moment from 'moment';

import { RecurrentPaymentsConfigurationForm } from '@donors/routes/RecurringAwardsSetup.interface';
import { EuiBasicTable, EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { DEFAULT_DATE_FORMAT } from '@sharedComponents/config';

function SchedulePreview({ schedule }: { schedule: RecurrentPaymentsConfigurationForm['disbursementsSchedule'] }) {
  const columns: EuiBasicTableColumn<typeof schedule[0]>[] = [
    {
      field: 'date',
      name: 'Date',
      truncateText: false,
      render: value => {
        const momentDate = moment(value);

        return `${momentDate.format(DEFAULT_DATE_FORMAT)} (${moment().to(momentDate)})`;
      }
    },
    {
      field: 'amount',
      name: 'Amount',
      render: value => {
        const numeric = value ? parseInt(value) : null;
        return numeric ? `$ ${numeric.toLocaleString()}` : '';
      }
    },
    {
      field: 'form',
      name: 'Form',
      render: (form: typeof schedule[0]['form']) => {
        return form.name;
      }
    }
  ];

  return (
    <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
      <EuiFlexItem>
        <EuiText>
          Total disbursements planned: <strong>{schedule.length}</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiBasicTable tableCaption="Planned disbursements" items={schedule} columns={columns} itemId="email" />
      </EuiFlexItem>
      <EuiFlexItem>{/** todo email preview? */}</EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default SchedulePreview;
