import React from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import BooleanRadioGroupField from '@sharedComponents/formElements/combined/BooleanRadioGroupField';
import ComboboxField from '@sharedComponents/formElements/simpleFields/ComboboxField';
import NumberField from '@sharedComponents/formElements/simpleFields/NumberField';
import TextAreaField from '@sharedComponents/formElements/simpleFields/TextAreaField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import useClientFetching from '@sharedComponents/hooks/useClientFetching';
import ReviewCriteriaWidget from '@sharedComponents/Scholarship/EditScholarship/ReviewCriteriaWidget';
import FileField from '@sharedComponents/formElements/combined/FileField';

import AbstractEntryStep from './AbstractEntryStep';

/** Helper component to handle async loading of formfields options
 * TODO:IMP: would be better to have exactly this case to be executed once, instead of two times per render here
 */
function AsyncFormOptions({ optionsPromise, render }) {
  const [options, { isLoading }] = useClientFetching<any[]>(() => Promise.resolve(optionsPromise()));

  return render(options, isLoading);
}

const EntryStepQA: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    isManagingApplications: yup.boolean().typeError('This choice is required'),
    form_id: yup
      .number()
      .label('Application Form Format')
      .when('isManagingApplications', {
        is: true,
        then: yup.number().required().typeError('Application Form Format is required'),
        otherwise: yup
          .number()
          .nullable()
          .transform(() => null)
      }),
    logo: yup.number().nullable().optional(),
    review_instructions: yup.number().nullable().optional(),
    recommender_form_id: yup
      .number()
      .label('Recommender Form Format')
      .when('isManagingApplications', {
        is: true,
        then: yup.number().required().typeError('Recommender Form Format is required'),
        otherwise: yup
          .number()
          .nullable()
          .transform(() => null)
      }),
    data: yup
      .object()
      .shape({
        allowed_recommenders: yup.string(),
        min_recommenders: yup.number()
      })
      .optional(),
    recommender_instructions: yup.string().nullable(),
    essay_instructions: yup.string().nullable(),
    reviewCriteriasConfiguration: yup.object()
    // review_criteria: yup.object()
  }),
  render: function (rowProps, { watch, control }, scholarshipClient, applicationClient) {
    const { isManagingApplications } = watch(['isManagingApplications']);
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Manage Application Process</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="l">
                <EuiFlexItem>
                  <BooleanRadioGroupField
                    fieldName="isManagingApplications"
                    rowProps={{
                      label: "Manage application process using Scholar's App",
                      className: 'm-longLabel',
                      helpText:
                        'Click "yes" if the scholarship application and application process will be hosted on Scholar\'s App.',
                      ...rowProps
                    }}
                  />
                </EuiFlexItem>
                {isManagingApplications ? (
                  <>
                    <EuiFlexItem>
                      <FileField
                        fieldName={'logo'}
                        rowProps={{
                          label: 'Scholarship Logo',
                          ...rowProps
                        }}
                        fieldProps={{
                          accept: 'image/*',
                          publicUpload: true
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <AsyncFormOptions
                        optionsPromise={async () =>
                          await scholarshipClient
                            .getForms()
                            .then(forms =>
                              forms
                                .filter(form => form.user_type === USER_TYPE.APPLICANT)
                                .map(form => ({ label: form.name, value: form.id }))
                            )
                        }
                        render={(options, isLoading) => (
                          <ComboboxField
                            fieldName={'form_id'}
                            options={options} // TODO: create new form option
                            fieldProps={{
                              fullWidth: true,
                              placeholder: 'Select one',
                              singleSelection: true,
                              isLoading: isLoading
                            }}
                            rowProps={{
                              label: 'Application Form Format *',
                              helpText:
                                'Select the type of application form format. These will be the set of fields that will be included in the application.',
                              ...rowProps
                            }}
                          />
                        )}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <TextField
                        fieldName={'data.allowed_recommenders'}
                        rowProps={{
                          label: 'Allowed recommenders',
                          helpText:
                            'Describe who may or may not act as a recommender. Example: high school sports coaches, but not parents of the student.',
                          ...rowProps
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <NumberField
                        fieldName={'data.min_recommenders'}
                        rowProps={{
                          label: 'Required Number of Recommenders',
                          helpText:
                            'The number of recommenders the student must invite and request a letter of recommendation from.',
                          ...rowProps
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <AsyncFormOptions
                        optionsPromise={async () =>
                          await scholarshipClient
                            .getForms()
                            .then(forms =>
                              forms
                                .filter(form => form.user_type === USER_TYPE.RECOMMENDER)
                                .map(form => ({ label: form.name, value: form.id }))
                            )
                        }
                        render={(options, isLoading) => (
                          <ComboboxField
                            fieldName={'recommender_form_id'}
                            options={options}
                            fieldProps={{
                              fullWidth: true,
                              placeholder: 'Select one',
                              singleSelection: true,
                              isLoading: isLoading
                            }}
                            rowProps={{
                              label: 'Recommender Form Format *',
                              helpText:
                                'Select the type of recommender form format. These will be the set of fields that a recommender will view when submitting a letter of recommendation for the student.',
                              ...rowProps
                            }}
                          />
                        )}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <TextAreaField
                        fieldName={'recommender_instructions'}
                        fieldProps={{ rows: 2 }}
                        rowProps={{
                          label: 'Instruction for Recommenders',
                          helpText:
                            'Describe to the recommender, what type of information must be included in their letter of recommendation for the student.',
                          ...rowProps
                        }}
                      />
                    </EuiFlexItem>
                  </>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="l">
                {isManagingApplications ? (
                  <>
                    <EuiFlexItem grow={false}>
                      <TextAreaField
                        fieldName={'essay_instructions'}
                        rowProps={{
                          label: 'Essay Instructions',
                          helpText:
                            'Describe to the student, what topics must be written about in their essay to apply for this scholarship.',
                          ...rowProps
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <FileField
                        fieldName={'review_instructions'}
                        rowProps={{
                          label: 'Criteria Instructions',
                          helpText:
                            'Upload a PDF of the criteria instructions provided by the donor. This document gives the reviewer details on how to score each criteria.',
                          ...rowProps
                        }}
                        fieldProps={{
                          accept: '.pdf'
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <Controller
                        control={control}
                        name={'reviewCriteriasConfiguration'}
                        isInvalid={false}
                        error={''}
                        render={({ onChange, value }) => (
                          <>
                            <ReviewCriteriaWidget reviewCriteriasConfiguration={value} onChange={onChange} />
                          </>
                        )}
                      />
                    </EuiFlexItem>
                  </>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepQA;
