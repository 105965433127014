import { cloneDeep, set } from 'lodash';
import { selector } from 'recoil';

import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';

import { scholarshipReviewState } from '../atoms/scholarshipReviewState';

const reviewStateActiveCategorySelector = selector<APPLICATION_TYPE | undefined>({
  key: 'reviewStateActiveCategorySelector',
  get: ({ get: getRecoilValue }) => {
    const { activeCategory } = getRecoilValue(scholarshipReviewState);
    return activeCategory;
  },
  set: ({ set: setRecoilState, get: getRecoilValue }, activeCategory) => {
    const _reviewState = cloneDeep(getRecoilValue(scholarshipReviewState));
    set(_reviewState, 'activeCategory', activeCategory);
    setRecoilState(scholarshipReviewState, _reviewState);
  }
});

export default reviewStateActiveCategorySelector;
