export async function postAsync(path: string, params: { [key: string]: string }, method = 'post') {
  let reqConfig = {
    method: method.toUpperCase()
  };

  let reqData = new FormData();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      reqData.append(key, params[key]);
    }
  }

  const res = await fetch(path, {
    ...reqConfig,
    body: reqData
  });

  let resBlob = await res.blob();
  // eslint-disable-next-line no-useless-escape
  let fileNameObj = path.match(/\/([^\/\.\?]+\.[^\/\.\?]+)(?:\?.*){0,1}$/); // regex to find the filename

  const url = window.URL.createObjectURL(resBlob);
  const a = document.createElement('a');
  a.href = url;
  a.download = (fileNameObj && fileNameObj[1]) || 'file';
  a.click();
  window.URL.revokeObjectURL(url);
}
