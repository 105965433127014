import { parseDate } from '@sharedComponents/utils/formatDate';

export function validateRequired(value: any) {
  if (value == null || value.toString().trim() === '') {
    return 'Required';
  } else {
    return null;
  }
}

export function validateLengthAtLeast(minLength: number) {
  return (value: string) => {
    if (!value.trim()) {
      return 'Required';
    } else if (value.trim().length < minLength) {
      return 'Too short';
    } else {
      return null;
    }
  };
}

export function validateDate(str: string) {
  if (!str || !str.trim()) {
    return 'Required';
  }

  try {
    parseDate(str);
    return null;
  } catch (e) {
    return 'Use the date format mm/dd/yy';
  }
}

export function validateDateYearFormatted(str: string) {
  if (!str || !str.trim()) {
    return 'Required';
  }
  if (str.length !== 8 || str.trim().length !== 8) {
    return 'Use the date format mm/dd/yy';
  }

  const msg = isValidDate(str);
  return msg === true ? null : msg;
}

// TODO:IMP: This has to be classificied and reworked
function isValidDate(dateString) {
  let components = dateString.split('/');

  if (components.length !== 3) {
    return 'Use the date format mm/dd/yy';
  }

  let componentNumbers = components.map(s => parseInt(s));

  let month = componentNumbers[0];
  let day = componentNumbers[1];

  let year = componentNumbers[2];

  if (year < 100) {
    year = 2000 + year;
  }

  // Check the ranges of month and year
  if (year < 2000 || year > 2100 || month === 0 || month > 12) {
    return 'invalid date Use the date format mm/dd/yy';
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  if (!(day > 0 && day <= monthLength[month - 1])) {
    return 'invalid day';
  }

  return true;
}

export function validatePhone(value: string) {
  value = value || '';

  const numbers = value.replace(/[^0-9]/g, '');

  if (!value) {
    return 'Required.';
  } else if (value.startsWith('+')) {
    if (numbers.length < 7) {
      return `Number seems too short.`;
    }
  } else {
    if (numbers.length !== 10) {
      return 'Domestic numbers: include 10 digits. International numbers: start with a "+" sign.';
    }
  }

  return null;
}

export function validateEmail(value: string) {
  value = value || '';

  if (value.includes(' ')) {
    return 'May not include spaces.';
  }

  if (value.startsWith('mailto:')) {
    return "Remove 'mailto:'";
  }

  const parts = value.split('@');

  if (parts.length < 2) {
    return 'Include @ symbol.';
  } else if (parts.length > 2) {
    return 'Multiple @ characters';
  }

  let [user, domain] = parts;

  if (!user || !domain) {
    return '@ cannot come first or last.';
  }

  domain = domain.toLowerCase();

  if (domain.indexOf('.') < 0) {
    return 'Invalid domain.';
  }

  if (domain.endsWith('.')) {
    return 'Cannot end with dot.';
  }

  return null;
}

export function validateUrl(str: string, { mustHavePath }: { mustHavePath?: boolean } = {}) {
  if (str) {
    str = str.trim();
  }

  if (!str) {
    return 'Required';
  }

  try {
    if (!str.startsWith('http://') && !str.startsWith('https://')) {
      return 'Start with http:// or https://';
    }

    if (mustHavePath && new URL(str).pathname === '/') {
      return 'Should be a link to the application form, not to the main page of a website.';
    }

    return null;
  } catch (e) {
    return 'Not a valid URL';
  }
}
