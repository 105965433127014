import React from 'react';
import { noValue } from '@sharedComponents/Application/Application';
import warn from '@donors/base/warn';
import { isList, toList, toString, isString } from './cast';
import { FormValueComponent, fieldValueToString } from './FormField';
import { getPath } from './getPath';
import { getApplicationSection } from './FormPage';
import FormDataNode from '@sharedClients/types/FormDataNode';

/**
 * Lists the children without labels (as it's obvious which field is what)
 */
export default function FormAddress(props) {
  const { node, application } = props;
  let address = getApplicationSection(application, node);
  const lines = toList(node.children)
    .map(node => childToComponent(node, address))
    .filter(line => !!line);

  if (!lines.length) {
    lines.push(noValue);
  }

  return (
    <div className="address field">
      {node.title ? <div className="label">{toString(node.title)}</div> : null}
      <div className="value">
        {lines.map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </div>
    </div>
  );
}

function childToComponent(node: FormDataNode, address) {
  if (isList(node)) {
    return joinFieldsOnSingleLine(node, address);
  } else {
    node = toRealNode(node);

    const value = getPath(address, toString(node.field).split('/'));
    if (value != null && value !== '') {
      return (
        <div className="field">
          {node.label ? <label>{node.label}</label> : null}
          <div className="value">{FormValueComponent({ node, value })}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

function toRealNode(node) {
  if (typeof node == 'string') {
    return { field: node };
  } else if (isString(node.field)) {
    return node;
  } else {
    warn(`Unknown node in address field ${JSON.stringify(node)}`);

    return { field: 'unknown' };
  }
}

function joinFieldsOnSingleLine(fieldsToJoin, address) {
  return fieldsToJoin
    .map(field => fieldValueToString(getPath(address, toString(field).split('/'))))
    .filter(str => !!str && str !== noValue)
    .join(', ');
}
