import warn from '@sharedComponents/base/warn';
import { FormData, default as FormDataNode } from '@sharedClients/types/FormDataNode';

export default function addStandardFields(standardFields: string[], form: FormData, standardForm: FormData): FormData {
  if (standardForm == null) {
    warn(new Error('No standard form'));
    return [];
  }

  if (standardFields == null) {
    warn(new Error('No standard fields'));
    return [];
  }

  if (standardFields.length === 0) {
    return form;
  }

  let standard = extractStandardFields(standardForm, standardFields) || [];

  return (isList(standard) ? (standard as any[]) : [standard]).concat(isList(form) ? form : [form]);
}

function extractStandardFields(standardForm: FormData, standardFields: string[], path?: string): FormData | null {
  const node = standardForm as FormDataNode;

  path = (path || '') + (node.path ? node.path + '/' : '');

  let result: FormData | null = null;

  if (typeof standardForm === 'object') {
    if ((standardForm as any).length) {
      let list = (standardForm as any[])
        .map(field => extractStandardFields(field, standardFields, path))
        .filter(f => f != null);

      if (list.length) {
        result = list as FormData;
      }
    } else {
      if (node.field && standardFields.includes(path + node.field)) {
        result = standardForm;
      }

      function handleChildren(attr: string): FormData | null {
        if (node[attr]) {
          const children = extractStandardFields(node[attr], standardFields, path) as any[];

          if (children && children.length) {
            return {
              ...node,
              [attr]: children
            };
          }
        }

        return null;
      }

      if (node.children) {
        result = handleChildren('children');
      }

      if (node.columns) {
        result = handleChildren('columns');
      }
    }
  } else if (typeof standardForm === 'string' && standardFields.includes(path + standardForm)) {
    result = standardForm;
  }

  return result;
}

function isList(object: any) {
  return typeof object == 'object' && object != null && object.length != null;
}
