import React, { createRef, KeyboardEvent, useState } from 'react';
import './MultipleSelectWidget.scss';
import './SelectWidget.scss';
import { isList } from '../../model/cast';

export default function MultipleSelectWidget({
  onChange,
  animationTime = 100,
  warnOnMissingRequired,
  selected,
  options,
  optionsByKey,
  id,
  requiredCount,
  isMissingRequiredFields,
  allowUnknown
}: {
  onChange: (value: string[]) => any;
  animationTime?: number;
  warnOnMissingRequired: boolean;
  selected: string[];
  options: string[];
  optionsByKey?: { [key: string]: string };
  id: string;
  requiredCount?: number | null;
  isMissingRequiredFields: () => boolean;
  allowUnknown?: boolean;
}) {
  const [isOpen, setOpen] = useState(false);
  const [animationState, setAnimationState] = useState('closed');
  const [typed, setTyped] = useState<string | null>(null);
  const [hasFocus, setFocus] = useState<boolean>(false);

  const ref = createRef<HTMLInputElement>();
  const keysByOption = optionsByKey
    ? Object.entries(optionsByKey).reduce((obj, [key, option]) => {
        obj[option] = key;
        return obj;
      }, {})
    : null;

  function keyToOption(key: string) {
    return (optionsByKey ? optionsByKey[key] : null) || key;
  }

  function optionToKey(option: string) {
    return (keysByOption ? keysByOption[option] : null) || option;
  }

  selected = selected != null ? (isList(selected) ? selected.map(keyToOption) : [selected.toString()]) : [];

  const onChangeKeys = onChange;

  onChange = (values: string[]) => {
    onChangeKeys(values.map(optionToKey));
    setTyped(null);
  };

  async function open() {
    setOpen(true);

    await soon();
    setAnimationState('open');
  }

  async function close() {
    setAnimationState('closed');

    await soon(animationTime);

    setOpen(false);

    setValueToTyped();
  }

  function setValueToTyped() {
    if (typed != null) {
      let newValue = typed
        .split(',')
        .map(s => s.trim())
        .filter(s => !!s);

      if (!allowUnknown) {
        newValue = newValue
          .map(s => options.find(option => s.toLowerCase() === option.toLowerCase()))
          .filter(o => !!o) as string[];
      }

      onChange(newValue as string[]);
      setTyped(null);
    }
  }

  function hasOption(option: string) {
    return selected.indexOf(option) >= 0;
  }

  function addOption(option: string) {
    onChange(selected.concat([option]));
  }

  function removeOption(option: string) {
    const i = selected.indexOf(option);

    onChange(removeIndex(i, selected));
  }

  function onKeyDown(e: KeyboardEvent<any>) {
    if (e.keyCode === 9) {
      // tab
    } else if (e.keyCode === 27) {
      close();

      if (ref.current) {
        ref.current.focus();
      }
    } else {
      open();
    }
  }

  const missing = warnOnMissingRequired && isMissingRequiredFields();

  const filter = typed && lastTyped(typed);

  const selectedString = hasFocus ? selected.map(s => s + ', ').join('') : selected.join(', ');

  return (
    <div className="FormFieldMultipleSelect">
      <input
        id={id}
        value={typed != null ? typed : selectedString}
        onClick={() => (isOpen ? close() : open())}
        onChange={e => setTyped(e.target.value)}
        onBlur={e => {
          if (!isOpen) {
            setValueToTyped();
          }

          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        onKeyDown={onKeyDown}
        ref={ref}
        autoComplete="new-password"
      />
      {missing && requiredCount && requiredCount > 1 ? (
        <div className="error">Add at least {digitToString(requiredCount)}.</div>
      ) : null}
      {isOpen ? <CaretUp /> : <CaretDown />}
      {isOpen
        ? [
            <div className="background" key="background" onClick={close} />,
            <div className={'popup-container ' + animationState} onClick={close} key="popup">
              <div className="popup">
                {options
                  .map(o => o.trim())
                  .filter(o => {
                    if (filter) {
                      return o.toLowerCase().includes(filter.toLowerCase());
                    } else {
                      return true;
                    }
                  })
                  .map(option => {
                    const checked = hasOption(option);

                    return (
                      <div
                        key={option}
                        className={'option' + (checked ? ' selected' : '')}
                        onClick={e => {
                          if (!checked) {
                            addOption(option);
                          } else {
                            removeOption(option);
                          }

                          e.preventDefault();
                          e.stopPropagation();

                          if (ref.current) {
                            ref.current.focus();
                          }
                        }}
                      >
                        {checked ? <CheckboxChecked /> : <CheckboxEmpty />}
                        <button className="button label invisible" onKeyDown={onKeyDown}>
                          {option}
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          ]
        : null}
    </div>
  );
}

function CheckboxChecked() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  );
}

function CheckboxEmpty() {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

function soon(ms?: number) {
  return new Promise(resolve => setTimeout(resolve, ms || 0));
}

function removeIndex(index: number, array: string[]) {
  array = array.slice(0);
  array.splice(index, 1);

  return array;
}

export function digitToString(digit: number) {
  digit = digit || 0;

  return (
    {
      0: 'none',
      1: 'one',
      2: 'two',
      3: 'three',
      4: 'four',
      5: 'five',
      6: 'six'
    }[digit] || digit.toString()
  );
}

export function CaretDown() {
  return (
    <svg viewBox="0 0 300 300" className="Caret">
      <path d="M30,100L150,200L270,100" />
    </svg>
  );
}

export function CaretUp() {
  return (
    <svg viewBox="0 0 300 300" className="Caret">
      <path d="M30,200L150,100L270,200" />
    </svg>
  );
}

function lastTyped(string: string) {
  let els = string.split(',');

  return els[els.length - 1].trim();
}
