import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiComboBox, EuiComboBoxOptionOption, EuiComboBoxProps } from '@elastic/eui';
import FormRow, { FormRowProps } from '@sharedComponents/formElements/FormRow';
import { getValueByDottedPath } from '@sharedComponents/formElements/util';

/**
 * Hardcoded combobox to handle selects in formbuilder, transforming strings into options
 */
export default function SelectField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<EuiComboBoxProps<string>>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors, getValues } = useFormContext();
  const [comboBoxOptions, setComboBoxOptions] = useState<EuiComboBoxOptionOption<string>[]>([]);

  useEffect(() => {
    const stringOptions = getValues(fieldName);
    if (stringOptions?.length) {
      setComboBoxOptions(stringOptions.split(',').map(optionValue => ({ label: optionValue })));
    }
  }, []);

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);

  const field = (
    <Controller
      control={control}
      key={fieldName}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        return (
          <EuiComboBox
            selectedOptions={value?.split(',').map(optionValue => ({ label: optionValue }))}
            isInvalid={!!fieldError}
            options={comboBoxOptions}
            onChange={values => {
              onChange(values.map(val => val.label).join(', '));
            }}
            onCreateOption={searchValue => {
              onChange(`${value},${searchValue}`);
            }}
            {...fieldProps}
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={!!fieldError}
      errorMessage={fieldError ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
