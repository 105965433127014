import React from 'react';

export function formatParagraphText(str: string | null | undefined) {
  if (str && str.trim()) {
    return str
      .replace(/\r/g, '')
      .split(/\n[ \t]*\n/)
      .map((p, i) => formatBullets(p, i));
  } else {
    return null;
  }
}

// code is duplicated in RecommendedStep :(
function formatBullets(str: string, index: number) {
  const matchAll = /((\n|^) *\* ([^\n])*)+/;

  const allMatch = str.match(matchAll);

  if (allMatch) {
    const matchSingle = /(\n|^) *\* ([^\n])*/g;

    const bullets: string[] = [];

    let oneMatch: RegExpExecArray | null;

    do {
      oneMatch = matchSingle.exec(allMatch[0]);

      if (oneMatch) {
        bullets.push(oneMatch[0]);
      }
    } while (oneMatch);

    return (
      <React.Fragment key={index}>
        {!!index && <br />}
        {formatSingleParagraph(str.substr(0, allMatch.index as number))}
        <ul>
          {bullets.map((bullet, i) => (
            <li key={i}>{formatSingleParagraph(bullet.replace(/(\n|^) *\* */, ''))}</li>
          ))}
        </ul>
        {formatSingleParagraph(str.substr((allMatch.index as number) + allMatch[0].length))}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment key={index}>
        {!!index && <br />}
        <p>{formatSingleParagraph(str)}</p>
      </React.Fragment>
    );
  }
}

export function formatSingleParagraph(str: string) {
  const linkPattern = /\[([^\]]*)\]\(([^)]*)\)/;

  {
    const m = str.match(linkPattern);

    if (m) {
      return (
        <React.Fragment>
          {formatSingleParagraph(str.substr(0, m.index))}
          <a href={m[2]} target="_blank" rel="noopener noreferrer">
            {m[1]}
          </a>
          {formatSingleParagraph(str.substr((m.index as number) + m[0].length))}
        </React.Fragment>
      );
    }
  }

  {
    const boldPattern = /\*\*([^*]+)\*\*/;

    const m = str.match(boldPattern);

    if (m) {
      return (
        <React.Fragment>
          {str.substr(0, m.index)}
          <b>{m[1]}</b>
          {formatSingleParagraph(str.substr((m.index as number) + m[0].length))}
        </React.Fragment>
      );
    }
  }

  return str;
}

export function formatNumber(num: number) {
  if (num != null) {
    let str = Math.round(num).toString();

    if (str.length > 3) {
      return str.substr(0, str.length - 3) + ',' + str.substr(str.length - 3);
    } else {
      return str;
    }
  } else {
    return '';
  }
}

export function formatFloat(num: number | undefined) {
  if (num && !isNaN(num)) {
    return num.toPrecision(2);
  } else {
    return '';
  }
}
