import track from '@sharedComponents/base/track';
import UserClient from '@sharedClients/UserClient';
import { CoreUserFields } from '@sharedContract/UserModel';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { HeaderProps } from '../components/Header';
import EditDonorUser from './EditDonorUser';
import EditDataEntryUser from './EditDataEntryUser';
import AddCounselorUser from './AddCounselorUser';
import { USER_TYPE } from '@sharedComponents/constants';
import useToast from '@sharedComponents/hooks/useToast';
import { htmlIdGenerator } from '@elastic/eui';
import { sleep } from '@sharedComponents/utils/misc';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

export default function AddUser(
  props: HeaderProps & {
    type: string;
  }
) {
  const { addToast } = useToast();
  const userClient = useUserClient();
  const { type, user: currentUser } = props;

  const [onError, errorMessage] = useErrorReporter();
  const [redirect, setRedirect] = useState<string | null>(null);

  async function create(user: CoreUserFields) {
    let skipWelcomeEmail = false;
    let isPasswordUnknown = true;

    if (type === USER_TYPE.DATAENTRY || type === USER_TYPE.COUNSELOR) {
      isPasswordUnknown = false;
    } else {
      skipWelcomeEmail = type !== USER_TYPE.REVIEWER;

      if (currentUser.donor?.id && user.type === USER_TYPE.REVIEWER && !user.donor?.id) {
        // shitty hardcode for donor user creating reviewer for his own org
        user.donor = currentUser.donor;
      }

      user = await createDonorIfMissing(user, userClient);
    }

    const { id } = await userClient.createUser({
      ...user,
      password: randomString(),
      isPasswordUnknown: isPasswordUnknown,
      skipWelcomeEmail: skipWelcomeEmail
    } as any);

    track('users-created');

    addToast({
      id: htmlIdGenerator()(),
      color: 'success',
      title: `New ${type} user was created!`,
      text: `User ${skipWelcomeEmail ? "won't" : 'will'} receive welcome email.`,
      toastLifeTimeMs: 15000
    });

    await sleep(2500);

    setRedirect(`/users/${id}`);
  }

  return (
    <div className="Scholarship">
      {redirect ? <Redirect to={redirect} /> : null}
      <div className="body">
        <div className="content">
          {errorMessage}
          {type === 'dataEntry' ? (
            <EditDataEntryUser
              userClient={userClient}
              onSave={user => create(user).catch(onError)}
              onCancel={() => setRedirect('/users/dataEntry')}
              onError={onError}
              type={type}
              currentUser={currentUser}
            />
          ) : null}
          {type === 'counselor' ? (
            <AddCounselorUser
              userClient={userClient}
              onSave={user => create(user).catch(onError)}
              onCancel={() => setRedirect('/users/counselor')}
              onError={onError}
              type={type}
              currentUser={currentUser}
            />
          ) : null}
          {type === 'donor' || type === 'reviewer' ? (
            <EditDonorUser
              userClient={userClient}
              onSave={user => create(user).catch(onError)}
              onCancel={() => setRedirect('/users/donor')}
              onError={onError}
              type={type}
              currentUser={currentUser}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function randomString() {
  return Math.floor(Math.random() * 900000000).toString(36);
}

export async function createDonorIfMissing<T extends CoreUserFields>(user: T, userClient: UserClient) {
  const usertypes = ['donor', 'dataEntry', 'reviewer'];
  //if (user.type === 'donor' && user.donor!.id == null) {
  if (usertypes.includes(user.type) && user.donor!.id == null) {
    const donor = await userClient.createDonor({
      name: user.donor!.name || ''
    });

    user.donor = { id: donor.id, name: user.donor!.name };
  }

  return user;
}
