import React, { useEffect, useState } from 'react';

import {
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButtonIcon,
  EuiLoadingSpinner,
  EuiFormRow,
  EuiSpacer,
  EuiCallOut,
  EuiText
} from '@elastic/eui';
import { isNil } from 'lodash';
import { RequestReviewStepProps } from '../../FormReviewRequestConfirmationModal';
import usersOfDonorQuery from '../../queries/usersOfDonorQuery';
import { USER_TYPE } from '@sharedComponents/constants';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';
import ReviewerSelectField from '../../FormReviewRequestConfirmationModal/steps/DonorRolesAssigningStep/ReviewerSelectField';
import { mapKeys } from 'lodash';

export type FormReviewerOption = {
  label: string;
  data: {
    email: string;
  };
  value: number;
};

const DonorRolesAssigningStep = ({
  selectedDonorId,
  superDonorId,
  setSuperDonorId,
  standardDonorsIds,
  setStandardDonorsIds,
  nextStep,
  prevStep
}: RequestReviewStepProps) => {
  const [userOptionsMap, setUserOptionsMap] = useState<{ [key: string]: FormReviewerOption }>({});
  const { data: users, isLoading: areUsersLoading } = usersOfDonorQuery.useUsersOfDonor(selectedDonorId);

  const onPrevStep = () => {
    setSuperDonorId(undefined);
    setStandardDonorsIds([]);
    prevStep();
  };

  useEffect(() => {
    if (Array.isArray(users)) {
      const newUserOptions = users
        .filter(user => user.type === USER_TYPE.DONOR && user.data?.isPremium) // only premium donors have access to the UI for requesting changes and form approving
        .map(user => {
          const fullName = getUserFullNameFromData(user.data);
          return {
            label: fullName,
            data: {
              email: user.name
            },
            value: user.id
          };
        });

      const newUserOptionsMap = mapKeys(newUserOptions, 'value');

      setUserOptionsMap(newUserOptionsMap);
    }
  }, [users]);

  const userOptionsLength = Object.keys(userOptionsMap).length;

  const usedOptionValues = [superDonorId, ...standardDonorsIds];

  return (
    <>
      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
          {areUsersLoading ? (
            <EuiLoadingSpinner size="l" style={{ alignSelf: 'center' }} />
          ) : (
            <>
              <EuiFlexItem>
                {!!userOptionsLength ? (
                  <EuiFormRow label="Super Donor" fullWidth>
                    <>
                      <EuiText size={'xs'} style={{ maxWidth: '450px' }}>
                        Select only 1 donor who will have permissions to approve the application form. This donor can
                        also request changes to the form.
                      </EuiText>
                      <EuiSpacer size={'s'} />
                      <ReviewerSelectField
                        userOptions={Object.values(userOptionsMap)}
                        selectedIds={superDonorId ? [userOptionsMap[superDonorId]] : []}
                        onChange={v => {
                          setSuperDonorId(v.length ? v[0].value : undefined);
                        }}
                        usedValues={usedOptionValues}
                      />
                    </>
                  </EuiFormRow>
                ) : (
                  <EuiCallOut title="Sorry, no donors found for this organization" color="danger" iconType="alert" />
                )}
              </EuiFlexItem>

              {userOptionsLength > 1 && (
                <>
                  <EuiSpacer />
                  <EuiFormRow label={'Standard Donors'} fullWidth>
                    <>
                      <EuiText size={'xs'} style={{ maxWidth: '450px' }}>
                        Select 1 or more donors who will have permissions to only request changes to the application
                        form.
                      </EuiText>
                      <EuiSpacer size={'s'} />
                      <EuiFlexItem grow={false}>
                        <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
                          {standardDonorsIds.length
                            ? standardDonorsIds.map((standardDonorId, index) => (
                                <EuiFlexItem key={`${standardDonorId}_${index}`}>
                                  <EuiFlexGroup direction="row" gutterSize="xs" responsive={false}>
                                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                                      <EuiButtonIcon
                                        aria-label="Remove item"
                                        color="danger"
                                        iconType="minusInCircle"
                                        onClick={() =>
                                          setStandardDonorsIds([
                                            ...standardDonorsIds.slice(0, index),
                                            ...standardDonorsIds.slice(index + 1)
                                          ])
                                        }
                                        title="Remove item"
                                      />
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                      <ReviewerSelectField
                                        userOptions={Object.values(userOptionsMap)}
                                        selectedIds={standardDonorId ? [userOptionsMap[standardDonorId]] : []}
                                        onChange={v => {
                                          setStandardDonorsIds([
                                            ...standardDonorsIds.slice(0, index),
                                            v.length ? v[0].value : undefined,
                                            ...standardDonorsIds.slice(index + 1)
                                          ]);
                                        }}
                                        usedValues={usedOptionValues}
                                      />
                                    </EuiFlexItem>
                                  </EuiFlexGroup>
                                </EuiFlexItem>
                              ))
                            : null}
                        </EuiFlexGroup>
                      </EuiFlexItem>
                      {standardDonorsIds.length + 1 < userOptionsLength ? (
                        <EuiFlexItem>
                          <EuiSpacer size={'s'} />
                          <EuiFlexGroup justifyContent={'center'} responsive={false}>
                            <EuiFlexItem grow={false}>
                              <EuiButtonIcon
                                aria-label="Add item"
                                title="Add new item"
                                color="primary"
                                style={{ alignSelf: 'center' }}
                                iconType="plusInCircle"
                                onClick={() => setStandardDonorsIds([...standardDonorsIds, undefined])}
                              />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      ) : null}
                    </>
                  </EuiFormRow>
                </>
              )}
            </>
          )}
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onPrevStep}>Back</EuiButtonEmpty>
        <EuiButton
          onClick={nextStep}
          disabled={isNil(selectedDonorId)}
          isDisabled={areUsersLoading || !superDonorId}
          fill
        >
          Next
        </EuiButton>
      </EuiModalFooter>
    </>
  );
};

export default DonorRolesAssigningStep;
