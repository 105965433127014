import React, { useEffect, useState } from 'react';
import { HeaderProps } from '../components/Header';
import ITranscriptRequestForm from '@sharedContract/TranscriptRequestModel';
import './TranscriptRequestForm.scss';
import { EuiButtonEmpty, EuiCopy, EuiLink, EuiToolTip } from '@elastic/eui';
import { ApplicationClient } from '@sharedClients/ApplicationClient';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
interface ITranscriptRequestFormHighSchoolInfo extends ITranscriptRequestForm {
  highschool: { id: number; name: string };
}
const TranscriptRequestItem = function ({
  applicationClient,
  isSubmitting,
  setTranscriptRequest,
  setSubmitting,
  item
}: {
  applicationClient: ApplicationClient;
  isSubmitting: boolean;
  setTranscriptRequest: React.Dispatch<React.SetStateAction<ITranscriptRequestFormHighSchoolInfo[]>>;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  item: ITranscriptRequestFormHighSchoolInfo;
}) {
  const [completed, setCompleted] = useState(item.data?.dataEntryCompleted || false);
  return (
    <div className="scholarship list">
      <div className="namee">{item.fileInfo.name}</div>
      <div className="pdf">
        <EuiLink href={item.link} external target="_blank">
          Download
        </EuiLink>
      </div>
      <div className="highSchoolId">{item.highschool.id}</div>
      <div className="add">
        <EuiToolTip position="left" content="Click to copy">
          <EuiCopy textToCopy={item.id}>
            {copy => (
              <EuiButtonEmpty onClick={copy} color="text">
                {item.id}
              </EuiButtonEmpty>
            )}
          </EuiCopy>
        </EuiToolTip>{' '}
      </div>
      <div className="completed">
        <input
          key={item.id}
          type="checkbox"
          checked={completed || false}
          disabled={isSubmitting}
          onChange={async () => {
            setSubmitting(true);
            setCompleted(!completed);

            // updates transcript request with formstack response info, so formStackDocumentId and data.initialFormInfo is being set
            await applicationClient.assignTranscriptRequestForm(item.id, !item.data?.dataEntryCompleted);

            // updates listing? why couldnt we return list with previous request?
            const res = await applicationClient.getTranscriptRequestForm();
            setTranscriptRequest(res.data);
            setSubmitting(false);
          }}
        />
      </div>
    </div>
  );
};

const TranscriptRequestForms = (props: {} & HeaderProps) => {
  const applicationClient = useApplicationClient();
  const [transcriptRequest, setTranscriptRequest] = useState<Array<ITranscriptRequestFormHighSchoolInfo>>([]);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const res = await applicationClient.getTranscriptRequestForm();
      setTranscriptRequest(res.data);
    })();
  }, [applicationClient]);

  return (
    <>
      <div className="body">
        <div className="content">
          <div className="ScholarshipList">
            <div className="scholarship header">
              <div className="namee">Form Name</div>
              <div className="pdf">PDF</div>
              <div className="highSchoolId">High School ID</div>
              <div className="add">Add as FormStack File Name</div>
              <div className="completed">Completed</div>
            </div>
            {transcriptRequest &&
              transcriptRequest.map(item => (
                <div key={item.id}>
                  <TranscriptRequestItem
                    item={item}
                    applicationClient={applicationClient}
                    setTranscriptRequest={setTranscriptRequest}
                    isSubmitting={isSubmitting}
                    setSubmitting={setSubmitting}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscriptRequestForms;
