import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiCheckbox, EuiCheckboxProps, htmlIdGenerator } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function CheckboxField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<EuiCheckboxProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  // extracting defaultChecked from props, since its useable only on controller level
  const { defaultChecked, ...restOfFieldProps } = fieldProps || {};
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      defaultValue={defaultChecked || false}
      render={({ onChange, onBlur, value, name, ref }) => {
        return (
          <EuiCheckbox
            id={htmlIdGenerator()()}
            onBlur={onBlur}
            onChange={e => onChange(e.target.checked)}
            name={name}
            ref={ref}
            checked={value}
            {...restOfFieldProps}
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={!!fieldError}
      errorMessage={fieldError ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
