import { useQuery } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';
import { RecurringAwardModelShape } from '@sharedComponents/models';

const CURRENT_QUERY_KEY = 'recurrentAwardApplication';

const ENDPOINT = (recordID: number, scholarshipID: number) => '/v3/recurring-awards/' + scholarshipID + '/' + recordID;
function useRecurrentAwards(recordID: number, scholarshipID: number) {
  const fetcher = useFetcher();

  return useQuery<RecurringAwardModelShape[]>(
    [CURRENT_QUERY_KEY],
    () => fetcher().url(ENDPOINT(recordID, scholarshipID)).get(),
    {
      staleTime: 1000 * 60
    }
  );
}

const recurrentAwardApplicationQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useRecurrentAwards
};

export default recurrentAwardApplicationQuery;
