import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.scss';

import { pick } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';
import { useForm } from 'react-hook-form';
import { EditorState, ContentState, convertToRaw } from 'draft-js';

import {
  EuiBadge,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiPanel,
  EuiPopover,
  EuiPopoverFooter
} from '@elastic/eui';

function stringToRawDraftContentState(text) {
  const contentState = ContentState.createFromText(text);
  const rawContentState = convertToRaw(contentState);
  return rawContentState;
}

const getToolbarOptions = (options = ['inline', 'textAlign', 'link'], readOnly = false) => {
  return {
    options: options,
    inline: {
      inDropdown: false,
      className: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
      component: ({ onChange, currentState = {} }) => {
        return (
          <EuiButtonGroup
            legend="Text style"
            options={
              [
                {
                  id: `bold`,
                  label: 'Bold',
                  iconType: 'editorBold'
                },
                {
                  id: `italic`,
                  label: 'Italic',
                  iconType: 'editorItalic'
                },
                {
                  id: `underline`,
                  label: 'Underline',
                  iconType: 'editorUnderline'
                }
              ] as any
            }
            idToSelectedMap={currentState}
            onChange={onChange}
            type="multi"
            buttonSize="compressed"
            isDisabled={readOnly}
            isIconOnly
          />
        );
      }
    },
    textAlign: {
      inDropdown: false,
      className: undefined,
      dropdownClassName: undefined,
      component: ({ onChange, currentState = {} }) => {
        return (
          <EuiButtonGroup
            legend="Text alignment"
            options={
              [
                {
                  id: `left`,
                  label: 'Align left',
                  iconType: 'editorAlignLeft'
                },
                {
                  id: `center`,
                  label: 'Align center',
                  iconType: 'editorAlignCenter'
                },
                {
                  id: `right`,
                  label: 'Align right',
                  iconType: 'editorAlignRight'
                }
              ] as any
            }
            idToSelectedMap={{
              ...(currentState['textAlignment'] ? { [currentState['textAlignment']]: true } : {})
            }}
            onChange={onChange}
            type="multi"
            buttonSize="compressed"
            isDisabled={readOnly}
            isIconOnly
          />
        );
      }
    },
    link: {
      inDropdown: false,
      className: undefined,
      showOpenOptionOnHover: false,
      dropdownClassName: undefined,
      component: ({ onChange, currentState = {} }) => {
        const [isPopoverOpen, setPopoverOpen] = useState(false);
        const { register, handleSubmit, watch, setValue } = useForm();

        const data = watch();

        useEffect(() => {
          // setting initial values for title and target when popover gets opened
          if (isPopoverOpen) {
            let initData;

            if (currentState['link']) {
              initData = pick(currentState['link'], ['title', 'target']);
            } else {
              initData = { title: currentState['selectionText'] || '', target: '' };
            }

            Object.keys(initData).forEach(key => setValue(key, initData[key]));
          }
        }, [isPopoverOpen]);

        const togglePopover = () => {
          setPopoverOpen(!isPopoverOpen);
        };

        const closePopover = () => {
          setPopoverOpen(false);
        };

        const onAddLink = data => {
          onChange('link', data.title, data.target, '_blank');
          closePopover();
        };

        const button = (
          <EuiButtonGroup
            legend="Link"
            options={
              [
                {
                  id: `link`,
                  label: 'Link',
                  iconType: 'link'
                },
                {
                  id: `unlink`,
                  label: 'Unlink',
                  iconType: 'unlink',
                  isDisabled: !currentState['link']
                }
              ] as any
            }
            idToSelectedMap={{ link: !!currentState['link'], unlink: false }}
            onChange={action => {
              if (action == 'link') {
                togglePopover();
              } else {
                onChange(action);
              }
            }}
            type="multi"
            buttonSize="compressed"
            isDisabled={readOnly}
            isIconOnly
          />
        );

        return (
          <EuiPopover
            ownFocus
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="s"
            anchorPosition="downLeft"
          >
            <div style={{ width: '225px' }}>
              <EuiForm component="form" onSubmit={e => e.preventDefault()}>
                <EuiFormRow label="Link title">
                  <EuiFieldText compressed inputRef={register} name={'title'} />
                </EuiFormRow>
                <EuiFormRow label="Link target">
                  <EuiFieldText compressed name={'target'} inputRef={register} placeholder={'https://'} />
                </EuiFormRow>
              </EuiForm>
            </div>
            <EuiPopoverFooter>
              <EuiFlexGroup alignItems="center" gutterSize="s" justifyContent="spaceAround" responsive={false}>
                <EuiFlexItem>
                  <EuiButton size="s" onClick={handleSubmit(onAddLink)} isDisabled={!(data.title && data.target)}>
                    {currentState['link'] ? 'Save' : 'Add'}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButtonEmpty size="s" onClick={closePopover}>
                    Cancel
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPopoverFooter>
          </EuiPopover>
        );
      }
    }
  };
};

const defaultEditorConfig = {
  minHeight: 150,
  maxHeight: 500,
  mode: 'edit',
  options: ['inline', 'textAlign', 'link']
};

export interface RichTextEditorProps {
  value: RawDraftContentState;
  onContentStateChange?: (value: RawDraftContentState) => any;
  config?: {
    minHeight?: number | string;
    maxHeight?: number | string;
    mode?: 'edit' | 'disabled' | 'view';
    hideWordsCounter?: boolean;
    options?: string[];
  };
}

export default function RichTextEditor({ value, onContentStateChange, config = {} }: RichTextEditorProps) {
  // for legacy reasons value could be just a string, so we need to convert it to RawDraftContentState
  const memoizedValue = useMemo(() => {
    if (typeof value === 'string') {
      return stringToRawDraftContentState(value);
    }
    return value;
  }, [value]);
  
  const fullConfig = {
    ...defaultEditorConfig,
    ...config
  };

  const [counter, setCounter] = useState({
    words: 0,
    characters: 0
  });

  useEffect(() => {
    // updating counters
    if (memoizedValue?.blocks && !config?.hideWordsCounter) { // not always working?
      const fullText = (memoizedValue?.blocks || []).map(block => block.text).join(' ');
      setCounter({
        words: [...fullText.matchAll(/\w+/g)].length,
        characters: fullText.length
      });
    }
  }, [memoizedValue]);

  const toolbarOptions = useMemo(
    () => getToolbarOptions(fullConfig.options, fullConfig.mode == 'disabled'),
    [fullConfig.mode]
  );

  return (
    <div className={`RichTextEditor ${fullConfig.mode}-mode`}>
      <Editor
        initialContentState={memoizedValue || null}
        wrapperClassName="EditorWrapper"
        editorClassName="EditorComponent"
        editorStyle={pick(fullConfig, ['minHeight', 'maxHeight'])}
        onContentStateChange={onContentStateChange}
        toolbar={toolbarOptions}
        readOnly={['view', 'disabled'].includes(fullConfig.mode)}
        handlePastedText={() => false}
      />
      {config?.hideWordsCounter ? (
        <></>
      ) : (
        <EuiPanel className="CounterPanel" paddingSize="s" hasShadow={false} hasBorder>
          <EuiFlexGroup responsive={false} gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiBadge>
                Words: <span>{counter.words}</span>
              </EuiBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge>
                Characters: <span>{counter.characters}</span>
              </EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      )}
    </div>
  );
}
