import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import {
  EuiDataGrid,
  EuiDataGridColumn,
  EuiDataGridControlColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiToolTip
} from '@elastic/eui';
import { capitalize, parseInt, replace } from 'lodash';
import { RecurrentPaymentsConfigurationForm } from '@donors/routes/RecurringAwardsSetup.interface';

const DataContext = createContext<RecurrentPaymentsConfigurationForm['records']>([]);

function SummaryTable({
  records,
  headers
}: {
  records: RecurrentPaymentsConfigurationForm['records'];
  headers: RecurrentPaymentsConfigurationForm['headers'];
}) {
  // initial columns set to required ones
  const columns: EuiDataGridColumn[] = [
    {
      id: 'name',
      displayAsText: 'First Name',
      actions: false
    },
    {
      id: 'last_name',
      displayAsText: 'Last Name',
      actions: false
    },
    {
      id: 'email',
      displayAsText: 'Email address',
      initialWidth: 130,
      actions: false
    },
    {
      id: 'previous_repayments',
      displayAsText: 'Number of previous repayments',
      actions: false
    },
    {
      id: 'previous_amount',
      displayAsText: 'Amount of previous repayments',
      actions: false
    }
  ];

  // adding optional columns as extended data
  if (headers.length > 5) {
    for (let index = 5; index < headers.length; index++) {
      const header = headers[index];

      columns.push({
        id: header,
        displayAsText: capitalize(replace(header || '', /[_]/g, ' ')),
        actions: false
      });
    }
  }

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0
      })),
    [setPagination]
  );

  const onChangePage = useCallback(
    pageIndex => setPagination(pagination => ({ ...pagination, pageIndex })),
    [setPagination]
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns

  const renderCellValue = useMemo(() => {
    return ({ rowIndex, columnId, setCellProps }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = useContext(DataContext);

      if (columnId === 'previous_amount') {
        if (data.hasOwnProperty(rowIndex)) {
          const numeric = data[rowIndex][columnId] ? parseInt(data[rowIndex][columnId]) : 0;
          return numeric ? `$ ${numeric.toLocaleString()}` : '';
        }
      }

      function getFormatted(_rowIndex, _columnId) {
        return data[_rowIndex][_columnId] ? data[_rowIndex][_columnId] : '';
      }

      return data.hasOwnProperty(rowIndex) ? getFormatted(rowIndex, columnId) : null;
    };
  }, []);

  const StatusRowCell = cell => {
    const { rowIndex } = cell;
    const data = useContext(DataContext);
    const record = data[rowIndex];

    return (
      <EuiFlexGroup direction="column" responsive={false} alignItems="center" justifyContent="center">
        <EuiFlexItem grow={false}>
          <EuiToolTip
            content={
              record.isRegistered
                ? "Student is already using Scholar's App"
                : "Student will be invited to Scholar's App"
            }
          >
            <EuiHealth color={record.isRegistered ? 'success' : 'primary'} />
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const leadingControlColumns: EuiDataGridControlColumn[] = [
    {
      id: 'status',
      width: 36,
      headerCellRender: () => null,
      rowCellRender: StatusRowCell
    }
  ];

  return (
    <DataContext.Provider value={records}>
      <EuiDataGrid
        aria-label="Recurrent payments summary page"
        leadingControlColumns={leadingControlColumns}
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={records.length}
        renderCellValue={renderCellValue}
        inMemory={{ level: 'enhancements' }}
        pagination={{
          ...pagination,
          pageSizeOptions: [10, 25],
          onChangeItemsPerPage: onChangeItemsPerPage,
          onChangePage: onChangePage
        }}
      />
    </DataContext.Provider>
  );
}

export default SummaryTable;
