import FormFieldCheckbox from './FormFieldCheckbox';
import React from 'react';

/**
 * This is only used on the recommender signup form. Counselors may also be recommenders.
 * Also used for parents/applicants registration
 * They click this checkbox to indicate they are.
 */
export default function FormUserType(props) {
  const { setUserType, node, userType } = props;
  const defaultChecked = userType ? userType === node.userType : false;
  return (
    <FormFieldCheckbox
      {...props}
      defaultChecked={defaultChecked}
      onFieldChange={value => {
        if (value) {
          setUserType(node.userType);
        } else {
          // this could be wrong, but works for all our cases for now.
          setUserType('applicant');
        }
      }}
    />
  );
}
