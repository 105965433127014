import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import { ScholarshipClient } from '@sharedClients/ScholarshipClient';
import { ComboboxField } from '@sharedComponents/formElements';
import BooleanRadioGroupField from '@sharedComponents/formElements/combined/BooleanRadioGroupField';
import NumberField from '@sharedComponents/formElements/simpleFields/NumberField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import { DonorModelShape } from '@sharedComponents/models';
import allStates from '@sharedComponents/models/enumValues/states';

import AbstractEntryStep from './AbstractEntryStep';
import DonorLookupWidget from './EntryStepContact/DonorLookupWidget';

const EntryStepContact: AbstractEntryStep = {
  validationSchema: yup
    .object()
    .shape({
      isDonorExists: yup.boolean().label('Donor Organization').required(),
      donor_id: yup.number().when('isDonorExists', {
        is: true,
        then: yup.number().required(),
        otherwise: yup
          .number()
          .nullable()
          .transform(() => null)
      }),

      donor: yup.object().shape({
        name: yup.string().required('Organization name is required'),
        email: yup.string().label('Organization email').email().optional(),
        phone: yup.string().label('Organization phone number').max(20).optional(),
        street: yup.string().optional(),
        street2: yup.string().optional(),
        city: yup.string().optional(),
        state: yup.string().notRequired().nullable(),
        zip: yup.string().optional()
      })
    })
    .test('donor.name', 'Donor organization with same name is already exist', async contactStep => {
      if (contactStep.isDonorExists || !contactStep.donor?.name) {
        return true;
      }

      // TODO:IMP we should actually pass ScholarshipClient via dependency injection or some context, but exactly in this place its okay to be, as non-auth request is allowed to that endpoint.
      const client = new ScholarshipClient(fetch, null, null);
      const lookup = await client.dataLookup(contactStep.donor.name, 'donors');
      const hasSameNamedDonors = lookup.reduce((result, record) => {
        return result || record.name === contactStep.donor.name;
      }, false);

      if (hasSameNamedDonors) {
        return false;
      }

      return true;
    }),

  render: function (rowProps, { watch, setValue }) {
    const isDonorExists = watch('isDonorExists');
    const donor = watch('donor');
    const { name } = donor || {};

    // When existing donor record selected, prefill his data into formfields
    const onExistingDonorSelection = (donor: DonorModelShape | null) => {
      if (donor) {
        setValue('donor_id', donor.id);
        setValue('donor', { ...donor }, { shouldDirty: true });
      }
    };

    return (
      <EuiFlexGroup direction="column" gutterSize="l">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Contact Information</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="l">
                <EuiFlexItem grow={false}>
                  <BooleanRadioGroupField
                    labels={['Use existing', 'Create new']}
                    fieldName="isDonorExists"
                    rowProps={{
                      label: 'Donor Organization *',
                      ...rowProps
                    }}
                  />
                </EuiFlexItem>

                {isDonorExists === true ? (
                  <EuiFlexItem>
                    <EuiFlexGroup direction="row">
                      <EuiFlexItem></EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <DonorLookupWidget // TODO: test this
                          onSelect={onExistingDonorSelection}
                          placeholder={name || ''}
                        />
                        <EuiSpacer size="xs" />
                        <NumberField fieldName={'donor_id'} fieldProps={{ disabled: true, fullWidth: false }} />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                ) : isDonorExists === false ? (
                  <EuiFlexItem grow={false}>
                    <TextField fieldName={'donor.name'} rowProps={{ label: 'Organization Name *', ...rowProps }} />
                  </EuiFlexItem>
                ) : null}

                {isDonorExists !== undefined ? (
                  <>
                    <EuiFlexItem grow={false}>
                      <TextField fieldName={'donor.email'} rowProps={{ label: 'Donor Email', ...rowProps }} />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <TextField fieldName={'donor.phone'} rowProps={{ label: 'Donor Phone Number', ...rowProps }} />
                    </EuiFlexItem>
                  </>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="l">
                {isDonorExists !== undefined ? (
                  <EuiFlexItem>
                    <TextField
                      fieldName={'donor.street'}
                      fieldProps={{ placeholder: 'Street' }}
                      rowProps={{ label: 'Donor Mailing Address', ...rowProps }}
                    />
                    {/** label is not empty, since we still need FormRow displayed, just with empty title */}
                    <TextField
                      fieldName={'donor.street2'}
                      fieldProps={{ placeholder: 'Street 2' }}
                      rowProps={{ label: ' ', ...rowProps }}
                    />
                    <TextField
                      fieldName={'donor.city'}
                      fieldProps={{ placeholder: 'City' }}
                      rowProps={{ label: ' ', ...rowProps }}
                    />
                    <ComboboxField
                      options={Object.keys(allStates).map(shortState => ({
                        label: allStates[shortState],
                        key: shortState,
                        value: shortState
                      }))}
                      fieldName={'donor.state'}
                      fieldProps={{ placeholder: 'State', singleSelection: true }}
                      rowProps={{ label: ' ', ...rowProps }}
                    />
                    <TextField
                      fieldName={'donor.zip'}
                      fieldProps={{ placeholder: 'ZIP' }}
                      rowProps={{ label: ' ', ...rowProps }}
                    />
                  </EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepContact;
