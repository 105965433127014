import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import { useQuery, UseQueryOptions } from 'react-query';
import { EVENT_AUTHORIZED_TYPE, EventAuthorizedUsers } from '@sharedComponents/interfaces/Events.interface';

// unique key for this query
const CURRENT_QUERY_KEY = 'eventAuthorizedUsers';

/**
 * disabled while selectedScholarshipId is null
 */
function useEventAuthorizedUsers(
  selectedScholarshipId: number | null,
  event: EVENT_AUTHORIZED_TYPE,
  daysNumber: number,
  options: UseQueryOptions<EventAuthorizedUsers, Error, EventAuthorizedUsers> = {}
) {
  const scholarshipClient = useScholarshipClient();
  const query = {
    ...useQuery<EventAuthorizedUsers, Error>(
      [CURRENT_QUERY_KEY, selectedScholarshipId, daysNumber], // depend cache on IDs
      () => scholarshipClient.getEventAuthorizedUsers(selectedScholarshipId, event, daysNumber),
      {
        staleTime: 1000 * 45,
        ...options,
        // if query disabled from outside - respect this, else relay on selectedScholarshipId availability
        enabled: options.enabled === false ? false : selectedScholarshipId !== null
      }
    )
  };

  return query;
}

const eventAuthorizedUsersQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useEventAuthorizedUsers };
export default eventAuthorizedUsersQuery;
