import './SubmitConfirmation.scss';

import React from 'react';

import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalFooter,
  EuiButton,
  EuiText
} from '@elastic/eui';

function SubmitConfirmation({ onConfirm, title, message }) {
  return (
    <EuiModal className="SubmitConfirmation" onClose={() => onConfirm()} style={{ minWidth: 400 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <div className="SubmitConfirmation-checkmark">
            <svg viewBox="0 0 130.2 130.2">
              <circle
                className="path circle"
                fill="none"
                stroke="#73AF55"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#73AF55"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
          </div>
          <EuiText size="relative">{title}</EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiText>{message}</EuiText>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onConfirm} fill>
          Ok
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default SubmitConfirmation;
