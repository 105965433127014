import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPagination,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  htmlIdGenerator
} from '@elastic/eui';
import { LEFT_ALIGNMENT, RIGHT_ALIGNMENT } from '@elastic/eui/lib/services';
import { USER_TYPE } from '@sharedComponents/constants';
import formatDate from '@sharedComponents/utils/formatDate';
import UserModel, {
  ApplicantUserData,
  CounselorUserData,
  DonorUserData,
  RecommenderSchool
} from '@sharedContract/UserModel';

import { hasFlag } from '../utils/flags';
import { getUserFullNameFromData } from '../utils/userUtils';

export const DEFAULT_PAGE_SIZE = 50;

const MIN_AMOUNT_TO_SHOW_TOTAL = 5;

enum USER_DISPLAY_FLAGS {
  DEFAULT,
  SKIP_SCHOOL_DETAILS = 1 << 1
}
interface Column {
  id: string;
  label: string;
  width?: string;
  align?: LEFT_ALIGNMENT | RIGHT_ALIGNMENT;
  render: Function;
}

const getColumnsForUserType = (userType: USER_TYPE, displayOptions: USER_DISPLAY_FLAGS): Array<Column> => {
  const isParent = userType === USER_TYPE.PARENT;
  const firstColumnWidth = isParent ? '40%' : '80%';

  const childrenColumn = {
    id: 'students',
    label: 'Student(s)',
    width: firstColumnWidth,
    render: user => {
      const children = (user.data as any).children || []; // TODO: typing for relationships

      return (
        <EuiFlexGroup direction="row">
          <EuiFlexItem>
            <EuiFlexGroup responsive={true} alignItems="flexStart" direction="column" gutterSize="none">
              {Array.isArray(children)
                ? children.map(child => (
                    <EuiFlexItem key={htmlIdGenerator()()}>{`${
                      child?.email ||
                      child /** Support for old relationship when children info was written into DB. Eventually it should be cleaned and only child.email will remain */
                    }`}</EuiFlexItem>
                  ))
                : null}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
  };

  const columns = [
    {
      id: 'id',
      label: 'Email / Name',
      width: firstColumnWidth,
      render: user => {
        const applicantData = user.data as ApplicantUserData;
        const counselorData = user.data as CounselorUserData;

        const schoolName =
          applicantData.school &&
          // counselor
          ((applicantData.school as RecommenderSchool).name ||
            // applicant
            (applicantData.school.school && applicantData.school.school.name));

        let state = counselorData.state || (applicantData.school && applicantData.school.state);

        if ((schoolName || '').endsWith(', ' + state)) {
          state = '';
        }

        return (
          <EuiFlexGroup direction="row">
            <EuiFlexItem>
              <EuiFlexGroup responsive={true} alignItems="flexStart" direction="column" gutterSize="none">
                <EuiFlexItem>{user.fullName || getUserFullNameFromData(user.data) || 'No name'}</EuiFlexItem>
                {hasFlag(displayOptions, USER_DISPLAY_FLAGS.SKIP_SCHOOL_DETAILS) ? null : (
                  <EuiFlexItem>
                    {schoolName || (user.donor && user.donor.name) || user.name || ''}
                    {state ? `, ${state}` : null}
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={null}>
              {userType === USER_TYPE.COUNSELOR && !(user.data as CounselorUserData).isVerified ? (
                <EuiBadge color={'warning'}>not verified</EuiBadge>
              ) : null}
              {userType === USER_TYPE.DONOR && (user.data as DonorUserData).isPremium ? (
                <EuiBadge color={'warning'}>premium</EuiBadge>
              ) : null}
              {userType === USER_TYPE.DONOR && (user.data as DonorUserData).isVerified === false ? (
                <EuiBadge color={'danger'}>not verified</EuiBadge>
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }
    },
    {
      id: 'created',
      label: 'Created',
      dataType: 'date',
      align: RIGHT_ALIGNMENT,
      width: '20%',
      render: user => {
        return user?.createdAt ? formatDate(user.createdAt) : null;
      }
    }
  ];

  if (isParent) {
    columns.splice(1, 0, childrenColumn);
  }

  return columns;
};

function AdvancedUserList({
  users = [],
  type,
  total,
  isLoading,
  currentPage,
  onPageChange,
  columns,
  rowClickHandler,
  pageSize = DEFAULT_PAGE_SIZE,
  displayOptions = USER_DISPLAY_FLAGS.DEFAULT
}: {
  users: UserModel[];
  type: USER_TYPE;
  total: number;
  isLoading: boolean;
  currentPage: number;
  onPageChange: Function;
  columns?: Array<Column>;
  rowClickHandler?: (item: UserModel) => void;
  pageSize?: number;
  displayOptions?: USER_DISPLAY_FLAGS;
}) {
  const [items, setItems] = useState([...users]);
  const pageCount = Math.ceil(total / pageSize);

  useEffect(() => {
    setItems([...users]);
  }, [users]);

  const history = useHistory();

  const columnsToRender: Column[] = columns?.length ? columns : getColumnsForUserType(type, displayOptions);

  const renderHeaderCells = () => {
    const headers: JSX.Element[] = [];

    columnsToRender.forEach(column => {
      headers.push(
        <EuiTableHeaderCell
          style={{ width: column.width || 'auto' }}
          key={column.id}
          align={column.align || LEFT_ALIGNMENT}
        >
          {column.label}
        </EuiTableHeaderCell>
      );
    });

    return headers.length ? headers : null;
  };

  const renderRows = () => {
    if (isLoading || !users || users.length === 0) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns?.length}>
            <EuiText size="s" textAlign="center" grow>
              {(isLoading && 'Loading...') || 'No results'}
            </EuiText>
          </EuiTableRowCell>
        </EuiTableRow>
      );
    }

    const renderRow = (item: UserModel) => {
      const cells = columnsToRender.map(column => {
        return (
          <EuiTableRowCell
            textOnly={false}
            key={column.id}
            align={column.align || LEFT_ALIGNMENT}
            mobileOptions={{
              header: column.label
            }}
          >
            {column.render(item)}
          </EuiTableRowCell>
        );
      });

      return (
        <EuiTableRow
          key={item.id}
          style={{ cursor: 'pointer' }}
          onClick={() => (rowClickHandler ? rowClickHandler(item) : history.push(`/users/${item.id}`))}
        >
          {cells}
        </EuiTableRow>
      );
    };

    const rows: JSX.Element[] = [];

    for (const item of items) {
      rows.push(renderRow(item));
    }

    return rows;
  };

  return (
    <div className="AdvancedUserList">
      <EuiFlexGroup justifyContent="spaceAround">
        <EuiFlexItem grow={false}>
          <EuiTable>
            <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>

            <EuiTableBody>{renderRows()}</EuiTableBody>
          </EuiTable>
          <EuiSpacer size="m" />

          <EuiFlexGroup justifyContent="spaceBetween">
            {total > MIN_AMOUNT_TO_SHOW_TOTAL && !isLoading && (
              <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
                <EuiText size="s">
                  Total users: <strong>{total}</strong>
                </EuiText>
              </EuiFlexItem>
            )}
            {pageCount > 1 && !isLoading && (
              <EuiFlexItem grow={false}>
                <EuiPagination
                  pageCount={pageCount}
                  activePage={currentPage}
                  onPageClick={pageIndex => onPageChange(pageIndex)}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

// Attaching to our component as static property, so we wont need to import it separately
AdvancedUserList.USER_DISPLAY_FLAGS = USER_DISPLAY_FLAGS;

export default AdvancedUserList;
