import './StudentInvitations.scss';

import { countBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiText } from '@elastic/eui';
import { onPageChange } from '@sharedComponents/base/track';
import { DEFAULT_ERROR_TITLE, USER_TYPE } from '@sharedComponents/constants';
import {
  Invitation,
  INVITATION_STATUS,
  InvitationConfirmation,
  VerifiedInvitation
} from '@sharedComponents/interfaces/Invitation.interface';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import UserModel from '@sharedContract/UserModel';

import userProfileQuery from './Profile/queries/userProfileQuery';
import { InvitationSubmittingView } from './StudentInvitations/InvitationSubmittingView';
import InvitesBlock from './StudentInvitations/InvitesBlock';

/**
 * Has 2 states, first one inputs all the invitation data
 * second one confirms actual invitations and processes them
 */
enum INVITATION_SUBSTEP {
  COLLECTING,
  SUBMITTING
}

export enum StudentInvitationsViewMode {
  REGISTRATION_VIEW,
  HOME_VIEW,
  SUCCESS_VIEW,
  ERROR_VIEW
}

const StudentInvitations = ({
  user,
  confirmationCallback,
  initialViewMode = StudentInvitationsViewMode.HOME_VIEW
}: {
  user?: UserModel | null;
  confirmationCallback?;
  initialViewMode?;
}) => {
  const [invitationList, updateInvitationList] = useState<Invitation[]>([]);

  const {
    data: userProfile,
    isLoading,
    isSuccess,
    isFetched
  } = userProfileQuery.useUserProfile(user?.id || 0, {
    enabled: !!user?.id
  });

  useEffect(() => {
    if (!isLoading && !isSuccess && !isFetched && !userProfile) {
      // thats registration case
      updateInvitationList([
        // starting fields for NUX
        { name: '', email: '', userType: USER_TYPE.PARENT },
        { name: '', email: '', userType: USER_TYPE.PARENT },
        { name: '', email: '', userType: USER_TYPE.COUNSELOR },
        { name: '', email: '', userType: USER_TYPE.RECOMMENDER },
        { name: '', email: '', userType: USER_TYPE.RECOMMENDER }
      ]);
    } else if (userProfile && !isLoading && isSuccess && isFetched) {
      const initialList: Invitation[] = [];

      const existingRoles = countBy(userProfile.relationships || [], 'userType');

      if (!existingRoles[USER_TYPE.COUNSELOR]) {
        initialList.push({ name: '', email: '', userType: USER_TYPE.COUNSELOR });
      }

      if (!existingRoles[USER_TYPE.PARENT] || existingRoles[USER_TYPE.PARENT] < 2) {
        initialList.push({ name: '', email: '', userType: USER_TYPE.PARENT });
      }

      if (!existingRoles[USER_TYPE.RECOMMENDER] || existingRoles[USER_TYPE.RECOMMENDER] < 4) {
        initialList.push({ name: '', email: '', userType: USER_TYPE.RECOMMENDER });
      }

      updateInvitationList(initialList);
    }
  }, [isLoading, isSuccess, isFetched]);

  const applicationClient = useApplicationClient();
  const queryClient = useQueryClient();

  const [localViewMode, setViewMode] = useState(initialViewMode);

  confirmationCallback = confirmationCallback
    ? confirmationCallback
    : async invitations => {
        applicationClient
          .processInvitations(invitations)
          .then(() => setViewMode(StudentInvitationsViewMode.SUCCESS_VIEW))
          .catch(() => setViewMode(StudentInvitationsViewMode.ERROR_VIEW));
      };

  const [subStep, setSubstep] = useState(INVITATION_SUBSTEP.COLLECTING);

  const [verifiedInvitations, setVerifiedInvitations] = useState<VerifiedInvitation[]>([]);
  const [optionalInvitesList, updateOptionalInvitesList] = useState<InvitationConfirmation[]>([]);

  useEffect(() => {
    if (localViewMode === StudentInvitationsViewMode.HOME_VIEW) {
      onPageChange('Invites', '#invites');
    }
  }, [localViewMode]);

  const hasAtLeastOneInvite = invitationList.reduce((prev: boolean, next: Invitation) => {
    return prev || (next.email !== '' && next.name !== '');
  }, false);

  // we need to finish registration, as well as provide email lists for letters
  const handleConfirm = async () => {
    if (isLoading) {
      return false;
    }

    if (hasAtLeastOneInvite && subStep === INVITATION_SUBSTEP.COLLECTING) {
      // check backend
      const verificationStatus = await applicationClient.verifyInvitations(
        invitationList.filter(invitation => invitation.name && invitation.email && invitation.userType)
      );

      if (!verificationStatus?.invitationStatuses?.length) {
        return setViewMode(StudentInvitationsViewMode.ERROR_VIEW);
      }

      const { invitationStatuses } = verificationStatus;

      updateOptionalInvitesList(
        invitationStatuses
          .filter(verifiedInvitation => verifiedInvitation.status !== INVITATION_STATUS.WRONG_RELATIONSHIP)
          .map(inv => ({
            email: inv.email,
            name: inv.name,
            status: inv.status,
            userType: inv.userType,
            isInviting: true
          }))
      ); // by default we 'invite'(creating relationships) with all users who has no errors with that

      setVerifiedInvitations(invitationStatuses);
      setSubstep(INVITATION_SUBSTEP.SUBMITTING);
    } else if (subStep === INVITATION_SUBSTEP.SUBMITTING) {
      return confirmationCallback(optionalInvitesList);
    } else {
      // user decided to skip this step
      return confirmationCallback([]);
    }
  };

  const invitationsChanged = (type: USER_TYPE, invitations: Invitation[]) => {
    updateInvitationList([
      ...invitationList.filter(record => record.userType !== type), // non-affected by this exactly invitation block
      ...invitations // actually changed records
    ]);
  };

  // Any kind of error result
  if (localViewMode === StudentInvitationsViewMode.ERROR_VIEW) {
    return (
      <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
        Operation failed. Try once again please.
      </EuiCallOut>
    );
  }

  if (localViewMode === StudentInvitationsViewMode.SUCCESS_VIEW) {
    return (
      <EuiCallOut title={'Invitations completed'} color="success" iconType="check">
        <p>
          Invitation process is completed, thank you for using <strong>Scholar's App</strong>!
        </p>
      </EuiCallOut>
    );
  }

  const descriptionBlock = (
    <EuiText className="studentInvitations-description">
      {localViewMode === StudentInvitationsViewMode.REGISTRATION_VIEW ? (
        <>
          {subStep === INVITATION_SUBSTEP.COLLECTING
            ? "Let's invite your parents/legal guardians, recommenders, and counselor to the platform."
            : 'Verify and confirm your invitations before signing up.'}
        </>
      ) : subStep === INVITATION_SUBSTEP.SUBMITTING ? (
        'Verify and confirm your invitations before sending.'
      ) : (
        "Let's invite your parents/legal guardians, recommenders, and counselor to the platform."
      )}
    </EuiText>
  );

  const actionButtonTitle =
    localViewMode === StudentInvitationsViewMode.REGISTRATION_VIEW
      ? subStep === INVITATION_SUBSTEP.COLLECTING
        ? hasAtLeastOneInvite
          ? 'Continue'
          : 'Skip this step for now'
        : 'Sign up'
      : 'Invite';

  const onUpdateHandler = () => {
    queryClient.invalidateQueries(userProfileQuery.QUERY_KEY);
  };

  return (
    <EuiFlexGroup className="StudentInvitations" direction="column" responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>{descriptionBlock}</EuiFlexItem>
      <EuiFlexItem>
        {subStep === INVITATION_SUBSTEP.SUBMITTING ? (
          <InvitationSubmittingView
            verifiedInvitations={verifiedInvitations}
            optionalInvitesList={optionalInvitesList}
            updateOptionalInvitesList={updateOptionalInvitesList}
          />
        ) : (
          <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
            <EuiFlexItem>
              {isLoading ? (
                <EuiLoadingContent lines={2} />
              ) : (
                <InvitesBlock
                  entries={(userProfile?.relationships || []).filter(
                    rel => rel.userType === USER_TYPE.PARENT || rel.userType === USER_TYPE.APPLICANT
                  )}
                  role={USER_TYPE.PARENT}
                  invites={invitationList.filter(record => record.userType === USER_TYPE.PARENT)}
                  onInviteChanged={invitationsChanged}
                  showControls={false}
                  maxRecords={2}
                  isFreezed={isLoading}
                  legendTitle="Parents/Legal Guardians"
                  applicationClient={applicationClient}
                  onUpdate={onUpdateHandler}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem>
              {isLoading ? (
                <EuiLoadingContent lines={2} />
              ) : (
                <InvitesBlock
                  entries={(userProfile?.relationships || []).filter(rel => rel.userType === USER_TYPE.RECOMMENDER)}
                  role={USER_TYPE.RECOMMENDER}
                  invites={invitationList.filter(record => record.userType === USER_TYPE.RECOMMENDER)}
                  onInviteChanged={invitationsChanged}
                  maxRecords={5}
                  isFreezed={isLoading}
                  applicationClient={applicationClient}
                  onUpdate={onUpdateHandler}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem>
              {isLoading ? (
                <EuiLoadingContent lines={1} />
              ) : (
                <InvitesBlock
                  entries={(userProfile?.relationships || []).filter(rel => rel.userType === USER_TYPE.COUNSELOR)}
                  role={USER_TYPE.COUNSELOR}
                  invites={invitationList.filter(record => record.userType === USER_TYPE.COUNSELOR)}
                  onInviteChanged={invitationsChanged}
                  showControls={false}
                  isFreezed={isLoading}
                  legendTitle="College Counselor"
                  maxRecords={1}
                  applicationClient={applicationClient}
                  onUpdate={onUpdateHandler}
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="center" direction="column" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiButton
              fill
              size="s"
              onClick={handleConfirm}
              isLoading={isLoading}
              isDisabled={localViewMode === StudentInvitationsViewMode.HOME_VIEW && !hasAtLeastOneInvite}
            >
              {actionButtonTitle}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default StudentInvitations;
