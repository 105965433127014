import { replace, toPairs, toString } from 'lodash';
import React from 'react';

// !!! Has to be the same with LOB template in dashboard. Eventually we may grab that from lob service tho, but its not yet possible
const HTML_STRING = `<html>
  <head>
    <meta charset="UTF-8" />
    <title>Scholar's App mailing template</title>
    <style>
      *,
      *:before,
      *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
      body {
        width: 8.5in;
        height: 22in;
        margin: 0;
        padding: 0;
        /* your background image should have dimensions of 2588x3338 pixels. */
      }
      .page {
        font-family: Arial, Helvetica, sans-serif;
        page-break-after: always;
        position: relative;
        width: 8.5in;
        height: 11in;

        border: 1px dotted gray;
      }
      .page-content {
        position: absolute;
        width: 8.125in;
        height: 10.625in;
        left: 0.1875in;
        top: 0.1875in;
      }
      .text {
        position: relative;
        left: 55px;
        top: 3.2in;
        width: 7in;
        font-size: 14pt;
        line-height: 24px;
      }
      #logo-bg {
        height: 1.5in;
        width: 8.5in;
      }
      #footer {
        position: absolute;
        bottom: 0;
        height: 0.75in;
        width: 8.5in;
      }

      /* second page */
      #scholarship-logo-block {
        height: 1.71in;
        width: 8.5in;
      }
      #scholarship-logo {
        width: 8.5in;
        text-align: center;
        max-height: 1.71in;
      }
      #scholarship-name {
        font-size: 27pt;
        line-height: 1.25;
        padding: 0.2in 0.5in 0 0.5in;
        color: black;
        text-align: center;
        letter-spacing: 1px;
      }
      #scholarship-qr {
        width: 8.5in;
        display: block;
        position: relative;
        height: 2.4in;
      }
      #qr-code {
        position: absolute;
        right: 3.325in;
        top: 0px;
      }
      .blueblock-wrap {
        width: 8.5in;
      }
      .blueblock {
        margin: 0 0.25in;
        background-color: #5296ff;
        height: 0.75in;
        width: 8in;
        font-size: 28px;
        color: white;
        padding: 20px 0 0 10px;
        font-weight: bold;
      }
      .scholarship-text {
        padding: 14px 40px;
        font-size: 20px;
      }
      .centered {
        text-align: center;
        font-size: 36px;
      }
    </style>
  </head>

  <body>
    <div class="page">
      <div id="logo-bg">
      </div>
      <div class="page-content">
        <div class="text">
          {{letter_content}}
        </div>
      </div>
      <div id="footer"></div>
    </div>
    <div class="page">
      <div id="scholarship-logo-block">
        <div id="scholarship-logo">
          {{logo_img}}
        </div>
      </div>
      <div id="scholarship-name">
        {{scholarship_name}}
      </div>
      <div id="scholarship-qr">
        <div id="qr-code">{{qr_code}}</div>
      </div>
      <div class="blueblock-wrap">
        <div class="blueblock">Who is the scholarship for?</div>
      </div>
      <div class="scholarship-text">
        {{who_text}}
      </div>
      <div class="blueblock-wrap"><div class="blueblock">Eligibility</div></div>
      <div class="scholarship-text">
        {{eligibility_text}}
      </div>
    </div>
  </body>
</html>
`;

const TemplatePreview = ({ variables }) => {
  const variablesPairs = toPairs(variables);

  let preparedTemplate = HTML_STRING;

  for (const pair of variablesPairs) {
    preparedTemplate = replace(preparedTemplate, new RegExp(`{{${pair[0]}}}`, 'g'), toString(pair[1]));
  }

  return (
    <div
      style={{
        height: '22in',
        width: '8.5in'
      }}
      dangerouslySetInnerHTML={{ __html: preparedTemplate }}
    ></div>
  );
};

export default TemplatePreview;
