import { useQuery } from 'react-query';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import { ScholarshipModelShape } from '@sharedComponents/models';
import { FORM_CHANGE_REQUEST_STATUSES } from '@sharedComponents/interfaces/Scholarships.interface';

// unique key for this query
const CURRENT_QUERY_KEY = 'formsToReview';

function useFormsToReview() {
  const scholarshipClient = useScholarshipClient();
  const query = {
    ...useQuery<
      Array<ScholarshipModelShape & { reviewStatus: FORM_CHANGE_REQUEST_STATUSES; reviewDeadline: string }>,
      Error
    >([CURRENT_QUERY_KEY], () => scholarshipClient.getFormsToReview(), {
      refetchOnWindowFocus: true
    })
  };

  return query;
}

const formsToReviewQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useFormsToReview };
export default formsToReviewQuery;
