import './ScholarshipsToCheck.scss';

import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';

import useCachedState from '@donors/base/useCachedState';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import ScholarshipToCheckModel from '@sharedClients/types/ScholarshipToCheckModel';
import { LoadingIcon } from '@sharedComponents/base/components/Icons';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

import { HeaderProps } from '../components/Header';
import { notFoundIsUserError } from '../donor/Donor';
import ScholarshipsToCheckList from './ScholarshipsToCheckList';

interface ScholarshipsProps extends HeaderProps {}

const ScholarshipsToCheck: FunctionComponent<PropsWithChildren<ScholarshipsProps>> = props => {
  const scholarshipClient = useScholarshipClient();

  const [scholarshipsToCheck, setScholarshipsToCheck] = useCachedState<ScholarshipToCheckModel[]>(
    'scholarships.to.check',
    []
  );
  const [onError, errorMessage] = useErrorReporter();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    scholarshipClient
      .getScholarshipsToCheck({})
      .then(scholarshipsToCheck => {
        setScholarshipsToCheck(scholarshipsToCheck);
      })
      .catch(notFoundIsUserError(onError))
      .then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="scholarships-to-check">
      <div className="body">
        <div className="content">
          {errorMessage}

          {isLoading ? (
            <LoadingIcon />
          ) : (
            <ScholarshipsToCheckList scholarshipsToCheck={scholarshipsToCheck} scholarshipClient={scholarshipClient} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScholarshipsToCheck;
