import { useCallback } from 'react';
import wretch from 'wretch';

import { getServiceUrl } from '@sharedClients/getServiceUrl';
import { useAuth } from '@sharedComponents/selectors/useAuth';

// ! thats a part of legacy mechanism which Im not yet sure that its safe to remove.
const stdPrefix = 'https://';
const stdOrigin = 'scholarsapp.com';

let originCache = null;

function getOrigin() {
  const compressOrigin = origin => {
    if (origin.endsWith('.' + stdOrigin)) {
      origin = origin.substr(0, origin.length - stdOrigin.length);
    }

    if (origin.startsWith(stdPrefix)) {
      origin = origin.substr(stdPrefix.length);
    }

    return origin;
  };

  if (!originCache) {
    originCache = compressOrigin(window.location.origin);
  }

  return originCache;
}

/**
 * TODO
 * use more catchers for errors handling
 * Performance API with resolver?
 * abortcontroller to use with r-q?
 */
//
export default function useFetcher() {
  const auth = useAuth();

  return useCallback(() => {
    const baseUrl = getServiceUrl('applications');

    return wretch(baseUrl)
      .query({ o: getOrigin() })
      .options({ encoding: 'same-origin', headers: { 'Content-Type': 'text/plain', Accept: 'application/json' } })
      .auth(auth.jwt || '')
      .catcher(401, async () => {
        // ? token refreshing?
        auth.logoutExecute();
      })
      .resolve(resolver => resolver.json(_ => _ /* automatically parse and return json */));
  }, [auth.jwt]);
}
