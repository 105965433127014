import { useQuery, UseQueryOptions } from 'react-query';

import { ScholarshipWithReviewStatus } from '@sharedComponents/interfaces/Scholarships.interface';

// unique key for this query
const CURRENT_QUERY_KEY = 'scholarshipWithReviewStatus';

function useScholarshipWithReviewStatus(
  client,
  scholarshipID: number = 0, // due to 'enabled' value it wont be fetched with no param, but lets such syntax to exist
  cacheKey = CURRENT_QUERY_KEY,
  options: UseQueryOptions<ScholarshipWithReviewStatus, Error, ScholarshipWithReviewStatus> = {}
) {
  return useQuery<ScholarshipWithReviewStatus, Error>(
    [cacheKey, scholarshipID],
    () => client.getScholarshipReviewStatus(scholarshipID),
    {
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
      enabled: scholarshipID > 0, // 0 is the case with optional param when no param yet provided
      ...options
    }
  );
}

export default { QUERY_KEY: CURRENT_QUERY_KEY, useScholarshipWithReviewStatus };
