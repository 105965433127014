import React, { useEffect } from 'react';
import { ArrayField, useFieldArray, useFormContext, FieldError } from 'react-hook-form';

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiNotificationBadge } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/**
 * Represents wrapper for any kind of field setup which are bind to react-hook-form context
 * adds append/removal functionality to those
 */
export default function FieldList({
  fieldName,
  minListItems = 0,
  fieldFactoryFn,
  recordDefaultValue,
  rowProps,
  disabled = false
}: {
  fieldName: string;
  minListItems?: number;
  /**
   * @rules defaultValue must be set for all inputs. Each input name needs to be unique.
   * @param fieldNamePrefix should be used for returned formfields as a prefix for their 'name' attribute
   * @param field current formfield representation
   * @returns JSX.Element any kind of form fields registered via 'register' func
   */
  fieldFactoryFn: (
    fieldNamePrefix: string,
    register: React.Ref<HTMLInputElement>,
    field: Partial<ArrayField<Record<string, any>, 'id'>>,
    control: any // Control<Record<string, any>>
  ) => JSX.Element;
  /**
   * Should represent default values for any new element added into fieldlist
   */
  recordDefaultValue: Record<string, unknown>;
  rowProps?: FormRowProps['rowProps'];
  disabled?: boolean;
}) {
  const { control, register, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName // name for overall control
  });

  // minListItems to fill missing elements if needed
  useEffect(() => {
    if (fields.length < minListItems) {
      append({ ...recordDefaultValue }, false);
    }
  }, [fields, minListItems]);

  if (fields.length < minListItems) {
    return null; // skip render until its filled with all items
  }

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const renderedFieldList = (
    <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
      <EuiFlexItem>
        <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
          {fields.map((field, index) => (
            <EuiFlexItem key={field.id}>
              <EuiFlexGroup direction="row" gutterSize="xs" responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiNotificationBadge size="s" color="subdued" style={{ marginTop: '2px' }}>
                    {index + 1}
                  </EuiNotificationBadge>
                </EuiFlexItem>
                <EuiFlexItem>{fieldFactoryFn(`${fieldName}[${index}].`, register, field, control)}</EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    aria-label="Remove item"
                    color="danger"
                    iconType="minus"
                    onClick={() => remove(index)}
                    isDisabled={disabled || index + 1 <= minListItems}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiButtonIcon
          aria-label="Add item"
          color="primary"
          style={{ alignSelf: 'flex-end' }}
          iconType="plus"
          isDisabled={disabled}
          onClick={() => append({ ...recordDefaultValue })}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {renderedFieldList}
    </FormRow>
  ) : (
    renderedFieldList
  );
}
