import { APPLICATION_NODE_TYPE } from '@sharedComponents/schemas/FormNodesSchema';
import { NodeBodyAddress } from './FormNodeBody/NodeBodyAddress';

import { NodeBodyCheckbox } from './FormNodeBody/NodeBodyCheckbox';
import { NodeBodyInput } from './FormNodeBody/NodeBodyInput';
import { NodeBodyNumber } from './FormNodeBody/NodeBodyNumber';
import { NodeBodyPage } from './FormNodeBody/NodeBodyPage';
import { NodeBodySection } from './FormNodeBody/NodeBodySection';
import { NodeBodySelect } from './FormNodeBody/NodeBodySelect';
import { NodeBodyUpload } from './FormNodeBody/NodeBodyUpload';

const FormNodeBody = ({ type }: { type: APPLICATION_NODE_TYPE }) => {
  if (type === APPLICATION_NODE_TYPE.TYPE_PAGE) {
    return NodeBodyPage;
  } else if (type === APPLICATION_NODE_TYPE.TYPE_SECTION) {
    return NodeBodySection;
  } else if (
    [APPLICATION_NODE_TYPE.TYPE_INPUT, APPLICATION_NODE_TYPE.TYPE_PHONE, APPLICATION_NODE_TYPE.TYPE_EMAIL].includes(
      type
    )
  ) {
    return NodeBodyInput;
  } else if (type === APPLICATION_NODE_TYPE.TYPE_SELECT) {
    return NodeBodySelect;
  } else if (type === APPLICATION_NODE_TYPE.TYPE_CHECKBOX) {
    return NodeBodyCheckbox;
  } else if (type === APPLICATION_NODE_TYPE.TYPE_ADDRESS) {
    return NodeBodyAddress;
  } else if (type === APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT) {
    return NodeBodyNumber;
  } else if (
    type === APPLICATION_NODE_TYPE.TYPE_UPLOADER ||
    type === APPLICATION_NODE_TYPE.TYPE_PDF ||
    type === APPLICATION_NODE_TYPE.TYPE_IMAGE
  ) {
    return NodeBodyUpload;
  }

  return null;
};

export default FormNodeBody;
