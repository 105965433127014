import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle, EuiText } from '@elastic/eui';
import ComboboxField from '@sharedComponents/formElements/simpleFields/ComboboxField';
import college_types from '@sharedComponents/models/enumValues/college_types';

import AbstractEntryStep from './AbstractEntryStep';
import SchoolEligibilitySelection from './EntryStepEligibility/SchoolEligibilitySelection';
import CollegeEligibilitySelection from './EntryStepEligibility/CollegeEligibilitySelection';
import ResidencyEligibilitySelection from './EntryStepEligibility/ResidencyEligibilitySelection';

const schoolsOptions = ['Any', 'Specific', 'State', 'County'];
const collegeOptions = [...schoolsOptions];
const residencyOptions = ['Any', 'State', 'County'];
const EntryStepEligibility: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    schoolsMatching: yup.string().oneOf(schoolsOptions),
    collegeMatching: yup.string().oneOf(collegeOptions),
    residencyMatching: yup.string().oneOf(residencyOptions),

    data: yup.object().shape({
      collegeTypes: yup.array(yup.string().oneOf(college_types)).nullable(),

      //  Schools based on schoolsMatching
      schools: yup.array(yup.object()).when('schoolsMatching', {
        is: 'Specific',
        then: yup
          .array(
            yup.object().shape({
              id: yup.number(),
              name: yup.string()
            })
          )
          .nullable(),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),
      schoolStates: yup.array(yup.string()).when('schoolsMatching', {
        is: 'State',
        then: yup.array(yup.string().min(2).max(2)).nullable(),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),
      schoolCounties: yup.array(yup.string()).when('schoolsMatching', {
        is: 'County',
        then: yup.array(yup.string()),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),

      // Colleges based on collegeMatching
      colleges: yup.array(yup.object()).when('collegeMatching', {
        is: 'Specific',
        then: yup.array(
          yup.object({
            id: yup.number(),
            name: yup.string()
          })
        ),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),
      collegeStates: yup.array(yup.string()).when('collegeMatching', {
        is: 'State',
        then: yup.array(yup.string().min(2).max(2)).nullable(),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),
      collegeCounties: yup.array(yup.string()).when('collegeMatching', {
        is: 'County',
        then: yup.array(yup.string()),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),

      // Residency based on residencyOptions
      residency: yup.array(yup.string()).when('residencyMatching', {
        is: 'State',
        then: yup.array(yup.string().min(2).max(2)).nullable(),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      }),
      residencyCounties: yup.array(yup.string()).when('residencyMatching', {
        is: 'County',
        then: yup.array(yup.string()),
        otherwise: yup
          .array()
          .nullable()
          .transform(() => null)
      })
    })
  }),

  render: function (rowProps) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Eligibility</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <SchoolEligibilitySelection rowProps={rowProps} />
                  <EuiSpacer size="xs" />
                  <EuiText size="xs">
                    First, select if this scholarship is only available for students who attend a specific high school,
                    a high school in a particular state, or a high school in a particular county. Next, select the
                    specific high school name, high school state, or high school county.
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <ResidencyEligibilitySelection rowProps={rowProps} />
                  <EuiSpacer size="xs" />
                  <EuiText size="xs">
                    First, select if this scholarship is only available for students who live in particular state, or
                    particular county. Next, select the state or county name.
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <CollegeEligibilitySelection />
                  <EuiSpacer size="xs" />
                  <EuiText size="xs">
                    First, select if this scholarship is only available for students who plan to attend a specific
                    college, a college in a particular state, or a college in a particular county. Next, select the
                    specific college name, college state, or college county.
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.collegeTypes'}
                    options={college_types}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more'
                    }}
                    rowProps={{ label: 'College Study Rate', ...rowProps }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepEligibility;
