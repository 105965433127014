import { useMutation } from 'react-query';

import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'answerQuestion';

// mutation to upsert
const useMutateAnswerQuestion = () => {
  const applicationClient = useApplicationClient();

  return {
    ...useMutation<
      any, // returned result result by our api
      Error, // error type
      {
        answer: string;
        questionId: number;
      }
      // parameters accepted by mutation
    >(({ answer, questionId }) => applicationClient.answerQuestion(answer, questionId))
  };
};

const answerQuestionQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useMutateAnswerQuestion };
export default answerQuestionQuery;
