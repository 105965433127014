/**
 * The field type "recommenders" expands to this definition.
 * Unfortunately duplicated in apply.
 */
const recommendersForm = {
  type: 'list',
  title: 'Recommenders',
  path: 'recommenders',
  columns: [
    [
      {
        type: 'address',
        children: [
          { field: 'name', label: 'First name', required: true },
          { field: 'lastName', required: true },
          {
            field: 'relationship',
            label: 'Relationship to applicant',
            required: false
          },
          { field: 'email', type: 'email', required: true },
          { field: 'phone', type: 'phone', width: 14, required: true }
        ]
      }
    ]
  ]
};
export default recommendersForm;
