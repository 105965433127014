import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import { formatNumber } from '../base/MarkdownLite';
import { ScholarshipModelShape } from '../models';

// Could be improved once ScholarshipModel will be dropped cuz there's virtuals in shape
export default function formatAwardAmount(scholarship: ScholarshipModel | ScholarshipModelShape) {
  const from =
    (scholarship as ScholarshipModel).awardAmountFrom || (scholarship as ScholarshipModelShape).award_amount_from || 0;
  const to =
    (scholarship as ScholarshipModel).awardAmountTo || (scholarship as ScholarshipModelShape).award_amount_to || 0;

  const currency = scholarship.currency || '$';

  if (!from && !to) {
    return 'varies';
  }

  if (from === to) {
    const amount = from;

    if (amount) {
      return currency + formatNumber(amount);
    } else {
      return 'varies';
    }
  } else if (from === 0) {
    return `up to ${currency}${formatNumber(to)}`;
  } else {
    return `${currency}${formatNumber(from)} – ${currency}${formatNumber(to)}`; // dash, not minus
  }
}
