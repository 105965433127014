import React, { useState, useEffect } from 'react';
import './Checkbox.scss';

/**
 * TODO: Replace this with UI kit
 * @deprecated
 */

const Checkbox = ({
  label = '',
  isChecked = false,
  onChange
}: {
  label?: string | JSX.Element;
  isChecked: boolean;
  onChange: () => void;
}) => {
  const [checkboxStatus, toggleChecked] = useState(isChecked);

  // If state update coming from parent, update local state
  useEffect(() => {
    toggleChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="checkbox">
      <label>
        <input
          className="checkbox-input"
          tabIndex={0}
          type="checkbox"
          checked={checkboxStatus}
          onChange={() => {
            toggleChecked(!checkboxStatus); // local state for checkbox

            onChange(); // emit checkbox change
          }}
        />
        <span className="checkbox-text">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
