import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiNotificationBadge,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell
} from '@elastic/eui';

const recordDefaultValue = { name: '', weight: null, scale: 10 };
const minListItems = 1;

export default function CriteriasList({
  round,
  control,
  register,
  formWatch
}: {
  round: string;
  control;
  register;
  formWatch;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: round
  });

  // minListItems to fill missing elements if needed
  useEffect(() => {
    if (fields.length < minListItems) {
      append({ ...recordDefaultValue }, false);
    }
  }, [fields, minListItems]);

  if (fields.length < minListItems) {
    return null; // skip render until its filled with all items
  }

  const criteriaFields = formWatch(round);
  return (
    <EuiFlexGroup direction="column" gutterSize="xs">
      <EuiFlexItem>
        <EuiTable>
          <EuiTableHeader>
            <EuiTableHeaderCell width="8%"></EuiTableHeaderCell>
            <EuiTableHeaderCell key="criteria_id" align={'left'}>
              Criteria description
            </EuiTableHeaderCell>
            {criteriaFields && criteriaFields[0]?.weight ? (
              <EuiTableHeaderCell width="15%" key="weight" align={'left'}>
                Weight
              </EuiTableHeaderCell>
            ) : null}
            <EuiTableHeaderCell width="4%"></EuiTableHeaderCell>
          </EuiTableHeader>
          <EuiTableBody>
            {fields.map((field, index) => {
              return (
                <EuiTableRow key={field.id}>
                  <EuiTableRowCell>
                    <EuiNotificationBadge size="s" color="subdued" style={{ marginTop: '2px' }}>
                      {index + 1}
                    </EuiNotificationBadge>
                  </EuiTableRowCell>
                  <EuiTableRowCell textOnly={false}>
                    <EuiFieldText
                      type="text"
                      name={`${round}.${index}.name`}
                      inputRef={register()}
                      defaultValue={field['name']}
                      placeholder={'Criteria Description'}
                      fullWidth
                    />
                  </EuiTableRowCell>
                  {criteriaFields && criteriaFields[0]?.weight ? (
                    <EuiTableRowCell>
                      <EuiFieldText
                        type="text"
                        name={`${round}.${index}.weight`}
                        inputRef={register({
                          valueAsNumber: true
                        })}
                        defaultValue={field['weight']}
                        placeholder={'Weight'}
                      />
                    </EuiTableRowCell>
                  ) : null}
                  <EuiTableRowCell>
                    <EuiButtonIcon
                      aria-label="Remove item"
                      color="danger"
                      iconType="minus"
                      onClick={() => remove(index)}
                      isDisabled={index === 0}
                    />
                  </EuiTableRowCell>
                </EuiTableRow>
              );
            })}
          </EuiTableBody>
        </EuiTable>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiButtonIcon
          aria-label="Add item"
          color="primary"
          style={{ alignSelf: 'flex-end', marginRight: '8px' }}
          iconType="plus"
          onClick={() => append({ ...recordDefaultValue })}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSpacer />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={2}> </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiFormRow key={`${round}.0.scale`} label={`Scoring Range: `} display="columnCompressed">
              {/** Hack: we consider first criteria scale as whole round scale since legacy format supposed to have different scales per each criteria, while we run per round configurations */}
              <EuiFieldText
                type="number"
                name={`${round}.0.scale`}
                inputRef={register({
                  valueAsNumber: true,
                  max: {
                    value: 100
                  }
                })}
                defaultValue={fields[0]['scale']}
                fullWidth={false}
                max={100}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
