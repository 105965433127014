export function doRepeat(factory: (key: number) => JSX.Element, times) {
  times = parseInt(times);
  if (isNaN(times) || !times) {
    return null;
  }

  let result: JSX.Element[] = [];
  for (let i = 0; i < times; i++) {
    result.push(factory(i));
  }

  return result;
}

/**
 * Resolves promise after {ms} amount of milliseconds
 */
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isNumber(s: string) {
  // using this test rather than !isNaN(parseInt) beacuse that would return true for the string "2) Name"
  return !s.trim().match(/[^0-9.]/);
}

/** Utility method to addEventListener */
export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args //: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
  }
}

/** Utility method to removeEventListener */
export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args //: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
  }
}
