/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useMemo } from 'react';
import './USMap.scss';

export default function USMap({ distribution }) {
  const scale = 16;

  let cleanedDistribution = useMemo(() => sanitizeDistribution(distribution), [distribution]);
  const max = Math.max(...Object.values(cleanedDistribution));

  return (
    <svg className="USMap" width={12 * scale} height={8 * scale}>
      {Object.keys(states).map(state => {
        let [x, y] = states[state];
        x = x - 1;
        y = y - 1;

        const count = cleanedDistribution[state];
        const percentage = (count || 0) / max;

        return (
          <g key={state} id={state} datacount={count || 0}>
            <rect
              x={x * scale}
              y={y * scale}
              width={scale}
              height={scale}
              style={{
                fill: getColor(percentage)
              }}
            />
            <text
              x={x * scale + scale / 2}
              y={y * scale + scale / 2 + 1}
              text={state}
              textAnchor="middle"
              dominantBaseline="middle"
              className={percentage > 0.5 ? 'invert' : ''}
            >
              {state}
            </text>
          </g>
        );
      })}
    </svg>
  );
}

/**
 * Removes any keys that do not correspond to states and renames any keys with state names
 * to state abbreviations.
 */
export function sanitizeDistribution(distribution) {
  return Object.keys(distribution || []).reduce((cleanedDistribution, key) => {
    let state;

    if (isStateAbbreviation(key)) {
      state = key;
    } else {
      state = stateByName[key.toLowerCase()];
    }

    if (state) {
      cleanedDistribution[state] = (cleanedDistribution[state] || 0) + distribution[key];
    }

    return cleanedDistribution;
  }, {});
}

function isStateAbbreviation(str) {
  return states[str] != null;
}

export function getColor(percentage) {
  const c = Math.round(255 * (1 - percentage));

  return `rgb(${c},${c},255)`;
}

export const states = {
  AK: [1, 1, 'arkansas'],
  ME: [12, 1, 'maine'],
  VT: [11, 2, 'vermont'],
  NH: [12, 2, 'new hampshire'],
  WA: [2, 3, 'washington'],
  ID: [3, 3, 'idaho'],
  MT: [4, 3, 'montana'],
  ND: [5, 3, 'north dakota'],
  MN: [6, 3, 'minnesota'],
  IL: [7, 3, 'illinois'],
  WI: [8, 3, 'wisconsin'],
  MI: [9, 3, 'michigan'],
  NY: [10, 3, 'new york'],
  RI: [11, 3, 'rhode island'],
  MA: [12, 3, 'massachusetts'],
  OR: [2, 4, 'oregon'],
  NV: [3, 4, 'nevada'],
  WY: [4, 4, 'wyoming'],
  SD: [5, 4, 'south dakota'],
  IA: [6, 4, 'iowa'],
  IN: [7, 4, 'indiana'],
  OH: [8, 4, 'ohio'],
  PA: [9, 4, 'pennsylviana'],
  NJ: [10, 4, 'new jersey'],
  CT: [11, 4, 'Connecticut'],
  CA: [2, 5, 'california'],
  UT: [3, 5, 'utah'],
  CO: [4, 5, 'colorado'],
  NE: [5, 5, 'nebraska'],
  MO: [6, 5, 'missouri'],
  KY: [7, 5, 'kentucky'],
  WV: [8, 5, 'west virginia'],
  VA: [9, 5, 'virginia'],
  MD: [10, 5, 'maryland'],
  DE: [11, 5, 'delaware'],
  AZ: [3, 6, 'arizona'],
  NM: [4, 6, 'new mexico'],
  KS: [5, 6, 'kansas'],
  AR: [6, 6, 'arkansas'],
  TN: [7, 6, 'tennessee'],
  NC: [8, 6, 'north carolina'],
  SC: [9, 6, 'south carolina'],
  DC: [10, 6, 'washington dc'],
  OK: [5, 7, 'oklahoma'],
  LA: [6, 7, 'louisiana'],
  MS: [7, 7, 'mississippi'],
  AL: [8, 7, 'alamaba'],
  GA: [9, 7, 'georgia'],
  HI: [1, 8, 'hawaii'],
  TX: [5, 8, 'texas'],
  FL: [10, 8, 'florida']
};

const stateByName = Object.keys(states).reduce((obj, state) => {
  const name = states[state][2];

  obj[name] = state;
  return obj;
}, {});
