import { FormNodeProps } from '../../apply/FormNodeProps';
import React from 'react';
import FormNode from '../../model/FormNode';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { AwardStatus } from '@sharedClients/types/ApplicationModel';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';
import AcceptChoice from './FormAcceptAward/AcceptChoice';

/**
 * Represents accept/reject award functionality
 * Emulates selection with 'No'/'Yes' options, so it can be used in conditionals
 */
export default function FormAcceptAward(props: FormNodeProps) {
  const { application } = props;
  const fieldId = props.node.field;

  if (fieldId !== 'acceptAward') {
    props.onFormError(new Error(`Accept award field must have field ID "acceptAward"`));
    return null;
  }

  const onFieldChange = (value: 'Yes' | 'No') => {
    const awardStatus =
      { Yes: AWARD_STATUS.AWARD_ACCEPTED, No: AWARD_STATUS.AWARD_REJECTED }[value] || AWARD_STATUS.AWARD_NOTIFIED;

    const awardStatusNode = new FormNode({ field: 'awardStatus' }, props.onFormError);

    awardStatusNode.setValue = (awardStatus: AwardStatus, application: ApplicationModel) => {
      return { ...application, awardStatus };
    };

    props.onFieldChange([value, awardStatus], [props.node, awardStatusNode]);
  };

  return application.awardStatus === AWARD_STATUS.AWARD_NOTIFIED ? (
    <AcceptChoice application={application} onChoice={onFieldChange} />
  ) : null;
}
