import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { MAILING_LAST_STEP_NUMBER } from '@sharedComponents/interfaces/Mailing.interface';

const MailingNavigation = ({ currentStep, nextStepHandler, returnBackHandler, isLoading }) => {
  return (
    <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty onClick={returnBackHandler} iconType="arrowLeft" size="xs" isDisabled={isLoading}>
          Go Back
        </EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem>{/* Spacer */}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          color="primary"
          onClick={nextStepHandler}
          iconType="arrowRight"
          iconSide="right"
          fill={currentStep === MAILING_LAST_STEP_NUMBER}
          isLoading={isLoading}
          size="s"
        >
          {currentStep === MAILING_LAST_STEP_NUMBER ? 'Submit Mailing' : 'Next'}
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default MailingNavigation;
