import { array, boolean, date, InferType, number, object, string } from 'yup';

import academic_years from '../models/enumValues/academic_years';
import athletics from '../models/enumValues/athletics';
import college_types from '../models/enumValues/college_types';
import ethnicities from '../models/enumValues/ethnicities';
import miscellaneous from '../models/enumValues/miscellaneous';
import religious from '../models/enumValues/religious';
import selfIdentification from '../models/enumValues/selfIdentification';
import studyRates from '../models/enumValues/study_rates';
import veteran_status from '../models/enumValues/veteran_status';
import allAreas from '../models/enumValues/areas';

export const ScholarshipListingFilterSchema = object({}).shape({
  isShowDeleted: boolean(),
  isShowExpired: boolean(),
  isDraftView: boolean(),
  textSearch: string(),
  draftSteps: array(number()), // SCHOLARSHIP_ENTRY_STEPS

  // detailed filters
  startDateFrom: date().nullable().default(undefined),
  startDateTo: date().nullable().default(undefined),
  deadlineDateFrom: date().nullable().default(undefined),
  deadlineDateTo: date().nullable().default(undefined),
  gpaFrom: number()
    .min(0)
    .max(5)
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    })
    .notRequired()
    .nullable()
    .default(undefined),
  gpaTo: number()
    .min(0)
    .max(5)
    .transform((currentValue, originalValue) => {
      return originalValue === '' ? null : currentValue;
    })
    .notRequired()
    .nullable()
    .default(undefined),

  academicYear: array(string().oneOf(academic_years)).notRequired().nullable().default(undefined),

  isLowIncome: boolean().notRequired().nullable().default(false),
  isCommunityService: boolean().notRequired().nullable().default(false),
  isFirstGen: boolean().notRequired().nullable().default(false),

  isEssayNeeded: boolean().notRequired().nullable().default(undefined),
  isLorNeeded: boolean().notRequired().nullable().default(undefined),
  isTranscriptNeeded: boolean().notRequired().nullable().default(undefined),

  studyRates: array(string().oneOf(studyRates)).notRequired().nullable().default(undefined),
  collegeTypes: array(string().oneOf(college_types)).notRequired().nullable().default(undefined),
  areasOfStudies: array(string().oneOf(allAreas)).notRequired().nullable().default(undefined),

  ethnicity: array(string().oneOf(ethnicities)).notRequired().nullable().default(undefined),
  religious: array(string().oneOf(religious)).notRequired().nullable().default(undefined),
  selfIdentification: array(string().oneOf(selfIdentification)).notRequired().nullable().default(undefined),
  veteranStatus: array(string().oneOf(veteran_status)).notRequired().nullable().default(undefined),
  athletics: array(string().oneOf(athletics)).notRequired().nullable().default(undefined),
  miscellaneous: array(string().oneOf(miscellaneous)).notRequired().nullable().default(undefined)
});

// yup magically creates TypeScript type definition here
export type ScholarshipListingFilterType = InferType<typeof ScholarshipListingFilterSchema>;
