import { ValidationError } from '../FormFieldText';

import Pattern from './Pattern';

function pad(num: number) {
  return ('00' + num).substr(-2);
}

function inRange(value: number, min: number, max: number, field: string) {
  if (isNaN(value) || value < min || value > max) {
    throw new ValidationError(`${field} cannot be ${value ? '"' + value + '"' : 'empty'}.`);
  }

  return value;
}

const birthDatePattern: Pattern = {
  placeholder: 'MM/DD/YYYY',

  correctPartial(value) {
    const corrected = value
      .replace(/ /g, '')
      .replace(/[^0-9/]/g, '/')
      .replace(/\/\//g, '/')
      .replace(/^\/*/, '');

    return corrected;
  },

  correctComplete(value) {
    value = value || '';

    let [mStr, dStr, yStr] = value.split('/');

    let [m, d, y] = [parseInt(mStr), parseInt(dStr), parseInt(yStr)];

    if (!isNaN(y)) {
      if (y < 25) {
        y = y + 2000;
      } else if (y < 100) {
        y = y + 1900;
      }
    }

    y = inRange(y, 1900, 2015, 'year');
    m = inRange(m, 1, 12, 'month');
    d = inRange(d, 1, 31, 'day');

    return [pad(m), pad(d), y].join('/');
  }
};

export default birthDatePattern;
