import { ScholarshipModelShape } from '@sharedComponents/models';

export function getScholarshiListingURL(scholarshipEntry: ScholarshipModelShape): string | undefined {
  if (scholarshipEntry.isDraft) {
    return undefined;
  }

  const { url_path } = scholarshipEntry;

  switch (process.env.REACT_APP_BUILD_ENV) {
    case 'stage':
      return `https://scholarsapp-stage.appspot.com/scholarship/${url_path}`;
    default:
      return `https://scholarsapp.com/scholarship/${url_path}`;
  }
}
