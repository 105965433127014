import React from 'react';
import { Link } from 'react-router-dom';

import formatDate from '@sharedComponents/utils/formatDate';
import Tooltip from '@sharedComponents/base/components/Tooltip';
import { AwardStatus } from '@sharedClients/types/ApplicationModel';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import './ApplicationList.scss';

interface ApplicationListProps {
  applications: ApplicationModel[];
  compact?: boolean;
  isLeads?: boolean;
  sameScholarship?: boolean;
  showName?: boolean;
  showAwardStatus?: boolean;
}

export const applicationListFields = [
  'id',
  'name',
  'submittedAt',
  'updatedAt',
  'school/school/name',
  'scholarship.name',
  'scholarship.id',
  'count.supports.submittedAt',
  'transcript.id'
];

function ApplicationList({
  applications,
  compact,
  isLeads,
  sameScholarship,
  showName = true,
  showAwardStatus = false
}: ApplicationListProps) {
  applications = applications || [];

  applicationListIds = applications.map(a => a.id);

  return (
    <div className="ApplicationList">
      {!compact ? (
        <ApplicationsListHeader
          isLeads={isLeads}
          sameScholarship={sameScholarship}
          showName={showName}
          showAwardStatus={showAwardStatus}
        />
      ) : null}
      {applications.map(application => {
        const hasTscpt = application.transcript;

        return (
          <Link to={`/applications/${application.id}`} key={application.id} className={'application selectable'}>
            {showName ? (
              <div className="name">
                {application.name || (application as any).data?.name || 'No name'}

                <div className="highSchool">
                  {application.school && application.school.school
                    ? application.school.school.name
                    : 'High school not specified'}
                </div>
              </div>
            ) : null}
            {!sameScholarship ? (
              <div className="scholarship">{application.scholarship && application.scholarship.name}</div>
            ) : null}
            {showAwardStatus ? (
              <div className="awardStatus" key="awardStatus">
                {formatAwardStatus(application.awardStatus)}
              </div>
            ) : null}
            {!compact
              ? [
                  <div className="submittedAt" key="submitted">
                    {isLeads
                      ? application.updatedAt
                        ? formatDate(application.updatedAt)
                        : null
                      : application.submittedAt
                      ? formatDate(application.submittedAt)
                      : null}

                    {application.submittedAt &&
                    application.updatedAt &&
                    isAfter(application.updatedAt, application.submittedAt) ? (
                      <div className="edited">edited {formatDate(application.updatedAt)}</div>
                    ) : null}
                  </div>,
                  <div className="supports" key="supports">
                    {repeat(
                      key => (
                        <Tooltip text="Letter of recommendation was submitted" key={key} className="icon">
                          LoR
                        </Tooltip>
                      ),
                      parseInt(application.supportCount)
                    )}
                    {hasTscpt ? (
                      <Tooltip text="Transcript was submitted." className="icon tooltip">
                        Tscp
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                ]
              : null}
          </Link>
        );
      })}

      {applications.length === 0 ? (
        <div className="application selectable">
          <div className="line2">No applications found.</div>
        </div>
      ) : (
        []
      )}
    </div>
  );
}

function ApplicationsListHeader({ isLeads, sameScholarship, showName, showAwardStatus }) {
  return (
    <div className="application header">
      {showName ? <div className="name">Applicant</div> : null}
      {!sameScholarship ? <div className="scholarship">Scholarship</div> : null}
      {showAwardStatus ? <div className="showAwardStatus">Status</div> : null}
      <div className="submittedAt">{isLeads ? 'Visited' : 'Submitted'}</div>
      <div className="supports">Support</div>
    </div>
  );
}

function repeat(factory: (key: number) => JSX.Element, times: number) {
  const res: JSX.Element[] = [];

  times = times || 0;

  for (let i = 0; i < times; i++) {
    res.push(factory(i));
  }

  return res;
}

function isAfter(date: string | Date, afterDate: string | Date) {
  return new Date(date).getTime() > new Date(afterDate).getTime() + 60 * 1000;
}

function formatAwardStatus(status?: AwardStatus) {
  return (
    {
      awardNotified: 'Notified',
      awardRejected: 'Rejected',
      awardAccepted: 'Accepted'
    }[status || ''] || ''
  );
}

export let applicationListIds: number[] = [];

export default ApplicationList;
