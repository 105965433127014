export const OFFLINE_CAUSE = 'offline';
export const PROXY_CAUSE = 'proxy';
// TODO:IMP: Such high level abstraction over requests is not always a good thing. There are many components which are now handling their own timeouts and loaders separately, so it causes troubles. This has to be reworked or improved in order to omit in some cases
const timeoutInMs = 20000;

export function wrapFetch(fetch) {
  const timeoutFetch = addTimeout(fetch);

  return (...args) => timeoutFetch(...args).catch(handleFetchException);
}

function addTimeout(fetch) {
  return (...args) =>
    Promise.race([
      fetch(...args),
      new Promise((resolve, reject) =>
        setTimeout(() => {
          const e = new Error('Timeout contacting server; you may be offline.');
          e.isUserError = true;
          e.cause = OFFLINE_CAUSE;
          e.result = OFFLINE_CAUSE;

          reject(e);
        }, timeoutInMs)
      )
    ]);
}

const offlineMessages = [
  'Could not connect',
  'Timeout',
  'cancelled',
  'cancelado',
  'NetworkError',
  'connection was lost',
  'red se perdió',
  'reset by peer',
  'request timed out',
  'connection appears to be offline'
];

const proxyMessages = [
  'Preflight response',
  'not allowed by Access-Control',
  'Failed to fetch',
  'certificate for this server',
  'SSL error'
];

function handleFetchException(e) {
  const msg = e.message || '';
  const hasProxy = !!proxyMessages.find(proxyMessage => msg.includes(proxyMessage));

  if (!hasProxy) {
    const throwE = new Error('Could not connect to server: ' + msg);

    const isOffline = !!offlineMessages.find(offlineMessage => msg.includes(offlineMessage));

    throwE.isUserError = isOffline;

    throwE.cause = OFFLINE_CAUSE;
    throwE.fingerprint = throwE.message;

    throw throwE;
  } else {
    const throwE = new Error(
      `Could not reach the server. A firewall or proxy might be blocking the request. ("${msg}")`
    );
    throwE.cause = PROXY_CAUSE;
    throwE.fingerprint = PROXY_CAUSE;

    throw throwE;
  }
}
