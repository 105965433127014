import { useEffect } from 'react';

const useBeforeLeave = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();

      /**
       * some browsers require the event handler to implement one of two legacy methods:
       * assigning a string to the event's returnValue property
       * returning a string from the event handler.
       */
      return (event.returnValue = message);
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }

    return () => {
      return window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    };
  }, [when, message]);
};

export default useBeforeLeave;
