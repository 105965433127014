import { number, object } from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui';
import FileField from '@sharedComponents/formElements/combined/FileField';
import { getServiceUrl } from '@sharedClients/getServiceUrl';

const StandaloneApplicationsCSV = ({ scholarshipID }) => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          Upload a .CSV file with applications data. First row is supposed to contain fields identificators.
        </EuiText>
        <EuiText>
          You can download example CSV which will contain all fields identifiers based on current scholarship
          application form:{' '}
          <EuiLink
            target="_blank"
            href={`${getServiceUrl(
              'applications'
            )}/v2/applications/standalone-csv/${scholarshipID}/standalone-applications.csv`}
          >
            Standalone applications upload template
          </EuiLink>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <FileField
          fieldName={'applications_file_id'}
          rowProps={{
            label: 'Applications spreadsheet',
            helpText: '',
            fullWidth: true
          }}
          fieldProps={{
            accept: '.csv',
            forcedMimeType: 'csv',
            isTempFile: true
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

StandaloneApplicationsCSV.validationRules = object({
  applications_file_id: number().required().label('CSV file').typeError('CSV file is required')
});

export default StandaloneApplicationsCSV;
