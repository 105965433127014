import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { object } from 'yup';

import CollegeEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/CollegeEligibilitySelection';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep11 = ({ nextStepHandler }) => {
  const { watch } = useFormContext();
  const collegeMatching = watch('collegeMatching');

  const [isSelectionShown, setIsSelectionShown] = useState(collegeMatching !== 'Any');

  const choiceOptions = (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton
              color={'text'}
              fullWidth={false}
              onClick={() => {
                setIsSelectionShown(true);
              }}
            >
              YES
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NO
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NOT SURE
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>Does the student need to attend a specific college?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        {isSelectionShown ? (
          <>
            <CollegeEligibilitySelection />
          </>
        ) : (
          choiceOptions
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep11.validationRules = object().shape({
  data: ScholarshipModelDataSchema.pick(['colleges', 'collegeStates', 'collegeCounties'])
});

export default SelfListingScholarshipStep11;
