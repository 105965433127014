module.exports = [
  'African American',
  'Alaska Native',
  'Asian',
  'Arab',
  'Atheist',
  'Athlete',
  'Buddhist',
  'Caucasian',
  'Catholic',
  'Chinese',
  'Chronically ill',
  'Chronically ill relative',
  'Disabled person',
  'Eastern Orthodox',
  'Evangelical',
  'Filipino',
  'Foster care / Orphan / Adopted',
  'German',
  'Hispanic',
  'Irish',
  'Italian',
  'Italian American',
  'Jewish',
  'LGBT',
  'Mexican',
  'Mormon',
  'Muslim',
  'Native American',
  'Native Hawaiian',
  'Non-traditional student',
  'Pacific Islander',
  'Portuguese',
  'Protestant',
  'Puerto Rican',
  'Single parent',
  'South Asian',
  'Veteran',
  'Relative of veteran'
];
