import { useState, useEffect, useRef, SyntheticEvent } from 'react';

/**
 * @description Hook wrapper for submit events, prevents multitriggering from submit events
 */
export default function useSingleSubmit(): [(Function) => (event: SyntheticEvent<any>) => void, boolean] {
  const [isSubmitting, setSubmitting] = useState(false);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const singleSubmit = (func: (e: SyntheticEvent<any>) => void) => {
    return async (e: SyntheticEvent<any>) => {
      e.preventDefault();

      if (!isSubmitting) {
        setSubmitting(true);

        try {
          return await func(e);
        } finally {
          if (mounted.current) {
            setSubmitting(false);
          }
        }
      }
    };
  };

  return [singleSubmit, isSubmitting];
}
