import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';

import AbstractEntryStep from './AbstractEntryStep';
import ScholarshipPublicationStatus from './EntryStepPublish/ScholarshipPublicationStatus';

const EntryStepPublish: AbstractEntryStep = {
  validationSchema: yup.object().shape({}),

  render: function (_rowProps, { getValues }, scholarshipClient) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Publication</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <ScholarshipPublicationStatus
            scholarshipClient={scholarshipClient}
            scholarshipData={getValues(['id', 'name', 'scholarship_id'])}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepPublish;
