export default function formatDate(
  date: any,
  options: {
    showTime?: boolean;
  } = {
    showTime: false
  }
) {
  if (!date) {
    return '';
  }

  if (typeof date == 'string') {
    date = new Date(date);
  } else {
    date = date as Date;
  }

  /** This issue is that if we store just a date (no time) in the DB, we store it as midnight UTC,
   * which is the day before in our timezone. As a workaround, interpret dates that are at midnight UTC
   * as UTC, and others as local timezone */
  let isUTC = date.getUTCHours() === 0 && date.getUTCMinutes() === 0 && date.getUTCSeconds() === 0;

  const [y, m, d] = isUTC
    ? [date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()]
    : [date.getFullYear(), date.getMonth(), date.getDate()];

  return `${pad(m + 1)}/${pad(d)}${'/' + (y < 2000 || y > 2030 ? y : pad(y % 100))}${
    options.showTime ? ' ' + formatTime(date) : ''
  }`;
}

function formatTime(date: Date) {
  const isInTest = typeof describe !== 'undefined';

  let h, m;

  if (isInTest) {
    h = date.getUTCHours();
    m = date.getUTCMinutes();
  } else {
    h = date.getHours();
    m = date.getMinutes();
  }

  return `${pad(h)}:${pad(m)}`;
}

function pad(number: number) {
  return ('00' + number).substr(-2);
}

// Used for validation only. TODO:IMP this
export function parseDate(
  string: string,
  options: {
    today?: Date;
    isFutureImplied?: boolean;
    nullOnError?: boolean;
  } = {}
) {
  if (string == null) {
    return null;
  }

  function error() {
    if (!options.nullOnError) {
      throw new InvalidDateFormat();
    } else {
      return null;
    }
  }

  let components = string.split('/');

  if (components.length !== 2 && components.length !== 3) {
    return error();
  }

  let componentNumbers = components.map(s => parseInt(s));

  if (componentNumbers.find(n => isNaN(n))) {
    return error();
  }

  let month = componentNumbers[0];
  let day = componentNumbers[1];
  let year = componentNumbers[2];

  if (year == null) {
    year = getImpliedYear(month - 1, day, options);
  } else if (year < 100) {
    year = 2000 + year;
  }

  if (year < 2000 || year > 2100) {
    return error();
  }

  return new Date(Date.UTC(year, month - 1, day));
}

/** If we don't mention the year, which year are we talking about?
 * This is adapted to scholarship deadlines. if it's September
 * and you enter 1st of Janary that means you're talking about
 * next year.
 * @param month first month is 0
 */
function getImpliedYear(month: number, day: number, options: { isFutureImplied?: boolean; today?: Date } = {}) {
  let { today, isFutureImplied } = options;

  today = today || new Date();

  let impliedYear = today.getUTCFullYear();

  if (isFutureImplied) {
    if (month < today.getMonth() || (month === today.getMonth() && day < today.getDate())) {
      impliedYear++;
    }
  } else {
    if (month > today.getMonth() || (month === today.getMonth() && day > today.getDate())) {
      impliedYear--;
    }
  }

  return impliedYear;
}

export class InvalidDateFormat extends Error {}

export function isAfter(date: string | Date, afterDate: string | Date) {
  return new Date(date).getTime() > new Date(afterDate).getTime() + 60 * 1000;
}
