import { Autocomplete, Option } from '../../base/Autocomplete';
import warn from '../../base/warn';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { toNumber } from '../../model/cast';
import FormNode, { isMissing } from '../../model/FormNode';
import React from 'react';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

/**
 * ! TODO: this field behavior loses part of school data which causes issues - T3968
 * Its now dirty-pached on backend, but this field eventually has to be sorted out
 */
export default function FormFieldAutocomplete(props: {
  node: FormNode;
  application: ApplicationModel;
  onFormError: (e: Error) => void;
  onFieldChange: (value: Option[] | Option | null, nodes: FormNode[] | FormNode) => {};
  applicationClient: ApplicationClient;
  animationTime?: number;
  searchDelay?: number;
}) {
  const { node, onFieldChange, application, onFormError } = props;

  const onChange = (option: Option | null) => {
    if (option) {
      const values: Option[] = [];
      const nodes: FormNode[] = [];

      values.push({
        id: option.id,
        name: option.name + (option.state ? ', ' + option.state : '')
      });
      nodes.push(node);

      Object.keys(option)
        .filter(key => key !== 'name')
        .forEach(key => {
          const sibling = node.parent && node.parent.getField(key);

          if (sibling && isMissing(sibling.getValue(application))) {
            values.push(option[key]);
            nodes.push(sibling);
          }
        });

      onFieldChange(values, nodes);
    } else {
      onFieldChange(null, node);
    }
  };

  return (
    <Autocomplete
      {...props}
      id={node.getInputElementId()}
      onChange={onChange}
      autocomplete={node.autocomplete as string}
      value={validateValueType(node.getValue(application), node)}
      width={node.width ? toNumber(node.width, onFormError) : undefined}
    />
  );
}

function validateValueType(value: any, node: FormNode): { id: number; name: string } {
  if (value != null && typeof value != 'object') {
    warn(`Expected object value of field ${node.field}`);

    if (typeof value == 'string') {
      value = { name: value };
    }
  }

  return value;
}
