import React from 'react';
import { useState, useEffect } from 'react';
import './DisappearingBanner.scss';

/**
 * This banner animates in from the top of the page when the applicant goes to the next page
 * without filling in some required fields.
 */
export default function DisappearingBanner(props) {
  const { onHide } = props;

  const [animationClass, setAnimationClass] = useState('');
  const animationTime = props.animationTime || 400;

  let abortTimer;

  function soon(ms) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(resolve, ms || 0);

      abortTimer = () => {
        clearTimeout(timer);
        reject(new Error('abort'));
      };
    });
  }

  useEffect(() => {
    soon()
      .then(async () => {
        setAnimationClass('show');

        await soon(10);

        setAnimationClass('show animate');

        await soon(5000);

        setAnimationClass('hide');

        await soon();

        setAnimationClass('hide animate');

        await soon(animationTime);

        setAnimationClass('hidden');

        onHide && onHide();
      })
      .catch(e => {
        if (e.message !== 'abort') {
          throw e;
        }
      });

    return () => abortTimer && abortTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={'DisappearingBanner ' + animationClass}>{props.children}</div>;
}
