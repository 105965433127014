import React from 'react';
import moment from 'moment';
import { Controller, useFormContext, FieldError } from 'react-hook-form';

import { EuiDatePicker, EuiDatePickerProps } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function DatePickerField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<EuiDatePickerProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const dateError = getValueByDottedPath<FieldError>(fieldName, errors);

  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!dateError}
      error={dateError?.message || ''}
      render={({ onChange, onBlur, value, name, ref }) => {
        return (
          <EuiDatePicker
            name={name}
            selected={value ? moment(value) : undefined}
            isInvalid={!!dateError}
            onBlur={onBlur}
            {...fieldProps} // before onChange to make sure its handled by controller
            onChange={onChange}
            autoComplete="off"
            utcOffset={0}
            ref={ref}
          />
        );
      }}
    />
  );

  const isInvalid = !!dateError?.message || false;
  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={dateError?.message || ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
