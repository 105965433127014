import React from 'react';

import { EuiPage, EuiPageBody, EuiPageContent, EuiPageHeader } from '@elastic/eui';
import UserModel from '@sharedContract/UserModel';
import LayoutHeader from './DonorPageLayout/LayoutHeader';

/**
 * Overall layout approach for donors service
 * TODO all the params and donor free dashboard header, login header, etc.
 */
export default function DonorPageLayout({
  onLogout,
  children
}: {
  user: UserModel;
  onLogout: () => void;
  children: React.ReactChild; // actually content
}) {
  return (
    <>
      <EuiPageHeader>
        <LayoutHeader onLogout={onLogout} />
      </EuiPageHeader>
      <EuiPage direction="column" paddingSize="none" grow>
        <EuiPageBody>
          <EuiPageContent hasShadow={false} paddingSize="none">
            {children}
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
}
