import React from 'react';

import { ApplicantNamePlugin, ApplicantNameRenderer, getApplicantNameParser } from '@donors/utils/markdownPlugins';
import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  getDefaultEuiMarkdownParsingPlugins,
  getDefaultEuiMarkdownProcessingPlugins,
  getDefaultEuiMarkdownUiPlugins,
  htmlIdGenerator
} from '@elastic/eui';

import AbstractDeclineStep from './AbstractDeclineStep';
import { NOTIFY_DECLINED_STEPS } from './config';

const NotifyDeclinedStepEmailDrafting: AbstractDeclineStep = {
  headerTitle: 'Draft Decline Email',
  actions(changeStep) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.CONFIRM_RECIPIENTS)}>Back</EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.EMAIL_PREVIEW)} fill>
          Next
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ emailDraft, setEmailDraft, emailSubject, setEmailSubject, affectedStudentExample }) {
    const parsingList = getDefaultEuiMarkdownParsingPlugins();

    parsingList.push(getApplicantNameParser(affectedStudentExample?.data.name || 'Applicant Full Name'));

    const processingList = getDefaultEuiMarkdownProcessingPlugins();

    // add the renderer for `applicantNamePlugin`
    processingList[1][1].components.applicantNamePlugin = ApplicantNameRenderer;

    const uiPlugins = getDefaultEuiMarkdownUiPlugins();

    uiPlugins.push(ApplicantNamePlugin);

    return (
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem>
          Write an email that will be sent to all of the candidates that did not receive the scholarship.
          <br />
          Click on the "Name" fields to automatically populate the name specific to each candidate in the email.
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFieldText
            value={emailSubject}
            onChange={e => setEmailSubject(e.target.value)}
            placeholder="Email subject"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiMarkdownEditor
            className="emailDrafting-editor"
            aria-label="Email draft example"
            value={emailDraft}
            onChange={setEmailDraft}
            height={300}
            uiPlugins={uiPlugins}
            parsingPluginList={parsingList}
            processingPluginList={processingList}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default NotifyDeclinedStepEmailDrafting;
