import { ServerError } from './ServerError';

export class UnauthorizedError extends ServerError {
  constructor(error) {
    super(error);

    this.isUnauthorized = true;

    this.user = error.user;
  }
}
