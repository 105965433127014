/* eslint-disable react-hooks/rules-of-hooks */
import React, { FunctionComponent, PropsWithChildren, useRef, useEffect, useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import './Users.scss';

import { HeaderProps } from '../components/Header';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { getServiceUrl } from '@sharedClients/getServiceUrl';
import FloatingActionButton from '@sharedComponents/common/FloatingActionButton';
import UserModel from '@sharedContract/UserModel';
import Debouncer from '@donors/components/Debouncer';
import Search from '@sharedComponents/common/SearchDropdown';
import AdvancedUserList, { DEFAULT_PAGE_SIZE } from '@sharedComponents/common/AdvancedUserList';
import { USER_TYPE } from '@sharedComponents/constants';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';
import { UserListingFilterSchema } from '@sharedComponents/schemas/UserModelSchema';

interface UsersProps extends RouteComponentProps<{}>, HeaderProps {
  type?: string;
  jwt: string;
}

const componentReceivingRouter: FunctionComponent<PropsWithChildren<UsersProps>> = props => {
  let { jwt, history, type = 'donor', user } = props;
  const userClient = useUserClient();

  const [onError, errorMessage] = useErrorReporter();
  const [users, setUsers] = useState<UserModel[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [textFilter, setTextFilter] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);

  const debouncer = useRef(
    new Debouncer<
      {
        type: string;
        textFilter: string;
        page: number;
        onError: (e: Error) => void;
      },
      {
        results: UserModel[];
        total: number;
      }
    >(
      res => {
        setLoading(true);
        return userClient.getUserListing(
          res.type,
          { ...UserListingFilterSchema.getDefault(), name: res.textFilter },
          res.page,
          DEFAULT_PAGE_SIZE
        );
      },
      response => {
        setUsers(response.results);
        setTotal(response.total);
        setLoading(false);
      },
      onError
    )
  );

  type = type || 'donor';

  if (type[type.length - 1] === 's') {
    type = type.substr(0, type.length - 1);
  }

  useEffect(() => {
    debouncer.current.load({ type, textFilter, page, onError }, { immediate: true });
  }, [userClient, type, page]);

  useEffect(() => {
    document.title = `Scholar's App - Users`;
  }, []);

  useEffect(() => {
    debouncer.current.load({ type, textFilter, page, onError }, { immediate: true });
  }, [textFilter]);

  useEffect(() => {
    setPage(0);
  }, [type]);

  const create = () => {
    history.push('/users/add/' + type);
  };

  const canCreate =
    (user.type === 'admin' &&
      (type === 'donor' || type === 'reviewer' || type === 'dataEntry' || type === 'counselor')) ||
    (user.type === 'donor' && type === 'reviewer');

  const searchExecutor = function (value): Promise<null> {
    return new Promise(resolve => {
      setTextFilter(value);
      resolve(null);
    });
  };

  return (
    <div className="Users">
      <div className="body">
        <div className="content">
          {errorMessage}

          {canCreate ? <FloatingActionButton onClick={create} tooltip="New User" /> : null}

          <TypeSelector current={type} user={user} reset={() => setTextFilter('')} />

          <EuiFlexGroup alignItems="flexEnd" direction="column">
            <EuiFlexItem grow={null} style={{ minWidth: '300px' }}>
              <Search
                value={textFilter}
                placeholder=""
                searchExecutor={searchExecutor}
                onValueReset={() => {
                  setTextFilter('');
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          {user.type === 'admin' && (
            <div className="download-container">
              <button className="button invisible link" onClick={() => download(type, jwt)}>
                Download
              </button>
            </div>
          )}

          <AdvancedUserList
            users={users}
            type={type as USER_TYPE}
            total={total}
            onPageChange={pageIndex => setPage(pageIndex)}
            currentPage={page}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

function TypeSelector({ current, user, reset }: { current: string; user: UserModel; reset: any }) {
  const types =
    user && user.type === 'admin'
      ? [
          ['applicant', 'Applicants'],
          ['professional', 'Professionals'],
          ['parent', 'Parents'],
          ['counselor', 'Counselors'],
          ['recommender', 'Recommenders'],
          ['donor', 'Donors'],
          ['reviewer', 'Reviewers'],
          ['admin', 'Admins'],
          ['dataEntry', 'Data Entry']
        ]
      : [
          ['counselor', 'Counselors'],
          ['donor', 'Donors']
        ].concat(user.donor?.canReview ? [['reviewer', 'Reviewers']] : []);

  return (
    <div className="TypeSelector">
      {types.map(([type, name]) =>
        type === current ? (
          <div key={type} className="current">
            {name}
          </div>
        ) : (
          <Link key={type} to={'/users/' + type} onClick={reset}>
            {name}
          </Link>
        )
      )}
    </div>
  );
}

function download(type: string, jwt: string) {
  post(`${getServiceUrl('applications')}/download-users/${type}s.tsv`, { jwt });
}

// ??
export function post(path: string, params: { [key: string]: string }, method = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

const Users = withRouter(componentReceivingRouter);

export default Users;
