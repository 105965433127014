import './LoginSignupForm.scss';

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiForm, EuiText } from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import track from '@sharedComponents/base/track';
import { DEFAULT_ERROR_TITLE, USER_TYPE } from '@sharedComponents/constants';
import useLoading from '@sharedComponents/hooks/useLoading';
import { ExtraSignupData } from '@sharedComponents/interfaces/UserData.interface';
import { UserModelShape } from '@sharedComponents/models';

import { LoginFormProps, SIGNUP_STEP, SignupStepProps } from './LoginSignupForm/config';
import aboutYouStep from './LoginSignupForm/formSteps/AboutYouStep';
import loginStartStep from './LoginSignupForm/formSteps/LoginStartStep';
import newUserRegistrationStep from './LoginSignupForm/formSteps/NewUserRegistrationStep';
import parentConfirmationStep from './LoginSignupForm/formSteps/ParentConfirmationStep';
import studentInvitationsStep from './LoginSignupForm/formSteps/StudentInvitationsStep';
import addressOfResidencyStep from './LoginSignupForm/formSteps/AddressOfResidencyStep';
import donorOrganizationStep from './LoginSignupForm/formSteps/DonorOrganizationStep';
import LoginSignupFormLayout from '../../layout/LoginSignupFormLayout';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import PasswordResetModal from '@sharedComponents/modals/PasswordResetModal';
import useToast from '@sharedComponents/hooks/useToast';

const SIGNUP_STEPS = {
  [SIGNUP_STEP.LOGIN_START]: loginStartStep,
  [SIGNUP_STEP.NEW_USER_REGISTRATION]: newUserRegistrationStep,
  [SIGNUP_STEP.ABOUT_YOU]: aboutYouStep,
  [SIGNUP_STEP.ADDRESS_OF_RESIDENCY]: addressOfResidencyStep,
  [SIGNUP_STEP.PARENTS_CONFIRMATION]: parentConfirmationStep,
  [SIGNUP_STEP.STUDENT_INVITATIONS]: studentInvitationsStep,
  [SIGNUP_STEP.DONOR_ORGANIZATION]: donorOrganizationStep
};

const trackConversion = async () => {
  // LinkedIn
  if (window.lintrk) {
    await window.lintrk('track', { conversion_id: 4792610 });
  }

  // Google
  if (window.gtag) {
    await new Promise(res => {
      window.gtag('event', 'conversion', {
        send_to: 'AW-443311813/6qwaCNTp-_UCEMXNsdMB',
        event_callback: res
      });
    });
  }

  // Facebook
  if (window.fbq) {
    await window.fbq('trackCustom', 'DonorRegistration');
  }
};

/**
 * The login/signup widget when starting the application process. State machine moving between "steps".
 */
function LoginSignupForm(props: LoginFormProps & { step?: SIGNUP_STEP }) {
  const { userClient, onLogin, help, applicationClient, noHeader } = props;

  const { addToast } = useToast();
  const createModal = useModalCreate();

  const urlParams = new URLSearchParams(window.location.search);
  const isRegistration = urlParams.get('register');

  const [required2FA, setRequired2FA] = useState(false);

  const [userType, setUserType] = useState<USER_TYPE>(
    props.userType && Object.values(USER_TYPE).includes(props.userType) ? props.userType : USER_TYPE.APPLICANT
  );

  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const [step, setStep] = useState<SIGNUP_STEP>(
    props.step || (isRegistration ? SIGNUP_STEP.NEW_USER_REGISTRATION : SIGNUP_STEP.LOGIN_START)
  );

  const currentStepObject = SIGNUP_STEPS[step];

  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      currentStepObject.validationSchema && userType ? currentStepObject.validationSchema(userType) : {}
    ),
    shouldUnregister: false // to collect data between steps
  });

  const loadingStatus = useLoading();

  // ? move into recoil?
  const completeLoginHandler = async ({
    email,
    password,
    code
  }: {
    email: string;
    password: string;
    code?: string;
  }) => {
    // we are not try-catching here, since error is being caught by handler which executes that action
    if (required2FA && !code) {
      return;
    }

    const loginRes = await userClient.login(email, password, code);

    if (loginRes.result == '2fa-needed') {
      setRequired2FA(true);
    } else {
      onLogin({ jwt: loginRes.jwt, user: loginRes.user, loginOptions: loginRes.loginOptions });
    }
  };

  const completeSignupHandler = async (extras?: ExtraSignupData) => {
    const newUserData: Partial<UserModelShape> = formMethods.getValues();

    try {
      // ? that object structure should be revised
      const user = {
        ...newUserData,
        type: userType,
        skipWelcomeEmail: false,
        confirmedEmailToken: props.token || undefined,
        invitations: extras?.invitations || []
      };

      const { jwt, id, loginOptions = {} } = await userClient.createUser(user);

      if (userType == USER_TYPE.DONOR) {
        await trackConversion();
      }

      // ? is that hook used?
      onLogin({
        jwt,
        user: {
          ...user,
          id
        },
        loginOptions
      });
    } catch (e: any) {
      setErrorMessage(e.message);
    }

    track('signup-completed');
  };

  const changeStepHandler = step => {
    // clean errors between steps if there are any (for those errors which were set with formMethods.setError)
    if (formMethods.errors && Object.values(formMethods.errors).length > 0) {
      formMethods.clearErrors();
    }

    // ? TODO reset form state between step changes to not trigger errors on next step?

    setStep(step);
    loadingStatus.stopLoading();
  };

  const showPasswordResetModal = () => {
    createModal(({ closeModal }) => (
      <PasswordResetModal closeModal={closeModal} userClient={userClient} addToast={addToast} /> // passing addToast method to the component because it doesn't have the access to the latest context
    ));
  };

  const stepProps: SignupStepProps = {
    userType,
    setUserType,
    completeSignup: completeSignupHandler,
    completeLogin: completeLoginHandler,
    required2FA: required2FA,
    applicationClient,
    userClient,
    formMethods,
    changeStep: changeStepHandler,
    loadingStatus,
    showPasswordResetModal
  };

  useEffect(() => {
    if (props.email && step === SIGNUP_STEP.LOGIN_START) {
      changeStepHandler(SIGNUP_STEP.NEW_USER_REGISTRATION);
      formMethods.setValue('name', props.email);
    }

    track('signup-start');
  }, [props.email]);

  const wrapIntoAnimation = content => (
    <SwitchTransition mode={'out-in'}>
      <CSSTransition
        key={`step_${step}`}
        addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
        classNames="transition-fade"
      >
        {content}
      </CSSTransition>
    </SwitchTransition>
  );

  const helpText =
    help && step === SIGNUP_STEP.LOGIN_START ? (
      <EuiFlexItem grow={false}>
        <EuiText size="s" className="LoginSignupForm-helpText" grow={false} textAlign="left">
          <h3>{help}</h3>
        </EuiText>
      </EuiFlexItem>
    ) : null;
  return (
    <LoginSignupFormLayout
      currentStep={step}
      onReset={() => {
        setStep(SIGNUP_STEP.LOGIN_START);
      }}
      userType={userType}
      setUserType={setUserType}
      noHeader={noHeader}
    >
      {errorMessage ? (
        <EuiFlexGroup direction="column" responsive={false}>
          <EuiFlexItem>
            <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert" style={{ marginTop: '10px' }}>
              {errorMessage}
            </EuiCallOut>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              onClick={() => {
                setStep(SIGNUP_STEP.NEW_USER_REGISTRATION);
                setErrorMessage('');
              }}
            >
              Return
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <FormProvider {...formMethods}>
          <EuiForm component="form" className={'LoginSignupForm'}>
            <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
              <>
                <EuiFlexItem grow={true}>{wrapIntoAnimation(currentStepObject.render(stepProps))}</EuiFlexItem>
                {helpText}
                <EuiFlexItem grow={false}>{wrapIntoAnimation(currentStepObject.renderControls(stepProps))}</EuiFlexItem>
              </>
            </EuiFlexGroup>
          </EuiForm>
        </FormProvider>
      )}
    </LoginSignupFormLayout>
  );
}

export default LoginSignupForm;
