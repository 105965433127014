import warn from '../base/warn';

export type Fetch = (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

export function wrapToCatch401s(fetch: Fetch, onUnauthorized: () => void): Fetch {
  return async (input, init?) => {
    const res = await fetch(input, init);

    if (res.status === 401) {
      if (await isInvalidJwtError(res)) {
        onUnauthorized();
      }
    }

    return res;
  };
}

export async function isInvalidJwtError(res: Response) {
  try {
    const json = await res.json();

    // the body cannot be read twice.
    // to allow downstream clients to access it anyway,
    // we write it back.
    res.json = async () => json;

    return json.cause === 'invalid-jwt' || json.cause === 'expired-jwt';
  } catch (e) {
    warn(`Could not determine if 401 was invalid JWT: ${e}`);

    return false;
  }
}
