/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import getLocalStorage, { Storage, inMemoryStorage, noStorage } from './getLocalStorage';

const isInTest = process.env.NODE_ENV === 'test';

export class LocalStorageCache<S> {
  constructor(private cacheKey: string, private storage: Storage) {}

  get(defaultValue: S | (() => S)): S {
    const result = this.storage.getItem(this.cacheKey);

    if (result != null) {
      return result as any;
    } else if (typeof defaultValue === 'function') {
      return (defaultValue as () => S)();
    } else {
      return defaultValue;
    }
  }

  put(value: S) {
    this.storage.setItem(this.cacheKey, value);
  }
}

export function getInMemoryStorage() {
  return !isInTest ? inMemoryStorage : noStorage;
}

export function useMemoryCachedState<S>(
  cacheKey: string,
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  return useCachedState(cacheKey, initialState, getInMemoryStorage());
}

/**
 * @deprecated
 */
export default function useCachedState<S>(
  cacheKey: string,
  initialState: S | (() => S),
  storage?: Storage
): [S, Dispatch<SetStateAction<S>>] {
  const actualStorage = storage || getLocalStorage();

  const cache = new LocalStorageCache<S>(cacheKey, actualStorage);

  let [value, setUncachedValue] = useState<S>(initialState);

  useEffect(() => {
    value = new LocalStorageCache<S>(cacheKey, actualStorage).get(initialState);

    setUncachedValue(value);
  }, [cacheKey]);

  const setValue: Dispatch<SetStateAction<S>> = (value: SetStateAction<S>) => {
    let s: S;

    if (typeof value === 'function') {
      s = (value as () => S)();
    } else {
      s = value as S;
    }

    const result = setUncachedValue(s);

    cache.put(s);

    return result;
  };

  return [value, setValue];
}
