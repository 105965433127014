import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';

/**
 * Steps with '_' are traited as sub-steps and not rendered in stepper. This has to be more obvious
 */
export const SCHOLARSHIP_ENTRY_STEPS_NAMES = {
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH]: 'Research',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND]: 'Research',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND]: 'Research',
  [SCHOLARSHIP_ENTRY_STEPS.CONTACT]: 'Contact',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY]: 'Eligibility',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_SECOND]: 'Eligibility',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_THIRD]: 'Eligibility',
  [SCHOLARSHIP_ENTRY_STEPS.VERIFICATION]: 'Verification',
  [SCHOLARSHIP_ENTRY_STEPS.QA]: 'QA',
  [SCHOLARSHIP_ENTRY_STEPS.QA_SECOND]: 'QA',
  [SCHOLARSHIP_ENTRY_STEPS.PUBLISH]: 'Publish'
};
