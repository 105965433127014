import { max, min } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import {
  Comparators,
  Direction,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiTitle,
  formatDate,
  Pagination,
  SortDirection
} from '@elastic/eui';
import { MailingConversion } from '@sharedComponents/interfaces/Mailing.interface';

function _takeMatched(conversions, pageIndex, pageSize, sortField, sortDirection) {
  let items;

  if (sortField) {
    items = conversions.slice(0).sort(Comparators.property(sortField, Comparators.default(sortDirection)));
  } else {
    items = conversions;
  }

  let pageOfItems;

  if (!pageIndex && !pageSize) {
    pageOfItems = items;
  } else {
    const startIndex = pageIndex * pageSize;
    pageOfItems = items.slice(startIndex, Math.min(startIndex + pageSize, items.length));
  }

  return {
    pageOfItems,
    totalItemCount: items.length
  };
}

const ConversionReport = ({ conversions }: { conversions: MailingConversion[] }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [sortField, setSortField] = useState('firstName');
  const [sortDirection, setSortDirection] = useState<Direction>(SortDirection.ASC);

  const onTableChange = ({ page = {}, sort = {} }) => {
    const { index: pageIndex, size: pageSize } = page as any;

    const { field: sortField, direction: sortDirection } = sort as any;

    setPageIndex(pageIndex);
    setPageSize(pageSize);
    setSortField(sortField);
    setSortDirection(sortDirection);
  };

  const { pageOfItems, totalItemCount } = _takeMatched(conversions, pageIndex, pageSize, sortField, sortDirection);

  const columns = [
    {
      field: 'recipient',
      name: 'Recipient',
      sortable: true
    },
    {
      field: 'state',
      name: 'State',
      sortable: true
    },
    {
      field: 'city',
      name: 'City',
      sortable: true
    },
    {
      field: 'visits',
      name: 'Visits Count',
      sortable: true
    },
    {
      id: 'first_visit',
      name: 'First Visit',
      render: conversion => {
        const { timestamps = [] } = conversion;
        const first_visit = min<number>(timestamps);
        return first_visit ? formatDate(moment(first_visit), 'dobLong') : '-';
      }
    },
    {
      id: 'last_visit',
      name: 'Last Visit',
      render: conversion => {
        const { timestamps = [] } = conversion;
        const last_visit = max<number>(timestamps);
        return last_visit ? formatDate(moment(last_visit), 'dobLong') : '-';
      }
    }
  ];

  const pagination: Pagination = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    totalItemCount: totalItemCount,
    pageSizeOptions: [5, 15, 25]
  };

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection
    },
    enableAllColumns: true
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiTitle size="xs">
          <h4>Conversion report:</h4>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPanel paddingSize="none">
          <EuiBasicTable
            items={pageOfItems}
            columns={columns}
            pagination={pagination}
            sorting={sorting}
            onChange={onTableChange}
          />
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default ConversionReport;
