import moment from 'moment';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { MailingModelShape } from '@sharedComponents/models';

import getMailingPricing from './util/getMailingPricing';
import { HTMLTemplatePagesCount } from '@sharedComponents/interfaces/Mailing.interface';

const SummaryPage = () => {
  const formMethods = useFormContext();

  const formData: Partial<MailingModelShape> = formMethods.getValues();

  const pagesSize = formData.isHTMLtemplateUsed ? HTMLTemplatePagesCount : formData.pdf_page_size;
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText size="m">
          <strong>Confirm your mailing:</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Mailing Title:</EuiFlexItem>
              <EuiFlexItem grow={2}>{formData.description}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Total Recipients:</EuiFlexItem>
              <EuiFlexItem grow={2}>{formData.recipients?.length}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Template type:</EuiFlexItem>
              <EuiFlexItem grow={2}>{formData.isHTMLtemplateUsed ? 'HTML' : 'PDF'}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Total Pages:</EuiFlexItem>
              <EuiFlexItem grow={2}>{pagesSize}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Mailing Date:</EuiFlexItem>
              <EuiFlexItem grow={2}>
                {formData.send_date ? moment.utc(formData.send_date)?.format('YYYY-MM-DD') : 'Immediately'}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Black and White Print:</EuiFlexItem>
              <EuiFlexItem grow={2}>{!formData.color ? 'Yes' : 'No'}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Double-sided:</EuiFlexItem>
              <EuiFlexItem grow={2}>{formData.double_sided ? 'Yes' : 'No'}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row">
              <EuiFlexItem>Mail Service</EuiFlexItem>
              <EuiFlexItem grow={2}>
                {formData.mail_type === 'usps_first_class' ? 'USPS First Class' : 'USPS Standard'}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row" gutterSize="xs">
          <EuiFlexItem>{/** spacer */}</EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText size="m">
              <strong>Total price:</strong>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{getMailingPricing(formData)}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SummaryPage.validationRules = yup.object().shape({});

export default SummaryPage;
