import React, { useRef, useState } from 'react';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import { EuiPanel, EuiText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui';
import BackgroundFileUploader from '@sharedComponents/common/BackgroundFileUploader';
import { get, set } from 'lodash';
import { APPLICATION_DOCUMENT } from '@sharedComponents/interfaces/Applications.interface';
import './DocumentsExternal.scss';

export default function DocumentsExternal({
  scholarship,
  application,
  onChange
}: {
  scholarship: ScholarshipModel;
  application: ApplicationModel | null;
  onChange: (application: ApplicationModel) => void;
}) {
  const [applicationPdf, setApplicationPdf] = useState(get(application, 'documents.application'));
  const [lorPdfs, setLorPdfs] = useState(get(application, 'documents.lettersOfRecommendation') || []);
  const donorId = useRef(get(scholarship, 'donor.id', null));

  const updateDocuments = (docName, value) => {
    const newApplication = {
      ...application
    };

    if (docName === APPLICATION_DOCUMENT.APPLICATION) {
      setApplicationPdf(value);
    }

    if (docName === APPLICATION_DOCUMENT.LETTERS_OF_RECOMMENDATION) {
      setLorPdfs(value);
    }

    set(newApplication, `documents.${docName}`, value);

    onChange(newApplication as ApplicationModel);
  };

  return (
    <div className="DocumentsExternal">
      <EuiPanel hasShadow={false} color={'subdued'}>
        <h3>Upload Documents</h3>
        <EuiText size={'s'} className={'help'}>
          Upload your application or letter of recommendation. The documents will be sent to the scholarship provider.
        </EuiText>
        <EuiFlexGroup className="filesContainer" direction="column">
          <EuiFlexItem className={'applicationFile'}>
            <EuiFormRow label="Application" helpText="Application file in a PDF format" fullWidth>
              <BackgroundFileUploader
                value={applicationPdf}
                onChange={value => updateDocuments('application', value)}
                donorId={donorId.current}
                accept=".pdf"
                forcedMimeType="pdf"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={'lorFiles'}>
            <EuiFormRow
              label="Letters of Recommendation"
              helpText="Letters of recommendation in a PDF format"
              fullWidth
            >
              <BackgroundFileUploader
                value={lorPdfs}
                onChange={values => updateDocuments('lettersOfRecommendation', values)}
                donorId={donorId.current}
                accept=".pdf"
                forcedMimeType="pdf"
                maxFiles={5}
                multiple
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </div>
  );
}
