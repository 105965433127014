import React, { useState } from 'react';

import { EuiButtonEmpty, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiPopover, EuiPopoverTitle } from '@elastic/eui';

import { DATA_ACTIONS } from './config';

const ActionsRowCellRenderer = ({
  cellDetails,
  data /** thats not a good thing to pass huge data as a prop here? */,
  reviewers,
  dispatch
}) => {
  const { rowIndex } = cellDetails;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const applicationDetails = data[rowIndex];
  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      anchorPosition="upCenter"
      panelPaddingSize="s"
      button={
        <EuiButtonIcon
          aria-label="show actions"
          iconType="boxesHorizontal"
          color="text"
          size="m"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        />
      }
      closePopover={() => setIsPopoverOpen(false)}
      ownFocus={false}
      style={{ margin: '0 6px' }}
    >
      <EuiPopoverTitle>Actions</EuiPopoverTitle>
      <EuiFlexGroup direction="column" gutterSize="xs">
        {reviewers.map(reviewer => (
          <EuiFlexItem key={reviewer?.id} style={{ alignItems: 'flex-start' }}>
            <EuiButtonEmpty
              iconType={'pin'}
              size="s"
              onClick={() => {
                dispatch({ action: DATA_ACTIONS.ASSIGN_SINGLE, context: rowIndex, value: reviewer });
              }}
            >
              {applicationDetails['reviewers'].some(r => r?.id === reviewer?.id) ? 'Unassign from' : 'Assign to'}{' '}
              {reviewer.data.name} {reviewer.data.lastName || ''}
            </EuiButtonEmpty>
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export default ActionsRowCellRenderer;
