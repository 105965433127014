import './DonorOrganizationStep.scss';

import React from 'react';
import * as yup from 'yup';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import { DonorModelShape } from '@sharedComponents/models';

import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';
import OrganizationInput from './DonorOrganizationStep/OrganizationInput';

// TODO: preselect donor org in case there is donor param in url (see @backend/utils/urls, getDonorSignupUrl)
const donorOrganizationStep: Step = {
  validationSchema: _userType =>
    yup
      .object()
      .shape({
        isDonorExists: yup.boolean().optional(), // later validated with .test
        donor: yup.object().shape({
          id: yup.number().optional().nullable(),
          name: yup.string().label('Organization Name').required('Organization name is required'),
          email: yup.string().email().label('Organization email').optional().nullable(),
          phone: yup.string().label('Donor phone number').max(20).optional().nullable(),
          street: yup.string().optional().nullable(),
          street2: yup.string().optional().nullable(),
          city: yup.string().optional().nullable(),
          state: yup.string().nullable().optional(),
          zip: yup.string().optional().nullable()
        })
      })
      .test('donor.name', 'Organization name is required', async contactStep => {
        if (contactStep.isDonorExists === undefined) {
          return false;
        }

        return true;
      })
      .test('donor.email', 'Organization email is required', async contactStep => {
        // if donor is new and no email is set
        if (
          contactStep.isDonorExists === false &&
          (!contactStep.donor?.email || !yup.string().email().isType(contactStep.donor?.email))
        ) {
          return false;
        }

        return true;
      }),
  // ideally this check still has to happen, to avoid forced duplicates. But somehow trigger this one before network call instead of every render(revalidate)
  // .test('donor.name', 'Organization with same name is already exist', async contactStep => {
  //   if (contactStep.isDonorExists && contactStep.donor?.id) {
  //     return true;
  //   }

  //   const client = new ScholarshipClient(fetch, null, null);
  //   const lookup = await client.dataLookup(contactStep.donor.name, 'donorOrgName');
  //   const hasSameNamedDonors = lookup.reduce((result, record) => {
  //     return result || record.name === contactStep.donor.name;
  //   }, false);

  //   if (hasSameNamedDonors) {
  //     return false;
  //   }

  //   return true;
  // })
  render({ formMethods }) {
    const clearDonorInputs = () => {
      formMethods.setValue('donor.id', undefined);
      formMethods.setValue('donor.email', undefined);
      formMethods.setValue('donor.phone', undefined);
      formMethods.setValue('donor.street', undefined);
      formMethods.setValue('donor.street2', undefined);
      formMethods.setValue('donor.city', undefined);
      formMethods.setValue('donor.state', undefined);
      formMethods.setValue('donor.zip', undefined);
    };

    const onExistingDonorSelection = (donor: DonorModelShape | null) => {
      if (donor) {
        formMethods.setValue('isDonorExists', true);
        formMethods.setValue('donor.id', donor.id);

        if (donor.name) {
          formMethods.setValue('donor.name', donor.name);
        }

        formMethods.setValue('donor.email', donor.email);
        formMethods.setValue('donor.phone', donor.phone);
        formMethods.setValue('donor.street', donor.street);
        formMethods.setValue('donor.street2', donor.street2);
        formMethods.setValue('donor.city', donor.city);
        formMethods.setValue('donor.state', donor.state);
        formMethods.setValue('donor.zip', donor.zip);
      } else {
        formMethods.setValue('isDonorExists', undefined);

        clearDonorInputs();
      }
    };

    const onNewDonorCreation = orgName => {
      formMethods.setValue('isDonorExists', false);
      formMethods.setValue('donor.name', orgName);

      clearDonorInputs();
    };

    return (
      <EuiFlexGroup direction="column" gutterSize="s" className="DonorOrganizationStep" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>Organization Registration</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
        </EuiFlexItem>
        <EuiFlexItem>
          <OrganizationInput
            onNewDonorCreation={onNewDonorCreation}
            onExistingDonorSelection={onExistingDonorSelection}
            formMethods={formMethods}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  },

  async getNextStep() {
    return null;
  },

  renderControls({ formMethods, completeSignup, changeStep, loadingStatus }) {
    // we validate our input and then processing to next step
    const stepSubmitHandler = formMethods.handleSubmit(
      () => {
        loadingStatus.startLoading();
        completeSignup();
      },
      _ => {
        // eslint-disable-next-line no-console
        console.warn(formMethods.errors);

        // ?  there was weird case tat this validation failed on 'updated_at' while this field is not even in schema!!
      }
    );

    return (
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiFlexItem>
          <EuiButton size="s" onClick={() => changeStep(SIGNUP_STEP.NEW_USER_REGISTRATION)}>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton color="primary" size="s" onClick={stepSubmitHandler} isLoading={loadingStatus.isLoading} fill>
            Sign Up
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default donorOrganizationStep;
