import React, { useEffect, useState } from 'react';

import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

import StudyLookupField from './StudyLookupWidget/StudyLookupField';

const MAX_LIST_VALUE = 20;

// Wrapper to have list over the fields.
function StudyLookupWidget({
  initialValues,
  placeholder,
  onChange,
  fieldName,
  dataType,
  isDisabled = false
}: {
  initialValues;
  placeholder;
  onChange;
  fieldName;
  dataType: 'schools' | 'colleges';
  isDisabled?;
}) {
  const scholarshipClient = useScholarshipClient();
  const [listValues, setListValues] = useState<any[]>(initialValues?.length ? [...initialValues] : [undefined]);

  const appendOne = () => {
    setListValues([...(listValues || []), undefined]);
  };

  const removeOne = index => {
    const changedArrayList = [...listValues];
    changedArrayList.splice(index, 1);
    setListValues(changedArrayList);
  };

  // when component is rendered without data and then rerender with new initialValues happens(async retrieved initial values)
  useEffect(() => {
    if (initialValues?.length && listValues && listValues[0] === undefined) {
      setListValues([...initialValues]);
    }
  }, [initialValues]);

  // trigger changes to outsite
  useEffect(() => {
    // only announce changes if they are supposed to change something
    if (JSON.stringify(initialValues) !== JSON.stringify(listValues)) {
      onChange(listValues);
    }
  }, [listValues]);

  return (
    <EuiFlexGroup direction="column" gutterSize="xs">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" gutterSize="s">
          {listValues?.length
            ? listValues.map((field, index) => (
                <EuiFlexItem key={`${fieldName}_${index}`}>
                  <EuiFlexGroup direction="row" gutterSize="xs" responsive={false}>
                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                      <EuiButtonIcon
                        aria-label="Remove item"
                        color="danger"
                        iconType="minusInCircle"
                        onClick={() => removeOne(index)}
                        title="Remove item"
                        isDisabled={isDisabled}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem
                      style={{
                        maxWidth:
                          'calc(100% - 32px)' /** Magic value to handle width of this widget mostly in profile */
                      }}
                    >
                      <StudyLookupField
                        dataType={dataType}
                        scholarshipClient={scholarshipClient}
                        placeholder={placeholder}
                        field={field}
                        isDisabled={isDisabled}
                        onSelect={entry => {
                          const changedArrayList = [...listValues];
                          changedArrayList[index] = entry;

                          setListValues(changedArrayList);
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              ))
            : null}
        </EuiFlexGroup>
      </EuiFlexItem>
      {listValues?.length < MAX_LIST_VALUE ? (
        <EuiFlexItem>
          <EuiButtonIcon
            aria-label="Add item"
            title="Add new item"
            color="primary"
            style={{ alignSelf: 'flex-end' }}
            iconType="plusInCircle"
            onClick={appendOne}
            isDisabled={isDisabled}
          />
        </EuiFlexItem>
      ) : null}
    </EuiFlexGroup>
  );
}

export default StudyLookupWidget;
