import React from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui';

import AbstractDeclineStep from './AbstractDeclineStep';
import { NOTIFY_DECLINED_STEPS } from './config';

const NotifyDeclinedStepNotifyCompleted: AbstractDeclineStep = {
  headerTitle: 'Notify Non-awarded Students',
  actions(changeStep) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton color="warning" onClick={() => changeStep(null, true)}>
          No, award runner-ups
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.CONFIRM_RECIPIENTS)} fill>
          Yes, send decline notifications
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render() {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            You are about to notify students who did not receive the award during this application cycle. <br />
          </EuiText>
          <EuiSpacer size="s" />
          <EuiText>Have all available scholarship awards been accepted by candidates?</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default NotifyDeclinedStepNotifyCompleted;
