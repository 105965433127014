import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';
import ChildrenInvitation from './ParentConfirmationStep/ChildrenInvitation';

const parentConfirmationStep: Step = {
  render({ applicationClient, completeSignup, changeStep, formMethods }) {
    const childrenData = formMethods.watch('invitations');
    return (
      <ChildrenInvitation
        applicationClient={applicationClient}
        childrenData={childrenData.filter(children => children.email?.length > 0)}
        confirmationCallback={async invitations => {
          await completeSignup({
            invitations: invitations
          });
        }}
        returnCallback={() => {
          changeStep(SIGNUP_STEP.NEW_USER_REGISTRATION);
        }}
      />
    );
  },

  async getNextStep() {
    return null;
  },

  renderControls(_props) {
    return <></>;
  }
};

export default parentConfirmationStep;
