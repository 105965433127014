import React, { ChangeEvent, useEffect, useState } from 'react';

import { EuiFieldText } from '@elastic/eui';
import useDebounce from '@sharedComponents/hooks/useDebounce';

/**
 * Debouncing input changes to trigger heavy 'onChange' events with more performant way
 */
export default function DebouncedInput({ initialValue, onChange, placeholder = '', disabled = false }) {
  const [value, setValue] = useState<string>(initialValue || '');

  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <EuiFieldText
      value={value}
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      placeholder={placeholder}
    />
  );
}
