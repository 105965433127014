export enum USER_TYPE {
  COUNSELOR = 'counselor',
  APPLICANT = 'applicant',
  DATAENTRY = 'dataEntry',
  ADMIN = 'admin',
  DONOR = 'donor',
  RECOMMENDER = 'recommender',
  PARENT = 'parent',
  REVIEWER = 'reviewer',
  PROFESSIONAL = 'professional'
}

export enum RESPONSE {
  OK = 'ok'
}

export const DEFAULT_ERROR_MESSAGE = 'Network error. Try again later.';
export const DEFAULT_ERROR_TITLE = 'Oops, something went wrong';

// number of questions per page (for pagination purposes)
export const QUESTIONS_PER_PAGE = 20;
