import React from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import { AwardStepProps } from './config';
import AbstractAwardStep from './AbstractAwardStep';

const AwardStepAwardingCompleted: AbstractAwardStep = {
  headerTitle: 'Award Process Complete!',
  actions(changeAwardingStep) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(null);
          }}
          fill
        >
          Close
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ selection }: AwardStepProps) {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            Congratulations! Your scholarship has been awarded to{' '}
            {selection.length === 1 ? 'the student' : selection.length + ' students, and they were sent an award email'}
            .
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepAwardingCompleted;
