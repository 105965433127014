import React, { useEffect, useState } from 'react';
import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui';
import IScholarshipClient from '@sharedClients/types/IScholarshipClient';
import useDebounce from '@sharedComponents/hooks/useDebounce';
import { DonorModelShape } from '@sharedComponents/models';

interface LookupFieldOption {
  label: string;
  entry?: object;
  disabled?: boolean;
}

/** Study lookup widget's version of LookupField. Could be potentially replaced with original LookupField */
export default function StudyLookupField({
  scholarshipClient,
  placeholder,
  field,
  dataType,
  onSelect,
  isDisabled = false
}: {
  scholarshipClient: IScholarshipClient;
  onSelect: (donor: DonorModelShape) => void;
  placeholder: string;
  field;
  dataType;
  isDisabled?;
}) {
  const [selectedOptions, setSelected] = useState<LookupFieldOption[]>(
    field?.name
      ? [
          {
            label: field.name,
            entry: field
          }
        ]
      : []
  );

  // when field options were loaded asyncally and coming after render
  useEffect(() => {
    if (!selectedOptions.length && field?.name) {
      setSelected([
        {
          label: field.name,
          entry: field
        }
      ]);
    }
  }, [field]);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<LookupFieldOption[]>([
    {
      // That's quite hacky way to show placeholder before user started typing
      label: 'Start typing to activate suggestions.',
      disabled: true
    }
  ]);

  const onChange = selectedOption => {
    setSelected([...selectedOption]);

    if (selectedOption[0]?.entry) {
      onSelect(selectedOption[0].entry);
    }
  };

  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    if (debouncedValue.length) {
      setLoading(true);
      setOptions([]);

      scholarshipClient.dataLookup(debouncedValue, dataType).then(schools => {
        setLoading(false);

        if (schools.length) {
          setOptions(
            schools.map(school => ({
              label: school.name,
              entry: school
            }))
          );
        }
      });
    }
  }, [debouncedValue]);

  const renderOption = (option, searchValue) => {
    const { entry, label, disabled } = option;
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>{!disabled ? <EuiHighlight search={searchValue}>{label}</EuiHighlight> : label}</EuiFlexItem>
        {entry?.city ? (
          <EuiFlexItem>
            {entry?.city}
            {entry?.state ? `, ${entry?.state}` : null}
          </EuiFlexItem>
        ) : null}
      </EuiFlexGroup>
    );
  };

  return (
    <EuiComboBox
      placeholder={placeholder}
      options={options}
      selectedOptions={selectedOptions}
      isLoading={isLoading}
      onChange={onChange}
      isDisabled={isDisabled}
      onSearchChange={setValue}
      singleSelection={{ asPlainText: true }}
      renderOption={renderOption}
      rowHeight={50}
      isClearable={false}
      fullWidth
      async
    />
  );
}
