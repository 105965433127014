import React from 'react';

export function PlusIcon({ skipVertical, className }: { skipVertical?: boolean; className?: string }) {
  return (
    <svg viewBox="0 0 512 512" className={className}>
      <g>
        <path d="M494,256c0,131.4-106.6,238-238,238S18,387.4,18,256S124.6,18,256,18S494,124.6,494,256z" />
      </g>
      <g>
        <g>
          {!skipVertical ? (
            <line
              stroke="#FFFFFF"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="46"
              x1="256"
              x2="256"
              y1="146"
              y2="374"
            />
          ) : null}
          <line
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="46"
            x1="150.5"
            x2="361.5"
            y1="260"
            y2="260"
          />
        </g>
      </g>
    </svg>
  );
}

export function MinusIcon(props) {
  return <PlusIcon skipVertical={true} {...props} />;
}
