import './Banner.scss';

import React from 'react';

import { EuiButton } from '@elastic/eui';

import { getDonorCalendlyLinkUrl } from './config';

// for implementing Banner component were used divs (instead of @eui components)
// in order to implement custom layout
export default function Banner() {
  return (
    <div className="Banner">
      <div className={'Banner__mainBlocks'}>
        <img src="/images/donor-homepage-banner-img.png" alt="banner" />
        <div className={'Banner__textWrapper'}>
          <div>
            <div>6x</div>
            <div>
              More Qualified
              <br />
              Applications
            </div>
          </div>
          <div>Get 6 times more qualified applications when you upgrade your Scholar's App account.</div>
        </div>
        <div className={'Banner__buttonWrapper'}>
          <EuiButton href={getDonorCalendlyLinkUrl()} color={'warning'} iconType={'arrowRight'} iconSide={'right'} fill>
            Get More Applications
          </EuiButton>
        </div>
      </div>
    </div>
  );
}
