import React from 'react';

import { EuiCallOut, EuiLink, EuiLoadingContent, EuiSpacer, EuiText } from '@elastic/eui';
import IScholarshipClient from '@sharedClients/types/IScholarshipClient';
import { scholarshipLink } from '@sharedComponents/utils/scholarshipUtils';

import useScholarshipDuplicateCheck from '../hooks/useScholarshipDuplicateCheck';

function ScholarshipPublicationStatus({
  scholarshipData,
  scholarshipClient
}: {
  scholarshipData: {
    id: number;
    name: string;
    scholarship_id?: number;
  };
  scholarshipClient: IScholarshipClient;
}) {
  const { isLoading, possibleDuplicates } = useScholarshipDuplicateCheck(scholarshipClient, {
    id: scholarshipData.id,
    name: scholarshipData.name,
    parentScholarshipID: scholarshipData.scholarship_id
  });

  if (isLoading) {
    return <EuiLoadingContent lines={6} />;
  }

  return (
    <>
      {possibleDuplicates?.length === 1 ? (
        <EuiCallOut color="warning" title="You're about to publish new scholarship!" iconType="indexOpen">
          <EuiText size="s" color="warning">
            This scholarship is a possible duplicate of:{' '}
            <EuiLink href={scholarshipLink(possibleDuplicates[0])} target="_blank">
              {possibleDuplicates[0].name}
            </EuiLink>
            <EuiSpacer size="s" />
            Check to ensure you are creating a unique record.
          </EuiText>
          <EuiSpacer size="s" />
        </EuiCallOut>
      ) : possibleDuplicates?.length > 1 ? (
        <EuiCallOut color="warning" title="You're about to publish new scholarship!" iconType="indexOpen">
          <EuiText size="s" color="warning">
            This scholarship is a possible duplicate of:{' '}
            {possibleDuplicates.map(scholarhip => (
              <React.Fragment key={scholarhip.id}>
                <EuiLink href={scholarshipLink(scholarhip)} target="_blank">
                  {scholarhip.name}
                </EuiLink>
                {scholarhip.id === possibleDuplicates[possibleDuplicates.length - 1].id ? '.' : ', '}
              </React.Fragment>
            ))}{' '}
            <EuiSpacer size="s" />
            Check to ensure you are creating a unique record.
          </EuiText>
          <EuiSpacer size="s" />
        </EuiCallOut>
      ) : (
        <EuiCallOut color="success" title="You're about to publish new scholarship!" iconType="indexOpen">
          <p>No duplicates found. Scholarship entry is ready to be published.</p>
          {scholarshipData?.scholarship_id ? (
            <p>
              <EuiText color="warning">
                Note that this draft scholarship publication will update already{' '}
                <EuiLink target="_blank" href={`/scholarships/${scholarshipData.scholarship_id}`}>
                  existing scholarship
                </EuiLink>
                .
              </EuiText>
            </p>
          ) : null}
        </EuiCallOut>
      )}
    </>
  );
}

export default ScholarshipPublicationStatus;
