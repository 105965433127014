import React, { useState } from 'react';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiRange,
  EuiSpacer,
  EuiText,
  htmlIdGenerator
} from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import useToast from '@sharedComponents/hooks/useToast';
import { ScholarshipModelShape } from '@sharedComponents/models';
import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import formatDate from '@sharedComponents/utils/formatDate';

export default function ReviewRoundsStatusBar({
  scholarship,
  canEdit,
  candidatesAmount,
  onSetupStart,
  onRoundEdit,
  onLastRoundDetails,
  onNotifyNonAwarded,
  filter
}: {
  scholarship: ScholarshipModelShape;
  canEdit: boolean;
  candidatesAmount: number;
  onSetupStart: (maxRounds: number) => void;
  onRoundEdit: () => void;
  onLastRoundDetails: () => void;
  onNotifyNonAwarded: () => void;
  filter: APPLICATION_TYPE;
}) {
  const user = useLoggedInUser()!;
  const [isStartingDialogOpened, toggleStartDialog] = useState(false);
  const [reviewRoundsInput, setReviewRoundsAmount] = useState('2');
  const { addToast } = useToast();

  const reviewRound = scholarship.reviewRound || null;

  const reviewStartDialog = (
    <EuiPopover
      ownFocus
      button={
        <EuiButton size="s" onClick={() => toggleStartDialog(!isStartingDialogOpened)}>
          Start Applications Review
        </EuiButton>
      }
      isOpen={isStartingDialogOpened}
      closePopover={() => toggleStartDialog(false)}
    >
      <EuiPopoverTitle>Review process for {scholarship?.name}</EuiPopoverTitle>
      {candidatesAmount ? (
        <>
          <div>
            <EuiText size="s">
              During a review round, reviewers are assigned to evaluate specific scholarship candidates. Select number
              of review rounds:
              <EuiRange
                id={htmlIdGenerator()()}
                value={reviewRoundsInput}
                onChange={e => setReviewRoundsAmount((e.target as HTMLTextAreaElement).value)}
                showInput
                min={1}
                max={7}
                aria-label="Review rounds amount"
              />
            </EuiText>
            <EuiSpacer size="s" />
            <EuiText size="s">
              Total of {candidatesAmount} candidates will be evaluated during the first review round.
            </EuiText>
          </div>
          <EuiPopoverFooter>
            <EuiButton
              fullWidth
              size="s"
              onClick={() => {
                if (!scholarship?.reviewers?.length) {
                  return addToast({
                    id: htmlIdGenerator()(),
                    color: 'danger',
                    title: 'No reviewers available!',
                    text: 'Your organization should have at least one reviewer available in order to start applications review.'
                  });
                } else if (!scholarship?.review_criteria) {
                  return addToast({
                    id: htmlIdGenerator()(),
                    color: 'danger',
                    title: 'No Criteria mentioned',
                    text: 'your organization should have review criteria defined in order to start application review.'
                  });
                }

                onSetupStart(parseInt(reviewRoundsInput));
              }}
            >
              Start Review Round
            </EuiButton>
          </EuiPopoverFooter>
        </>
      ) : (
        <div>
          <EuiText size="s">There are no candidates available in order to start the first review round.</EuiText>
        </div>
      )}
    </EuiPopover>
  );

  let notifyNonAwardedButton;

  if (filter === APPLICATION_TYPE.AWARDED && user.type !== USER_TYPE.COUNSELOR) {
    notifyNonAwardedButton = (
      <EuiFlexItem grow={false}>
        <EuiButton color="success" onClick={onNotifyNonAwarded} size="s">
          Notify non-awarded students
        </EuiButton>
      </EuiFlexItem>
    );
  }

  if (!scholarship?.reviewRound) {
    return (
      <>
        <EuiFlexGroup direction="row">
          <EuiFlexItem>
            <EuiSpacer size="xs" />
            <EuiText textAlign="left">
              There is no active review process at this time. <br />{' '}
              {canEdit ? 'Click the "Start Applications Review" button to begin reviewing candidates.' : null}
            </EuiText>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" alignItems="flexEnd" gutterSize="m">
              {canEdit && filter === APPLICATION_TYPE.CANDIDATES ? (
                <EuiFlexItem grow={false}>{reviewStartDialog}</EuiFlexItem>
              ) : null}
              {canEdit && scholarship?.lastCompletedReviewRound ? (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiButton color="primary" onClick={onLastRoundDetails} size="s">
                      Review latest review round [finished {formatDate(scholarship.lastCompletedReviewRound.updated_at)}
                      ]
                    </EuiButton>
                  </EuiFlexItem>
                  {notifyNonAwardedButton}
                </>
              ) : null}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  }

  return (
    <>
      <EuiText textAlign="center">
        Round {reviewRound.currentRound} out of {reviewRound.totalRounds} is in progress.
      </EuiText>
      {canEdit ? (
        <EuiButtonEmpty color="primary" onClick={onRoundEdit}>
          View details
        </EuiButtonEmpty>
      ) : null}
    </>
  );
}
