/* eslint-disable react-hooks/rules-of-hooks */
import track from '@sharedComponents/base/track';
import { getServiceUrl } from '@sharedClients/main';
import { FormPost } from '@sharedClients/types/FormModel';
import { GetForm } from '@sharedClients/types/IScholarshipClient';
import UserModel from '@sharedContract/UserModel';
import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import ButtonBar from '../components/ButtonBar';
import { HeaderProps } from '../components/Header';
import EditForm from './EditForm';
import './Form.scss';
import openPreviewWindow, { previewForm } from './openPreviewWindow';
import ViewForm from './ViewForm';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import FormReviewRequestConfirmationModal from '@donors/form/Form/FormReviewRequestConfirmationModal';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import useToast from '@sharedComponents/hooks/useToast';
import {
  FORM_CHANGE_REQUEST_STATUSES,
  FORM_REVIEW_STATUSES
} from '@sharedComponents/interfaces/Scholarships.interface';
import { EuiCallOut } from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { isArray } from 'lodash';

export const noValue = '—';

export const fullFormId = 0;

interface FormProps extends RouteComponentProps<{}>, HeaderProps {
  user: UserModel;
  formId: number;
}

/**
 * Handles fetching and storing of the scholarship and the application.
 */
const Form: FunctionComponent<PropsWithChildren<FormProps>> = props => {
  const { user, history, formId } = props;
  const scholarshipClient = useScholarshipClient();
  const router = useRouter();
  const [isEdit, setEdit] = useState(false);
  const [form, setForm] = useLocalStorage<GetForm | null>('form.' + formId, null);
  const [standardForm, setStandardForm] = useLocalStorage<any>('standardForm', []);
  const [unsavedForm, setUnsavedForm] = useState<FormPost | null>(null);

  const [onError, errorMessage] = useErrorReporter();
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);

  const { addToast } = useToast();
  const createModal = useModalCreate();

  const canEdit = form && (form.donorId != null || user.type === 'admin');
  useEffect(() => {
    scholarshipClient
      .getForm(formId, ['scholarships', 'changeRequest'])
      .then(form => {
        setForm({ ...form, standardForm: undefined } as any);

        setStandardForm(form.standardForm);

        document.title = `${form.name} – Form definition at Scholar's App`;
      })
      .catch(onError);
  }, [formId]);

  const navigateToVisualEditor = () => {
    router.push(`/form-builder/${formId}`);
  };

  // TODO: figure out why form.data is non array typed here
  const hasAcceptancePage = (form && isArray(form?.data) ? (form.data as Array<any>) : []).reduce((prev, curr) => {
    return prev || curr?.path === 'awardAcceptance';
  }, false);

  async function preview(unsavedForm?: FormPost, isAwardAcceptancePreview = false) {
    let formToPreview =
      hasAcceptancePage && isAwardAcceptancePreview
        ? { ...form, data: [...(form?.data as Array<any>).filter(page => page?.path === 'awardAcceptance')] }
        : unsavedForm || form;

    if (!formToPreview) {
      return;
    }

    const applyDomain = getServiceUrl('apply');

    let preview = previewWindow;

    if (!preview || !preview.window) {
      preview = await openPreviewWindow(applyDomain);

      setPreviewWindow(preview);
    }

    previewForm({
      previewWindow: preview,
      form: formToPreview as any,
      standardForm,
      applyDomain,
      awardStatus: isAwardAcceptancePreview ? AWARD_STATUS.AWARD_NOTIFIED : AWARD_STATUS.CANDIDATE
    });
  }

  function sendForReview() {
    if (!form?.scholarships?.length) {
      return;
    }

    createModal(({ closeModal }) => (
      <FormReviewRequestConfirmationModal
        closeModal={closeModal}
        form={form!}
        addToast={addToast}
        onReviewRequested={changeRequest => {
          setForm({
            ...form,
            changeRequest
          });
          closeModal();
        }}
      />
    ));
  }

  function duplicate() {
    if (form) {
      history.push('/forms/add?basedon=' + form.id);
    }
  }

  return (
    <div className="Form">
      <div className="body">
        <div className="content">
          {errorMessage}

          <ButtonBar className="links">
            {isEdit ? (
              <div>
                <button className="button" onClick={() => setEdit(false)}>
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={() => {
                    preview(unsavedForm ? unsavedForm : undefined).catch(onError);
                  }}
                >
                  Preview Application
                </button>
              </div>
            ) : (
              <div>
                {form ? (
                  <button className="button" onClick={() => preview().catch(onError)}>
                    Preview Application
                  </button>
                ) : null}
                {form && hasAcceptancePage ? (
                  <button className="button" onClick={() => preview(undefined, true).catch(onError)}>
                    Preview Award Acceptance
                  </button>
                ) : null}
                {form && !isEdit ? (
                  <button className="button" onClick={duplicate}>
                    Duplicate
                  </button>
                ) : null}
                {canEdit ? (
                  <button data-testid="editButton" className="button" onClick={() => setEdit(true)}>
                    Edit
                  </button>
                ) : null}
                {form?.scholarships?.length ? (
                  <button
                    className="button"
                    onClick={sendForReview}
                    disabled={
                      form?.changeRequest?.status &&
                      form?.changeRequest?.status !== FORM_CHANGE_REQUEST_STATUSES.APPROVED
                    }
                  >
                    {form?.changeRequest ? 'Resend for Review' : 'Send for Review'}
                  </button>
                ) : null}
                {canEdit ? (
                  <button className="button" onClick={navigateToVisualEditor}>
                    Edit in Visual Editor [Beta]
                  </button>
                ) : null}
              </div>
            )}
          </ButtonBar>

          {form?.changeRequest?.status === FORM_CHANGE_REQUEST_STATUSES.CHANGES_REQUESTED &&
            form.changeRequest.reviews?.map(review =>
              review.status === FORM_REVIEW_STATUSES.CHANGES_REQUESTED ? (
                <EuiCallOut title="Requested changes" color="warning" key={review.id} style={{ marginBottom: '18px' }}>
                  <p>{review.changes || ''}</p>
                </EuiCallOut>
              ) : null
            )}

          {form ? (
            isEdit ? (
              <EditForm
                form={form}
                standardForm={standardForm}
                onChange={form => {
                  setUnsavedForm(form);

                  if (previewWindow && previewWindow.window) {
                    preview(form).catch(onError);
                  }
                }}
                onSave={editedForm =>
                  scholarshipClient
                    .updateForm(formId, editedForm)
                    .then(() => {
                      track('form-updated');

                      setForm({ ...form, ...editedForm });
                      setUnsavedForm(null);
                      setEdit(false);
                    })
                    .catch(onError)
                }
                onPreview={preview}
                onCancel={() => setEdit(false)}
                onError={onError}
              />
            ) : (
              <ViewForm form={form} standardForm={standardForm} user={user} />
            )
          ) : (
            []
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Form);
