import '@sharedComponents/ScholarshipApplications.scss';
import './ApplicationsCategories.scss';

import { isNil } from 'lodash';
import React from 'react';

import {
  EuiBetaBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiKeyPadMenuItem,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';
import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { ApplicationModelShape, ScholarshipModelShape } from '@sharedComponents/models';
import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';
import UserModel from '@sharedContract/UserModel';

import useActiveCategory from './hooks/useActiveCategory';

export default function ApplicationsCategories({
  scholarship,
  isReviewing,
  applicationsFetchResult
}: {
  scholarship: ScholarshipModelShape;
  isReviewing: boolean;
  applicationsFetchResult?;
}) {
  const { value: activeCategory, change: changeActiveCategory } = useActiveCategory();
  const category = (name: string, categoryName: APPLICATION_TYPE, help: string) => {
    const category =
      applicationsFetchResult && !isNil(applicationsFetchResult[categoryName])
        ? applicationsFetchResult[categoryName]
        : '~'; // pagination object, array of users or number

    let iconType = 'documents';
    switch (categoryName) {
      case APPLICATION_TYPE.AWARDED: {
        iconType = 'starEmpty'; // indexOpen starEmpty
        break;
      }
      case APPLICATION_TYPE.UNREVIEWED: {
        iconType = 'folderOpen';
        break;
      }
      case APPLICATION_TYPE.REVIEWED: {
        iconType = 'folderCheck';
        break;
      }
      case APPLICATION_TYPE.REVIEWED_PAST_ROUNDS: {
        iconType = 'folderClosed';
        break;
      }
      case APPLICATION_TYPE.CANDIDATES: {
        iconType = 'indexOpen';
        break;
      }
      case APPLICATION_TYPE.ELIMINATED: {
        iconType = 'indexClose';
        break;
      }
      case APPLICATION_TYPE.NODOCUMENTS: {
        iconType = 'document';
        break;
      }
      case APPLICATION_TYPE.NOTSUBMITTED: {
        iconType = 'indexEdit';
        break;
      }
      case APPLICATION_TYPE.QUALIFIED: {
        iconType = 'users';
        break;
      }
      case APPLICATION_TYPE.RECURRING: {
        iconType = 'starPlusEmpty';
        break;
      }
    }

    const betaBadgeLabel = (
      typeof category === 'object'
        ? (category as Array<UserModel>)?.length
          ? (category as Array<UserModel>).length
          : (category as Page<ApplicationModelShape>)?.total || 0
        : category
    ).toString();
    return (
      <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
        <EuiToolTip content={help}>
          <EuiKeyPadMenuItem
            className={activeCategory && activeCategory === categoryName ? 'isActive' : undefined}
            label={name}
            onClick={() => changeActiveCategory(categoryName)}
          >
            <div className="euiKeyPadMenuItem__badgeWrapper">
              <EuiBetaBadge
                size="s"
                color="subdued"
                className="euiKeyPadMenuItem__badge"
                label={betaBadgeLabel}
                title={betaBadgeLabel}
              />
            </div>
            <EuiIcon type={iconType} size={iconType === 'document' ? 'xl' : 'xxl'} />
          </EuiKeyPadMenuItem>
        </EuiToolTip>
      </EuiFlexItem>
    );
  };

  const currentReviewRound = scholarship.reviewRound?.currentRound;

  // external scholarship
  const categories: React.ReactElement[] = [];
  if (scholarship.apply_url) {
    categories.push(
      category('Applied', APPLICATION_TYPE.CANDIDATES, 'Students who said they submitted an application.')
    );

    if (scholarship.require_transcript) {
      categories.push(
        category('Missing transcript', APPLICATION_TYPE.NODOCUMENTS, 'Submitted an application but lack a transcript.')
      );
    }

    categories.push(
      category(
        'Intended to apply',
        APPLICATION_TYPE.NOTSUBMITTED,
        'Enquired about applying but never submitted an application.'
      )
    );

    categories.push(
      category('Qualified', APPLICATION_TYPE.QUALIFIED, 'Students who confirmed they qualify for the scholarship.')
    );
  } else {
    if (isReviewing) {
      categories.push(
        category(
          `For Review ${currentReviewRound ? `in Round ${currentReviewRound}` : ''}`,
          APPLICATION_TYPE.UNREVIEWED,
          'Applications you should review in this round.'
        )
      );

      categories.push(
        category(
          `Reviewed ${currentReviewRound ? `in Round ${currentReviewRound}` : ''}`,
          APPLICATION_TYPE.REVIEWED,
          'Applications you have reviewed already in this round.'
        )
      );

      categories.push(
        category(
          'Reviewed in Previous Rounds',
          APPLICATION_TYPE.REVIEWED_PAST_ROUNDS,
          'Applications you have reviewed already in previous rounds.'
        )
      );
    } else {
      categories.push(
        category(
          'Qualified',
          APPLICATION_TYPE.QUALIFIED,
          'Students who confirmed they qualify for the scholarship, but have not yet applied.'
        )
      );

      categories.push(
        category('Not Submitted', APPLICATION_TYPE.NOTSUBMITTED, 'Students who started applying but never submitted.')
      );

      categories.push(
        category(
          'Missing Documents',
          APPLICATION_TYPE.NODOCUMENTS,
          'Applications that lack a transcript or letter of recommendation.'
        )
      );

      categories.push(
        category('Eliminated', APPLICATION_TYPE.ELIMINATED, 'Applications that have been manually eliminated.')
      );

      categories.push(
        category('Candidates', APPLICATION_TYPE.CANDIDATES, 'Applications to consider when selecting the winner.')
      );

      categories.push(
        category('Awarded', APPLICATION_TYPE.AWARDED, 'Candidates that you have awarded the scholarship.')
      );

      if (scholarship.isUsingRecurrentPayments) {
        categories.push(
          category('Recurring', APPLICATION_TYPE.RECURRING, 'Candidates that you have awarded with recurring awards.')
        );
      }
    }
  }

  return (
    <EuiFlexGroup className="applicationsCategories" justifyContent="spaceEvenly">
      {categories.map(Category => Category)}
    </EuiFlexGroup>
  );
}
