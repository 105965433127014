import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';

import EntryStepContact from './ScholarshipEntryFormSteps/EntryStepContact';
import EntryStepEligibility from './ScholarshipEntryFormSteps/EntryStepEligibility';
import EntryStepEligibilitySecond from './ScholarshipEntryFormSteps/EntryStepEligibilitySecond';
import EntryStepEligibilityThird from './ScholarshipEntryFormSteps/EntryStepEligibilityThird';
import EntryStepPublish from './ScholarshipEntryFormSteps/EntryStepPublish';
import EntryStepQA from './ScholarshipEntryFormSteps/EntryStepQA';
import EntryStepQASecond from './ScholarshipEntryFormSteps/EntryStepQASecond';
import EntryStepResearch from './ScholarshipEntryFormSteps/EntryStepResearch';
import EntryStepResearchSecond from './ScholarshipEntryFormSteps/EntryStepResearchSecond';
import EntryStepResearchThird from './ScholarshipEntryFormSteps/EntryStepResearchThird';
import EntryStepVerification from './ScholarshipEntryFormSteps/EntryStepVerification';

const getScholarshipEntryFormSteps = (step: SCHOLARSHIP_ENTRY_STEPS) =>
  ({
    [SCHOLARSHIP_ENTRY_STEPS.RESEARCH]: EntryStepResearch,
    [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND]: EntryStepResearchSecond,
    [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_THIRD]: EntryStepResearchThird,
    [SCHOLARSHIP_ENTRY_STEPS.CONTACT]: EntryStepContact,
    [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY]: EntryStepEligibility,
    [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_SECOND]: EntryStepEligibilitySecond,
    [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_THIRD]: EntryStepEligibilityThird,
    [SCHOLARSHIP_ENTRY_STEPS.VERIFICATION]: EntryStepVerification,
    [SCHOLARSHIP_ENTRY_STEPS.QA]: EntryStepQA,
    [SCHOLARSHIP_ENTRY_STEPS.QA_SECOND]: EntryStepQASecond,
    [SCHOLARSHIP_ENTRY_STEPS.PUBLISH]: EntryStepPublish
  }[step]);

export default getScholarshipEntryFormSteps;
