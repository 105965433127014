const field = {
  type: 'address',
  children: [
    { field: 'name', required: true },
    { field: 'email', type: 'email', required: true },
    { field: 'phone', type: 'phone', width: 14, required: false }
  ]
};

/**
 * The field type "counselors" expands to this definition.
 * Unfortunately duplicated in donors.
 */
export default function counselorForm() {
  return {
    type: 'section',
    title: 'Counselor',
    path: 'counselor',
    help: 'The application requires your high school transcript. Enter the contact details of your post-high counselor, so we can request the transcript from them.',
    columns: [[field], [{ type: 'counselorStatus', field: 'status' }]]
  };
}

export function rawCounselorForm() {
  return { ...field, path: 'counselor' };
}
