import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import Apply from '@sharedComponents/routes/Apply';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { useState } from 'react';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import ScholarshipApplicationsBreadcrumbs from '@sharedComponents/ScholarshipApplications/ScholarshipApplicationsBreadcrumbs';
import { ScholarshipModelShape } from '@sharedComponents/models';

/**
 * Access: admins
 *
 * Hardcode wrap over legacy application form in order to let admins to create standalone applications(applications without real users assigned)
 */
const StandaloneApplication = ({
  scholarshipID,
  applicationID
}: {
  scholarshipID: number;
  applicationID?: number | null;
}) => {
  const user = useLoggedInUser();

  const [scholarship, setScholarship] = useState<ScholarshipModel>();

  // legaccy parts in order to keep support
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSaveStatus] = useState<string>();

  return (
    <PageContentWrapper fullHeight restrictWidth={true}>
      <>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem>
            <ScholarshipApplicationsBreadcrumbs
              scholarship={scholarship as any as ScholarshipModelShape}
              viewMode={0}
              onViewModeReset={() => {}}
              extraItems={[{ text: applicationID ? 'Edit application' : 'Create new application', href: '' }]}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <Apply
              scholarshipId={scholarshipID}
              onScholarship={setScholarship}
              user={user}
              onSaveStatusChange={setSaveStatus}
              applicationID={applicationID}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </PageContentWrapper>
  );
};

export default StandaloneApplication;
