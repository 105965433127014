import React from 'react';
import UserModel from '@sharedContract/UserModel';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import ApplicationList from '@sharedComponents/ApplicationList/ApplicationList';
import { FormNode } from '@donors/application/FormNode';
import signupForms from './signupForms';
import Field from '@donors/components/Field';
import { Link } from 'react-router-dom';
import formatDate from '@sharedComponents/utils/formatDate';
import preventDefault from '@donors/base/preventDefault';
import { History } from 'history';

const ViewUser = ({
  user,
  currentUser,
  applications,
  history,
  onError
}: {
  user: UserModel;
  currentUser: UserModel;
  applications: ApplicationModel[] | null;
  history: History;
  onError: (e: Error) => void;
}) => {
  const data = user && user.data;
  const donorUrl = user && user.donor && currentUser.type === 'admin' ? `/donors/${user.donor.id}` : null;

  return (
    <div>
      <Field label="Email">
        <div className="value">{user && user.name}</div>
      </Field>

      <Field label="Type">
        <div className="value">{user && user.type}</div>
      </Field>

      <FormNode application={data} fullApplication={data} node={getForm(user)} onError={onError} />

      <Field label="Created">
        <div className="value">{renderDate(user && user.createdAt)}</div>
      </Field>

      <Field label="Last updated">
        <div className="value">{renderDate(user && user.updatedAt)}</div>
      </Field>

      <Field label="Last login">
        <div className="value">{renderDate(user && user.lastLogin)}</div>
      </Field>

      {donorUrl ? (
        <Field label="Donor Organization">
          <div className="value">
            <Link to={donorUrl} onClick={preventDefault(() => history.push(donorUrl))}>
              {user.donor!.name}
            </Link>
          </div>
        </Field>
      ) : null}

      <Field label="Registration source">
        <div className="value">
          {user?.data?.source || '-'}
          {user?.data?.source_other ? `(${user?.data?.source_other})` : ''}
        </div>
      </Field>

      {applications ? (
        <div>
          <h4>Applications</h4>

          <ApplicationList applications={applications} showName={false} />
        </div>
      ) : null}
    </div>
  );
};

function renderDate(date: string | Date | null | undefined) {
  return date ? formatDate(date, { showTime: true }) : '–';
}

function getForm(user: UserModel) {
  const type = user && user.type;
  return (
    signupForms[type] || [
      { field: 'name', required: true },
      { field: 'lastName', required: true },
      { field: 'phone', type: 'phone', required: true }
    ]
  );
}
export default ViewUser;
