import { findIndex } from 'lodash';
import { useState } from 'react';

import { cn } from '@bem-react/classname';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTitle,
  useEuiTheme
} from '@elastic/eui';
import { ScholarshipModelShape } from '@sharedComponents/models';

import inviteScholarshipsQuery from './DonorsInvitationModal/queries/inviteScholarshipsQuery';
import ScholarshipsToInvite from './DonorsInvitationModal/ScholarshipsToInvite';

const blockClass = cn('DonorsInvitationModal');

const DonorsInvitationModal = ({ scholarships, closeModal }: { scholarships: ScholarshipModelShape[]; closeModal }) => {
  const { euiTheme } = useEuiTheme();
  const { mutateAsync: inviteScholarshipsAsync, isLoading } = inviteScholarshipsQuery.useScholarshipInvite();
  const [invitationList, setInvitationList] = useState<
    (ScholarshipModelShape & {
      isChecked: boolean;
    })[]
  >(
    scholarships.map(s => ({
      ...s,
      isChecked: false
    }))
  );

  const inviteScholarsips = async e => {
    e.preventDefault();

    const scholarshipsToInvite = invitationList.filter(s => s.isChecked);

    if (scholarshipsToInvite && scholarshipsToInvite.length) {
      await inviteScholarshipsAsync(scholarshipsToInvite.map(s => s.id));
    }

    closeModal();
  };

  const isAllSelected = invitationList.reduce((prev, curr) => (!prev || !curr.isChecked ? false : true), true);
  const isAnySelected = invitationList.some(s => s.isChecked);

  const selectionCtaLabel = isAllSelected ? 'Reset' : 'Select all scholarships';

  const onScholarshipSelectionHandle = (id: number) => {
    const recordIndex = findIndex(invitationList, function (inv) {
      return inv.id === id;
    });

    if (recordIndex >= 0) {
      invitationList[recordIndex].isChecked = !invitationList[recordIndex].isChecked;
      setInvitationList([...invitationList]);
    }
  };

  const onCancelHandler = () => {
    closeModal();
  };

  return (
    <EuiModal className={blockClass()} style={{ width: '992px' }} maxWidth={992} onClose={onCancelHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup direction="row" gutterSize="xs" responsive={false} justifyContent="spaceBetween">
                <EuiFlexItem>
                  <EuiTitle size="s" className={'m-dark'}>
                    <h2>Invite Scholarships</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  {invitationList?.length > 0 ? (
                    <EuiFlexGroup direction="row" gutterSize="none" justifyContent="flexStart">
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          onClick={e => {
                            e.preventDefault();

                            const updatedList = invitationList.map(s => ({
                              ...s,
                              isChecked: !isAllSelected
                            }));

                            setInvitationList([...updatedList]);
                          }}
                          fullWidth={false}
                          color="success"
                          size="s"
                          fill
                        >
                          {selectionCtaLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  ) : null}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem className="invitation-subtitle" grow={false}>
              <EuiText size="m" color={euiTheme.colors.darkShade}>
                There are {invitationList.length} local scholarships listed on Scholar's App, but the scholarship
                provider has not joined the platform. Invite the scholarship provider to streamline your students'
                application process (an email will be automatically sent on your behalf).
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
          <EuiFlexItem>
            {invitationList?.length ? (
              <ScholarshipsToInvite invitationList={invitationList} onSelect={onScholarshipSelectionHandle} />
            ) : null}
          </EuiFlexItem>

          <EuiFlexItem className="invitation-invite" grow={false}>
            <EuiFlexGroup direction="row" gutterSize="m" justifyContent="center">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={onCancelHandler} isDisabled={isLoading}>
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  type="primary"
                  onClick={inviteScholarsips}
                  fullWidth={false}
                  isDisabled={!isAnySelected}
                  isLoading={isLoading}
                  fill
                >
                  Invite scholarship providers to Scholar's App
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal>
  );
};

export default DonorsInvitationModal;
