import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer } from '@elastic/eui';
import DateRangeField from '@sharedComponents/formElements/combined/DateRangeField';
import CheckboxField from '@sharedComponents/formElements/simpleFields/CheckboxField';
import { getValueByDottedPath } from '@sharedComponents/formElements/util';

function ScholarshipDates({ watch, rowProps }) {
  const { isDeadlineExpired, isDeadlineUncertain } = watch(['isDeadlineExpired', 'isDeadlineUncertain']);

  const { errors } = useFormContext();

  const fieldNames: [string, string] = ['data.startDate', 'deadlineDate'];

  const [dateFromFieldName, dateToFieldName] = fieldNames;
  const dateFromError = getValueByDottedPath<FieldError>(dateFromFieldName, errors);
  const dateToError = getValueByDottedPath<FieldError>(dateToFieldName, errors);

  const isInvalid = !!dateFromError?.message || !!dateToError?.message || false;
  return (
    <EuiFormRow
      label={'Start Date and Deadline' + (!isDeadlineUncertain ? ' *' : '')}
      {...rowProps}
      isInvalid={isInvalid}
      error={dateToError?.message || dateFromError?.message || ''}
    >
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <DateRangeField
            fieldName={fieldNames}
            fieldProps={{
              disabled: isDeadlineUncertain
            }}
          />
          <EuiSpacer size="xs" />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="none">
            <EuiFlexItem>
              <CheckboxField fieldName="isDeadlineUncertain" fieldProps={{ label: 'Uncertain' }} />
            </EuiFlexItem>
            <EuiFlexItem>
              {!isDeadlineUncertain && isDeadlineExpired ? (
                <EuiFlexGroup justifyContent="center">
                  <EuiFlexItem>
                    <div style={{ position: 'relative' }}>
                      <EuiBadge color="warning" style={{ position: 'absolute', top: '-72px', left: '131px' }}>
                        Expired
                      </EuiBadge>
                    </div>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  );
}

export default ScholarshipDates;
