import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiComboBox, EuiComboBoxProps, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui';
import useDebounce from '@sharedComponents/hooks/useDebounce';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

interface LookupFieldOption {
  label: string;
  disabled?: boolean;
  // ... plus any object returned from autocomplete endpoint
}

const placeholderOption = {
  // That's quite hacky way to show placeholder before user started typing
  label: 'Start typing to activate suggestions.',
  disabled: true
};

/** Wrapped EuiComboBox with autocomplete to have support for hook-form context and inbound formRow rendering */
export default function LookupField({
  lookupFn,
  fieldName,
  fieldProps,
  customOptionRenderer,
  rowProps,
  searchWithEmptyValue
}: {
  lookupFn: (val: string) => Promise<Array<any>>; // could pass autocomplete type in <T>
  fieldName: string;
  fieldProps?: Partial<EuiComboBoxProps<string>>;
  customOptionRenderer?: (option: any, searchValue: any) => JSX.Element;
  rowProps?: FormRowProps['rowProps'];
  searchWithEmptyValue?: boolean;
}) {
  const { register, errors, control } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<LookupFieldOption[]>([placeholderOption]);

  const [searchValue, setInputValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(searchValue, 500);

  // EuiComboBox has to have labels. We assume that any entry returned from autocomplete has name, so we use it as label
  const transformIntoEuiComboBox = entry => ({
    label: entry.name || '',
    ...entry
  });

  useEffect(() => {
    if (searchWithEmptyValue || debouncedValue.length) {
      setLoading(true);
      setOptions([]);

      lookupFn(debouncedValue).then(values => {
        setLoading(false);

        if (values.length) {
          setOptions(values.map(transformIntoEuiComboBox));
        } else {
          setOptions([]);
        }
      });
    }
  }, [debouncedValue]);

  const renderOption = (option, searchValue) => {
    if (customOptionRenderer) {
      return customOptionRenderer(option, searchValue);
    }

    const { label, disabled } = option;
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>{!disabled ? <EuiHighlight search={searchValue}>{label}</EuiHighlight> : label}</EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const field = (
    <Controller
      control={control}
      key={fieldName}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        // it might be that this field is used to render previously saved records and 'value' retrieved by react-hook-form will be represented in internal format. Lets check for this and prepare data if needed
        if (value && !isArray(value) && !value.label) {
          value = [transformIntoEuiComboBox(value)];
        } else if (value && value.label) {
          // We have to transform data here to keep consistency with legacy data format
          value = [value];
        }

        return (
          <EuiComboBox
            options={options}
            isLoading={isLoading}
            selectedOptions={value || undefined}
            onSearchChange={setInputValue}
            renderOption={renderOption}
            onChange={values => {
              onChange(values?.length ? values[0] : values);
            }}
            inputRef={register}
            isInvalid={isInvalid}
            {...fieldProps}
            // forcing asPlainText for any sigleSelection
            {...{ singleSelection: { asPlainText: true } }}
            async
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
