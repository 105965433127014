import { useMutation } from 'react-query';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'approveForm';

// mutation to upsert
const useMutateApproveForm = () => {
  const scholarshipClient = useScholarshipClient();

  return {
    ...useMutation<
      any, // returned result result by our api
      Error, // error type
      {
        formId: number;
      }
      // parameters accepted by mutation
    >(({ formId }) => scholarshipClient.approveForm(formId))
  };
};

const approveFormQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useMutateApproveForm };
export default approveFormQuery;
