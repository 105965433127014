import React from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexItem } from '@elastic/eui';
import { ComboboxField, NumberField, PhoneField, TextField, MaskedTextField } from '@sharedComponents/formElements';
import thousandsMask from '@sharedComponents/masks/thousandsMask';

function DonorFormFields() {
  const { watch } = useFormContext();

  const registrationSource = watch('data.source');

  return (
    <>
      <EuiFlexItem>
        <TextField
          fieldName={'data.name'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'First name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.lastName'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Last name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <PhoneField />
      </EuiFlexItem>
      <EuiFlexItem>
        <NumberField
          fieldName={'data.noOfScholarships'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Number of scholarships awarded per year', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <MaskedTextField
          fieldName={'data.awardAmount'}
          mask={thousandsMask}
          fieldProps={{ fullWidth: true, prepend: '$', autoComplete: 'off' }}
          rowProps={{ label: 'Amount disbursed per year', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <ComboboxField
          fieldName={'data.source'}
          options={['Social Media', 'Google', 'Friend', 'Email', 'Online Ad', 'Other']}
          fieldProps={{ fullWidth: true, placeholder: 'Select one', singleSelection: true }}
          rowProps={{ label: 'How did you hear about us? *', fullWidth: true }}
        />
        {registrationSource === 'Other' ? (
          <TextField
            fieldName={'data.source_other'}
            fieldProps={{ fullWidth: true }}
            rowProps={{ label: 'Please specify how did you hear about us', fullWidth: true }}
          />
        ) : null}
      </EuiFlexItem>
    </>
  );
}

DonorFormFields.validationRules = yup.object().shape({
  data: yup.object().shape({
    name: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    phone: yup.string().label('Phone').required(),
    noOfScholarships: yup.number().label('Field'),
    awardAmount: yup.number().label('Amount'),
    source: yup.string().label('Source').required(),
    source_other: yup.string().label('Source')
  })
});

export default DonorFormFields;
