import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilState, useResetRecoilState } from 'recoil';

import * as Sentry from '@sentry/react';
import { isLocalStorageSupported } from '@sharedComponents/base/getLocalStorage';
import { identify } from '@sharedComponents/base/track';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import UserModel from '@sharedContract/UserModel';

import { authSelector } from './loggedInUserSelectors';
import { LoggedInUserStateType } from '@sharedComponents/atoms/loggedInUserState';

function configureSentryUser(user?: UserModel | null) {
  Sentry.configureScope(scope =>
    scope.setUser(
      user
        ? {
            id: user.id.toString(),
            email: user.name
          }
        : null
    )
  );
}

function configureHotJarUser(user?: UserModel | null) {
  try {
    const userId = user?.id.toString();
    if (window && 'hj' in window) {
      if (userId) {
        window.hj('identify', userId, {
          email: user?.name,
          role: user?.type
        });
      } else {
        window.hj('identify', null);
      }
    }
  } catch (e) {
    // do nothing, in local env hj is not defined
  }
}

export function useAuth() {
  const [authState, setAuthState] = useRecoilState<LoggedInUserStateType>(authSelector);
  const resetAuth = useResetRecoilState(authSelector);

  const router = useRouter();
  const queryClient = useQueryClient();
  const { user, jwt } = authState;

  useEffect(() => {
    configureSentryUser(user);

    if (user?.name) {
      identify(user.name); // hubspot
    }
  }, [user]);

  useEffect(() => configureHotJarUser(user), [user]);

  const loginExecute = (jwt: string, user: UserModel) => {
    setAuthState({ user, jwt });
  };

  const logoutExecute = () => {
    if (isLocalStorageSupported()) {
      // now clearing the localStorage
      localStorage.clear();
    }

    // Set history to the default tab
    if (router) {
      router.push('');
    }

    queryClient.getQueryCache().clear();

    resetAuth();
  };

  return {
    isLoggedIn: !!jwt,
    user: user,
    jwt: jwt,
    loginExecute,
    logoutExecute
  };
}
