import { FieldError, useFormContext, Controller } from 'react-hook-form';

import { EuiFieldNumber, EuiFieldNumberProps } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/** Wrapped EuiFieldNumber to have support for hook-form context and inbound formRow rendering */
export default function NumberField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: EuiFieldNumberProps;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value, name, ref }) => (
        <EuiFieldNumber
          value={value || ''}
          onChange={e => onChange(parseFloat(e.target.value))}
          name={name}
          inputRef={ref}
          isInvalid={isInvalid}
          {...fieldProps}
        />
      )}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
