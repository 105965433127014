export default [
  'Active reserve',
  'Active wartime',
  'Disabled veteran',
  'Inactive reserve',
  'No military service',
  'Orphan of veteran',
  'Relative of veteran',
  'Retired military',
  'Spouse of veteran',
  'Veteran'
];
