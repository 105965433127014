import { filter } from 'lodash';
import moment from 'moment';

import BasicModal from '@donors/components/BasicModal';
import {
  EuiAccordion,
  EuiBadge,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiText
} from '@elastic/eui';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import { MailingModelShape } from '@sharedComponents/models';

import getMailingPricing from '../CreateMailing/util/getMailingPricing';
import mailingQuery from '../queries/mailingQuery';
import ConversionReport from './Mailing/ConversionReport';

const Mailing = ({ mailing }: { mailing: MailingModelShape }) => {
  const createModal = useModalCreate();
  const { mutate: resendMailing, isLoading } = mailingQuery.useResendMailing();

  let mailingStatus;

  if (!mailing.service_response) {
    mailingStatus = <EuiBadge color="primary">Awaiting for response</EuiBadge>;
  } else if (mailing.service_response?.errors === false) {
    mailingStatus = <EuiBadge color="success"> Success</EuiBadge>;
  } else {
    mailingStatus = (
      <EuiFlexGroup direction="row">
        <EuiFlexItem grow={false}>
          <EuiBadge color="warning">Has errors</EuiBadge>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiLink
            onClick={() => {
              createModal(({ closeModal }) => {
                return (
                  <BasicModal closeModal={closeModal} title={'Raw LOB response'}>
                    <div style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'auto' }}>
                      <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                        {JSON.stringify(mailing.service_response, null, 2)}
                      </pre>
                    </div>
                  </BasicModal>
                );
              });
            }}
          >
            Watch service response
          </EuiLink>{' '}
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  let mailingConversions;

  if (mailing.conversions && mailing.conversions.length) {
    mailingConversions = <ConversionReport conversions={mailing.conversions} />;
  } else {
    mailingConversions = <EuiText>No conversion redirects are yet collected.</EuiText>;
  }

  const handleResend = () => {
    createModal(({ closeModal }) => {
      const closeModalHandler = (isConfirm?: boolean) => {
        closeModal();

        if (isConfirm) {
          resendMailing(mailing.id);
        }
      };

      const recipients = filter(mailing.recipients, rec => rec.is_selected_for_sending);
      return (
        <BasicModal closeModal={closeModalHandler} title={'Want to resend this mailing?'} isConfirmation={true}>
          <EuiFlexGroup direction="column" responsive={false}>
            <EuiFlexItem>{recipients.length ? recipients.length : 0} recipients will be used</EuiFlexItem>
          </EuiFlexGroup>
        </BasicModal>
      );
    });
  };

  const resendAction =
    !mailing.service_response && moment().isAfter(moment(mailing.created_at).add(1, 'day')) ? (
      <EuiButton onClick={handleResend} size="s" color="warning" isLoading={isLoading}>
        Resend
      </EuiButton>
    ) : null;

  return (
    <EuiFlexGroup direction="column" responsive={false}>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Status: </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiFlexGroup responsive={false}>
              <EuiFlexItem grow={false}>{mailingStatus}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Created at: </EuiFlexItem>
          <EuiFlexItem grow={3}>
            {moment(mailing.created_at).toLocaleString()} ({moment(mailing.created_at).fromNow()})
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Total recipients: </EuiFlexItem>
          <EuiFlexItem grow={3}>
            {mailing.recipients.length}
            {mailing.conversions?.length > 0 ? (
              <>{` (${mailing.conversions?.length} confirmed delivery by QR codes)`}</>
            ) : null}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Internal description: </EuiFlexItem>
          <EuiFlexItem grow={3}>{mailing.description || 'Empty'}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Colored: </EuiFlexItem>
          <EuiFlexItem grow={3}>{mailing.color ? 'Yes' : 'No'}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem>Send date: </EuiFlexItem>
          <EuiFlexItem grow={3}>
            {mailing.send_date ? moment(mailing.send_date).format('YYYY-MM-DD') : 'Instant'}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>Raw recipients details: </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiAccordion id={'recipients_details'} buttonContent="Expand">
              <div style={{ maxHeight: 600, overflowY: 'auto' }}>
                <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                  {JSON.stringify(mailing.recipients, null, 2)}
                </pre>
              </div>
            </EuiAccordion>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={1}>
            <EuiText size="m">Predicted price:</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>{getMailingPricing(mailing)}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{resendAction}</EuiFlexItem>
      <EuiFlexItem>
        <EuiHorizontalRule />
      </EuiFlexItem>
      <EuiFlexItem>
        {mailing.isHTMLtemplateUsed ? (
          <>{mailingConversions}</>
        ) : (
          <EuiText>Conversion report unavailable for PDF mailings.</EuiText>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Mailing;
