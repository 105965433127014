import { LoginOptions, UserDataModel, UserType } from '@sharedContract//UserModel';
import UserClient from '@sharedClients/UserClient';
import { UseFormMethods } from 'react-hook-form';
import { LoadingStatus } from '@sharedComponents/hooks/useLoading';
import { ExtraSignupData } from '@sharedComponents/interfaces/UserData.interface';
import { USER_TYPE } from '@sharedComponents/constants';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export enum SIGNUP_STEP {
  LOGIN_START,
  NEW_USER_REGISTRATION,
  ADDRESS_OF_RESIDENCY,
  ABOUT_YOU,
  PARENTS_CONFIRMATION,
  STUDENT_INVITATIONS,
  DONOR_ORGANIZATION
}

export interface SignupStepProps {
  userType: USER_TYPE;
  setUserType: (type: UserType) => any;

  applicationClient: ApplicationClient;
  userClient: UserClient;

  completeSignup: (extraData?: ExtraSignupData) => Promise<any>;
  completeLogin: ({ email, password, code }: { email: string; password: string; code?: string }) => Promise<any>;

  required2FA: boolean;

  formMethods: UseFormMethods<any>;
  changeStep: (step: SIGNUP_STEP) => void;
  loadingStatus: LoadingStatus;
  showPasswordResetModal: () => void;
}

// TODO:IMP: This has to be reviewed. We may have many of those useless now
export interface LoginFormProps {
  userClient: UserClient;
  applicationClient: ApplicationClient;
  onLogin: ({ jwt: string, user: UserModel, loginOptions: LoginOptions }) => void;
  userType: UserType;
  password?: string;
  confirmPassword?: string;
  //randomPassword?: string;
  help?: string | JSX.Element;
  email?: string;
  name?: string;
  phone?: string;
  state?: string;
  schoolId?: number;
  schoolName?: string;
  token?: string;
  data?: UserDataModel;
  source?: string; // registration source, passed AT LEAST during tests

  noHeader?: boolean; // compatibility with new layout approach. Will be deprecated
}
