import React, { Fragment, useState } from 'react';

import {
  EuiButton,
  EuiButtonIcon,
  EuiConfirmModal,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiOverlayMask,
  EuiTable,
  EuiTableBody,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';
import { RELATIONSHIP_STATUS, InvitationRelationship } from '@sharedComponents/interfaces/Invitation.interface';

const RelationshipsBlock = ({
  entries,
  onLegacy,
  onRemove
}: {
  entries: InvitationRelationship[];
  onLegacy: Function;
  onRemove: (relationship: InvitationRelationship) => void;
}) => {
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const [recordToRemove, setRecordToRemove] = useState<InvitationRelationship>();

  if (!entries.length) {
    return (
      <EuiEmptyPrompt
        body={
          <Fragment>
            <p>No records.</p>
          </Fragment>
        }
      />
    );
  }

  const onClickRemove = (rel: InvitationRelationship) => {
    setRecordToRemove(rel);
    showDestroyModal();
  };

  const closeDestroyModal = () => {
    setIsDestroyModalVisible(false);
    setRecordToRemove(undefined);
  };

  const showDestroyModal = () => setIsDestroyModalVisible(true);

  const confirmRemoval = () => {
    closeDestroyModal();
    recordToRemove && onRemove(recordToRemove);
  };

  let destroyModal;

  if (isDestroyModalVisible) {
    destroyModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title="Remove selected record?"
          onCancel={closeDestroyModal}
          onConfirm={confirmRemoval}
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>
            <strong>{recordToRemove?.name}</strong>
            <br />
            {recordToRemove?.email}
          </p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  return (
    <>
      <EuiTable>
        <EuiTableBody>
          {entries.map((relationship: InvitationRelationship) => (
            <EuiTableRow key={htmlIdGenerator()()}>
              <EuiTableRowCell width="70%">
                <strong>{relationship.name}</strong>
                <br />
                {relationship.email}
              </EuiTableRowCell>
              <EuiTableRowCell textOnly={false}>
                <EuiFlexGroup gutterSize="s" direction="column" alignItems="stretch" responsive={false}>
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize="none" direction="row" responsive={false}>
                      <EuiFlexItem grow={7}>
                        {relationship.status === RELATIONSHIP_STATUS.LEGACY_RECORD ? (
                          <EuiToolTip
                            position="top"
                            content={`This user is not yet confirmed as your ${relationship.userType}. Click here to send invitation.`}
                          >
                            <EuiButton
                              color="primary"
                              iconType="questionInCircle"
                              size="s"
                              onClick={() => onLegacy({ ...relationship })}
                            >
                              Confirm
                            </EuiButton>
                          </EuiToolTip>
                        ) : relationship.status === RELATIONSHIP_STATUS.CONFIRMED ? (
                          <EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
                            <EuiFlexItem grow={false}>
                              <EuiIcon type="checkInCircleFilled" color="success" />
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText size="s">Approved</EuiText>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        ) : (
                          <EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
                            <EuiFlexItem grow={false}>
                              <EuiIcon type="check" color="warning" />
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText size="s">Pending</EuiText>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        )}
                      </EuiFlexItem>
                      <EuiFlexItem style={{ alignSelf: 'center' }}>
                        <EuiButtonIcon
                          iconType="minusInCircle"
                          color="danger"
                          title="Remove this record"
                          onClick={() => onClickRemove({ ...relationship })}
                          size={'s'}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiTableRowCell>
            </EuiTableRow>
          ))}
        </EuiTableBody>
      </EuiTable>
      {destroyModal}
    </>
  );
};

export default RelationshipsBlock;
