import { FormData } from '@sharedClients/types/FormDataNode';
import { GetForm } from '@sharedClients/types/IScholarshipClient';
import React, { useMemo } from 'react';
import ScholarshipList from '@sharedComponents/ScholarshipList/ScholarshipList';
import Field from '../components/Field';
import definitionHelp from './definitionHelp';
import { getAllStandardFields, StandardField } from './StandardFieldSelect';
import UserModel from '@sharedContract/UserModel';

export default function ViewForm(props: { form: GetForm; standardForm: FormData; user: UserModel }) {
  const { form, standardForm, user } = props;

  const standardFields = useMemo(() => {
    const all = getAllStandardFields(standardForm);

    const standardFields: StandardField[] = [];

    form.standardFields.forEach(id => {
      const field = all.find(field => field.id === id);

      if (field) {
        standardFields.push(field);
      }
    });

    return standardFields;
  }, [form, standardForm]);

  return (
    <div>
      <div className="pageTitle">
        <h4>{form.userType === 'applicant' ? 'Application' : 'Recommendation'} Form</h4>
        <h2>{form.name}</h2>
      </div>

      <Field label="User Group">
        <div className="userGroup">{userGroupLabels[form.userType] || '—'}</div>
      </Field>
      <Field label="Standard fields">
        <ul>
          {standardFields.map(field => (
            <li key={field.id}>{field.titlePath.map(f => f + ' – ' /* dash, not minus */).join('') + field.name}</li>
          ))}
        </ul>
      </Field>
      <Field label="Definition" help={definitionHelp}>
        <div className="data">{JSON.stringify(form.data, null, 2)}</div>
      </Field>

      {form && form.scholarships ? (
        <div>
          <h4>Scholarships</h4>

          <ScholarshipList
            scholarships={form.scholarships}
            emptyMessage="No scholarships are using this form."
            user={user}
          />
        </div>
      ) : null}
    </div>
  );
}

const userGroupLabels = {
  recommender: 'For recommenders to submit letters of recommendation',
  applicant: 'For students to submit scholarship applications'
};
