import * as yup from 'yup';

export const TranscriptSubmitModalSchema = yup.object({
  transcript_file_id: yup.number().required(),
  semester: yup.string().required(),
  name: yup.string(), // optional for admins
  email: yup.string().email().optional(), // optional for admins
  phone: yup.string().max(20).nullable() // optional for admins
});

export type TranscriptSubmitModalSchemaType = yup.InferType<typeof TranscriptSubmitModalSchema>;
