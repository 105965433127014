import { useQuery, UseQueryOptions } from 'react-query';

import { ScholarshipApplicationsDocuments } from '@sharedComponents/interfaces/Applications.interface';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'applicationsDocumentsForScholarship';

/**
 * disabled while selectedScholarshipId is null
 */
function useApplicationsDocumentsForScholarship(
  selectedScholarshipId: number | null,
  options: UseQueryOptions<ScholarshipApplicationsDocuments, Error, ScholarshipApplicationsDocuments> = {}
) {
  const applicationClient = useApplicationClient();
  const query = {
    ...useQuery<ScholarshipApplicationsDocuments, Error>(
      [CURRENT_QUERY_KEY, selectedScholarshipId], // depend cache on IDs
      () => applicationClient.getApplicationsDocumentsForScholarship(selectedScholarshipId as any),
      {
        staleTime: 1000 * 45,
        ...options,
        // if query disabled from outside - respect this, else relay on selectedScholarshipId availability
        enabled: options.enabled === false ? false : selectedScholarshipId !== null
      }
    )
  };

  return query;
}

const applicationsDocumentsForScholarshipQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useApplicationsDocumentsForScholarship
};
export default applicationsDocumentsForScholarshipQuery;
