import { useMutation, useQuery, useQueryClient } from 'react-query';

import { TranscriptsModelShape } from '@sharedComponents/models';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import { TranscriptSubmitModalSchemaType } from '@sharedComponents/schemas/TranscriptModelSchema';
import transcriptRequestsQuery from '../../../apply/src/content/Documents/queries/transcriptRequestsQuery';
import submittedTranscriptsQuery from '../../../apply/src/content/Documents/queries/submittedTranscriptsQuery';

// unique key for this query
const CURRENT_QUERY_KEY = 'transcriptForApplicant';

function useTranscriptForApplicant(applicantId: number | null) {
  const applicationClient = useApplicationClient();

  return {
    ...useQuery<TranscriptsModelShape, Error>(
      [CURRENT_QUERY_KEY, applicantId],
      () => applicationClient.getTranscriptForApplicant(applicantId ? applicantId : 0),
      {
        staleTime: 1000 * 15,
        enabled: !!applicantId
      }
    )
  };
}

// mutation to submit transcript
const useMutateTranscriptForApplicant = (applicantId: number | null) => {
  const queryClient = useQueryClient();
  const applicationClient = useApplicationClient();

  return {
    ...useMutation<
      TranscriptsModelShape, // returned result result by our api
      Error, // error type
      TranscriptSubmitModalSchemaType
      // parameters accepted by mutation
    >(data => applicationClient.submitTranscriptForApplicant(data, applicantId ? applicantId : 0), {
      onSuccess: () => {
        // invalidate requests and submitted documents
        queryClient.invalidateQueries(transcriptRequestsQuery.QUERY_KEY);
        queryClient.invalidateQueries(submittedTranscriptsQuery.QUERY_KEY);
      }
    })
  };
};

const transcriptForApplicantQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useTranscriptForApplicant,
  useMutateTranscriptForApplicant
};

export default transcriptForApplicantQuery;
