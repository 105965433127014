import { useMutation } from 'react-query';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'requestFormReview';

// mutation to upsert
const useMutateRequestFormReview = () => {
  const scholarshipClient = useScholarshipClient();

  return {
    ...useMutation<
      any, // returned result result by our api
      Error, // error type
      {
        formId: number;
        data: { donorId: number; superDonorId: number; standardDonorsIds: number[]; deadline: string };
      }
      // parameters accepted by mutation
    >(({ formId, data }) => scholarshipClient.requestFormReview(formId, data))
  };
};

const requestFormReviewQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useMutateRequestFormReview };
export default requestFormReviewQuery;
