import { EuiFlexItem } from '@elastic/eui';
import { CheckboxField, TextField } from '@sharedComponents/formElements';
import SelectField from './formElements/SelectField';

// TODO: predefined sets of options
export const NodeBodySelect = (
  <>
    <EuiFlexItem>
      <TextField fieldName="field" rowProps={{ label: 'ID:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <SelectField
        fieldName={'options'}
        fieldProps={{
          fullWidth: true
        }}
        rowProps={{ label: 'Options', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="required"
        rowProps={{ label: 'Is required:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="multiple"
        rowProps={{ label: 'Allow multiple:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
  </>
);
