import React from 'react';
import './Chevron.scss';

export default function ChevronRight() {
  return (
    <svg viewBox="0 0 12 12" className="Chevron" alt="Next">
      <path d="M 3 11 L 9 6  L 3 1" />
    </svg>
  );
}
