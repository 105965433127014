import { useQuery } from 'react-query';

import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { DonorModelShape } from '@sharedComponents/models';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'organization';

function useDonorOrganizations(page: number, textFilter = '') {
  const userClient = useUserClient();
  const query = {
    ...useQuery<Page<DonorModelShape>, Error>(
      [CURRENT_QUERY_KEY, page, textFilter],
      () => userClient.getDonors({ page, textFilter }),
      {
        staleTime: 1000 * 60 * 5
      }
    )
  };

  return query;
}

const organizationsQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useDonorOrganizations };
export default organizationsQuery;
