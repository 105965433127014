// a bit of hooks magic to handle modals via context on global level
import React from 'react';
import ReactDOM from 'react-dom';

type ModalContextType = {
  isModalVisible: boolean;
  createModal: (
    modalContentFn: ({ closeModal, onConfirm }: { closeModal: () => void; onConfirm?: () => void }) => JSX.Element
  ) => void;
  modalContent: JSX.Element | null;
};

let ModalContext: React.Context<ModalContextType>;

const { Provider } = (ModalContext = React.createContext<ModalContextType>({
  isModalVisible: false,
  createModal: () => {},
  modalContent: null
}));

const Modal = () => {
  const { modalContent, isModalVisible } = React.useContext<ModalContextType>(ModalContext);
  const modalSelector = document.querySelector('#modal-root');
  if (modalContent && isModalVisible && modalSelector) {
    return ReactDOM.createPortal(modalContent, modalSelector);
  }

  return null;
};

// internal hook to handle modals state
const useModalHook = (): ModalContextType => {
  const [isModalVisible, setModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<JSX.Element | null>(null);

  const createModal = (modalContentFn: ({ closeModal }) => JSX.Element) => {
    setModal(!isModalVisible);
    if (modalContentFn) {
      setModalContent(modalContentFn({ closeModal: () => setModal(false) }));
    }
  };

  return { isModalVisible, createModal, modalContent };
};

// Provider for modals related props
const ModalProvider = ({ children }) => {
  const { isModalVisible, createModal, modalContent } = useModalHook();
  return (
    <Provider value={{ isModalVisible, createModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

function useModalCreate() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalCreate must be used within a ModalProvider');
  }

  const { createModal } = context;
  return createModal;
}

export { ModalContext, ModalProvider, useModalCreate };
