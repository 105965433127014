import { differenceBy } from 'lodash';
import React from 'react';

import {
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiText,
  htmlIdGenerator
} from '@elastic/eui';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';

import { ApplicationGridDataEntry } from '../config';
import AbstractAwardStep from './AbstractAwardStep';
import SelectionWidget from './AwardStepApplicationsSelections/SelectionWidget';
import { AWARD_STEPS, AwardStepProps } from './config';

const AwardStepApplicationsSelections: AbstractAwardStep = {
  headerTitle: 'Award Selection',
  actions(changeAwardingStep, selection) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiText size="s">Total: {selection.length}</EuiText>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.AWARD_AMOUNT_SELECTION);
          }}
          isDisabled={selection.length <= 0}
          fill
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ applicationGridData, setSelection, selection }: AwardStepProps) {
    const selectionIds = selection.map(item => item.id);

    const applicationGridDataWithSelection = applicationGridData.map(item => {
      let selectionIndex = selectionIds.indexOf(item.id) + 1;

      let newItem;
      if (selectionIndex) {
        newItem = selection[selectionIndex - 1];
        newItem['num'] = selectionIndex;
      } else {
        newItem = {
          ...item
        };
      }

      return newItem;
    });

    const selectionValue = {
      selectable: (application: ApplicationGridDataEntry) =>
        !application.award_status || application.award_status === AWARD_STATUS.CANDIDATE,
      selectableMessage: () => '',
      onSelectionChange: newSelection => {
        // custom algorithm of sorting added because 'newSelection' can have items in a random order
        if (newSelection.length > selection.length) {
          let newItems = differenceBy(newSelection, selection, (el: any) => el.id);
          newSelection = [...selection, ...newItems];
        } else {
          let removedItemsIds = differenceBy(selection, newSelection, (el: any) => el.id).map(el => el.id);
          newSelection = selection.filter(el => !removedItemsIds.includes(el.id));
        }

        setSelection(newSelection);
      },
      initialSelected: applicationGridDataWithSelection.filter(item => selectionIds.includes(item.id))
    };

    const columns: EuiBasicTableColumn<ApplicationGridDataEntry>[] = [
      {
        field: 'num',
        name: '#',
        sortable: false,
        width: '50px',
        align: 'center'
      },
      {
        field: 'name',
        name: 'Name',
        sortable: false
      },
      {
        field: 'totalAverageScore',
        name: 'Total Average Score',
        sortable: false
      }
    ];

    return (
      <EuiFlexGroup direction="column" gutterSize="none" key={htmlIdGenerator()()}>
        <EuiFlexItem>
          <SelectionWidget
            selection={selection}
            applicationGridDataWithSelection={applicationGridDataWithSelection}
            setSelection={setSelection}
            totalSize={applicationGridData.length}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiInMemoryTable
            items={applicationGridDataWithSelection}
            itemId="id"
            columns={columns}
            pagination={false}
            sorting={false}
            selection={selectionValue}
            isSelectable={true}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepApplicationsSelections;
