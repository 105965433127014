import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { EuiFlexItem } from '@elastic/eui';
import {
  CheckboxField,
  ComboboxField,
  LookupField,
  PhoneField,
  SchoolLookupField,
  TextField
} from '@sharedComponents/formElements';
import allStates from '@sharedComponents/models/enumValues/states';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

function RecommenderFormFields() {
  const formMethods = useFormContext();
  const associatedToSchool = formMethods.watch('data.atSchool');
  const applicationClient = useApplicationClient();
  return (
    <>
      <EuiFlexItem>
        <TextField
          fieldName={'data.name'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'First name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.lastName'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Last name *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <PhoneField />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.occupation'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Occupation', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.atSchool'}
          fieldProps={{ label: "I'm associated with a school" }}
          rowProps={{}}
        />
      </EuiFlexItem>
      {associatedToSchool ? (
        <>
          <EuiFlexItem>
            <SchoolLookupField fieldName={'data.school'} formMethods={formMethods} isRequired />
          </EuiFlexItem>
          <EuiFlexItem>
            <LookupField
              lookupFn={val => applicationClient.getAutocomplete('colleges', val)}
              fieldName={'data.college'}
              fieldProps={{ fullWidth: true }}
              rowProps={{ label: 'College', helpText: 'Fill in either high school or college, as applicable.' }}
            />
          </EuiFlexItem>
        </>
      ) : null}
      <EuiFlexItem>
        <ComboboxField
          options={Object.keys(allStates).map(shortState => ({
            label: allStates[shortState],
            key: shortState,
            value: shortState
          }))}
          fieldName={'data.state'}
          fieldProps={{ fullWidth: true, singleSelection: true }}
          rowProps={{ label: 'State' }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.smsnofification'}
          fieldProps={{ label: 'Would you like to receive text message notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.emailNotification'}
          fieldProps={{ label: 'Would you like to receive email notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
    </>
  );
}

RecommenderFormFields.validationRules = yup.object().shape({
  data: yup.object().shape({
    name: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    phone: yup.string().label('Phone').required(),
    occupation: yup.string(),
    atSchool: yup.boolean(),
    school: yup.object().shape({
      id: yup.number(),
      name: yup.string()
    }),
    college: yup.object().shape({
      id: yup.number(),
      name: yup.string()
    }),
    state: yup.string().oneOf(Object.keys(allStates)),
    smsnofification: yup.boolean().default(true),
    emailNotification: yup.boolean().default(true)
  })
});

export default RecommenderFormFields;
