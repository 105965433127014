import { isConditionalVisible } from '../apply/fields/FormFieldConditional';
import warn from '../base/warn';
import ApplicationModel from '@sharedClients/types/ApplicationModel';

import birthDatePattern from '../apply/fields/patterns/birthDatePattern';
import emailPattern from '../apply/fields/patterns/emailPattern';
import phoneNumberPattern from '../apply/fields/patterns/phoneNumberPattern';
import { getPath, setPath } from './Application';
import { isList, isString, toList, toString } from './cast';
import counselorForm from './counselorForm';
import recommendersForm from './recommendersForm';
import urlPattern from '../apply/fields/patterns/urlPattern';
import FormDataNode from '@sharedClients/types/FormDataNode';
import fullNamePattern from '../apply/fields/patterns/fullNamePattern';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';

export interface FormPageNode extends FormNode {
  id: string;
}

/**
 * Wraps a JSON node in the form definition with utility methods.
 * Also normalizes the format a bit; e.g. expands ['a', 'b'] to {children: [{field:'a'}, {field: 'b'}]}
 *
 * Contains
 *  * children: (on e.g. sections) fields that are inside the section
 *  * columns: same as children, but should be presented as columns. each entry is the content of one column.
 *  * path: the path segment in the xpath-like expression for where to store field values
 *  * field: the final segment(s) of where to store field values as an array
 *
 * children and columns are just raw nodes, but calling getChildren and getColumns will return
 * wrapped version.
 */
export default class FormNode {
  required?: boolean | number;
  parent?: FormNode;
  field!: string;
  title?: string;
  label?: string;
  help?: string;
  type?: string;
  path!: string[] | null;
  children!: FormNode[];
  columns?: FormNode[];
  wide?: boolean;
  if?: any;
  anyOf?: [];
  allOf?: [];
  unless?: any;
  width?: number;
  options?: string | string[];
  multiple?: boolean;
  autocomplete?: string;
  isDetailsTo?: string;
  allowUnknown?: boolean;
  userType?: string; // please to deprecate
  disabled?: boolean;
  action?: string;
  value?: string; // option for actions
  config?: { [key: string]: any }; // configuration parameters for specific field types that can be set via form. Example: { min: 5, max: 10 }, for 'numberRange' field type
  freezedAfterSubmit?: boolean; // is field supposed to be disabled after application submit?
  onFormError: (e: Error) => void;

  constructor(node: any, onFormError = (e: Error) => {}, parent?: FormNode) {
    if (node.constructor === FormNode) {
      Object.assign(this, node);

      this.parent = parent;
    } else {
      if (typeof node == 'string') {
        this.field = node;
      } else if (isList(node)) {
        this.children = node;
      } else {
        node = resolveMacros(node);
        Object.assign(this, node);

        if (this.field) {
          this.field = toString(this.field, onFormError);
        }
      }

      this.parent = parent;

      if (isString(node.path)) {
        this.path = node.path ? node.path.split('/') : [];
      } else if (typeof node.path == 'number') {
        const listIndex = node.path;

        this.path = [listIndex];
      } else {
        this.path = null;
      }
    }

    // note that this must be last or it will be overwritten during object.assign
    this.onFormError = onFormError;
  }

  getParents(): FormNode[] {
    const upstairs = (node: FormNode, parents: FormNode[]) =>
      node.parent ? upstairs(node.parent, [...parents, node.parent]) : parents;

    return upstairs(this, []);
  }

  // Not sure about this case, might be some false positive records. For now only used for awarding, to detect root node condition
  isRootNode(): boolean {
    return this.getParents().length === 0 && this.getDescendants().length > 0;
  }

  getChildren(): FormNode[] {
    return toList(this.children, this.onFormError).map(child => new FormNode(child, this.onFormError, this));
  }

  getColumns(): FormNode[] {
    return toList(this.columns, this.onFormError).map(node => new FormNode(node, this.onFormError, this));
  }

  getDescendants(): FormNode[] {
    if (this.children) {
      return this.getChildren();
    } else if (this.columns) {
      return this.getColumns();
    } else {
      return [];
    }
  }

  getField(fieldId: string) {
    if (this.isField()) {
      if (this.field === fieldId) {
        return this;
      }
    } else {
      for (let child of this.getDescendants()) {
        const field = child.getField(fieldId);

        if (field) {
          return field;
        }
      }

      return null;
    }
  }

  getFieldByTitle(title: string) {
    if (this.title === title) {
      return this;
    }

    for (let child of this.getDescendants()) {
      const field = child.getFieldByTitle(title);

      if (field) {
        return field;
      }
    }
  }

  isAction() {
    return !!this.action;
  }

  isField() {
    return isString(this.field);
  }

  isRequired() {
    return this.required;
  }

  /**
   * the DOM ID to use for the input element of this field.
   */
  getInputElementId() {
    return this.getValuePath().join('_');
  }

  getConfig() {
    return this.config || {};
  }

  getValuePath(): Array<string> {
    let result = (this.parent && this.parent.getValuePath()) || [];

    if (this.path) {
      result = result.concat(this.path);
    }

    if (this.field) {
      result = result.concat(this.field.split('/'));
    }

    return result;
  }

  getValue(application: ApplicationModel, valuePath?: Array<string>) {
    return getPath(application, valuePath?.length ? valuePath : this.getValuePath());
  }

  setValue(value, application: ApplicationModel) {
    return setPath(application, this.getValuePath(), value);
  }

  getTitle() {
    return (
      this.title ||
      toList(this.children, this.onFormError)
        .map(child => child.title)
        .find(title => !!title) ||
      'n/a'
    );
  }

  getActiveActions(application: ApplicationModel) {
    return this.getAllActiveFieldNodes(application, true).filter(formNode => formNode.isAction());
  }

  getAllActiveFieldNodes(application: ApplicationModel, includeActions = false) {
    const fields: FormNode[] = [];

    const readNodes = (formNode: FormNode) => {
      if (formNode.isField() || (includeActions && formNode.isAction())) {
        if (!formNode.isConditional() || isConditionalVisible(formNode, application, this.onFormError)) {
          fields.push(formNode);
        }
      } else {
        // pages and sections and just arrays(conditioned for example), maybe something else
        if (isPageActive(formNode, application)) {
          const descendants = formNode.getDescendants();

          for (let i = 0; i < descendants.length; i++) {
            readNodes(descendants[i]);
          }
        }
      }
    };

    // start from this node
    readNodes(this);

    return fields;
  }

  /**
   * returns non-triggered counselor/recommender notifications
   * quite hardcoded, but thats how its done
   */
  hasMissingNotifications(application: ApplicationModel) {
    const recommenders = application?.recommenders || [];
    if (recommenders.length) {
      for (const recommender of recommenders) {
        if (
          recommender.email &&
          (!(application.recommenderNotificationStatus || {})[recommender.email] ||
            ((application.recommenderNotificationStatus || {})[recommender.email] || {}).status === 'unsent')
        ) {
          return true;
        }
      }
    }

    const counselorEmail = application?.counselor?.email;

    /**
     * As stated here: https://github.com/scholars-app/scholarsapp-gcp-spike/blob/e791013637575b854815d13a147757fa807d76cb/apply/src/apply/fields/FormCounselorStatus.tsx#L24
     * when application has transcript uploaded by admin or attached directly to application, we consider that transcript exists for any email entered as counselor email. This way we dont need to notify anyone
     */
    if (application?.transcript) {
      return false;
    }

    if (
      counselorEmail &&
      (!application.counselorNotificationStatus ||
        application.counselorNotificationStatus.status === 'unsent' ||
        application.counselorNotificationStatus?.counselor?.email !== counselorEmail)
    ) {
      return true;
    }

    return false;
  }

  // ? lets use getAllActiveFieldNodes instead to get all the fields?
  isMissingRequiredFields(application: ApplicationModel, skipChildren?: boolean) {
    if (this.isConditional() && !isConditionalVisible(this, application, this.onFormError)) {
      return false;
    }

    if (this.isField()) {
      const value = this.getValue(application);

      if (this.isRequired()) {
        if (this.isMultipleSelect()) {
          return this.isListMissingElements(application);
        } else if (this.type === 'pdf') {
          if (!value || value.id == null) {
            return this.field;
          }
        } else {
          const missing = isMissing(value);

          if (missing) {
            return this.field;
          }
        }
      }

      if (this.getPattern() && !isMissing(value)) {
        try {
          this.getPattern()?.correctComplete(this.getValue(application));
        } catch (e: any) {
          if (e.isUserError) {
            return this.field;
          } else {
            throw e;
          }
        }
      }

      return false;
    } else {
      if (this.type === 'list' || this.type === 'recommenders-list') {
        let requiredCount: number | undefined;

        if (this.type === 'recommenders-list' && application) {
          if (application.scholarship) {
            requiredCount = application.scholarship.minRecommenders || undefined;
          } else {
            warn(new Error('No scholarship in application'));
          }
        }

        if (this.isListMissingElements(application, requiredCount)) {
          return this.path || 'list';
        }

        const listValue = this.getValue(application);

        return toList(listValue, this.onFormError).find((value, index) => {
          return this.getListEntryNode(index)
            .getDescendants()
            .find(node => node.isMissingRequiredFields(application));
        });
      } else if (skipChildren) {
        return false;
      } else {
        const isVisible = this.type !== 'page' || isPageVisible(this, application);

        if (isVisible) {
          const descendants = this.getDescendants();

          for (let i = 0; i < descendants.length; i++) {
            const result = descendants[i].isMissingRequiredFields(application);

            if (result) {
              return result;
            }
          }

          return false;
        }
      }
    }
  }

  isMultipleSelect() {
    return this.options && this.multiple;
  }

  isListMissingElements(application: ApplicationModel, requiredCount?: number) {
    requiredCount = requiredCount || parseInt(this.required as any);

    if (!isNaN(requiredCount) && requiredCount > 0) {
      const value = this.getValue(application);

      if (value != null && typeof value != 'object') {
        warn(`Field ${this.field || this.path} should have a list value`);

        return true;
      }

      if (value == null || value.length < requiredCount) {
        return true;
      }
    }

    return false;
  }

  /**
   * Given that the current node represents a list, returns a node representing a specific item in the list.
   */
  getListEntryNode(index: number) {
    if (!this.path) {
      this.onFormError(
        new Error(
          `List with children ${this.getDescendants()
            .map(f => '"' + (f.field || f.type || f.label) + '"')
            .join(', ')} missing path attribute`
        )
      );

      this.path = ['notSet'];
    }

    return new FormNode(
      {
        children: this.children,
        columns: this.columns,
        path: index
      },
      this.onFormError,
      this
    );
  }

  /**
   * Normalizes and returns the pages
   */
  getPages(submission: any): FormPageNode[] {
    function addId(page: FormPageNode, index: number) {
      if (page.id == null) {
        page.id = (index + 1).toString();
      }

      return page;
    }

    const pages = this.getChildren()
      .filter(node => node.type === 'page')
      .map((p, idx) => addId(p as FormPageNode, idx));

    if (pages.length) {
      return pages.filter(node => {
        return isPageVisible(node, submission);
      });
    } else {
      const page = new FormNode({ type: 'page', children: [this] }, this.onFormError);

      return [page as FormPageNode].map(addId);
    }
  }

  getPattern() {
    return {
      birthdate: birthDatePattern,
      phone: phoneNumberPattern,
      email: emailPattern,
      fullName: fullNamePattern, // left here just for compatibility
      url: urlPattern
    }[this.type || ''];
  }

  isConditional() {
    return this.if || this.unless || this.allOf || this.anyOf;
  }
}

function resolveMacros(node: FormDataNode) {
  let macro: any;

  if (node.type === 'recommenders') {
    macro = recommendersForm({
      help: node.help
    });
  } else if (node.type === 'counselor') {
    macro = counselorForm();
  }

  if (macro) {
    return { ...macro, if: node.if, unless: node.unless };
  } else {
    return node;
  }
}

export function isMissing(value: any) {
  if (value == null) {
    return true;
  }

  // checkboxes can be made mandatory in order to produce a "please confirm that you..."
  if (value === false) {
    return true;
  }

  if (typeof value == 'string' && value.trim() === '') {
    return true;
  }

  return false;
}

export function isApplicationAcceptance(application: ApplicationModel | null) {
  return (
    application?.awardStatus &&
    [AWARD_STATUS.AWARD_NOTIFIED, AWARD_STATUS.AWARD_ACCEPTED, AWARD_STATUS.AWARD_REJECTED].includes(
      application.awardStatus as AWARD_STATUS
    )
  );
}

// duplicated in donor FormPage
// TODO: DRY
/**
 * Determines if page supposed to be visible at current stage of application(due to stage & conditions)
 */
function isPageVisible(page: FormNode, application: ApplicationModel) {
  if (!isPageActive(page, application)) {
    return false;
  }

  // hiding all the pages in case application is in awardAcceptance stage, show only page for awardAcceptance
  if (isApplicationAcceptance(application)) {
    if (page.isRootNode()) {
      return true;
    }

    if (page.userType === 'awarded') {
      // ? userType === 'awarded' is sucks and should be dropped
      return true;
    }

    // If this node is children of awardAcceptance node, then we should let it to be visible
    // hide regular pages tho
    return page.getParents().some(page => page.userType === 'awarded');
  }

  if (page.userType === 'awarded') {
    // ? userType === 'awarded' is sucks and should be dropped
    return false;
  }

  return true;
}

/**
 * determines if page supposed to be active (not conditionally unavailable)
 */
function isPageActive(page: FormNode, application: ApplicationModel) {
  if (page.isConditional() && !isConditionalVisible(page, application, page.onFormError)) {
    return false;
  }

  return true;
}
