import warn from '@donors/base/warn';
import { default as React, useRef, useState } from 'react';
import useCachedState from '@donors/base/useCachedState';
import './Autocomplete.scss';
import UserClient from '@sharedClients/UserClient';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export interface Option {
  id?: number;
  name: string;
  city?: string;
  state?: string;
  email?: string; // donor fields
}

export function Autocomplete({
  id,
  value,
  onChange,
  applicationClient,
  userClient,
  autocomplete,
  width,
  animationTime = 100,
  searchDelay = 200
}: {
  id: string;
  value: any;
  onChange: (value: Option | null) => void;
  applicationClient?: ApplicationClient;
  userClient?: UserClient;
  autocomplete: string;
  width?: number;
  animationTime?: number;
  searchDelay?: number;
}) {
  const [previouslySelected, setPreviouslySelected] = useCachedState<{
    [id: number]: Option;
  }>('autocomplete.selected.' + autocomplete, {});

  const [isOpen, setOpen] = useState(false);
  const [animationState, setAnimationState] = useState('closed');
  const [options, setOptions] = useState<Option[]>([]);
  const [typed, setTyped] = useState<string | undefined>(undefined);

  // these are states we don't want React to know about because they
  // don't directly affect rendering
  const searchState = useRef<{
    timer: any;
    lastSearch: string | null;
    cache: {
      [id: number]: Option;
    };
    hasFocus: boolean;
    didSelect: Option | null;
  }>({
    timer: null,
    lastSearch: null,
    cache: previouslySelected || {},
    hasFocus: false,
    didSelect: null
  });

  const valueString = typed !== undefined ? typed : (value && value.name) || '';

  async function open() {
    if (isOpen) {
      return;
    }

    setOpen(true);

    await soon();

    setAnimationState('open');
  }

  async function close() {
    if (!isOpen) {
      return;
    }

    setAnimationState('closed');

    await soon(animationTime);

    setOpen(false);
  }

  function updateOptionsFromCache({ noMatchOk } = { noMatchOk: false }) {
    if (Object.keys(searchState.current.cache).length === 0) {
      return;
    }

    const matches = getMatchesFromCache(
      searchState.current.cache,
      searchState.current.lastSearch as string,
      previouslySelected
    );

    if (matches.length || !noMatchOk) {
      setOptions(matches);

      if (matches.length && searchState.current.hasFocus) {
        open();
      }

      if (!matches.length) {
        close();
      }
    }
  }

  function onTextChange(searchString: string) {
    searchString = (searchString || '').trim();
    searchState.current.lastSearch = searchString;

    if (searchString) {
      // first, search in what we know without waiting for the server
      updateOptionsFromCache({ noMatchOk: true });

      // then, wait a while (in case the user is still typing) and
      // then ask the server
      if (!searchState.current.timer) {
        searchState.current.timer = setTimeout(() => {
          searchState.current.timer = null;

          // search against the current input; searchString might be out of date
          searchString = searchState.current.lastSearch as string;

          if (searchString) {
            if (applicationClient) {
              applicationClient
                .getAutocomplete(autocomplete as string, searchString)
                .then(options => {
                  // we use the server-side results to update the cache;
                  // what we display is always a local search across the full cache.
                  // that way we can update search results without waiting for the server
                  // (and the Postgres full text search is kind of funky;
                  // this search algorithm is more stable)
                  searchState.current.cache = addToCache(options, searchState.current.cache);

                  updateOptionsFromCache();
                })
                .catch(warn);
            } else {
              if (userClient) {
                userClient
                  .getAutocomplete(autocomplete as string, searchString)
                  .then(options => {
                    // we use the server-side results to update the cache;
                    // what we display is always a local search across the full cache.
                    // that way we can update search results without waiting for the server
                    // (and the Postgres full text search is kind of funky;
                    // this search algorithm is more stable)
                    searchState.current.cache = addToCache(options, searchState.current.cache);

                    updateOptionsFromCache();
                  })
                  .catch(warn);
              }
            }
          }
        }, searchDelay);
      }
    } else {
      setOptions([]);

      close();
    }
  }

  function selectOption(option: Option) {
    onChange(option);

    close();

    setPreviouslySelected({
      ...previouslySelected,
      [option.id as number]: option
    });

    setTyped(undefined);
  }

  function postBlur() {
    if (searchState.current.didSelect) {
      selectOption(searchState.current.didSelect);

      searchState.current.didSelect = null;
    } else if (typed !== undefined) {
      onChange(typed ? { name: typed } : null);
    }

    setTyped(undefined);
    searchState.current.hasFocus = false;
  }

  function onBlur() {
    close();
    postBlur();
  }

  function onSelectOption(option: Option) {
    if (searchState.current.hasFocus) {
      searchState.current.didSelect = option;
    } else {
      selectOption(option);
    }
  }

  return (
    <div className="FormFieldAutocomplete">
      <input
        id={id}
        type="text"
        value={valueString}
        onBlur={onBlur}
        onFocus={() => {
          onTextChange(valueString);
          open();
          searchState.current.hasFocus = true;
        }}
        onChange={e => {
          onTextChange(e.target.value);
          setTyped(e.target.value);
        }}
        maxLength={width}
        autoComplete="new-password"
        className="FormFieldAutocomplete"
      />
      {isOpen ? (
        <div className={'options ' + animationState}>
          {options.map(option => (
            <button
              key={option.id}
              className="button option invisible"
              onClick={e => {
                // this prevents submission of the surrounding form
                e.preventDefault();

                onSelectOption(option);
              }}
              onMouseDown={() => onSelectOption(option)}
            >
              <div className="label">
                {option.name} <span className="btw">{[option.city, option.state].filter(s => !!s).join(', ')}</span>
              </div>
            </button>
          ))}
        </div>
      ) : null}
      {value && value.id == null && value.name ? (
        <div className="error">
          Please select{' '}
          {autocomplete === 'schools' || autocomplete === 'colleges'
            ? 'your ' + autocomplete.substr(0, autocomplete.length - 1)
            : 'an option'}{' '}
          from the list (if possible).
        </div>
      ) : null}
    </div>
  );
}

function soon(ms?: number) {
  return new Promise(resolve => setTimeout(resolve, ms || 0));
}

function searchStringToWords(searchString: string) {
  return searchString
    .toLowerCase()
    .split(' ')
    .map(s => s.trim())
    .filter(s => !!s);
}

function addToCache(options: Option[], cache: { [key: string]: Option }) {
  cache = { ...cache };

  options.forEach(option => {
    cache[option.id as number] = option;
  });

  return cache;
}

export function getMatchesFromCache(
  cache: { [key: string]: Option },
  searchString: string,
  previouslySelected: {
    [id: number]: Option;
  }
) {
  const words = searchStringToWords(searchString);

  const options = Object.values(cache);

  const optionsNormalized: string[] = options.map(option =>
    (option.name + ' ' + (option.city || '') + (option.state || '')).toLowerCase()
  );

  let matches = options.filter((option, idx) => !words.find(word => optionsNormalized[idx].indexOf(word) < 0));

  if (!matches.length && options.length) {
    matches = fuzzySearch(searchString, options, optionsNormalized);
  }

  return sort(matches, previouslySelected);
}

export function fuzzySearch(searchString: string, options: Option[], optionsNormalized: string[]) {
  const triplets = getTriplets(searchString.toLowerCase());

  const hitCounts: number[] = [];

  triplets.forEach(triplet =>
    optionsNormalized.forEach((option, idx) => {
      if (option.indexOf(triplet) >= 0) {
        hitCounts[idx] = (hitCounts[idx] || 0) + 1;
      }
    })
  );

  const count = 20;
  const cutOff = hitCounts.map(n => (isNaN(n) ? 0 : n)).sort((a, b) => a - b)[count + 1] || 0;

  const matches: Option[] = [];

  hitCounts.forEach((count, idx) => {
    if (count >= cutOff) {
      matches.push(options[idx]);
    }
  });

  return matches;
}

function sort(
  options: Option[],
  previouslySelected: {
    [id: number]: Option;
  }
) {
  return options.slice(0).sort((a, b) => {
    const scoreA = previouslySelected[a.id as number] ? 2 : 0;
    const scoreB = previouslySelected[b.id as number] ? 2 : 0;

    return scoreB - scoreA + (a.name < b.name ? -1 : 1);
  });
}

function getTriplets(str: string) {
  const result: string[] = [];
  for (let i = 0; i < Math.max(str.length - 2, 1); i++) {
    result.push(str.substr(i, 3));
  }

  return result;
}
