import React from 'react';
import './NumberBubble.scss';

export default function NumberBubble(props) {
  return (
    <div className="NumberBubble">
      <div className="number">{props.number}</div>
    </div>
  );
}
