import './FormFieldConditional.scss';

import React from 'react';
import { SlideDown } from 'react-slidedown';

import ApplicationModel from '@sharedClients/types/ApplicationModel';

import { FormNodeProps } from '../../apply/FormNodeProps';
import { getPath } from '../../model/Application';
import FormNode from '../../model/FormNode';

export default function FormFieldConditional(props: FormNodeProps & { children: React.ReactNode }) {
  const { application, node } = props;

  return (
    <SlideDown className="FormFieldConditional" transitionOnAppear={false}>
      {isConditionalVisible(node, application, props.onFormError) ? props.children : false}
    </SlideDown>
  );
}

export function isConditionalVisible(node: FormNode, application: any, onFormError: (e: Error) => void) {
  const isNodeHasRightValue = ([field = undefined, value = undefined]: [string?, string?], negate = false) => {
    if (field && typeof field == 'string') {
      const actualValue = getFieldValue(field, {
        node,
        application,
        onFormError
      });

      let isShown: boolean;

      if (value?.length && actualValue?.length) {
        isShown = value.includes(actualValue);
      } else {
        if (actualValue != null && value != null && typeof actualValue != typeof value) {
          onFormError(new Error(`Condition based on ${field} won't work since value is the wrong type.`));
        }

        isShown = actualValue === value;
      }

      if (negate) {
        isShown = !isShown;
      }

      return isShown;
    } else {
      return true;
    }
  };

  const composalCondition = node.allOf || node.anyOf;
  // console.log('composalCondition:', composalCondition)
  if (composalCondition) {
    if (node.allOf?.length) {
      return node.allOf.every(option => isNodeHasRightValue(option));
    } else if (node.anyOf?.length) {
      return node.anyOf.some(option => {
        return isNodeHasRightValue(option);
      });
    }
  }

  const isNegate = !!node.unless;
  return isNodeHasRightValue(node.if || node.unless || [], isNegate);
}

function getFieldValue(
  field: string,
  {
    node,
    application,
    onFormError
  }: {
    node: FormNode;
    application: ApplicationModel;
    onFormError: (e: Error) => void;
  }
) {
  const parentPath = (node.parent && node.parent.getValuePath()) || [];

  const actualValue = getPath(application, parentPath.concat(field.split('/')));

  if (actualValue != null && typeof actualValue == 'object') {
    onFormError(new Error(`Condition based on ${field} won't work since it has an object value.`));
  }

  return actualValue;
}
