import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { ScholarshipModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'myOrganizationScholarships';

function useMyOrganizationScholarships(
  options: UseQueryOptions<ScholarshipModelShape[], Error, ScholarshipModelShape[]> = {}
) {
  const scholarshipClient = useScholarshipClient();

  return useQuery<ScholarshipModelShape[], Error>([CURRENT_QUERY_KEY], () => scholarshipClient.getMyScholarships(), {
    staleTime: 1000 * 90,
    retry: 0, // There migth be error instantly dropped cuz of no organization assigned, we show it
    ...options
  });
}

function useInvalidate() {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(CURRENT_QUERY_KEY);
}

const myOrganizationScholarshipEntryQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useMyOrganizationScholarships,
  useInvalidate
};

export default myOrganizationScholarshipEntryQuery;
