import { flattenDeep, get, isString } from 'lodash';

import { EuiBadge, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui';
import {
  FIELD_NAMES,
  FormStateInternals,
  getDescendants,
  getNodeType,
  hasDescendants,
  isNodeConditional
} from '@sharedComponents/interfaces/Forms.interface';
import {
  APPLICATION_NODE_TYPE,
  ApplicationFormNodeType,
  ApplicationNodeAddressType,
  ApplicationNodeListType,
  ApplicationPageType,
  ApplicationSectionType
} from '@sharedComponents/schemas/FormNodesSchema';

import PageChildrenBuilder from './FormNodeBuilder/PageChildrenBuilder';
import ParentalNodeBuilder from './FormNodeBuilder/ParentalNodeBuilder';
import { useFormNodeEdit, useFormNodeRemoval } from './hooks/useFormNodeModifiers';

export default function FormNodeBuilder({ node }: { node: Partial<ApplicationFormNodeType> & FormStateInternals }) {
  const removeNodeByPath = useFormNodeRemoval();
  const editNodeByPath = useFormNodeEdit();

  const nodeType = getNodeType(node);
  if (!nodeType) {
    // eslint-disable-next-line no-console
    console.log('wrong node:', node);
    return (
      <EuiPanel>
        <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" justifyContent="spaceBetween" gutterSize="s" responsive={false}>
              <EuiFlexItem grow={false}>
                Not supported node type. Contact administrator or edit it via raw editor.
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon iconType="documentEdit" onClick={() => editNodeByPath(node.internalPath, true)} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="s">ID: {node?.field}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    );
  }

  if (nodeType === APPLICATION_NODE_TYPE.TYPE_PAGE) {
    return <PageChildrenBuilder pageNode={node as ApplicationPageType & FormStateInternals} />;
  } else if (nodeType === APPLICATION_NODE_TYPE.TYPE_SECTION) {
    /**
     * * since sectionFormNode is a subset of applicationformnode, we cannot extend or inherit our type in this case.
     * * I wish we could somehow solve this to avoid type casting
     */
    return <ParentalNodeBuilder parentalNode={node as ApplicationSectionType & FormStateInternals} />;
  } else if (nodeType === APPLICATION_NODE_TYPE.TYPE_ADDRESS) {
    /**
     * type address is just a preset of fields in a section, but in children array instead of columns
     */
    return <ParentalNodeBuilder parentalNode={node as ApplicationNodeAddressType & FormStateInternals} />;
  } else if (nodeType === APPLICATION_NODE_TYPE.TYPE_LIST) {
    return <ParentalNodeBuilder parentalNode={node as ApplicationNodeListType & FormStateInternals} />;
  }

  // else its just a normal field
  return (
    <EuiPanel>
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="none" responsive={false}>
            <EuiFlexItem>
              {node?.label ? <EuiText size="s">Label: {node?.label}</EuiText> : null}
              <EuiText size="s">ID: {node?.field}</EuiText>
              <EuiText size="s">
                Type: {FIELD_NAMES[nodeType] ? FIELD_NAMES[nodeType] : nodeType}
                {/* {FIELD_ICONS[nodeType] ? (
                  <>
                    {' '}
                    (<EuiIcon type={FIELD_ICONS[nodeType]} size="m" />)
                  </>
                ) : null} */}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup direction="column" gutterSize="xs" justifyContent="spaceBetween" responsive={false}>
                <EuiFlexItem>
                  <EuiFlexGroup direction="row" gutterSize="xs" responsive={false}>
                    {node.required ? (
                      <EuiFlexItem grow={false}>
                        <EuiBadge color={'success'}>required</EuiBadge>
                      </EuiFlexItem>
                    ) : null}
                    {isNodeConditional(node) ? (
                      <EuiFlexItem grow={false}>
                        <EuiBadge color={'accent'}>conditional</EuiBadge>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup direction="row" gutterSize="xs" responsive={false}>
                    <EuiFlexItem></EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButtonIcon
                        iconType="documentEdit"
                        title="Edit field"
                        onClick={() => editNodeByPath(node.internalPath)}
                        iconSize="m"
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButtonIcon
                        iconType="cross"
                        color="danger"
                        title="Delete field"
                        onClick={() => removeNodeByPath(node.internalPath)}
                        iconSize="m"
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
}

/**
 * returns nested notes, with taking all possible nodes or only strict structure nodes(like only check expected by our formbuilder paths)
 * @param withDirty {Boolean} - only gather supported by form builder descendants
 */
FormNodeBuilder.getDeepDescendants = function (node: Partial<ApplicationFormNodeType>, withDirty: boolean) {
  const descendants: Partial<ApplicationFormNodeType>[] = [];

  if (withDirty) {
    const processDirty = node => {
      if (hasDescendants(node)) {
        const subNodes: any = flattenDeep(get(getDescendants(node), 'descendants', []));
        if (subNodes?.length) {
          for (const subNode of subNodes) {
            // alternative to isField
            if (isString(subNode.field)) {
              descendants.push(subNode);
            } else if (hasDescendants(subNode)) {
              processDirty(subNode);
            }
          }
        }
      }
    };

    processDirty(node);
  } else {
    // processing nodes the same way we process them via rendering
    //
    const processStrict = node => {
      const nodeType = getNodeType(node);
      if (nodeType === APPLICATION_NODE_TYPE.TYPE_PAGE || nodeType === APPLICATION_NODE_TYPE.TYPE_ADDRESS) {
        const children = get(node, 'children', []);
        if (children.length) {
          for (const subNode of children) {
            // alternative to isField
            if (isString(subNode.field)) {
              descendants.push(subNode);
            } else if (hasDescendants(subNode)) {
              processStrict(subNode);
            }
          }
        }
      } else if (nodeType === APPLICATION_NODE_TYPE.TYPE_SECTION || nodeType === APPLICATION_NODE_TYPE.TYPE_LIST) {
        const parsedColumn = ParentalNodeBuilder.getDescendants(node);
        if (parsedColumn.columns[0].length) {
          for (const subNode of parsedColumn.columns[0]) {
            // alternative to isField
            if (isString(subNode.field)) {
              descendants.push(subNode);
            } else if (hasDescendants(subNode)) {
              processStrict(subNode);
            }
          }
        }

        if (parsedColumn.columns[1].length) {
          for (const subNode of parsedColumn.columns[1]) {
            // alternative to isField
            if (isString(subNode.field)) {
              descendants.push(subNode);
            } else if (hasDescendants(subNode)) {
              processStrict(subNode);
            }
          }
        }
      }
    };

    processStrict(node);
  }

  return flattenDeep(descendants);
};
