import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText, EuiLoadingSpinner, EuiBadge } from '@elastic/eui';
import AbstractListing, { Column } from '@sharedComponents/common/AbstractListing';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { ScholarshipModelShape, UserModelShape } from '@sharedComponents/models';
import { scholarshipLink, formatFormStatus } from '@sharedComponents/utils/scholarshipUtils';
import UserModel from '@sharedContract/UserModel';
import formsToReviewQuery from './FormReview/queries/formsToReviewQuery';
import { USER_TYPE } from '@sharedComponents/constants';
import { getServiceUrl } from '@sharedClients/getServiceUrl';
import openPreviewWindow, { previewForm } from '@donors/form/openPreviewWindow';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import { GetForm } from '@sharedClients/types/IScholarshipClient';
import { FORM_CHANGE_REQUEST_STATUSES } from '@sharedComponents/interfaces/Scholarships.interface';

interface FormReviewProps extends RouteComponentProps<{}> {
  user: UserModel;
  jwt: string;
}

const FormReview = (props: FormReviewProps) => {
  let { user, jwt } = props;
  const [formPreviewingId, setFormPreviewingId] = useState<number>();
  const [previewWindow, setPreviewWindow] = useState<Window>();
  const [cachedForms, setCachedForms] = useState<{ [key: string]: GetForm }>({});
  const scholarshipClient = useScholarshipClient();
  const { data: scholarships, isLoading, isFetching } = formsToReviewQuery.useFormsToReview();

  useEffect(() => {
    if (user.type !== USER_TYPE.DONOR) {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (isFetching) {
      setCachedForms({});
    }
  }, [isFetching]);

  const preview = async formId => {
    if (formPreviewingId) {
      return;
    } else {
      setFormPreviewingId(formId);
    }

    try {
      let form = cachedForms[formId];

      if (!form) {
        form = await scholarshipClient.getForm(formId, ['changeRequest']);
        setCachedForms(data => ({ ...data, [formId]: form }));
      }

      const applyDomain = getServiceUrl('apply');

      let preview = previewWindow;

      if (!preview || !preview.window) {
        preview = await openPreviewWindow(applyDomain);

        setPreviewWindow(preview);
      }

      previewForm({
        previewWindow: preview,
        form: { ...form, standardForm: undefined } as any,
        standardForm: form.standardForm as any,
        previewReason: 'review',
        applyDomain,
        authData: { jwt, user }
      });
    } catch (err) {}
    setFormPreviewingId(undefined);
  };

  const columns: Column[] = [
    {
      id: 'name',
      label: 'Scholarship',
      width: '50%',
      render: (scholarship: ScholarshipModelShape) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiFlexItem>
              <EuiFlexGroup direction="row" gutterSize="none">
                <EuiFlexItem>
                  <EuiLink
                    href={scholarshipLink(scholarship, user as UserModelShape)}
                    onClick={e => e.preventDefault()}
                  >
                    <EuiFlexGroup alignItems={'center'}>
                      <EuiFlexItem grow={false}>
                        <EuiText size="s">{scholarship.name}</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        {formPreviewingId === scholarship.form_id && <EuiLoadingSpinner size="m" />}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            {scholarship.donor?.name ? (
              <EuiFlexItem>
                <EuiText size="xs" grow={false}>
                  {scholarship.donor?.name}
                </EuiText>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        );
      }
    },
    {
      id: 'status',
      label: 'Review Status',
      align: 'left',
      width: '15%',
      render: scholarship => {
        return scholarship.reviewStatus ? (
          <EuiBadge
            color={
              scholarship.reviewStatus === FORM_CHANGE_REQUEST_STATUSES.REVIEW_REQUESTED
                ? 'default'
                : scholarship.reviewStatus === FORM_CHANGE_REQUEST_STATUSES.CHANGES_REQUESTED
                ? 'warning'
                : 'success'
            }
          >
            {formatFormStatus(scholarship.reviewStatus)}
          </EuiBadge>
        ) : null;
      }
    },
    {
      id: 'deadline',
      label: 'Deadline',
      align: 'right',
      render: scholarship => {
        return scholarship.reviewDeadline;
      }
    }
  ];

  return (
    <PageContentWrapper>
      <>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem>
            <EuiText size="s">
              {!isLoading && !scholarships?.length
                ? 'No application forms needed for approval at this time. An email will be sent to you when the approval\n' +
                  '                  process begins.'
                : 'All scholarships for your organization for which reviews were requested.'}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            {!isLoading && !scholarships?.length ? null : (
              <AbstractListing
                isLoading={isLoading}
                columns={columns}
                records={scholarships}
                total={scholarships?.length}
                onRowClick={scholarship => preview(scholarship.form_id)}
                onPageChange={() => {}}
                pageSize={scholarships?.length}
              />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </PageContentWrapper>
  );
};

const FormReviewWithRouter = withRouter(FormReview);

export default FormReviewWithRouter;
