import './FloatingActionButton.scss';

import React from 'react';

import { EuiIcon, EuiToolTip } from '@elastic/eui';

export default function FloatingActionButton(props) {
  const { tooltip } = props;

  return (
    <div className="FloatingActionButton">
      <EuiToolTip title={tooltip} position="left">
        <button className="invisible" onClick={props.onClick}>
          <EuiIcon type="plus" color="white" />
        </button>
      </EuiToolTip>
    </div>
  );
}
