/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import PopOver from '@donors/base/components/PopOver';
import preventDefault from '@donors/base/preventDefault';
import QuestionModel from '@sharedClients/types/QuestionModel';
import Debouncer from '@donors/components/Debouncer';
import SearchContainer from '@donors/components/SearchContainer';
import React, { ChangeEvent, FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useSingleSubmit from '../useSingleSubmit';
import useCachedState from '@donors/base/useCachedState';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { HeaderProps } from '../components/Header';
import QuestionList from './QuestionList';
import './Questions.scss';
import useToast from '@sharedComponents/hooks/useToast';
import { htmlIdGenerator } from '@elastic/eui';
import { EuiPagination } from '@elastic/eui';
import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { QUESTIONS_PER_PAGE } from '@sharedComponents/constants';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

interface FormProps extends RouteComponentProps<{}>, HeaderProps {
  decreaseUnreadQuestions: () => void;
}

/**
 * Handles fetching and storing of the scholarship and the application.
 */
const componentReceivingRouter: FunctionComponent<PropsWithChildren<FormProps>> = props => {
  let { user, history, decreaseUnreadQuestions } = props;
  const client = useApplicationClient();
  const { addToast } = useToast();
  const [onError, errorMessage] = useErrorReporter();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [questions, setQuestions] = useCachedState<Page<QuestionModel>>('questions', { results: [], total: 0 });
  const [showAnswer, setShowAnswer] = useState<QuestionModel | null>(null);
  const [questionsBeingSent, setQuestionsBeingSent] = useState([] as number[]);

  const [textFilter, setTextFilter] = useCachedState<string>('questions.search', '');

  const debouncer = useRef(
    new Debouncer<
      {
        textFilter: string;
        onError: (e: Error) => void;
      },
      Page<QuestionModel>
    >(
      ({ textFilter }) => {
        setCurrentPage(0);

        textFilter = textFilter.trim();

        const filter = textFilter ? (textFilter.includes(':') ? textFilter : 'text:' + textFilter) : undefined;

        return client.getQuestions(currentPage, filter as any);
      },
      setQuestions,
      onError
    )
  );

  useEffect(() => {
    document.title = `Scholar's App - Questions`;

    debouncer.current.load({ textFilter, onError });
  }, [textFilter]);

  const refreshQuestions = () => {
    return client.getQuestions(currentPage).then(questionsObj => {
      if (!questionsObj.results?.length && currentPage) {
        // if there is no questions left on the page
        setCurrentPage(page => page - 1);
      } else {
        setQuestions(questionsObj);
      }
    });
  };

  let isFirstRefresh = useRef(true);
  useEffect(() => {
    if (!isFirstRefresh.current) {
      refreshQuestions();
    } else {
      isFirstRefresh.current = false;
    }
  }, [currentPage]);

  async function onDelete(q: QuestionModel) {
    await client.deleteQuestion(q.id);

    await refreshQuestions();

    if (q.status !== 'answered') {
      decreaseUnreadQuestions();
    }
  }

  const onClose = () => {
    setShowAnswer(null);
  };

  const onAnswer = async (answerText: string) => {
    await client
      .answerQuestion(answerText, (showAnswer as QuestionModel).id)
      .then(() => {
        if ((showAnswer as QuestionModel).status !== 'answered') {
          decreaseUnreadQuestions();
        }
      })
      .catch(onError);

    setShowAnswer(null);

    refreshQuestions().catch(onError);
  };

  const onSend = async (question: QuestionModel) => {
    setQuestionsBeingSent(ids => (ids.includes(question.id) ? ids : [...ids, question.id]));

    try {
      await client.sendQuestion(question.id);

      await refreshQuestions();

      addToast({
        id: htmlIdGenerator()(),
        color: 'success',
        title: 'Question sent to donor!'
      });
    } catch (err: any) {
      onError(err);
    }

    setQuestionsBeingSent(ids => ids.filter(id => id !== question.id));
  };

  return (
    <PageContentWrapper className="Questions">
      <div className="body">
        <div>
          {errorMessage}

          {showAnswer ? (
            <PopOver onClose={onClose}>
              <Answer question={showAnswer} onClose={onClose} onAnswer={onAnswer} />
            </PopOver>
          ) : null}

          <SearchContainer
            value={textFilter}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTextFilter(e.target.value)}
          />

          <QuestionList
            questions={questions.results}
            history={history}
            onDelete={onDelete}
            onAnswer={q => {
              setShowAnswer(q);
            }}
            onSend={onSend}
            questionsBeingSent={questionsBeingSent}
            user={user}
          />

          {questions.results?.length ? (
            <EuiPagination
              pageCount={Math.ceil(questions.total / QUESTIONS_PER_PAGE)}
              activePage={currentPage}
              onPageClick={setCurrentPage}
            />
          ) : null}
        </div>
      </div>
    </PageContentWrapper>
  );
};

function Answer({
  question,
  onClose,
  onAnswer
}: {
  question: QuestionModel;
  onClose: () => void;
  onAnswer: (answerText: string) => Promise<any>;
}) {
  const [answerText, setAnswerText] = useState(question.answer || '');
  const [singleSubmit] = useSingleSubmit();

  return (
    <form className="Answer">
      <div className="help">Answer the following question about {question.scholarship.name}:</div>

      <div className="question">{question.text}</div>

      <textarea onChange={e => setAnswerText(e.target.value)} value={answerText}></textarea>

      <div className="buttons">
        <button className="button" disabled={answerText.length < 6} onClick={singleSubmit(() => onAnswer(answerText))}>
          Submit
        </button>
        <button className="button" onClick={preventDefault(onClose)}>
          Cancel
        </button>
      </div>
    </form>
  );
}

const Questions = withRouter(componentReceivingRouter);

export default Questions;
