import React from 'react';
import './PageNavigationMobile.scss';
import ChevronRight from './ChevronRight';
import ChevronLeft from './ChevronLeft';

export default function PageNavigation(props) {
  const { pages, onPageClick, currentPage, previousPage, nextPage } = props;

  const showWarningForPage = props.showWarningForPage || (() => false);

  return (
    <div className={'PageNavigationMobile' + (props.hideShadow ? ' no-shadow' : '')}>
      <div className="button-container">
        {previousPage ? (
          <button
            className="button invisible"
            title={'Previous: ' + previousPage.getTitle()}
            onClick={() => onPageClick(previousPage)}
          >
            <div className="label">
              <ChevronLeft />
            </div>
          </button>
        ) : null}
      </div>

      <div className="bubbles">
        {pages.map((page, i) => (
          <div
            key={i}
            onClick={() => onPageClick(page)}
            className={
              'page' +
              (currentPage && page.id === currentPage.id ? ' active' : '') +
              (showWarningForPage(page) ? ' warning' : '')
            }
          >
            <div className="bubble" />
          </div>
        ))}
      </div>

      <div className="button-container right">
        {nextPage ? (
          <button
            className="button invisible"
            title={'Next: ' + nextPage.getTitle()}
            onClick={() => onPageClick(nextPage, { isNext: true })}
          >
            <div className="label">
              <ChevronRight />
            </div>
          </button>
        ) : null}
      </div>
    </div>
  );
}
