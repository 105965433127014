export default [
  'Baseball',
  'Basketball',
  'Cheerleading',
  'Cross Country',
  'Field Hockey',
  'Flag Football',
  'Football',
  'Golf',
  'Gymnastics',
  'Hockey',
  'Lacrosse',
  'Rowing',
  'Skiing / Snowboarding',
  'Soccer',
  'Softball',
  'Surf',
  'Swimming',
  'Tennis',
  'Track and Field',
  'Volleyball',
  'Water Polo',
  'Weightlifting',
  'Wrestling'
];
