import React, { useMemo } from 'react';
import { FormData, default as FormDataNode } from '@sharedClients/types/FormDataNode';
import './StandardFieldSelect.scss';

export default function StandardFieldSelect({
  standardFields,
  setStandardFields,
  standardForm
}: {
  standardFields: string[];
  setStandardFields: (stdFields: string[]) => void;
  standardForm: FormData;
}) {
  const fieldGroups = useMemo(() => groupFields(getAllStandardFields(standardForm)), [standardForm]);

  const setGroupSelected = (group: FieldGroup, selected: boolean) => {
    let newFieldIds = standardFields.slice(0);

    group.fields.forEach(field => {
      const isSelected = newFieldIds.includes(field.id);

      if (selected !== isSelected) {
        if (selected) {
          newFieldIds.push(field.id);
        } else {
          newFieldIds = newFieldIds.filter(id => id !== field.id);
        }
      }
    });

    setStandardFields(newFieldIds);
  };

  const isGroupSelected = (group: FieldGroup) => {
    return !group.fields.find(field => !standardFields.includes(field.id));
  };

  return (
    <div className="StandardFieldSelect">
      {fieldGroups.map(group => {
        return (
          <div className="group" key={group.id}>
            <div className="head checkbox">
              <input
                id={'group.' + group.id}
                type="checkbox"
                checked={isGroupSelected(group)}
                onChange={e => setGroupSelected(group, e.target.checked)}
              />

              <label className="name" htmlFor={'group.' + group.id}>
                {group.name}
              </label>
            </div>
            <div className="body">
              {group.fields.map(field => (
                <div className="checkbox" key={field.id}>
                  <input
                    id={'stdfld.' + field.id}
                    type="checkbox"
                    checked={standardFields.includes(field.id)}
                    onChange={e => {
                      if (e.target.checked) {
                        setStandardFields(standardFields.concat([field.id]));
                      } else {
                        setStandardFields(standardFields.filter(f => f !== field.id));
                      }
                    }}
                  />
                  <label htmlFor={'stdfld.' + field.id}>
                    {field.titlePath
                      .slice(1)
                      .map(t => t + ' – ' /* dash, not minus */)
                      .join('') + field.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export interface StandardField {
  id: string;
  name: string;
  titlePath: string[];
}

interface FieldGroup extends StandardField {
  fields: StandardField[];
}

function groupFields(fields: StandardField[]): FieldGroup[] {
  const grouped: FieldGroup[] = [];
  let currentGroup: FieldGroup;

  fields.forEach(field => {
    const groupId = field.titlePath[0] || '';

    if (!currentGroup || currentGroup.id !== groupId) {
      currentGroup = {
        id: groupId,
        name: field.titlePath[0] || groupId,
        fields: [field],
        titlePath: field.titlePath
      };
      grouped.push(currentGroup);
    } else {
      currentGroup.fields.push(field);
    }
  });

  return grouped;
}

export function getAllStandardFields(
  standardForm: FormData | FormData[],
  result?: StandardField[],
  path?: string,
  titlePath?: string[]
): StandardField[] {
  result = result || [];

  if (standardForm == null) {
    return result;
  }

  const node = standardForm as FormDataNode;

  path = (path || '') + (node.path ? node.path + '/' : '');

  titlePath = titlePath || [];

  if (typeof standardForm == 'object' && (standardForm as any).length) {
    (standardForm as any).forEach(field => (result = getAllStandardFields(field, result, path, titlePath)));
  } else if (typeof standardForm == 'string') {
    result.push({
      id: path + standardForm,
      name: camelCaseToHuman(standardForm),
      titlePath
    });
  } else if (node.field) {
    result.push({
      id: path + node.field,
      name: node.title || camelCaseToHuman(node.field),
      titlePath
    });
  }

  let childTitlePath = titlePath;

  if (node.title) {
    childTitlePath = childTitlePath.concat([node.title]);
  }

  if (node.children) {
    result = getAllStandardFields(node.children as FormData[], result, path, childTitlePath);
  }

  if (node.columns) {
    result = getAllStandardFields(node.columns as FormData[], result, path, childTitlePath);
  }

  return result;
}

export function camelCaseToHuman(str: string) {
  let res = '';

  for (let ch of str) {
    if (ch === ch.toUpperCase()) {
      res = res + ' ' + ch.toLowerCase();
    } else {
      res = res + ch;
    }
  }

  return res[0].toUpperCase() + res.substr(1);
}
