import { SyntheticEvent, useEffect, useRef, useState } from 'react';

export default function useSingleSubmit<T extends HTMLElement>(): [
  (Function) => (event: SyntheticEvent<T>) => void,
  boolean
] {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const singleSubmit = func => {
    return async e => {
      e.preventDefault();

      if (!isSubmitting) {
        setSubmitting(true);

        try {
          return await func(e);
        } finally {
          if (mounted.current) {
            setSubmitting(false);
          }
        }
      }
    };
  };

  return [singleSubmit, isSubmitting];
}
