import './ScholarshipsToInvite.scss';
import { EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui';
import { ReactComponent as CalendarBlankIcon } from '@apply/assets/icons/calendarBlank.svg';
import { ReactComponent as CurrencyCircleDollarIcon } from '@apply/assets/icons/currencyCircleDollar.svg';
import { ReactComponent as HourglassIcon } from '@apply/assets/icons/hourglass.svg';
import { ReactComponent as SelectionIcon } from '@apply/assets/icons/selected.svg';

import { cn } from '@bem-react/classname';
import moment from 'moment';
import { ScholarshipModelShape } from '@sharedComponents/models';
import { DEFAULT_DATE_FORMAT } from '@sharedComponents/config';

const blockClass = cn('ScholarshipsToInvite');
const cardBlockClass = cn('ScholarshipsToInvite-card');
export default function ScholarshipsToInvite({
  invitationList,
  onSelect
}: {
  invitationList: (ScholarshipModelShape & {
    isChecked: boolean;
  })[];
  onSelect: (id: number) => void;
}) {
  const onCardClickHandle = id => {
    onSelect(id);
  };

  const statBlock = (icon, title, description) => (
    <EuiFlexGroup direction="column" responsive={false} alignItems="stretch" gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="xs">
          <EuiFlexItem grow={false}>{icon}</EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="xs" color={'#98A2B3'}>
              {title}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText size="s" color={'#000000'} className="eui-textNumber" style={{ paddingLeft: 4 }}>
          {description}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const iconsColor = '#D3DAE6';
  return (
    <EuiFlexGrid columns={2} className={blockClass()}>
      {invitationList.map(scholarshipData => (
        <EuiFlexItem key={`${scholarshipData.id}`}>
          <EuiPanel
            paddingSize="none"
            className={cardBlockClass({
              isActive: scholarshipData.isChecked
            })}
            onClick={e => {
              e.preventDefault();
              onCardClickHandle(scholarshipData.id);
            }}
            hasShadow={true}
          >
            <SelectionIcon width={24} height={24} className={cardBlockClass('selectionIcon')} />
            <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
              <EuiFlexItem>
                <EuiText className={cardBlockClass('name')}>{scholarshipData.name}</EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction="row" responsive={false}>
                  <EuiFlexItem>
                    {statBlock(
                      <CalendarBlankIcon width={18} height={18} fill={iconsColor} />,
                      'START DATE',
                      moment(scholarshipData.data.startDate).format(DEFAULT_DATE_FORMAT)
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {statBlock(
                      <HourglassIcon width={18} height={18} fill={iconsColor} />,
                      'DEADLINE',
                      moment(scholarshipData.deadline).format(DEFAULT_DATE_FORMAT)
                    )}
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {statBlock(
                      <CurrencyCircleDollarIcon width={18} height={18} fill={iconsColor} />,
                      'AMOUNT',
                      (scholarshipData as any).formattedAwardAmount
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      ))}
    </EuiFlexGrid>
  );
}
