/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './FormSupport.scss';
import Thumbnail from './Thumbnail';
import NodeProps from './FormNodeProps';
import './FormTranscript.scss';

export default function FormTranscript(props: NodeProps) {
  let { fullApplication, onError } = props;

  const applicationId = fullApplication.id;

  const transcript = fullApplication.transcript;

  const transcriptFileId = transcript && transcript.data && transcript.data.transcript && transcript.data.transcript.id;

  if (transcript && transcriptFileId) {
    const counselor = transcript.data.counselor || ({} as any);

    return (
      <div className="FormSection FormTranscript">
        <h2>Transcript</h2>
        {counselor.name ? (
          <div className="help">
            Submitted by {counselor.name} (<a href={'mailto:' + counselor.email}>{counselor.email}</a>,{' '}
            {counselor.phone})
          </div>
        ) : null}

        {transcriptFileId != null ? (
          <Thumbnail
            fileId={transcriptFileId}
            fileName={transcriptFileId + '' && transcript.data.transcript.name}
            applicationId={applicationId}
            onError={onError}
          />
        ) : null}
      </div>
    );
  } else {
    const counselor = fullApplication.counselor || {};
    return (
      <div className="FormSection FormTranscript">
        <h2>Transcript</h2>
        {counselor && counselor.email ? (
          <div className="message">
            The counselor ({counselor.name},<a href={'mailto:' + counselor.email}>{counselor.email}</a>,{' '}
            {counselor.phone}) has not supplied a transcript.
          </div>
        ) : (
          <div className="message">Applicant did not enter their counselor.</div>
        )}
      </div>
    );
  }
}
