import FormDataNode from '@sharedClients/types/FormDataNode';
import React from 'react';
import './Application.scss';
import { isList, toString } from './cast';
import FormFieldValuePdf from './FormFieldValuePdf';
import FormFieldValueImage from './FormFieldValueImage';
import FormFieldValueUrl from './FormFieldValueUrl';
import { getPath } from './getPath';
import NodeProps from './FormNodeProps';
import FormFieldValueRichText from '@donors/application/FormFieldValueRichText';
import FormFieldValueNumberRange from '@donors/application/FormFieldValueNumberRange';

export const noValue = '—';

export default function FormField(props: NodeProps) {
  const { node, application } = props;
  const field = node.field as string;
  const label = toString(node.label) || camelCaseToHuman(field);

  const value = getPath(application, field.split('/'));

  return (
    <div className="field">
      <label>{label}</label>
      <div className="value">{FormValueComponent({ ...props, value })}</div>
    </div>
  );
}

export function FormValueComponent(props: { node: FormDataNode; value: any }) {
  let valueComponent: JSX.Element | string | null;

  const { node, value } = props;

  if (node.type === 'pdf') {
    valueComponent = FormFieldValuePdf({ ...props } as any);
  } else if (node.type === 'image' || node.type === 'upload') {
    valueComponent = FormFieldValueImage({ ...props } as any);
  } else if (node.type === 'richText') {
    valueComponent = FormFieldValueRichText({ ...props } as any);
  } else if (node.type === 'numberRange') {
    valueComponent = FormFieldValueNumberRange({ ...props } as any);
  } else if (node.type === 'url') {
    valueComponent = FormFieldValueUrl({ ...props } as any);
  } else if (node.autocomplete) {
    valueComponent = FormFieldValue({ value: value && value.name });
  } else if (node.type === 'checkbox') {
    valueComponent = FormFieldValueCheckbox({ value });
  } else {
    valueComponent = FormFieldValue({ value });
  }

  return valueComponent;
}

function FormFieldValue({ value }): JSX.Element | string {
  const lines = fieldValueToString(value)
    .toString()
    .split('\\n')
    .filter(p => !!p);

  return lines.length === 1 ? (
    lines[0].trim()
  ) : (
    <span>
      {lines.map((line, i) => (
        <p key={i}>{line.trim()}</p>
      ))}
    </span>
  );
}

function FormFieldValueCheckbox({ value }) {
  if (value) {
    return 'yes';
  } else {
    return 'no';
  }
}

function camelCaseToHuman(str: string) {
  let res = '';

  if (!str) {
    return;
  }

  for (let ch of str) {
    if (ch === ch.toUpperCase()) {
      res = res + ' ' + ch.toLowerCase();
    } else {
      res = res + ch;
    }
  }

  return res[0].toUpperCase() + res.substr(1);
}

export function fieldValueToString(value: any): string {
  if (value == null || value.toString().trim() === '') {
    return noValue;
  } else if (typeof value == 'boolean') {
    return value ? 'yes' : 'no';
  } else if (isList(value)) {
    return value.join(', ');
  } else {
    return value.toString();
  }
}
