import { ValidationError } from '../FormFieldText';

const urlPattern = {
  placeholder: 'https://example.com/',

  correctPartial(value: string): string {
    if (value.includes('.') && !value.startsWith('http://') && !value.startsWith('https://')) {
      throw new ValidationError('Should start with http:// or https://');
    }

    return value;
  },

  correctComplete(value: string): string | null {
    if (!value.startsWith('http://') && !value.startsWith('https://')) {
      throw new ValidationError('Should start with http:// or https://');
    }

    if (value.includes(' ')) {
      throw new ValidationError('Should not include a space');
    }

    try {
      return new URL(value).toString();
    } catch (e) {
      throw new ValidationError('This does not look like an address. It should have the format http://site.com/page');
    }
  }
};

export default urlPattern;
