import { useEffect, useState } from 'react';

import { ScholarshipModelShape } from '@sharedComponents/models';

function useScholarshipDuplicateCheck(scholarshipClient, { id, name, parentScholarshipID }) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [possibleDuplicates, setPossibleDuplicates] = useState<ScholarshipModelShape[]>([]);
  useEffect(() => {
    if (name?.length > 4) {
      setLoading(true);

      scholarshipClient.dataLookup(name, 'scholarships').then(scholarships => {
        if (scholarships?.length) {
          setPossibleDuplicates(
            scholarships.filter(scholarship => scholarship.id !== id && scholarship.id !== parentScholarshipID)
          );
        } else {
          setPossibleDuplicates([]);
        }

        setLoading(false);
      });
    }
  }, [name, id]);

  return {
    isLoading,
    possibleDuplicates: isLoading ? [] : possibleDuplicates
  };
}

export default useScholarshipDuplicateCheck;
