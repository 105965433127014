import formatDate from '@sharedComponents/utils/formatDate';
import { getServiceUrl } from '@sharedClients/main';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import FormDataNode from '@sharedClients/types/FormDataNode';
import SupportModel from '@sharedClients/types/SupportModel';
import UserModel from '@sharedContract/UserModel';
import React from 'react';
import { defaultRecommenderFormId } from '@sharedComponents/Scholarship/EditScholarship';
import { openMessageWindow, postLoginMessage } from '../form/openPreviewWindow';
import { toList } from './cast';
import { FormNode } from './FormNode';
import './FormSupport.scss';
import NodeProps from './FormNodeProps';
import recommendersForm from './recommendersForm';
import Thumbnail from './Thumbnail';

/**
 * Displays recommendations of the application.
 */
export default function FormSupport(props: NodeProps) {
  let { supports, fullApplication, user } = props;

  supports = supports || [];

  const recommenderFormSections =
    (fullApplication.recommenderForm &&
      fullApplication.recommenderForm.id !== defaultRecommenderFormId &&
      getOnlySections(
        (fullApplication.recommenderForm && (fullApplication.recommenderForm.data as any)) || [],
        user
      )) ||
    undefined;

  const nonReplying = getNonReplyingRecommender(fullApplication, supports);

  return (
    <div className="FormSupport">
      {supports.length || nonReplying.length ? (
        supports
          .map((support, i) => (
            <Support {...props} key={i} support={support} i={i} recommenderFormSections={recommenderFormSections} />
          ))
          .concat(nonReplying.length ? [renderApplicantRecommenderData(nonReplying, props, 'noReply')] : [])
      ) : (
        <div className="message">No letters of recommendation.</div>
      )}
    </div>
  );
}

type SupportProps = {
  support: SupportModel;
  i: number;
  recommenderFormSections?: FormDataNode[];
};

/** Render a single recommendation */
function Support(props: NodeProps & SupportProps) {
  const { support, i, recommenderFormSections, user, jwt, fullApplication, onError } = props;

  if (!jwt || !user) {
    // the edit link needs the jwt. crash early rather than late if missing
    throw new Error('jwt or user missing');
  }

  let help: string | JSX.Element | undefined = support.submittedAt
    ? `Provided by the recommender on ${formatDate(support.submittedAt)}.`
    : undefined;

  let warn: string | JSX.Element | undefined = !support.submittedAt
    ? 'The recommender never completed this submission.'
    : undefined;

  const editLink =
    isAdmin(user) && fullApplication ? getEditLink({ application: fullApplication, support, user, jwt }) : null;

  if (editLink) {
    if (warn) {
      warn = (
        <div>
          {warn} {editLink}
        </div>
      );
    } else {
      help = (
        <div>
          {help} {editLink}
        </div>
      );
    }
  }

  const node = {
    type: 'section',
    title: 'Recommendation ' + (i + 1),
    help: help || undefined,
    warn: warn,
    wide: true,
    columns: (recommenderFormSections || []).map(section => [section]) as FormDataNode[]
  };

  const recommender =
    support.data.recommender ||
    ({} as {
      name: string;
      email: string;
      phone: string;
      occupation: string;
    });

  return (
    <div className="Support">
      {recommenderFormSections ? (
        <FormNode {...props} key={i} application={support.data} node={node} />
      ) : (
        <div className="help">
          Submitted by {recommender.name}, {recommender.occupation} (
          <a href={'mailto:' + recommender.email}>{recommender.email}</a>, {recommender.phone})
        </div>
      )}
      {fullApplication && support.data.recommendationLetter ? (
        <Thumbnail
          fileId={support.data.recommendationLetter.id}
          fileName={support.data.recommendationLetter.name}
          onError={onError}
          applicationId={fullApplication.id}
        />
      ) : null}
    </div>
  );
}

function isAdmin(user: UserModel) {
  return user && user.type === 'admin';
}

/**
 * Admins can edit supports by opening an apply window
 * where are automatically logged in as admin,
 * which gives them the permission to get and edit supports by ID.
 */
function getEditLink({ application, support, user, jwt }) {
  async function open() {
    if (!jwt || !user) {
      // benefits system tests
      throw new Error('No jwt or user');
    }

    const editLink = `${getServiceUrl('apply')}/recommend/${application.scholarship.id}/${application.id}?support=${
      support.id
    }`;

    const applyDomain = getServiceUrl('apply');
    const preview = await openMessageWindow(editLink);

    postLoginMessage(preview, applyDomain, { user, jwt });
  }

  return (
    <button className="button invisible link" onClick={open}>
      Edit
    </button>
  );
}

/** Render data the applicant supplied on a recommender */
function renderApplicantRecommenderData(recommenders, props, key) {
  return (
    <FormNode
      {...props}
      key={key}
      application={{ recommenders }}
      node={{
        ...recommendersForm,
        title: 'Waiting for',
        help: 'The applicant supplied the following contact information for recommenders who still need to submit a letter of recommendation.',
        wide: true
      }}
    />
  );
}

function getNonReplyingRecommender(fullApplication: ApplicationModel, supports: SupportModel[]) {
  return toList(fullApplication.recommenders).filter(
    recommender =>
      !supports.find(
        support =>
          support.data.recommender &&
          support.data.recommender.email === recommender.email &&
          // if the support has not been submitted, we will display it twice,
          // but that's necessary because the incomplete support might not include personal data.
          support.submittedAt
      )
  );
}

/** Strips pages from a form */
export function getOnlySections(form: FormDataNode[], user?: UserModel): FormDataNode[] {
  let result: FormDataNode[] = [];

  toList(form).forEach((node: FormDataNode) => {
    if (node.type === 'page') {
      if (node.columns) {
        toList(node.columns).forEach(column => (result = result.concat(column)));
      } else {
        result = result.concat((toList(node.children) || []) as FormDataNode[]);
      }
    } else {
      result.push(node);
    }
  });

  result = result.filter(section => isSectionVisibleToUser(section, user));

  return result;
}

function isSectionVisibleToUser(section: FormDataNode, user?: UserModel) {
  if (section && section.userType) {
    if (!user || user.type !== section.userType) {
      return false;
    }
  }

  return true;
}
