import React from 'react';
import formatDate from '@sharedComponents/utils/formatDate';
import { Link } from 'react-router-dom';

import './UserList.scss';
import UserModel, {
  CounselorUserData,
  DonorUserData,
  ApplicantUserData,
  RecommenderSchool
} from '@sharedContract/UserModel';

export default function UserList({
  users,
  showLinks = true,
  showType = false
}: {
  users: UserModel[];
  showLinks?: boolean;
  showType?: boolean;
}) {
  userListIds = users.map(u => u.id);

  return (
    <div className="UserList">
      <div className="user header">
        <div className="name">Email / Name</div>
        {showType ? <div className="type">Type</div> : null} <div className="createdAt">Created</div>
      </div>

      {users.map(user => {
        return showLinks ? (
          <Link to={`/users/${user.id}`} key={user.id} className="user">
            {renderUser(user, { showType })}
          </Link>
        ) : (
          <div key={user.id} className="user">
            {renderUser(user, { showType })}
          </div>
        );
      })}
    </div>
  );
}

function renderUser(user: UserModel, { showType }: { showType: boolean }) {
  const applicantData = user.data as ApplicantUserData;
  const counselorData = user.data as CounselorUserData;

  const schoolName =
    applicantData.school &&
    // counselor
    ((applicantData.school as RecommenderSchool).name ||
      // applicant
      (applicantData.school.school && applicantData.school.school.name));

  let state = counselorData.state || (applicantData.school && applicantData.school.state);

  if ((schoolName || '').endsWith(', ' + state)) {
    state = '';
  }

  return (
    <React.Fragment>
      <div className="name">
        <div className="line">
          <div className="realName">{user.data.name || 'No name'}</div>
          {user.type === 'counselor' && !(user.data as CounselorUserData).isVerified ? (
            <span className="unverified">not verified</span>
          ) : null}
          {user.type === 'donor' && (user.data as DonorUserData).isPremium ? (
            <span className="premium">premium</span>
          ) : null}
        </div>
        <div className="email">
          {schoolName || (user.donor && user.donor.name) || user.name || ''}
          {state ? `, ${state}` : null}
        </div>
      </div>
      {showType ? <div className="type">{user.type}</div> : null}
      <div className="createdAt" key="createdAt">
        {formatDate(user.createdAt)}
      </div>
    </React.Fragment>
  );
}

export let userListIds: number[] = [];
