const numberPattern = {
  correctPartial(value: string): string {
    if (typeof value === 'string') {
      return value.replace(' ', '').replace(/[^0-9,.]/g, '');
    } else {
      return value + '';
    }
  },

  correctComplete(value: string): number | null {
    if (typeof value !== 'number') {
      const trimmed = ((value || '') + '').replace(/ /g, '').replace(/[^0-9.]/g, '');

      if (trimmed !== '') {
        const num = parseFloat(trimmed);

        if (!isNaN(num)) {
          return num;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return value;
    }
  }
};

export default numberPattern;
