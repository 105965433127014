import { DeadlineMarkupReplaceToken } from '@donors/routes/RecurringAwardsSetup.interface';
import {
  ApplicantNameMarkupReplaceToken,
  AwardAmountMarkupReplaceToken
} from '@sharedComponents/interfaces/Applications.interface';

function getApplicantNameParser(nameExample: string) {
  const ApplicantNameParser = function (this) {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;

    // function to parse a matching string
    function tokenizeApplicantNames(eat, value, silent) {
      const tokenMatch = value.match(new RegExp(`^${ApplicantNameMarkupReplaceToken}`));

      if (!tokenMatch) {
        return false; // no match
      }

      if (silent) {
        return true;
      }

      // must consume the exact & entire match string
      return eat(ApplicantNameMarkupReplaceToken)({
        type: 'applicantNamePlugin',
        example: nameExample // configuration is passed to the renderer
      });
    }

    // // function to detect where the next applicantName match might be found
    tokenizeApplicantNames.locator = (value, fromIndex) => {
      return value.indexOf('{', fromIndex);
    };

    // define the applicantName plugin and inject it just after the existing text plugin
    tokenizers.applicantName = tokenizeApplicantNames;
    methods.splice(methods.indexOf('text'), 0, 'applicantName');
  };

  return ApplicantNameParser;
}

// receives the configuration from the parser and renders
const ApplicantNameRenderer = ({ example }) => {
  return <span>{example}</span>;
};

function getAwardAmountParser(exampleAmount) {
  const AwardAmountParser = function (this) {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;

    // function to parse a matching string
    function tokenizeApplicantAmount(eat, value, silent) {
      const tokenMatch = value.match(new RegExp(`^${AwardAmountMarkupReplaceToken}`));

      if (!tokenMatch) {
        return false; // no match
      }

      if (silent) {
        return true;
      }

      // must consume the exact & entire match string
      return eat(AwardAmountMarkupReplaceToken)({
        type: 'awardAmountPlugin',
        amount: exampleAmount // configuration is passed to the renderer
      });
    }

    // // function to detect where the next applicantName match might be found
    tokenizeApplicantAmount.locator = (value, fromIndex) => {
      return value.indexOf('{', fromIndex);
    };

    // define the applicantName plugin and inject it just after the existing text plugin
    tokenizers.awardAmount = tokenizeApplicantAmount;
    methods.splice(methods.indexOf('text'), 0, 'awardAmount');
  };

  return AwardAmountParser;
}

// receives the configuration from the parser and renders
const AwardAmountRenderer = ({ amount }) => {
  return <span>{amount}</span>;
};

const ApplicantNamePlugin = {
  name: 'applicantName',
  button: {
    label: 'Inserts applicant first and last name',
    iconType: 'user'
  },
  helpText: <div>This field will be replaced with awarded candidate full name</div>,
  formatting: {
    prefix: ApplicantNameMarkupReplaceToken,
    suffix: ''
  }
};

const AwardAmountPlugin = {
  name: 'awardAmount',
  button: {
    label: 'Inserts award amount assigned to specific applicant',
    iconType: 'number'
  },
  helpText: <div>This field will be replaced with candidate award amount</div>,
  formatting: {
    prefix: AwardAmountMarkupReplaceToken,
    suffix: ''
  }
};

// receives the configuration from the parser and renders
const DeadlineRenderer = ({ deadlineDate }) => {
  return <span>{deadlineDate}</span>;
};

const DeadlinePlugin = {
  name: 'deadlineDate',
  button: {
    label: 'Inserts date due assigned to specific repayment',
    iconType: 'calendar'
  },
  helpText: <div>This field will be replaced with deadline date</div>,
  formatting: {
    prefix: DeadlineMarkupReplaceToken,
    suffix: ''
  }
};

function getDeadlineParser(exampleDeadline) {
  const DeadlineParser = function (this) {
    const Parser = this.Parser;
    const tokenizers = Parser.prototype.inlineTokenizers;
    const methods = Parser.prototype.inlineMethods;

    // function to parse a matching string
    function tokenizeDeadline(eat, value, silent) {
      const tokenMatch = value.match(new RegExp(`^${DeadlineMarkupReplaceToken}`));

      if (!tokenMatch) {
        return false; // no match
      }

      if (silent) {
        return true;
      }

      // must consume the exact & entire match string
      return eat(DeadlineMarkupReplaceToken)({
        type: 'deadlinePlugin',
        deadlineDate: exampleDeadline // configuration is passed to the renderer
      });
    }

    // // function to detect where the next applicantName match might be found
    tokenizeDeadline.locator = (value, fromIndex) => {
      return value.indexOf('{', fromIndex);
    };

    // define the applicantName plugin and inject it just after the existing text plugin
    tokenizers.deadlineDate = tokenizeDeadline;

    methods.splice(methods.indexOf('text'), 0, 'deadlineDate');
  };

  return DeadlineParser;
}

export {
  getApplicantNameParser,
  ApplicantNameRenderer,
  getAwardAmountParser,
  AwardAmountRenderer,
  DeadlineRenderer,
  getDeadlineParser,
  ApplicantNamePlugin,
  AwardAmountPlugin,
  DeadlinePlugin
};
