import { getServiceUrl } from '@sharedClients/main';
import { FormPost } from '@sharedClients/types/FormModel';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';
import UserModel from '@sharedContract/UserModel';

// maybe we can just move form related stuff into shared and reuse those components in order to display them on donors side?
export default function openPreviewWindow(applyDomain: string) {
  return openMessageWindow(applyDomain + '/preview');
}

export function previewForm({
  form,
  standardForm,
  previewWindow,
  applyDomain,
  awardStatus,
  previewReason,
  authData
}: {
  form: FormPost;
  standardForm: FormData;
  previewWindow: Window;
  applyDomain: string;
  awardStatus?: AWARD_STATUS;
  previewReason?: string;
  authData?: { jwt: string; user: UserModel };
}) {
  applyDomain = applyDomain || getServiceUrl('apply');

  previewWindow.postMessage({ form, standardForm, awardStatus, previewReason, authData }, applyDomain);
}

export function openMessageWindow(url: string): Promise<Window> {
  if (window.location.hostname !== 'localhost' && window.location.protocol !== 'https:') {
    return Promise.reject(new Error('Replace http with https in the URL for preview to work.'));
  }

  return new Promise((resolve, reject) => {
    let timer = setTimeout(() => reject(new Error('Preview window did not respond')), 10000);

    let previewWindow: Window;

    const onMessage = e => {
      if (e.data === 'ready') {
        clearTimeout(timer);

        window.removeEventListener('message', onMessage);

        resolve(previewWindow);
      }
    };

    window.addEventListener('message', onMessage, false);

    previewWindow = window.open(url, 'preview') as Window;
  });
}

/** See LoginContains on apply */
export function postLoginMessage(toWindow, applyDomain, { user, jwt }) {
  toWindow.postMessage(['login', { user, jwt }], applyDomain);
}
