import moment, { Moment } from 'moment';
import React, { useState } from 'react';

import requestFormReviewQuery from '@donors/form/Form/queries/requestFormReviewQuery';
import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, htmlIdGenerator } from '@elastic/eui';
import { GetForm } from '@sharedClients/types/IScholarshipClient';
import { FormChangeRequestModelShape } from '@sharedComponents/models';

import DonorOrganizationSelectionStep from './FormReviewRequestConfirmationModal/steps/DonorOrganizationSelectionStep';
import DonorRolesAssigningStep from './FormReviewRequestConfirmationModal/steps/DonorRolesAssigningStep';
import ReviewDeadlineSelectionStep from './FormReviewRequestConfirmationModal/steps/ReviewDeadlineSelectionStep';

type FormReviewRequestConfirmationModalProps = {
  closeModal: () => void;
  form: GetForm;
  addToast: (toast: any) => void;
  onReviewRequested: (changeRequest: FormChangeRequestModelShape) => void;
};

export type RequestReviewStepProps = FormReviewRequestConfirmationModalProps & {
  nextStep: () => void;
  prevStep: () => void;
  selectedDonorId: string | undefined;
  setSelectedDonorId: (v) => void;
  superDonorId: number | undefined;
  setSuperDonorId: (v) => void;
  standardDonorsIds: number[];
  setStandardDonorsIds: (v) => void;
  deadline: Moment;
  setDeadline: (v) => void;
  isSubmittingRequest: boolean;
};

const REQUEST_REVIEW_STEPS = {
  DONOR_ORGANIZATION_SELECTION: DonorOrganizationSelectionStep,
  DONOR_ROLES_ASSIGNING: DonorRolesAssigningStep,
  REVIEW_DEADLINE_SELECTION: ReviewDeadlineSelectionStep
};

const REQUEST_REVIEW_STEP_KEYS = Object.keys(REQUEST_REVIEW_STEPS);

const FormReviewRequestConfirmationModal = (props: FormReviewRequestConfirmationModalProps) => {
  const { closeModal, form, addToast, onReviewRequested } = props;
  const [selectedDonorId, setSelectedDonorId] = useState<string>();
  const [superDonorId, setSuperDonorId] = useState<number>();
  const [standardDonorsIds, setStandardDonorsIds] = useState<Array<number | undefined>>([]);
  const [deadline, setDeadline] = useState<Moment>(moment().add(1, 'day').utcOffset(0));
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const CurrentStepComponent = REQUEST_REVIEW_STEPS[REQUEST_REVIEW_STEP_KEYS[currentStepIndex]];

  const { mutate: mutateRequestFormReview, isLoading: isSubmittingRequest } =
    requestFormReviewQuery.useMutateRequestFormReview();

  const submitReviewRequest = async () => {
    if (!superDonorId) {
      return;
    }

    const filteredStandardDonorsIds = standardDonorsIds.filter(el => !!el && el !== superDonorId) as number[];

    try {
      mutateRequestFormReview(
        {
          formId: form.id,
          data: {
            donorId: parseInt(selectedDonorId as string),
            superDonorId,
            standardDonorsIds: filteredStandardDonorsIds,
            deadline: deadline.toISOString()
          }
        },
        {
          onSuccess: changeRequest => {
            addToast({
              id: htmlIdGenerator()(),
              color: 'success',
              title: 'The form review request is sent!'
            });

            onReviewRequested(changeRequest);
          },
          onError: err => {
            addToast({
              id: htmlIdGenerator()(),
              color: 'danger',
              title: 'Something went wrong!'
            });
          }
        }
      );
    } catch (e) {}
  };

  const nextStep = () => {
    if (REQUEST_REVIEW_STEP_KEYS[currentStepIndex + 1]) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      submitReviewRequest();
    }
  };

  const prevStep = () => {
    if (REQUEST_REVIEW_STEP_KEYS[currentStepIndex - 1]) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  return (
    <EuiModal onClose={() => closeModal()} style={{ minWidth: 400 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Send Form for Review</EuiModalHeaderTitle>
      </EuiModalHeader>
      <CurrentStepComponent
        {...props}
        {...{
          nextStep,
          prevStep,
          selectedDonorId,
          setSelectedDonorId,
          superDonorId,
          setSuperDonorId,
          standardDonorsIds,
          setStandardDonorsIds,
          deadline,
          setDeadline,
          isSubmittingRequest
        }}
      />
    </EuiModal>
  );
};

export default FormReviewRequestConfirmationModal;
