/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete } from '@donors/base/Autocomplete';
import UserClient from '@sharedClients/UserClient';
import ButtonBar from '@donors/components/ButtonBar';
import UserModel, { CoreUserFields, CounselorUserData } from '@sharedContract/UserModel';
import React, { useState } from 'react';
import useSingleSubmit from '../useSingleSubmit';
import './AddCounselorUser.scss';
import ValidatingField, { useSubmittable } from '../components/ValidatingField';
import { validateEmail, validateRequired, validatePhone } from '@donors/components/validations';
import Field from '@donors/components/Field';
import DonorWidget from '../components/DonorWidget';
import { SelectedDonor } from '../components/DonorWidget';

export default function AddCounselorUser({
  onSave,
  onError,
  onCancel,
  userClient,
  type,
  currentUser,
  user
}: {
  onSave: (user: CoreUserFields) => any;
  onCancel: () => void;
  onError: (e: Error) => void;
  userClient: UserClient;
  type: string;
  currentUser: UserModel;
  user?: CoreUserFields;
}) {
  user = user || ({ data: {} } as CoreUserFields);

  const [name, setName] = useState<string>(user.data.name || '');
  const [lastName, setLastName] = useState<string>(user.data.lastName || '');
  const [email, setEmail] = useState<string>(user.name);
  const [phone, setPhone] = useState<string>(user.data.phone || '');
  const [seniorcount, setseniorcount] = useState<number>((user.data as CounselorUserData).seniorCount || 0);

  const [postHigh, setpostHigh] = useState<boolean>((user.data as CounselorUserData).postHigh || false);
  const [isPremium, setisPremium] = useState<boolean>((user.data as CounselorUserData).isPremium || false);

  const [data, setData] = useState<CounselorUserData>(currentUser.data as CounselorUserData);

  const [donor, setDonor] = useState<SelectedDonor>(
    user.donor && user.donor.name
      ? { isExisting: true, id: user.donor.id, name: user.donor.name || '' }
      : { isExisting: false }
  );

  const { isSubmittable, validatingFieldDefaults, setSubmitted } = useSubmittable();
  const [singleSubmit, isSubmitting] = useSingleSubmit();

  async function onSubmit(e: Event) {
    e.preventDefault();
    setSubmitted(true);
    debugger;
    if (isSubmittable()) {
      const userdata: CounselorUserData = {
        name: name || 'Unnamed',
        lastName: lastName,
        phone: phone,
        isPremium: isPremium,
        postHigh: postHigh,
        seniorCount: seniorcount,
        school: data.school
      };

      let newDonor;

      if (!donor.isExisting) {
        newDonor = await userClient.createDonor({
          name: donor!.name || ''
        });
      }

      const editedUser: CoreUserFields = {
        name: email,
        type: type,
        data: userdata,
        donor: {
          id: donor.isExisting ? (donor.id as number) : newDonor.id,
          name: donor.name
        }
      };
      return onSave(editedUser);
    }
  }

  return (
    <form onSubmit={singleSubmit(onSubmit)} className="AddCounselor">
      <div className="pageTitle">
        <h4>Add User</h4>
      </div>
      <ValidatingField
        label="Email"
        id="email"
        value={email}
        setValue={setEmail}
        validate={validateEmail}
        renderChildren={props => <input {...props} type="text" />}
        {...validatingFieldDefaults}
      />

      <ValidatingField
        label="First Name"
        id="name"
        value={name}
        setValue={setName}
        validate={validateRequired}
        renderChildren={props => <input {...props} type="text" />}
        {...validatingFieldDefaults}
      />

      <ValidatingField
        label="Last Name"
        id="lastName"
        value={lastName}
        setValue={setLastName}
        validate={validateRequired}
        renderChildren={props => <input {...props} type="text" />}
        {...validatingFieldDefaults}
      />

      <Field label="School" htmlFor="school">
        <Autocomplete
          id="school"
          value={data.school}
          onChange={option => {
            if (option && option.id != null) {
              const { id, name, state } = option;

              setData({
                ...data,
                school: { id, name: name + ', ' + state },
                state
              });
            }
          }}
          userClient={userClient}
          autocomplete="schools"
        />
      </Field>

      {currentUser.type === 'admin' ? (
        <DonorWidget
          initialValue={undefined}
          onChange={setDonor}
          onError={onError}
          user={currentUser}
          userClient={userClient}
          type={user.type}
          {...validatingFieldDefaults}
        />
      ) : null}

      <ValidatingField
        label="Phone"
        id="phone"
        value={phone}
        setValue={setPhone}
        validate={validatePhone}
        renderChildren={props => <input {...props} type="text" maxLength={13} />}
        {...validatingFieldDefaults}
      />
      <ValidatingField
        label="Number of seniors in your school"
        id="seniorCount"
        value={seniorcount}
        setValue={setseniorcount}
        renderChildren={props => <input {...props} type="number" maxLength={6} />}
        {...validatingFieldDefaults}
      />
      <div className="field">
        <div className="checkbox">
          <input id="postHigh" type="checkbox" checked={postHigh} onChange={e => setpostHigh(e.target.checked)} />
          <label htmlFor="postHigh">I'm a post-high / college counselor</label>
        </div>
      </div>

      <div className="field">
        <div className="checkbox">
          <input id="ispremium" type="checkbox" checked={isPremium} onChange={e => setisPremium(e.target.checked)} />
          <label htmlFor="ispremium">Premium account</label>
        </div>
      </div>

      <ButtonBar>
        <button className="button" disabled={isSubmitting}>
          Save
        </button>
        <button
          className="button"
          onClick={e => {
            onCancel();
            e.preventDefault();
          }}
        >
          Cancel
        </button>
      </ButtonBar>
    </form>
  );
}
