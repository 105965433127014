/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useEffect } from 'react';
import './Applications.scss';
import useCachedState from '@donors/base/useCachedState';
import USMap from '@donors/components/USMap';
import BarChart from '@donors/components/BarChart';

export default function Distributions({ applicationClient, onError }) {
  const [distributions, setDistributions] = useCachedState('distributions', []);

  useEffect(
    () => {
      getDistributions(applicationClient).then(setDistributions).catch(onError);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applicationClient]
  );

  const stateDistribution = distributions && distributions[0];
  const gpaDistribution = distributions && distributions[1];
  const satDistribution = distributions && distributions[2];

  return (
    <div>
      <div className="chart" key="state">
        <USMap distribution={stateDistribution} />
        <div className="label">State of residence</div>
      </div>
      {gpaDistribution && !isZeros(gpaDistribution) ? (
        <div className="chart" key="gpa">
          <BarChart distribution={gpaDistribution} labels={['C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-', 'A']} />
          <div className="label">GPA</div>
        </div>
      ) : null}
      {satDistribution && !isZeros(satDistribution) ? (
        <div className="chart" key="sat">
          <BarChart distribution={satDistribution} labels={['-700', '-800', '-1,000', '-1,200', '-1,400', '-1,600']} />
          <div className="label">SAT (total)</div>
        </div>
      ) : null}
    </div>
  );
}

function isZeros(array) {
  return !array.find(i => i > 0);
}

async function getDistributions(applicationClient) {
  // gpa: range is inclusive of min but exclusive of max.
  // a GPA of 4.0 is possible
  return await applicationClient.getFieldDistributions(['address/residence/state', 'gpa:1.5-4.15:8']);
}
