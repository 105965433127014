import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiFieldText, EuiFieldTextProps } from '@elastic/eui';
import { Mask } from '@sharedComponents/masks/masker';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function MaskedTextField({
  fieldName,
  mask,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  mask: Mask;
  fieldProps?: EuiFieldTextProps;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value, name, ref }) => {
        return (
          <EuiFieldText
            value={value ? mask.mask(value.toString()) : ''}
            name={name}
            inputRef={ref}
            isInvalid={isInvalid}
            {...fieldProps}
            onChange={e => {
              onChange(mask.unmask(e.target.value));
            }}
          />
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
