import { MinusIcon } from '../PlusMinusIcon';
import track from '@sharedComponents/base/track';
import React, { useState } from 'react';
import warn from '../../base/warn';
import DisappearingBanner from '../DisappearingBanner';
import Warning from '../Warning';
import './FormFieldUpload.scss';

function preventDefault(fn) {
  return e => {
    e.preventDefault();
    fn(e);
  };
}

const UploadIcon = () => (
  <svg viewBox="0 0 100 125" className="UploadIcon">
    <path d="M99.01,58.042c0,15.454-12.575,28.028-28.029,28.028c-2.767,0-5.009-2.242-5.009-5.008  c0-2.768,2.242-5.01,5.009-5.01c9.933,0,18.011-8.08,18.011-18.011c0-9.931-8.078-18.013-18.011-18.013  c-0.437,0-0.884,0.018-1.331,0.05c-2.206,0.162-4.254-1.145-5.043-3.209c-3.067-8.036-10.907-13.436-19.51-13.436  c-11.508,0-20.872,9.364-20.872,20.874c0,0.303,0.006,0.616,0.021,0.962c0.106,2.329-1.411,4.424-3.658,5.048  c-5.64,1.574-9.579,6.765-9.579,12.625c0,7.229,5.879,13.109,13.109,13.109c4.973,0,9.449-2.775,11.676-7.151l-1.707,1.113  c-2.317,1.508-5.421,0.855-6.932-1.463c-1.512-2.317-0.857-5.42,1.462-6.932l11.764-7.669c1.115-0.725,2.47-0.979,3.768-0.704  c1.3,0.274,2.439,1.055,3.163,2.166l7.669,11.766c1.512,2.316,0.857,5.421-1.46,6.932c-0.844,0.551-1.793,0.813-2.729,0.813  c-1.639,0-3.243-0.802-4.202-2.274l-0.913-1.398c-0.049,0.171-0.109,0.338-0.177,0.505C41.914,80.451,33.52,86.07,24.118,86.07  c-12.753,0-23.127-10.374-23.127-23.127c0-9.037,5.312-17.176,13.299-20.933c1.178-15.965,14.545-28.594,30.806-28.594  c6.384,0,12.51,1.931,17.72,5.583c4.106,2.882,7.411,6.66,9.693,11.052C87.259,30.849,99.01,43.1,99.01,58.042z" />
  </svg>
);

const RetryIcon = () => (
  <svg viewBox="0 0 439 439">
    <path
      d="M427.408,19.697c-7.803-3.23-14.463-1.902-19.986,3.999l-37.116,36.834C349.94,41.305,326.672,26.412,300.5,15.848
		C274.328,5.285,247.251,0.003,219.271,0.003c-29.692,0-58.052,5.808-85.08,17.417c-27.03,11.61-50.347,27.215-69.951,46.82
		c-19.605,19.607-35.214,42.921-46.824,69.949C5.807,161.219,0,189.575,0,219.271c0,29.687,5.807,58.05,17.417,85.079
		c11.613,27.031,27.218,50.347,46.824,69.952c19.604,19.599,42.921,35.207,69.951,46.818c27.028,11.611,55.388,17.419,85.08,17.419
		c32.736,0,63.865-6.899,93.363-20.7c29.5-13.795,54.625-33.26,75.377-58.386c1.52-1.903,2.234-4.045,2.136-6.424
		c-0.089-2.378-0.999-4.329-2.711-5.852l-39.108-39.399c-2.101-1.711-4.473-2.566-7.139-2.566c-3.045,0.38-5.232,1.526-6.566,3.429
		c-13.895,18.086-30.93,32.072-51.107,41.977c-20.173,9.894-41.586,14.839-64.237,14.839c-19.792,0-38.684-3.854-56.671-11.564
		c-17.989-7.706-33.551-18.127-46.682-31.261c-13.13-13.135-23.551-28.691-31.261-46.682c-7.708-17.987-11.563-36.874-11.563-56.671
		c0-19.795,3.858-38.691,11.563-56.674c7.707-17.985,18.127-33.547,31.261-46.678c13.135-13.134,28.693-23.555,46.682-31.265
		c17.983-7.707,36.879-11.563,56.671-11.563c38.259,0,71.475,13.039,99.646,39.116l-39.409,39.394
		c-5.903,5.711-7.231,12.279-4.001,19.701c3.241,7.614,8.856,11.42,16.854,11.42h127.906c4.949,0,9.23-1.807,12.848-5.424
		c3.613-3.616,5.42-7.898,5.42-12.847V36.55C438.542,28.558,434.84,22.943,427.408,19.697z"
    />
  </svg>
);

const CancelIcon = () => (
  <svg viewBox="0 0 512 512">
    <g>
      <line strokeLinecap="round" strokeMiterlimit="10" strokeWidth="90" x1="64" y1="448" x2="448" y2="64" />
      )
      <line strokeLinecap="round" strokeMiterlimit="10" strokeWidth="90" x1="64" y1="64" x2="448" y2="448" />
    </g>
  </svg>
);

const FileIcon = () => (
  <svg viewBox="0 0 482 482" className="FileIcon">
    <g>
      <path
        d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69
		c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"
      />
      <path
        d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394
		c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"
      />
      <path
        d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404
		c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223
		c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796
		c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646
		c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811
		c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748
		c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233
		c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z
		 M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282
		c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222
		C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42
		v19.875H314.892z"
      />
    </g>
  </svg>
);

export function openFile(id, { applicationClient, onError }) {
  applicationClient
    .getFileUrl(id)
    .then(({ url }) => {
      let urlWithDomain = applicationClient.baseUrl + url;

      if (!applicationClient.baseUrl.startsWith('http://localhost')) {
        urlWithDomain = 'https://docs.google.com/gview?url=' + encodeURIComponent(urlWithDomain);
      }

      window.open(urlWithDomain, '_' + id.toString());
    })
    .catch(e => {
      warn(e);
      onError(e);
    });
}

enum UPLOAD_TYPE {
  PDF,
  IMAGE,
  ANY
}

export default function FormFieldUpload({ node, application, onFieldChange, donorId, applicantId, applicationClient }) {
  const { id, name } = node.getValue(application) || {};

  const uploadType =
    node.type === 'image' ? UPLOAD_TYPE.IMAGE : node.type === 'pdf' ? UPLOAD_TYPE.PDF : UPLOAD_TYPE.ANY;

  const [isUploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<Error | null>(null);
  const [downloadError, setDownloadError] = useState<Error | null>(null);
  const [uploadField, setUploadFile] = useState(null);

  const hasValue = id != null;

  async function upload(file) {
    if (!file?.name) {
      return;
    }

    if (
      (uploadType === UPLOAD_TYPE.IMAGE &&
        !(file.name.toLowerCase().endsWith('.png') || file.name.toLowerCase().endsWith('.jpg'))) ||
      (uploadType === UPLOAD_TYPE.PDF && !file.name.toLowerCase().endsWith('.pdf'))
    ) {
      setUploadError(new Error(`Only ${uploadType === UPLOAD_TYPE.IMAGE ? 'PNG / JPG' : 'PDF'} files are supported.`));

      return;
    }

    // happens in preview
    if (!applicationClient) {
      setUploadError(new Error('Uploading not supported.'));

      return;
    }

    setUploading(true);

    try {
      const { id, name } = await applicationClient.createFile(file, {
        donorId,
        applicantId
      });

      track('application-uploadedFile');

      onFieldChange({ id, name }, node);
      setUploadError(null);
    } catch (e: any) {
      warn(e);
      setUploadError(e);
    } finally {
      setUploading(false);
    }
  }

  const onChangeHandler = async event => {
    const file = event.target.files[0];

    if (file) {
      setUploadFile(file);
      upload(file);
    }
  };

  function onRetry() {
    upload(uploadField);
  }

  function onCancel() {
    setUploadFile(null);
    setUploadError(null);
  }

  async function onOpen() {
    if (hasValue) {
      openFile(id, { applicationClient, onError: setDownloadError });
    }
  }

  function onClear(e) {
    onFieldChange(null, node);

    e.stopPropagation();
  }

  let label;

  if (isUploading) {
    label = 'Uploading...';
  } else if (hasValue) {
    label = name || 'File';
  } else {
    label = `Upload a${
      uploadType === UPLOAD_TYPE.IMAGE ? 'n image' : uploadType === UPLOAD_TYPE.PDF ? ' PDF' : ' file'
    }`;
  }

  return (
    <React.Fragment>
      {downloadError ? (
        <DisappearingBanner onHide={() => setDownloadError(null)} key="error">
          <Warning /> Could not open file: {downloadError.message}
        </DisappearingBanner>
      ) : null}
      <div className={'FormFieldUpload' + (!isUploading ? ' can-hover' : '')} key="pdf">
        {uploadError ? (
          <div className="error">
            <div className="message">{'Upload failed'}</div>
            {uploadError.message ? <div className="detail">{uploadError.message}</div> : null}

            <div className="buttons">
              <button className="button retry invisible" onClick={preventDefault(onRetry)}>
                <RetryIcon />
              </button>

              <button className="button cancel invisible" onClick={preventDefault(onCancel)}>
                <CancelIcon />
              </button>
            </div>
          </div>
        ) : (
          [
            <button className="button invisible upload-field" key="field" onClick={preventDefault(onOpen)}>
              {hasValue ? (
                <div className="clear" onClick={preventDefault(onClear)}>
                  <MinusIcon alt="Delete" />
                </div>
              ) : null}
              <React.Fragment>
                <div
                  className={
                    'icon' + (isUploading ? ' is-uploading' : '') + (hasValue && !isUploading ? ' has-value' : '')
                  }
                >
                  <UploadIcon />
                  <img className="spinner" src="/images/spinner.png" alt="" />
                  <FileIcon />
                </div>
                <div className="label">{label}</div>
              </React.Fragment>
            </button>,
            !hasValue ? <input type="file" onChange={onChangeHandler} key="input" /> : null
          ]
        )}
      </div>
    </React.Fragment>
  );
}
