import { useQuery, UseQueryOptions } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';
import { MailingVerificationResult } from '@sharedComponents/interfaces/Mailing.interface';

// unique key for this query
const CURRENT_QUERY_KEY = 'mailingAddressValidation';

const ENDPOINT = fileID => `/v3/mailings/validate/${fileID}`;

function useMailingAddressValidation(
  fileID: number,
  options: UseQueryOptions<MailingVerificationResult, Error, MailingVerificationResult> = {}
) {
  const fetcher = useFetcher();
  const skipLob = true; // hardcoded for now, skipping address verification by lob

  return useQuery<MailingVerificationResult, Error>(
    [CURRENT_QUERY_KEY, fileID],
    () => fetcher().url(ENDPOINT(fileID)).query({ skipLob: skipLob }).get(),
    {
      staleTime: 1000 * 60 * 5, // we trust this response since its file based and is being steady
      ...options
    }
  );
}

const mailingAddressValidationQuery = { useMailingAddressValidation };

export default mailingAddressValidationQuery;
