import { useQuery, UseQueryOptions } from 'react-query';

import { TranscriptsModelShape, UserModelShape } from '@sharedComponents/models';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'submittedTranscriptsQuery';

export type SubmittedTranscriptsDataType = TranscriptsModelShape & {
  applicant: UserModelShape;
};

function useCounselorDocumentsSubmitted(
  options: UseQueryOptions<SubmittedTranscriptsDataType[], Error, SubmittedTranscriptsDataType[]> = {}
) {
  const applicationClient = useApplicationClient();

  return {
    ...useQuery<SubmittedTranscriptsDataType[], Error>(
      [CURRENT_QUERY_KEY],
      () => applicationClient.getSubmittedTranscripts(),
      {
        ...options
      }
    )
  };
}

const submittedTranscriptsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useCounselorDocumentsSubmitted
};

export default submittedTranscriptsQuery;
