import './Application.scss';

import React from 'react';

import warn from '@donors/base/warn.js';
import { USER_TYPE } from '@sharedComponents/constants';

import { isList, isString } from './cast';
import FormAddress from './FormAddress';
import FormField from './FormField';
import FormFieldConditional from './FormFieldConditional';
import NodeProps from './FormNodeProps';
import FormPage, { ChildList, FormList, FormSection } from './FormPage';
import FormSupport from './FormSupport';
import FormTranscript from './FormTranscript';
import PrivateFormFieldValue from './PrivateFormFieldValue';

export function FormNode(props: NodeProps) {
  const { node, user } = props;

  if (node == null) {
    warn('Null node in application.');

    return null;
  } else if (node.if || node.unless) {
    const nodeWithoutIf = { ...node, if: undefined, unless: undefined };

    return (
      <FormFieldConditional {...props}>
        <FormNode {...props} node={nodeWithoutIf} />
      </FormFieldConditional>
    );
  }

  // considering private status of fields (T3685)
  const isHidden = user?.type === USER_TYPE.REVIEWER && node.private === true;

  if (isHidden) {
    const privateFieldTitle = node.label || node.field || node.title;

    // We only support named fields to be private
    if (privateFieldTitle) {
      return <PrivateFormFieldValue label={privateFieldTitle} />;
    }
  }

  if (isList(node)) {
    return <ChildList {...props} />;
  } else if (typeof node == 'string') {
    return <FormField {...props} node={{ field: node }} />;
  } else if (node.type === 'list' || node.type === 'recommenders-list') {
    return <FormList {...props} />;
  } else if (node.type === 'page') {
    return <FormPage {...props} />;
  } else if (node.type === 'address') {
    return <FormAddress {...props} />;
  } else if (node.type === 'recommenders') {
    return <FormSupport {...props} />;
  } else if (node.type === 'counselor') {
    return <FormTranscript {...props} />;
  } else if (node.type === 'message') {
    return null;
  } else if (isString(node.field)) {
    return <FormField {...props} />;
  } else if (node.type === 'section' || isList(node.columns) || isList(node.children)) {
    return <FormSection {...props} />;
  } else if (node.type === 'action') {
    // not displaying those
    return null;
  } else {
    warn(`Don't know what to do with node ${JSON.stringify(node)}`);

    return null;
  }
}
