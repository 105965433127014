import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import { EuiFieldTextProps, EuiIcon, EuiLink, EuiSpacer, EuiText } from '@elastic/eui';
import { FormRowProps } from '@sharedComponents/formElements/FormRow';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import useDebounce from '@sharedComponents/hooks/useDebounce';
import { scholarshipLink } from '@sharedComponents/utils/scholarshipUtils';

import useScholarshipDuplicateCheck from '../hooks/useScholarshipDuplicateCheck';

function ScholarshipNameLookup({
  rowProps,
  formMethods,
  fieldProps = {},
  scholarshipClient
}: {
  rowProps: Partial<FormRowProps['rowProps']>;
  fieldProps?: EuiFieldTextProps;
  formMethods: UseFormMethods<any>;
  scholarshipClient: any;
}) {
  // scholarship_id is a part of draft scholarship entry which shows that this draft was created FROM some existing scholarship. Means we are not threat this one as a duplicate
  const { name, id, scholarship_id } = formMethods.watch(['name', 'id', 'scholarship_id']);

  const debouncedValue = useDebounce<string>(name, 1000);

  const { isLoading, possibleDuplicates } = useScholarshipDuplicateCheck(scholarshipClient, {
    id: id,
    name: debouncedValue,
    parentScholarshipID: scholarship_id
  });

  const hasNoDuplicates = debouncedValue === name && name?.length > 5 && !isLoading && possibleDuplicates.length === 0;
  return (
    <>
      <TextField
        fieldName={'name'}
        rowProps={{ label: 'Scholarship Name *', ...rowProps }}
        fieldProps={{
          autoComplete: 'off',
          isLoading: isLoading,
          append: hasNoDuplicates ? (
            <EuiIcon type="check" color="success" />
          ) : isLoading || name?.length <= 5 || debouncedValue !== name ? (
            <EuiIcon type="dot" color="success" />
          ) : (
            <EuiIcon type="cross" color="warning" />
          ),
          ...fieldProps
        }}
      />
      {possibleDuplicates?.length === 1 ? (
        <>
          <EuiText size="s" color="warning">
            There's already a scholarship with similar name:{' '}
            <EuiLink href={scholarshipLink(possibleDuplicates[0])} target="_blank">
              {possibleDuplicates[0].name}
            </EuiLink>
            . <br />
            Check to ensure you are not creating a duplicate record.
          </EuiText>
          <EuiSpacer size="s" />
        </>
      ) : possibleDuplicates?.length > 1 ? (
        <>
          <EuiText size="s" color="warning">
            There are already scholarships with similar name:{' '}
            {possibleDuplicates.map(scholarhip => (
              <React.Fragment key={scholarhip.id}>
                <EuiLink href={scholarshipLink(scholarhip)} target="_blank">
                  {scholarhip.name}
                </EuiLink>
                {scholarhip.id === possibleDuplicates[possibleDuplicates.length - 1].id ? '.' : ', '}
              </React.Fragment>
            ))}{' '}
            <br />
            Check to ensure you are not creating a duplicate record.
          </EuiText>
          <EuiSpacer size="s" />
        </>
      ) : null}
    </>
  );
}

export default ScholarshipNameLookup;
