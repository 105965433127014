import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EuiSpacer } from '@elastic/eui';
import { ComboboxField } from '@sharedComponents/formElements';
import counties from '@sharedComponents/models/enumValues/counties';
import states from '@sharedComponents/models/enumValues/states';

import StudyLookupWidget from './StudyLookupWidget';

const collegeSelectionOptions = ['Any', 'Specific', 'State', 'County'];
const statesOptions = Object.keys(states).map(key => ({
  label: states[key],
  value: key
}));

const CollegeEligibilitySelection = ({ rowProps = {}, fieldProps = {} }: { rowProps?; fieldProps? }) => {
  const { watch, setValue } = useFormContext();

  // scholarship entry data field
  const { data } = watch(['data']);

  const { collegeMatching = 'Any', colleges = [] } = data || {};

  return (
    <>
      <ComboboxField
        fieldName={'data.collegeMatching'}
        options={collegeSelectionOptions}
        fieldProps={{ fullWidth: true, defaultValue: 'Any', isClearable: false, singleSelection: true, ...fieldProps }}
        rowProps={{
          label: 'College(s) Matching',
          ...rowProps
        }}
      />
      <EuiSpacer size="s" />
      {collegeMatching === 'Specific' ? (
        <StudyLookupWidget
          fieldName="data.colleges"
          dataType={'colleges'}
          placeholder="College Name"
          initialValues={colleges}
          onChange={entries => {
            if (entries.length) {
              const newColleges = entries.filter(entry => !!entry).map(entry => ({ id: entry.id, name: entry.name }));

              // we update whole data property, in order to have 'isDirty' status properly sync
              setValue('data', { ...data, colleges: newColleges }, { shouldDirty: true });
            }
          }}
        />
      ) : null}
      {collegeMatching === 'State' ? (
        <ComboboxField
          fieldName={'data.collegeStates'}
          options={statesOptions}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more' }}
        />
      ) : null}
      {collegeMatching === 'County' ? (
        <ComboboxField
          fieldName={'data.collegeCounties'}
          options={counties}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more' }}
        />
      ) : null}
    </>
  );
};

CollegeEligibilitySelection.schoolSelectionOptions = collegeSelectionOptions;

export default CollegeEligibilitySelection;
