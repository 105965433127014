export default [
  'Asian Folk Religion',
  'Atheist',
  'Buddhist',
  'Catholic',
  'Christian',
  'Eastern Orthodox',
  'Evangelical',
  'Hindu',
  'Jewish',
  'Mormon',
  'Muslim',
  'Non-religious',
  'Protestant',
  'Sikh'
];
