import React from 'react';

import ScholarshipToCheckModel from '@sharedClients/types/ScholarshipToCheckModel';
import formatDate from '@sharedComponents/utils/formatDate';
import { useErrorReporter } from '@donors/base/useErrorReporter';

import './ScholarshipsToCheckList.scss';
import Checkbox from '@sharedComponents/Checkbox/Checkbox';

const defaultEmptyMessage = 'No scholarships-to-check found.';

const ScholarshipsToCheckList = ({
  scholarshipsToCheck,
  scholarshipClient
}: {
  scholarshipsToCheck: ScholarshipToCheckModel[];
  scholarshipClient;
}) => {
  const [onError, errorMessage] = useErrorReporter();

  const downloadFlyer = (id: number, fileName: string) => {
    scholarshipClient.getScholarshipsToCheckFlyer(id, fileName).catch(onError);
  };

  const updateScholarship = (scholarship: ScholarshipToCheckModel) => {
    scholarshipClient.updateScholarshipToCheck(scholarship).catch(onError);
  };

  return (
    <div className="scholarships-to-check-list">
      <div className="scholarship header">
        <div className="name">Scholarship</div>
        <div className="url">URL</div>
        <div className="flyer">Flyer</div>
        <div className="created-at">Date</div>
        <div className="verified">Checked</div>
      </div>

      {scholarshipsToCheck.map(scholarship => (
        <div className="scholarship" key={scholarship.id}>
          <div className="name">{scholarship.name}</div>
          <div className="url">
            {scholarship.url && (
              <a target="_blank" rel="noopener noreferrer" href={scholarship.url}>
                Link
              </a>
            )}
          </div>
          <div className="flyer">
            {scholarship.flyer_path && (
              <button
                className="button invisible link"
                onClick={() => downloadFlyer(scholarship.id, scholarship.flyer_path)}
              >
                Download Flyer
              </button>
            )}
          </div>
          <div className="created-at">
            {formatDate(scholarship.created_at)}
            <div className="line2">{scholarship.updated_at ? 'edited ' + formatDate(scholarship.updated_at) : ''}</div>
          </div>
          <div className="verified">
            <Checkbox
              isChecked={scholarship.verified}
              onChange={() => {
                scholarship.verified = !scholarship.verified;
                updateScholarship(scholarship);
              }}
            />
          </div>
        </div>
      ))}

      {errorMessage}
      {scholarshipsToCheck.length === 0 && (
        <div className="scholarship">
          <div className="line2">{defaultEmptyMessage}</div>
        </div>
      )}
    </div>
  );
};

export default ScholarshipsToCheckList;
