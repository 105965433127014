export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

/**
 * Checks if passed value is a valid member of typescript enum
 */
export function isValidEnumValue(ENUM, value) {
  return Object.values(ENUM).includes(value);
}
