import React from 'react';

import { EuiCallOut, EuiTitle } from '@elastic/eui';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';

const AcceptanceFormHead = ({ submission }) => {
  let awardAmount = submission.awardAmount || 0;

  if (awardAmount > 999) {
    awardAmount = awardAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return submission.awardStatus === AWARD_STATUS.AWARD_NOTIFIED ? (
    <EuiTitle size="s">
      <h3 style={{ marginBottom: 0 }}>
        Congratulations! You have been awarded{awardAmount ? ' $' + awardAmount : ''} for the{' '}
        {submission.scholarship?.name || 'Scholarship Name'}.
      </h3>
    </EuiTitle>
  ) : submission.awardStatus === AWARD_STATUS.AWARD_REJECTED ? (
    <>
      <EuiCallOut title="Last step to reject!" color="warning" iconType="cross">
        <p>
          You have chosen to reject the award. To finish the process, fill out the fields below and click "Submit".
          <br />
          The scholarship organization will be notified about your response.
        </p>
      </EuiCallOut>
    </>
  ) : (
    <>
      <EuiCallOut title="Last step to accept!" color="success" iconType="check">
        <p>
          You have chosen to accept the award. To finish the process, fill out the fields below and click "Submit".
          <br />
          The scholarship organization will be notified about your response.
        </p>
      </EuiCallOut>
    </>
  );
};

export default AcceptanceFormHead;
