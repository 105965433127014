import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiTextArea,
  EuiToolTip
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNodeType, SUPPORTED_NODES_WITH_SCHEMAS } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationFormNodeType } from '@sharedComponents/schemas/FormNodesSchema';

import FormNodeBody from './EditFormNodeModal/FormNodeBody';

const validateJsonString = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
};

const formatStringToJson = (jsonString: Record<string, any>) => {
  return JSON.stringify(jsonString, null, 4);
};

const EditFormNodeModal = ({
  onModalSubmit,
  node,
  isForcedRaw = false
}: {
  onModalSubmit: (changedNode?: ApplicationFormNodeType) => void;
  node: ApplicationFormNodeType;
  isForcedRaw?: boolean;
}) => {
  const nodeType = getNodeType(node);

  const [isRaw, setIsRaw] = useState(isForcedRaw);
  const [isRawError, setIsRawError] = useState(false);
  const [textAreaState, setTextAreaState] = useState(JSON.stringify(node, null, 4));

  const handleTextAreaChange = e => {
    const editedText = e.target.value;
    const validatedJSON = validateJsonString(editedText);
    if (!validatedJSON) {
      setIsRawError(true);
    } else if (isRawError) {
      setIsRawError(false);
    }

    setTextAreaState(editedText);
  };

  const formatRawEditor = () => {
    const validatedJSON = validateJsonString(textAreaState);
    if (validatedJSON) {
      // format
      setTextAreaState(formatStringToJson(validatedJSON));
    }
  };

  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      nodeType && SUPPORTED_NODES_WITH_SCHEMAS[nodeType] ? SUPPORTED_NODES_WITH_SCHEMAS[nodeType] : yup.mixed()
    ),
    defaultValues: {
      ...node
    },
    shouldUnregister: false // to enable raw
  });

  // const updateFormFromRawJson = (): boolean => {
  //   try {
  //     const json = JSON.parse(textAreaState);

  //     formMethods.reset({ ...json });
  //     return true;
  //   } catch (_) {
  //     // json validation

  //     return false;
  //   }
  // };

  const onSubmitHandler = formMethods.handleSubmit(
    async validationResult => {
      if (isRaw) {
        if (isRawError) {
          return false;
        }

        if (validateJsonString(textAreaState)) {
          // this way we skip validation by hook-forms, so it may be better to use updateFormFromRawJson and then trigger submit
          onModalSubmit({ ...validateJsonString(textAreaState) });
        }

        return;
      }

      onModalSubmit({ ...validationResult });
    },
    e => {
      // eslint-disable-next-line no-console
      console.error('submit error:', e);
    }
  );

  const onCancelHandler = () => {
    onModalSubmit();
  };

  const handleEditRAWClick = () => {
    if (!isForcedRaw) {
      setIsRaw(!isRaw);
    }
  };

  return (
    <EuiModal onClose={onCancelHandler} style={{ width: '800px' }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle style={{ width: '100%' }}>
          <EuiFlexGroup direction="row" gutterSize="m" justifyContent="spaceBetween" responsive={false}>
            <EuiFlexItem grow={false}>{`Edit field details`}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                title="Edit RAW data"
                iconType="indexEdit"
                onClick={handleEditRAWClick}
                color={isRaw ? 'accent' : 'primary'}
                size="s"
                isDisabled={isForcedRaw}
              >
                {isRaw ? 'UI' : 'RAW'}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
          <EuiFlexItem>
            {isRaw ? (
              <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
                <EuiFlexItem>
                  <EuiTextArea value={textAreaState} onChange={handleTextAreaChange} fullWidth />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButtonEmpty onClick={formatRawEditor}>Format JSON</EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : (
              <FormProvider {...formMethods}>
                <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
                  <EuiFlexItem>
                    <EuiFlexGroup direction="row" responsive={false}>
                      <EuiFlexItem>
                        <EuiText size="s">Field type:</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiText size="s">
                          <strong>{nodeType}</strong>
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem></EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <FormNodeBody type={nodeType!} />{' '}
                  {/** if node unknown, then raw is forced and we are not rendering this part */}
                </EuiFlexGroup>
              </FormProvider>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onCancelHandler} size="s">
          Cancel
        </EuiButtonEmpty>
        <EuiToolTip content={isRawError ? 'Raw JSON is corrupted' : undefined}>
          <EuiButton
            color={isRawError ? 'danger' : 'primary'}
            onClick={onSubmitHandler}
            size="s"
            iconType={isRawError ? 'alert' : undefined}
          >
            {isRaw ? 'Save Raw' : 'Submit'}
          </EuiButton>
        </EuiToolTip>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditFormNodeModal;
