import './InvitesBlock.scss';

import React, { useEffect, useState } from 'react';

import DebouncedInput from '@apply/components/DebouncedInput';
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import { Invitation, InvitationRelationship } from '@sharedComponents/interfaces/Invitation.interface';
import { capitalize } from '@sharedComponents/utils/stringUtils';
import { subtract } from 'lodash';
import RelationshipsBlock from './InvitesBlock/RelationshipsBlock';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export const InvitesBlock = ({
  role,
  invites,
  entries,
  onInviteChanged,
  maxRecords = invites.length,
  isFreezed,
  applicationClient,
  onUpdate,
  showControls = true,
  legendTitle
}: {
  entries: InvitationRelationship[]; // already established relationships
  role: USER_TYPE;
  invites: Invitation[]; // new invitations from this component
  onInviteChanged: (type: USER_TYPE, invitations: Invitation[]) => void;
  maxRecords: number;
  isFreezed: boolean;
  applicationClient: ApplicationClient;
  onUpdate;
  showControls?: boolean;
  legendTitle?: string;
}) => {
  // if there are connections, substract min/max
  const minRecords = subtract(1, entries.length);
  maxRecords = subtract(maxRecords, entries.length);

  const [currentInvitations, setCurrentInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    setCurrentInvitations([...invites]);
  }, [invites]);

  const addingNewListItem = () => {
    if (isFreezed) {
      return;
    }

    onInviteChanged(role, [...invites, { name: '', email: '', userType: role }]);
  };

  const removeListItem = async (index: number) => {
    if (isFreezed) {
      return;
    }

    const updatedInvites = [...invites];
    updatedInvites.splice(index, 1);

    onInviteChanged(role, updatedInvites);
  };

  const confirmLegacyRecord = async legacyRecord => {
    try {
      await applicationClient.processLegacyInvitation(legacyRecord);
    } catch (e) {
      throw new Error('Unexpected error during relationship confirmation. Please, try again.');
    }

    onUpdate(); // notify parent component
  };

  const removeRecord = async record => {
    try {
      await applicationClient.removeRelationship(record);
    } catch (e) {
      throw new Error('Unexpected error during relationship confirmation. Please, try again.');
    }

    onUpdate();
  };

  return (
    <EuiFlexGroup className="invitesBlock" direction="column" gutterSize="s" responsive={false}>
      <EuiFlexItem>
        <EuiTitle className={'m-dark'} size="xs">
          <h3>{legendTitle ? legendTitle : `${capitalize(role)}${maxRecords > 1 ? 's' : ''}`}</h3>
        </EuiTitle>
      </EuiFlexItem>

      {entries.length ? (
        <RelationshipsBlock entries={entries} onLegacy={confirmLegacyRecord} onRemove={removeRecord} />
      ) : null}
      {currentInvitations.map((invitation: Invitation, i: number) => {
        return (
          <EuiFlexItem key={i} grow={false}>
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}></EuiFlexItem>
              <EuiFlexItem>
                <DebouncedInput
                  placeholder={`Enter name`}
                  initialValue={invitation.name}
                  disabled={isFreezed}
                  onChange={(value: string) => {
                    const updatedCurrentInvitations = [...currentInvitations];
                    updatedCurrentInvitations[i].name = value;

                    onInviteChanged(role, [...updatedCurrentInvitations]);
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <DebouncedInput
                  placeholder={`Enter email`}
                  initialValue={invitation.email}
                  disabled={isFreezed}
                  onChange={(value: string) => {
                    const updatedCurrentInvitations = [...currentInvitations];
                    updatedCurrentInvitations[i].email = value;

                    onInviteChanged(role, [...updatedCurrentInvitations]);
                  }}
                />
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  className={`removalButton ${showControls && i + 1 > minRecords && i > 0 ? '' : ' m-hidden'}`}
                  isDisabled={isFreezed}
                  color="danger"
                  onClick={async () => await removeListItem(i)}
                  iconType="minusInCircle"
                  aria-label={'Remove entry'}
                  title={'Remove entry'}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}></EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        );
      })}

      <EuiFlexItem>
        {showControls ? (
          <EuiFlexGroup alignItems="flexEnd" direction="row" responsive={false}>
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                style={{ marginLeft: '-8px' }}
                size="s"
                disabled={currentInvitations.length === maxRecords || isFreezed}
                onClick={() => addingNewListItem()}
                iconType="plusInCircle"
                aria-label="Add new entry"
                title="Add new entry"
              ></EuiButtonIcon>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : null}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default InvitesBlock;
