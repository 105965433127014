import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import { AllEventsData, EVENT_AUTHORIZED_TYPE } from '@sharedComponents/interfaces/Events.interface';
import { get, sumBy } from 'lodash';
import SimpleStatChart from './utils/SimpleStatChart';
import { ScholarshipModelShape } from '@sharedComponents/models';

export default function PageSuggestionsBlock({
  eventsData,
  dateRangeFilter,
  selectedScholarship,
  isLoading
}: {
  eventsData: AllEventsData | undefined;
  dateRangeFilter: number;
  selectedScholarship: ScholarshipModelShape | null;
  isLoading: boolean;
}) {
  const [prevPeriodNumber, setPrevPeriodNumber] = useState<number>(0);
  const [value, setValue] = useState<AllEventsData['eventsAuthorized'][EVENT_AUTHORIZED_TYPE.SCHOLARSHIP_SUGGESTION]>(
    []
  );

  useEffect(() => {
    const newValue = get(eventsData, `eventsAuthorized.${EVENT_AUTHORIZED_TYPE.SCHOLARSHIP_SUGGESTION}`) || [];
    const newPrevPeriodNumber =
      get(eventsData, `previousPeriodNumbers.eventsAuthorized.${EVENT_AUTHORIZED_TYPE.SCHOLARSHIP_SUGGESTION}`) || 0;

    setValue(newValue);
    setPrevPeriodNumber(newPrevPeriodNumber);
  }, [eventsData]);

  const count = sumBy(value, 'count');
  const periodDifference = count - prevPeriodNumber;

  return (
    <div className={'DonorHomepage__statBlock pageSuggestionsBlock'}>
      <EuiFlexGroup responsive={false} direction={'column'}>
        <EuiFlexItem grow={false} className={'pageSuggestionsBlock__statData'}>
          <EuiFlexGroup responsive={false} direction={'column'}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup responsive={false} direction={'column'} className={'DonorHomepage__statInfo'}>
                <EuiFlexItem className={'DonorHomepage__statNumber'}>
                  {isLoading ? <EuiLoadingSpinner size={'l'} /> : <>{count}</>}
                </EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statName'}>Suggestions</EuiFlexItem>
                <EuiFlexItem className={'DonorHomepage__statDescription'}>
                  The number of times a student was suggested to apply for your scholarship.
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {!!value?.length ? (
          <EuiFlexItem className={'pageSuggestionsBlock__chart'}>
            <SimpleStatChart
              data={value}
              dateRangeFilter={dateRangeFilter}
              selectedScholarship={selectedScholarship}
              action={'suggestion'}
              periodDifference={periodDifference}
            />
          </EuiFlexItem>
        ) : (
          <EuiFlexItem className={'pageSuggestionsBlock__emptyChartBlock'}>
            No data to display. <br />
            Check back soon for updates!
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  );
}
