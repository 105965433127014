import './ScholarshipListingWizard.scss';

import { cloneDeep, omit, set } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { cn } from '@bem-react/classname';

import useAfterPublicationLocation from '@sharedComponents/hooks/useAfterPublicationLocation';
import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiLoadingSpinner,
  EuiPanel,
  EuiPanelProps
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { SELF_LISTING_LAST_STEP_NUMBER } from '@sharedComponents/interfaces/Scholarships.interface';
import { DraftScholarshipModelShape, UserModelShape } from '@sharedComponents/models';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';

import useScholarshipEntry from '../hooks/useScholarshipEntry';
import PublicationModal from './ScholarshipListingWizard/PublicationModal';
import draftScholarshipEntryQuery from './ScholarshipListingWizard/queries/draftScholarshipEntryQuery';
import SelfListingFormNavigation from './ScholarshipListingWizard/SelfListingFormNavigation';
import SelfListingHeader from './ScholarshipListingWizard/SelfListingHeader';
import {
  SelfListingScholarshipStep1,
  SelfListingScholarshipStep10,
  SelfListingScholarshipStep11,
  SelfListingScholarshipStep12,
  SelfListingScholarshipStep13,
  SelfListingScholarshipStep14,
  SelfListingScholarshipStep2,
  SelfListingScholarshipStep3,
  SelfListingScholarshipStep4,
  SelfListingScholarshipStep5,
  SelfListingScholarshipStep6,
  SelfListingScholarshipStep7,
  SelfListingScholarshipStep8,
  SelfListingScholarshipStep9
} from './ScholarshipListingWizard/SelfListingSteps';
import { scholarshipLink } from '@sharedComponents/utils/scholarshipUtils';

const stepValidationRules = {
  1: SelfListingScholarshipStep1.validationRules,
  2: SelfListingScholarshipStep2.validationRules,
  3: SelfListingScholarshipStep3.validationRules,
  4: SelfListingScholarshipStep4.validationRules,
  5: SelfListingScholarshipStep5.validationRules,
  6: SelfListingScholarshipStep6.validationRules,
  7: SelfListingScholarshipStep7.validationRules,
  8: SelfListingScholarshipStep8.validationRules,
  9: SelfListingScholarshipStep9.validationRules,
  10: SelfListingScholarshipStep10.validationRules,
  11: SelfListingScholarshipStep11.validationRules,
  12: SelfListingScholarshipStep12.validationRules,
  13: SelfListingScholarshipStep13.validationRules,
  14: SelfListingScholarshipStep14.validationRules
};

/**
 * Access: donors, counselors
 */
const className = cn('ScholarshipListingWizard');
const ScholarshipListingWizard = ({
  draftID,
  scholarshipID,
  panelProps,
  onScholarshipPublication // hook just to inform parent that its happened
}: {
  draftID?: number | null;
  scholarshipID?: number;
  panelProps?: EuiPanelProps;
  onScholarshipPublication?: () => Promise<void>;
}) => {
  const router = useRouter();
  const createModal = useModalCreate();
  const user = useLoggedInUser();
  const scholarshipListingUrl = useAfterPublicationLocation();

  const [wizardInstance, setWizardInstance] = useState<StepWizardChildProps>();
  const [currentStep, setCurrentStep] = useState(1);
  /**
   * isFreezed: when donor already submitted the form, entry opened only in read-only mode
   */
  const [isFreezed, setIsFreezed] = useState(false);

  // we store param in state, since this id may be obtained during first submit
  const [draftScholarshipID, setDraftScholarshipID] = useState(draftID);
  const isScholarshipHasToBeFetched = !!(scholarshipID || draftScholarshipID);
  const {
    isSuccess,
    data: scholarshipEntry,
    isFetching,
    isError
  } = useScholarshipEntry(
    scholarshipID || draftScholarshipID || 0,
    isScholarshipHasToBeFetched,
    !!(draftScholarshipID && draftScholarshipID > 0)
  );

  const { mutateAsync: upsertScholarshipEntry, isLoading } =
    draftScholarshipEntryQuery.useMutateDraftScholarshipEntry();

  const formMethods = useForm<Partial<DraftScholarshipModelShape>>({
    reValidateMode: 'onChange',
    resolver: yupResolver(stepValidationRules[currentStep]),
    shouldUnregister: false // to collect data between steps
  });

  // making sure formState is read before render to enable the Proxy
  const isDirty = formMethods.formState.isDirty;

  // if this UI is used for drafts or live scholarships
  const isDraftView = !scholarshipID;

  // Fill form with fetched draft entry [restore previous draft]
  useEffect(() => {
    if (isSuccess && scholarshipEntry?.id) {
      formMethods.reset(
        // Type instantiation is excessively deep and possibly infinite
        JSON.parse(JSON.stringify(scholarshipEntry as unknown)) as any
      );

      // restore last step for drafts if needed or if its published scholarship
      if (scholarshipID || scholarshipEntry?.data?.self_listing_step === SELF_LISTING_LAST_STEP_NUMBER) {
        setCurrentStep(SELF_LISTING_LAST_STEP_NUMBER);

        setIsFreezed(isDraftView);

        wizardInstance?.goToStep(SELF_LISTING_LAST_STEP_NUMBER);
      } else if (scholarshipEntry?.data?.self_listing_step) {
        setCurrentStep(scholarshipEntry?.data?.self_listing_step + 1);
        wizardInstance?.goToStep(scholarshipEntry?.data?.self_listing_step + 1);
      }
    }
  }, [draftScholarshipID, isSuccess, scholarshipEntry?.id, wizardInstance]);

  const nextStepHandler = formMethods.handleSubmit(
    async scholarshipEntry => {
      let _scholarshipEntry = cloneDeep(scholarshipEntry);
      if (isDirty || currentStep === SELF_LISTING_LAST_STEP_NUMBER) {
        if (isDraftView) {
          // Update draft scholarship completed step with latest one if needed (could be moved to backend tho)
          if (
            !_scholarshipEntry.data ||
            !_scholarshipEntry.data?.self_listing_step ||
            _scholarshipEntry.data.self_listing_step < currentStep
          ) {
            if (!_scholarshipEntry.data) {
              _scholarshipEntry.data = {} as any;
            }

            if (_scholarshipEntry.data) {
              _scholarshipEntry.data.self_listing_step = currentStep;
            }
          }

          // set donor organization to current user's one [I guess thats not the ideal place for that]
          if (!_scholarshipEntry.donor_id && user?.donor?.id) {
            _scholarshipEntry.donor_id = user.donor.id;
          }
        } else {
          // at this point if we submit live scholarship here, we have to create draft instead
          set(_scholarshipEntry, 'scholarship_id', scholarshipEntry.id);
          _scholarshipEntry = omit(_scholarshipEntry, ['id']);
        }

        await upsertScholarshipEntry(_scholarshipEntry, {
          // update URL in case its a fresh draft creation
          onSuccess: async data => {
            if (currentStep === SELF_LISTING_LAST_STEP_NUMBER) {
              // custom behavior on publication
              if (onScholarshipPublication) {
                await onScholarshipPublication();
              } else {
                // if scholarship published, display success message
                createModal(({ closeModal }) => <PublicationModal closeModal={closeModal} />);
              }
            } else {
              // first time submit
              if (!draftScholarshipID && data?.id) {
                router.replace(scholarshipLink(data, user as UserModelShape, true));
                setDraftScholarshipID(data.id);
              }
            }
          }
        });
      }

      if (currentStep !== SELF_LISTING_LAST_STEP_NUMBER) {
        setCurrentStep(currentStep + 1);

        wizardInstance?.nextStep();
      }
    },
    e => {
      // eslint-disable-next-line no-console
      console.log('submit error:', e);
    }
  );

  const returnBackHandler = () => {
    // in case form is freezed or its a first step form, return handler should get back to listing, since there are no steps
    if (isFreezed || currentStep === 1) {
      router.push(scholarshipListingUrl);
    } else {
      setCurrentStep(currentStep - 1);

      wizardInstance?.goToStep(currentStep - 1);
    }
  };

  if (isError) {
    return (
      <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
        {DEFAULT_ERROR_MESSAGE}
      </EuiCallOut>
    );
  }

  const isReadyForIteraction = !isFetching && (!draftScholarshipID || isSuccess);
  return (
    <EuiFlexGroup className={className()} direction="column" gutterSize="s" responsive={false}>
      <EuiFlexItem grow={false}>
        {isReadyForIteraction ? (
          <SelfListingHeader
            scholarshipEntry={scholarshipEntry}
            currentStep={currentStep}
            nextStepHandler={nextStepHandler}
            isFreezed={isFreezed}
            isDraftView={isDraftView}
          />
        ) : null}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPanel className={className('panel')} {...panelProps}>
          <EuiFlexGroup direction="column" gutterSize="s" style={{ height: '100%' }}>
            <EuiFlexItem>
              {isReadyForIteraction ? (
                <FormProvider {...formMethods}>
                  <EuiForm component="form" onSubmit={nextStepHandler}>
                    <StepWizard
                      instance={instance => setWizardInstance(instance as StepWizardChildProps)}
                      isHashEnabled={false}
                      isLazyMount={true}
                      initialStep={isFreezed ? SELF_LISTING_LAST_STEP_NUMBER : 1}
                    >
                      <SelfListingScholarshipStep1 />
                      <SelfListingScholarshipStep2 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep3 />
                      <SelfListingScholarshipStep4 />
                      <SelfListingScholarshipStep5 />
                      <SelfListingScholarshipStep6 />
                      <SelfListingScholarshipStep7 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep8 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep9 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep10 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep11 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep12 nextStepHandler={nextStepHandler} />
                      <SelfListingScholarshipStep13 />
                      <SelfListingScholarshipStep14 isFreezed={isFreezed} />
                    </StepWizard>
                  </EuiForm>
                </FormProvider>
              ) : (
                <EuiFlexGroup direction="column">
                  <EuiFlexItem>
                    <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SelfListingFormNavigation
                currentStep={currentStep}
                previouslyPassedStep={scholarshipEntry?.data?.self_listing_step}
                returnBackHandler={returnBackHandler}
                nextStepHandler={nextStepHandler}
                isLoading={isLoading}
                isFreezed={isFetching || isFreezed}
                isDraftView={isDraftView}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default ScholarshipListingWizard;
