import React from 'react';
import { useState, useEffect } from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import './SubmitBanner.scss';
import useSingleSubmit from '../useSingleSubmit';

export default function SubmitBanner(props) {
  const { onSubmit, label } = props;
  const [animationClass, setAnimationClass] = useState('show');
  const [singleSubmit, isSubmitting] = useSingleSubmit();

  useEffect(() => {
    Promise.resolve().then(() => setAnimationClass('show animate'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'SubmitBanner ' + animationClass}>
      <EuiFlexGroup direction="row" justifyContent="center">
        <EuiFlexItem grow={false}>
          <form onSubmit={singleSubmit(onSubmit)}>
            <EuiButton type="submit" disabled={isSubmitting} fill>
              {label || 'Submit'}
            </EuiButton>
          </form>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
