import { cloneDeep, set } from 'lodash';
import { selector } from 'recoil';
import { formStateAtom } from '../atoms/formState';

const formStateActivePageSelector = selector<number>({
  key: 'formStateActivePageSelector',
  get: ({ get: getRecoilValue }) => {
    const { activePage } = getRecoilValue(formStateAtom);
    return activePage;
  },
  set: ({ set: setRecoilState, get: getRecoilValue }, _activePage) => {
    const _formState = cloneDeep(getRecoilValue(formStateAtom));
    set(_formState, 'activePage', _activePage);
    setRecoilState(formStateAtom, _formState);
  }
});

export default formStateActivePageSelector;
