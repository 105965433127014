import moment from 'moment';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui';
import { CheckboxField, ComboboxField, DatePickerField, SwitchField, TextField } from '@sharedComponents/formElements';
import { MAILTYPE_OPTIONS } from '@sharedComponents/interfaces/Mailing.interface';
import { MailingModelSchema } from '@sharedComponents/schemas/MailingModelSchema';

const PrintOptions = () => {
  const formMethods = useFormContext();
  const color = formMethods.watch('color');

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>Select printing preferences:</EuiText>
        <EuiSpacer />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row" responsive={false}>
          <EuiFlexItem>
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <TextField
                  fieldName={'description'}
                  fieldProps={{}}
                  rowProps={{
                    label: 'Mailing Title (internal use only)',
                    display: 'columnCompressed',
                    fullWidth: true
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <SwitchField
                  fieldName={'color'}
                  label={color ? `Color Print` : 'Black and White Print'}
                  rowProps={{
                    label: 'Color Options',
                    display: 'columnCompressedSwitch',
                    fullWidth: true
                  }}
                />
              </EuiFlexItem>

              <EuiFlexItem>
                <DatePickerField
                  fieldName={'send_date'}
                  fieldProps={{
                    minDate: moment.utc().add(1, 'd'),
                    maxDate: moment.utc().add(180, 'days'),
                    showTimeSelect: false
                  }}
                  rowProps={{
                    label:
                      'Mailing Date (date letter is sent to LOB warehouse for assembly, if left empty - will be created instant)',
                    display: 'columnCompressed',
                    fullWidth: true
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <ComboboxField
                  fieldName={'mail_type'}
                  options={MAILTYPE_OPTIONS.map(opt => ({
                    label: opt === 'usps_first_class' ? 'USPS First Class' : 'USPS Standard',
                    value: opt
                  }))}
                  fieldProps={{ defaultValue: MAILTYPE_OPTIONS[0], singleSelection: true, isClearable: false }}
                  rowProps={{
                    label: 'Mail Service',
                    display: 'columnCompressed',
                    fullWidth: true
                  }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <CheckboxField
                  fieldName={'double_sided'}
                  rowProps={{
                    label: 'Double-sided Print',
                    display: 'columnCompressedSwitch',
                    fullWidth: true
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

PrintOptions.validationRules = MailingModelSchema.pick([
  'color',
  'description',
  'send_date',
  'mail_type',
  'double_sided'
]);

export default PrintOptions;
