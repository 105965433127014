import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import UserClient from '@sharedClients/UserClient';
import { loggedInUserState } from '@sharedComponents/atoms/loggedInUserState';
import { wrapToCatch401s } from '@sharedComponents/utils/wrappedFetch';

export function useUserClient() {
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);

  const userClientFromCallback = useMemo(() => {
    const wrappedFetch = wrapToCatch401s(window.fetch, () =>
      setLoggedInUser(() => ({
        jwt: null,
        user: undefined
      }))
    );

    const userClient = new UserClient(wrappedFetch, null, loggedInUser.jwt);
    return userClient;
  }, [loggedInUser.jwt]);

  return userClientFromCallback;
}
