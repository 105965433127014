import React from 'react';

export default function FormFieldTextArea({ node, application, onFieldChange }) {
  const value = node.getValue(application);
  const valueString = (value || '').toString();

  return (
    <textarea
      id={node.getInputElementId()}
      type="text"
      rows={Math.max((valueString.match(/\n/g) || []).length + 1, 2)}
      value={valueString}
      onChange={e => onFieldChange(e.target.value, node)}
      onBlur={e => {
        const value = e.target.value;
        if (value.trim() !== value) {
          onFieldChange(value.trim(), node);
        }
      }}
    />
  );
}
