import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { isList } from '../../model/cast';
import FormNode from '../../model/FormNode';
import React from 'react';
import MultipleSelectWidget from '../../base/components/MultipleSelectWidget';
import warn from '../../base/warn';
import { toList } from './FormFieldSelect';

export default function FormFieldMultipleSelect({
  node,
  application,
  onFieldChange,
  animationTime = 100,
  warnOnMissingRequired,
  onFormError
}: {
  node: FormNode;
  application: ApplicationModel;
  onFormError: (e: Error) => void;
  onFieldChange: (value: any, node: FormNode) => any;
  animationTime?: number;
  warnOnMissingRequired: boolean;
}) {
  const selected = getSelected(node, application);
  const options = toList(node.options, onFormError);
  const id = node.getInputElementId();

  const requiredCount = node.required && node.required;

  return (
    <MultipleSelectWidget
      onChange={value => onFieldChange(value, node)}
      warnOnMissingRequired={warnOnMissingRequired}
      selected={selected}
      options={options}
      id={id}
      requiredCount={requiredCount as number}
      animationTime={animationTime}
      allowUnknown={node.allowUnknown}
      isMissingRequiredFields={() => {
        const skipChildren = true;

        return !!node.isMissingRequiredFields(application, skipChildren);
      }}
    />
  );
}

function getSelected(node: any, application: ApplicationModel) {
  let selected = node.getValue(application) as string[];

  if (selected == null) {
    selected = [];
  } else if (!isList(selected)) {
    warn(`Field ${node.field} did not have a list value`);

    selected = typeof selected != 'object' ? [(selected as any).toString()] : [];
  }

  return selected;
}
