import track from '@sharedComponents/base/track';
import useCachedState from '@donors/base/useCachedState';
import warn from '@donors/base/warn';
import { getServiceUrl } from '@sharedClients/main';
import { FormPost } from '@sharedClients/types/FormModel';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import ButtonBar from '../components/ButtonBar';
import { HeaderProps } from '../components/Header';
import EditForm from './EditForm';
import { fullFormId } from './Form';
import './Form.scss';
import openPreviewWindow, { previewForm } from './openPreviewWindow';
import UserModel from '@sharedContract/UserModel';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

/* eslint-disable react-hooks/exhaustive-deps */

export default function AddForm(
  props: {
    user: UserModel;
    baseOnForm: string | null;
  } & HeaderProps
) {
  const scholarshipClient = useScholarshipClient();
  const { user, baseOnForm } = props;

  const [onError, errorMessage] = useErrorReporter();
  const [redirect, setRedirect] = useState<string | null>(null);
  const [form, setForm] = useState<FormPost>({
    name: '',
    data: [],
    userType: 'applicant',
    standardFields: []
  });
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);
  const [unsavedForm, setUnsavedForm] = useState<FormPost | null>(null);

  const [standardForm, setStandardForm] = useCachedState<any>('standardForm', []);

  useEffect(() => {
    scholarshipClient.getForm(fullFormId).then(setStandardForm).catch(onError);
  }, []);

  async function preview(form?: FormPost) {
    const formToPreview = form || unsavedForm;

    if (!formToPreview || !standardForm) {
      return warn(new Error(`Could not preview. ${!!formToPreview}, ${!!standardForm}`));
    }

    const applyDomain = getServiceUrl('apply');

    let preview = previewWindow;

    if (!preview || !preview.window) {
      preview = await openPreviewWindow(applyDomain);

      setPreviewWindow(preview);
    }

    previewForm({
      previewWindow: preview,
      form: formToPreview,
      standardForm,
      applyDomain
    });
  }

  useEffect(() => {
    scholarshipClient
      .getForm(fullFormId)
      .then(form => setStandardForm(form.data))
      .catch(onError);
  }, []);

  useEffect(() => {
    if (baseOnForm != null && !parseInt(baseOnForm)) {
      scholarshipClient.getForm(parseInt(baseOnForm)).then(baseOn => {
        setForm({
          data: baseOn.data,
          standardFields: baseOn.standardFields,
          name: baseOn.name + ' (copy)',
          userType: baseOn.userType
        });
      });
    }
  }, [baseOnForm]); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div className="Form">
      {redirect ? <Redirect to={redirect} /> : null}

      <div className="body">
        <div className="content">
          {errorMessage}

          <ButtonBar className="links">
            {unsavedForm ? (
              <button className="button" onClick={() => preview().catch(onError)}>
                Preview
              </button>
            ) : null}
          </ButtonBar>

          {user.type === 'admin' ? (
            <div className="warn">
              You are logged in as admin. This form will become a template visible to all donors.
            </div>
          ) : null}

          <EditForm
            form={form}
            standardForm={standardForm}
            onSave={editedForm =>
              scholarshipClient
                .createForm(editedForm)
                .then(({ id }) => {
                  track('form-created');

                  setRedirect('/forms');
                })
                .catch(onError)
            }
            onChange={form => {
              setUnsavedForm(form);

              if (previewWindow && previewWindow.window) {
                preview(form).catch(onError);
              }
            }}
            onPreview={preview}
            onCancel={() => setRedirect('/forms')}
            onError={onError}
          />
        </div>
      </div>
    </div>
  );
}
