import React from 'react';

import applicantAddressFields from '@apply/profileFields/studentAddressFields';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';

import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';

const addressOfResidencyStep: Step = {
  validationSchema: _userType => applicantAddressFields.validationRules,

  render({ applicationClient }) {
    const addressFields = applicantAddressFields(applicationClient);
    return (
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>Residence Address</h3>
          </EuiTitle>
        </EuiFlexItem>
        {addressFields.map((item, key) => {
          return <EuiFlexItem key={key}>{item}</EuiFlexItem>;
        })}
      </EuiFlexGroup>
    );
  },

  async getNextStep() {
    return SIGNUP_STEP.STUDENT_INVITATIONS;
  },

  renderControls(props) {
    const { formMethods, changeStep } = props;

    // we validate our input and then processing to next step
    const stepSubmitHandler = formMethods.handleSubmit(async _ => {
      const nextStep = await this.getNextStep(props);
      if (nextStep) {
        changeStep(nextStep);
      }
    });

    return (
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiFlexItem>
          <EuiButton size="s" onClick={() => changeStep(SIGNUP_STEP.ABOUT_YOU)}>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton color="primary" size="s" onClick={stepSubmitHandler} fill>
            Next
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default addressOfResidencyStep;
