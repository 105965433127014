import { EuiBreadcrumbs } from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';

import { VIEW_MODE } from './config';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';

// TODO: IMP
const ScholarshipApplicationsBreadcrumbs = ({
  scholarship,
  onViewModeReset,
  extraItems,
  viewMode
}: {
  scholarship?: { id: number; name: string };
  onViewModeReset: () => void;
  viewMode: VIEW_MODE;
  extraItems?: {
    text: string;
    href: string;
    onClick?: (e: any) => void;
  }[];
}) => {
  const user = useLoggedInUser();
  const router = useRouter();
  const breadcrumbs: {
    text: string;
    href: string;
    onClick?: (e: any) => void;
  }[] = [
    {
      text: 'Scholarships',
      href: `/scholarships`,
      onClick: e => {
        router.push(`/scholarships`);
        e.preventDefault();
      }
    }
  ];

  if (user && [USER_TYPE.REVIEWER, USER_TYPE.DONOR].includes(user.type as USER_TYPE)) {
    breadcrumbs.push({
      text: scholarship?.name || '...',
      href: '',
      onClick: e => e.preventDefault()
    });
  } else {
    breadcrumbs.push({
      text: scholarship?.name || '...',
      href: `/scholarships/${scholarship?.id}`,
      onClick: e => {
        router.push(`/scholarships/${scholarship?.id}`);
        e.preventDefault();
      }
    });
  }

  if (viewMode === VIEW_MODE.DEFAULT) {
    breadcrumbs.push({
      text: 'Applications',
      href: `/scholarships/${scholarship?.id}/applications`,
      onClick: e => {
        router.push(`/scholarships/${scholarship?.id}/applications`);
        e.preventDefault();
      }
    });
  } else {
    // review round related
    breadcrumbs.push({
      text: 'Applications',
      href: `/scholarships/${scholarship?.id}/applications`,
      onClick: e => {
        onViewModeReset();
        e.preventDefault();
      }
    });

    breadcrumbs.push({
      text: viewMode === VIEW_MODE.REVIEW_VIEW ? 'Review rounds lookup' : 'Review rounds setup',
      href: '',
      onClick: e => e.preventDefault()
    });
  }

  if (extraItems?.length) {
    breadcrumbs.push(...extraItems);
  }

  return <EuiBreadcrumbs breadcrumbs={breadcrumbs} aria-label="" />;
};

export default ScholarshipApplicationsBreadcrumbs;
