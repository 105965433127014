import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import RadioGroupField from '@sharedComponents/formElements/combined/RadioGroupField';
import TextAreaField from '@sharedComponents/formElements/simpleFields/TextAreaField';
import { ScholarshipModelDataApplicationMaterialSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

import AbstractEntryStep from './AbstractEntryStep';

// ? add minRecommenders
const EntryStepResearchThird: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    data: yup.object().shape({
      ApplicationMaterial: ScholarshipModelDataApplicationMaterialSchema.clone(),
      eligibility_raw: yup.string().optional()
    })
  }),

  render: function (rowProps) {
    return (
      <EuiFlexGroup direction="column" alignItems="center">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Application Materials</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <RadioGroupField
            fieldName="data.ApplicationMaterial.essay"
            radios={[
              {
                value: 'Yes'
              },
              {
                value: 'No'
              }
            ]}
            rowProps={{
              ...rowProps,
              label: 'Essay?',
              helpText: 'Is an essay required to apply?'
            }}
          />
          <RadioGroupField
            fieldName="data.ApplicationMaterial.letterofRecommendation"
            radios={[
              {
                value: 'Yes'
              },
              {
                value: 'No'
              }
            ]}
            rowProps={{
              label: 'Letter of Recommendation?',
              ...rowProps,
              helpText: 'Is a letter of recommendation required to apply?'
            }}
          />
          <RadioGroupField
            fieldName="data.ApplicationMaterial.transcript"
            radios={[
              {
                value: 'Yes'
              },
              {
                value: 'No'
              }
            ]}
            rowProps={{
              label: 'Transcript?',
              ...rowProps,
              helpText: 'Is a transcript required to apply?'
            }}
          />
          <EuiSpacer size="m" />
          <TextAreaField
            fieldName={'data.ApplicationMaterial.notes'}
            fieldProps={{ rows: 2 }}
            rowProps={{
              label: 'Notes (visible to students)',
              helpText: 'Information about any additional application materials that are necessary to apply.',
              ...rowProps
            }}
          />
          <TextAreaField
            fieldName={'data.eligibility_raw'}
            fieldProps={{ rows: 2 }}
            rowProps={{
              label: 'Eligibility (Copy/paste raw data)',
              helpText:
                'Copy & paste any eligibility requirements. You do not have to proofread or edit the text–that will be completed during the eligibility step.',
              ...rowProps
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepResearchThird;
