import { capitalize } from 'lodash';
import UserModel from '../../contract/UserModel';

/** Temporal workaround for splitting users names into first/last name combo.
 * For some user profiles we may have data.name as a fullName and for some newer cases we have data.name containing first name and data.lastName for storing last name
 * Eventually we may get away from this util function once objection UserModel would be used all around
 * Note that this function may be used for applications, as well as for the applicants. Thats why firstName is not a part of user data, but there is a check for this field there.
 * It ends up that this helper function is used for both user and application data which can be confusing, but also holds way more cases.
 */
export function getUserFullNameFromData(data: any = {}) {
  // when this function is called, there could be the case when reviewer is watching this application. And for reviewer, we have awesomly stupid workaround on backend which will set data.name to "Application ID" in case application is supposed to be anonymous for reviewer. We have to respect this case here.
  if (data?.name?.startsWith('Application')) {
    return data.name;
  }

  let firstName = data?.firstName || data?.name || '';
  let lastName = data?.lastName || data?.lastname || (data?.last && typeof data?.last === 'string' ? data?.last : '');

  if (!firstName) {
    // we may be here as a part of application data parsing, where we have various data.name formats which we may try to guess
    /**
     * Cases: fullName, fullname, name1, firstname, first, last, lastname
     */
    if (data?.fullName || data?.fullname) {
      const fullName = data?.fullName || data?.fullname;
      const [first, ...rest] = fullName.split(' ');
      firstName = first;
      lastName = rest.join(' ');
    }

    if (data?.name1 || data?.firstname || data?.first) {
      firstName = data?.name1 || data?.firstname || data?.first;
    }
  }

  return `${firstName} ${lastName}`.trim();
}

export function getUserRepresentative(user: UserModel) {
  return getUserFullNameFromData(user?.data || {}) || user.name;
}

export function toGrade(academicYear = '') {
  return capitalize(academicYear.replace('High school ', '').replace('Undergraduate', 'College'));
}
