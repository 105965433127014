import React from 'react';
import './PopOver.scss';

export default function PopOver({ children, onClose }: { children: React.ReactNode; onClose: () => void }) {
  return (
    <div className="PopOver" onClick={onClose}>
      <div className="content" onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}
