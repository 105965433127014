import './ScholarshipListing.scss';

import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import ScholarshipListingWizard from '@sharedComponents/routes/ScholarshipListingWizard';

import useDraftScholarshipRestore from './ScholarshipListing/hooks/useDraftScholarshipRestore';

/**
 * Access: donors, counselors
 */
const ScholarshipListing = ({ draftID, scholarshipID }: { draftID?: number | null; scholarshipID?: number }) => {
  // Restore prev drafts
  const checkForUnfinishedDrafts = !draftID && !scholarshipID;
  const isUnfinishedDraftFetching = useDraftScholarshipRestore(checkForUnfinishedDrafts);

  return (
    <PageContentWrapper className="ScholarshipListing">
      <>
        <EuiSpacer />
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem>
            <EuiFlexGroup direction="row" style={{ alignSelf: 'center', width: '100%' }}>
              <EuiFlexItem></EuiFlexItem>
              <EuiFlexItem grow={false}>
                {!isUnfinishedDraftFetching ? (
                  <ScholarshipListingWizard draftID={draftID} scholarshipID={scholarshipID} />
                ) : (
                  <EuiFlexGroup direction="column">
                    <EuiFlexItem>
                      <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexItem>
              <EuiFlexItem></EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </PageContentWrapper>
  );
};

export default ScholarshipListing;
