import '@ui-kit/fonts/index.css'; // Montserrat font
import '@ui-kit/index.scss'; // elastic ui theme for SA
import './index.scss'; // original styles for SA

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isProd } from '@sharedClients/main';
import GoogleTagManager from '@sharedComponents/base/GoogleTagManager';
import { ModalProvider } from '@sharedComponents/contexts/modalContext';
import initHotJarSnippet from '@sharedComponents/hotJarSnippet';
import ToastProvider from '@sharedComponents/ToastProvider';

import BrowserCheck from './base/components/BrowserCheck';
import RouterComponent from './RouterComponent';
import * as serviceWorker from './serviceWorker';

if (!window['Cypress'] && isProd()) {
  initHotJarSnippet();

  Sentry.init({
    dsn: 'https://bf87fb999a8a482183cb1c04306f26cf@sentry.io/1536113',
    release: `donors@${process.env.REACT_APP_VERSION}`,
    environment: 'production',
    integrations: [new Integrations.BrowserTracing()],
    debug: false,
    tracesSampleRate: 0.2
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1 // only one retry attemp in case of failed query
    }
  }
});

ReactDOM.render(
  <Router>
    <BrowserCheck>
      <GoogleTagManager gtmId="GTM-TRD8KD9">
        <RecoilRoot>
          <QueryClientProvider
            client={queryClient}
            contextSharing={
              true /** context sharing, which will share the first and at least one instance of the context across the window to ensure that if React Query is used across different bundles or microfrontends they will all use the same instance of context, regardless of module scoping */
            }
          >
            <ModalProvider>
              <ToastProvider>
                <RouterComponent />
              </ToastProvider>
            </ModalProvider>
          </QueryClientProvider>
        </RecoilRoot>
      </GoogleTagManager>
    </BrowserCheck>
  </Router>,
  document.getElementById('root')
);

// eslint-disable-next-line no-console
console.info(`Release version[donors]: ${process.env.REACT_APP_VERSION}`);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
