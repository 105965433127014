import React from 'react';

import MultipleSelectWidget from '@sharedComponents/base/components/MultipleSelectWidget';
import stateByAbbreviation from '@sharedComponents/base/stateByAbbreviation';

export default function MultipleSelectState({
  id,
  onChange,
  selected,
  hasSubmitted
}: {
  id: string;
  onChange: (value: string[]) => any;
  selected: string[];
  hasSubmitted: boolean;
}) {
  return (
    <MultipleSelectWidget
      id={id}
      onChange={onChange}
      selected={selected}
      options={stateNames}
      optionsByKey={stateByAbbreviation}
      warnOnMissingRequired={hasSubmitted}
      isMissingRequiredFields={() => true}
    />
  );
}

export const stateNames = Object.entries(stateByAbbreviation)
  .map(([key, name]) => name)
  .sort();
