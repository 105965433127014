import { cloneDeep, set } from 'lodash';
import { selector } from 'recoil';
import { formStateAtom } from '../atoms/formState';

const formStateIsDirtySelector = selector<boolean>({
  key: 'formStateIsDirtySelector',
  get: ({ get: getRecoilValue }) => {
    const { isDirty } = getRecoilValue(formStateAtom);
    return isDirty;
  },
  set: ({ set: setRecoilState, get: getRecoilValue }, _isDirty) => {
    const _formState = cloneDeep(getRecoilValue(formStateAtom));
    set(_formState, 'isDirty', _isDirty);
    setRecoilState(formStateAtom, _formState);
  }
});

export default formStateIsDirtySelector;
