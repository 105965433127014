import React from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem, htmlIdGenerator, EuiText } from '@elastic/eui';
import { AwardStepProps, AWARD_STEPS } from './config';
import AbstractAwardStep from './AbstractAwardStep';

const AwardStepFinalPreview: AbstractAwardStep = {
  headerTitle: 'Preview and Send',
  actions(changeAwardingStep) {
    const actions: React.ReactNode[] = [];
    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeAwardingStep(AWARD_STEPS.EMAIL_DRAFTING)}>Edit Email</EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeAwardingStep(AWARD_STEPS.AWARD_COMPLETED)} fill>
          Send
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ compiledEmailContent }: AwardStepProps) {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          Review the email and click "Send" if everything is correct. If you'd like to make edits to the email, click
          "Edit Email".
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText grow={false}>
            <pre>
              <code dangerouslySetInnerHTML={{ __html: compiledEmailContent }}></code>
            </pre>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepFinalPreview;
