import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import {
  EuiBasicTable,
  EuiCallOut,
  EuiContextMenuPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPagination,
  EuiPopover
} from '@elastic/eui';
import { DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import { EVENT_AUTHORIZED_TYPE, EventAuthorizedUsers } from '@sharedComponents/interfaces/Events.interface';

import { getDonorCalendlyLinkUrl } from './config';
import eventAuthorizedUsersQuery from './queries/eventAuthorizedUsers';

const ITEMS_PER_PAGE = 8;

export default function NewQualifyingStudentsListBlock({
  selectedScholarshipId,
  dateRangeFilter,
  areScholarshipsLoading
}: {
  selectedScholarshipId: number | null;
  dateRangeFilter: number;
  areScholarshipsLoading: boolean;
}) {
  let { data, isLoading, isError } = eventAuthorizedUsersQuery.useEventAuthorizedUsers(
    selectedScholarshipId,
    EVENT_AUTHORIZED_TYPE.SCHOLARSHIP_VETTED,
    dateRangeFilter
  );
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipContainer = useRef(undefined);

  useEffect(() => {
    setSelectedPageIndex(0);
  }, [selectedScholarshipId, data, dateRangeFilter]);

  let dataSet: EventAuthorizedUsers = [];
  if (data?.length) {
    let startFrom = selectedPageIndex * ITEMS_PER_PAGE;
    dataSet = data.slice(startFrom, startFrom + ITEMS_PER_PAGE);
  }

  useEffect(() => {
    let gridElement = document.querySelector('.DonorHomepage__grid');

    if (gridElement) {
      tooltipContainer.current = gridElement as any;
    }
  }, []);

  let pageCount = 0;
  if (data?.length) {
    pageCount = Math.ceil(data!.length / ITEMS_PER_PAGE);
  }

  return (
    <div className={'DonorHomepage__statBlock newQualifyingStudentsListBlock'}>
      <EuiFlexGroup direction={'column'} responsive={false}>
        <EuiFlexItem className={'DonorHomepage__statBlockHeader'} grow={false}>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem grow={false}>New Qualifying Students</EuiFlexItem>
            <EuiFlexItem grow={false} className={'newQualifyingStudentsListBlock__headerIcon'}>
              <EuiPopover
                className={'upgradeToPremiumTooltip'}
                button={<img onMouseOver={() => setIsTooltipOpen(true)} src="/images/lock.png" alt="lock" />}
                isOpen={isTooltipOpen}
                closePopover={() => setIsTooltipOpen(false)}
                panelPaddingSize="m"
                anchorPosition="upLeft"
                container={tooltipContainer.current}
                ownFocus={false}
              >
                <EuiContextMenuPanel className={'upgradeToPremiumTooltip__content'}>
                  <p>
                    Upgrade to a premium account for exclusive features, including Scholar's App managing your
                    scholarship application process. <a href={getDonorCalendlyLinkUrl()}>Schedule a call</a> today to
                    get more qualified and completed applications.
                  </p>
                </EuiContextMenuPanel>
              </EuiPopover>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className={'DonorHomepage__statBlockBody'}>
          {!!dataSet?.length ? (
            <EuiFlexGroup responsive={false} direction={'column'} justifyContent={'spaceBetween'}>
              <EuiFlexItem>
                <EuiBasicTable
                  className={'DonorHomepage__basicTable'}
                  items={dataSet}
                  columns={[
                    {
                      field: 'name',
                      name: 'Name',
                      truncateText: true
                    },
                    {
                      field: 'email',
                      name: 'Email',
                      truncateText: true,
                      render: email => <div className={'hiddenValue'}>{email}</div>,
                      mobileOptions: {
                        show: false
                      }
                    },
                    {
                      field: 'date',
                      name: 'Date Qualified',
                      truncateText: true,
                      render: date => moment(date).format('MM/DD/YYYY')
                    }
                  ]}
                />
              </EuiFlexItem>
              {pageCount > 1 && (
                <EuiFlexItem grow={false}>
                  <EuiPagination
                    aria-label="pagination"
                    pageCount={pageCount}
                    activePage={selectedPageIndex}
                    onPageClick={pageIndex => setSelectedPageIndex(pageIndex)}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup justifyContent={'center'} alignItems={'center'} responsive={false}>
              {isError ? (
                <EuiFlexItem className={'DonorHomepage__statBlockError'} grow={false}>
                  <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert" />
                </EuiFlexItem>
              ) : areScholarshipsLoading || isLoading ? (
                <EuiFlexItem grow={false}>
                  <EuiLoadingSpinner size={'l'} />
                </EuiFlexItem>
              ) : (
                <EuiFlexItem grow={false}>No students found</EuiFlexItem>
              )}
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
