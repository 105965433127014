import React from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  htmlIdGenerator,
  EuiText,
  EuiFieldText
} from '@elastic/eui';
import { AwardStepProps, AWARD_STEPS } from './config';
import AbstractAwardStep from './AbstractAwardStep';

const AwardStepAmountInput: AbstractAwardStep = {
  headerTitle: 'Verify Number and Amount',
  actions(changeAwardingStep, selection) {
    const actions: React.ReactNode[] = [];
    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.AWARD_SELECTION);
          }}
        >
          Back
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.AWARD_AMOUNT_CONFIRMATION);
          }}
          isDisabled={selection.some(record => isNaN(record.awardAmount) || record.awardAmount <= 0)}
          fill
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    );
    return actions;
  },

  render({ selection, onAmountChange }: AwardStepProps) {
    const columns = [
      {
        field: 'index',
        name: 'Number of Scholarships',
        sortable: false,
        width: '170px',
        render: index => index + 1
      },
      {
        field: 'name',
        name: 'Name',
        sortable: false
      },
      {
        field: 'awardAmount',
        name: 'Award Amount',
        sortable: false,
        render: (awardAmount, record) => {
          return (
            <EuiFieldText
              defaultValue={isNaN(+awardAmount) || !awardAmount ? '' : awardAmount}
              isInvalid={isNaN(awardAmount) || awardAmount < 0}
              onChange={e => {
                onAmountChange(record.index, +e.target.value);
              }}
              prepend={
                <EuiText size="s">
                  <strong>$</strong>
                </EuiText>
              }
            />
          );
        }
      }
    ];

    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            Confirm the number of candidates who will receive the scholarship, and enter the award amount for each
            candidate.
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiInMemoryTable
            items={selection}
            itemId="id"
            columns={columns}
            pagination={false}
            sorting={false}
            isSelectable={false}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepAmountInput;
