import masker from './masker';

const thousandsMask = masker({
  masked: {
    mask: Number,
    scale: 0, // digits after point, 0 for integers
    signed: true, // disallow negative
    thousandsSeparator: ',' // any single char
  }
});

export default thousandsMask;
