import React from 'react';
import * as yup from 'yup';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import PasswordField from '@sharedComponents/formElements/simpleFields/PasswordField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import { getPublicRoleName } from '@sharedComponents/utils/stringUtils';

import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';
import ApplicantFormFields from './NewUserRegistrationStep/ApplicantFormFields';
import CounselorFormFields from './NewUserRegistrationStep/CounselorFormFields';
import DonorFormFields from './NewUserRegistrationStep/DonorFormFields';
import ParentFormFields from './NewUserRegistrationStep/ParentFormFields';
import ProfessionalFormFields from './NewUserRegistrationStep/ProfessionalFormFields';
import RecommenderFormFields from './NewUserRegistrationStep/RecommenderFormFields';

const validationRules = {
  [USER_TYPE.APPLICANT]: ApplicantFormFields.validationRules,
  [USER_TYPE.PARENT]: ParentFormFields.validationRules,
  [USER_TYPE.COUNSELOR]: CounselorFormFields.validationRules,
  [USER_TYPE.RECOMMENDER]: RecommenderFormFields.validationRules,
  [USER_TYPE.PROFESSIONAL]: ProfessionalFormFields.validationRules,
  [USER_TYPE.DONOR]: DonorFormFields.validationRules
};

const newUserRegistrationStep: Step = {
  validationSchema: userType =>
    yup
      .object()
      .shape({
        // mandatory for all roles
        name: yup.string().email().label('Email').required(),
        password: yup
          .string()
          .min(8, 'Please choose a more secure password (at least eight characters).')
          .max(50, 'Please choose shorter password (not longer than 50 characters).')
          .required(),
        confirmPassword: yup
          .string()
          .label('Password')
          .required()
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
          })
      })
      // specific role related fields are in data
      .concat(validationRules[userType]),

  render(props) {
    const { userType, formMethods } = props;

    let profileFieldsByRole: React.ReactElement | null = null;

    switch (userType) {
      case USER_TYPE.APPLICANT: {
        profileFieldsByRole = <ApplicantFormFields />;
        break;
      }
      case USER_TYPE.PARENT: {
        profileFieldsByRole = <ParentFormFields />;
        break;
      }
      case USER_TYPE.COUNSELOR: {
        profileFieldsByRole = <CounselorFormFields formMethods={formMethods} />;
        break;
      }
      case USER_TYPE.RECOMMENDER: {
        profileFieldsByRole = <RecommenderFormFields />;
        break;
      }
      case USER_TYPE.PROFESSIONAL: {
        profileFieldsByRole = <ProfessionalFormFields />;
        break;
      }
      case USER_TYPE.DONOR: {
        profileFieldsByRole = <DonorFormFields />;
        break;
      }
    }

    return (
      <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>New {getPublicRoleName(userType)} registration</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <TextField
            fieldName={'name'}
            fieldProps={{ fullWidth: true, tabIndex: 0, autoFocus: true }}
            rowProps={{ label: 'Your email address *', fullWidth: true }}
          />
        </EuiFlexItem>
        {profileFieldsByRole}

        <EuiFlexItem>
          <PasswordField
            fieldName={'password'}
            fieldProps={{ type: 'dual', fullWidth: true, autoComplete: 'new-password' }}
            rowProps={{ label: 'Password *', fullWidth: true }}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <PasswordField
            fieldName={'confirmPassword'}
            fieldProps={{ type: 'dual', fullWidth: true, autoComplete: 'confirm-password' }}
            rowProps={{ label: 'Confirm password *', fullWidth: true }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  },

  async getNextStep({ userType, completeSignup, formMethods }) {
    switch (userType) {
      case USER_TYPE.APPLICANT: {
        return SIGNUP_STEP.ABOUT_YOU;
      }

      case USER_TYPE.PARENT: {
        return SIGNUP_STEP.PARENTS_CONFIRMATION;
      }

      case USER_TYPE.COUNSELOR: {
        await completeSignup();
        break;
      }

      case USER_TYPE.DONOR: {
        return SIGNUP_STEP.DONOR_ORGANIZATION;
      }

      case USER_TYPE.RECOMMENDER: {
        await completeSignup();
        break;
      }
      case USER_TYPE.PROFESSIONAL: {
        await completeSignup();
        break;
      }
    }

    return null;
  },

  renderControls(props) {
    const { formMethods, userType, changeStep, loadingStatus } = props;
    let buttonlabel = 'Sign Up';
    if (userType === USER_TYPE.APPLICANT || userType === USER_TYPE.DONOR) {
      buttonlabel = 'Next';
    }

    // we validate our input and then processing to next step
    const stepSubmitHandler = formMethods.handleSubmit(async () => {
      loadingStatus.startLoading();
      const nextStep = await this.getNextStep(props);
      if (nextStep) {
        changeStep(nextStep);
      }
    });

    const returnHandler = () => {
      // We're on first registration step. There might be a scenario that we came to this page from homepage(then we have returnUrl set)
      // Return to returnUrl in case thats the case
      const urlParams = new URLSearchParams(window.location.search);
      const returnUrl = urlParams.get('returnUrl');
      if (returnUrl) {
        window.location.href = returnUrl;
        return;
      }

      changeStep(SIGNUP_STEP.LOGIN_START);
    };

    return (
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiFlexItem>
          <EuiButton size="s" isDisabled={loadingStatus.isLoading} onClick={returnHandler} color="text">
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton
            type="submit"
            color="primary"
            size="s"
            isDisabled={loadingStatus.isLoading}
            onClick={stepSubmitHandler}
            fill
          >
            {buttonlabel}
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default newUserRegistrationStep;
