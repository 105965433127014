import React from 'react';
import { useState, useRef } from 'react';

import './Tooltip.scss';

export default function (props) {
  const [show, setShow] = useState(0);
  const { position } = props;

  const timer = useRef(null);

  function onOut() {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (show) {
      setShow(1);

      timer.current = setTimeout(() => setShow(0), 200);
    }
  }

  function onOver() {
    timer.current = setTimeout(() => {
      setShow(1);

      timer.current = setTimeout(() => setShow(2), 1);
    }, 400);
  }

  return (
    <div {...props} className={props.className + ' Tooltip'} onMouseOver={onOver} onMouseOut={onOut}>
      {props.children}
      {show ? (
        <div className={'tooltip-text' + (show === 2 ? ' show' : '') + ' ' + position}>
          {!position ? <div className="arrow" /> : null} {props.text}
        </div>
      ) : null}
    </div>
  );
}
