import { useFormContext } from 'react-hook-form';
import { object, array, string } from 'yup';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiText, EuiTitle } from '@elastic/eui';

import recurrentPaymentsQuery from './queries/recurrentPaymentsQuery';
import { ParsedRecurrentRecords } from './RecurrentPaymentsVerification/ParsedRecurrentRecords';
import { RecurrentPaymentsConfigurationForm } from '../RecurringAwardsSetup.interface';

function RecurrentPaymentsVerification() {
  const formContext = useFormContext<RecurrentPaymentsConfigurationForm>();
  const fileId = formContext.getValues('file_id');

  const { data, isLoading, isError, error } = recurrentPaymentsQuery.useRecurrentPaymentFileValidation(fileId, {
    onSuccess: parsedData => {
      formContext.setValue('records', parsedData.records);
      formContext.setValue('headers', parsedData.headers);
    }
  });

  if (isLoading) {
    return (
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem>
          <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (isError) {
    return (
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem>
          <EuiCallOut title="Oops, something went wrong" color="danger" iconType="alert">
            {error?.message || 'Unknown error during validation.'}
          </EuiCallOut>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  if (!data?.recordsCount || !data?.isSuccess) {
    return (
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem>
          <EuiText color={'warning'}>
            No applications were recognized based on uploaded file. Please, check file structure.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return (
    <EuiFlexGroup direction="column" responsive={false}>
      <EuiFlexItem>
        <EuiTitle size="s">
          <h3>Parsed data verification</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText color={'primary'}>
          We were able to recognize {data.records.length} records about ongoing recurrent payments. Please, review data
          carefully before processing further.
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <ParsedRecurrentRecords headers={data.headers} records={data.records} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

RecurrentPaymentsVerification.validationRules = object({
  records: array(object({})),
  headers: array(string())
});

export default RecurrentPaymentsVerification;
