import React from 'react';
import {
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiButtonEmpty
} from '@elastic/eui';
import './NQSChart';
import './NQSChartModal.scss';
import NQSChart from '@donors/routes/DonorHomepage/NewQualifyingStudentsNumberBlock/NQSChart';
import { Datum } from '@elastic/charts/dist/utils/common';

const NQSChartModal = ({ closeModal, data }: { closeModal: () => void; data: Datum[] }) => {
  return (
    <EuiModal className={'NQSChartModal'} onClose={() => closeModal()}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>New Qualifying Students</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <NQSChart data={data} />
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default NQSChartModal;
