import { cleanHistory, formStateAtomHistory } from '../atoms/formState';

export default function useFormUndoRedo() {
  const historyLength = formStateAtomHistory.length;

  return {
    isAvailable: historyLength > 0,
    flush: () => cleanHistory(),
    undo: () => {
      const lastItem = formStateAtomHistory.pop();
      if (lastItem) {
        lastItem.undo();
      }
    }
  };
}
