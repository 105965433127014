import React, { useEffect, useState } from 'react';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiTextArea,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow
} from '@elastic/eui';
import { FormChangeRequestModelShape } from '@sharedComponents/models';

const RequestChangesModal = ({
  closeModal,
  changeRequest,
  requestFormChanges
}: {
  closeModal: () => void;
  changeRequest: FormChangeRequestModelShape;
  requestFormChanges: (changes, onlySave?, successCallback?, errorCallback?) => void;
}) => {
  const [changes, setChanges] = useState('');
  const [actionProcessing, setActionProcessing] = useState<string>();
  const [areFormChangesBeingRequested, setAreFormChangesBeingRequested] = useState(false);

  useEffect(() => {
    setChanges(changeRequest.reviews[0].changes || '');
  }, [changeRequest]);

  const isSaveProcessing = actionProcessing === 'save',
    isSubmitProcessing = actionProcessing === 'submit';

  const onRequestFormChanges = (changes, onlySave) => {
    setActionProcessing(onlySave ? 'save' : 'submit');
    setAreFormChangesBeingRequested(true);

    requestFormChanges(
      changes,
      onlySave,
      () => {
        // success callback
        setActionProcessing(undefined);
        setAreFormChangesBeingRequested(false);
        closeModal();
      },
      () => {
        // error callback
        setActionProcessing(undefined);
        setAreFormChangesBeingRequested(false);
      }
    );
  };

  return (
    <EuiModal onClose={() => closeModal()} style={{ minWidth: 400 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Request Changes</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFormRow
          helpText={`Describe the changes you would like made to the application form. The information will be sent to Scholar's
          App, and a team member will email you directly when the changes have been made so you can continue the review
          process.`}
          fullWidth
        >
          <EuiTextArea value={changes} onChange={e => setChanges(e.target.value)} fullWidth />
        </EuiFormRow>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup responsive={false} justifyContent={'flexEnd'} gutterSize="s">
          <EuiFlexItem>
            <EuiButton
              isLoading={areFormChangesBeingRequested && isSaveProcessing}
              isDisabled={!changes || isSubmitProcessing}
              onClick={() => onRequestFormChanges(changes, true)}
            >
              Save
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              isLoading={areFormChangesBeingRequested && isSubmitProcessing}
              isDisabled={!changes || isSaveProcessing}
              onClick={() => onRequestFormChanges(changes, false)}
              fill
            >
              Submit
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default RequestChangesModal;
