import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiBreadcrumbs } from '@elastic/eui';
import { SCHOLARSHIP_ENTRY_STEPS_NAMES } from '../config';

export default function ScholarshipEntryBreadcrumbs({ currentStep, scholarshipName }) {
  const breadcrumbs = [
    {
      text: 'Scholarship Entry'
    }
  ];

  if (scholarshipName) {
    breadcrumbs.push({
      text: scholarshipName
    });
  }

  if (SCHOLARSHIP_ENTRY_STEPS_NAMES[currentStep]) {
    breadcrumbs.push({
      text: SCHOLARSHIP_ENTRY_STEPS_NAMES[currentStep]
    });
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem grow={false}>
        <EuiBreadcrumbs breadcrumbs={breadcrumbs} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
