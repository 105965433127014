import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiInputPopover } from '@elastic/eui';
import React, { useEffect, useState } from 'react';

import SearchEntity from './SearchDropdown/SearchEntity';
import useDebounce from '@sharedComponents/hooks/useDebounce';

/**
 * TODO: minvalue length to trigger executor
 * ! not the best implementation - could Be cropped
 * @description Abstract search dropdown component with debounce
 */
export default function SearchDropdown({
  placeholder,
  searchExecutor,
  value,
  searchCallback,
  searchResultsChanged,
  onValueReset
}: {
  placeholder: string;
  searchExecutor: (searchString: string) => Promise<SearchEntity[] | null>; // executes search
  value?: string;
  searchCallback?: (searchResult: SearchEntity) => void; // emits single selected search result to parent
  searchResultsChanged?: (searchString: string, resultsAreEmpty: boolean) => void;
  onValueReset?: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState(value || '');
  const [searchResults, setSearchResults] = useState<Array<SearchEntity>>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  // Debounce search term so that it only gives us latest value if searchTerm has not been updated within some time
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 750);

  const notifyParentAboutResults = (searchString: string, resultsAreEmpty: boolean): void => {
    if (searchResultsChanged) {
      searchResultsChanged(searchString, resultsAreEmpty);
    }
  };

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        searchExecutor(debouncedSearchTerm)
          .then(results => {
            setIsSearching(false);
            if (results && results.length) {
              setResultMessage('');
              notifyParentAboutResults(debouncedSearchTerm, false);
              return setSearchResults(results);
            }

            if (results === null) {
              // in case search results === null, then search popup/result is handled outside and shouldnt appear here
              return;
            }

            if (searchResultsChanged) {
              searchResultsChanged(debouncedSearchTerm, true);
            } else {
              setResultMessage('No results found.');
            }
          })
          .catch((e: Error) => {
            setIsSearching(false);
            setResultMessage(e.message);
          });
      }

      setSearchResults([]);
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  // Value changes from external components [for external resetting of value]
  useEffect(() => {
    if (value === '') {
      setSearchValue(value);
    }
  }, [value]);

  const clear = () => {
    setResultMessage('');
    setSearchTerm('');
    setSearchResults([]);
    notifyParentAboutResults('', true);
  };

  const setValue = e => {
    const newValue = e.target.value;
    newValue ? setSearchValue(newValue) : reset();
    setSearchTerm(e.target.value);
  };

  const reset = () => {
    setSearchValue('');
    setResultMessage('');
    setSearchResults([]);
    onValueReset && onValueReset();
  };

  const onSelectResult = selectedResult => {
    setSearchValue(selectedResult?.displayValue);
    searchCallback && searchCallback(selectedResult);
    clear();
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toggleIsPopoverOpen = (shouldBeOpen = !isPopoverOpen) => {
    setIsPopoverOpen(shouldBeOpen);
  };

  const searchInput = (
    <EuiFieldSearch
      placeholder={placeholder}
      value={searchValue}
      isLoading={isSearching}
      onChange={setValue}
      aria-autocomplete="list"
      aria-haspopup="listbox"
      name="searchterm"
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="false"
      spellCheck={false}
      maxLength={255}
      onFocus={() => toggleIsPopoverOpen()}
      // style={{ width: '260px' }}
    />
  );

  return (
    <div role="search" aria-label="Search">
      <form
        role="search"
        itemProp="potentialAction"
        itemScope
        itemType="https://schema.org/SearchAction"
        onSubmit={event => {
          event.preventDefault();
        }}
        noValidate
      >
        <EuiInputPopover
          input={searchInput}
          isOpen={isPopoverOpen}
          closePopover={() => {
            toggleIsPopoverOpen(false);
          }}
          panelPaddingSize="none"
        >
          {resultMessage ? (
            <div style={{ padding: '4px 8px' }}>{resultMessage}</div>
          ) : searchResults?.length ? (
            <EuiFlexGroup direction="column" gutterSize="s">
              {searchResults.map((result, i) => (
                <EuiFlexItem
                  key={i}
                  onClick={() => onSelectResult(result)}
                  style={{
                    padding: '4px 8px',
                    cursor: 'pointer'
                  }}
                >
                  {result.toString()}
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          ) : null}
        </EuiInputPopover>
      </form>
    </div>
  );
}
