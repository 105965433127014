import warn from './warn';

export interface Storage {
  getItem(key: string): Object | undefined;
  setItem(key: string, value: Object): void;
}

const realLocalStorage: Storage = {
  getItem(key: string) {
    const json = window.localStorage.getItem(key);

    if (json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        warn(`Invalid value ${json} in cache key ${key}`);
      }
    }

    return null;
  },

  setItem(key: string, value: Object) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e: any) {
      if (e.toString().includes('QuotaExceededError')) {
        window.localStorage.clear();
      } else {
        warn(`While storing ${key}: ${e.toString()}`);
      }
    }
  }
};

export const inMemoryStorage = {
  data: {},

  getItem(key: string) {
    return this.data[key];
  },

  setItem(key: string, value: Object) {
    this.data[key] = value;
  }
};

export const noStorage: Storage = {
  getItem() {
    return undefined;
  },

  setItem() {}
};

export function isLocalStorageSupported() {
  try {
    return window.localStorage != null;
  } catch (e) {
    return false;
  }
}

export default function getLocalStorage(): Storage {
  if (isLocalStorageSupported()) {
    return realLocalStorage;
  } else {
    return inMemoryStorage;
  }
}
