/* eslint-disable react-hooks/exhaustive-deps */
import { useErrorReporter } from '@donors/base/useErrorReporter';
import ButtonBar from '@donors/components/ButtonBar';
import ValidatingField, { useSubmittable } from '@donors/components/ValidatingField';
import { validateEmail } from '@donors/components/validations';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import useSingleSubmit from '../useSingleSubmit';
import { HeaderProps } from '../components/Header';
import Field from '@donors/components/Field';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

export const noValue = '—';

export default function EditDonorPage(
  props: {
    donorId: number;
    history: History;
  } & HeaderProps
) {
  const { donorId, history } = props;
  const userClient = useUserClient();

  const [onError, errorMessage] = useErrorReporter();
  const [singleSubmit, isSubmitting] = useSingleSubmit();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [canReview, setCanReview] = useState<boolean>(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    userClient
      .getDonor(donorId)
      .then(donor => {
        setName(donor.name);
        setEmail(donor.email || '');
        setCanReview(!!donor.canReview);
        setLoaded(true);

        document.title = `${donor.name} – Scholar's App`;
      })
      .catch(onError);
  }, []);

  const { isSubmittable, validatingFieldDefaults, setSubmitted } = useSubmittable();

  function onSubmit() {
    setSubmitted(true);

    if (isSubmittable() && name) {
      userClient
        .updateDonor({
          name,
          email,
          canReview,
          id: donorId
        })
        .then(() => history.push(`/donors/${donorId}`), onError);
    }
  }

  function onCancel() {
    history.push(`/donors/${donorId}`);
  }

  return (
    <div className="EditDonor">
      <div className="body ">
        <div className="content">
          {errorMessage}
          <div className="pageTitle">
            <h4>Donor Organization</h4>
            <h2>{name || 'Donor'}</h2>
          </div>
          {isLoaded ? (
            <form
              onSubmit={e => {
                e.preventDefault();
                singleSubmit(onSubmit)(e);
              }}
              className="EditDonor"
            >
              <ValidatingField
                label="Name"
                id="name"
                value={name}
                setValue={setName}
                validate={str => (!str ? 'Required' : str.length > 120 ? 'Too long' : null)}
                renderChildren={props => <input {...props} type="text" />}
                {...validatingFieldDefaults}
              />

              <ValidatingField
                label="Email"
                id="email"
                value={email}
                setValue={setEmail}
                validate={str => (str && validateEmail(str)) || null}
                renderChildren={props => <input {...props} type="text" />}
                {...validatingFieldDefaults}
              />

              <Field label="Can review applications" htmlFor="canReview">
                <input
                  id="canReview"
                  type="checkbox"
                  defaultChecked={canReview}
                  onChange={e => setCanReview(e.target.checked)}
                />
              </Field>
              <ButtonBar>
                <button className="button" disabled={isSubmitting}>
                  Save
                </button>
                <button
                  className="button"
                  onClick={e => {
                    e.preventDefault();
                    onCancel();
                  }}
                >
                  Cancel
                </button>
              </ButtonBar>
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
}
