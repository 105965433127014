import './Thumbnail.scss';

import React from 'react';

import { EuiButton } from '@elastic/eui';
import { ApplicationClient } from '@sharedClients/ApplicationClient';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

export default function Thumbnail({
  fileName,
  fileId,
  applicationId,
  isImage,
  onError
}: {
  fileId?: number;
  fileName?: string;
  applicationId: number;
  isImage?: boolean;
  onError: (e: any) => void;
}) {
  const applicationClient = useApplicationClient();
  const onPreviewLinkClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (fileId != null) {
      openGoogleDocs(fileId, { applicationClient, applicationId, onError });
    }
  };

  const onDownloadLinkClick = (e: React.FormEvent) => {
    e.preventDefault();

    if (fileId != null) {
      downloadPdf(fileId, { applicationClient, applicationId, onError });
    }
  };

  return fileId != null ? (
    <div className="ThumbnailActions">
      <span>{fileName}</span>
      {!isImage ? (
        <EuiButton size="s" iconType="inspect" onClick={onPreviewLinkClick} aria-label="Preview using Google Documents">
          Preview using Google Documents
        </EuiButton>
      ) : null}
      <EuiButton size="s" iconType="download" onClick={onDownloadLinkClick} aria-label="Download">
        Download
      </EuiButton>
    </div>
  ) : null;
}

export async function openGoogleDocs(
  id: number,
  {
    applicationClient,
    applicationId,
    onError
  }: {
    applicationClient: ApplicationClient;
    applicationId: number;
    onError: (e: any) => void;
  }
) {
  applicationClient
    .getFileUrl(id, applicationId)
    .then(({ url }) => {
      const baseUrl = applicationClient['baseUrl'];
      let urlWithDomain = baseUrl + url;

      if (!baseUrl.startsWith('http://localhost')) {
        urlWithDomain = 'https://docs.google.com/gview?url=' + encodeURIComponent(urlWithDomain);
      }

      window.open(urlWithDomain, '_' + id.toString());
    })
    .catch(onError);
}

export async function downloadPdf(
  id: number,
  {
    applicationClient,
    applicationId,
    onError
  }: {
    applicationClient: ApplicationClient;
    onError: (e: any) => void;
    applicationId?: number;
  }
) {
  applicationClient
    .getFileUrl(id, applicationId || undefined)
    .then(({ url }) => {
      const baseUrl = applicationClient['baseUrl'];
      let urlWithDomain = baseUrl + url;
      window.open(urlWithDomain);
    })
    .catch(onError);
}
