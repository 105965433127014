import './ScholarshipApplications.scss';

import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import ReviewRoundsSetup from '@donors/scholarship/ReviewRoundsSetup';
import {
  EuiButtonGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLoadingContent,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText
} from '@elastic/eui';
import SearchInput from '@sharedComponents/common/SearchInput';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import ApplicationsCategories from '@sharedComponents/ScholarshipApplications/ApplicationsCategories';
import UserModel from '@sharedContract/UserModel';

import AdvancedUserList from './common/AdvancedUserList';
import { USER_TYPE } from './constants';
import { getAwardStatusName } from './interfaces/Applications.interface';
import { Page } from './interfaces/CommonData.interface';
import { ApplicationModelShape, UserModelShape } from './models';
import { APPLICATION_TYPE } from './ScholarshipApplications.interface';
import { ApplicationsList } from './ScholarshipApplications/ApplicationsList';
import { VIEW_MODE } from './ScholarshipApplications/config';
import NotifyNonAwardedModal from './ScholarshipApplications/NotifyNonAwardedModal';
import Operations from './ScholarshipApplications/Operations';
import scholarshipWithReviewStatusQuery from './ScholarshipApplications/queries/scholarshipWithReviewStatusQuery';
import ReviewRoundsStatusBar from './ScholarshipApplications/ReviewRoundsStatusBar';
import ScholarshipApplicationsBreadcrumbs from './ScholarshipApplications/ScholarshipApplicationsBreadcrumbs';
import { useLoggedInUser } from './selectors/loggedInUserSelectors';
import { useApplicationClient } from './selectors/useApplicationClient';
import useActiveCategory from './ScholarshipApplications/hooks/useActiveCategory';
import useActiveCategoryApplications from './ScholarshipApplications/hooks/useActiveCategoryApplications';
import RecurrentAwardsListing from './ScholarshipApplications/RecurrentAwardsListing';

/**
 * Can be accesed by counselors from donor org with same access as donors. Has own wrapper in apply app, to modify some styles
 */
export default function ScholarshipApplications({ scholarshipId }: { scholarshipId: number }) {
  const user = useLoggedInUser()!;
  const applicationClient = useApplicationClient();
  const history = useHistory();
  const [viewMode, setViewMode] = useState(VIEW_MODE.DEFAULT);
  const [maxRounds, setMaxRounds] = useState(0); // TODO:IMP: should be moved from here
  const [isNotifyNonAwardedModalOpen, setNotifyNonAwardedModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { data: scholarship, isLoading: isScholarshipLoading } =
    scholarshipWithReviewStatusQuery.useScholarshipWithReviewStatus(applicationClient, scholarshipId);

  const [applicationCycle, setApplicationCycle] = useState<number>(0);

  const isReviewing = user.type === USER_TYPE.REVIEWER;

  const [sort, setSort] = useState<string>('DESC');

  const { value: activeCategory } = useActiveCategory();
  const [pageIndex, setPageIndex] = useState(0);

  const [textFilter, setTextFilter] = useState<string>('');

  const {
    applications,
    applicationsToScholarship,
    isLoading: isApplicationsLoading
  } = useActiveCategoryApplications(scholarshipId, textFilter, applicationCycle, pageIndex, sort);

  useEffect(() => {
    document.title = `Scholar's App - Applications to ${scholarship?.name}`;
  }, [scholarship]);

  const handleScholarshipNameClick = () => {
    setViewMode(VIEW_MODE.DEFAULT);
  };

  const title = (
    <div className="pageTitle">
      <h4>Applicant Report</h4>
      <h2>{scholarship ? <a onClick={handleScholarshipNameClick}>{scholarship.name}</a> : <span>Loading...</span>}</h2>
    </div>
  );

  if (isScholarshipLoading) {
    return (
      <div className="scholarshipApplications">
        <div className="content">
          {title}
          <EuiSpacer size="xxl" />
          <EuiLoadingContent lines={8} />
        </div>
      </div>
    );
  }

  const canHaveReviews = scholarship && !scholarship.apply_url;

  const notifyNonAwardedModal = isNotifyNonAwardedModalOpen ? (
    <NotifyNonAwardedModal
      applicationClient={applicationClient}
      scholarship={scholarship}
      applicationCycle={applicationCycle}
      onClose={() => setNotifyNonAwardedModalOpen(false)}
      openLatestReviewRound={() => setViewMode(VIEW_MODE.REVIEW_VIEW)}
    />
  ) : null;

  const reviewRoundStatusBar = canHaveReviews ? (
    <EuiFlexItem>
      <ReviewRoundsStatusBar
        scholarship={scholarship}
        candidatesAmount={applications.total}
        canEdit={!isReviewing}
        filter={activeCategory}
        onSetupStart={maxRound => {
          setMaxRounds(maxRound);
          setViewMode(VIEW_MODE.REVIEW_SETUP);
        }}
        onRoundEdit={() => setViewMode(VIEW_MODE.REVIEW_SETUP)}
        onLastRoundDetails={() => setViewMode(VIEW_MODE.REVIEW_VIEW)}
        onNotifyNonAwarded={() => setNotifyNonAwardedModalOpen(true)}
      />
    </EuiFlexItem>
  ) : null;

  // TODO:IMP: figure out scholarship states in component and break this into smaller ones
  let recordsListing = <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />;
  if (!isApplicationsLoading) {
    if (!applications?.total) {
      recordsListing = <EuiText textAlign="center">No applications to show.</EuiText>;
    } else {
      switch (activeCategory) {
        case APPLICATION_TYPE.QUALIFIED: {
          recordsListing = (
            <AdvancedUserList
              users={applications?.results as unknown as UserModelShape[]}
              type={USER_TYPE.APPLICANT}
              total={applications?.total || 0}
              currentPage={pageIndex}
              onPageChange={pageIndex => setPageIndex(pageIndex)}
              isLoading={isApplicationsLoading}
              rowClickHandler={item =>
                // not allowing opening user profiles for counselor, since there's no such route for counselors portal
                user.type === USER_TYPE.COUNSELOR ? null : history.push(`/users/${item.id}`)
              }
              pageSize={25}
            />
          );

          break;
        }

        case APPLICATION_TYPE.RECURRING: {
          recordsListing = <RecurrentAwardsListing rewardedUsers={applications.results} />;
          break;
        }

        default: {
          recordsListing = (
            <ApplicationsList
              scholarship={scholarship}
              applications={applications}
              onPageChange={({ page = {} }) => {
                const { index: pageIndex } = page as any;

                setPageIndex(pageIndex);
              }}
              sort={sort}
              onSortChange={sort => setSort(sort)}
              type={activeCategory}
              extraColumns={
                activeCategory === APPLICATION_TYPE.AWARDED
                  ? [
                      {
                        field: '',
                        name: 'Status',
                        sortable: false,
                        render: (application: ApplicationModelShape) => {
                          if (!application.award_status) {
                            return <></>;
                          }

                          return <EuiText size="xs">{getAwardStatusName(application.award_status)}</EuiText>;
                        }
                      }
                    ]
                  : scholarship?.hasScreeningScore
                  ? [
                      {
                        field: '',
                        name: 'Auto Score',
                        sortable: false,
                        align: 'center',
                        render: (application: ApplicationModelShape) => {
                          if (!application?.prequalify_score) {
                            return <></>;
                          }

                          return <EuiText size="s">{application.prequalify_score}</EuiText>;
                        }
                      }
                    ]
                  : []
              }
              pageIndex={pageIndex}
            />
          );
        }
      }
    }
  }

  return (
    <PageContentWrapper className="scholarshipApplications">
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiFlexItem grow={false}>
              <ScholarshipApplicationsBreadcrumbs
                scholarship={scholarship}
                viewMode={viewMode}
                onViewModeReset={() => setViewMode(VIEW_MODE.DEFAULT)}
              />
            </EuiFlexItem>

            <EuiFlexItem>{title}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {viewMode === VIEW_MODE.DEFAULT && scholarship ? (
          <>
            <EuiFlexItem>
              <ApplicationsCategories
                scholarship={scholarship}
                isReviewing={isReviewing}
                applicationsFetchResult={applicationsToScholarship}
              />
            </EuiFlexItem>
            {reviewRoundStatusBar}
            <EuiHorizontalRule size="full" />

            <EuiFlexItem>
              <EuiFlexGroup responsive={false} direction="row">
                <EuiFlexItem>
                  {scholarship?.applicationCycles?.length &&
                  !isReviewing &&
                  activeCategory !== APPLICATION_TYPE.RECURRING ? (
                    <EuiFlexGroup gutterSize="none">
                      <EuiFlexItem grow={false} style={{ alignSelf: 'center', textAlign: 'end', marginLeft: '10px' }}>
                        <h4 style={{ color: '#e47446' }}>Application cycle:</h4>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
                        <EuiButtonGroup
                          className="applicationCycle"
                          name="coarsness"
                          legend="Application Cycle filter"
                          options={[
                            ...scholarship?.applicationCycles?.map(cycle => ({
                              id: cycle.toString(),
                              label: cycle.toString()
                            }))
                          ]}
                          idSelected={
                            scholarship?.applicationCycles?.length === 1
                              ? scholarship?.applicationCycles[0].toString()
                              : applicationCycle.toString()
                          }
                          onChange={id => {
                            setApplicationCycle(+id);
                          }}
                          buttonSize="compressed"
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  ) : null}
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ alignItems: 'flex-end' }}>
                  <SearchInput value={textFilter} placeholder="Search for application" onValueChange={setTextFilter} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <Operations
                applicationClient={applicationClient}
                scholarshipId={scholarshipId}
                user={user}
                searchText={textFilter}
                applicationCycle={applicationCycle}
              />
            </EuiFlexItem>

            <EuiFlexItem>{recordsListing}</EuiFlexItem>
            {notifyNonAwardedModal}
          </>
        ) : (
          scholarship &&
          !isScholarshipLoading && (
            <ReviewRoundsSetup
              scholarship={scholarship}
              applicationClient={applicationClient}
              textFilter={textFilter}
              applicationCycle={applicationCycle}
              maxRounds={maxRounds}
              isPastResultView={viewMode === VIEW_MODE.REVIEW_VIEW}
              onReviewProcessChange={isReviewFinished => {
                if (isReviewFinished) {
                  setViewMode(VIEW_MODE.REVIEW_VIEW);
                }

                // we reset and invalidate in order to trigger 'loading' state once again. Thats the way which worked, maybe not the best one
                queryClient.resetQueries(scholarshipWithReviewStatusQuery.QUERY_KEY, { exact: true });
                queryClient.invalidateQueries(scholarshipWithReviewStatusQuery.QUERY_KEY);
              }}
            />
          )
        )}
      </EuiFlexGroup>
    </PageContentWrapper>
  );
}
