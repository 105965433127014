import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { EuiFlexGroup, EuiFlexItem, formatDate, EuiBadge } from '@elastic/eui';
import AbstractListing, { Column } from '@sharedComponents/common/AbstractListing';
import SearchInput from '@sharedComponents/common/SearchInput';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import { FormsModelShape } from '@sharedComponents/models';

import formsQuery from './Forms/queries/formsQuery';
import FloatingActionButton from '@sharedComponents/common/FloatingActionButton';
import { USER_TYPE } from '@sharedComponents/constants';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { FORM_CHANGE_REQUEST_STATUSES } from '@sharedComponents/interfaces/Scholarships.interface';
import { formatFormStatus } from '@sharedComponents/utils/scholarshipUtils';
import { SPECIAL_FORM_TYPE } from '@sharedComponents/interfaces/Forms.interface';

const formUrl = id => `/forms/${id}`;

const Forms = ({ history }) => {
  const [textFilter, setTextFilter] = useLocalStorage<string>('forms.textFilter', '');

  const [pageIndex, setPageIndex] = useState(0);
  const { data, isLoading } = formsQuery.useForms(pageIndex, textFilter, ['changeRequest']);

  const columns: Column[] = [
    {
      id: 'name',
      label: 'Form Name',
      width: '50%',
      render: (form: FormsModelShape) => {
        return (
          <EuiFlexGroup direction="row" gutterSize="none">
            <EuiFlexItem>
              <Link to={formUrl(form.id)} key={form.id}>
                {form.name}
              </Link>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }
    }
  ];

  columns.push({
    id: 'status',
    label: 'Form Status',
    align: 'left',
    width: '15%',
    render: (form: FormsModelShape) => {
      return form.changeRequest ? (
        <EuiBadge
          color={
            form.changeRequest.status === FORM_CHANGE_REQUEST_STATUSES.REVIEW_REQUESTED
              ? 'default'
              : form.changeRequest.status === FORM_CHANGE_REQUEST_STATUSES.CHANGES_REQUESTED
              ? 'warning'
              : 'success'
          }
        >
          {formatFormStatus(form.changeRequest.status!)}
        </EuiBadge>
      ) : null;
    }
  });

  columns.push({
    id: 'user_type',
    label: 'Form Type',
    align: 'left',
    render: (form: FormsModelShape) => {
      if (form.special_type === SPECIAL_FORM_TYPE.RECURRENT_APPLICATION) {
        return <EuiBadge color={'accent'}>repayments</EuiBadge>;
      }

      return (
        <EuiBadge color={form.user_type === USER_TYPE.APPLICANT ? 'primary' : 'default'}>
          {form.user_type === USER_TYPE.APPLICANT ? 'students' : 'recommenders'}
        </EuiBadge>
      );
    }
  });

  columns.push({
    id: 'updated_at',
    label: 'Last Updated',
    align: 'center',
    render: (form: FormsModelShape) => {
      return `${formatDate(form.updated_at, 'LL')}`;
    }
  });

  columns.push({
    id: 'created_at',
    label: 'Created At',
    align: 'right',
    render: (form: FormsModelShape) => {
      return `${formatDate(form.created_at, 'LL')}`;
    }
  });

  const onCreateClickHandler = () => {
    history.push('/forms/add');
  };

  const onRowClickHandler = form => {
    history.push(formUrl(form.id));
  };

  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SearchInput
                value={textFilter}
                placeholder="Form Name"
                onValueChange={setTextFilter}
                isLoading={isLoading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <AbstractListing
            isLoading={isLoading}
            columns={columns}
            records={data?.results}
            total={data?.total}
            onRowClick={onRowClickHandler}
            pageIndex={pageIndex}
            onPageChange={pageIndex => setPageIndex(pageIndex)}
            pageSize={50}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <FloatingActionButton onClick={onCreateClickHandler} tooltip="New Form" />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

const FormsWithRouter = withRouter(Forms);

export default FormsWithRouter;
