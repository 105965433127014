import React, { FunctionComponent, PropsWithChildren } from 'react';

interface FieldProps {
  label: string | JSX.Element;
  htmlFor?: string;
  id?: string;
  help?: string | JSX.Element;
  className?: string;
  style?: any;
}

const Field: FunctionComponent<PropsWithChildren<FieldProps>> = props => {
  const { label, htmlFor, id, help, className, style } = props;
  return (
    <div style={style} className={'field' + (className ? ' ' + className : '')} id={id}>
      <label htmlFor={htmlFor}>{label}</label>
      {props.children}
      {help ? <div className="help">{help}</div> : null}
    </div>
  );
};

export default Field;
