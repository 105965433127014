/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent } from 'react';
import 'react-slidedown/lib/slidedown.css';

import Field from '@sharedComponents/base/components/Field';
import NumberBubble from '@sharedComponents/base/components/NumberBubble';
import { MinusIcon, PlusIcon } from '@sharedComponents/base/PlusMinusIcon';
import useAnimatingList from '@sharedComponents/base/useAnimatingList';
import { ScholarshipFaqQuestionAnswer } from '@sharedClients/types/ScholarshipModel';
import './FaqQuestionAnswer.scss';

export default function FaqQuestionAnswer({
  value,
  setValue
}: {
  value: ScholarshipFaqQuestionAnswer[];
  setValue: (value: ScholarshipFaqQuestionAnswer[]) => void;
}) {
  const { renderListItem, animateListItem } = useAnimatingList(50);
  let emptymdodel: ScholarshipFaqQuestionAnswer = { question: '', answer: '' };

  const add = async () => {
    value.push(emptymdodel);
    setValue(value);
    animateListItem(value.length, true);
  };

  const remove = async (i: number) => {
    await animateListItem(i, false);

    let newValue = value.slice(0);
    newValue.splice(i, 1);

    setValue(newValue);
  };

  const label = '';

  return (
    <Field label={label} htmlFor="faqquestioans" help="" style={{ marginTop: '0px' }}>
      <h3>FAQ</h3>
      <div id="faqquestioans" className="faqquestionanswer">
        <div>
          {atLeastOne(value)
            .map((listItem: ScholarshipFaqQuestionAnswer, i: number) => (
              <div className="list-faqQA" key={i}>
                <NumberBubble number={i + 1} />
                <div className="faqQAfields faq-question">
                  <label>Question</label>
                  <input
                    type="text"
                    value={listItem.question}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newValue = value.slice(0);
                      newValue[i].question = e.target.value;

                      setValue(newValue);
                    }}
                  />
                </div>

                <div className="faqQAfields faq-answer">
                  <label>Answer</label>
                  <input
                    type="text"
                    value={listItem.answer}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newValue = value.slice(0);
                      newValue[i].answer = e.target.value;

                      setValue(newValue);
                    }}
                  />
                </div>
                {value.length > 1 ? (
                  <button
                    className="button invisible delete"
                    onClick={e => {
                      e.preventDefault();
                      remove(i);
                    }}
                  >
                    <div className="icon">
                      <MinusIcon />
                      <div className="label">Remove</div>
                    </div>
                  </button>
                ) : null}
              </div>
            ))
            .map(renderListItem)}
        </div>

        <button
          className="button invisible add"
          onClick={e => {
            e.preventDefault();
            add();
          }}
        >
          <div className="icon">
            <PlusIcon />
          </div>
          <div className="label">Add another</div>
        </button>
      </div>
    </Field>
  );
}

export function isList(object: any) {
  return typeof object == 'object' && object.length != null;
}

function atLeastOne(items: ScholarshipFaqQuestionAnswer[]) {
  if (items.length) {
    return items;
  } else {
    let emmdoel: ScholarshipFaqQuestionAnswer = { question: '', answer: '' };
    items.push(emmdoel);
    return items;
  }
}
