import './SubmissionForm.scss';

import { isNil } from 'lodash';
import React from 'react';

import { AVAILABLE_ACTIONS } from '@sharedComponents/interfaces/Applications.interface';

import { formatParagraphText } from '../base/MarkdownLite';
import FormAcceptAward from './fields/FormAcceptAward';
import FormCounselorStatus from './fields/FormCounselorStatus';
import FormFieldCheckbox from './fields/FormFieldCheckbox';
import FormFieldConditional from './fields/FormFieldConditional';
import FormFieldInput from './fields/FormFieldInput';
import FormFieldLabel from './fields/FormFieldLabel';
import FormList from './fields/FormList';
import FormMessage from './fields/FormMessage';
import FormRecommendersList from './fields/FormRecommendersList';
import FormRecommenderStatus from './fields/FormRecommenderStatus';
import FormScreeningField from './fields/FormScreeningField';
import FormUserType from './fields/FormUserType.js';
import { FormNodeProps } from './FormNodeProps.js';

export function FormNodeComponent(props: FormNodeProps) {
  const { node, application } = props;
  let result: JSX.Element | null;
  // looking for `type: recommenders` / `counselor`? It's a kind of macro; look in FormNode.resolveMacros
  if (node == null) {
    result = null;
  } else if (node.action && typeof node.action === 'string' && AVAILABLE_ACTIONS.includes(node.action)) {
    result = null;
    // result = <>Debug action representation: {JSON.stringify(node.action)}</>;
  } else if (node.children && !node.type) {
    result = renderChildren(props);
  } else if (node.type === 'list') {
    result = <FormList {...props} />;
  } else if (node.type === 'recommenders-list') {
    result = <FormRecommendersList {...props} />;
  } else if (node.type === 'section' || node.columns != null) {
    result = <FormSection {...props} />;
  } else if (node.type === 'address') {
    result = <FormAddress {...props} />;
  } else if (node.type === 'page') {
    result = <FormPage {...props} />;
  } else if (node.type === 'recommenderStatus') {
    result = <FormRecommenderStatus {...props} />;
  } else if (node.type === 'counselorStatus') {
    result = <FormCounselorStatus {...props} />;
  } else if (node.type === 'acceptAward') {
    return <FormAcceptAward {...props} />;
  } else if (node.type === 'message') {
    result = <FormMessage {...props} />;
  } else if (node.type === 'userType') {
    result = <FormUserType {...props} />;
  } else if (node.type === 'screening') {
    result = <FormScreeningField {...props} />;
  } else if (node.field === 'essay' && node.type === 'pdf') {
    result = (
      <FormFieldLabel {...props}>
        <FormFieldInput {...props} />

        {application && application.scholarship && application.scholarship.essayInstructions ? (
          <div className="field">
            <div className="help">{formatParagraphText(application.scholarship.essayInstructions)}</div>
          </div>
        ) : null}
      </FormFieldLabel>
    );
  } else if (!isNil(node.field)) {
    if (node.type === 'checkbox') {
      result = <FormFieldCheckbox {...props} />;
    } else {
      result = (
        <FormFieldLabel {...props}>
          <FormFieldInput {...props} />
        </FormFieldLabel>
      );
    }
  } else {
    props.onFormError(
      new Error('Unknown node type ' + JSON.stringify({ ...node, parent: undefined }) + '. Is "field" missing?')
    );

    result = null;
  }

  if (result && node.isConditional()) {
    result = <FormFieldConditional {...props}>{result}</FormFieldConditional>;
  }

  return result;
}

function FormSection(props: FormNodeProps) {
  const { node } = props;

  // "wide" means full page width on wide resolutions
  const className = node.wide ? ' wide' : '';
  return (
    <div className={'FormSection' + (className ? ' ' + className : '')}>
      {node.title ? <h2>{node.title}</h2> : null}
      {node.help ? <div className="help">{node.help}</div> : null}
      {renderChildren(props)}
    </div>
  );
}

function FormAddress(props: FormNodeProps) {
  const { node } = props;
  return (
    <div className="address field">
      {node.title ? <h2>{node.title}</h2> : null}
      <div className="value">{renderChildren(props)}</div>
    </div>
  );
}

/**
 * Pages are a grouping of sections. They should only be used on the top level and may not be nested.
 */
function FormPage(props: FormNodeProps) {
  return (
    <div>
      <div className="page">{renderChildren(props)}</div>
    </div>
  );
}

/**
 * Render the children of a node that has child nodes.
 */
export function renderChildren(props: FormNodeProps) {
  const { node } = props;

  if (node.children && node.children.length) {
    return <ChildList {...props} />;
  } else if (node.columns && node.columns.length) {
    return <ChildColumns {...props} />;
  } else {
    return null;
  }
}

function ChildColumns(props: FormNodeProps) {
  const { node } = props;

  return (
    <div className={node.wide ? 'wide-columns' : 'columns'}>
      {node.getColumns().map((column, i) => (
        <div className="column" key={i}>
          <FormNodeComponent {...props} node={column} />
        </div>
      ))}
    </div>
  );
}

function ChildList(props: FormNodeProps) {
  const { node } = props;

  return (
    <React.Fragment>
      {node.getChildren().map((child, i) => (
        <FormNodeComponent {...props} key={i} node={child} />
      ))}
    </React.Fragment>
  );
}
