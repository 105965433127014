import React from 'react';
import { useFormContext } from 'react-hook-form';

import { EuiSpacer } from '@elastic/eui';
import { ComboboxField } from '@sharedComponents/formElements';
import counties from '@sharedComponents/models/enumValues/counties';
import states from '@sharedComponents/models/enumValues/states';

const residencySelectionOptions = ['Any', 'State', 'County'];
const statesOptions = Object.keys(states).map(key => ({
  label: states[key],
  value: key
}));

const ResidencyEligibilitySelection = ({ rowProps = {}, fieldProps = {} }: { rowProps?; fieldProps? }) => {
  const { watch } = useFormContext();

  // scholarship entry data field
  const { data } = watch(['data']);

  const { residencyMatching = 'Any' } = data || {};

  return (
    <>
      <ComboboxField
        fieldName={'data.residencyMatching'}
        options={residencySelectionOptions}
        fieldProps={{
          fullWidth: true,
          defaultValue: 'Any',
          isClearable: false,
          singleSelection: true,
          ...fieldProps
        }}
        rowProps={{
          label: 'Current Address of Residence',
          ...rowProps
        }}
      />
      <EuiSpacer size="s" />
      {residencyMatching === 'State' ? (
        <ComboboxField
          fieldName={'data.residency'}
          options={statesOptions}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more', ...fieldProps }}
        />
      ) : null}
      {residencyMatching === 'County' ? (
        <ComboboxField
          fieldName={'data.residencyCounties'}
          options={counties}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more', ...fieldProps }}
        />
      ) : null}
    </>
  );
};

ResidencyEligibilitySelection.residencySelectionOptions = residencySelectionOptions;

export default ResidencyEligibilitySelection;
