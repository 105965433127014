import { AddressVerificationType } from '../schemas/MailingModelSchema';

export const MAILING_LAST_STEP_NUMBER = 5;
export const HTMLTemplatePagesCount = 2;

// address verification statuses from LOB
export enum DELIVERABILITY_STATUS {
  OK = 'deliverable',
  UNNECESSARY = 'deliverable_unnecessary_unit',
  INCORRECT = 'deliverable_incorrect_unit',
  MISSING = 'deliverable_missing_unit',
  NOT_OK = 'undeliverable',
  SKIPPED = 'skipped'
}

// todo display hints over statuses
export const DELIVERABILITY_SUMMARY = {
  [DELIVERABILITY_STATUS.OK]: 'The address is deliverable by the USPS',
  [DELIVERABILITY_STATUS.UNNECESSARY]: 'The address is deliverable, but the secondary unit information is unnecessary.',
  [DELIVERABILITY_STATUS.INCORRECT]:
    "The address is deliverable to the building's default address but the secondary unit provided may not exist. There is a chance the mail will not reach the intended recipient.",
  [DELIVERABILITY_STATUS.MISSING]:
    "The address is deliverable to the building's default address but is missing secondary unit information. There is a chance the mail will not reach the intended recipient.",
  [DELIVERABILITY_STATUS.NOT_OK]: 'The address is not deliverable according to the USPS.'
};
export interface MailingVerificationResult {
  hasErrors: boolean;
  // verification response from Lob
  recipients: AddressVerificationType[];
}

export const ADDRESS_PLACEMENT_OPTIONS = ['top_first_page', 'insert_blank_page', 'bottom_first_page'];
export const MAILTYPE_OPTIONS = ['usps_standard', 'usps_first_class'];

export type LobAddressVerificationError = { error: { code: string; message: string; status_code: number } };

export interface MailingConversion {
  recipient: string;
  city: string;
  state: string;
  visits: number;
  timestamps?: number[];
}

// transform text input to be properly rendered by lob template
export function transformHTMLforLOB(html) {
  const lineBreakCharRegex = new RegExp(`\\n`, 'g'); // line breaks
  const lineBreakTagsRegex = new RegExp('<p></p>', 'g'); // empty paragraphs
  const endingParagraphsRegex = new RegExp('</p>', 'g');
  const startingParagraphsRegex = new RegExp('<p', 'g');

  return html
    .replace(lineBreakCharRegex, '')
    .replace(lineBreakTagsRegex, '<br />')
    .replace(endingParagraphsRegex, '</span><br />')
    .replace(startingParagraphsRegex, '<span');
}
