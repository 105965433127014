import React, { useContext, useEffect, useState } from 'react';

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormFieldset,
  EuiListGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiTabbedContent,
  EuiTabbedContentTab,
  EuiText
} from '@elastic/eui';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import {
  ReviewCriteriaConfiguration,
  ReviewCriteriaRoundConfiguration
} from '@sharedComponents/interfaces/Scholarships.interface';

import ReviewCriteriaModal from './ReviewCriteriaWidget/ReviewCriteriaModal';

/**
 * ! Warning. A lot of data magic happeing in those components due to legacy data format/entry_form and requirement to support those.
 * * I am sorry
 */
export default function ReviewCriteriaWidget({ reviewCriteriasConfiguration, onChange }) {
  const createModal = useModalCreate();

  const handleReviewCriteriaChange = (data: ReviewCriteriaConfiguration) => {
    const rounds = Object.keys(data);
    const transformedData: ReviewCriteriaConfiguration = {};

    for (const round of rounds) {
      transformedData[round] = {};

      const roundCriterias = data[round];
      const roundScale = +roundCriterias[0].scale; // we take first criteria scale as whole round scale
      for (const criteriaKey of Object.keys(roundCriterias)) {
        // Object.keys in order to have criterias indexes available
        const criteriaConfig = roundCriterias[criteriaKey];
        if (criteriaConfig?.name?.length) {
          // we auto-generate criterias IDs based on text, avoiding duplicates
          const generateID = (text, criteriaKey: string, isSlicing = true) => {
            let generatedCriteriaID = `${criteriaKey}${text.toLowerCase().replace(/[^a-z1-9_]+/g, '')}`;

            if (isSlicing) {
              generatedCriteriaID = generatedCriteriaID.slice(0, 15);
            }

            if (transformedData[round][generatedCriteriaID]) {
              // thats a duplicate id
              const characters = 'abcdefghijklmnopqrstuvwxyz';
              return generateID(
                `${generatedCriteriaID}_${characters.charAt(Math.floor(Math.random() * characters.length))}`,
                '', // key is already appended
                false
              );
            }

            return generatedCriteriaID;
          };

          const generatedID: string = generateID(criteriaConfig.name, criteriaKey);
          transformedData[round][generatedID] = {
            name: criteriaConfig.name,
            scale: roundScale,
            weight: criteriaConfig.weight ? +criteriaConfig.weight : 0
          };
        }
      }

      onChange(transformedData);
    }
  };

  const [tabs, setTabs] = useState<Array<EuiTabbedContentTab>>([]);
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>();

  const hasCriteriasToDisplay =
    reviewCriteriasConfiguration &&
    reviewCriteriasConfiguration[1] &&
    Object.keys(reviewCriteriasConfiguration[1]).length;

  useEffect(() => {
    const tabsView = Object.keys(reviewCriteriasConfiguration || {}).map(round => {
      const criteriasForRound = reviewCriteriasConfiguration[round];
      return {
        id: `id_${round}`,
        name: (
          <>
            Round {round}
            {+round === Object.keys(reviewCriteriasConfiguration).length ? '+' : ''}
          </>
        ),
        content: (
          <EuiFlexGroup direction="column" gutterSize="s" key={round}>
            <EuiFlexItem grow={false}>
              <EuiSpacer size="xs" />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiListGroup flush={true} bordered={false} maxWidth={570}>
                {Object.values(criteriasForRound).map((criteria: any, i) => (
                  <EuiListGroupItem
                    key={i}
                    label={
                      <>
                        <EuiText size="s">
                          {i + 1}. {criteria?.name}
                        </EuiText>
                      </>
                    }
                    wrapText
                  />
                ))}
              </EuiListGroup>
            </EuiFlexItem>
            <EuiFlexItem> </EuiFlexItem>
          </EuiFlexGroup>
        )
      };
    });

    setTabs(tabsView);
    setSelectedTab(tabsView[0]);
  }, [reviewCriteriasConfiguration]);

  return (
    <EuiFormFieldset
      legend={{ children: 'Review Process Criteria' }}
      style={{ border: '1px solid rgb(55 61 104 / 10%)', borderRadius: 6 }}
    >
      {hasCriteriasToDisplay && tabs?.length ? (
        <EuiTabbedContent size="s" tabs={tabs} selectedTab={selectedTab} onTabClick={tab => setSelectedTab(tab)} />
      ) : (
        <EuiText size="s">No configuration is available.</EuiText>
      )}

      <EuiButton
        size="s"
        style={{ float: 'right' }}
        onClick={() => {
          createModal(({ closeModal }) => (
            <ReviewCriteriaModal
              closeModal={(isSave, data) => {
                if (isSave && data) {
                  handleReviewCriteriaChange(data);
                }

                closeModal();
              }}
              reviewCriteriasConfiguration={reviewCriteriasConfiguration}
            />
          ));
        }}
      >
        Change review criteria configuration
      </EuiButton>
    </EuiFormFieldset>
  );
}
