import React from 'react';
import UserModel from '@sharedContract/UserModel';
import formatDate from '@sharedComponents/utils/formatDate';
import preventDefault from '../base/preventDefault';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import { isScholarshipExpired } from '@sharedComponents/utils/scholarshipUtils';
import DocumentsExternal from './ApplyExternal/DocumentsExternal';
import formatAwardAmount from '@sharedComponents/utils/formatAwardAmount';

export default function ApplyExternal({
  scholarship,
  application,
  onMarkApplied,
  onDiscardApplication,
  errorMessage,
  updateApplication
}: {
  scholarship: ScholarshipModel;
  application: ApplicationModel | null;
  user: UserModel;
  onMarkApplied: () => void;
  onDiscardApplication: () => void;
  onNotifyCounselor: () => void;
  errorMessage?: JSX.Element | null;
  onError: (e: any) => void;
  updateApplication: (application: ApplicationModel) => void;
}) {
  if (scholarship && scholarship.applyUrl) {
    return (
      <div className="ApplyExternal">
        <div className="content">
          {errorMessage}
          <div className="pageTitle">
            <h4>Applying to</h4>
            <h2>{scholarship.name}</h2>
          </div>
          <div className="attributes">
            <div className="deadline">
              Deadline: <b>{scholarship ? formatDate(scholarship.deadline) : ' – '}</b>
              {scholarship && isScholarshipExpired(scholarship) ? <span className="expired"> Expired</span> : null}
            </div>

            <div className="amount">
              Value: <b>{scholarship ? formatAwardAmount(scholarship) : 'Varies'}</b>
            </div>
          </div>
          <div className="bullet">
            <div className="number">1.</div>
            <div className="body">
              Carefully check that you are eligible by reading the{' '}
              <a href={scholarship.originalUrl as string} target={'listing.' + scholarship.id}>
                donor's listing
              </a>{' '}
              on {scholarship.donor.name}'s website
            </div>
          </div>
          <div className="bullet">
            <div className="number">2.</div>
            <div className="body">
              Submit your application
              {scholarship.applyUrl && scholarship.applyUrl !== scholarship.originalUrl ? (
                <React.Fragment>
                  {' '}
                  using the{' '}
                  <a href={scholarship.applyUrl} target={'apply.' + scholarship.id}>
                    application form
                  </a>
                  .
                </React.Fragment>
              ) : (
                " by following the instructions in the donor's listing"
              )}
            </div>
          </div>
          <div className="bullet">
            <div className="number">3.</div>
            {!application || !application.submittedAt ? (
              <div className="body">
                Let us know you applied.
                {application ? (
                  <form>
                    {scholarship ? (
                      <React.Fragment>
                        <button className="button" onClick={preventDefault(onMarkApplied)}>
                          I have applied
                        </button>
                        <button className="button" onClick={preventDefault(onDiscardApplication)}>
                          I won't apply after all
                        </button>
                      </React.Fragment>
                    ) : null}
                    <div className="help">
                      Scholar's App may share your personal details with the donor if you say you applied.
                    </div>
                  </form>
                ) : null}
              </div>
            ) : (
              <div className="body">You told us you applied on {formatDate(application.submittedAt)}.</div>
            )}
          </div>
          {!!(application && application.submittedAt) && (
            <DocumentsExternal application={application} scholarship={scholarship} onChange={updateApplication} />
          )}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}
