// Used on onLogout, to keep persistent keys during relogins
const PERSISTENT_KEY = 'persistentKeys';

export function getPersistentKeys() {
  try {
    const item = window.localStorage.getItem(PERSISTENT_KEY);
    return item ? JSON.parse(item) : [];
  } catch (e) {
    return [];
  }
}

export function getPersistentData() {
  const persistentKeys = getPersistentKeys();
  const persistentData = new Map<string, string>();
  for (const persistentKey of persistentKeys) {
    persistentData.set(persistentKey, getFromLocalStorage(persistentKey));
  }

  return persistentData;
}

export function saveToLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
}

export function getFromLocalStorage(key: string) {
  try {
    const item = window.localStorage.getItem(key);
    return item !== null ? JSON.parse(item) : null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Error while getting from local storage, ${key}`);
    return null;
  }
}
