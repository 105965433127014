import { useFormContext } from 'react-hook-form';
import { boolean, object } from 'yup';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiText, EuiToolTip } from '@elastic/eui';
import standaloneApplicationsQuery from './queries/standaloneApplicationsQuery';
import { CheckboxField } from '@sharedComponents/formElements';
import { difference, isEqual } from 'lodash';

const StandaloneApplicationsVerification = ({ scholarshipID }) => {
  const formContext = useFormContext();
  const applicationsFileId = formContext.getValues('applications_file_id');

  const { data, isLoading, isError, error } = standaloneApplicationsQuery.useStandaloneApplicationsValidation(
    scholarshipID,
    applicationsFileId
  );

  if (isLoading) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (isError) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiCallOut title="Oops, something went wrong" color="danger" iconType="alert">
            {error?.message || 'Unknown error during validation.'}
          </EuiCallOut>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  if (!data || !data?.recordsCount) {
    return (
      <EuiFlexItem>
        <EuiText color={'warning'}>
          No applications were recognized based on uploaded file. Please, check file structure.
        </EuiText>
      </EuiFlexItem>
    );
  }

  const isFullMatch = isEqual(data.headers, data.formHeaders);
  const fieldsMissing = difference(data.formHeaders, data.headers);
  const fieldsExtras = difference(data.headers, data.formHeaders);
  return (
    <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
      <EuiFlexItem>
        <EuiText size="m">Total number of applications parsed: {data.recordsCount}</EuiText>
        {isFullMatch ? (
          <EuiCallOut title="Ok" color={'success'}>
            <EuiText size="m">Uploaded file fully matches application form structure!</EuiText>
          </EuiCallOut>
        ) : (
          <EuiCallOut title="File validation warnings">
            {fieldsMissing?.length ? (
              <EuiToolTip content="Those fields exists in application form, but are not provided in the uploaded CSV.">
                <EuiText size="m" color={'warning'}>
                  Fields missing: "{fieldsMissing.join('", "')}"
                </EuiText>
              </EuiToolTip>
            ) : null}
            {fieldsExtras?.length ? (
              <EuiToolTip content="Those fields are provided in the uploaded CSV, but wont be displayed in applications unless added into application form.">
                <EuiText size="m" color={'warning'}>
                  Redundant fields from uploaded CSV: "{fieldsExtras.join('", "')}"
                </EuiText>
              </EuiToolTip>
            ) : null}
          </EuiCallOut>
        )}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row" justifyContent="flexEnd" gutterSize="m" responsive={false}>
          <EuiFlexItem grow={false}>
            <CheckboxField
              fieldName={'isReadyForSubmit'}
              fieldProps={{
                label: 'I am ready to submit'
              }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

StandaloneApplicationsVerification.validationRules = object({
  isReadyForSubmit: boolean().oneOf([true], 'Field must be checked before submit')
});

export default StandaloneApplicationsVerification;
