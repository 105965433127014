import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import DonorModel from '@sharedContract/DonorModel';
import React, { useEffect, useState } from 'react';
import ScholarshipList from '@sharedComponents/ScholarshipList/ScholarshipList';
import UserList from '@donors/users/UserList';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { HeaderProps } from '../components/Header';
import './Donor.scss';
import ButtonBar from '@donors/components/ButtonBar';
import { History } from 'history';
import UserModel from '@sharedContract/UserModel';
import { useMemoryCachedState } from '@donors/base/useCachedState';
import Field from '@donors/components/Field';
import { useAllClients } from '@sharedComponents/selectors/useAllClients';

export const noValue = '—';

export default function Donor(
  props: {
    donorId: number;
    history: History;
  } & HeaderProps
) {
  const { donorId, history, user } = props;
  const { scholarshipClient, userClient } = useAllClients();

  const [donor, setDonor] = useMemoryCachedState<DonorModel | null>('donor.' + donorId, null);
  const [scholarships, setScholarships] = useMemoryCachedState<ScholarshipModel[] | null>('donor.s.' + donorId, null);
  const [users, setUsers] = useMemoryCachedState<UserModel[] | null>('donor.u.' + donorId, null);

  const [onError, errorMessage] = useErrorReporter();

  const [deleteState, setDeleteState] = useState<'not' | 'confirm' | 'gone'>('not');

  useEffect(() => {
    if (!isNaN(donorId)) {
      scholarshipClient
        .getScholarshipsOfDonor(donorId, ['count.applications.submittedAt'])
        .then(scholarships => {
          setScholarships(scholarships);
        })
        .catch(onError);

      userClient
        .getUsersOfDonor(donorId)
        .then(users => {
          setUsers(users);
        })
        .catch(onError);

      userClient
        .getDonor(donorId)
        .then(donor => {
          setDonor(donor);

          document.title = `${donor.name} – Scholar's App`;
        })
        .catch(notFoundIsUserError(onError));
    } else {
      onError(new Error('Invalid address'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scholarshipClient, donorId]);

  async function deleteDonor() {
    if (deleteState === 'not') {
      setDeleteState('confirm');
    } else if (deleteState === 'confirm') {
      userClient
        .deleteDonor(donorId)
        .then(() => setDeleteState('gone'))
        .catch(onError);
    }
  }

  return (
    <div className="Donor">
      <div className="body ">
        <div className="content">
          {errorMessage}

          <ButtonBar>
            <button
              className="button"
              key="delete"
              onClick={e => {
                deleteDonor();

                e.preventDefault();
              }}
            >
              {deleteState === 'not'
                ? 'Delete'
                : deleteState === 'confirm'
                ? "Really delete all this donor's data?"
                : 'Deleted'}
            </button>
            <button
              className="button"
              key="edit"
              onClick={e => {
                history.push(`/donors/${donorId}/edit`);

                e.preventDefault();
              }}
            >
              Edit
            </button>
          </ButtonBar>

          {donor ? (
            <React.Fragment>
              <div className="pageTitle">
                <h4>Donor Organization</h4>
                <h2>{donor.name}</h2>
              </div>

              <form>
                <Field label="Email">
                  <div className="value">{donor.email ? donor.email : '–'}</div>
                </Field>
                <Field label="Can review applications">
                  <div className="value">{donor.canReview ? 'Yes' : 'No'}</div>
                </Field>
              </form>
            </React.Fragment>
          ) : (
            []
          )}

          {users ? (
            <div>
              <h3>Users</h3>

              {users.length ? <UserList users={users} showType={true} /> : 'None'}
            </div>
          ) : null}

          {scholarships ? (
            <div>
              <h3>Scholarships</h3>

              {scholarships.length ? <ScholarshipList scholarships={scholarships} user={user} /> : 'None'}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function notFoundIsUserError(onError: (e: Error) => void): (e: Error) => void {
  return e => {
    if (e['result'] === 'not-found') {
      e['isUserError'] = true;
    }

    onError(e);
  };
}
