import { USER_TYPE } from '@sharedComponents/constants';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';

// hook to define listing url for free donors or counselors in order to define location to return after scholarship publication
export default function useAfterPublicationLocation() {
  const user = useLoggedInUser()!;

  // free donors have homepage on root
  let newLocation;

  if (user.type === USER_TYPE.DONOR) {
    newLocation = user.type === USER_TYPE.DONOR && !(user.data as any)?.isPremium ? '/' : `/scholarships`;
  } else if (user.type === USER_TYPE.COUNSELOR) {
    newLocation = '/manage';
  }

  return newLocation;
}
