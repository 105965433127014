import './ReviewRoundsSetup.scss';

import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import ReviewerStatusModal from '@donors/scholarship/ReviewRoundsSetup/ReviewerStatusModal/ReviewerStatusModal';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiStepsHorizontal,
  EuiText,
  formatDate,
  htmlIdGenerator
} from '@elastic/eui';
import { ApplicationClient } from '@sharedClients/ApplicationClient';
import { USER_TYPE } from '@sharedComponents/constants';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import useToast from '@sharedComponents/hooks/useToast';
import { ApplicationWithScores, AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';
import {
  ReviewRoundAssignations,
  ScholarshipWithReviewStatus
} from '@sharedComponents/interfaces/Scholarships.interface';
import { ApplicationModelShape, UserModelShape } from '@sharedComponents/models';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { capitalize, stringifyNumber } from '@sharedComponents/utils/stringUtils';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';

import ApplicationsDataGrid from './ReviewRoundsSetup/ApplicationsDataGrid';
import AwardingModal from './ReviewRoundsSetup/AwardingModal';
import { ApplicationGridDataEntry, getDataGridColumns, VIEW_MODE } from './ReviewRoundsSetup/config';
import reviewRoundDetailsQuery from './ReviewRoundsSetup/queries/reviewRoundDetailsQuery';

const applicationModelToDataGrid = (
  application: ApplicationWithScores,
  scholarship: ScholarshipWithReviewStatus
): ApplicationGridDataEntry => {
  const applicationData: {
    name: string;
    email: string;
    gpa: number | undefined;
    academicYear: string | undefined;
    school: string | undefined;
    id: number;
    award_status: AWARD_STATUS;
    prequalify_score?: number;
    totalAverageScore?: number;
    roundsStats?: ApplicationWithScores['roundsStats'];
  } = {
    name: getUserFullNameFromData(application.data) || 'Applicant ' + application.id,
    email: application.data?.email || '',
    gpa: application.data?.gpa,
    academicYear: application.data?.academicYear,
    school: application.data?.school?.school?.name,
    id: application.id,
    award_status: application.award_status as AWARD_STATUS,
    totalAverageScore: application.totalAverageScore,
    roundsStats: application.roundsStats
  };

  if (scholarship.hasScreeningScore) {
    applicationData.prequalify_score = application.prequalify_score;
  }

  return applicationData;
};

// TODO:IMP: Move into own route
// TODO:IMP: More decomposition to this one
// TODO:IMP: check for review criteria(should exists) and checkbox for donor org to review, in order to let start review round
export default function ReviewRoundsSetup({
  scholarship,
  applicationClient,
  onReviewProcessChange,
  isPastResultView,
  maxRounds,
  textFilter,
  applicationCycle
}: {
  scholarship: ScholarshipWithReviewStatus;
  applicationClient: ApplicationClient;
  onReviewProcessChange: (isFinishedReview: boolean) => void;
  isPastResultView: boolean;
  maxRounds: number;
  textFilter: string;
  applicationCycle: number;
}) {
  const user = useLoggedInUser()!;
  const { addToast } = useToast();

  const getViewMode = () => {
    if (isPastResultView) {
      return VIEW_MODE.PAST_VIEW;
    }

    return scholarship?.reviewRound === null ? VIEW_MODE.ROUNDS_SETUP : VIEW_MODE.ROUND_EDIT;
  };

  const [viewMode, setViewMode] = useState(getViewMode());
  const createModal = useModalCreate();

  useEffect(() => {
    setViewMode(getViewMode());
  }, [isPastResultView, scholarship?.reviewRound]);

  // cuz we supposed to fetch review round details if its ongoing
  // const isRoundDetailsQueryNeeded = viewMode !== VIEW_MODE.ROUND_EDIT && viewMode !== VIEW_MODE.PAST_VIEW;
  const { data: reviewRoundDetails, isLoading } = reviewRoundDetailsQuery.useReviewRoundDetailsQuery(
    applicationClient,
    viewMode === VIEW_MODE.PAST_VIEW ? scholarship.lastCompletedReviewRound?.id : scholarship?.reviewRound?.id
  );

  const [applicationGridData, setApplicationGridData] = useState<ApplicationGridDataEntry[]>([]);
  const [roundsSteps, setRoundsSteps] = useState<any[]>([]);
  const [isAwardingModalVisible, setIsAwardingModalVisible] = useState(false);

  const showReviewerStatusModal = () => {
    createModal(({ closeModal }) => (
      <ReviewerStatusModal closeModal={closeModal} reviewRoundDetails={reviewRoundDetails!} scholarship={scholarship} />
    ));
  };

  useEffect(() => {
    if (!isLoading && viewMode === VIEW_MODE.ROUNDS_SETUP) {
      // new review process starting, fill with all applications for candidates
      applicationClient
        .getAllCandidatesToScholarship(scholarship.id, textFilter, applicationCycle)
        .then((candidates: ApplicationModelShape[]) => {
          setApplicationGridData(
            candidates.map((application: ApplicationModelShape) => ({
              ...applicationModelToDataGrid(application, scholarship),
              reviewers: []
            }))
          );
        });

      const roundsStepsTmp: any[] = [];
      for (let round = 1; round <= maxRounds; round++) {
        roundsStepsTmp.push({
          isComplete: false,
          disabled: true,
          title: capitalize(`${stringifyNumber(round)} round`),
          onClick: () => {}
        });
      }

      setRoundsSteps([...roundsStepsTmp]);
    } else if (!isLoading && reviewRoundDetails) {
      // currently active round
      const reviewersMap = {};
      for (const reviewer of scholarship?.reviewers as UserModelShape[]) {
        reviewersMap[reviewer.id] = reviewer;
      }

      const applications = reviewRoundDetails?.applications || [];
      const allReviewRoundsMapping = {};
      if (reviewRoundDetails?.currentReviewRounds?.length) {
        for (const reviewRound of reviewRoundDetails.currentReviewRounds) {
          allReviewRoundsMapping[reviewRound.id] = reviewRound.currentRound;
        }
      }

      // adding reviewers into application info. Could be moved into applicationModelToDataGrid func
      const extraApplicationsData = application => {
        const reviewers =
          viewMode === VIEW_MODE.NEXT_ROUND_SETUP || viewMode === VIEW_MODE.PAST_VIEW // for new round we are supposed to assign applications once again and for past rounds we dont show assigned reviewers
            ? []
            : reviewRoundDetails?.assignations[application.id].map(reviewerID => reviewersMap[reviewerID]);

        return {
          reviewers: reviewers
        };
      };

      let _applicationGridData = [
        ...applications.map(application => ({
          ...applicationModelToDataGrid(application, scholarship),
          ...extraApplicationsData(application)
        }))
      ];

      if (
        viewMode === VIEW_MODE.PAST_VIEW ||
        viewMode === VIEW_MODE.NEXT_ROUND_SETUP ||
        viewMode === VIEW_MODE.ROUND_EDIT
      ) {
        _applicationGridData = _applicationGridData.sort((a, b) => {
          return get(b, 'totalAverageScore', 0) - get(a, 'totalAverageScore', 0);
        });
      }

      setApplicationGridData(_applicationGridData);

      const roundsStepsTmp: any[] = [];
      if (reviewRoundDetails) {
        for (let round = 1; round <= reviewRoundDetails.totalRounds; round++) {
          const isRoundCompleted = round < reviewRoundDetails.currentRound;
          const isRoundSelected = round === reviewRoundDetails.currentRound;

          roundsStepsTmp.push({
            isComplete: viewMode === VIEW_MODE.PAST_VIEW ? true : isRoundCompleted,
            isSelected: isRoundSelected,
            disabled: false,
            title: capitalize(`${stringifyNumber(round)} round`),
            onClick: () => {}
          });
        }
      }

      setRoundsSteps([...roundsStepsTmp]);
    }
  }, [viewMode, isLoading, reviewRoundDetails?.id]);

  if (isLoading) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiLoadingSpinner size="m" />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  const onDataGridSubmitChanges = assignedApplications => {
    // TODO:IMP move to interfaces
    const assignations: ReviewRoundAssignations = {};

    for (const application of assignedApplications) {
      assignations[application.id] = application.reviewers.map(reviewer => reviewer.id);
    }

    applicationClient
      .setupReviewRoundSetup(
        scholarship.id,
        reviewRoundDetails?.currentRound || 1,
        reviewRoundDetails?.totalRounds || maxRounds,
        assignations
      )
      .then(() => onReviewProcessChange(false));
  };

  const dataGridActions = gridData => {
    const gridActions: JSX.Element[] = [];

    if (viewMode === VIEW_MODE.PAST_VIEW) {
      const disabledPastViewButton = <EuiButton isDisabled={true}>Review Round Completed</EuiButton>;

      gridActions.push(disabledPastViewButton);

      if ([USER_TYPE.DONOR, USER_TYPE.ADMIN].includes(user.type as USER_TYPE)) {
        const startAwardProcessButton = (
          <EuiButton onClick={() => setIsAwardingModalVisible(true)}>Start Award Process</EuiButton>
        );

        gridActions.push(startAwardProcessButton);
      }
    } else {
      const submitButton = (
        <EuiButton
          onClick={() => {
            const assignations = gridData.filter(record => record.reviewers.length > 0);

            if (assignations.length > 0) {
              onDataGridSubmitChanges(gridData.filter(record => record.reviewers.length > 0));

              addToast({
                id: htmlIdGenerator()(),
                color: 'success',
                title: 'Round changes are submitted!'
              });
            } else {
              addToast({
                id: htmlIdGenerator()(),
                color: 'danger',
                title: 'Not possible to start the round without any applications assigned.'
              });
            }
          }}
        >
          {viewMode === VIEW_MODE.ROUNDS_SETUP
            ? 'Start Review Round'
            : viewMode === VIEW_MODE.ROUND_EDIT
            ? 'Save Changes'
            : 'Start ' + capitalize(stringifyNumber(reviewRoundDetails?.currentRound)) + ' Round'}
        </EuiButton>
      );

      gridActions.push(submitButton);
    }

    if (
      viewMode === VIEW_MODE.ROUND_EDIT &&
      reviewRoundDetails &&
      reviewRoundDetails.currentRound < reviewRoundDetails.totalRounds
    ) {
      const nextRoundButton = (
        <EuiButton
          color="success"
          onClick={() => {
            // increment round
            reviewRoundDetails.currentRound = reviewRoundDetails.currentRound + 1;
            setViewMode(VIEW_MODE.NEXT_ROUND_SETUP);
          }}
        >
          Finish {capitalize(stringifyNumber(reviewRoundDetails.currentRound))} Round
        </EuiButton>
      );

      gridActions.push(nextRoundButton);
    }

    if (
      viewMode === VIEW_MODE.ROUND_EDIT &&
      reviewRoundDetails &&
      reviewRoundDetails.currentRound === reviewRoundDetails.totalRounds
    ) {
      const finishRoundButton = (
        <EuiButton
          color="success"
          onClick={() =>
            applicationClient
              .setupReviewRoundSetup(scholarship.id, reviewRoundDetails.currentRound, reviewRoundDetails.totalRounds)
              .then(() => {
                onReviewProcessChange(true);
              })
          }
        >
          Finish review process
        </EuiButton>
      );

      gridActions.push(finishRoundButton);
    }

    return gridActions;
  };

  // ? theres double render at this point which should be solved
  const dataGridColumns = getDataGridColumns(viewMode, reviewRoundDetails, applicationGridData);

  const dataGridTrailingCellOptions = [
    VIEW_MODE.ROUND_EDIT,
    VIEW_MODE.NEXT_ROUND_SETUP,
    VIEW_MODE.ROUNDS_SETUP
  ].includes(viewMode)
    ? [ApplicationsDataGrid.TRAILING_CELL_OPTIONS.ASSIGNATIONS]
    : [];

  const awardingModal = (
    <AwardingModal
      applicationClient={applicationClient}
      applicationGridData={applicationGridData}
      onClose={() => setIsAwardingModalVisible(false)}
    />
  );

  return (
    <EuiFlexGroup direction="column" className="reviewRoundsSetup">
      <EuiFlexItem>
        <>
          <EuiStepsHorizontal steps={roundsSteps} />
          <EuiText size="m" textAlign={viewMode === VIEW_MODE.ROUNDS_SETUP ? 'left' : 'center'}>
            {viewMode === VIEW_MODE.ROUNDS_SETUP ? (
              <>
                Let's start the application review process, by creating a review round. <br />
                During a review round, reviewers are assigned to evaluate specific scholarship candidates. After the
                reviewer completes the evaluation, you are able to view scores and select which candidates will move on
                to the next round. During the final round, you are able to select a candidate to award your scholarship.{' '}
                <br />
                Assign one or more reviewers to each candidate, then click "Start Review Round" to begin.
              </>
            ) : viewMode === VIEW_MODE.PAST_VIEW ? (
              <>
                Round {reviewRoundDetails?.currentRound} was completed on{' '}
                {formatDate(reviewRoundDetails?.updated_at, 'LL')}. <br /> This was the final round. Please award the
                scholarship to the winning candidate(s).
              </>
            ) : viewMode === VIEW_MODE.NEXT_ROUND_SETUP ? (
              <>
                Round {reviewRoundDetails?.currentRound} out of {reviewRoundDetails?.totalRounds} <br />
                Please select the candidates that will go to the next round and assign them reviewer(s).
              </>
            ) : reviewRoundDetails?.currentRound ? (
              <>
                Round {reviewRoundDetails?.currentRound} out of {reviewRoundDetails?.totalRounds} <br />
                Please review the candidate scores, as the evaluations are completed. Also, you can assign additional
                reviewers by adding them and clicking "Save Changes". If you're ready to move to the next round, click
                "Finish {capitalize(stringifyNumber(reviewRoundDetails?.currentRound))} Round".
              </>
            ) : (
              <>
                Round {reviewRoundDetails?.currentRound} out of {reviewRoundDetails?.totalRounds}
              </>
            )}
          </EuiText>
          <EuiSpacer />
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              {applicationGridData?.length ? (
                <>
                  {viewMode === VIEW_MODE.ROUND_EDIT && !!reviewRoundDetails && (
                    <EuiFlexGroup alignItems="flexEnd" justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          size="s"
                          iconType="scale"
                          onClick={showReviewerStatusModal}
                          style={{ marginBottom: '0.5rem' }}
                        >
                          Reviewer Status
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}

                  <ApplicationsDataGrid
                    reviewers={scholarship?.reviewers as UserModelShape[]}
                    applications={applicationGridData}
                    gridActions={dataGridActions}
                    columns={dataGridColumns}
                    hasSelect={viewMode !== VIEW_MODE.PAST_VIEW}
                    trailingCellOptions={dataGridTrailingCellOptions}
                  />
                </>
              ) : null}
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      </EuiFlexItem>
      {isAwardingModalVisible ? awardingModal : null}
    </EuiFlexGroup>
  );
}

ReviewRoundsSetup.VIEW_MODE = VIEW_MODE;
