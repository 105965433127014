export enum EVENT_AUTHORIZED_TYPE { // represents database enum event_authorized_type and has to match
  SCHOLARSHIP_VETTED = 'scholarship_vetted',
  SCHOLARSHIP_CLICK = 'scholarship_click',
  SCHOLARSHIP_SUGGESTION = 'scholarship_suggestion',
  SCHOOL_REGISTERED = 'school_registered'
}

export enum EVENT_TYPE {
  SCHOLARSHIP_PAGE_VIEW = 'scholarship_page_view'
}

export interface AllEventsData {
  events: { [key in EVENT_TYPE]: number };
  eventsAuthorized: { [key in EVENT_AUTHORIZED_TYPE]: { count: number; date: string }[] };
}

export type EventAuthorizedUsers = { name: string; email: string; date: string }[];
