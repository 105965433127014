import React from 'react';

import { FieldList } from '@sharedComponents/formElements';
import { ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiFieldText } from '@elastic/eui';
import { object } from 'yup';

const SelfListingScholarshipStep6 = () => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>What are some common questions that students ask about your scholarship?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <FieldList
          fieldName={'data.faqQustionAnswer'}
          recordDefaultValue={{ question: '', answer: '' }}
          minListItems={1}
          fieldFactoryFn={(fieldNamePrefix, register, field) => {
            return (
              <EuiFlexGroup direction="column" gutterSize="xs">
                <EuiFlexItem>
                  <EuiFieldText
                    type="text"
                    name={`${fieldNamePrefix}question`}
                    inputRef={register}
                    defaultValue={field['question']}
                    placeholder={'Question'}
                    fullWidth
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    type="text"
                    name={`${fieldNamePrefix}answer`}
                    inputRef={register}
                    defaultValue={field['answer']}
                    placeholder={'Answer'}
                    fullWidth
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            );
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep6.validationRules = object().shape({
  data: ScholarshipModelDataSchema.pick(['faqQustionAnswer'])
});

export default SelfListingScholarshipStep6;
