import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { EuiButtonPropsForButton } from '@elastic/eui/src/components/button/button';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

export default function ButtonsChoiceField({
  fieldName,
  fieldProps,
  buttons,
  rowProps,
  viewMode = 'column'
}: {
  fieldName: string;
  fieldProps?: Partial<EuiButtonPropsForButton>;
  buttons: {
    value: any;
    callback?: (value: any) => void;
    label: string;
  }[];
  rowProps?: FormRowProps['rowProps'];
  viewMode?: 'row' | 'column';
}) {
  const { control, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={!!fieldError}
      render={({ onChange, value }) => {
        return (
          <EuiFlexGroup direction={viewMode} gutterSize="s">
            {buttons.map(button => (
              <EuiFlexItem key={button.label}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButton
                      color={value === button.value ? 'primary' : 'text'}
                      onClick={() => {
                        onChange(button.value);

                        if (button.callback) {
                          button.callback(button.value);
                        }
                      }}
                      {...fieldProps}
                    >
                      {button.label}
                    </EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        );
      }}
    />
  );

  return rowProps ? (
    <FormRow rowKey={fieldName} rowProps={{ ...rowProps }} isInvalid={!!fieldError} errorMessage={fieldError?.message}>
      {field}
    </FormRow>
  ) : (
    <>{field}</>
  );
}
