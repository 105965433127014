import { EuiBadge } from '@elastic/eui';
import { RecurrentAwardStatus } from '@sharedComponents/interfaces/RecurrentAward.interface';
import { RecurringAwardModelShape } from '@sharedComponents/models';

function RecurrentAwardStatusBadge({ recurrentAward }: { recurrentAward: RecurringAwardModelShape }) {
  let badgeColor = 'primary';
  switch (recurrentAward.status) {
    case RecurrentAwardStatus.EXPIRED: {
      badgeColor = 'danger';
      break;
    }

    case RecurrentAwardStatus.IN_PROGRESS: {
      badgeColor = 'warning';
      break;
    }

    case RecurrentAwardStatus.SUBMITTED: {
      badgeColor = 'success';
      break;
    }
  }
  return <EuiBadge color={badgeColor}>{recurrentAward.statusString}</EuiBadge>;
}

export default RecurrentAwardStatusBadge;
