import { AllEventsData } from '@sharedComponents/interfaces/Events.interface';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import { useQuery, UseQueryOptions } from 'react-query';

// unique key for this query
const CURRENT_QUERY_KEY = 'eventsData';

/**
 * disabled while selectedScholarshipId is null
 */
function useEventsData(
  selectedScholarshipId: number | null,
  dateRangeFilter: number,
  options: UseQueryOptions<AllEventsData, Error, AllEventsData> = {}
) {
  const scholarshipClient = useScholarshipClient();
  const query = {
    ...useQuery<AllEventsData, Error>(
      [CURRENT_QUERY_KEY, selectedScholarshipId, dateRangeFilter], // depend cache on IDs
      () => scholarshipClient.getAllScholarshipEventsData(selectedScholarshipId, dateRangeFilter),
      {
        staleTime: 1000 * 45,
        ...options,
        // if query disabled from outside - respect this, else relay on selectedScholarshipId availability
        enabled: options.enabled === false ? false : selectedScholarshipId !== null
      }
    )
  };

  return query;
}

const eventsDataQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useEventsData };
export default eventsDataQuery;
