import {
  ApplicationNodeAddressSchema,
  ApplicationNodeCheckboxSchema,
  ApplicationNodeEmailSchema,
  ApplicationNodeNumberSchema,
  ApplicationNodePhoneSchema,
  ApplicationNodeSelectSchema,
  ApplicationNodeStateSchema,
  ApplicationNodeTextInputSchema,
  ApplicationNodeUploadSchema,
  ApplicationSectionSchema,
  ApplicationNodePDFSchema,
  ApplicationNodeImageSchema,
  APPLICATION_NODE_TYPE
} from '../schemas/FormNodesSchema';
import { isValidEnumValue } from '../utils/typeHelpers';

// map used for new nodes adding or nodes editing modal in order to validate user input
export const SUPPORTED_NODES_WITH_SCHEMAS = {
  [APPLICATION_NODE_TYPE.TYPE_INPUT]: ApplicationNodeTextInputSchema,
  [APPLICATION_NODE_TYPE.TYPE_EMAIL]: ApplicationNodeEmailSchema,
  [APPLICATION_NODE_TYPE.TYPE_PHONE]: ApplicationNodePhoneSchema,

  [APPLICATION_NODE_TYPE.TYPE_SECTION]: ApplicationSectionSchema,
  [APPLICATION_NODE_TYPE.TYPE_CHECKBOX]: ApplicationNodeCheckboxSchema,
  [APPLICATION_NODE_TYPE.TYPE_SELECT]: ApplicationNodeSelectSchema,
  [APPLICATION_NODE_TYPE.TYPE_ADDRESS]: ApplicationNodeAddressSchema,
  [APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT]: ApplicationNodeNumberSchema,
  [APPLICATION_NODE_TYPE.TYPE_STATE]: ApplicationNodeStateSchema,
  [APPLICATION_NODE_TYPE.TYPE_UPLOADER]: ApplicationNodeUploadSchema,
  [APPLICATION_NODE_TYPE.TYPE_PDF]: ApplicationNodePDFSchema,
  [APPLICATION_NODE_TYPE.TYPE_IMAGE]: ApplicationNodeImageSchema
};

export type FormStateInternals = { internalPath?: string };

// Todo drop those for schemas
export interface ApplicationFormNode {
  required?: boolean | number;
  field?: string;
  title?: string;
  label?: string;
  help?: string | JSX.Element;
  warn?: string | JSX.Element;
  type: APPLICATION_NODE_TYPE | string; // could be uncovered node type for now
  path?: string;
  userType?: string;
  if?: any;
  unless?: any;
  wide?: boolean;
  width?: number;
  options?: string | string[];
  multiple?: boolean;
  autocomplete?: string;
  isDetailsTo?: string;
  private?: boolean;
}

// utility functions for formNodes -
// ? use model / class instances instead maybe ?
// dive deeper into FormNode, it may be children or columns
export const getDescendants = node => {
  if (node.children) {
    return {
      path: node.path || '',
      descendants: node.children
    };
  } else if (node.columns) {
    return {
      path: node.path || '',
      descendants: node.columns
    };
  } else {
    return {
      path: '',
      descendants: []
    };
  }
};

export const hasDescendants = node => node.children?.length || node.columns?.length || false;

/**
 * finding out node type based on 'field' or content
 */
export const getNodeType = (node: Partial<ApplicationFormNode>) => {
  if (typeof node === 'string') {
    // shortcut definition for nodes whicch eventually should be dropped
    return APPLICATION_NODE_TYPE.TYPE_INPUT;
  }

  if (node.type && isValidEnumValue(APPLICATION_NODE_TYPE, node.type)) {
    return node.type as APPLICATION_NODE_TYPE;
  }

  if (node.options?.length) {
    return APPLICATION_NODE_TYPE.TYPE_SELECT;
  }

  if (!node.type && node.field) {
    return APPLICATION_NODE_TYPE.TYPE_INPUT;
  }

  if ((node as any) /** TODO */.columns?.length) {
    return APPLICATION_NODE_TYPE.TYPE_SECTION;
  }

  // check there might be more specific cases
  // TODO macros resolving

  return null;
};

// TODO DRY WITH LEGACY FORMNODE classes
export function isNodeConditional(node) {
  return node.if || node.unless || node.allOf || node.anyOf;
}

export const FIELD_ICONS = {
  [APPLICATION_NODE_TYPE.TYPE_INPUT]: 'visText',
  [APPLICATION_NODE_TYPE.TYPE_EMAIL]: 'email',
  [APPLICATION_NODE_TYPE.TYPE_PHONE]: 'mobile',

  [APPLICATION_NODE_TYPE.TYPE_SECTION]: 'visTable',
  [APPLICATION_NODE_TYPE.TYPE_CHECKBOX]: 'checkInCircleFilled',
  [APPLICATION_NODE_TYPE.TYPE_SELECT]: 'tag',
  [APPLICATION_NODE_TYPE.TYPE_ADDRESS]: 'visMapCoordinate',
  [APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT]: 'asterisk',
  [APPLICATION_NODE_TYPE.TYPE_STATE]: 'globe',
  [APPLICATION_NODE_TYPE.TYPE_UPLOADER]: 'paperClip',
  [APPLICATION_NODE_TYPE.TYPE_PDF]: 'exportAction',
  [APPLICATION_NODE_TYPE.TYPE_IMAGE]: 'image'
};

export const FIELD_NAMES = {
  [APPLICATION_NODE_TYPE.TYPE_INPUT]: 'Text',
  [APPLICATION_NODE_TYPE.TYPE_EMAIL]: 'Email',
  [APPLICATION_NODE_TYPE.TYPE_PHONE]: 'Phone',

  [APPLICATION_NODE_TYPE.TYPE_SECTION]: 'Section',
  [APPLICATION_NODE_TYPE.TYPE_CHECKBOX]: 'Checkbox',
  [APPLICATION_NODE_TYPE.TYPE_SELECT]: 'Dropdown',
  [APPLICATION_NODE_TYPE.TYPE_ADDRESS]: 'Address',
  [APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT]: 'Number',
  [APPLICATION_NODE_TYPE.TYPE_STATE]: 'State',
  [APPLICATION_NODE_TYPE.TYPE_UPLOADER]: 'Any file',
  [APPLICATION_NODE_TYPE.TYPE_PDF]: 'PDF file',
  [APPLICATION_NODE_TYPE.TYPE_IMAGE]: 'Image'
};

export enum SPECIAL_FORM_TYPE {
  RECURRENT_APPLICATION = 'RECURRENT_APPLICATION'
}
