import './Scholarship.scss';

import React, { useEffect, useRef, useState } from 'react';
import { match as Match } from 'react-router-dom';

import preventDefault from '@donors/base/preventDefault';
import track from '@sharedComponents/base/track';
import { useMemoryCachedState } from '@donors/base/useCachedState';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { notFoundIsUserError } from '@donors/donor/Donor';
import { filterSubmitted } from '@sharedClients/main';
import ScholarshipModel, { ScholarshipPost } from '@sharedClients/types/ScholarshipModel';
import EditScholarship from '@sharedComponents/Scholarship/EditScholarship';
import ViewScholarship from '@sharedComponents/Scholarship/ViewScholarship';

import ButtonBar from '@sharedComponents/base/components/ButtonBar';
import { createRelationsIfMissing } from '@sharedComponents/Scholarship/AddScholarship';
import { USER_TYPE } from '@sharedComponents/constants';
import { useAllClients } from './selectors/useAllClients';
import { useRouter } from './hooks/useRouter';
import { useLoggedInUser } from './selectors/loggedInUserSelectors';

export const noValue = '—';

interface Identifiable {
  id: string;
}

/**
 * Shared representation for scholarship view with possibly edit functionality.
 * @access admin, dataEntry, counselor, donor, reviewer, maybe more?
 */
export default function Scholarship(props: {
  match: Match<Identifiable>;
  onLogout: () => void;
  animationTime?: number;
  searchDelay?: number;
  isDraft?: boolean;
}) {
  const { match, animationTime, searchDelay, isDraft = false } = props;

  const { history } = useRouter();
  const user = useLoggedInUser()!;

  const scholarshipId = parseInt(match.params.id);

  const { scholarshipClient, applicationClient, userClient } = useAllClients();

  const [isEdit, setEdit] = useState(false);
  const [scholarship, setScholarship] = useMemoryCachedState<ScholarshipModel | null>(
    'scholarship.' + scholarshipId + (isDraft ? '.draft' : ''),
    null
  );

  const [onError, errorMessage, clearError] = useErrorReporter();

  const [deleteState, setDeleteState] = useState<'not' | 'confirm'>('not');

  useEffect(() => {
    window.onbeforeunload = isEdit ? () => 'Are you sure you want to abandon these edits?' : null;

    return () => {
      window.onbeforeunload = null;
    };
  }, [isEdit]);

  const fetchCount = useRef<number>(0);

  async function fetchScholarship() {
    fetchCount.current = fetchCount.current + 1;

    const startFetchCount = fetchCount.current;

    if (!isNaN(scholarshipId)) {
      try {
        const resp = await Promise.all([
          scholarshipClient.getScholarship(scholarshipId, ['applications'], ['id'], filterSubmitted, isDraft),
          scholarshipClient.getPastRecipients(scholarshipId, isDraft)
        ]);

        if (fetchCount.current === startFetchCount) {
          setScholarship({ ...resp[0], data: { ...resp[0].data, pastRecipients: resp[1].pastRecipients } });
          document.title = `${resp[0].name} – Scholar's App`;
        }
      } catch (error) {
        notFoundIsUserError(onError);
      }
    } else {
      onError(new Error('Invalid address'));
    }
  }

  async function deleteScholarship() {
    if (deleteState === 'not') {
      setDeleteState('confirm');
    } else if (deleteState === 'confirm') {
      scholarshipClient
        .deleteScholarship(scholarshipId, scholarship?.isDraft)
        .then(() => {
          setDeleteState('not');

          if (scholarship) {
            setScholarship({
              ...scholarship,
              deletedAt: scholarship.deletedAt ? null : new Date()
            });
          }
        })
        .catch(onError);
    }
  }

  useEffect(() => {
    fetchScholarship();
    return;
  }, [scholarshipClient, scholarshipId]);

  // TODO! Thats a code duplication from 'AddScholarship'. This has to be fixed.
  // ? Why there are both ScholarshipModel and ScholarshipPost interfaces?
  async function saveOrPublish(editedScholarship: ScholarshipPost) {
    clearError();

    const pastRecipients = editedScholarship.data.pastRecipients || [];

    editedScholarship.data.pastRecipients = []; // ?

    editedScholarship = await createRelationsIfMissing(editedScholarship, {
      userClient,
      scholarshipClient
    });

    let updateResult;
    try {
      if (editedScholarship.isDraft) {
        throw new Error('Draft scholarships are not supported here anymore. Use newer interfaces');
      }

      updateResult = await scholarshipClient.updateScholarship(scholarshipId, editedScholarship);
    } catch (error: any) {
      return onError(error);
    }

    track('scholarship-update');

    // ? would be great to handle this on backend controller
    if (pastRecipients.length < (scholarship?.data.pastRecipients || []).length) {
      const ePastRecipientsObj = {};
      pastRecipients.forEach(i => (ePastRecipientsObj[i.pastRecipientId?.toString() || ''] = i.pastRecipientId));
      const dPastRecipientsId = scholarship?.data?.pastRecipients
        ?.filter(i => !ePastRecipientsObj[i.pastRecipientId || ''])
        ?.map(i => i.pastRecipientId);
      try {
        await scholarshipClient.deletePastRecipients(dPastRecipientsId || []);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error, 'error');
      }
    }

    if (pastRecipients.length) {
      try {
        for (let i = 0; i < pastRecipients.length; i++) {
          if (pastRecipients[i].pastRecipientId === 0 && (!pastRecipients[i].name || !pastRecipients[i].note)) {
            continue;
          }

          // todo case when edited is a draft alrdy and we need to update scid
          await scholarshipClient.uploadPastRecipients(updateResult.id, pastRecipients[i], updateResult.isDraft);
        }
      } catch (error) {
        // ?
      }
    }

    if (updateResult.isDraft) {
      // returned scholarship is a draft, we need to redirect page to newly created draft record
      setEdit(false);
      history.push(`/scholarships/drafts/${updateResult.id}`);
      return;
    }

    setEdit(false);
    fetchScholarship();
    // the scholarship we get from EditScholarship is not guaranteed to be complete; better reload.
    history.push(`/scholarships/${updateResult.id}`);
  }

  const canEdit = user.type === USER_TYPE.ADMIN || user.donor?.id === scholarship?.donor.id;
  const canSeeApplicatios = canEdit;
  return (
    <div className="scholarship">
      <div className="scholarship-content">
        {errorMessage}

        <ButtonBar className="links">
          {isEdit || !canEdit ? null : (
            <div>
              <button className="button" onClick={() => setEdit(true)}>
                Edit
              </button>
            </div>
          )}

          {!isEdit && canSeeApplicatios ? (
            <button className="button" onClick={() => history.push(`/scholarships/${scholarshipId}/applications`)}>
              Applications
              {scholarship && scholarship.applications ? ` (${scholarship.applications.length})` : null}
            </button>
          ) : null}

          {isDraft && scholarship && user.type === USER_TYPE.ADMIN && !isEdit ? (
            <button className="button" onClick={() => saveOrPublish(scholarship)}>
              Save and Publish
            </button>
          ) : null}

          {!isEdit && scholarship && !scholarship.deletedAt && user.type === USER_TYPE.ADMIN && canEdit ? (
            <button className="button" onClick={preventDefault(deleteScholarship)}>
              {deleteState === 'not'
                ? 'Delete'
                : deleteState === 'confirm'
                ? isDraft
                  ? 'Really delete this DRAFT?'
                  : 'Really delete scholarship and all its applications?'
                : 'Deleted'}
            </button>
          ) : null}
          {scholarship && scholarship.deletedAt && canEdit ? (
            <button
              className="button"
              onClick={preventDefault(deleteScholarship)}
              disabled={deleteState === 'not' && scholarship.isDraft}
            >
              {deleteState === 'not'
                ? scholarship.isDraft
                  ? 'Deleted'
                  : 'Undelete'
                : deleteState === 'confirm'
                ? 'Really undelete scholarship?'
                : 'Undeleted'}
            </button>
          ) : null}
        </ButtonBar>

        {scholarship ? (
          isEdit ? (
            <EditScholarship
              user={user}
              scholarship={scholarship}
              onSave={saveOrPublish}
              canAddForm={true}
              userClient={userClient}
              scholarshipClient={scholarshipClient}
              applicationClient={applicationClient}
              onCancel={() => setEdit(false)}
              onError={onError}
              animationTime={animationTime}
              searchDelay={searchDelay}
            />
          ) : (
            <ViewScholarship
              scholarship={scholarship}
              scholarshipClient={scholarshipClient}
              user={user}
              history={history}
            />
          )
        ) : null}
      </div>
    </div>
  );
}
