/* eslint-disable react/jsx-key */
import React from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

// ! what the fuck with that include path?
import StudyLookupWidget from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/StudyLookupWidget';
import { EuiFormRow } from '@elastic/eui';
import { CheckboxField, ComboboxField, NumberField, SchoolLookupField } from '@sharedComponents/formElements';
import academic_years from '@sharedComponents/models/enumValues/academic_years';
import athletics from '@sharedComponents/models/enumValues/athletics';
import college_types from '@sharedComponents/models/enumValues/college_types';
import ethnicities from '@sharedComponents/models/enumValues/ethnicities';
import genders from '@sharedComponents/models/enumValues/genders';
import miscellaneous from '@sharedComponents/models/enumValues/miscellaneous';
import religious from '@sharedComponents/models/enumValues/religious';
import selfIdentification from '@sharedComponents/models/enumValues/selfIdentification';
import studyRates from '@sharedComponents/models/enumValues/study_rates';
import veteran_status from '@sharedComponents/models/enumValues/veteran_status';
import { UserModelDataSchema, UserModelDataSchemaType } from '@sharedComponents/schemas/UserModelSchema';
import allAreas from '@sharedContract/areas';
import graduationyear from '@sharedContract/graduationyear';

// Represents array of fields from students profile + registration step
function applicantProfileFields(formMethods) {
  return [
    // School related
    <ComboboxField
      fieldName={'data.academicYear'}
      options={academic_years}
      fieldProps={{ fullWidth: true, placeholder: 'Select one', singleSelection: true }}
      rowProps={{ label: 'Academic Year' }}
    />,

    <SchoolLookupField fieldName={'data.school.school'} formMethods={formMethods} isRequired={false} />,

    <ComboboxField
      fieldName={'data.school.graduationyear'}
      options={graduationyear}
      fieldProps={{
        fullWidth: true,
        singleSelection: true,
        placeholder: ''
      }}
      rowProps={{ label: 'High School Graduation Year' }}
    />,

    <NumberField fieldName={'data.gpa'} fieldProps={{ fullWidth: true }} rowProps={{ label: 'GPA' }} />,

    <ComboboxField
      fieldName={'data.collegeType'}
      options={college_types}
      fieldProps={{
        fullWidth: true,
        placeholder: 'Select one or more'
      }}
      rowProps={{ label: 'Types of college you are considering' }}
    />,

    <ComboboxField
      fieldName={'data.studyRates'}
      options={studyRates}
      fieldProps={{ fullWidth: true, placeholder: '' }}
      rowProps={{ label: 'Study Rate' }}
    />,

    <ComboboxField
      fieldName={'data.area'}
      options={allAreas}
      fieldProps={{ fullWidth: true, placeholder: '' }}
      rowProps={{ label: 'Areas of your studies, work and/or hobbies' }}
    />,

    <EuiFormRow label="Colleges you are applying to">
      <Controller
        control={formMethods.control}
        name="data.college.applyingTo"
        render={({ onChange, value }) => {
          // we have to always tranform data from internal format into suitable for eui components
          const studyLookupInitialData = (value?.length ? value : [])?.map(applyingTo => ({
            id: applyingTo?.college?.id,
            name: applyingTo?.college?.name,
            city: applyingTo?.city,
            state: applyingTo?.state
          }));
          return (
            <StudyLookupWidget
              fieldName="data.college.applyingTo"
              dataType={'colleges'}
              placeholder="College Name"
              initialValues={studyLookupInitialData}
              onChange={entries => {
                if (entries.length) {
                  const newColleges = entries.filter(entry => !!entry).map(entry => ({ ...entry }));
                  // colleges has this format from DB: {city, id, name, state, wp_id}
                  const applyingTo: UserModelDataSchemaType['college']['applyingTo'] = []; // we clean previous data
                  for (const newCollege of newColleges) {
                    applyingTo.push({
                      college: {
                        name: newCollege.name,
                        id: newCollege.id
                      },
                      state: newCollege.state || '',
                      city: newCollege.city || ''
                    } as any); // :( Sorry, we have to do those transformations in order to maintain legacy format for that data
                  }

                  onChange(applyingTo);
                } else {
                  onChange([]);
                }
              }}
            />
          );
        }}
      />
    </EuiFormRow>,

    <CheckboxField fieldName={'data.lowIncome'} fieldProps={{ label: 'I have financial need' }} rowProps={{}} />,

    <CheckboxField
      fieldName={'data.communityService'}
      fieldProps={{ label: 'I have performed community service' }}
      rowProps={{}}
    />,

    <CheckboxField
      fieldName={'data.college.firstGen'}
      fieldProps={{ label: "I'm among the first generation in my family to attend college" }}
      rowProps={{}}
    />,

    // Personal
    <ComboboxField
      fieldName={'data.gender'}
      options={genders}
      fieldProps={{
        fullWidth: true,
        placeholder: 'Select one',
        singleSelection: true
      }}
      rowProps={{ label: 'Gender' }}
    />,

    // new 'ethnicity' fields
    <ComboboxField
      fieldName={'data.ethnicity'}
      options={ethnicities}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{ label: 'Ethnicity', helpText: 'Many scholarships are only available to specific groups.' }}
    />,

    <ComboboxField
      fieldName={'data.religious'}
      options={religious}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{
        label: 'Religious / Spiritual Identity'
      }}
    />,

    <ComboboxField
      fieldName={'data.selfIdentification'}
      options={selfIdentification}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{
        label: 'Self-Identification of Disability'
      }}
    />,

    <ComboboxField
      fieldName={'data.veteranStatus'}
      options={veteran_status}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{
        label: 'Veteran Status/Affiliation'
      }}
    />,

    <ComboboxField
      fieldName={'data.athletics'}
      options={athletics}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{
        label: 'Athletics'
      }}
    />,

    <ComboboxField
      fieldName={'data.miscellaneous'}
      options={miscellaneous}
      fieldProps={{
        fullWidth: true,
        placeholder: ''
      }}
      rowProps={{
        label: 'Other'
      }}
    />,

    <CheckboxField
      fieldName={'data.smsnofification'}
      fieldProps={{ label: 'Would you like to receive text message notifications?', defaultChecked: true }}
      rowProps={{}}
    />,

    <CheckboxField
      fieldName={'data.emailNotification'}
      fieldProps={{ label: 'Would you like to receive email notifications?', defaultChecked: true }}
      rowProps={{}}
    />
  ];
}

applicantProfileFields.validationRules = yup.object().shape({
  data: UserModelDataSchema.pick([
    'academicYear',
    'gpa',
    'gender',
    'ethnicity',
    'religious',
    'selfIdentification',
    'veteranStatus',
    'athletics',
    'miscellaneous',
    'emailNotification',
    'smsnofification',
    'school',
    'collegeType',
    'studyRates',
    'area',
    'college',
    'lowIncome',
    'communityService'
  ])
});

export default applicantProfileFields;
