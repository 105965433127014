import React, { createContext, useContext, useCallback, useReducer, useState, Dispatch } from 'react';

import {
  EuiDataGrid,
  EuiCheckbox,
  EuiButtonIcon,
  EuiPopover,
  EuiButtonEmpty,
  EuiPopoverTitle,
  EuiDataGridColumn,
  EuiLink,
  EuiToolTip,
  EuiText
} from '@elastic/eui';
import addressVerificationState from '../atoms/addressVerificationState';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { DELIVERABILITY_STATUS, DELIVERABILITY_SUMMARY } from '@sharedComponents/interfaces/Mailing.interface';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import EditAddressModal from './VerificationResults/EditAddressModal';
import { AddressVerificationType } from '@sharedComponents/schemas/MailingModelSchema';

const columns: EuiDataGridColumn[] = [
  {
    id: 'is_selected_for_sending',
    display: <strong>Will be sent</strong>,
    isExpandable: false,
    isResizable: false,
    actions: false
  },
  {
    id: 'id',
    display: <strong>ID</strong>
  },
  {
    id: 'recipient',
    display: <strong>Recipient</strong>
  },
  {
    id: 'primary_line',
    display: <strong>Primary Line</strong>
  },
  {
    id: 'last_line',
    display: <strong>Last Line</strong>
  },
  {
    id: 'deliverability',
    display: <strong>Validation Status</strong>,
    isExpandable: false,
    isResizable: false,
    actions: false
  }
];

function getAddressVerificationStatusColor(status: DELIVERABILITY_STATUS) {
  switch (status) {
    case DELIVERABILITY_STATUS.SKIPPED:
      return `rgba(255, 255, 0, 0.45)`;

    case DELIVERABILITY_STATUS.OK:
      return `rgba(0, 255, 0, 0.45)`;

    case DELIVERABILITY_STATUS.UNNECESSARY:
    case DELIVERABILITY_STATUS.INCORRECT:
    case DELIVERABILITY_STATUS.MISSING:
      return `rgba(0, 255, 0, 0.25)`;

    case DELIVERABILITY_STATUS.NOT_OK:
    default:
      return `rgba(255, 0, 0, 0.25)`;
  }
}

const SelectionContext = createContext<[Set<number>, Dispatch<{ action: string; rowIndex?: any }>]>([
  new Set(),
  () => {}
]);

const SelectionButton = () => {
  const [selectedRows, updateSelectedRows] = useContext(SelectionContext);
  const setAddressVerifications = useSetRecoilState(addressVerificationState);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toggleItemsFromSendingHandler = () => {
    setAddressVerifications(currentStateAddresses => {
      const updatedArray: AddressVerificationType[] = currentStateAddresses.map((address, key) => {
        return {
          ...address,
          is_selected_for_sending:
            selectedRows.has(key) && address.deliverability !== DELIVERABILITY_STATUS.NOT_OK
              ? !address.is_selected_for_sending
              : address.is_selected_for_sending
        };
      });

      return updatedArray;
    });

    setIsPopoverOpen(false);
    updateSelectedRows({ action: 'clear' });
  };

  if (selectedRows.size > 0) {
    return (
      <EuiPopover
        isOpen={isPopoverOpen}
        anchorPosition="upCenter"
        panelPaddingSize="s"
        button={
          <EuiButtonEmpty
            size="xs"
            iconType="arrowDown"
            color="primary"
            className="euiDataGrid__controlBtn"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            {selectedRows.size} {selectedRows.size > 1 ? 'items' : 'item'} selected
          </EuiButtonEmpty>
        }
        closePopover={() => setIsPopoverOpen(false)}
      >
        <EuiPopoverTitle>
          {selectedRows.size} {selectedRows.size > 1 ? 'items' : 'item'}
        </EuiPopoverTitle>
        <EuiText size="s">
          <EuiLink onClick={toggleItemsFromSendingHandler}>Toggle selected items from sending</EuiLink>
        </EuiText>
      </EuiPopover>
    );
  } else {
    return null;
  }
};

const SelectionHeaderCell = () => {
  const addressesVerifications = useRecoilValue(addressVerificationState);
  const [selectedRows, updateSelectedRows] = useContext(SelectionContext);
  const isIndeterminate = selectedRows.size > 0 && selectedRows.size < addressesVerifications.length;
  return (
    <EuiCheckbox
      id="selection-toggle"
      aria-label="Select all rows"
      indeterminate={isIndeterminate}
      checked={selectedRows.size > 0}
      onChange={e => {
        if (isIndeterminate) {
          // clear selection
          updateSelectedRows({ action: 'clear' });
        } else {
          if (e.target.checked) {
            // select everything
            updateSelectedRows({ action: 'selectAll' });
          } else {
            // clear selection
            updateSelectedRows({ action: 'clear' });
          }
        }
      }}
    />
  );
};

const SelectionRowCell = ({ rowIndex }) => {
  const [selectedRows, updateSelectedRows] = useContext(SelectionContext);
  const isChecked = selectedRows.has(rowIndex);

  const addressesVerifications = useRecoilValue(addressVerificationState);

  return (
    <EuiCheckbox
      id={`${rowIndex}`}
      aria-label={`Select row ${rowIndex}, ${addressesVerifications[rowIndex].recipient}`}
      checked={isChecked}
      onChange={e => {
        if (e.target.checked) {
          updateSelectedRows({ action: 'add', rowIndex });
        } else {
          updateSelectedRows({ action: 'delete', rowIndex });
        }
      }}
    />
  );
};

const leadingControlColumns = [
  {
    id: 'selection',
    width: 32,
    headerCellRender: SelectionHeaderCell,
    rowCellRender: SelectionRowCell
  }
];

const RowCellRenderer = ({ rowIndex }, editRecordHandler) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      anchorPosition="upCenter"
      panelPaddingSize="s"
      button={
        <EuiButtonIcon
          aria-label="show actions"
          iconType="boxesHorizontal"
          color="text"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        />
      }
      closePopover={() => setIsPopoverOpen(false)}
    >
      <EuiPopoverTitle>Actions</EuiPopoverTitle>
      <EuiLink onClick={() => editRecordHandler(rowIndex)}>Edit & Revalidate</EuiLink>
    </EuiPopover>
  );
};

function VerificationResults() {
  const createModal = useModalCreate();

  const [addressesVerifications, setAddressVerifications] = useRecoilState(addressVerificationState);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15
  });

  const setPageIndex = useCallback(
    pageIndex => setPagination({ ...pagination, pageIndex }),
    [pagination, setPagination]
  );

  const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

  const rowSelection = useReducer((rowSelection, { action, rowIndex }: { action; rowIndex? }) => {
    if (action === 'add') {
      const nextRowSelection = new Set(rowSelection);
      nextRowSelection.add(rowIndex);
      return nextRowSelection;
    } else if (action === 'delete') {
      const nextRowSelection = new Set(rowSelection);
      nextRowSelection.delete(rowIndex);
      return nextRowSelection;
    } else if (action === 'clear') {
      return new Set();
    } else if (action === 'selectAll') {
      return new Set(addressesVerifications.map((_, index) => index));
    }
    return rowSelection;
  }, new Set());

  const renderCellValue = useCallback(
    ({ rowIndex, columnId, setCellProps, isExpanded }) => {
      if (columnId === 'deliverability') {
        setCellProps({
          style: {
            backgroundColor: getAddressVerificationStatusColor(addressesVerifications[rowIndex][columnId])
          }
        });

        return DELIVERABILITY_SUMMARY[addressesVerifications[rowIndex][columnId]] ? (
          <EuiToolTip content={DELIVERABILITY_SUMMARY[addressesVerifications[rowIndex][columnId]]}>
            <EuiText size="s">{addressesVerifications[rowIndex][columnId]}</EuiText>
          </EuiToolTip>
        ) : (
          addressesVerifications[rowIndex][columnId]
        );
      } else if (columnId === 'is_selected_for_sending') {
        setCellProps({
          style: {
            color:
              addressesVerifications[rowIndex]['deliverability'] === DELIVERABILITY_STATUS.NOT_OK ? 'gray' : 'black',
            backgroundColor: addressesVerifications[rowIndex][columnId]
              ? `rgba(0, 255, 0, 0.45)`
              : addressesVerifications[rowIndex]['deliverability'] === DELIVERABILITY_STATUS.NOT_OK
              ? 'rgba(0, 0, 0, 0.15)'
              : ''
          }
        });

        return addressesVerifications[rowIndex][columnId] ? 'Yes' : 'No';
      } else if (columnId === 'recipient' && !isExpanded) {
        // \"name\" length must be less than or equal to 40 characters long
        if (addressesVerifications[rowIndex][columnId]?.length > 40) {
          setCellProps({
            style: {
              backgroundColor: 'rgba(255, 0, 0, 0.25)'
            }
          });
        } else {
          setCellProps({
            style: {
              backgroundColor: `rgba(0, 255, 0, 0.45)`
            }
          });
        }
      }

      return (addressesVerifications[rowIndex][columnId] || '-').toString();
    },
    [addressesVerifications]
  );

  if (!addressesVerifications?.length) {
    return null;
  }

  const editRecordHandler = rowIndex => {
    const record = addressesVerifications[rowIndex];
    createModal(({ closeModal }) => {
      const onModalSubmitHandler = async (verifiedAddress?: AddressVerificationType) => {
        closeModal();

        if (verifiedAddress) {
          setAddressVerifications(currentStateAddresses => {
            const updatedArray: AddressVerificationType[] = currentStateAddresses.map((address, key) => {
              if (key === rowIndex) {
                return verifiedAddress;
              }

              return address;
            });

            return updatedArray;
          });
        }
      };

      return <EditAddressModal onModalSubmit={onModalSubmitHandler} title={<>Edit recipient</>} record={record} />;
    });
  };

  const trailingControlColumns = [
    {
      id: 'actions',
      width: 40,
      headerCellRender: () => null,
      rowCellRender: cellDetails => RowCellRenderer(cellDetails, editRecordHandler)
    }
  ];

  return (
    <SelectionContext.Provider value={rowSelection}>
      <EuiDataGrid
        aria-label="Address verification results"
        leadingControlColumns={leadingControlColumns}
        trailingControlColumns={trailingControlColumns}
        columns={columns}
        columnVisibility={{
          visibleColumns,
          setVisibleColumns
        }}
        rowCount={addressesVerifications.length}
        renderCellValue={renderCellValue}
        pagination={{
          ...pagination,
          pageSizeOptions: undefined,
          onChangeItemsPerPage: () => {},
          onChangePage: setPageIndex
        }}
        toolbarVisibility={{
          additionalControls: <SelectionButton />,
          showSortSelector: true,
          showColumnSelector: false
        }}
      />
    </SelectionContext.Provider>
  );
}

export default VerificationResults;
