import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingSpinner, EuiText } from '@elastic/eui';
import { MailingModelSchema } from '@sharedComponents/schemas/MailingModelSchema';

import addressVerificationState from './atoms/addressVerificationState';
import VerificationResults from './MailingAddressVerification/VerificationResults';
import mailingAddressValidationQuery from './queries/mailingAddressValidationQuery';

const MailingAddressVerification = () => {
  const formContext = useFormContext();
  const addressFileID = formContext.getValues('address_file_id');

  const setAddressVerificationState = useSetRecoilState(addressVerificationState);
  const { data, isLoading, isError, error } = mailingAddressValidationQuery.useMailingAddressValidation(addressFileID, {
    onSuccess: _data => {
      setAddressVerificationState(_ => [..._data.recipients]);
    }
  });

  const recipients = formContext.watch('recipients');

  if (isLoading) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (isError) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiCallOut title="Oops, something went wrong" color="danger" iconType="alert">
            {error?.message || 'Unknown error during validation.'}
          </EuiCallOut>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  let status = data?.hasErrors ? (
    <EuiText>
      Some mailing addresses were not fully validated, and must have data updated.
      <br /> See the "Validation Status" column for details. To update data, click on the{' '}
      <EuiIcon type="boxesHorizontal" /> icon.
    </EuiText>
  ) : (
    <EuiText>Everything is set! Process to the next step!</EuiText>
  );

  if (data?.recipients.some(r => r.deliverability === 'skipped')) {
    status = (
      <EuiText>Validation was skipped for your recipients. Those records will be pass into LOB as it is.</EuiText>
    );
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem>{status}</EuiFlexItem>
      {data?.recipients ? (
        <EuiFlexItem>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <EuiText>The following mailing addresses were parsed based upon the uploaded .CSV file.</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <VerificationResults />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="row">
                <EuiFlexItem></EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText size="s">Total number of recipients verified for mailing: {recipients.length}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : null}
    </EuiFlexGroup>
  );
};

MailingAddressVerification.validationRules = MailingModelSchema.pick(['recipients']);

export default MailingAddressVerification;
