/* eslint-disable react-hooks/exhaustive-deps */
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { toNumber } from '../../model/cast';
import FormNode from '../../model/FormNode';
import React, { useState, useEffect } from 'react';
import numberPattern from './patterns/numberPattern';

export class ValidationError extends Error {}

export default function FormFieldNumber({
  node,
  application,
  onFormError,
  onFieldChange
}: {
  node: FormNode;
  application: ApplicationModel;
  onFormError: (e: Error) => void;
  onFieldChange: (value: number | null, node: FormNode) => any;
}) {
  const nodeValue: number = node.getValue(application);

  const [value, setValue] = useState<string>(toString(nodeValue));

  useEffect(() => setValue(toString(nodeValue)), [nodeValue]);

  const pattern = numberPattern;

  function onBlur(e: React.FocusEvent<HTMLInputElement>) {
    const correctedValue = pattern.correctComplete(e.target.value);

    if (correctedValue !== nodeValue) {
      onFieldChange(correctedValue, node);
    }
  }

  return (
    <input
      id={node.getInputElementId()}
      value={value}
      onChange={e => setValue(pattern.correctPartial(e.target.value))}
      onBlur={onBlur}
      maxLength={node.width ? toNumber(node.width, onFormError) : undefined}
      // // if we don't include size the preferred width of the input is zero.
      // // if there is nothing else making sure the page gets width, the whole
      // // page will be very narrow
      size={node.width ? toNumber(node.width, onFormError) : 6}
      className="narrow"
    />
  );
}

function toString(value: any) {
  if (value == null) {
    return '';
  } else {
    return value + '';
  }
}
