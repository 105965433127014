import { ScholarshipModelShape, ScholarshipReviewRoundModelShape } from '@sharedComponents/models';

// Step IDs for scholarship entry form
export enum SCHOLARSHIP_ENTRY_STEPS {
  RESEARCH,
  RESEARCH_SECOND,
  RESEARCH_THIRD,
  CONTACT,
  ELIGIBILITY,
  ELIGIBILITY_SECOND,
  ELIGIBILITY_THIRD,
  VERIFICATION,
  QA,
  QA_SECOND,
  PUBLISH,

  // self-listing virtual steps [not exists in form, but we use them to identify]
  // those steps computed from self_listing_step?
  VIRTUAL_STEP_THRESHOLD = 98,
  SELF_LISTING_IN_PROGRESS = 99,
  SELF_LISTING_COMPLETED = 100
}

export enum FORM_CHANGE_REQUEST_STATUSES {
  REVIEW_REQUESTED = 'review_requested',
  CHANGES_REQUESTED = 'changes_requested',
  APPROVED = 'approved'
}

export enum FORM_REVIEW_STATUSES {
  REVIEW_REQUESTED = 'review_requested',
  CHANGES_REQUESTED = 'changes_requested',
  APPROVED = 'approved'
}

export enum FORM_REVIEWER_TYPES {
  SUPER = 'super',
  STANDARD = 'standard'
}

export const DRAFT_STEP_BADGE_COLOR = {
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH]: 'default',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND]: 'default',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_THIRD]: 'default',
  [SCHOLARSHIP_ENTRY_STEPS.CONTACT]: 'warning',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY]: 'accent',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_SECOND]: 'accent',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_THIRD]: 'accent',
  [SCHOLARSHIP_ENTRY_STEPS.VERIFICATION]: '#BADA55',
  [SCHOLARSHIP_ENTRY_STEPS.QA]: 'success',
  [SCHOLARSHIP_ENTRY_STEPS.QA_SECOND]: 'success',

  // self-listing badge
  [SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_IN_PROGRESS]: '#ffdda0ad',
  [SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_COMPLETED]: '#FFA500'
};

export const DRAFT_STEP_STATUSES = {
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH]: 'Research in progress',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND]: 'Research in progress',
  [SCHOLARSHIP_ENTRY_STEPS.RESEARCH_THIRD]: 'Research complete',
  [SCHOLARSHIP_ENTRY_STEPS.CONTACT]: 'Contact complete',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY]: 'Eligibility in progress',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_SECOND]: 'Eligibility in progress',
  [SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_THIRD]: 'Eligibility complete',
  [SCHOLARSHIP_ENTRY_STEPS.VERIFICATION]: 'Verification complete',
  [SCHOLARSHIP_ENTRY_STEPS.QA]: 'QA in progress',
  [SCHOLARSHIP_ENTRY_STEPS.QA_SECOND]: 'QA complete',

  [SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_IN_PROGRESS]: 'Draft In Progress by Donor',
  [SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_COMPLETED]: 'Draft Submitted by Donor'
};

// review criteria configuration for single review round
// also used as a legacy format for whole review_criteria column
export type ReviewCriteriaRoundConfiguration = {
  // [criteriaID]: {}
  [key: string]: {
    name: string;
    scale: number;
    weight: number;
  };
};

export type ReviewCriteriaConfiguration = {
  // [roundNumber] : {}
  [key: number]: ReviewCriteriaRoundConfiguration;
};

export type ScholarshipWithReviewStatus = ScholarshipModelShape & {
  applicationCycles: Array<number>;
  hasScreeningScore?: boolean;
  mostRelevantReviewProcess?: ScholarshipReviewRoundModelShape[];
};

export interface ReviewRoundAssignations {
  /** applications.id: [reviewers_id] */
  [index: number]: number[];
}

export interface ScholarshipVettStatus {
  applied: boolean; // has an application
  submitted: boolean; // has a submitted application
  vetted: boolean; // did qualify questions answered
  pass?: boolean; // all qualified questions passed as 'I qualify'
}

export const SELF_LISTING_LAST_STEP_NUMBER = 14; // todo use model static
