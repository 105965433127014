import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import track from '@sharedComponents/base/track';
import FormModel from '@sharedClients/types/FormModel';
import IScholarshipClient from '@sharedClients/types/IScholarshipClient';
import UserClient from '@sharedClients/UserClient';
import { ScholarshipDataModel, ScholarshipPost } from '@sharedClients/types/ScholarshipModel';
import EditScholarship from './EditScholarship';
import { UserError, useErrorReporter } from '@sharedComponents/base/useErrorReporter';
import UserModel from '@sharedContract/UserModel';
import './AddScholarship.scss';
import { useAllClients } from "@sharedComponents/selectors/useAllClients";

export default function AddScholarship({ user }: { user: UserModel }) {
  const { scholarshipClient, applicationClient, userClient } = useAllClients();

  const [onError, errorMessage, clearError] = useErrorReporter();
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    document.title = `Scholar's App - Add scholarship`;

    window.onbeforeunload = () => 'Are you sure you want to abandon these edits?';

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // TODO! Thats a code duplication from 'Scholarship'(save against update, but a lot of dubplicated code). This has to be fixed.
  async function create(scholarship: ScholarshipPost) {
    // console.log('create. Add Scholarship', scholarship);
    const pastRecipients = scholarship.data.pastRecipients || [];

    scholarship.data.pastRecipients = [];

    let validpastreceipts = true;
    // ? why another validation here?
    for (let i = 0; i < pastRecipients.length; i++) {
      // old hack which has to be reworked eventually
      // also thats a copy-paste from EditScholarship. TODO: make it DRY
      let { name, note } = pastRecipients[i];
      name = name.trim();
      note = note.trim();

      // both fields must be set or left empty
      if ((name !== '' && note === '') || (note !== '' && name === '')) {
        validpastreceipts = false;
      }
    }

    if (!validpastreceipts) {
      onError(new UserError('Please fill past recipients information'));
      return;
    }

    // TODO Create donor
    scholarship = await createRelationsIfMissing(scholarship, {
      userClient,
      scholarshipClient
    }); // ? already seems like a problem, but most likely even if its required, then must be handled on Model level
    // console.log('create. Add Scholarship. createRelationsIfMissing', scholarship);

    const isDraftScholarship = user.type === 'dataEntry';
    const { id, isDraft = false } = await scholarshipClient.createScholarship(scholarship, isDraftScholarship);

    // ? Any reason that past recipients are handled here instead of on backend?
    // past recipients save code
    if (pastRecipients.length < (scholarship?.data.pastRecipients || []).length) {
      const ePastRecipientsObj = {};
      pastRecipients.forEach(i => (ePastRecipientsObj[i.pastRecipientId?.toString() || ''] = i.pastRecipientId));
      const dPastRecipientsId = scholarship?.data?.pastRecipients
        ?.filter(i => !ePastRecipientsObj[i.pastRecipientId || ''])
        ?.map(i => i.pastRecipientId);
      try {
        await scholarshipClient.deletePastRecipients(dPastRecipientsId || []);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error, 'error');
      }
    }
    if (pastRecipients.length) {
      /**
       * FIXME: this seems like was a check for file uploaded or not, but this is a wrong place for it. Especially when since now, uploading a photo is not a mandatory field
       */
      try {
        for (let i = 0; i < pastRecipients.length; i++) {
          if (pastRecipients[i].pastRecipientId === 0 && (!pastRecipients[i].name || !pastRecipients[i].note)) {
            continue;
          }
          await scholarshipClient.uploadPastRecipients(id, pastRecipients[i], isDraftScholarship);
        }
      } catch (error) {
        // ??
      }
    }

    clearError();

    track('scholarship-create' + isDraft ? '-draft' : '');

    if (isDraft) {
      // returned scholarship is a draft, we need to redirect page to newly created draft record
      setRedirect(`/scholarships/drafts/${id}`);
      return;
    }

    setRedirect(`/scholarships/${id}`);
  }

  const defaultValues: Partial<ScholarshipPost> = {
    data: {} as ScholarshipDataModel,
    allowQuestions: true
  };

  return (
    <div className="add-scholarship">
      {redirect ? <Redirect to={redirect} /> : null}
      <div className="content">
        {errorMessage}

        <EditScholarship
          scholarship={defaultValues as ScholarshipPost}
          scholarshipClient={scholarshipClient}
          applicationClient={applicationClient}
          userClient={userClient}
          user={user}
          canAddForm={true}
          onSave={scholarship => create(scholarship).catch(onError)}
          onCancel={() => setRedirect('/scholarships')}
          onError={onError}
        />
      </div>
    </div>
  );
}

// TODO:IMPROVEMENT: Why wouldnt this part handlede by backend?
async function createDonorIfMissing(scholarship: ScholarshipPost, userClient: UserClient) {
  if (scholarship.donor && scholarship.donor.id == null) {
    // todo test this case
    const name = scholarship.donor.name || '';
    const email = scholarship.donor.email;

    const donor = await userClient.createDonor({
      name,
      email
    });
    scholarship.donor = { id: donor.id, name, email };
  }

  userClient.createDealIfDonorMissing(scholarship);

  return scholarship;
}

async function createFormIfMissing(scholarship: ScholarshipPost, scholarshipClient: IScholarshipClient) {
  if (!scholarship.form) {
    const name = scholarship.name + ' (form)';

    scholarship.form = toMinimalForm(
      await scholarshipClient.createForm({
        name,
        data: [],
        standardFields: [],
        userType: 'applicant',
        donorId: scholarship.donor && scholarship.donor.id
      })
    );
  }

  return scholarship;
}

async function createRecommenderFormIfMissing(scholarship: ScholarshipPost, scholarshipClient: IScholarshipClient) {
  if (!scholarship.recommenderForm) {
    const name = scholarship.name + ' (recommender form)';

    scholarship.recommenderForm = toMinimalForm(
      await scholarshipClient.createForm({
        name,
        data: [],
        standardFields: [],
        userType: 'recommender',
        donorId: scholarship.donor && scholarship.donor.id
      })
    );
  }

  return scholarship;
}

function toMinimalForm(form: FormModel) {
  const { id, name } = form;

  return { id, name };
}

export async function createRelationsIfMissing(
  scholarship: ScholarshipPost,
  { scholarshipClient, userClient }: { scholarshipClient; userClient: UserClient }
) {
  if (!scholarship.applyUrl) {
    // todo this case when admin creates scholarship and data entry edits later
    scholarship = await createFormIfMissing(scholarship, scholarshipClient);

    scholarship = await createRecommenderFormIfMissing(scholarship, scholarshipClient);
  }

  scholarship = await createDonorIfMissing(scholarship, userClient);
  // console.log('create. Add Scholarship. createDonorIfMissing', scholarship);

  return scholarship;
}
