import React from 'react';
import { camelCaseToHuman } from './FormFieldLabel';
import { toString } from '../../model/cast';
import { FormNodeProps } from '../../apply/FormNodeProps';

export default function FormFieldCheckbox(props: FormNodeProps & { defaultChecked?: boolean }) {
  const { node, application, onFieldChange, warnOnMissingRequired } = props;
  const value = node.getValue(application) || props?.defaultChecked || undefined;
  const field = node.field;
  const label = toString(node.label || camelCaseToHuman(field), props.onFormError);

  const missing = warnOnMissingRequired && node.isMissingRequiredFields(application, false);
  const id = node.getInputElementId();
  const isDisabled = application.submittedAt && node.freezedAfterSubmit;
  return (
    <div className="field" key={id}>
      <div className="checkbox">
        <input
          id={id}
          type="checkbox"
          defaultChecked={value}
          disabled={node.disabled || isDisabled ? true : false}
          onChange={e => onFieldChange(e.target.checked, node)}
        />
        <label htmlFor={id}>
          {label}
          {missing ? <span className="error"> Required</span> : null}
        </label>
      </div>
    </div>
  );
}
