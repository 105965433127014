import './RecurrentNotificationEmailConfiguration.scss';

import { capitalize } from 'lodash';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { cn } from '@bem-react/classname';
import {
  ApplicantNamePlugin,
  ApplicantNameRenderer,
  AwardAmountPlugin,
  AwardAmountRenderer,
  DeadlinePlugin,
  DeadlineRenderer,
  getApplicantNameParser,
  getAwardAmountParser,
  getDeadlineParser
} from '@donors/utils/markdownPlugins';
import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  getDefaultEuiMarkdownParsingPlugins,
  getDefaultEuiMarkdownProcessingPlugins,
  getDefaultEuiMarkdownUiPlugins
} from '@elastic/eui';
import { DEFAULT_DATE_FORMAT } from '@sharedComponents/config';

import { RecurrentPaymentsConfigurationForm } from '../RecurringAwardsSetup.interface';

const blockClass = cn('RecurrentNotificationEmailConfiguration');
function RecurrentNotificationEmailConfiguration() {
  const formContext = useFormContext<RecurrentPaymentsConfigurationForm>();

  const notificationEmailConfig = formContext.watch('notificationEmail');
  const recipients = formContext.getValues('records');
  const disbursementsSchedule = formContext.getValues('disbursementsSchedule');

  const parsingList = getDefaultEuiMarkdownParsingPlugins();
  const exampleApplicant = {
    name: `${capitalize(recipients[0].name)} ${capitalize(recipients[0].last_name)}`,
    awardAmount: disbursementsSchedule[0].amount,
    deadline: moment(disbursementsSchedule[0].date).format(DEFAULT_DATE_FORMAT)
  };

  parsingList.push(getApplicantNameParser(exampleApplicant.name));
  parsingList.push(getAwardAmountParser(exampleApplicant.awardAmount));
  parsingList.push(getDeadlineParser(exampleApplicant.deadline));

  const processingList = getDefaultEuiMarkdownProcessingPlugins();

  // add the renderer for `applicantNamePlugin`
  processingList[1][1].components.applicantNamePlugin = ApplicantNameRenderer;
  processingList[1][1].components.awardAmountPlugin = AwardAmountRenderer;
  processingList[1][1].components.deadlinePlugin = DeadlineRenderer;

  const uiPlugins = getDefaultEuiMarkdownUiPlugins();

  uiPlugins.push(ApplicantNamePlugin);
  uiPlugins.push(AwardAmountPlugin);
  uiPlugins.push(DeadlinePlugin);

  const setEmailSubject = subject => {
    formContext.setValue('notificationEmail.subject', subject);
  };

  const setEmailDraft = body => {
    formContext.setValue('notificationEmail.body', body);
  };

  return (
    <EuiFlexGroup className={blockClass()} direction="column" gutterSize="s">
      <EuiFlexItem>
        Write an email that will be sent to all of the winning candidates. <br />
        Click on the the "Name" and "Award Amount" fields to automatically populate information specific to each
        candidate in the email.
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFieldText
          value={notificationEmailConfig.subject}
          onChange={e => setEmailSubject(e.target.value)}
          placeholder="Email subject"
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiMarkdownEditor
          className={blockClass('editor')}
          aria-label="Email draft example"
          value={notificationEmailConfig.body}
          onChange={setEmailDraft}
          height={350}
          uiPlugins={uiPlugins}
          parsingPluginList={parsingList}
          processingPluginList={processingList}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

RecurrentNotificationEmailConfiguration.validationRules = yup.object({});

export default RecurrentNotificationEmailConfiguration;
