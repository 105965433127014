import React from 'react';
import FormNotificationStatus from './FormNotificationStatus';
import track from '@sharedComponents/base/track';
import ApplicationModel, {
  CounselorNotificationStatus,
  NotificationStatus,
  RecommenderNotificationStatus
} from '@sharedClients/types/ApplicationModel';
import { FormNodeProps } from '../FormNodeProps';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function FormCounselorStatus(props: FormNodeProps) {
  return (
    <FormNotificationStatus
      {...props}
      getStatus={getStatus}
      sendNotification={sendCounselorNotification}
      objectName={'transcript'}
      actorName={'counselor'}
    />
  );
}

function getStatus(email: string, { application }: { application: ApplicationModel }): NotificationStatus {
  if (application.transcript) {
    return 'submitted';
  } else {
    const qns = application.counselorNotificationStatus;

    return qns && qns.counselor && qns.counselor.email === email ? (qns.status as NotificationStatus) : 'unsent';
  }
}

export function sendCounselorNotification({
  name,
  email,
  phone,
  application,
  customContent,
  onError,
  applicationClient,
  setCounselorNotificationStatus
}: {
  name: string;
  email: string;
  phone: string;
  customContent?: string;
  application: ApplicationModel;
  onError: (e: Error) => void;
  applicationClient: ApplicationClient;
  setCounselorNotificationStatus: (status: CounselorNotificationStatus) => void;
  setRecommenderNotificationStatus: (status: RecommenderNotificationStatus) => void;
}) {
  if (email && name && getStatus(email, { application }) === 'unsent') {
    return applicationClient
      .notifyCounselor(
        {
          name: name,
          email: email,
          phone: phone,
          content: customContent || '',
          skipEmail: false
        },
        application.id
      )
      .then(result => {
        if (result.status === 'sent') {
          track('application-notifiedCounselor');
        }

        return result;
      })
      .then(s => setCounselorNotificationStatus(s))
      .catch(onError);
  } else {
    return Promise.resolve();
  }
}
