import React from 'react';
import { useState } from 'react';
import { SlideDown } from 'react-slidedown';

function soon(ms) {
  return new Promise(resolve => setTimeout(resolve, ms || 0));
}

function isRunningInJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

export default function useAnimatingList(animationTime) {
  const [getAnimationState, setAnimationState] = useState({});

  const animateListItem = async (index, isShow) => {
    setAnimationState({ index, show: !isShow });

    await soon();

    setAnimationState({ index, show: isShow });

    await soon(isRunningInJest() ? 0 : animationTime);

    setAnimationState([]);
  };

  const renderListItem = (child, i) => {
    const { index, show } = getAnimationState;

    if (i === index) {
      return (
        <SlideDown key={i} transitionOnAppear={false}>
          {show ? child : null}
        </SlideDown>
      );
    } else {
      return child;
    }
  };

  return { renderListItem, animateListItem };
}
