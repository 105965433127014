import { parseInt, take } from 'lodash';
import React, { createContext, Dispatch, useContext, useState } from 'react';

import {
  EuiButtonEmpty,
  EuiPopoverTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCheckbox,
  EuiText,
  EuiButtonIcon,
  EuiFieldNumber,
  EuiPopover
} from '@elastic/eui';
import { UserModelShape } from '@sharedComponents/models';

import { DATA_ACTIONS, SELECTION_ACTIONS } from './config';
import { DataContext } from './DataContext';

const SelectionContext = createContext<{
  selection: Set<number>;
  dispatchSelection: Dispatch<{ action: any; payload: any }>;
}>({
  selection: new Set(),
  dispatchSelection: () => {}
});

const SelectionButton = ({
  reviewers,
  dispatchActionForSelection
}: {
  reviewers: UserModelShape[];
  dispatchActionForSelection;
}) => {
  const { selection } = useContext(SelectionContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return selection.size ? (
    <EuiPopover
      isOpen={isPopoverOpen}
      anchorPosition="upCenter"
      panelPaddingSize="s"
      button={
        <EuiButtonEmpty
          size="xs"
          iconType="arrowDown"
          color="primary"
          className="euiDataGrid__controlBtn"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          Assign {selection.size} {!selection.size || selection.size > 1 ? 'applications' : 'application'} to reviewer
        </EuiButtonEmpty>
      }
      closePopover={() => setIsPopoverOpen(false)}
      ownFocus={false}
    >
      <EuiPopoverTitle>
        {selection.size} {!selection.size || selection.size > 1 ? 'applications' : 'application'}
      </EuiPopoverTitle>
      <EuiFlexGroup direction="column" gutterSize="xs">
        {!!reviewers.length && (
          <EuiFlexItem style={{ alignItems: 'flex-start' }}>
            <EuiButtonEmpty
              color="text"
              iconType={'pin'}
              size="s"
              onClick={() => {
                dispatchActionForSelection({
                  action: DATA_ACTIONS.ASSIGN_SELECTED_TO_ALL,
                  context: selection
                });
                setIsPopoverOpen(false);
              }}
            >
              Assign all reviewers
            </EuiButtonEmpty>
          </EuiFlexItem>
        )}
        {reviewers.map(reviewer => (
          <EuiFlexItem key={reviewer.id} style={{ alignItems: 'flex-start' }}>
            <EuiButtonEmpty
              iconType={'pin'}
              size="s"
              onClick={() => {
                dispatchActionForSelection({
                  action: DATA_ACTIONS.ASSIGN_SELECTED,
                  context: selection,
                  value: reviewer
                });
                setIsPopoverOpen(false);
              }}
            >
              Assign/Remove {reviewer.data.name} {reviewer.data.lastName || ''}
            </EuiButtonEmpty>
          </EuiFlexItem>
        ))}
        <EuiFlexItem style={{ alignItems: 'flex-start' }}>
          <EuiButtonEmpty
            color="danger"
            iconType={'pin'}
            size="s"
            onClick={() => {
              dispatchActionForSelection({ action: DATA_ACTIONS.CLEAR_ASSIGNMENTS, context: selection });
              setIsPopoverOpen(false);
            }}
          >
            Clear assignments
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  ) : null;
};

const SelectionHeaderCell = tableSize => {
  const { data } = useContext(DataContext);
  const { selection, dispatchSelection } = useContext(SelectionContext);
  const [selectionAmount, setSelectionAmount] = useState<number>();
  const isAllSelected = selection.size > 0 && selection.size === tableSize;
  const isNoneSelected = selection.size === 0;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleSelectionByAmount = () => {
    const applications = take(data, selectionAmount);
    const selectionArray: Array<number> = [];
    for (const application of applications) {
      selectionArray.push(application.id);
    }

    dispatchSelection({ action: SELECTION_ACTIONS.ADD_SELECTION, payload: selectionArray });
  };

  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      anchorPosition="upCenter"
      panelPaddingSize="s"
      button={
        <EuiButtonIcon
          aria-label="show selection options"
          size="xs"
          iconType="boxesVertical"
          color="primary"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        />
      }
      closePopover={() => setIsPopoverOpen(false)}
      ownFocus={false}
    >
      <EuiFlexGroup direction="column" gutterSize="xs">
        <EuiFlexItem>
          <EuiFieldNumber
            value={selectionAmount || ''}
            max={data.length}
            onChange={e => setSelectionAmount(parseInt(e.target.value))}
            fullWidth={false}
          />
          <EuiButtonEmpty
            isDisabled={!selectionAmount}
            iconType={'pagesSelect'}
            size="s"
            onClick={handleSelectionByAmount}
          >
            Select top {selectionAmount || ''} {selectionAmount === 1 ? 'candidate' : 'candidates'}
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem style={{ alignItems: 'flex-start' }}>
          <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            justifyContent="spaceAround"
            style={{ width: '100%', margin: '0px' }}
          >
            <EuiFlexItem>
              <EuiButtonEmpty
                isDisabled={isNoneSelected}
                size="s"
                onClick={() => {
                  dispatchSelection({
                    action: SELECTION_ACTIONS.CLEAR_ALL,
                    payload: null
                  });
                  setIsPopoverOpen(false);
                }}
              >
                Clear all
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                isDisabled={isAllSelected}
                size="s"
                onClick={() => {
                  dispatchSelection({
                    action: SELECTION_ACTIONS.SELECT_ALL,
                    payload: null
                  });
                  setIsPopoverOpen(false);
                }}
              >
                Select all
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

const SelectionRowCell = ({ rowIndex }) => {
  const { data } = useContext(DataContext);
  const { selection, dispatchSelection } = useContext(SelectionContext);

  const dataGridItem = data[rowIndex];
  const isChecked = selection.has(dataGridItem.id);

  return (
    <EuiCheckbox
      style={{ alignSelf: 'center', justifySelf: 'center' }}
      id={`${rowIndex}`}
      aria-label={`Select row ${rowIndex}`}
      checked={isChecked}
      onChange={e => {
        if (e.target.checked) {
          dispatchSelection({ action: SELECTION_ACTIONS.ADD_SELECTION, payload: [dataGridItem.id] });
        } else {
          dispatchSelection({ action: SELECTION_ACTIONS.DELETE_SELECTION, payload: [dataGridItem.id] });
        }
      }}
    />
  );
};

const NumRowCell = ({ rowIndex }) => {
  const { data } = useContext(DataContext);
  const { selection } = useContext(SelectionContext);

  const dataGridItem = data[rowIndex];

  const num = Array.from(selection).indexOf(dataGridItem.id) + 1;

  return num ? (
    <div>
      <EuiText textAlign="center" size="s">
        {num}
      </EuiText>
    </div>
  ) : null;
};

export { NumRowCell, SelectionRowCell, SelectionHeaderCell, SelectionButton, SelectionContext };
