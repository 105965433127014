import { ErrorMessage } from '@donors/base/components/ErrorMessage';
import { UserError } from '@donors/base/useErrorReporter';
import { FormPost } from '@sharedClients/types/FormModel';
import { FormData, default as FormDataNode } from '@sharedClients/types/FormDataNode';
import React, { ChangeEvent, useEffect, useState } from 'react';
import SlideDown from 'react-slidedown';
import ButtonBar from '../components/ButtonBar';
import Field from '../components/Field';
import useSingleSubmit from '../useSingleSubmit';
import definitionHelp from './definitionHelp';
import './EditForm.scss';
import StandardFieldSelect from './StandardFieldSelect';
import useDraft from './useDraft';
import { SPECIAL_FORM_TYPE } from '@sharedComponents/interfaces/Forms.interface';

export default function EditForm({
  form,
  standardForm,
  onSave,
  onChange,
  onCancel,
  onPreview,
  onError
}: {
  form: FormPost & { id?: number };
  standardForm: FormData;
  onSave: (form: FormPost) => Promise<any>;
  onChange: (form: FormPost) => any;
  onPreview?: (form: FormPost) => any;
  onCancel: () => any;
  onError: (e: any) => any;
}) {
  const [name, setName] = useState(form.name || '');
  const [userType, setUserType] = useState(form.userType || 'applicant');
  const [data, unwrappedSetData] = useState(JSON.stringify(form.data || [], null, 2));
  const [standardFields, setStandardFields] = useState<string[]>(form.standardFields || []);
  const [isFormatted, setFormatted] = useState(true);
  const [singleSubmit, isSubmitting] = useSingleSubmit();
  const [fullScreen, setFullScreen] = useState(false);
  const [showStandardFields, setShowStandardFields] = useState<boolean>(
    form.standardFields && !!form.standardFields.length
  );

  const [isRecurrentApplicationForm, setIsRecurrentApplicationForm] = useState<boolean>(
    form.special_type === SPECIAL_FORM_TYPE.RECURRENT_APPLICATION
  );

  const { onEdited, onPersist, hasDraft, restoreDraft } = useDraft(form.id, setData);

  function setData(data: any) {
    unwrappedSetData(data);

    onEdited(data);
  }

  useEffect(() => {
    setUserType(form.userType);
    setStandardFields(form.standardFields || []);
    setShowStandardFields(form.standardFields && !!form.standardFields.length);

    if (form.name) {
      setName(form.name);
    }

    if (form.data) {
      unwrappedSetData(JSON.stringify(form.data, null, 2));
    }
  }, [form]);

  useEffect(() => {
    (document.getElementById('data') as HTMLElement).addEventListener('scroll', e => {
      const lineNumbers = document.getElementById('line-numbers');

      if (lineNumbers) {
        lineNumbers.style.top = `-${(e as any).target.scrollTop}px`;
      }
    });
  }, []);

  function onSubmit(e: Event) {
    let parsedData: any;

    try {
      parsedData = JSON.parse(data);
    } catch (e: any) {
      onError(new UserError('Invalid JSON: ' + e.message));
    }

    if (parsedData) {
      onSave({
        name,
        data: parsedData,
        userType,
        standardFields,
        special_type: isRecurrentApplicationForm ? SPECIAL_FORM_TYPE.RECURRENT_APPLICATION : null
      }).then(() => onPersist());
    }

    e.preventDefault();
  }

  function format() {
    try {
      setData(JSON.stringify(JSON.parse(data), null, 2));

      setFormatted(true);
    } catch (e) {
      setData(data);
    }
  }

  function onUserTypeChange(e: ChangeEvent<HTMLInputElement>) {
    setUserType(e.target.value);
  }

  return (
    <form onSubmit={singleSubmit(onSubmit)} className="EditForm">
      {hasDraft ? (
        <ErrorMessage
          title="You have an unsaved draft."
          detail={
            <span className="draftButtons">
              <button
                className="button invisible link"
                onClick={e => {
                  restoreDraft();
                  e.preventDefault();
                }}
              >
                Restore
              </button>
              <button
                className="button invisible link"
                onClick={e => {
                  onPersist();
                  e.preventDefault();
                }}
              >
                Discard
              </button>
            </span>
          }
        />
      ) : null}

      <div className="pageTitle">
        <h4>{form.userType === 'applicant' ? 'Application' : 'Recommendation'} Form</h4>
        <h2>{form.name || 'New Form'}</h2>
      </div>

      <Field label="Name" htmlFor="name">
        <input id="name" type="text" value={name} onChange={e => setName(e.target.value)} />
      </Field>

      <Field label="User Group">
        <div className="radio-label">
          <input
            type="radio"
            id="typeApplicant"
            value="applicant"
            checked={userType === 'applicant'}
            onChange={onUserTypeChange}
          />

          <label className="radio" htmlFor="typeApplicant">
            For <b>students</b> to submit scholarship applications
          </label>
        </div>
        <div className="radio-label">
          <input
            type="radio"
            id="typeRecommender"
            value="recommender"
            checked={userType === 'recommender'}
            onChange={onUserTypeChange}
          />
          <label className="radio" htmlFor="typeRecommender">
            For <b>recommenders</b> to submit letters of recommendation
          </label>
        </div>
      </Field>

      {userType === 'applicant' ? (
        <div className="head checkbox showStandardFields">
          <input
            type="checkbox"
            id="showStandardFields"
            checked={showStandardFields}
            onChange={e => setShowStandardFields(e.target.checked)}
          />

          <label className="name" htmlFor="showStandardFields">
            Use standard fields (to be deprecated)
          </label>
        </div>
      ) : null}

      {userType === 'applicant' ? (
        <div className="head checkbox">
          <input
            type="checkbox"
            id="isRecurrentApplicationForm"
            checked={isRecurrentApplicationForm}
            onChange={e => setIsRecurrentApplicationForm(e.target.checked)}
          />

          <label className="name" htmlFor="isRecurrentApplicationForm">
            Used for recurrent payments confirmation
          </label>
        </div>
      ) : null}

      <SlideDown transitionOnAppear={false}>
        {showStandardFields ? (
          <Field label="Standard fields" help="Select a set of standard fields to include.">
            <StandardFieldSelect
              standardFields={standardFields}
              setStandardFields={setStandardFields}
              standardForm={standardForm}
            />
          </Field>
        ) : null}
      </SlideDown>

      <div className={fullScreen ? ' fullScreen' : ''}>
        {fullScreen && onPreview ? (
          <ButtonBar className="links">
            <button
              className="button"
              onClick={e => {
                onPreview(form);
                e.preventDefault();
              }}
            >
              Preview
            </button>
          </ButtonBar>
        ) : null}

        <Field label="Definition" htmlFor="data" help={definitionHelp}>
          <JsonValidationError data={data} userType={userType} standardFields={standardFields} />

          <div className="textarea-wrapper">
            <div className="buttons">
              {!isFormatted ? (
                <button
                  className="button invisible"
                  onClick={e => {
                    e.preventDefault();
                    format();
                  }}
                >
                  <FormatIcon />
                </button>
              ) : null}
              <button
                className="button invisible"
                onClick={e => {
                  e.preventDefault();

                  setFullScreen(!fullScreen);
                }}
              >
                {fullScreen ? <LeaveFullScreenIcon /> : <FullScreenIcon />}
              </button>
            </div>

            <div id="line-numbers" className="line-numbers">
              {numberSequence(countNewlines(data) + 50).map(i => (
                <div key={i}>{i}</div>
              ))}
            </div>
            <textarea
              id="data"
              value={data}
              onChange={e => {
                setData(e.target.value);
                setFormatted(false);

                if (onChange) {
                  try {
                    onChange({
                      name,
                      data: JSON.parse(e.target.value),
                      standardFields,
                      userType
                    });
                  } catch (e) {}
                }
              }}
              rows={20}
            />
          </div>
        </Field>
        <ButtonBar>
          <button className="button" disabled={isSubmitting}>
            Save
          </button>
          <button
            className="button"
            onClick={e => {
              onCancel();
              e.preventDefault();
            }}
          >
            Cancel
          </button>
        </ButtonBar>
      </div>
    </form>
  );
}

function JsonValidationError({
  data,
  userType,
  standardFields
}: {
  data: any;
  userType: string;
  standardFields: string[];
}) {
  let error: string | null = null;

  try {
    const form = JSON.parse(data);

    error = validateFieldIds(form, userType, standardFields);
  } catch (e: any) {
    const m = e.message.match(/Unexpected (.*) in JSON at position ([0-9]*)/s);

    if (m) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [msg, token, position] = m;

      if (token === 'token \n') {
        token = 'newline';
      } else if (token === 'token  ') {
        token = 'space';
      } else if (token.startsWith('token ')) {
        token = `"${token.substr(6)}"`;
      } else {
        token = `"${token}"`;
      }

      error = `Did not expect to find ${token} on line ${
        countNewlines(data.substr(0, position)) + 1
      } after: ...${wordBoundaries(data.substr(Math.max(position - 50, 0), 50))}`;
    } else {
      error = e.message;
    }
  }

  return error ? (
    <div className="JsonValidationError">
      <ErrorMessage title="Invalid form" error={{ message: error }} />
    </div>
  ) : null;
}

function wordBoundaries(str: string) {
  const m = str.match(/[ .":'\t\n]*/);

  if (m) {
    return str.substr((m.index as number) + m[0].length);
  } else {
    return str;
  }
}

function numberSequence(end: number) {
  var list: number[] = [];
  for (var i = 1; i <= end; i++) {
    list.push(i);
  }

  return list;
}

function countNewlines(str: string) {
  let result = 0;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === '\n') {
      result++;
    }
  }

  return result;
}

const reservedIds = [
  'transcript',
  'id',
  'userId',
  'scholarships',
  'supportCount',
  'submittedAt',
  'updatedAt',
  'createdAt',
  'form',
  'recommenderForm',
  'review',
  'score',
  'counselorNotificationStatus',
  'recommenderNotificationStatus',
  'standardForm',
  'awardStatus',
  'isExternal',
  'forwardDocumentStatus'
];

export function validateFieldIds(form: FormData, userType: string, standardFields: string[]): string | null {
  let error: string | null = null;

  const ids: string[] = (standardFields || []).slice(0) || [];
  const titles: string[] = [];

  gatherIds(form, ids, titles, []);

  const idSet = new Set(ids);

  if (idSet.size < ids.length) {
    const idsSoFar: { [key: string]: boolean } = {};
    const duplicates: { [key: string]: boolean } = {};

    ids.forEach(id => {
      if (idsSoFar[id]) {
        duplicates[id] = true;
      }

      idsSoFar[id] = true;
    });

    if (Object.keys(duplicates).length) {
      error = 'The following fields are duplicated: ' + Object.keys(duplicates).join(', ');
    }
  }

  reservedIds.forEach(reservedId => {
    if (idSet.has(reservedId)) {
      error = `"${reservedId}" is a reserved field ID. Use a different ID.`;
    }
  });

  ids.forEach(id => {
    if (!isCamelCase(id)) {
      error = `"${id}" is not camelCase (should be called e.g. "firstName", "fullAddress", "dayOfYear")`;
    }

    if (id.match(/[^a-zA-Z0-9/]/)) {
      error = `"${id}" contains special characters. Use only letters and numbers.`;
    }
  });

  const mandatoryFields: string[] = mandatoryFieldsByType[userType] || [];

  const missingFields = mandatoryFields.filter(field => ids.indexOf(field) < 0);

  if (missingFields.length) {
    error = `Must include the field${missingFields.length > 1 ? 's' : ''} ${missingFields.join(', ')}.`;
  }

  return error;
}

function gatherIds(node: FormData, ids: string[], titles: string[], path: string[]) {
  if (typeof node == 'string') {
    ids.push(path.concat([node]).join('/'));
  } else if (typeof node === 'object') {
    const nodeObject = node as FormDataNode;

    if (nodeObject.path) {
      path = path.concat([nodeObject.path]);

      ids.push(path.join('/'));
    }

    if (nodeObject.field) {
      ids.push(path.concat([nodeObject.field]).join('/'));
    }

    if (nodeObject.title) {
      titles.push(nodeObject.title);
    }

    return getDescendants(node).forEach((child: FormData) => gatherIds(child, ids, titles, path));
  }
}

function getDescendants(nodeData: FormData | FormData[]): FormData[] {
  if (typeof nodeData === 'object') {
    if ((nodeData as any).length > 0) {
      return nodeData as any;
    }

    const node = nodeData as FormDataNode;
    const descendants = node.children || (node.columns as FormData[]);

    if (descendants) {
      return getDescendants(descendants);
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function isCamelCase(id: string) {
  return id.indexOf(' ') < 0 && id.indexOf('_') < 0 && id.indexOf('-') < 0 && id[0] >= 'a' && id[0] <= 'z';
}

// why they are mandatory?
const mandatoryFieldsByType = {
  recommender: ['recommender/name', 'recommender/phone', 'recommender/email'],
  applicant: []
};

function FullScreenIcon() {
  return (
    <svg
      viewBox="0 0 500 500"
      style={{
        strokeWidth: 80,
        fill: 'none'
      }}
    >
      <g>
        <g>
          <path d="M40,260L40,40L260,40M40,40L230,230" />
        </g>
      </g>
      <g>
        <g>
          <path d="M460,240L460,460L240,460M460,460L270,270" />
        </g>
      </g>
    </svg>
  );
}

function LeaveFullScreenIcon() {
  return (
    <svg
      viewBox="0 0 500 500"
      style={{
        strokeWidth: 80,
        fill: 'none'
      }}
    >
      <g>
        <g>
          <path d="M10,230L230,230L230,10M40,40L230,230" />
        </g>
      </g>
      <g>
        <g>
          <path d="M490,270L270,270L270,490M460,460L270,270" />
        </g>
      </g>
    </svg>
  );
}

function FormatIcon() {
  return (
    <svg viewBox="20 20 56 56">
      <g>
        <path
          d="M64.5,24v-3c0-1.6-1.3-3-3-3h-36c-1.7,0-3,1.4-3,3v12c0,1.7,1.3,3,3,3h36c1.7,0,3-1.3,3-3v-3h3
v12h-30v33c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3V48h24V24H64.5z"
        />
      </g>
    </svg>
  );
}
