import { useFormContext } from 'react-hook-form';
import { object } from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';

import { RecurrentPaymentsConfigurationForm } from '../RecurringAwardsSetup.interface';
import SummaryTable from './RecurrentPaymentsSummary/SummaryTable';
import SchedulePreview from './RecurrentPaymentsSummary/SchedulePreview';

function RecurrentPaymentsSummary() {
  const formContext = useFormContext<RecurrentPaymentsConfigurationForm>();
  const summaryData = formContext.getValues();

  return (
    <EuiFlexGroup direction="column" responsive={false}>
      <EuiFlexItem>
        <EuiTitle size="s">
          <h3>Recurrent payment summary</h3>
        </EuiTitle>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiText>
          This is your last step before submitting awards. Please, review all the information carefully before
          processing.
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <SummaryTable records={summaryData.records} headers={summaryData.headers} />
      </EuiFlexItem>
      <EuiFlexItem>
        <SchedulePreview schedule={summaryData.disbursementsSchedule} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

RecurrentPaymentsSummary.validationRules = object({});

export default RecurrentPaymentsSummary;
