import { ApplicationModelShape, ReviewModelShape, ScholarshipReviewRoundModelShape } from '../models';

export enum AWARD_STATUS {
  CANDIDATE = 'candidate', // at some point we took as assumption that awardStatus === 'candidate' is always a candidate for a scholarship. But it seems like wrong assumption/legacy bug, we should be double checking this.
  ELIMINATED = 'noCandidate', // manually eliminated by admin/donor
  AWARD_ACCEPTED = 'awardAccepted', // was awarded and accepted the award
  AWARD_NOTIFIED = 'awardNotified', // was awarded and not yet accepted the award
  AWARD_REJECTED = 'awardRejected', // was awarded, but rejected to take the award
  REJECT_NOTIFIED = 'rejectNotified' // scholarship finished awarding and this candidate was notified. Technically he is still a candidate, but its unlikely he will receive the award for this application
}

export type ApplicationWithScores = ApplicationModelShape & {
  totalAverageScore?: number;
  roundsStats?: {
    [roundID: number]: {
      isConverted: boolean;
      score: number;
      reviews: ReviewModelShape[];
    };
  };
};

export type DetailedReviewRoundResponse = ScholarshipReviewRoundModelShape & {
  applications: ApplicationWithScores[];
  currentReviewRounds: ScholarshipReviewRoundModelShape[];
  isScoreConverted: boolean;
};

export enum APPLICATION_DOCUMENT {
  APPLICATION = 'application',
  LETTERS_OF_RECOMMENDATION = 'lettersOfRecommendation'
}

export type ScholarshipApplicationsDocuments = {
  user: string;
  date: string;
  type: APPLICATION_DOCUMENT;
  file: number;
}[];

// email drafts for awarding dialogs, replacing those tokens with applicant values
export const ApplicantNameMarkupReplaceToken = '{:applicantName}';
export const AwardAmountMarkupReplaceToken = '{:awardAmount}';

export function getAwardStatusName(awardStatus) {
  return {
    candidate: 'Candidate',
    noCandidate: 'Eliminated',
    awardAccepted: 'Accepted award',
    awardNotified: 'Awarded; notified',
    awardRejected: 'Rejected award',
    rejectNotified: 'Eliminated; notified'
  }[awardStatus || AWARD_STATUS.CANDIDATE];
}

export const ACTION_APPLICATION_DUPLICATION = 'duplicateApplicationOnSubmit';
export const AVAILABLE_ACTIONS = [ACTION_APPLICATION_DUPLICATION];
