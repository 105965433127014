import * as React from 'react';

import { EuiStepsHorizontal } from '@elastic/eui';
import { EuiStepHorizontalProps } from '@elastic/eui/src/components/steps/step_horizontal';
import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';
import { enumKeys } from '@sharedComponents/utils/typeHelpers';

import { SCHOLARSHIP_ENTRY_STEPS_NAMES } from '../config';

export default function ScholarshipEntryStepper({
  activeStep,
  lastUnblockedStep,
  onStepChange
}: {
  activeStep: SCHOLARSHIP_ENTRY_STEPS;
  lastUnblockedStep: SCHOLARSHIP_ENTRY_STEPS | undefined;
  onStepChange: (step: SCHOLARSHIP_ENTRY_STEPS) => void;
}) {
  const horizontalSteps: Omit<EuiStepHorizontalProps, 'step'>[] = [];

  const stepEntries = enumKeys(SCHOLARSHIP_ENTRY_STEPS);
  for (const stepEntry of stepEntries) {
    // Skipping substeps(has '_' in their name) to be added into stepper
    if (stepEntry.includes('_')) {
      continue;
    }

    const currentStepName = SCHOLARSHIP_ENTRY_STEPS[activeStep];
    const baseStepName = currentStepName.split('_')[0];
    const isSelected = baseStepName === stepEntry;
    const stepsWithCurrentBase = Object.values(stepEntries).filter(step => step.includes(baseStepName));
    let stepName = SCHOLARSHIP_ENTRY_STEPS_NAMES[SCHOLARSHIP_ENTRY_STEPS[stepEntry]];
    if (isSelected && stepsWithCurrentBase.length > 1) {
      stepName += `[${stepsWithCurrentBase.indexOf(currentStepName as any /** FIXME */) + 1} of ${
        stepsWithCurrentBase.length
      }]`;
    }

    const isCompleted =
      lastUnblockedStep &&
      (stepsWithCurrentBase?.length > 1 && isSelected
        ? lastUnblockedStep >= SCHOLARSHIP_ENTRY_STEPS[stepsWithCurrentBase[stepsWithCurrentBase.length - 1]]
        : lastUnblockedStep >= SCHOLARSHIP_ENTRY_STEPS[stepEntry]);

    const isDisabled =
      !isSelected &&
      (!lastUnblockedStep ||
        // we allow visiting only next unblocked step
        SCHOLARSHIP_ENTRY_STEPS[stepEntry] > lastUnblockedStep + 1);

    horizontalSteps.push({
      title: stepName,
      isSelected: isSelected,
      status: isCompleted ? 'complete' : isDisabled ? 'disabled' : 'incomplete',
      disabled: isDisabled,
      onClick: () => {
        onStepChange(SCHOLARSHIP_ENTRY_STEPS[stepEntry]);
      }
    });
  }

  return <EuiStepsHorizontal steps={horizontalSteps} />;
}
