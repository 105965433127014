import warn from '../../base/warn';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { toNumber } from '../../model/cast';
import FormNode from '../../model/FormNode';
import React, { useState } from 'react';
import fieldValueToString from '../fieldValueToString';
import Pattern from './patterns/Pattern';

export class ValidationError extends Error {
  isUserError = true;
}

export default function FormFieldText(props: {
  node: FormNode;
  application: ApplicationModel;
  onFormError: (e: Error) => void;
  onFieldChange: (value: string, node: FormNode) => any;
  pattern?: Pattern;
}) {
  const { node, application, onFormError, onFieldChange } = props;

  const value = node.getValue(application);

  const valueString = fieldValueToString(value);

  const pattern = props.pattern || getPattern(node);

  // node.type = 'text' is default value for text input by form builder
  if (pattern === nullPattern && node.type && node.type !== 'text') {
    onFormError(
      new Error(
        `Ignoring unknown node type "${node.type}" in ${JSON.stringify({
          ...node,
          parent: undefined
        })}`
      )
    );
  }

  const [isInvalid, setInvalid] = useState(isInvalidValue(value, pattern));

  let inputClass = getInputClass(node);

  if (isInvalid) {
    inputClass = (inputClass || '') + ' invalid';
  }

  let autoComplete = getAutocomplete(node);

  const placeholder = pattern.placeholder;

  function onBlur(value: string) {
    if (value) {
      try {
        const correctedValue = pattern.correctComplete(value);

        if (correctedValue !== value) {
          onFieldChange(correctedValue, node);
        }

        setInvalid(false);
      } catch (e: any) {
        if (e.isUserError) {
          setInvalid(e.message);
        } else {
          warn(e);
        }
      }
    } else {
      setInvalid(false);
    }
  }

  return (
    <React.Fragment>
      <input
        id={node.getInputElementId()}
        type={'text'}
        value={valueString}
        onChange={e => onFieldChange(pattern.correctPartial(e.target.value), node)}
        onBlur={e => onBlur(e.target.value)}
        maxLength={node.width ? toNumber(node.width, onFormError) : undefined}
        // if we don't include size the preferred width of the input is zero.
        // if there is nothing else making sure the page gets width, the whole
        // page will be very narrow
        size={node.width ? toNumber(node.width, onFormError) : 30}
        className={inputClass}
        placeholder={placeholder}
        autoComplete={autoComplete}
        key="input"
      />
      {isInvalid ? (
        <div className="error" key="invalid">
          {isInvalid}
        </div>
      ) : null}
    </React.Fragment>
  );
}

function isInvalidValue(value: string, pattern: Pattern) {
  if (!value) {
    // required values are handled by the label.
    return;
  }

  try {
    pattern.correctComplete(value);
  } catch (e) {
    if (e instanceof ValidationError) {
      return e.message;
    }
  }

  return false;
}

export function getInputClass(node) {
  if (node.width) {
    if (node.width < 16) {
      return 'narrow';
    } else if (node.width < 40) {
      return 'medium-narrow';
    }
  }

  // intentionally returning undefined
}

function getAutocomplete(node: FormNode) {
  let autoComplete =
    {
      email: 'email',
      street: 'address-line1',
      city: 'address-level2',
      state: 'address-level1',
      zip: 'postal-code',
      country: 'country',
      phone: 'tel'
    }[node.field] || 'off';

  if (autoComplete !== 'off') {
    // this is all a bit of a heuristic, but then again Chrome's autofill seems completely random, too.
    const title = node.parent && (node.parent.title || (node.parent.parent && node.parent.parent.title));

    if (title === 'Mailing') {
      autoComplete = 'billing ' + autoComplete;
    } else if (title === 'Current') {
      autoComplete = 'shipping ' + autoComplete;
    }
  }

  return autoComplete;
}

export function getPattern(node: FormNode): Pattern {
  return (node.getPattern() || nullPattern) as any;
}

export const nullPattern = {
  correctPartial: s => s,
  correctComplete: s => s
};
