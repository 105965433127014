import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { getUserTypeFromUrl } from '@sharedClients/getServiceUrl';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import LoginContainer from '@sharedComponents/routes/auth/LoginContainer';
import LoginSignupForm from '@sharedComponents/routes/auth/LoginSignupForm';

import DonorPageLayout from './layout/DonorPageLayout';
import { ContentBody } from './layout/DonorPageLayout/ContentBody';
import SetPassword from './login/SetPassword';
import ProtectedRoute from '@sharedComponents/routes/ProtectedRoute';

function RouterComponent() {
  return (
    <Switch>
      <Route
        exact
        path="/set-password"
        render={() => {
          return (
            <PageContentWrapper fullHeight centeredContent>
              <SetPassword />
            </PageContentWrapper>
          );
        }}
      />

      {/** Auth routers */}
      <Route
        render={() => {
          const userType = getUserTypeFromUrl(document.location.toString());
          return (
            <LoginContainer
              fetch={window.fetch}
              loginFormComponent={LoginSignupForm}
              isLoginOptional={true}
              userType={userType}
              renderChildren={(jwt: string | null, fetch, props: any) => {
                const { user, onLogout } = props;

                return (
                  <DonorPageLayout user={user} onLogout={onLogout}>
                    {/** Auth protected routes */}
                    <ProtectedRoute props={props}>
                      <ContentBody jwt={jwt} onLogout={props.onLogout} />
                    </ProtectedRoute>
                  </DonorPageLayout>
                );
              }}
            />
          );
        }}
      />
    </Switch>
  );
}

export default Sentry.withProfiler(RouterComponent);
