import React, { useEffect, useState } from 'react';
import './ReviewerStatusModal.scss';

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiProgress,
  EuiAccordion,
  EuiSpacer,
  EuiPanel
} from '@elastic/eui';
import { DetailedReviewRoundResponse } from '@sharedComponents/interfaces/Applications.interface';
import { ScholarshipWithReviewStatus } from '@sharedComponents/interfaces/Scholarships.interface';
import { getUserFullNameFromData } from '@sharedComponents/utils/userUtils';
import { mapKeys, mean } from 'lodash';

type ReviewerData = {
  id: number;
  name: string;
  rate: number;
  applications: [
    {
      id: number;
      name: string;
      rate: number;
    }
  ];
};

type StatsData = {
  generalRate: number | null;
  reviewers: ReviewerData[];
};

const ReviewerStatusModal = ({
  closeModal,
  scholarship,
  reviewRoundDetails
}: {
  closeModal: () => void;
  scholarship: ScholarshipWithReviewStatus;
  reviewRoundDetails: DetailedReviewRoundResponse;
}) => {
  const [statsData, setStatsData] = useState<StatsData>({
    generalRate: null,
    reviewers: []
  });

  const [openReviewerData, setOpenReviewerData] = useState<number | null>();

  const toggleOpenReviewerData = reviewerId => {
    setOpenReviewerData(value => (value === reviewerId ? null : reviewerId));
  };

  useEffect(() => {
    const data: Partial<StatsData> = {};

    let reviewers = mapKeys<any>(
      (scholarship.reviewers || []).map(reviewer => ({
        id: reviewer.id,
        name: getUserFullNameFromData(reviewer.data),
        applications: []
      })),
      'id'
    );

    const reviewRoundId = reviewRoundDetails.id;
    const scholarshipCriterias = Object.keys(scholarship.currentRoundReviewCriteriasConfiguration || {});
    const applications = mapKeys(reviewRoundDetails.applications || [], 'id');

    // calculating rates per application
    Object.keys(reviewRoundDetails.assignations).forEach(applicationId => {
      let applicationObj = applications[applicationId];
      let applicationReviewsByReviewerMap = mapKeys(
        (applicationObj.reviews || []).filter(review => review.review_round === reviewRoundId),
        'user_id'
      );
      let assignedReviewerIds = reviewRoundDetails.assignations[applicationId] || [];

      assignedReviewerIds.forEach(reviewerId => {
        let reviewerReview = applicationReviewsByReviewerMap[reviewerId] || {};

        reviewers[reviewerId].applications.push({
          id: applicationObj.id,
          name: getUserFullNameFromData(applicationObj.data),
          rate: Math.floor((Object.keys(reviewerReview.data || {}).length / scholarshipCriterias.length) * 100) // calculating rate based on the amount of criterias checked by reviewer
        });
      });
    });

    // calculating rates per reviewer
    Object.values(reviewers as { [key: string]: Partial<ReviewerData> }).forEach(reviewer => {
      reviewer.rate = Math.floor(mean(reviewer.applications!.map(application => application.rate)));
    });

    data.reviewers = Object.values(reviewers).filter(reviewer => reviewer.applications.length); // showing only reviewers that are assigned for the current round;

    data.generalRate = Math.floor(mean(data.reviewers.map(reviewer => reviewer.rate)));

    setOpenReviewerData(null);
    setStatsData(data as StatsData);
  }, [scholarship, reviewRoundDetails]);

  return (
    <EuiModal onClose={() => closeModal()} className="ReviewerStatusModal">
      <EuiModalHeader>
        <EuiModalHeaderTitle>Reviewer Status</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup direction="column" gutterSize="l">
          <EuiFlexItem>
            <EuiFlexGroup gutterSize="s" direction="column">
              <EuiFlexItem>
                <h3>Status of all reviewers</h3>
                <EuiSpacer size="xs" />
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiProgress
                  label="All Reviewers"
                  value={statsData.generalRate || 0}
                  max={100}
                  size="m"
                  color="primary"
                  valueText={true}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFlexGroup gutterSize="s" direction="column">
              <EuiFlexItem>
                <h3>Status of individual reviewers</h3>
                <EuiSpacer size="xs" />
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiFlexGroup gutterSize="m" direction="column">
                  {statsData.reviewers.map(reviewer => {
                    let isAccordionOpen = openReviewerData === reviewer.id;

                    return (
                      <EuiFlexItem className={'reviewerData'} key={reviewer.id}>
                        <EuiFlexItem>
                          <EuiFlexGroup gutterSize="s" responsive={false}>
                            <EuiFlexItem>
                              <EuiProgress
                                label={reviewer.name}
                                value={reviewer.rate || 0}
                                max={100}
                                size="m"
                                color="subdued"
                                valueText={true}
                              />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiButtonIcon
                                iconType={isAccordionOpen ? 'minimize' : 'expand'}
                                display={isAccordionOpen ? 'fill' : 'fill'}
                                onClick={() => toggleOpenReviewerData(reviewer.id)}
                                aria-label="Details"
                                color="ghost"
                                size="xs"
                              />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiAccordion
                          className={'details'}
                          id={`reviewer_${reviewer.id}`}
                          paddingSize="none"
                          arrowDisplay="none"
                          forceState={isAccordionOpen ? 'open' : 'closed'}
                        >
                          <EuiPanel color="subdued" borderRadius="m" hasShadow={false}>
                            <EuiSpacer size="xs" />
                            <EuiFlexGroup gutterSize="m" direction="column">
                              {reviewer.applications.map(application => (
                                <EuiFlexItem key={application.id}>
                                  <EuiProgress
                                    label={application.name}
                                    value={application.rate || 0}
                                    max={100}
                                    size="xs"
                                    color="warning"
                                    valueText={true}
                                  />
                                </EuiFlexItem>
                              ))}
                            </EuiFlexGroup>
                          </EuiPanel>
                        </EuiAccordion>
                      </EuiFlexItem>
                    );
                  })}
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Close</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default ReviewerStatusModal;
