import { useEffect } from 'react';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

/**
  Used to track 'invitation' hash in url and asyncly send confirmation request to backend
 */
export default function useInvitationConfirm() {
  const userClient = useUserClient();

  useEffect(() => {
    const urlSearch = new URLSearchParams(document.location.search);
    const verificationCode = urlSearch.get('invitation');
    if (verificationCode) {
      // verification code for invitation
      userClient
        .confirmInvitation(verificationCode)
        .then(() => {
          // Invitation was verified, we can remove it from url to not trigger any more requests
          urlSearch.delete('invitation');
          window.history.replaceState({}, document.title, '?' + urlSearch.toString());
        })
        .catch(e => {
          // This error shouldnt be fatal on frontend, however we should log this to sentry
          // eslint-disable-next-line no-console
          console.error('Invitation confirmation error: ', e.message);
        });
    }
  }, [document.location.search]);

  return true;
}
