import { getServiceUrl } from './getServiceUrl';
import { handleResult } from './handleResult';
import { getStaticHeaders, wrapToAddReferer } from './UserClient';
import { handleError } from './handleResult';

export class ScholarshipClient {
  /**
   * @param fetch The fetch method. Should be window.fetch in the browser or node-fetch on node.
   */
  constructor(fetch, baseUrl, jwt = null) {
    this.fetch = wrapToAddReferer(fetch); // ? all the clients has the same code parts. Seems they can be actually extending some base class instead...

    this.baseUrl = baseUrl || getServiceUrl('applications');
    this.jwt = jwt;
  }

  async createScholarship(scholarship, isDraft = false) {
    const res = await this.fetch(`${this.baseUrl}/v1/scholarships`, {
      method: 'POST',
      body: JSON.stringify({
        scholarship: scholarship,
        isDraft
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating scholarship');
  }

  async upsertDraftScholarshipEntry(scholarship) {
    const res = await this.fetch(`${this.baseUrl}/v2/scholarship/draft`, {
      method: 'POST',
      body: JSON.stringify({
        scholarship: scholarship
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating scholarship');
  }

  // Thats more like UserClient-autocomplete endpoint, but we need it for scholarships, so -
  async dataLookup(query, dataType) {
    const res = await this.fetch(
      this.baseUrl + `/v2/autocomplete/${dataType}/${encodeURIComponent(query)}`,
      this.getHeaders()
    );

    return handleResult(res, `${dataType} lookup`);
  }

  async updateScholarship(id, scholarship) {
    const res = await this.fetch(`${this.baseUrl}/v1/scholarships/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        scholarship: scholarship
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'saving scholarship');
  }

  async uploadPastRecipients(scholarshipid, payload, isDraft = false) {
    const form = new FormData();
    form.append('id', scholarshipid);
    form.append('name', payload.name);
    form.append('note', payload.note);
    form.append('link', payload.link || '');
    form.append('files', payload.file || '');
    form.append('pastRecipientId', payload.pastRecipientId || '');
    form.append('isDraft', isDraft);
    try {
      const res = await this.fetch(this.baseUrl + '/v1/pastRecipients', {
        method: 'POST',
        body: form,
        ...this.getAuthHeaders()
      });
      return handleResult(res, 'uploading past Recipients');
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  async getPastRecipients(scholarShipId, isDraft = false) {
    try {
      const res = await this.fetch(
        addQuery(`${this.baseUrl}/v1/pastRecipients/${scholarShipId}`, {
          isDraft
        }),
        this.getAuthHeaders()
      );

      return handleResult(res, 'getting past Recipients');
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  async deletePastRecipients(pastRecipientIds) {
    const res = await this.fetch(`${this.baseUrl}/v1/pastRecipients`, {
      method: 'PUT',
      body: JSON.stringify({ pastRecipientIds: pastRecipientIds }),
      ...this.getAuthHeaders()
    });
    return handleResult(res, 'deleting past Recipients');
  }

  async deleteScholarship(scholarshipID, isDraft = false) {
    const res = await this.fetch(`${this.baseUrl}/v1/scholarships${isDraft ? '/drafts' : ''}/${scholarshipID}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting scholarship');
  }

  async publishDraftScholarship(scholarshipID) {
    const res = await this.fetch(`${this.baseUrl}/v2/scholarships/drafts/${scholarshipID}/publish`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'publish scholarship');
  }

  async getScholarship(id, fields, applicationFields, applicationFilter, isDraft = false) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/scholarships/${id}`, {
        fields: fields ? fields.join(',') : null,
        applicationFields: applicationFields ? applicationFields.join(',') : null,
        applicationFilter,
        isDraft
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting scholarship');
  }

  async getScholarshipEntry(id, isDraft = false) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v2/scholarship/${id}`, {
        isDraft
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting scholarship entry');
  }

  async getMyScholarships() {
    const res = await this.fetch(`${this.baseUrl}/v2/scholarships`, this.getHeaders());

    return handleResult(res, 'getting my scholarships');
  }

  async getScholarshipsListing(filter, page = 0, pageSize = 50) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v2/scholarships/listing`, {
        filter: JSON.stringify(filter),
        page,
        pageSize
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting scholarships listing');
  }

  async filterScholarships({
    academicYear,
    gender,
    ethnicity,
    collegeType,
    area,
    gpa,
    residency,
    lowIncome,
    communityService,
    college,
    school
  }) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/scholarships/filter`, {
        academicYear: academicYear ? academicYear : undefined,
        gender: gender ? gender : undefined,
        ethnicity: ethnicity ? ethnicity : undefined,
        collegeType: collegeType ? collegeType : undefined,
        area: area ? area : undefined,
        gpa: gpa ? gpa : undefined,
        residency: residency ? residency : undefined,
        lowIncome: lowIncome ? lowIncome : undefined,
        communityService: communityService ? communityService : undefined,
        firstGen: college ? college.firstGen : undefined,
        type: college ? college.type : undefined,
        school: school ? school.school : undefined
      }),
      this.getHeaders()
    );
    return handleResult(res, 'recommending scholarships');
  }

  async getScholarshipsOfDonor(donorId, fields) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/scholarships/of-donor/${donorId}`, {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting scholarships of donor');
  }

  async createForm(form) {
    const res = await this.fetch(`${this.baseUrl}/v1/forms`, {
      method: 'POST',
      body: JSON.stringify(form),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating form');
  }

  async updateForm(id, form) {
    const res = await this.fetch(`${this.baseUrl}/v1/forms/${id}`, {
      method: 'PUT',
      body: JSON.stringify(form),
      ...this.getHeaders()
    });

    return handleResult(res, 'saving form');
  }

  async upsertForm(form) {
    const res = await this.fetch(`${this.baseUrl}/v2/forms`, {
      method: 'PUT',
      body: JSON.stringify(form),
      ...this.getHeaders()
    });

    return handleResult(res, 'upserting form');
  }

  async getForm(id, fields) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/forms/${id}`, {
        fields: fields ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting form');
  }

  async getFormEntry(id) {
    const res = await this.fetch(`${this.baseUrl}/v2/forms/${id}`, this.getHeaders());

    return handleResult(res, 'getting form');
  }

  async requestFormReview(formId, data) {
    const res = await this.fetch(`${this.baseUrl}/v2/forms/${formId}/requestReview`, {
      method: 'POST',
      body: JSON.stringify(data),
      ...this.getHeaders()
    });

    return handleResult(res, 'requesting form review');
  }

  async requestFormChanges(formId, changes, onlySave) {
    const query = {};

    if (onlySave) {
      query.onlySave = true;
    }

    const res = await this.fetch(addQuery(`${this.baseUrl}/v2/forms/${formId}/requestChanges`, query), {
      method: 'POST',
      body: JSON.stringify({ changes }),
      ...this.getHeaders()
    });

    return handleResult(res, 'requesting form changes');
  }

  async approveForm(formId) {
    const res = await this.fetch(`${this.baseUrl}/v2/forms/${formId}/approve`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'approving a form');
  }

  async getForms({ page = -1, textFilter = '', fields = [], special_type = null } = {}) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v2/forms`, {
        textFilter,
        page,
        fields: Array.isArray(fields) && fields.length ? fields.join(',') : undefined,
        special_type: special_type
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting forms');
  }

  async getFormsToReview() {
    const res = await this.fetch(`${this.baseUrl}/v2/formsToReview`, this.getHeaders());

    return handleResult(res, 'getting forms to review');
  }

  async getEditorStats() {
    const res = await this.fetch(`${this.baseUrl}/v1/editor-stats`, this.getHeaders());

    return handleResult(res, 'getting editor stats');
  }

  async createScholarshipToCheck(form) {
    const res = await this.fetch(`${this.baseUrl}/v1/scholarships-to-check`, {
      method: 'POST',
      body: form,
      ...this.getAuthHeaders()
    });

    return handleResult(res, 'creating scholarship to check');
  }

  async getScholarshipsToCheck(filters = {}) {
    const res = await this.fetch(addQuery(`${this.baseUrl}/v1/scholarships-to-check`, filters), this.getHeaders());

    return handleResult(res, 'getting scholarships to check');
  }

  async getScholarshipsToCheckFlyer(scholarshipId, fileName) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v1/scholarships-to-check/${scholarshipId}/flyer`, {}),
      this.getAuthHeaders()
    );

    if (res.ok) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    } else {
      await handleError(res, 'getting scholarships flyer');
    }
  }

  async updateScholarshipToCheck(scholarship) {
    const res = await this.fetch(`${this.baseUrl}/v1/scholarships-to-check/${scholarship.id}`, {
      method: 'PUT',
      body: JSON.stringify(scholarship),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating scholarship to check');
  }

  async getMostRecentUnfinishedDraftScholarship() {
    const res = await this.fetch(`${this.baseUrl}/v2/scholarships/drafts`, {
      method: 'GET',
      ...this.getHeaders()
    });

    return handleResult(res, 'updating scholarship to check');
  }

  // events
  async logScholarshipEventAuthorized(scholarshipId, event) {
    const res = await this.fetch(`${this.baseUrl}/v2/events/${scholarshipId}/eventAuthorized?event=${event}`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'logging event authorized');
  }

  async getEventAuthorizedUsers(scholarshipId, event, daysNumber) {
    const query = {
      event,
      daysNumber
    };

    const res = await this.fetch(addQuery(`${this.baseUrl}/v2/events/${scholarshipId}/eventAuthorizedUsers`, query), {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting event authorized users');
  }

  async getAllScholarshipEventsData(scholarshipId, daysNumber) {
    const res = await this.fetch(`${this.baseUrl}/v2/events/${scholarshipId}?daysNumber=${daysNumber}`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting all scholarship events data');
  }

  getHeaders() {
    return {
      headers: {
        ...(this.jwt ? { Authorization: this.jwt } : null),
        ...getStaticHeaders()
      }
    };
  }
  getAuthHeaders() {
    return {
      headers: {
        ...(this.jwt ? { Authorization: this.jwt } : null)
      }
    };
  }
}

function addQuery(url, params) {
  const query = Object.keys(params)
    .map(k => (params[k] != null ? encodeURIComponent(k) + '=' + encodeURIComponent(params[k]) : null))
    .filter(q => !!q)
    .join('&');

  return query ? url + '?' + query : url;
}
