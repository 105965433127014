import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiKeyPadMenuItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@sharedComponents/formElements';
import { FIELD_ICONS, FIELD_NAMES, SUPPORTED_NODES_WITH_SCHEMAS } from '@sharedComponents/interfaces/Forms.interface';
import { APPLICATION_NODE_TYPE } from '@sharedComponents/schemas/FormNodesSchema';

import FormNodeBody from '../EditFormNodeModal/FormNodeBody';

const AddNewNodeModal = ({ onModalSubmit }) => {
  const [selectedFieldType, setSelectedFieldType] = useState<APPLICATION_NODE_TYPE>();

  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(selectedFieldType ? SUPPORTED_NODES_WITH_SCHEMAS[selectedFieldType] : {}),
    shouldUnregister: false,
    defaultValues: {}
  });

  // update form when node type changed
  useEffect(() => {
    if (selectedFieldType) {
      const nodeDefaults = SUPPORTED_NODES_WITH_SCHEMAS[selectedFieldType].getDefault();
      formMethods.reset({ ...nodeDefaults, type: selectedFieldType });
    }
  }, [selectedFieldType]);

  const onModalSubmitHandler = formMethods.handleSubmit(
    async entry => {
      onModalSubmit({ type: selectedFieldType, ...entry });
    },
    e => {
      // eslint-disable-next-line no-console
      console.error('submit error:', e);
    }
  );

  const onCancelHandler = () => {
    onModalSubmit();
  };

  return (
    <EuiModal onClose={onCancelHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Add new field</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody style={{ maxWidth: '768px' }}>
        {selectedFieldType ? (
          <FormProvider {...formMethods}>
            <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
              <EuiFlexItem>
                <TextField
                  fieldName="type"
                  rowProps={{ label: 'Field Type:', fullWidth: true, display: 'columnCompressed', isDisabled: true }}
                />
              </EuiFlexItem>
              <FormNodeBody type={selectedFieldType} />
            </EuiFlexGroup>
          </FormProvider>
        ) : (
          <EuiFlexGroup
            direction="row"
            responsive={false}
            gutterSize="m"
            style={{ maxWidth: '600px', flexFlow: 'wrap' }}
          >
            {Object.keys(SUPPORTED_NODES_WITH_SCHEMAS).map(value => (
              <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
                <EuiKeyPadMenuItem
                  label={FIELD_NAMES[value] ? FIELD_NAMES[value] : value}
                  href="#"
                  onClick={() => setSelectedFieldType(value as APPLICATION_NODE_TYPE)}
                >
                  <EuiIcon type={FIELD_ICONS[value] ? FIELD_ICONS[value] : 'star'} size="l" />
                </EuiKeyPadMenuItem>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        )}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onCancelHandler} size="s">
          Cancel
        </EuiButtonEmpty>
        <EuiButton color="primary" onClick={onModalSubmitHandler} size="s">
          Submit
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AddNewNodeModal;
