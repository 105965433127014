import { ChecklistResponseShape } from '@sharedComponents/interfaces/CollegeReadyChecklists.interface.js';
import { LobAddressVerificationError } from '@sharedComponents/interfaces/Mailing.interface.js';
import { ExtraSignupData, UserProfile } from '@sharedComponents/interfaces/UserData.interface';
import { BroadcastModelShape, MailingModelShape, UserModelShape } from '@sharedComponents/models';
import { BroadcastCreateParametersType } from '@sharedComponents/schemas/Broadcasts.schema';
import {
  AddressVerificationType,
  MailingAddressType,
  MailingTemplateVariablesType
} from '@sharedComponents/schemas/MailingModelSchema';
import { UserListingFilterType } from '@sharedComponents/schemas/UserModelSchema';

import { CreateDonorModel } from '../contract/DonorModel';
import { UserDataModel } from '../contract/UserModel';
import { getServiceUrl } from './getServiceUrl.js';
import { handleError, handleResult } from './handleResult';
import { ServerError } from './ServerError';
import { ScholarshipDataModel, ScholarshipPost } from './types/ScholarshipModel';

export default class UserClient {
  fetch;
  baseUrl;
  jwt;

  /**
   * @param fetch The fetch method. Should be window.fetch in the browser or node-fetch on node.
   */
  constructor(fetch?, baseUrl?, jwt?) {
    this.fetch = wrapToAddReferer(fetch || window.fetch);

    this.baseUrl = baseUrl || getServiceUrl('applications');
    this.jwt = jwt || null;
  }

  /**
   * @returns A JWT.
   */
  async login(user: string, password: string | null, code?: string) {
    const reqData = { user, password };

    if (code) {
      reqData['code'] = code;
    }

    const res = await this.fetch(this.baseUrl + '/v1/login', {
      method: 'POST',
      body: JSON.stringify(reqData),
      ...this.getHeaders()
    });

    return handleResult(res, 'logging in');
  }

  async createUser(user: Partial<UserModelShape> & ExtraSignupData) {
    const res = await this.fetch(this.baseUrl + '/v1/users', {
      method: 'POST',
      body: JSON.stringify({ ...user }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating user');
  }

  async createuserBulk(form: FormData) {
    const res = await this.fetch(this.baseUrl + '/v1/bulkinvite', {
      method: 'POST',
      body: form,
      ...this.getHeadersFormData()
    });

    return handleResult(res, 'creating user');
  }

  async updateUser(user: {
    data?: UserDataModel;
    donorId?: number;
    isEmailVerified?: boolean;
    type?: string;
    id: number;
    donor?: any;
  }) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify(user),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating user');
  }

  async updateUserProfileData(id, userProfileData: UserProfile['data']): Promise<UserProfile> {
    const res = await this.fetch(`${this.baseUrl}/v2/user/data/${id}`, {
      method: 'PUT',
      body: JSON.stringify(userProfileData),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating user profile');
  }

  async deleteUser(userId) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${userId}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting user');
  }

  async getMatchingUserCount(scholarshipData: ScholarshipDataModel) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/applicants/matching-count`, {
      method: 'POST',
      body: JSON.stringify(scholarshipData),
      ...this.getHeaders()
    });

    return handleResult(res, 'getting matching user count');
  }

  async createDonor(donor: CreateDonorModel) {
    const res = await this.fetch(this.baseUrl + '/v1/donors', {
      method: 'POST',
      body: JSON.stringify(donor),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating donor');
  }

  async getDonor(id) {
    const res = await this.fetch(this.baseUrl + `/v1/donors/${id}`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting donor');
  }

  async getDonors({ page, textFilter }: { page: number; textFilter: string }) {
    const res = await this.fetch(
      addQuery(`${this.baseUrl}/v2/donors`, {
        textFilter,
        page
      }),
      {
        ...this.getHeaders()
      }
    );

    return handleResult(res, 'getting donors');
  }

  async updateDonor(donor: CreateDonorModel & { id: number }) {
    const res = await this.fetch(this.baseUrl + `/v1/donors/${donor.id}`, {
      method: 'PUT',
      body: JSON.stringify(donor),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating donor');
  }

  async deleteDonor(donorId) {
    const res = await this.fetch(`${this.baseUrl}/v1/donors/${donorId}`, {
      method: 'DELETE',
      ...this.getHeaders()
    });

    return handleResult(res, 'deleting donor');
  }

  async createDealIfDonorMissing(scholarship: ScholarshipPost) {
    // This is not crucial failure, so we can let it happen
    try {
      await this.fetch(this.baseUrl + '/v2/hubspot/donor-deals', {
        method: 'POST',
        body: JSON.stringify({
          donor: {
            name: scholarship.donor?.name,
            email: scholarship.donor?.email
          },
          scholarship: {
            name: scholarship.name,
            amount: scholarship.awardAmountTo
          }
        }),
        ...this.getHeaders()
      });
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }

    return true;
  }

  async createBroadcast(broadcastParameters: BroadcastCreateParametersType, isPreview?: boolean) {
    const res = await this.fetch(`${this.baseUrl}/v2/broadcasts${isPreview ? '/preview' : ''}`, {
      method: 'POST',
      body: JSON.stringify({
        broadcastParameters: broadcastParameters
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'creating broadcast');
  }

  async getBroadcasts(): Promise<BroadcastModelShape[]> {
    const res = await this.fetch(`${this.baseUrl}/v2/broadcasts`, {
      ...this.getHeaders()
    });

    return handleResult(res, 'getting broadcast');
  }

  async setPassword({ email, token, password }: { email: string; token: string; password?: string }) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/password/${email}`, {
      method: 'PUT',
      body: JSON.stringify({ token, password }),
      ...this.getHeaders()
    });

    return handleResult(res, 'updating user');
  }

  async createResetPasswordUrl({ email }) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${email}/reset-password-url`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'getting reset password URL');
  }

  async sendResetPasswordUrl({ email }) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${email}/send-reset-password-url`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'sending reset password URL');
  }

  async sendSignupUrl(userId) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${userId}/send-signup-url`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'sending welcome email');
  }

  async makeCounselor(userId) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/counselor/${userId}`, {
      method: 'PUT',
      ...this.getHeaders()
    });

    return handleResult(res, 'turning user into counselor');
  }

  async getSiblingNonVerifiedCounselors(id: number) {
    const res = await this.fetch(addQuery(this.baseUrl + '/v2/users/sibling-counselors/' + id, {}), this.getHeaders());

    return handleResult(res, 'getting users by type');
  }

  /**
   * still somehow used even by backend code!!! wtf
   * @deprecated
   */
  async getUsers(type: string, filter?: string, fields?: string[]) {
    // TODO:IMP: Currently limits most of requests to 50 records. Has to be reworked, so we wont have to consider this every time
    const res = await this.fetch(
      addQuery(this.baseUrl + '/v1/users/type/' + type, {
        filter,
        fields: fields && fields?.length ? fields.join(',') : null
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting users by type');
  }

  async getUserListing(type: string, filter: UserListingFilterType, page: number = 1, pageSize: number = 50) {
    const res = await this.fetch(
      addQuery(this.baseUrl + '/v2/users/listing/' + type, {
        filter: JSON.stringify(filter),
        page,
        pageSize
      }),
      this.getHeaders()
    );

    return handleResult(res, 'getting users listing by type');
  }

  async getUsersOfDonor(donorId) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/of-donor/${donorId}`, this.getHeaders());

    return handleResult(res, 'getting users of donor');
  }

  async getUser(id) {
    const res = await this.fetch(this.baseUrl + '/v1/users/' + id, this.getHeaders());

    return handleResult(res, 'getting user');
  }

  // This endpoint eventually should replace the one above (getUser), but since its too breaking change, this transition will be happening slowly one by one
  async getUserProfile(id): Promise<UserProfile> {
    const res = await this.fetch(this.baseUrl + '/v1/users/profile/' + id, this.getHeaders());

    return handleResult(res, 'getting user profile');
  }

  async getStudentDetails(id) {
    const res = await this.fetch(this.baseUrl + '/v2/users/details/' + id, this.getHeaders());

    return handleResult(res, 'getting students details');
  }

  async getUserByName(name) {
    const res = await this.fetch(this.baseUrl + '/v1/users/name/' + name, this.getHeaders());

    const jsonResult = await handleResult(res, 'getting user');

    // we're using a 200 status code because there seems to have been
    // an old chrome bug where non-200 cors-loaded responses are reported as
    // cors errors. this call is used during signup to determine whether to
    // log in or sign up and we don't want that to be a cors error.

    // it's, however, not certain this fixes it.
    // https://bugs.chromium.org/p/chromium/issues/detail?id=269192
    if (jsonResult.result === 'not-found') {
      throw new ServerError(jsonResult);
    }

    return jsonResult;
  }

  /**
   * @returns An expired JWT.
   */
  async getExpiredJwt() {
    const res = await this.fetch(this.baseUrl + '/v1/expired-jwt');

    return handleResult(res, 'getting expired JWT').then(responce => responce.jwt);
  }

  async getAutocomplete(table, query) {
    const res = await this.fetch(
      this.baseUrl + `/v2/autocomplete/${table}/${encodeURIComponent(query)}`,
      this.getHeaders()
    );

    return handleResult(res, 'autocompleting schools');
  }

  /**
   * Retrieves checklists for specified user(college-ready checklists)
   * @param {number} userID
   */
  async getUserChecklists(userID): Promise<ChecklistResponseShape[]> {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${userID}/checklists`, this.getHeaders());
    return handleResult(res, 'retrieving checklists');
  }

  /**
   * Updates checklist at backend
   * @param {number} userID
   * @param {string} listType
   * @param {object[]} items
   */
  async updateUserChecklist(userID, listID, items) {
    const res = await this.fetch(`${this.baseUrl}/v1/users/${userID}/checklists/${listID}`, {
      method: 'POST',
      body: JSON.stringify({
        data: items
      }),
      ...this.getHeaders()
    });

    return handleResult(res, 'saving checklist');
  }

  async validateJwt(jwt) {
    const res = await this.fetch(this.baseUrl + '/v1/validate-jwt', {
      method: 'POST',
      body: JSON.stringify({
        jwt
      }),
      ...this.getHeaders()
    });

    if (res.status === 200) {
      const json = await res.json();

      return { ...json, valid: true };
    } else if (res.status === 401) {
      const json = await res.json();

      return { ...json, valid: false };
    } else {
      return handleError(res, 'validating JWT');
    }
  }

  async confirmInvitation(verificationCode) {
    const res = await this.fetch(`${this.baseUrl}/v1/confirm-invitation/${verificationCode}`, {
      method: 'POST',
      ...this.getHeaders()
    });

    return handleResult(res, 'invitation validation');
  }

  async getParentsInformation() {
    const res = await this.fetch(`${this.baseUrl}/v1/parents`, this.getHeaders());
    return handleResult(res, 'retrieving parents');
  }

  async getPendingInvitations() {
    const existanceData = await this.fetch(this.baseUrl + '/v1/invitations/pending', {
      ...this.getHeaders()
    });

    return handleResult(existanceData, 'Retrieve pending invitations');
  }

  async getAllInvitations() {
    const existanceData = await this.fetch(this.baseUrl + '/v2/invitations/all', {
      ...this.getHeaders()
    });

    return handleResult(existanceData, 'Retrieve all invitations');
  }

  async createMailing(mailing: Partial<MailingModelShape>) {
    const mailingCreationResponse = await this.fetch(`${this.baseUrl}/v2/mailings/submit`, {
      method: 'POST',
      body: JSON.stringify({
        mailing: mailing
      }),
      ...this.getHeaders()
    });

    return handleResult(mailingCreationResponse, 'Creating mailing');
  }

  async submitPreviewMailing(html_variables: MailingTemplateVariablesType) {
    const mailingCreationResponse = await this.fetch(`${this.baseUrl}/v2/mailings/preview`, {
      method: 'POST',
      body: JSON.stringify({
        html_variables: html_variables
      }),
      ...this.getHeaders()
    });

    return handleResult(mailingCreationResponse, 'Creating preview mailing');
  }

  async getMailingListing() {
    const response = await this.fetch(this.baseUrl + '/v2/mailings', {
      ...this.getHeaders()
    });

    return handleResult(response, 'Retrieve mailings listing');
  }

  async getMailing(mailingID: number) {
    const response = await this.fetch(this.baseUrl + `/v2/mailings/${mailingID}`, {
      ...this.getHeaders()
    });

    return handleResult(response, 'Retrieve mailing by id');
  }

  async revalidateMailingAddress(
    address: MailingAddressType
  ): Promise<AddressVerificationType | LobAddressVerificationError> {
    const response = await this.fetch(`${this.baseUrl}/v2/mailings/validate`, {
      method: 'POST',
      body: JSON.stringify({
        address: address
      }),
      ...this.getHeaders()
    });

    return handleResult(response, 'Retrieve mailing validation result');
  }

  async getStandaloneApplicationsValidation(scholarshipID: number, fileID: number) {
    const validationResultResponse = await this.fetch(
      this.baseUrl + '/v2/applications/standalone-verification/' + scholarshipID + '/' + fileID,
      {
        ...this.getHeaders()
      }
    );

    return handleResult(validationResultResponse, 'Retrieve standalone validation result');
  }

  async submitStandaloneApplications(scholarshipID, standaloneApplicationParams) {
    const mailingCreationResponse = await this.fetch(
      `${this.baseUrl}/v2/applications/standalone-submit/${scholarshipID}`,
      {
        method: 'POST',
        body: JSON.stringify({
          params: standaloneApplicationParams
        }),
        ...this.getHeaders()
      }
    );

    return handleResult(mailingCreationResponse, 'Creating mailing');
  }

  getHeaders() {
    return {
      headers: {
        Authorization: this.jwt || '',
        ...getStaticHeaders()
      }
    };
  }
  getHeadersFormData() {
    return {
      headers: {
        Authorization: this.jwt || ''
        // ...getStaticHeaders()
      }
    };
  }
}

function addQuery(url, params) {
  const query = Object.keys(params)
    .map(k => (params[k] != null ? encodeURIComponent(k) + '=' + encodeURIComponent(params[k]) : null))
    .filter(q => !!q)
    .join('&');

  return query ? url + (url.includes('?') ? '&' : '?') + query : url;
}

// "static" as in "same on every request"
export function getStaticHeaders() {
  return {
    'Content-Type': 'application/json'
  };
}

export function wrapToAddReferer(fetch) {
  return (url, init) => {
    // there seem to be browsers not sending the origin
    // (specifically chrome 57 on win 10)
    if (typeof describe === 'undefined' && typeof window !== 'undefined') {
      const o = getOrigin();

      if (o) {
        url = addQuery(url, { o });
      }
    }

    return fetch(url, init);
  };
}

const stdPrefix = 'https://';
const stdOrigin = 'scholarsapp.com';

let originCache = null;

function getOrigin() {
  if (!originCache) {
    originCache = compressOrigin(window.location.origin);
  }

  return originCache;
}

function compressOrigin(origin) {
  if (origin.endsWith('.' + stdOrigin)) {
    origin = origin.substr(0, origin.length - stdOrigin.length);
  }

  if (origin.startsWith(stdPrefix)) {
    origin = origin.substr(stdPrefix.length);
  }

  if (origin === 'apply.') {
    origin = null;
  }

  return origin;
}
