import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiButtonEmpty,
  EuiForm,
  htmlIdGenerator
} from '@elastic/eui';
import TextField from '../formElements/simpleFields/TextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UserClient from '@sharedClients/UserClient';

const schema = yup.object().shape({
  email: yup.string().email().required()
});

const PasswordResetModal = ({
  closeModal,
  userClient,
  addToast
}: {
  closeModal: () => void;
  userClient: UserClient;
  addToast: (toast: any) => void;
}) => {
  const formMethods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    setIsLoading(true);

    try {
      await userClient.sendResetPasswordUrl(data);

      addToast({
        id: htmlIdGenerator()(),
        color: 'success',
        title: 'The link is sent!'
      });

      closeModal();
    } catch (e) {
      addToast({
        id: htmlIdGenerator()(),
        color: 'danger',
        title: 'Something went wrong!'
      });

      setIsLoading(false);
    }
  };

  return (
    <EuiModal onClose={() => closeModal()} style={{ minWidth: 400 }}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Reset Password</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <FormProvider {...formMethods}>
          <EuiForm component="form" className={'PasswordResetForm'} onSubmit={e => e.preventDefault()}>
            <EuiFlexGroup direction="column" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="column" gutterSize="m">
                  <EuiFlexItem>
                    <TextField
                      fieldName={'email'}
                      fieldProps={{ placeholder: 'Your email address', fullWidth: true }}
                      rowProps={{ fullWidth: true }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>
        </FormProvider>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton isLoading={isLoading} onClick={formMethods.handleSubmit(onSubmit)} fill>
          Send a Password Reset Link
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default PasswordResetModal;
