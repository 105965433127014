import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFormFieldset } from '@elastic/eui';
import { concat, flattenDeep, get, isArray, isObject, toPath } from 'lodash';
import FormNodeBuilder from '../FormNodeBuilder';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import AddNewNodeModal from '../SectionBuilder/AddNewNodeModal';
import {
  ApplicationNodeAddressType,
  ApplicationNodeListType,
  ApplicationSectionType,
  APPLICATION_NODE_TYPE
} from '@sharedComponents/schemas/FormNodesSchema';
import { useFormNodeAdding, useFormNodeEdit, useFormNodeRemoval } from '../hooks/useFormNodeModifiers';
import { FormStateInternals, getNodeType } from '@sharedComponents/interfaces/Forms.interface';

type SupportedNodeType = (ApplicationSectionType | ApplicationNodeAddressType | ApplicationNodeListType) &
  FormStateInternals;
/**
 * represents builder for a node which has any kind of children
 * supports sections and address containers, list nodes
 */
function ParentalNodeBuilder({
  parentalNode
}: {
  // actually ApplicationNodeAddressType here is a bullshit to support legacy and may be dropped
  parentalNode: SupportedNodeType;
}) {
  const createModal = useModalCreate();
  const removeNodeByPath = useFormNodeRemoval();
  const editNodeByPath = useFormNodeEdit();
  const addFormNodeByPath = useFormNodeAdding();

  let parsedColumn = ParentalNodeBuilder.getDescendants(parentalNode);
  let [firstColumn, secondColumn] = parsedColumn.columns;
  let [firstColumnAddress, secondColumnAddress] = parsedColumn.columnPaths;

  // some legacy forms may have arrays here and we temporaly solve it here
  // ? shall we?
  firstColumn = flattenDeep(firstColumn);

  if (secondColumn?.length) {
    secondColumn = flattenDeep(secondColumn);
  }

  const addNewNodeHandler = (columnNumber: 1 | 2) => {
    createModal(({ closeModal }) => {
      const onModalSubmitHandler = (nodeDefaults?) => {
        if (nodeDefaults) {
          addFormNodeByPath(nodeDefaults, columnNumber === 1 ? firstColumnAddress : secondColumnAddress);
        }

        closeModal();
      };

      return <AddNewNodeModal onModalSubmit={onModalSubmitHandler} />;
    });
  };

  const ctaTitle = parentalNode.type === APPLICATION_NODE_TYPE.TYPE_ADDRESS ? 'Add new address field' : 'Add new field';
  return (
    <EuiFormFieldset
      style={{
        border: '1px solid #e9edf3',
        borderRadius: '15px'
      }}
      legend={{
        children: (
          <EuiFlexGroup direction="row" gutterSize="xs" alignItems="center" responsive={false}>
            <EuiFlexItem grow={false}>
              {ParentalNodeBuilder.getNodeName(parentalNode)}: {parentalNode.title || 'Unnamed'}
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType="documentEdit"
                title="Edit field"
                onClick={() => editNodeByPath(parentalNode.internalPath)}
                iconSize="s"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                iconType="cross"
                color="danger"
                title="Delete field"
                onClick={() => removeNodeByPath(parentalNode.internalPath)}
                iconSize="s"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }}
    >
      <EuiFlexGroup direction="row" responsive={false}>
        <EuiFlexItem>
          <EuiFlexGroup gutterSize="s" direction="column">
            {firstColumn.map((field, index) => (
              <EuiFlexItem key={`formnode_${index}`} grow={false}>
                <FormNodeBuilder node={field} />
              </EuiFlexItem>
            ))}
            <EuiFlexItem>
              <EuiButton size="s" color="primary" onClick={() => addNewNodeHandler(1)}>
                {ctaTitle}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {(secondColumn?.length || !(parentalNode as ApplicationSectionType).wide) &&
        parentalNode.type === APPLICATION_NODE_TYPE.TYPE_SECTION ? (
          <>
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="s" direction="column">
                {secondColumn.map((field, index) => (
                  <EuiFlexItem key={`formnode_${index}`} grow={false}>
                    <FormNodeBuilder node={field} />
                  </EuiFlexItem>
                ))}
                <EuiFlexItem>
                  <EuiButton size="s" color="primary" onClick={() => addNewNodeHandler(2)}>
                    {ctaTitle}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </>
        ) : null}
      </EuiFlexGroup>
    </EuiFormFieldset>
  );
}

ParentalNodeBuilder.getDescendants = (formNode: SupportedNodeType) => {
  /**
   * sections has array of two arrays defining real columns
   * for address group, fields represented in children array
   */
  const columns =
    formNode.type === APPLICATION_NODE_TYPE.TYPE_ADDRESS ? get(formNode, 'children', []) : get(formNode, 'columns', []);
  const sectionPath = toPath(formNode.internalPath);

  let firstColumn,
    secondColumn = [];
  let firstColumnAddress, secondColumnAddress;
  if (formNode.type === APPLICATION_NODE_TYPE.TYPE_ADDRESS) {
    firstColumn = columns;
    firstColumnAddress = concat([...sectionPath], 'children');
  } else if (columns.length === 2 && isArray(columns[0]) && isArray(columns[1])) {
    // default case
    [firstColumn, secondColumn] = (formNode as ApplicationSectionType).columns;
    firstColumnAddress = concat([...sectionPath], 'columns', '0');
    secondColumnAddress = concat([...sectionPath], 'columns', '1');
  } else if (columns.length === 1 && isArray(columns[0])) {
    firstColumn = columns[0];
    firstColumnAddress = concat([...sectionPath], 'columns', '0');

    // second column is empty
    secondColumnAddress = concat([...sectionPath], 'columns', '1');
  } else if (columns.every(node => isObject(node))) {
    firstColumn = columns;
    firstColumnAddress = concat([...sectionPath], 'columns');
  }

  return {
    columns: [firstColumn, secondColumn],
    columnPaths: [firstColumnAddress, secondColumnAddress]
  };
};

ParentalNodeBuilder.getNodeName = (formNode: SupportedNodeType) => {
  const nodeType = getNodeType(formNode);
  switch (nodeType) {
    case APPLICATION_NODE_TYPE.TYPE_SECTION: {
      return 'Section';
    }

    case APPLICATION_NODE_TYPE.TYPE_LIST: {
      return 'Fields List';
    }

    case APPLICATION_NODE_TYPE.TYPE_ADDRESS: {
      return 'Address List';
    }
  }
};

export default ParentalNodeBuilder;
