import { useMutation, useQuery, UseQueryOptions } from 'react-query';

import { FormsModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'form';

// to be renamed since its conflicts with useForm from hook-form
function useForm(formID: number, options: UseQueryOptions<FormsModelShape, Error, FormsModelShape> = {}) {
  const scholarshipClient = useScholarshipClient();

  return useQuery<FormsModelShape, Error>([CURRENT_QUERY_KEY, formID], () => scholarshipClient.getFormEntry(formID), {
    staleTime: 1000 * 60 * 5,
    ...options
  });
}

const useUpsertForm = () => {
  const scholarshipClient = useScholarshipClient();

  return {
    ...useMutation<
      FormsModelShape, // returned result result by our api
      Error, // error type
      Partial<FormsModelShape>
      // parameters accepted by mutation
    >(updatedFormShape => scholarshipClient.upsertForm(updatedFormShape), {
      onSuccess: () => {}
    })
  };
};

const formQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useForm, useUpsertForm };
export default formQuery;
