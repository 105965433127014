import { useState, useEffect, useRef } from 'react';

export default function useDraft(key, setData) {
  const [draft, setDraft] = useState(null);

  const unsavedData = useRef();
  const draftKey = 'formDraft.' + (key || 'new');

  function restoreDraft() {
    setData(draft);
    setDraft(null);
    unsavedData.current = null;
  }

  function onUnload() {
    if (unsavedData.current) {
      localStorage.setItem(draftKey, JSON.stringify(unsavedData.current));
    }
  }

  function onEdited(data) {
    unsavedData.current = data;
  }

  function onPersist() {
    unsavedData.current = null;
    localStorage.removeItem(draftKey);
    setDraft(null);
  }

  useEffect(
    () => {
      const json = localStorage.getItem(draftKey);

      if (json) {
        setDraft(JSON.parse(json));
      }

      window.onbeforeunload = onUnload;

      return () => {
        onUnload();
        window.onbeforeunload = null;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draftKey]
  );

  return {
    restoreDraft,
    onEdited,
    onPersist,
    hasDraft: draft != null
  };
}
