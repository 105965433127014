import { useMutation } from 'react-query';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'requestFormChanges';

// mutation to upsert
const useMutateRequestFormChanges = () => {
  const scholarshipClient = useScholarshipClient();

  return {
    ...useMutation<
      any, // returned result result by our api
      Error, // error type
      {
        formId: number;
        changes: string;
        onlySave?: boolean;
      }
      // parameters accepted by mutation
    >(({ formId, changes, onlySave }) => scholarshipClient.requestFormChanges(formId, changes, onlySave))
  };
};

const requestFormChangesQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useMutateRequestFormChanges };
export default requestFormChangesQuery;
