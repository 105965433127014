import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiTitle, htmlIdGenerator } from '@elastic/eui';
import LoadingPrompt from '@sharedComponents/common/LoadingPrompt';
import useBeforeLeave from '@sharedComponents/hooks/useBeforeLeave';
import useToast from '@sharedComponents/hooks/useToast';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';

import ControlBar from './FormBuilder/ControlBar';
import useFormPreview from './FormBuilder/hooks/useFormPreview';
import PageBuilder from './FormBuilder/PageBuilder';
import formQuery from './FormBuilder/queries/formQuery';
import formStateIsDirtySelector from './FormBuilder/selectors/formStateIsDirtySelector';
import formStateSelector from './FormBuilder/selectors/formStateSelector';

/** Beta workaround for formbuilder to visualize application form entry setup */
const FormBuilder = ({ formID }) => {
  const [formState, setFormState] = useRecoilState(formStateSelector);
  const { formPreview, previewWindow } = useFormPreview();
  const { addToast } = useToast();
  const [originalFormModel, setOriginalFormModel] = useState({});

  const [isNotAbleToOpenForm, setIsNotAbleToOpenForm] = useState(false);

  const {
    data: form,
    isLoading,
    isError
  } = formQuery.useForm(formID, {
    onSuccess: formModel => {
      // we dont support forms with standard_fields
      if (formModel.standard_fields?.length) {
        setIsNotAbleToOpenForm(true);
        return;
      }

      // updating form state atom after query execution
      setFormState(formModel as any /** ? */);

      setOriginalFormModel(formModel); // this could be optimized, since its only for a legacy preview
    }
  });

  const [isDirty] = useRecoilState(formStateIsDirtySelector);

  useBeforeLeave({
    when: isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?'
  });

  if (isNotAbleToOpenForm) {
    return (
      <LoadingPrompt
        isLoading={false}
        isError={true}
        errorMessage={'This form contains some legacy parts[standard_fields] which are not supported.'}
      >
        <></>
      </LoadingPrompt>
    );
  }

  // so, we're having form data. Lets analyze this one and build into UI?
  // analyzing existing data to display visually

  // ! each form contains standardFields array which is handling something weird. Need to investigate
  // also, this model here returns data and standartForm which are seems the same. Find the difference and drop out standartForm

  const handlePreviewClick = async () => {
    addToast({
      id: htmlIdGenerator()(),
      color: 'success',
      title: previewWindow ? 'Preview refreshed!' : 'Preview opened'
    });

    await formPreview({ ...originalFormModel, data: formState.pages });
  };

  // at this point, formState is actually a head node with pages
  return (
    <PageContentWrapper>
      <LoadingPrompt isLoading={isLoading} isError={isError}>
        <EuiFlexGroup direction="column" gutterSize="l" responsive={false}>
          <EuiFlexItem>
            <EuiFlexGroup direction="row" responsive={false} gutterSize="xl">
              <EuiFlexItem>
                <EuiTitle>
                  <h2>{form?.name}</h2>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton size="s" onClick={handlePreviewClick} color={previewWindow ? 'accent' : 'primary'}>
                  {previewWindow ? 'Update preview window' : 'Open preview window'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <PageBuilder />
          </EuiFlexItem>
          <EuiFlexItem>
            <ControlBar formName={form?.name} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </LoadingPrompt>
    </PageContentWrapper>
  );
};

export default FormBuilder;
