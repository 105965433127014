import React, { createContext, useContext } from 'react';

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui';
import { AWARD_STATUS, getAwardStatusName } from '@sharedComponents/interfaces/Applications.interface';

import { ApplicationGridDataEntry } from '../config';
import { DATA_ACTIONS } from './config';

const DataContext = createContext<{
  data: Array<ApplicationGridDataEntry>; // ? consider having Map here for performance needs and access by id?
  dispatch: React.Dispatch<{
    action: DATA_ACTIONS;
    context: any;
    value: any;
  }>;
}>({
  data: [],
  dispatch: () => {}
});

function RenderCellValue({ rowIndex, columnId }) {
  const { data } = useContext(DataContext);

  if (!data.hasOwnProperty(rowIndex)) {
    return '';
  }

  // ? enums for column ids
  if (columnId === 'reviewers') {
    return data[rowIndex][columnId]
      .map(reviewer =>
        reviewer?.data?.name
          ? `${reviewer?.data?.name}${reviewer?.data?.lastName ? ' ' + reviewer?.data?.lastName : ''}`
          : 'Deleted reviewer'
      )
      .join(', ');
  } else if (columnId.startsWith('round_')) {
    const roundsStats = data[rowIndex]['roundsStats'];
    const roundID = columnId.split('_')[1];
    if (!roundsStats || !roundsStats[roundID]) {
      return '';
    }

    const roundStats = roundsStats[roundID];
    return `${roundStats.score} ${roundStats.isConverted ? ' (Tally converted to %)' : ''}`;
  } else if (columnId === 'name') {
    const renderAwardStatus = (award_status: AWARD_STATUS) => {
      switch (award_status) {
        case AWARD_STATUS.AWARD_NOTIFIED:
        case AWARD_STATUS.AWARD_ACCEPTED:
        case AWARD_STATUS.AWARD_REJECTED:
        case AWARD_STATUS.REJECT_NOTIFIED: {
          return <EuiBadge>{getAwardStatusName(award_status)}</EuiBadge>;
        }
      }

      return '';
    };

    return (
      <EuiFlexGroup direction="row">
        <EuiFlexItem>
          <EuiLink href={`/applications/${data[rowIndex].id}`} target="_blank">
            {data[rowIndex][columnId]}
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {data[rowIndex].award_status ? renderAwardStatus(data[rowIndex].award_status as any) : ''}
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return data[rowIndex][columnId] || '';
}

export { RenderCellValue, DataContext };
