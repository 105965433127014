/**
 * Since our field names may represent deepnested structures, its often needed to search deeper into object in order to get final value of field path
 * @param path dotted path, f.e. 'data.startDate'
 * @param object obj to search in, f.e. {data:{startDate: 'string'}}
 */
export function getValueByDottedPath<T>(path, object): T | null {
  return path.split('.').reduce((dataPart, pathPart) => {
    return dataPart && dataPart[pathPart] ? dataPart[pathPart] : null;
  }, object);
}
