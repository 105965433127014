import React from 'react';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { EuiButton, EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter } from '@elastic/eui';
import RichTextEditor from '@sharedComponents/common/RichTextEditor/RichTextEditor';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';

const RichTextPreviewModal = ({
  closeModal,
  value,
  modalTitle
}: {
  closeModal: () => void;
  value: RawDraftContentState;
  modalTitle?: string;
}) => {
  return (
    <EuiModal onClose={closeModal} maxWidth={992}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{modalTitle || 'Preview'}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <RichTextEditor value={value} config={{ mode: 'view', maxHeight: 'none' }} />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={closeModal}>Cancel</EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default function RichTextPreview({ value, modalTitle }: { value: RawDraftContentState; modalTitle?: string }) {
  const createModal = useModalCreate();

  const showPreviewModal = () => {
    createModal(({ closeModal }) => (
      <RichTextPreviewModal closeModal={closeModal} value={value} modalTitle={modalTitle} />
    ));
  };

  return (
    <div className="RichTextPreview">
      <EuiButton size="s" onClick={showPreviewModal} iconType="inspect">
        Preview
      </EuiButton>
    </div>
  );
}
