import React, { useState } from 'react';

import { EuiConfirmModal, EuiConfirmModalProps } from '@elastic/eui';

/**
 * Use context modal approach instead please
 * @search for const { useModal } = useContext(ModalContext);
 * @deprecated
 */
export default function useModal({
  title = '',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  modalProps = {},
  onConfirm,
  content = null
}: {
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  modalProps?: Omit<EuiConfirmModalProps, 'onCancel'>;
  onConfirm: () => void;
  content?: React.ReactNode;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  let modal;

  if (isModalVisible) {
    modal = (
      <EuiConfirmModal
        title={title}
        onCancel={closeModal}
        onConfirm={() => {
          closeModal();
          onConfirm();
        }}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        defaultFocusedButton="confirm"
        {...modalProps}
      >
        {content}
      </EuiConfirmModal>
    );
  }

  return [modal, showModal, closeModal];
}
