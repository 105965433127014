// ! TO BE DEPRECATED
import ethnicities from '@sharedContract/ethnicities';
import areas from '@sharedContract/areas';

const signupForms = {
  recommender: [
    { field: 'phone', type: 'phone', required: true },
    { field: 'occupation', required: true },
    {
      type: 'checkbox',
      field: 'atSchool',
      label: "I'm associated with a school"
    },
    {
      type: 'address',
      if: ['atSchool', true],
      children: [
        {
          field: 'school',
          label: 'High School',
          autocomplete: 'schools'
        },
        {
          field: 'college',
          autocomplete: 'colleges',
          help: 'Fill in either high school or college, as applicable.'
        }
      ]
    },
    {
      type: 'checkbox',
      field: 'notifyMe',
      label: 'Inform me of scholarships that are relevant to my students'
    },
    {
      field: 'state',
      label: 'Your state',
      type: 'state'
    }
  ],
  applicant: [
    { field: 'phone', type: 'phone', required: true },
    {
      type: 'address',
      label: 'Current address',
      path: 'address/residence',
      children: [
        'street',
        'city',
        { field: 'state', type: 'state', width: 14 },
        { field: 'zip', type: 'number', width: 5 }
      ]
    },
    {
      columns: [
        [
          {
            field: 'residency',
            label: 'Resident of (state)',
            type: 'state'
          },
          {
            field: 'gender',
            options: 'Female, Male, Prefer not to say'
          },
          {
            field: 'ethnicity',
            label: 'Group identities',
            help: 'Many scholarships are only available to specific groups, such as religions or ethnicities.',
            options: ethnicities.join(', '),
            multiple: true
          }
        ],
        [
          {
            type: 'checkbox',
            field: 'lowIncome',
            label: 'I have financial need'
          },
          {
            type: 'checkbox',
            field: 'communityService',
            label: 'I have performed community service'
          },
          {
            type: 'checkbox',
            field: 'firstGen',
            path: 'college',
            label: "I'm among the first generation in my family to attend college"
          }
        ]
      ]
    },
    {
      columns: [
        [
          {
            field: 'academicYear',
            width: 10,
            options:
              'High school freshman,' +
              'High school sophomore,' +
              'High school junior, High school senior, Undergraduate freshman,' +
              'Undergraduate sophomore, Undergraduate junior, Undergraduate senior,' +
              'Graduate student, PhD student' +
              '6th Grade, 7th Grade, 8th Grade'
          },
          {
            field: 'gpa',
            type: 'number',
            width: 3,
            label: 'GPA'
          },
          {
            path: 'school',
            children: [
              {
                type: 'address',
                label: 'High school',
                children: [
                  {
                    field: 'school',
                    autocomplete: 'schools'
                  },
                  [
                    { field: 'city', isDetailsTo: 'school' },
                    {
                      field: 'state',
                      type: 'state',
                      isDetailsTo: 'school',
                      width: 14
                    }
                  ]
                ]
              }
            ]
          }
        ],
        [
          {
            field: 'area',
            label: 'Field',
            options: areas,
            multiple: true,
            help: 'Area(s) of your studies, work and/or hobbies.'
          },
          {
            field: 'collegeType',
            label: 'Type(s) of college you are considering',
            options: 'Trade school, 2-year, 4-year',
            multiple: true
          },
          {
            field: 'type',
            path: 'college',
            label: 'Study rate',
            options: 'Full time, Half time (or more), Less than half time'
          },
          {
            type: 'list',
            label: 'College you are applying to',
            path: 'college/applyingTo',
            children: [
              {
                type: 'address',
                children: [
                  {
                    field: 'college',
                    autocomplete: 'colleges'
                  },
                  [
                    { field: 'city', isDetailsTo: 'college' },
                    {
                      field: 'state',
                      type: 'state',
                      width: 14,
                      isDetailsTo: 'college'
                    }
                  ]
                ]
              }
            ]
          }
        ]
      ]
    }
  ],
  donor: [
    { field: 'name', label: 'First name', required: true },
    { field: 'lastName', required: true },
    { field: 'phone', type: 'phone', required: true },
    { field: 'isPremium', label: 'Premium?', type: 'checkbox' }
  ],
  reviewer: [
    { field: 'name', label: 'First name', required: true },
    { field: 'lastName', required: true },
    { field: 'phone', type: 'phone', required: true }
  ],
  counselor: [
    { field: 'isVerified', label: 'Verified?', type: 'checkbox' },
    {
      field: 'school',
      label: 'School',
      autocomplete: 'schools',
      required: true
    },
    {
      field: 'state',
      type: 'state'
    },
    { field: 'phone', type: 'phone', required: true },
    {
      type: 'number',
      field: 'seniorCount',
      label: 'Number of seniors in your school'
    },
    {
      type: 'checkbox',
      field: 'postHigh',
      label: "I'm a post-high / college counselor"
    },
    {
      type: 'checkbox',
      field: 'isPremium',
      label: 'Premium Account'
    }
  ]
};

export default signupForms;
