import { isString } from '../../model/cast';
import FormFieldAutocomplete from './FormFieldAutocomplete';
import FormFieldMultipleSelect from './FormFieldMultipleSelect';
import FormFieldNumber from './FormFieldNumber';
import FormFieldUpload from './FormFieldUpload';
import FormFieldSelect from './FormFieldSelect';
import FormFieldState from './FormFieldState';
import FormFieldText from './FormFieldText';
import FormFieldTextArea from './FormFieldTextArea';
import FormFieldRichText from './FormFieldRichText';
import FormFieldNumberRange from './FormFieldNumberRange';
import { FormNodeProps } from '../../apply/FormNodeProps';

/** This field is inside a FormFieldLabel */
export default function FormFieldInput(props: FormNodeProps) {
  const { node } = props;

  if (node.type === 'textarea') {
    return <FormFieldTextArea {...props} />;
  } else if (node.type === 'richText') {
    return <FormFieldRichText {...props} />;
  } else if (node.type === 'pdf' || node.type === 'image' || node.type === 'upload') {
    return <FormFieldUpload {...props} />;
  } else if (node.type === 'number') {
    return <FormFieldNumber {...props} />;
  } else if (node.type === 'numberRange') {
    return <FormFieldNumberRange {...props} />;
  } else if (node.options) {
    if (node.isMultipleSelect()) {
      return <FormFieldMultipleSelect {...props} />;
    } else {
      return <FormFieldSelect {...props} />;
    }
  } else if (node.type === 'state') {
    return <FormFieldState {...props} />;
  } else if (isString(node.autocomplete)) {
    return <FormFieldAutocomplete {...props} onFieldChange={props.onFieldChange as any} />;
  } else {
    return <FormFieldText {...props} />;
  }
}
