import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiSpacer } from '@elastic/eui';
import CheckboxField from '@sharedComponents/formElements/simpleFields/CheckboxField';
import ComboboxField from '@sharedComponents/formElements/simpleFields/ComboboxField';
import NumberField from '@sharedComponents/formElements/simpleFields/NumberField';
import academic_years from '@sharedComponents/models/enumValues/academic_years';
import athletics from '@sharedComponents/models/enumValues/athletics';
import miscellaneous from '@sharedComponents/models/enumValues/miscellaneous';
import genders from '@sharedComponents/models/enumValues/genders';
import religious from '@sharedComponents/models/enumValues/religious';
import selfIdentification from '@sharedComponents/models/enumValues/selfIdentification';
import veteran_status from '@sharedComponents/models/enumValues/veteran_status';
import ethnicities from '@sharedComponents/models/enumValues/ethnicities';

import AbstractEntryStep from './AbstractEntryStep';

const EntryStepEligibilitySecond: AbstractEntryStep = {
  validationSchema: yup.object({
    data: yup
      .object({
        genders: yup.array(yup.string().oneOf(genders)).nullable(),
        ethnicities: yup.array(yup.string().oneOf(ethnicities)).nullable(),
        religious: yup.array(yup.string().oneOf(religious)),
        selfIdentification: yup.array(yup.string().oneOf(selfIdentification)),
        veteranStatus: yup.array(yup.string().oneOf(veteran_status)),
        athletics: yup.array(yup.string().oneOf(athletics)),
        miscellaneous: yup.array(yup.string().oneOf(miscellaneous)).nullable(),
        academicYears: yup.array(yup.string().oneOf(academic_years)),
        gpaFrom: yup.number().label('GPA').min(0).max(5),
        gpaTo: yup.number().label('GPA').min(0).max(5),
        lowIncome: yup.boolean().optional(),
        communityService: yup.boolean().optional(),
        firstGen: yup.boolean().optional()
      })
      .optional()
  }),

  render: function (rowProps) {
    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiTitle>
            <h3>Eligibility</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="xl">
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.genders'}
                    options={genders}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: 'Select the gender of a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Gender', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.ethnicities'}
                    options={ethnicities}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: 'Select the ethnicity or nationality of a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Ethnicity', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.religious'}
                    options={religious}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title:
                        'Select the religious or spiritual identity of a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Religious / Spiritual Identity', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.selfIdentification'}
                    options={selfIdentification}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: 'Select the disability of a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Self-Identification of Disability:', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.veteranStatus'}
                    options={veteran_status}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: 'Select the veteran status or affiliation of a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Veteran Status/Affiliation:', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.athletics'}
                    options={athletics}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: 'Select the sport played by a student who can apply for this scholarship.'
                    }}
                    rowProps={{ label: 'Athletics:', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <ComboboxField
                    fieldName={'data.miscellaneous'}
                    options={miscellaneous}
                    fieldProps={{
                      fullWidth: true,
                      placeholder: 'Select one or more',
                      title: ''
                    }}
                    rowProps={{ label: 'Other:', ...rowProps }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false}>
                  <ComboboxField
                    fieldName={'data.academicYears'}
                    options={academic_years}
                    fieldProps={{ fullWidth: true, placeholder: 'Select one or more' }}
                    rowProps={{ label: 'Current Grade:', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup direction="row">
                    <EuiFlexItem grow={5}>
                      <NumberField
                        fieldName={'data.gpaFrom'}
                        rowProps={{ label: 'GPA', ...rowProps }}
                        fieldProps={{
                          fullWidth: false,
                          prepend: 'Between',
                          step: 'any'
                        }}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={2}>
                      <NumberField
                        fieldName={'data.gpaTo'}
                        fieldProps={{ prepend: 'and', fullWidth: true, step: 'any' }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup direction="row">
                    <EuiFlexItem grow={true}></EuiFlexItem>
                    <EuiFlexItem grow={2}>
                      <CheckboxField
                        fieldName="data.lowIncome"
                        fieldProps={{ label: 'Low income' }}
                        rowProps={{
                          helpText:
                            "Click the checkbox if a student's family is required to have low income, to apply for this scholarship."
                        }}
                      />
                      <EuiSpacer size="s" />
                      <CheckboxField
                        fieldName="data.communityService"
                        fieldProps={{ label: 'Community service' }}
                        rowProps={{
                          helpText:
                            'Click the checkbox if a student is required to have participated in community service, to apply for this scholarship.'
                        }}
                      />
                      <EuiSpacer size="s" />
                      <CheckboxField
                        fieldName="data.firstGen"
                        fieldProps={{ label: 'First person in family to attend college' }}
                        rowProps={{
                          helpText:
                            'Click the checkbox if a student is required to be the first member of their family to attend college, to apply for this scholarship.'
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepEligibilitySecond;
