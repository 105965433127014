import './RecurrentAwardCard.scss';

import moment from 'moment';

import { FormNode } from '@donors/application/FormNode';
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { DEFAULT_DATE_FORMAT } from '@sharedComponents/config';
import { RecurringAwardModelShape } from '@sharedComponents/models';
import { useCurrentUserJWT, useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

import RecurrentAwardStatusBadge from './RecurrentAwardCard/RecurrentAwardStatusBadge';
import { cn } from '@bem-react/classname';

const blockClass = cn('RecurrentAwardCard');
function RecurrentAwardCard({ recurrentAward }: { recurrentAward: RecurringAwardModelShape }) {
  const user = useLoggedInUser();
  const webToken = useCurrentUserJWT();
  const applicationClient = useApplicationClient();

  const momentDate = moment(recurrentAward.disbursement_date);

  const deadlineString = `${momentDate.format(DEFAULT_DATE_FORMAT)} (${moment().to(momentDate)})`;

  return (
    <EuiCard className={blockClass()} title={''} description={''} hasBorder={true}>
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiText>
                <strong>Deadline:</strong> {deadlineString}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <RecurrentAwardStatusBadge recurrentAward={recurrentAward} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiText>
                <strong>Amount:</strong> ${recurrentAward.disbursement_amount.toLocaleString()}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <FormNode
            application={recurrentAward.submission}
            user={user}
            jwt={webToken || undefined}
            fullApplication={recurrentAward.submission}
            node={recurrentAward!.form!.data as any}
            onError={() => {}}
            applicationClient={applicationClient}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiCard>
  );
}

export default RecurrentAwardCard;
