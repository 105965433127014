import { getPath } from './getPath';

// todo figure out this usage
export default function FormFieldConditional(props) {
  const { node, application } = props;

  const negate = !!node.unless;

  const [field, value] = node.if || node.unless || [];

  if (typeof field == 'string' && field) {
    const parentPath = (node.parent && node.parent.getValuePath()) || [];

    const actualValue = getPath(application, parentPath.concat(field.split('/')));

    let isShown = value.length ? value.includes(actualValue) : actualValue === value;

    if (negate) {
      isShown = !isShown;
    }

    return isShown ? props.children : null;
  } else {
    return props.children;
  }
}
