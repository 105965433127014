import React from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';

import CreateMailing from './Mailings/CreateMailing';
import PastMailings from './Mailings/PastMailings';
import ViewMailing from './Mailings/ViewMailing';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';

const Mailings = () => {
  const router = useRouter();
  return (
    <PageContentWrapper>
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem grow={1}>
          <PastMailings />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column">
            <EuiFlexItem grow={false}>
              <EuiSpacer size="xxl" />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton onClick={() => router.push('/mailings/create')}>Create New Mailing</EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

// exporting subroutes components to not expose them one by one
Mailings.Create = CreateMailing;
Mailings.View = ViewMailing;

export default Mailings;
