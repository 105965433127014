import React from 'react';
import * as yup from 'yup';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle, EuiLink } from '@elastic/eui';
import PasswordField from '@sharedComponents/formElements/simpleFields/PasswordField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import { capitalize, getPublicRoleName } from '@sharedComponents/utils/stringUtils';

import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';
import LoginRoleSelector from './LoginStartStep/LoginRoleSelector';
import { USER_TYPE } from '@sharedComponents/constants';

const rolesWithRegistration = [
  USER_TYPE.APPLICANT,
  USER_TYPE.COUNSELOR,
  USER_TYPE.RECOMMENDER,
  USER_TYPE.PARENT,
  USER_TYPE.PROFESSIONAL,
  USER_TYPE.DONOR
];

const loginStartStep: Step = {
  validationSchema: _userType =>
    yup.object().shape({
      email: yup.string().email().label('Email').required(),
      password: yup.string().label('Password').required(),
      code: yup.string().label('Code').optional()
    }),

  render(props) {
    const { userType, setUserType, required2FA } = props;

    return (
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" gutterSize="s" justifyContent="spaceBetween" responsive={false}>
            <EuiFlexItem grow={1}>
              <EuiTitle size="s">
                <h2>{`${capitalize(getPublicRoleName(userType))}s`}</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <LoginRoleSelector userType={userType} setUserType={setUserType} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText size="m">Sign in to your {getPublicRoleName(userType)} account, or create a new account.</EuiText>
          <EuiSpacer size={'m'} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {!required2FA ? (
            <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
              <EuiFlexItem>
                <TextField
                  fieldName={'email'}
                  fieldProps={{ placeholder: 'Your email address', fullWidth: true }}
                  rowProps={{ fullWidth: true }}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <PasswordField
                  fieldName={'password'}
                  fieldProps={{ placeholder: 'Password', type: 'dual', fullWidth: true }}
                  rowProps={{ fullWidth: true }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
              <EuiFlexItem>
                <TextField
                  fieldName={'code'}
                  fieldProps={{ placeholder: 'Secret code', fullWidth: true }}
                  rowProps={{ fullWidth: true }}
                />
                <EuiSpacer size={'xs'} />
                <EuiText size={'s'}>
                  Your account is protected with two-factor authentication.
                  <br />
                  We've sent you an email. Please enter the code below.
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
        {!required2FA ? (
          <EuiFlexItem style={{ alignSelf: 'flex-start' }}>
            <EuiLink onClick={props.showPasswordResetModal}>
              <EuiText size="s" textAlign={'right'}>
                Forgot your password?
              </EuiText>
            </EuiLink>
          </EuiFlexItem>
        ) : null}
        <EuiSpacer />
      </EuiFlexGroup>
    );
  },

  async getNextStep() {
    return SIGNUP_STEP.NEW_USER_REGISTRATION;
  },

  renderControls(props) {
    const { formMethods, completeLogin, userType, changeStep, loadingStatus, required2FA } = props;
    const loginHandler = formMethods.handleSubmit(
      async () => {
        loadingStatus.startLoading();

        try {
          formMethods.clearErrors();

          await completeLogin({
            email: formMethods.getValues('email'),
            password: formMethods.getValues('password'),
            code: formMethods.getValues('code')
          });
        } catch (e: any) {
          formMethods.setError(required2FA ? 'code' : 'password', {
            message: e.message
          });
        }

        loadingStatus.stopLoading();
      },
      async () => {
        // eslint-disable-next-line no-console
        console.error(formMethods.errors);
      }
    );

    const isCurrentRoleAbleToRegister = rolesWithRegistration.includes(userType);

    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiButton
            type="submit"
            color="primary"
            size="s"
            isLoading={loadingStatus.isLoading}
            onClick={loginHandler}
            fill
          >
            Sign in as {getPublicRoleName(userType)}
          </EuiButton>
        </EuiFlexItem>
        {isCurrentRoleAbleToRegister ? (
          <>
            <EuiFlexItem grow={false} style={{ textAlign: 'center' }}>
              <EuiText size="s">OR</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                isDisabled={loadingStatus.isLoading}
                onClick={async () => {
                  const nextStep = await this.getNextStep(props);
                  if (nextStep) {
                    changeStep(nextStep);
                  }
                }}
              >
                Create {capitalize(getPublicRoleName(userType))} Account
              </EuiButton>
            </EuiFlexItem>
          </>
        ) : (
          // Just making spacing to have Sign in button at top
          <>
            <EuiFlexItem grow={false}>
              <EuiSpacer />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiSpacer />
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
    );
  }
};

export default loginStartStep;
