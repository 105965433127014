import React, { useState } from 'react';
import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
  EuiLoadingSpinner,
  EuiLink
} from '@elastic/eui';
import './Banner.scss';
import { ScholarshipModelShape } from '@sharedComponents/models';

export default function Controls({
  scholarshipsMap,
  selectedScholarshipId,
  setSelectedScholarshipId,
  dateRangeFilter,
  dateRangeFilterOptionsMap,
  setDateRangeFilter,
  selectedScholarship,
  areScholarshipsLoading
}: {
  scholarshipsMap: { [key: number]: ScholarshipModelShape };
  selectedScholarshipId: number | null;
  setSelectedScholarshipId: (v) => void;
  dateRangeFilter: number;
  dateRangeFilterOptionsMap: { [key: string]: { label: string; value: number } };
  setDateRangeFilter: (v: number) => void;
  selectedScholarship: ScholarshipModelShape | null;
  areScholarshipsLoading: boolean;
}) {
  const [scholarshipFilterOpen, setScholarshipFilterOpen] = useState(false);
  const [dateRangeFilterOpen, setDateRangeFilterOpen] = useState(false);

  const updateSelectedScholarshipId = id => {
    setSelectedScholarshipId(id);
    setScholarshipFilterOpen(false);
  };

  const updateDateRangeFilterValue = v => {
    setDateRangeFilter(v);
    setDateRangeFilterOpen(false);
  };

  return (
    <EuiFlexGroup className="DonorHomepage__controls" alignItems={'center'} responsive={false}>
      <EuiFlexItem className="DonorHomepage__dropdownControls" grow={false}>
        <EuiFlexGroup alignItems={'center'} responsive={false} direction={'column'}>
          <EuiFlexItem className={'DonorHomepage__scholarshipName'} grow={false}>
            {!!selectedScholarship ? (
              <EuiPopover
                initialFocus={false}
                button={
                  <h1 onClick={() => setScholarshipFilterOpen(v => !v)}>
                    <span>{selectedScholarship.name}</span> <EuiIcon size={'s'} type={'arrowDown'} />
                  </h1>
                }
                isOpen={scholarshipFilterOpen}
                closePopover={() => setScholarshipFilterOpen(false)}
                panelPaddingSize="s"
                anchorPosition="downLeft"
              >
                <EuiListGroup flush>
                  {Object.values(scholarshipsMap!).map(scholarship => (
                    <EuiListGroupItem
                      key={scholarship.id}
                      onClick={() => updateSelectedScholarshipId(scholarship.id)}
                      label={`${scholarship.name}${scholarship.isDraft ? ' (Awaiting publication)' : ''}`}
                      isActive={scholarship.id === selectedScholarshipId}
                      isDisabled={scholarship.isDraft} // not allowing drafts to be selected
                      wrapText={true}
                    />
                  ))}
                </EuiListGroup>
              </EuiPopover>
            ) : areScholarshipsLoading ? (
              <EuiLoadingSpinner size="m" />
            ) : (
              <h1>Scholarship Provider Dashboard</h1>
            )}
          </EuiFlexItem>

          <EuiFlexItem className={'DonorHomepage__dateRangeFilter'} grow={false}>
            <EuiPopover
              initialFocus={false}
              button={
                <EuiButtonEmpty
                  onClick={() => setDateRangeFilterOpen(v => !v)}
                  iconType="arrowDown"
                  iconSide="right"
                  iconSize={'s'}
                >
                  {dateRangeFilterOptionsMap[dateRangeFilter].label}
                </EuiButtonEmpty>
              }
              isOpen={dateRangeFilterOpen}
              closePopover={() => setDateRangeFilterOpen(false)}
              panelPaddingSize="s"
              anchorPosition="downLeft"
            >
              <EuiListGroup flush>
                {Object.values(dateRangeFilterOptionsMap).map(option => (
                  <EuiListGroupItem
                    key={option.value}
                    onClick={() => updateDateRangeFilterValue(option.value)}
                    label={option.label}
                    isActive={option.value === dateRangeFilter}
                  />
                ))}
              </EuiListGroup>
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem className="DonorHomepage__newScholarshipLink" grow={false}>
        <EuiLink href={`/scholarship-self-listing/`}>Create new scholarship</EuiLink>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}
