import React from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  htmlIdGenerator,
  getDefaultEuiMarkdownParsingPlugins,
  getDefaultEuiMarkdownProcessingPlugins,
  getDefaultEuiMarkdownUiPlugins,
  EuiFieldText
} from '@elastic/eui';
import { AwardStepProps, AWARD_STEPS } from './config';
import './AwardStepEmailDrafting.scss';
import {
  getApplicantNameParser,
  ApplicantNameRenderer,
  getAwardAmountParser,
  AwardAmountRenderer,
  ApplicantNamePlugin,
  AwardAmountPlugin
} from '../../../utils/markdownPlugins';
import AbstractAwardStep from './AbstractAwardStep';

const AwardStepEmailDrafting: AbstractAwardStep = {
  headerTitle: 'Draft Award Email',
  actions(changeAwardingStep) {
    const actions: React.ReactNode[] = [];
    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.AWARD_AMOUNT_CONFIRMATION);
          }}
        >
          Back
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          onClick={() => {
            changeAwardingStep(AWARD_STEPS.EMAIL_PREVIEW);
          }}
          fill
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ selection, emailDraft, setEmailDraft, emailSubject, setEmailSubject }: AwardStepProps) {
    const parsingList = getDefaultEuiMarkdownParsingPlugins();
    const exampleApplicant = {
      name: selection[0]?.name || 'Full Name',
      awardAmount: selection[0]?.awardAmount || 1000
    };

    parsingList.push(getApplicantNameParser(exampleApplicant.name));
    parsingList.push(getAwardAmountParser(exampleApplicant.awardAmount));

    const processingList = getDefaultEuiMarkdownProcessingPlugins();

    // add the renderer for `applicantNamePlugin`
    processingList[1][1].components.applicantNamePlugin = ApplicantNameRenderer;
    processingList[1][1].components.awardAmountPlugin = AwardAmountRenderer;

    const uiPlugins = getDefaultEuiMarkdownUiPlugins();

    uiPlugins.push(ApplicantNamePlugin);
    uiPlugins.push(AwardAmountPlugin);

    return (
      <EuiFlexGroup className="emailDrafting" direction="column" gutterSize="s">
        <EuiFlexItem>
          Write an email that will be sent to all of the winning candidates. <br />
          Click on the the "Name" and "Award Amount" fields to automatically populate information specific to each
          candidate in the email.
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFieldText
            value={emailSubject}
            onChange={e => setEmailSubject(e.target.value)}
            placeholder="Email subject"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiMarkdownEditor
            className="emailDrafting-editor"
            aria-label="Email draft example"
            value={emailDraft}
            onChange={setEmailDraft}
            height={400}
            uiPlugins={uiPlugins}
            parsingPluginList={parsingList}
            processingPluginList={processingList}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default AwardStepEmailDrafting;
