import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import { EuiGlobalToastList } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

export const ToastContext = React.createContext<{ addToast: (toast: Toast) => void; removeToast: (id: any) => void }>({
  addToast: () => {},
  removeToast: () => {}
});

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    newToast => {
      setToasts(_toasts => [..._toasts, { ...newToast }]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    removedToast => {
      setToasts(_toasts => _toasts.filter(toast => toast.id !== removedToast.id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {createPortal(
        <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={2000} />,
        document.body
      )}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
