import moment from 'moment';
import { USER_TYPE } from '../constants';
import { UserModelShape, DraftScholarshipModelShape, ScholarshipModelShape } from '../models';
import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';
import { FORM_CHANGE_REQUEST_STATUSES } from '../interfaces/Scholarships.interface';

/**
 * ? get this from some config?
 * Building scholarship url based on draft status/step, user role
 */
export const scholarshipLink = (
  scholarship: Partial<ScholarshipModelShape | DraftScholarshipModelShape>,
  user?: UserModelShape,
  isEdit?: boolean
) => {
  // Drafts are only opened in EDIT mode
  if (scholarship.isDraft) {
    // Donors and counselors have own self-listing flow
    if (user?.type) {
      if (user.type === USER_TYPE.DONOR) {
        return `/scholarship-self-listing/${scholarship.id}`;
      } else if (user.type === USER_TYPE.COUNSELOR) {
        return `/manage-draft/${scholarship.id}`;
      }
    }

    const currentDraftStep = (scholarship as DraftScholarshipModelShape).isSelfListingDraft
      ? SCHOLARSHIP_ENTRY_STEPS.QA
      : scholarship.data?.draft_step;

    return `/scholarship-entry/${scholarship.id}/${currentDraftStep ? currentDraftStep + 1 : 0}`;
  }

  if (isEdit && user?.type) {
    if (user.type === USER_TYPE.COUNSELOR) {
      return `/manage-scholarship/${scholarship.id}`;
    }
  }

  // View mode by default
  // reviewers and donors are supposed to get into applications, instead of scholarship view
  return user && [USER_TYPE.REVIEWER, USER_TYPE.DONOR].includes(user.type)
    ? `/scholarships/${scholarship.id}/applications`
    : `/scholarships/${scholarship.id}`;
};

// TODO:IMP: try to get this into using ScholarshipModelShape and isDeadlineExpired virtual
export function isScholarshipExpired(scholarship: { deadline?: string | Date }) {
  if (!scholarship?.deadline) {
    return false;
  }

  return moment().isAfter(moment(scholarship.deadline).endOf('day'));
}

export function formatFormStatus(statusKey: FORM_CHANGE_REQUEST_STATUSES) {
  switch (statusKey) {
    case FORM_CHANGE_REQUEST_STATUSES.REVIEW_REQUESTED:
      return 'Sent for review';
    case FORM_CHANGE_REQUEST_STATUSES.CHANGES_REQUESTED:
      return 'Requested changes';
    case FORM_CHANGE_REQUEST_STATUSES.APPROVED:
      return 'Approved';
    default:
      return '';
  }
}
