import './LeaveReviewPanel.scss';

import { isNil, mapValues } from 'lodash';
import React, { useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { useQueryClient } from 'react-query';

import { EuiButton, htmlIdGenerator } from '@elastic/eui';
import { EditableReviewModel } from '@sharedClients/types/ReviewModel';
import useBeforeLeave from '@sharedComponents/hooks/useBeforeLeave';
import useToast from '@sharedComponents/hooks/useToast';
import { ReviewCriteriaRoundConfiguration } from '@sharedComponents/interfaces/Scholarships.interface';
import { ReviewModelShape } from '@sharedComponents/models';

import { ReviewFlyout } from './LeaveReviewPanel/ReviewFlyout';
import applicationReviewsQuery from './queries/applicationReviewsQuery';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function LeaveReviewPanel({
  client,
  applicationId,
  reviewCriteria,
  reviewInstructions
}: {
  client: ApplicationClient;
  applicationId: number;
  reviewCriteria: ReviewCriteriaRoundConfiguration;
  reviewInstructions?: number | null;
}) {
  const [isLoading, setLoading] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isOpened, setPanelOpened] = useState<boolean>(false);
  const [currentReview, setCurrentReview] = useState<EditableReviewModel>();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  useBeforeLeave({
    when: isUnsaved,
    message: 'You have unsaved review, are you sure you want to leave?'
  });

  useEffect(() => {
    setLoading(true);
    client.getMyReviewForApplication(applicationId).then(reviewModel => {
      setCurrentReview(reviewModel);
      setLoading(false);
    });
  }, [applicationId]);

  function upsertReview(review: EditableReviewModel, isSave: boolean) {
    if (isSave) {
      const reviewToSave: ReviewModelShape = {
        ...review,
        data: mapValues<EditableReviewModel['data'], ReviewModelShape['data'][string]>(review.data, (v: any) =>
          isNaN(parseInt(v)) ? null : parseInt(v)
        )
      };
      setLoading(true);
      client
        .upsertReview(reviewToSave, applicationId)
        .then(upsertedReview => {
          setLoading(false);
          setPanelOpened(false);
          setCurrentReview(upsertedReview);
          setIsUnsaved(false);

          // invalidate reviews query in order to have reviews updated
          queryClient.invalidateQueries(applicationReviewsQuery.QUERY_KEY);
          addToast({
            id: htmlIdGenerator()(),
            color: 'success',
            title: 'Review submitted!'
          });
        })
        .catch(e => {
          addToast({
            id: htmlIdGenerator()(),
            color: 'warning',
            title: 'Error while saving the review!',
            toastLifeTimeMs: 10000
          });

          // eslint-disable-next-line no-console
          console.error(e.message);
        });
    } else {
      setCurrentReview(review);
      setIsUnsaved(true);
    }
  }

  if (!currentReview) {
    return null;
  }

  return (
    <>
      {!isOpened ? (
        <Sticky className="leaveReviewPanel" enabled={true} top={50}>
          <EuiButton
            className={`leaveReviewPanel-open ${!isUnsaved && !isNil(currentReview?.score) ? 'm-saved' : ''}`}
            onClick={() => setPanelOpened(true)}
            isLoading={isLoading}
            aria-label="Expand review panel"
            iconType={isUnsaved ? 'alert' : 'expand'}
            color={isUnsaved ? 'warning' : 'primary'}
            title={isUnsaved ? 'Contains unsaved data!' : ''}
            size="s"
            fill
          >
            {!isNil(currentReview?.score) ? (isUnsaved ? 'Review is unsaved!' : 'Show My Review') : 'Show Review Panel'}
          </EuiButton>
        </Sticky>
      ) : (
        <ReviewFlyout
          reviewInstructions={reviewInstructions}
          currentReview={currentReview}
          reviewCriteria={reviewCriteria}
          isLoading={isLoading}
          isUnsaved={isUnsaved}
          client={client}
          upsertReview={upsertReview}
          onClose={() => {
            setPanelOpened(false);
          }}
        />
      )}
    </>
  );
}
