import React from 'react';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText
} from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

const DraftRestoreModal = ({ closeModal, draftEntry }) => {
  const scholarshipClient = useScholarshipClient();
  const router = useRouter();
  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Unsaved Draft Found</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiText size="m">Would you like to continue working on your previous draft?</EuiText>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty
          size="s"
          color="danger"
          onClick={async () => {
            await scholarshipClient.deleteScholarship(draftEntry.id, true);
            closeModal();
          }}
        >
          Discard
        </EuiButtonEmpty>
        <EuiButton
          size="s"
          onClick={() => {
            closeModal();
            const newLocation = `/scholarship-self-listing/${draftEntry.id}`;

            router.push(newLocation);
          }}
        >
          Restore
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default DraftRestoreModal;
