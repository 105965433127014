import React from 'react';
import * as yup from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';
import CheckboxField from '@sharedComponents/formElements/simpleFields/CheckboxField';
import NumberField from '@sharedComponents/formElements/simpleFields/NumberField';
import TextAreaField from '@sharedComponents/formElements/simpleFields/TextAreaField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';

import AbstractEntryStep from './AbstractEntryStep';
import ScholarshipNameLookup from './EntryStepResearch/ScholarshipNameLookup';
import ScholarshipDates from './EntryStepResearch/ScholarshipDates';

const EntryStepResearch: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    name: yup.string().label('Scholarship Name').min(6),
    apply_url: yup.string().url('Invalid application URL').optional(),
    description: yup.string().label('Scholarship description').min(50),
    candidates_limit: yup.number().nullable(true),

    data: yup.object().when(['isDeadlineUncertain'], {
      is: false,
      then: yup.object().shape({
        startDate: yup
          .date()
          .label('Start Date')
          .typeError('Scholarship start date and deadline are required')
          .required('Scholarship start date and deadline are required')
      }),
      otherwise: yup
        .object()
        .shape({
          startDate: yup
            .string()
            .nullable()
            .transform(() => null)
        })
        .default({})
    }),

    deadlineDate: yup
      .date()
      .label('Deadline')
      .when('isDeadlineUncertain', {
        is: true,
        then: yup
          .date()
          .nullable()
          .transform(() => null),
        otherwise: yup
          .date()
          .typeError('Scholarship start date and deadline are required')
          .required('Scholarship start date and deadline are required')
      }),

    isRangedAmount: yup.boolean().when('isAmountVaries', {
      is: true,
      then: yup.boolean().default(false),
      otherwise: yup.boolean().optional()
    }),
    isAmountVaries: yup.boolean().optional(),

    award_amount_from: yup
      .number()
      .label('Award Amount')
      .when('isRangedAmount', {
        is: true,
        then: yup.number().when('isAmountVaries', {
          is: val => {
            return val === true;
          },
          then: yup
            .number()
            .nullable()
            .transform(() => null),
          otherwise: yup.number().required()
        }),
        otherwise: yup
          .number()
          .nullable()
          .transform(() => null)
      }),
    award_amount_to: yup
      .number()
      .label('Award Amount')
      .when('isAmountVaries', {
        is: true,
        then: yup
          .number()
          .nullable()
          .transform(() => null),
        otherwise: yup.number().typeError('Award amount is required').required()
      })
      .when('isRangedAmount', {
        is: true,
        then: yup.number().required().moreThan(yup.ref('award_amount_from'))
      }),
    isDeadlineUncertain: yup.boolean().optional()
  }),
  render: function (rowProps, formMethods, scholarshipClient) {
    const { isRangedAmount, isAmountVaries } = formMethods.watch(['isRangedAmount', 'isAmountVaries']);

    return (
      <EuiFlexGroup direction="column" responsive={false}>
        <EuiFlexItem>
          <EuiTitle>
            <h3>General Information</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row" gutterSize="l" responsive={false}>
            <EuiFlexItem>
              <ScholarshipNameLookup
                rowProps={rowProps}
                formMethods={formMethods}
                scholarshipClient={scholarshipClient}
              />
              <TextField
                fieldName={'apply_url'}
                rowProps={{ label: 'Application URL', ...rowProps }}
                fieldProps={{ autoComplete: 'off', placeholder: 'https://' }}
              />
              <TextAreaField fieldName={'description'} rowProps={{ label: 'Description *', ...rowProps }} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column" responsive={false}>
                <EuiFlexItem grow={false}>
                  <ScholarshipDates watch={formMethods.watch} rowProps={rowProps} />
                  {/** TODO:v2: Application cycle has to be here also */}
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup direction="column" responsive={false}>
                    <EuiFlexItem>
                      <EuiFlexGroup direction="row" gutterSize="none" responsive={false}>
                        {isAmountVaries ? null : isRangedAmount ? (
                          <>
                            <EuiFlexItem grow={4}>
                              <EuiText size="xs" style={{ fontWeight: 600 }}>
                                Between
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={3}>
                              <NumberField
                                fieldName={'award_amount_from'}
                                fieldProps={{
                                  prepend: '$',
                                  fullWidth: false,
                                  autoComplete: 'off'
                                }}
                              />
                            </EuiFlexItem>
                            <EuiFlexItem grow={2}>
                              <EuiText size="xs" style={{ fontWeight: 600, alignSelf: 'center' }}>
                                and
                              </EuiText>
                            </EuiFlexItem>
                          </>
                        ) : null}
                        {isAmountVaries ? null : (
                          <EuiFlexItem grow={3}>
                            <NumberField
                              fieldName={'award_amount_to'}
                              rowProps={isRangedAmount ? {} : { label: 'Award Amount *', ...rowProps }}
                              fieldProps={{
                                prepend: '$',
                                fullWidth: false,
                                autoComplete: 'off'
                              }}
                            />
                          </EuiFlexItem>
                        )}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup direction="row" gutterSize="none">
                        <EuiFlexItem>{/**To have automated padding */}</EuiFlexItem>
                        <EuiFlexItem grow={2}>
                          {!isAmountVaries ? (
                            <CheckboxField fieldName="isRangedAmount" fieldProps={{ label: 'Use range' }} />
                          ) : null}
                          <CheckboxField fieldName="isAmountVaries" fieldProps={{ label: 'Amount varies' }} />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <NumberField
                        fieldName={'candidates_limit'}
                        rowProps={{
                          label: 'Candidates Limit',
                          helpText:
                            'Close application cycle instantly when a certain number of completed applications are received.',
                          ...rowProps
                        }}
                        fieldProps={{
                          fullWidth: false,
                          autoComplete: 'off'
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepResearch;
