import React from 'react';
import {
  EuiCheckbox,
  EuiIcon,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';
import {
  InvitationConfirmation,
  INVITATION_STATUS,
  VerifiedInvitation
} from '@sharedComponents/interfaces/Invitation.interface';
import { capitalize } from '@sharedComponents/utils/stringUtils';

export function InvitationSubmittingView({
  verifiedInvitations,
  optionalInvitesList,
  updateOptionalInvitesList
}: {
  verifiedInvitations: VerifiedInvitation[];
  optionalInvitesList: InvitationConfirmation[];
  updateOptionalInvitesList: React.Dispatch<React.SetStateAction<InvitationConfirmation[]>>;
}) {
  return (
    <EuiTable>
      <EuiTableHeader>
        <EuiTableHeaderCell width="65%">User</EuiTableHeaderCell>
        <EuiTableHeaderCell width="15%">Role</EuiTableHeaderCell>
        <EuiTableHeaderCell width="20%">Invitation</EuiTableHeaderCell>
      </EuiTableHeader>

      <EuiTableBody>
        {verifiedInvitations.map(verifiedInvitation => (
          <EuiTableRow key={verifiedInvitation.email}>
            <EuiTableRowCell>
              <strong>{verifiedInvitation.name}</strong>
              {`, ${verifiedInvitation.email}`}
            </EuiTableRowCell>
            <EuiTableRowCell>{capitalize(verifiedInvitation.userType)}</EuiTableRowCell>
            <EuiTableRowCell>
              {verifiedInvitation.status === INVITATION_STATUS.USER_EXIST ? (
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  label="Already registered"
                  checked={true}
                  onChange={() => {}}
                  disabled
                />
              ) : verifiedInvitation.status === INVITATION_STATUS.USER_NOT_EXIST ? (
                <EuiCheckbox
                  id={htmlIdGenerator()()}
                  label="Send invitation"
                  checked={optionalInvitesList.filter(inv => inv.email === verifiedInvitation.email)[0].isInviting}
                  onChange={e => {
                    const updateList = [
                      ...optionalInvitesList.filter(inv => inv.email !== verifiedInvitation.email),
                      {
                        ...optionalInvitesList.filter(inv => inv.email === verifiedInvitation.email)[0],
                        isInviting: e.target.checked
                      }
                    ];
                    updateOptionalInvitesList(updateList);
                  }}
                />
              ) : (
                <EuiToolTip
                  position="top"
                  content={`This user is already registered in the system in a different role and cannot be invited as your ${verifiedInvitation.userType}. However, you are still able to continue your registration. Please contact hello@scholarsapp.com and we can invite a different email to link as your parent.`}
                >
                  <EuiText color="warning" size="s">
                    <EuiIcon type="alert" color="warning" /> Cannot be invited
                  </EuiText>
                </EuiToolTip>
              )}
            </EuiTableRowCell>
          </EuiTableRow>
        ))}
      </EuiTableBody>
    </EuiTable>
  );
}
