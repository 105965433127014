import { ValidationError } from '../FormFieldText';

import Pattern from './Pattern';

const emailPattern: Pattern = {
  placeholder: 'user@domain.com',

  correctPartial(value) {
    const corrected = value.replace(/ /g, '');

    return corrected;
  },

  correctComplete(value) {
    value = value || '';

    if (value.startsWith('<')) {
      value = value.substr(1);
    }

    if (value.endsWith('>')) {
      value = value.substr(0, value.length - 1);
    }

    if (value.startsWith('mailto:')) {
      value = value.substr('mailto:'.length);
    }

    const parts = value.split('@');

    if (parts.length !== 2) {
      throw new ValidationError('Include @ symbol.');
    }

    let [user, domain] = parts;

    if (!user || !domain) {
      throw new ValidationError('@ cannot come first or last.');
    }

    // note that the user name of an email IS actually case-sensitive,
    // but it's so unusual we ignore that case. It's a lot more common
    // that people entere upper-cased emails, which creates a problem
    // e.g. when matching counselors up by their email addresses.
    user = user.toLowerCase();

    domain = domain.toLowerCase();

    if (domain.indexOf('.') < 0) {
      throw new ValidationError('The part after @ is wrong.');
    }

    // could be quite long tho - christa.rask@showlow.education
    if (domain.length - domain.lastIndexOf('.') > 10) {
      throw new ValidationError('The part after @ is wrong.');
    }

    return [user, domain].join('@');
  }
};

export default emailPattern;
