import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { post } from '@donors/users/Users';
import { EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSwitch, EuiText, formatDate } from '@elastic/eui';
import { getServiceUrl } from '@sharedClients/main';
import AbstractListing, { Column } from '@sharedComponents/common/AbstractListing';
import FloatingActionButton from '@sharedComponents/common/FloatingActionButton';
import SearchInput from '@sharedComponents/common/SearchInput';
import { USER_TYPE } from '@sharedComponents/constants';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import {
  DRAFT_STEP_BADGE_COLOR,
  DRAFT_STEP_STATUSES,
  SCHOLARSHIP_ENTRY_STEPS,
  SELF_LISTING_LAST_STEP_NUMBER
} from '@sharedComponents/interfaces/Scholarships.interface';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { DraftScholarshipModelShape, ScholarshipModelShape, UserModelShape } from '@sharedComponents/models';
import { ScholarshipListingFilterSchema } from '@sharedComponents/schemas/ScholarshipListingFilter.schema';
import { scholarshipLink } from '@sharedComponents/utils/scholarshipUtils';
import UserModel, { DonorUserData } from '@sharedContract/UserModel';

import { HeaderProps } from '../components/Header';
import useScholarshipListing from './hooks/useScholarshipListing';
import DraftStatusFilter from './Scholarships/DraftStatusFilter';

type PathParamsType = {};

interface ScholarshipsProps extends RouteComponentProps<PathParamsType>, HeaderProps {
  user: UserModel;
  jwt: string;
}

/**
 * Handles fetching and storing of the scholarship and the application.
 */
const Scholarships = (props: ScholarshipsProps) => {
  let { user, jwt, history } = props;

  const [textFilter, setTextFilter] = useLocalStorage<string>('scholarships.textFilter', '');
  const [pageIndex, setPageIndex] = useState(0);

  const initialDraftFilterValue =
    user?.type === USER_TYPE.DONOR && (user?.data as DonorUserData)?.isVerified === false ? true : false;
  const [showDrafts, setShowDrafts] = useLocalStorage<boolean>(
    'scholarships.listing.showDrafts',
    initialDraftFilterValue
  );
  const [showDeleted, setShowDeleted] = useLocalStorage<boolean>('scholarships.listing.showDeleted', false);
  const [showExpired, setShowExpired] = useLocalStorage<boolean>('scholarships.listing.showExpired', true);
  const [draftStepsFilters, setDraftStepsFilters] = useState<SCHOLARSHIP_ENTRY_STEPS[]>([]);
  // const [sortField, setSortField] = useState('firstName');
  // const [sortDirection, setSortDirection] = useState('asc');

  const { data: scholarships, isLoading } = useScholarshipListing(
    {
      ...ScholarshipListingFilterSchema.getDefault(),
      isShowDeleted: showDeleted,
      isShowExpired: !showDrafts ? showExpired : true,
      isDraftView: showDrafts,
      textSearch: textFilter,
      draftSteps: showDrafts ? draftStepsFilters : []
    },
    pageIndex,
    50
  );

  useEffect(() => {
    document.title = `Scholar's App - Scholarships${showDrafts ? ' Drafts' : ''}`;

    setPageIndex(0);
  }, [textFilter, showDrafts]);

  const columns: Column[] = [
    {
      id: 'name',
      label: 'Scholarship',
      width: '50%',
      render: (scholarship: ScholarshipModelShape) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiFlexItem>
              <EuiFlexGroup direction="row" gutterSize="none">
                <EuiFlexItem>
                  <EuiLink
                    href={scholarshipLink(scholarship, user as UserModelShape)}
                    onClick={e => e.preventDefault()}
                  >
                    <EuiText size="s">{scholarship.name}</EuiText>
                  </EuiLink>
                </EuiFlexItem>

                {!showDrafts && scholarship.apply_url ? (
                  <EuiFlexItem grow={false}>
                    <EuiBadge color="default" style={{ marginLeft: '8px' }}>
                      external
                    </EuiBadge>
                  </EuiFlexItem>
                ) : null}

                {!showDrafts && scholarship.is_premium ? (
                  <EuiFlexItem grow={false}>
                    <EuiBadge color="success" style={{ marginLeft: '8px' }}>
                      premium
                    </EuiBadge>
                  </EuiFlexItem>
                ) : null}
              </EuiFlexGroup>
            </EuiFlexItem>
            {scholarship.donor?.name ? (
              <EuiFlexItem>
                <EuiText size="xs" grow={false}>
                  {scholarship.donor?.name}
                </EuiText>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        );
      }
    }
  ];

  if (!showDrafts) {
    columns.push({
      id: 'candidatesApplicationsCount',
      label: 'Submitted Applications',
      align: 'center',
      render: (scholarship: ScholarshipModelShape) => {
        return scholarship.candidatesApplicationsCount || 0;
      }
    });

    columns.push({
      id: 'qualifiedApplicantsCount',
      label: 'Qualified Applicants',
      align: 'center',
      render: (scholarship: ScholarshipModelShape) => {
        return scholarship.qualifiedApplicantsCount || 0;
      }
    });
  } else {
    columns.push({
      id: 'draft_step',
      label: 'Draft Status',
      align: 'center',
      render: (scholarship: DraftScholarshipModelShape) => {
        let currentDraftStep = scholarship.data?.draft_step;
        if (scholarship.isSelfListingDraft) {
          const isReadyForPublishing = scholarship.data?.self_listing_step === SELF_LISTING_LAST_STEP_NUMBER;
          currentDraftStep = isReadyForPublishing
            ? SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_COMPLETED
            : SCHOLARSHIP_ENTRY_STEPS.SELF_LISTING_IN_PROGRESS;
        }

        return currentDraftStep ? (
          <EuiBadge color={DRAFT_STEP_BADGE_COLOR[currentDraftStep] || 'default'}>
            {DRAFT_STEP_STATUSES[currentDraftStep]}
          </EuiBadge>
        ) : (
          ''
        );
      }
    });
  }

  columns.push({
    id: 'deadline',
    label: 'Deadline',
    align: 'right',
    render: (scholarship: ScholarshipModelShape) => {
      return `${formatDate(scholarship.deadline, 'LL')}${scholarship.isDeadlineExpired ? ' (Expired)' : ''}`;
    }
  });

  return (
    <PageContentWrapper>
      <>
        {user.type === USER_TYPE.DONOR ? (
          <FloatingActionButton
            onClick={() => history.push('/scholarship-self-listing')}
            tooltip="List New Scholarship"
          />
        ) : user.type !== USER_TYPE.REVIEWER ? (
          <FloatingActionButton
            onClick={() =>
              showDrafts || user.type === USER_TYPE.DATAENTRY
                ? history.push('/scholarship-entry')
                : history.push('/scholarships/add')
            }
            tooltip="New Scholarship"
          />
        ) : null}
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem>
            <EuiText size="s">
              All scholarships for your organization. Click on the scholarship name to view the Applicant Reports.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
              {user.type !== USER_TYPE.REVIEWER ? (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiSwitch label="Show Drafts" checked={showDrafts} onChange={() => setShowDrafts(!showDrafts)} />
                  </EuiFlexItem>
                  {user.type === USER_TYPE.ADMIN || user.type === USER_TYPE.DATAENTRY ? (
                    <>
                      <EuiFlexItem grow={false}>
                        <EuiSwitch
                          label="Show Deleted"
                          checked={showDeleted}
                          onChange={() => setShowDeleted(!showDeleted)}
                        />
                      </EuiFlexItem>
                      {!showDrafts ? (
                        <EuiFlexItem grow={false}>
                          <EuiSwitch
                            label="Show Expired"
                            checked={showExpired}
                            onChange={() => setShowExpired(!showExpired)}
                          />
                        </EuiFlexItem>
                      ) : null}
                    </>
                  ) : null}
                  <EuiFlexItem grow={false}>
                    {showDrafts && (user.type === USER_TYPE.ADMIN || user.type === USER_TYPE.DATAENTRY) ? (
                      <DraftStatusFilter
                        onDraftStatusFilterChange={draftStatuses => {
                          if (!isEqual(draftStatuses.sort(), draftStepsFilters.sort())) {
                            setDraftStepsFilters(draftStatuses);
                          }
                        }}
                      />
                    ) : null}
                  </EuiFlexItem>
                </>
              ) : null}
              <EuiFlexItem>
                <EuiFlexGroup direction="row" justifyContent="flexEnd" alignItems="center">
                  {user.type === USER_TYPE.ADMIN && !showDrafts ? (
                    <EuiFlexItem grow={false} style={{ justifySelf: 'flex-start' }}>
                      <EuiButton size="s" onClick={() => download(jwt)}>
                        Download
                      </EuiButton>
                    </EuiFlexItem>
                  ) : null}
                  <EuiFlexItem grow={false}>
                    <SearchInput
                      value={textFilter}
                      placeholder="Scholarship Name"
                      onValueChange={setTextFilter}
                      isLoading={isLoading}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <AbstractListing
              isLoading={isLoading}
              columns={columns}
              records={scholarships?.results}
              total={scholarships?.total}
              onRowClick={scholarship => {
                history.push(scholarshipLink(scholarship, user as UserModelShape));
              }}
              pageIndex={pageIndex}
              onPageChange={pageIndex => setPageIndex(pageIndex)}
              pageSize={50}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    </PageContentWrapper>
  );
};

function download(jwt: string) {
  post(`${getServiceUrl('applications')}/scholarships.tsv`, { jwt });
}

const ScholarshipsWithRouter = withRouter(Scholarships);

export default ScholarshipsWithRouter;
