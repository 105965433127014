import React from 'react';
import NodeProps from './FormNodeProps';
import RichTextPreview from '@sharedComponents/common/RichTextPreview';

export default function FormFieldValueRichText(props: NodeProps & { value: any }) {
  const { value, node } = props;

  if (!value) {
    return '—';
  } else {
    return <RichTextPreview value={value} modalTitle={node.label} />;
  }
}
