import { EuiFlexItem } from '@elastic/eui';
import { CheckboxField, TextField } from '@sharedComponents/formElements';

export const NodeBodySection = (
  <>
    <EuiFlexItem>
      <TextField fieldName="title" rowProps={{ label: 'Title:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <TextField fieldName="path" rowProps={{ label: 'Path:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <TextField fieldName="help" rowProps={{ label: 'Help text:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="wide"
        rowProps={{ label: 'Is full width:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
  </>
);
