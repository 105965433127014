import React from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';

import AbstractDeclineStep from './AbstractDeclineStep';

const NotifyDeclinedStepNotifyCompleted: AbstractDeclineStep = {
  headerTitle: 'Students notification is completed!',
  actions(changeStep) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(null)} fill>
          Close
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ affectedStudentsCount }) {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            {affectedStudentsCount}{' '}
            {affectedStudentsCount === 1 ? 'student' : 'students' + ' were successfully notified'}.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default NotifyDeclinedStepNotifyCompleted;
