import React from 'react';
import { toString } from '../../model/cast';
import FormNode from '../../model/FormNode';
import ApplicationModel from '@sharedClients/types/ApplicationModel';

/**
 * The component that renders any form field that has a label with an input field underneath.
 */
export default function FormFieldLabel(props: {
  node: FormNode;
  application: ApplicationModel;
  onFormError: (e: Error) => void;
  warnOnMissingRequired: boolean;
  children: React.ReactNode;
}) {
  const { node, application, onFormError, warnOnMissingRequired } = props;

  const field = node.field;
  const label = toString(node.label != null ? node.label : camelCaseToHuman(field), onFormError);

  const missing = warnOnMissingRequired && node.isMissingRequiredFields(application, false);

  if (isShow(node, application)) {
    return (
      <div className={'field' + (missing ? ' missing-required' : '')}>
        <label className="own-line" htmlFor={node.getInputElementId()}>
          {label}
          {missing ? <span className="error">Required</span> : null}
        </label>
        <div className="value">{props.children}</div>
        {node.help ? <div className="help">{toString(node.help, onFormError)}</div> : null}
      </div>
    );
  } else {
    return null;
  }
}

function isShow(node: FormNode, application: ApplicationModel) {
  if (node.isDetailsTo) {
    let sibling = node.parent && node.parent.getField(node.isDetailsTo);

    if (!sibling) {
      sibling = node.parent && node.parent.parent && node.parent.parent.getField(node.isDetailsTo);
    }

    const value = sibling.getValue(application);

    return value && !!value.name && value.id == null;
  } else {
    return true;
  }
}

export function camelCaseToHuman(str: string) {
  let res = '';

  if (!str) {
    return;
  }

  for (let ch of str) {
    if (ch === ch.toUpperCase()) {
      res = res + ' ' + ch.toLowerCase();
    } else {
      res = res + ch;
    }
  }

  return res[0].toUpperCase() + res.substr(1);
}
