import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'unreadQuestions';

function useUnreadQuestionsCount(options: UseQueryOptions<number, Error, number, QueryKey> = {}) {
  const applicationClient = useApplicationClient();
  const query = {
    ...useQuery<number, Error>([CURRENT_QUERY_KEY], () => applicationClient.getUnreadQuestions(), {
      staleTime: 1000 * 60 * 15,
      ...options
    })
  };

  return query;
}

const unreadQuestionsCount = { QUERY_KEY: CURRENT_QUERY_KEY, useUnreadQuestionsCount };
export default unreadQuestionsCount;
