import React from 'react';
import { EuiFlexItem } from '@elastic/eui';
import { CheckboxField, TextField } from '@sharedComponents/formElements';

export const NodeBodyCheckbox = (
  <>
    <EuiFlexItem>
      <TextField fieldName="label" rowProps={{ label: 'Label:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <TextField fieldName="field" rowProps={{ label: 'ID:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="required"
        rowProps={{ label: 'Is required:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="defaultChecked"
        rowProps={{ label: 'Is checked by default:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
  </>
);
