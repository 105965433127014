import './ScholarshipEntryFormContainer.scss';

import React, { useEffect, useState } from 'react';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, htmlIdGenerator } from '@elastic/eui';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import useToast from '@sharedComponents/hooks/useToast';
import { SCHOLARSHIP_ENTRY_STEPS } from '@sharedComponents/interfaces/Scholarships.interface';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { DraftScholarshipModelShape, ScholarshipModelShape } from '@sharedComponents/models';
import { updateMergeDeep } from '@sharedComponents/utils/objectUtils';

import getScholarshipEntryFormSteps from './ScholarshipEntryFormContainer/allScholarshipEntryFormSteps';
import ScholarshipEntryBreadcrumbs from './ScholarshipEntryFormContainer/ScholarshipEntryBreadcrumbs';
import ScholarshipEntryForm from './ScholarshipEntryFormContainer/ScholarshipEntryForm';
import ScholarshipEntryStepper from './ScholarshipEntryFormContainer/ScholarshipEntryStepper';
import { useAllClients } from '@sharedComponents/selectors/useAllClients';

const ScholarshipEntryFormContainer = ({
  draftID,
  initialStep
}: {
  draftID?: DraftScholarshipModelShape['id'];
  initialStep?: SCHOLARSHIP_ENTRY_STEPS;
}) => {
  const { applicationClient, scholarshipClient } = useAllClients();
  const { addToast } = useToast();
  const router = useRouter();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [draftScholarshipModel, setDraftScholarshipModel] = useState<
    DraftScholarshipModelShape | ScholarshipModelShape | undefined
  >();

  const [currentStep, setCurrentStep] = useState<SCHOLARSHIP_ENTRY_STEPS>(
    initialStep || SCHOLARSHIP_ENTRY_STEPS.RESEARCH
  );
  const [currentStepObject, setCurrentStepObject] = useState(getScholarshipEntryFormSteps(currentStep));

  useEffect(() => {
    if (draftID && !isNaN(draftID)) {
      // existing draft scholarship

      scholarshipClient
        .getScholarshipEntry(draftID, true)
        .then(scholarship => {
          setDraftScholarshipModel({ ...scholarship });
          document.title = `Scholarship entry form - ${scholarship.name}`;

          // Only allow to set current step based on params in case that scholarhip record has it unlocked
          if (
            initialStep &&
            scholarship?.data?.draft_step &&
            scholarship?.data?.draft_step + 1 /** Next step is available when previous is filled */ >= initialStep
          ) {
            setCurrentStep(initialStep);
          }

          setLoading(false);
        })
        .catch(() => setError(DEFAULT_ERROR_MESSAGE));
    } else {
      // new scholarship entry form
      setDraftScholarshipModel({} as DraftScholarshipModelShape);
      setLoading(false);
    }
  }, [draftID]);

  useEffect(() => {
    setCurrentStepObject(getScholarshipEntryFormSteps(currentStep));
  }, [currentStep]);

  if (error) {
    return (
      <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
        {error}
      </EuiCallOut>
    );
  } else if (!draftScholarshipModel) {
    return null;
  }

  // wrapping step changing to update url in case that's existing scholarship
  const changeFormStep = step => {
    if (draftScholarshipModel?.name && draftScholarshipModel?.id) {
      window.history.replaceState(
        null,
        `Scholarship entry form - ${draftScholarshipModel.name}`,
        `/scholarship-entry/${draftScholarshipModel.id}/${step}`
      );
    }

    setCurrentStep(step);
  };

  // step controls functions. Should be nulled if prev/next step is blocked at this moment
  const prevStepifAvailable = () => {
    if (currentStep === SCHOLARSHIP_ENTRY_STEPS.RESEARCH) {
      return false;
    }

    return () => {
      changeFormStep(currentStep - 1);
    };
  };

  const nextStepifAvailable = () => {
    if (currentStep === SCHOLARSHIP_ENTRY_STEPS.PUBLISH) {
      return false;
    }

    // each step has own conditions when next step could be unblocked
    switch (currentStep) {
      case SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND:
      case SCHOLARSHIP_ENTRY_STEPS.RESEARCH: {
        // allowed only in case draft scholarship is created
        if (!draftScholarshipModel.id) {
          return false;
        }

        break;
      }

      case SCHOLARSHIP_ENTRY_STEPS.QA_SECOND: {
        // Publishing only works if QA step is submitted
        if ((draftScholarshipModel?.data?.draft_step || 0) < currentStep) {
          return false;
        }

        break;
      }

      // We let any kind of navigation between steps, since autosave will be triggering validation
    }

    return () => {
      changeFormStep(currentStep + 1);
    };
  };

  const updateScholarshipEntry = async validatedStepValues => {
    setLoading(true);

    // TODO:IMP: switch into lodash func?
    const scholarshipEntry = updateMergeDeep<DraftScholarshipModelShape>(draftScholarshipModel, validatedStepValues);

    // Update draft scholarship completed step with latest one if needed (could be moved to backend tho)
    if (!scholarshipEntry.data?.draft_step || scholarshipEntry.data.draft_step < currentStep) {
      scholarshipEntry.data.draft_step = currentStep;
    }

    // review criteria configuration is a complex object, we have to replace whole object instead of merging
    // could be nice to have some better approach instead of this hardpatch
    if (validatedStepValues.reviewCriteriasConfiguration) {
      scholarshipEntry.reviewCriteriasConfiguration = validatedStepValues.reviewCriteriasConfiguration;
    }

    const isNewInsert = scholarshipEntry.id === undefined;
    try {
      const upsertedResult: DraftScholarshipModelShape = await scholarshipClient.upsertDraftScholarshipEntry(
        scholarshipEntry
      );

      setDraftScholarshipModel({ ...upsertedResult });

      if (isNewInsert) {
        window.history.replaceState(
          null,
          `Scholarship entry form - ${upsertedResult.name}`,
          `/scholarship-entry/${upsertedResult.id}`
        );
      }
    } catch (e: any) {
      setError(`Error during scholarship entry saving. Please, report this to administration: ${e.message}`);
    }

    setLoading(false);

    addToast({
      id: htmlIdGenerator()(),
      color: 'success',
      title: isNewInsert ? 'New draft scholarship saved!' : 'Scholarship updated!'
    });

    return true;
  };

  const deleteScholarshipEntry = async () => {
    setLoading(true);
    await scholarshipClient.deleteScholarship(draftScholarshipModel.id, true);
    router.push('/scholarships');
  };

  const publishScholarshipEntry = async () => {
    setLoading(true);
    // todo somenhow we had undefined here in logs = https://applications-dot-scholarsapp-prod.appspot.com/v2/scholarships/drafts/undefined/publish?o=donors. [500]
    const { id } = await scholarshipClient.publishDraftScholarship(draftScholarshipModel.id);
    router.push(`/scholarships/${id}`);
  };

  return (
    <PageContentWrapper className="scholarshipEntryFormContainer">
      <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
        <EuiFlexItem>
          <ScholarshipEntryBreadcrumbs scholarshipName={draftScholarshipModel?.name || ''} currentStep={currentStep} />
        </EuiFlexItem>
        <EuiFlexItem>
          <ScholarshipEntryStepper
            activeStep={currentStep}
            lastUnblockedStep={
              draftScholarshipModel?.data?.draft_step
                ? draftScholarshipModel?.data?.draft_step
                : (draftScholarshipModel as DraftScholarshipModelShape).isSelfListingDraft
                ? SCHOLARSHIP_ENTRY_STEPS.QA
                : 0
            }
            onStepChange={step => changeFormStep(step)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <ScholarshipEntryForm
            applicationClient={applicationClient as any}
            scholarshipClient={scholarshipClient}
            currentStepObject={currentStepObject}
            defaultValues={draftScholarshipModel}
            isLoading={isLoading}
            currentStep={currentStep}
            // those methods could be omitted at some point, we may extract buttons from that component
            onSave={updateScholarshipEntry}
            handleDelete={deleteScholarshipEntry}
            handlePublication={publishScholarshipEntry}
            prevStepifAvailable={prevStepifAvailable()}
            nextStepifAvailable={nextStepifAvailable()}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
};

export default ScholarshipEntryFormContainer;
