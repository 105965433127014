export default [
  'Autism',
  'Autoimmune disorder (lupus, fibromyalgia, rheumatoid arthritis, HIV/AIDS, etc.)',
  'Blind or low vision',
  'Cancer',
  'Cardiovascular or heart disease',
  'Celiac disease',
  'Cerebral palsy',
  'Deaf or hard of hearing',
  'Depression or anxiety',
  'Diabetes',
  'Epilepsy',
  "Gastrointestinal disorder (Crohn's disease, IBS, etc.)",
  'Intellectual disability',
  'Missing limbs or partially missing limbs',
  "Nervous system condition (Parkinson's disease, multiple sclerosis, etc.)",
  'Psychiatric condition (bipolar disorder, schizophrenia, PTSD, major depression, etc.)'
];
