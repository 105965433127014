import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import track from '@sharedComponents/base/track';
import UserClient from '@sharedClients/UserClient';
import useToast from '@sharedComponents/hooks/useToast';
import warn from '@donors/base/warn';
import {
  EuiButton,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui';
import { getUserHomeUrl } from '@sharedClients/main';
import { htmlIdGenerator } from '@elastic/eui';
import { sleep } from '@sharedComponents/utils/misc';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { get } from 'lodash';
import { useAuth } from '@sharedComponents/selectors/useAuth';

// Note that this is used both by applicants and donors/reviewers on the donors domain.
const SetPassword = ({
  email,
  token,
  userClient = new UserClient() // could be used for tests, but we have to get rid of this
}: {
  email?: string; // for tests
  token?: string; // for tests
  userClient?: UserClient;
}) => {
  const auth = useAuth();
  const params = new URLSearchParams(window.location.search);

  if (!email) {
    email = params.get('email') || '';
  }

  if (!token) {
    token = params.get('token') || '';
  }

  const [generalFormError, setGeneralFormError] = useState<string | undefined>();
  const [errorCause, setErrorCause] = useState<string | undefined>();
  const router = useRouter();
  const { addToast } = useToast();

  const isLinkExpired = errorCause === 'link-expired';

  useEffect(() => {
    if (!email || !token) {
      setGeneralFormError(`This link is invalid`);
      setErrorCause('link-expired');
    } else {
      userClient.setPassword({ email: email as string, token: token as string }).catch(e => {
        setGeneralFormError(e.message);
        setErrorCause(get(e, 'fingerprint[1]'));
      });
    }
  }, [email, token]);

  // Uncontroller components for passwords. Will be replaced with branch: experimental/T3468
  const passwordRef = React.createRef<HTMLInputElement>();
  const confirmPasswordRef = React.createRef<HTMLInputElement>();
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, 'Please choose a more secure password (at least eight characters).')
      .max(50, 'Please choose shorter password (not longer than 50 characters).'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const onSetPasswordSubmit = async e => {
    e.preventDefault();
    setGeneralFormError(undefined);

    let password;
    try {
      const passwordFormValidationResult = await validationSchema.validate({
        password: passwordRef?.current?.value || '',
        confirmPassword: confirmPasswordRef?.current?.value || ''
      });

      password = passwordFormValidationResult.password;
      setFieldErrors({});
    } catch (e: any) {
      setFieldErrors({
        [e.path]: { message: e.message }
      });

      return;
    }

    try {
      await userClient.setPassword({
        email: email as string,
        token: token as string,
        password: password
      });

      try {
        track('users-setPassword');

        addToast({
          id: htmlIdGenerator()(),
          color: 'success',
          title: `Password set!`
        });

        await sleep(1500);

        const loginRes = await userClient.login(email as string, password);
        if (loginRes.result === '2fa-needed') {
          router.push('/');
        } else {
          const { jwt, user } = loginRes;
          const homeUrl = getUserHomeUrl(user.type) + '#jwt-' + jwt;

          if (new URL(homeUrl).origin === document.location.origin) {
            auth.loginExecute(jwt, user);

            router.push('/');
          } else {
            const redirect =
              window['redirect'] ||
              ((url: string) => {
                // if we do a redirect in cypress, cypress won't be able to clear the local storage of the new
                // domain. this can be super confusing. so don't do unexpected redirects
                if (navigator.userAgent.includes('Cypress')) {
                  throw new Error("Should redirect but won't in Cypress.");
                }

                window.location.href = url;
              });

            redirect(homeUrl);
          }
        }
      } catch (e) {
        // the set password passed and cannot be repeated, so we should
        // leave the form if login somehow fails. the next page will show
        // a login form, which is ok.
        warn('While logging in after setting password: ' + e);

        router.push('/');
      }
    } catch (e: any) {
      setGeneralFormError(e.message);
      setErrorCause(get(e, 'fingerprint[1]'));
    }
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="none" justifyContent="center" alignItems="center">
      <EuiFlexItem style={{ maxWidth: '600px', width: '100%' }}>
        <EuiTitle size="s">
          <h3>Welcome to Scholar's App</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem style={{ maxWidth: '600px', width: '100%' }}>
        <React.Fragment>
          {!isLinkExpired && (
            <>
              <EuiText>
                Please choose a password for <strong>{email || ''}</strong>:
              </EuiText>
              <EuiSpacer size="s" />
            </>
          )}

          <EuiForm
            isInvalid={!!generalFormError || Object.keys(fieldErrors).length > 0}
            error={
              isLinkExpired ? <span dangerouslySetInnerHTML={{ __html: generalFormError || '' }} /> : generalFormError
            }
          >
            {!isLinkExpired && (
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <EuiFormRow
                    label={'Password'}
                    isInvalid={!!fieldErrors['password']}
                    error={fieldErrors['password']?.message}
                    fullWidth={true}
                  >
                    <EuiFieldPassword
                      type={'dual'}
                      autoComplete={'password'}
                      name={'password'}
                      inputRef={passwordRef}
                      isInvalid={!!fieldErrors['password']}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow
                    label={'Confirm Password'}
                    isInvalid={!!fieldErrors['confirmPassword']}
                    error={fieldErrors['confirmPassword']?.message}
                    fullWidth={true}
                  >
                    <EuiFieldPassword
                      type={'dual'}
                      autoComplete={'confirmPassword'}
                      name={'confirmPassword'}
                      inputRef={confirmPasswordRef}
                      isInvalid={!!fieldErrors['confirmPassword']}
                      fullWidth={true}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton onClick={onSetPasswordSubmit} disabled={!!generalFormError}>
                    Submit
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiForm>
        </React.Fragment>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default SetPassword;
