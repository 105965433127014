import React, { ChangeEvent } from 'react';
import 'react-slidedown/lib/slidedown.css';

import Field from '@sharedComponents/base/components/Field';
import NumberBubble from '@sharedComponents/base/components/NumberBubble';
import { MinusIcon, PlusIcon } from '@sharedComponents/base/PlusMinusIcon';
import useAnimatingList from '@sharedComponents/base/useAnimatingList';
import { Autocomplete } from '@sharedComponents/base/components/Autocomplete';
import { RecommenderSchool } from '@sharedContract/UserModel';
import './Eligibility.scss';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function Eligibility({ value, setValue }: { value: string[]; setValue: (value: string[]) => void }) {
  const { renderListItem, animateListItem } = useAnimatingList(50);

  const add = async () => {
    setValue(value.concat(['']));

    animateListItem(value.length, true);
  };

  const remove = async (i: number) => {
    await animateListItem(i, false);

    const newValue = value.slice(0);
    newValue.splice(i, 1);

    setValue(newValue);
  };

  const label = 'To qualify, applicant must...';

  return (
    <Field
      label={label}
      htmlFor="eligibility"
      help='Any other requirements in bullet form, completing the sentence "applicant must...". Please start with a lower-case letter.'
    >
      <div id="eligibility" className="Eligibility">
        <div>
          {atLeastOne(value)
            .map((listItem: string, i: number) => (
              <div className="list-item" key={i}>
                {value.length > 1 ? (
                  <button
                    className="button invisible delete"
                    onClick={e => {
                      e.preventDefault();

                      remove(i);
                    }}
                  >
                    <div className="button icon">
                      <MinusIcon />
                      <div className="label">Remove</div>
                    </div>
                  </button>
                ) : null}
                <NumberBubble number={i + 1} />
                <div className="fields">
                  <input
                    type="text"
                    value={listItem}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newValue = value.slice(0);
                      newValue[i] = e.target.value;

                      setValue(newValue);
                    }}
                  />
                </div>
              </div>
            ))
            .map(renderListItem)}
        </div>

        <button
          className="button invisible add"
          onClick={e => {
            e.preventDefault();
            add();
          }}
        >
          <div className="icon">
            <PlusIcon />
          </div>
          <div className="label">Add another</div>
        </button>
      </div>
    </Field>
  );
}

export function isList(object: any) {
  return typeof object == 'object' && object.length != null;
}

function atLeastOne(items: string[]) {
  if (items.length) {
    return items;
  } else {
    return [''];
  }
}

function EligibilityHighSchool({
  value,
  setValue,
  applicationClient
}: {
  value: Array<RecommenderSchool>;
  setValue: (value: RecommenderSchool[]) => void;
  applicationClient: ApplicationClient;
}) {
  const { renderListItem, animateListItem } = useAnimatingList(50);

  const add = async () => {
    setValue((value || []).concat([{ id: 0, name: '' }]));

    animateListItem((value || []).length, true);
  };

  const remove = async (i: number) => {
    await animateListItem(i, false);

    const newValue = (value || []).slice(0);
    newValue.splice(i, 1);

    setValue(newValue);
  };

  const label = 'To qualify, the applicant must attend the following High School(s)...';

  return (
    <Field
      label={label}
      htmlFor="eligibility"
      help="This scholarship is only open to students who attend the High School(s) listed above. If the scholarship is open to students from any High School, please leave the field blank"
    >
      <div id="eligibility" className="Eligibility">
        <div>
          {atLeastOne((value || []).map(i => i.name))
            .map((listItem: string, i: number) => (
              <div className="list-item" key={i}>
                {(value || []).length > 1 ? (
                  <button
                    className="button invisible delete"
                    onClick={e => {
                      e.preventDefault();

                      remove(i);
                    }}
                  >
                    <div className="icon">
                      <MinusIcon />
                      <div className="label">Remove</div>
                    </div>
                  </button>
                ) : null}
                <NumberBubble number={i + 1} />
                <div className="fields">
                  <Autocomplete
                    id="highschoolname"
                    value={value?.[i] || {}}
                    autocomplete="schools"
                    onChange={option => {
                      if (
                        option &&
                        option.id != null &&
                        !Object.keys(value.find(i => i.id === option.id) || {}).length
                      ) {
                        const { id, name, state } = option;
                        const model: RecommenderSchool = { id, name: `${name}, ${state}` };
                        setValue([...(value ? value.filter(i => i.id) : []), model]);
                      }
                    }}
                    applicationClient={applicationClient}
                  />
                </div>
              </div>
            ))
            .map(renderListItem)}
        </div>

        <button
          className="button invisible add"
          onClick={e => {
            e.preventDefault();
            add();
          }}
        >
          <div className="icon">
            <PlusIcon />
          </div>
          <div className="label">Add another</div>
        </button>
      </div>
    </Field>
  );
}
export { EligibilityHighSchool };
