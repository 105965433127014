export enum SELECTION_ACTIONS {
  CLEAR_ALL = 'CLEAR_ALL',
  SELECT_ALL = 'SELECT_ALL',
  ADD_SELECTION = 'ADD_SELECTION',
  REMOVE_SELECTION = 'REMOVE_SELECTION',
  DELETE_SELECTION = 'DELETE_SELECTION'
}

export enum DATA_ACTIONS {
  UPDATE_DATA = 'UPDATE_DATA',
  UPDATE_SORTING = 'UPDATE_SORTING',
  CLEAR_ASSIGNMENTS = 'CLEAR_ASSIGNMENTS',
  ASSIGN_SINGLE = 'ASSIGN_SINGLE',
  ASSIGN_SELECTED = 'ASSIGN_SELECTED',
  ASSIGN_SELECTED_TO_ALL = 'ASSIGN_SELECTED_TO_ALL'
}
