import { useQuery } from 'react-query';
import { useUserClient } from '@sharedComponents/selectors/useUserClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'usersOfDonor';

function useUsersOfDonor(donorId) {
  const userClient = useUserClient();
  const query = {
    ...useQuery<any[], Error>([CURRENT_QUERY_KEY, donorId], () => userClient.getUsersOfDonor(donorId), {
      staleTime: 1000 * 60 * 5
    })
  };

  return query;
}

const usersOfDonorQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useUsersOfDonor };
export default usersOfDonorQuery;
