import React from 'react';
import Thumbnail from './Thumbnail';
import NodeProps from './FormNodeProps';

export default function FormFieldValuePdf(props: NodeProps & { value: any }) {
  const { value, fullApplication, onError } = props;

  if (!value) {
    return '—';
  } else {
    const { id, name } = value;

    return <Thumbnail fileName={name} fileId={id} applicationId={fullApplication?.id} onError={onError} />;
  }
}
