import { useMutation, useQueryClient } from 'react-query';
import { merge } from 'lodash';

import { DraftScholarshipModelShape } from '@sharedComponents/models';
import { ScholarshipModelSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'draftScholarshipEntry';

// mutation to update entry
const useMutateDraftScholarshipEntry = () => {
  const queryClient = useQueryClient();
  const scholarshipClient = useScholarshipClient();

  return {
    ...useMutation<
      Partial<DraftScholarshipModelShape>, // returned result result by our api
      Error, // error type
      Partial<DraftScholarshipModelShape>
      // parameters accepted by mutation
    >(
      scholarshipEntry => {
        return scholarshipClient.upsertDraftScholarshipEntry(
          merge(ScholarshipModelSchema.getDefault(), scholarshipEntry)
        );
      },
      {
        onSuccess: data => {
          // user profile update request returns updated profile, so we can refresh our query
          queryClient.setQueryData([CURRENT_QUERY_KEY, data.id], data);
        }
      }
    )
  };
};

const draftScholarshipEntryQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useMutateDraftScholarshipEntry
};

export default draftScholarshipEntryQuery;
