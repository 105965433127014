import { Dictionary } from 'lodash';
import { Page } from './interfaces/CommonData.interface';
import { ApplicationModelShape, RecurringAwardModelShape, UserModelShape } from './models';

export enum APPLICATION_TYPE {
  UNREVIEWED = 'UNREVIEWED',
  REVIEWED = 'REVIEWED',
  REVIEWED_PAST_ROUNDS = 'REVIEWED_PAST_ROUNDS',
  CANDIDATES = 'CANDIDATES',
  ELIMINATED = 'ELIMINATED',
  NODOCUMENTS = 'NODOCUMENTS',
  NOTSUBMITTED = 'NOTSUBMITTED',
  QUALIFIED = 'QUALIFIED',
  AWARDED = 'AWARDED',
  RECURRING = 'RECURRING'
}

export interface ScholarshipReviewState {
  activeCategory?: APPLICATION_TYPE;
}

/**
 * Attempt to typize legacy part of app, This has to be improved and reworked
 */
export type ApplicationsToScholarship = {
  [APPLICATION_TYPE.UNREVIEWED]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.REVIEWED]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.REVIEWED_PAST_ROUNDS]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.CANDIDATES]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.ELIMINATED]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.NODOCUMENTS]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.NOTSUBMITTED]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.QUALIFIED]: Page<UserModelShape> | number;
  [APPLICATION_TYPE.AWARDED]: Page<ApplicationModelShape> | number;
  [APPLICATION_TYPE.RECURRING]: Dictionary<[RecurringAwardModelShape, ...RecurringAwardModelShape[]]> | number;
  applicationCycles?: any[];
};
