import React from 'react';

import CollegeEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/CollegeEligibilitySelection';
import ResidencyEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/ResidencyEligibilitySelection';
import SchoolEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/SchoolEligibilitySelection';
import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel } from '@elastic/eui';
import {
  ButtonsChoiceField,
  CheckboxField,
  ComboboxField,
  DateRangeField,
  FieldList,
  NumberField,
  TextAreaField,
  TextField
} from '@sharedComponents/formElements';
import FileField from '@sharedComponents/formElements/combined/FileField';
import academic_years from '@sharedComponents/models/enumValues/academic_years';
import athletics from '@sharedComponents/models/enumValues/athletics';
import college_types from '@sharedComponents/models/enumValues/college_types';
import ethnicities from '@sharedComponents/models/enumValues/ethnicities';
import genders from '@sharedComponents/models/enumValues/genders';
import miscellaneous from '@sharedComponents/models/enumValues/miscellaneous';
import religious from '@sharedComponents/models/enumValues/religious';
import selfIdentification from '@sharedComponents/models/enumValues/selfIdentification';
import veteran_status from '@sharedComponents/models/enumValues/veteran_status';
import { ScholarshipModelSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep14 = ({ isFreezed }) => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiPanel className="ScholarshipListing-panel_inner" hasBorder={false} hasShadow={false}>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <TextField
                fieldName={'name'}
                fieldProps={{ fullWidth: true, disabled: isFreezed }}
                rowProps={{ label: 'Scholarship Name', fullWidth: true, display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <TextAreaField
                fieldName={'description'}
                fieldProps={{ fullWidth: true, disabled: isFreezed }}
                rowProps={{ label: 'Description', fullWidth: true, display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <TextField
                fieldName={'apply_url'}
                rowProps={{ label: 'Scholarship Application URL', fullWidth: true, display: 'columnCompressed' }}
                fieldProps={{ fullWidth: true, autoComplete: 'off', placeholder: 'https://', disabled: isFreezed }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <FileField
                fieldName={'data.application_example'}
                rowProps={{
                  label: 'Application Example',
                  helpText: '',
                  display: 'columnCompressed',
                  fullWidth: true
                }}
                fieldProps={{
                  accept: '.pdf',
                  disabled: isFreezed
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label={'Start Date and Deadline'} display="columnCompressed" fullWidth>
                <DateRangeField
                  fieldName={['data.startDate', 'deadlineDate']}
                  fieldProps={{
                    inline: false,
                    disabled: isFreezed,
                    popoverPlacement: 'bottom' // counselor edit modal overlap
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <NumberField
                fieldName={'candidates_limit'}
                rowProps={{
                  label: 'Candidates Limit',
                  display: 'columnCompressed',
                  fullWidth: true,
                  helpText:
                    'Close application cycle instantly when a certain number of completed applications are received.'
                }}
                fieldProps={{
                  fullWidth: true,
                  autoComplete: 'off',
                  disabled: isFreezed
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <NumberField
                fieldName={'award_amount_to'}
                rowProps={{ label: 'Total Award Amount', display: 'columnCompressed', fullWidth: true }}
                fieldProps={{
                  prepend: '$',
                  fullWidth: true,
                  autoComplete: 'off',
                  disabled: isFreezed
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <NumberField
                fieldName={'data.award_recipients_number'}
                rowProps={{ label: 'Number of Award Recipients', display: 'columnCompressed', fullWidth: true }}
                fieldProps={{
                  fullWidth: true,
                  autoComplete: 'off',
                  disabled: isFreezed
                }}
              />
            </EuiFlexItem>
            {/** Step 6 */}
            <EuiFlexItem>
              <EuiFormRow
                label={'What are some common questions that students ask about your scholarship?'}
                display="columnCompressed"
                fullWidth
              >
                <FieldList
                  fieldName={'data.faqQustionAnswer'}
                  recordDefaultValue={{ question: '', answer: '' }}
                  minListItems={1}
                  disabled={isFreezed}
                  fieldFactoryFn={(fieldNamePrefix, register, field) => {
                    return (
                      <EuiFlexGroup direction="column" gutterSize="xs">
                        <EuiFlexItem>
                          <EuiFieldText
                            type="text"
                            name={`${fieldNamePrefix}question`}
                            inputRef={register}
                            defaultValue={field['question']}
                            placeholder={'Question'}
                            disabled={isFreezed}
                            fullWidth
                          />
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiFieldText
                            type="text"
                            name={`${fieldNamePrefix}answer`}
                            inputRef={register}
                            defaultValue={field['answer']}
                            placeholder={'Answer'}
                            disabled={isFreezed}
                            fullWidth
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    );
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            {/** Step 7 */}
            <EuiFlexItem>
              <ButtonsChoiceField
                fieldName={'data.ApplicationMaterial.essay'}
                buttons={[
                  {
                    value: 'Yes',
                    label: 'YES'
                  },
                  {
                    value: 'No',
                    label: 'NO'
                  },
                  {
                    value: null,
                    label: 'NOT SURE'
                  }
                ]}
                viewMode="row"
                fieldProps={{ size: 's', isDisabled: isFreezed }}
                rowProps={{ label: 'Is essay required?', fullWidth: true, display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            {/** Step 8 */}
            <EuiFlexItem>
              <ButtonsChoiceField
                fieldName={'data.ApplicationMaterial.letterofRecommendation'}
                buttons={[
                  {
                    value: 'Yes',
                    label: 'YES'
                  },
                  {
                    value: 'No',
                    label: 'NO'
                  },
                  {
                    value: null,
                    label: 'NOT SURE'
                  }
                ]}
                viewMode="row"
                fieldProps={{ size: 's', isDisabled: isFreezed }}
                rowProps={{ label: 'Is recommendation letter required?', fullWidth: true, display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            {/** Step 9 */}
            <EuiFlexItem>
              <ButtonsChoiceField
                fieldName={'data.ApplicationMaterial.transcript'}
                buttons={[
                  {
                    value: 'Yes',
                    label: 'YES'
                  },
                  {
                    value: 'No',
                    label: 'NO'
                  },
                  {
                    value: null,
                    label: 'NOT SURE'
                  }
                ]}
                viewMode="row"
                fieldProps={{ size: 's', isDisabled: isFreezed }}
                rowProps={{ label: 'Is transcript required?', fullWidth: true, display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            {/** Step 10 */}
            <EuiFlexItem>
              <SchoolEligibilitySelection
                rowProps={{ display: 'columnCompressed', fullWidth: true }}
                fieldProps={{ isDisabled: isFreezed }}
              />
            </EuiFlexItem>
            {/** Step 11 */}
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.collegeTypes'}
                options={college_types}
                fieldProps={{
                  fullWidth: true,
                  placeholder: 'Select one or more',
                  isDisabled: isFreezed
                }}
                rowProps={{ label: 'College Study Rate', display: 'columnCompressed', fullWidth: true }}
              />
              <CollegeEligibilitySelection
                fieldProps={{ isDisabled: isFreezed }}
                rowProps={{ display: 'columnCompressed', fullWidth: true }}
              />
            </EuiFlexItem>
            {/** Step 12 */}
            <EuiFlexItem>
              <ResidencyEligibilitySelection
                rowProps={{ display: 'columnCompressed', fullWidth: true }}
                fieldProps={{ isDisabled: isFreezed }}
              />
            </EuiFlexItem>
            {/** Step 13 */}
            <EuiFlexItem>
              <EuiFormRow label={'GPA'} display="columnCompressed" fullWidth>
                <EuiFlexGroup direction="row">
                  <EuiFlexItem grow={false}>
                    <NumberField
                      fieldName={'data.gpaFrom'}
                      fieldProps={{
                        fullWidth: false,
                        step: 0.1,
                        disabled: isFreezed
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem style={{ textAlign: 'center', lineHeight: '38px' }}>-</EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <NumberField
                      fieldName={'data.gpaTo'}
                      fieldProps={{ fullWidth: false, step: 0.1, disabled: isFreezed }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.genders'}
                options={genders}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the gender of a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Gender', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.ethnicities'}
                options={ethnicities}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the ethnicity or nationality of a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Ethnicity', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.religious'}
                options={religious}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the religious or spiritual identity of a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Religious / Spiritual Identity', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.selfIdentification'}
                options={selfIdentification}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the disability of a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{
                  fullWidth: true,
                  label: 'Self-Identification of Disability:',
                  display: 'columnCompressed'
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.veteranStatus'}
                options={veteran_status}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the veteran status or affiliation of a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Veteran Status/Affiliation:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.athletics'}
                options={athletics}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the sport played by a student who can apply for this scholarship.',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Athletics:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.miscellaneous'}
                options={miscellaneous}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: '',
                  fullWidth: true,
                  isDisabled: isFreezed
                }}
                rowProps={{ fullWidth: true, label: 'Other:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.academicYears'}
                options={academic_years}
                fieldProps={{ placeholder: 'Select one or more', fullWidth: true, isDisabled: isFreezed }}
                rowProps={{ fullWidth: true, label: 'Current Grade:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>

            <EuiFlexItem>
              <CheckboxField
                fieldName="data.lowIncome"
                fieldProps={{ label: 'Low income', disabled: isFreezed }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    "Click the checkbox if a student's family is required to have low income, to apply for this scholarship."
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <CheckboxField
                fieldName="data.communityService"
                fieldProps={{ label: 'Community service', disabled: isFreezed }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    'Click the checkbox if a student is required to have participated in community service, to apply for this scholarship.'
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <CheckboxField
                fieldName="data.firstGen"
                fieldProps={{ label: 'First person in family to attend college', disabled: isFreezed }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    'Click the checkbox if a student is required to be the first member of their family to attend college, to apply for this scholarship.'
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep14.validationRules = ScholarshipModelSchema.clone();

export default SelfListingScholarshipStep14;
