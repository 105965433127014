import React, { MouseEvent } from 'react';
import './FormNotificationStatus.scss';
import warn from '../../base/warn';
import { UserError } from '../../base/useErrorReporter';
import ApplicationModel, {
  NotificationStatus,
  CounselorNotificationStatus,
  RecommenderNotificationStatus
} from '@sharedClients/types/ApplicationModel';
import FormNode from '../../model/FormNode';
import SupportModel from '@sharedClients/types/SupportModel';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import PersonalizedInvitationModal from './FormNotificationStatus/PersonalizedInvitationModal';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

const FormNotificationStatus = (props: {
  node: FormNode;
  application: ApplicationModel;
  supports: SupportModel[] | null;
  applicationClient: ApplicationClient;
  onError: (e: Error) => void;
  clearError: () => void;
  objectName: string;
  actorName: string;
  sendNotification: ({
    name,
    email,
    phone,
    customContent,
    application,
    onError,
    applicationClient,
    setCounselorNotificationStatus,
    setRecommenderNotificationStatus
  }: {
    name: string;
    email: string;
    phone: string;
    customContent?: string;
    application: ApplicationModel;
    onError: (e: Error) => void;
    applicationClient: ApplicationClient;
    setCounselorNotificationStatus: (status: CounselorNotificationStatus) => void;
    setRecommenderNotificationStatus: (status: RecommenderNotificationStatus) => void;
  }) => Promise<void>;
  setCounselorNotificationStatus: (status: CounselorNotificationStatus) => void;
  setRecommenderNotificationStatus: (status: RecommenderNotificationStatus) => void;
  getStatus: (
    email: string,
    props: { supports: SupportModel[] | null; application: ApplicationModel }
  ) => NotificationStatus;
}) => {
  const {
    node,
    application,
    setCounselorNotificationStatus,
    setRecommenderNotificationStatus,
    applicationClient,
    onError,
    clearError,
    objectName = 'PDF',
    actorName = 'person',
    sendNotification,
    getStatus
  } = props;

  const createModal = useModalCreate();
  const email = getSiblingFieldValue('email');
  const phone = getSiblingFieldValue('phone');

  function getSiblingFieldValue(fieldId: string) {
    const field = node.parent!.parent!.getField(fieldId);

    if (field) {
      return field.getValue(application);
    } else {
      warn(`Cannot find field ${fieldId}.`);
    }
  }

  async function notifyPerson(e?: MouseEvent, customContent?) {
    if (e) {
      e.preventDefault();
    }

    clearError();

    const name = getSiblingFieldValue('name');

    if (name && email) {
      sendNotification({
        name,
        email,
        phone: phone || '',
        customContent,
        application,
        onError,
        applicationClient,
        setCounselorNotificationStatus,
        setRecommenderNotificationStatus
      });
    } else {
      onError(new UserError('Fill in contact information first.'));
    }
  }

  const fieldName = lastElement(node.parent!.getValuePath());
  const isFirst = isNaN(fieldName) || fieldName === '0';

  let status: NotificationStatus | null = null;

  if (email) {
    status = getStatus(email, props);
  }

  const isApplicationCreated = application.id != null;

  const onPersonalizedInvitationHandler = () => {
    createModal(({ closeModal }) => {
      const onModalSubmitHandler = async (content?: string) => {
        if (!content) {
          return closeModal();
        }

        // we submit some text from modal, send personalized invitation then
        await notifyPerson(undefined, content);
        closeModal();
      };

      return (
        <PersonalizedInvitationModal
          onModalSubmit={onModalSubmitHandler}
          title={<>Write a personalized invite to your {actorName}</>}
        />
      );
    });
  };

  return (
    <div className="FormNotificationStatus">
      {status === 'unsent' && isApplicationCreated ? (
        <EuiFlexGroup direction="column" gutterSize="none">
          <EuiFlexItem>
            <EuiFlexGroup direction="row" gutterSize="xs">
              <EuiFlexItem grow={false}>
                <EuiButton color="primary" size="s" onClick={notifyPerson} fullWidth={false}>
                  Send Standard Invite
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton color="primary" size="s" onClick={onPersonalizedInvitationHandler} fullWidth={false}>
                  Send Personalized Invite
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            {isFirst ? (
              <div className="help">
                Send the {actorName} a link to upload a {objectName}.
              </div>
            ) : null}
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : null}
      {status ? (
        <EuiText size="s" color={status === 'undeliverable' ? 'danger' : 'success'}>
          {
            {
              sent: 'Notified. Waiting for reply.',
              submitted: `Has submitted ${objectName}.`,
              partial: `Partial ${objectName} (not submitted).`,
              undeliverable: 'Wrong email; delivery failed'
            }[status]
          }
        </EuiText>
      ) : null}
    </div>
  );
};

function lastElement(list: any[]) {
  return list[list.length - 1];
}

export default FormNotificationStatus;
