import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { isProd } from '@sharedClients/getServiceUrl';

type PathParamsType = Record<string, any>;

declare global {
  interface Window {
    dataLayer: Record<string, any> | undefined;
    [key: string]: any;
  }
}

interface GoogleAnalyticsProps extends RouteComponentProps<PathParamsType> {
  gtmId: string;
}

const componentReceivingRouter: FunctionComponent<PropsWithChildren<GoogleAnalyticsProps>> = ({
  gtmId,
  children,
  history
}) => {
  useEffect(() => {
    const ga = window['ga'];

    if (ga) {
      ga('set', 'page', history.location.pathname);
      ga('send', 'pageview');
    }
  }, [history.location]);

  return (
    <React.Fragment>
      {children}
      <GoogleTagManagerInit gtmId={gtmId} />
    </React.Fragment>
  );
};

const GoogleTagManager = withRouter(componentReceivingRouter);

export function GoogleTagManagerInit({ gtmId }) {
  useEffect(() => {
    if (!window['Cypress'] && isProd()) {
      const tagManagerArgs = {
        gtmId: gtmId
      };

      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return null;
}

export default GoogleTagManager;
