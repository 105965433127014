import { ApplicationModelShape } from '@sharedComponents/models';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import { useQuery } from 'react-query';

// unique key for this query
const CURRENT_QUERY_KEY = 'myApplications';

function useMyApplications(opts?) {
  const applicationClient = useApplicationClient();
  return {
    ...useQuery<ApplicationModelShape[], Error>(CURRENT_QUERY_KEY, () => applicationClient.getMyApplications(), {
      ...opts,
      staleTime: 1000 * 30
    })
  };
}

// based on base query, selecting only submitted applications
const useMySubmittedApplications = () => {
  return {
    ...useMyApplications({
      select: (data: ApplicationModelShape[]) => data.filter(a => a.submitted_at != null)
    })
  };
};

// not submmitted applications selector
const useMyDraftApplications = () => {
  return {
    ...useMyApplications({
      select: (data: ApplicationModelShape[]) => data.filter(a => !a.submitted_at)
    })
  };
};

const myApplicationsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useMyApplications,
  useMySubmittedApplications,
  useMyDraftApplications
};

export default myApplicationsQuery;
