import React, { useEffect, useState } from 'react';

import {
  EuiButton,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableRowCellCheckbox,
  EuiTitle
} from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import {
  Invitation,
  INVITATION_STATUS,
  InvitationConfirmation
} from '@sharedComponents/interfaces/Invitation.interface';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

const ChildrenInvitation = ({
  applicationClient,
  childrenData,
  confirmationCallback,
  returnCallback
}: {
  applicationClient: ApplicationClient;
  childrenData: { name: string; email: string }[]; // previously entered children emails
  confirmationCallback: (extraRegistrationData: InvitationConfirmation[]) => void;
  returnCallback: () => void;
}) => {
  const [isLoading, setLoading] = useState(true);
  const [existingApplicants, setExistingApplicants] = useState<string[]>([]); // holds children emails which are supposed to get invites (backend wise)
  const [optionalInvitesList, updateOptionalInvitesList] = useState<string[]>([]); // holds invitations which parent decided to process

  useEffect(() => {
    if (!childrenData.length) {
      setLoading(false);

      confirmationCallback([]);
      return;
    }

    // emails transformed into Invitation obj array
    const invitations: Invitation[] = childrenData.map(invitation => ({
      email: invitation.email,
      name: invitation.name,
      userType: USER_TYPE.APPLICANT
    }));

    applicationClient.verifyInvitations(invitations).then(verificationStatus => {
      setLoading(false);

      if (verificationStatus?.invitationStatuses) {
        const { invitationStatuses } = verificationStatus;

        // students which are registered, supposed to receive confirmation emails
        setExistingApplicants([
          ...invitationStatuses
            .filter(verifiedInvitation => verifiedInvitation.status === INVITATION_STATUS.USER_EXIST)
            .map(invitation => invitation.email)
        ]);

        // update optional invites, by default it has all children emails which are not yet registered into SA
        updateOptionalInvitesList([
          ...invitationStatuses
            .filter(verifiedInvitation => verifiedInvitation.status === INVITATION_STATUS.USER_NOT_EXIST)
            .map(invitation => invitation.email)
        ]);
      }
    });
  }, []);

  // we need to finish registration, as well as provide email lists for letters
  const handleConfirm = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);

    const invitationConfirmations: InvitationConfirmation[] = [
      ...existingApplicants.map(email => ({
        name: '', // not collected during this flow
        status: INVITATION_STATUS.USER_EXIST,
        email: email,
        isInviting: true,
        userType: USER_TYPE.APPLICANT
      })),
      ...optionalInvitesList.map(email => ({
        name: '', // not collected during this flow
        status: INVITATION_STATUS.USER_NOT_EXIST,
        email: email,
        isInviting: true,
        userType: USER_TYPE.APPLICANT
      }))
    ];

    confirmationCallback(invitationConfirmations);
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiTitle size="s" className={'m-dark'}>
          <h3>Confirmation step</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {childrenData.length ? (
          <>
            Thanks for signing up for Scholar's App!
            <br />
            Let's invite your student(s) to the platform.
          </>
        ) : (
          <>Thanks for signing up for Scholar's App!</>
        )}
      </EuiFlexItem>

      <EuiFlexItem style={{ justifyContent: 'center' }}>
        {isLoading || !childrenData.length ? (
          <EuiLoadingSpinner size="l" style={{ alignSelf: 'center' }} />
        ) : (
          <EuiTable>
            <EuiTableHeader>
              <EuiTableHeaderCell>Email</EuiTableHeaderCell>
              <EuiTableHeaderCell>Status</EuiTableHeaderCell>
            </EuiTableHeader>
            <EuiTableBody>
              {childrenData.map((invitation, i) => (
                <EuiTableRow key={i}>
                  <EuiTableRowCell>{invitation.email}</EuiTableRowCell>
                  <EuiTableRowCellCheckbox>
                    {existingApplicants.includes(invitation.email) ? (
                      <EuiCheckbox
                        id={invitation.email}
                        checked={true}
                        disabled={true}
                        label={'Already registered'}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onChange={() => {}}
                      />
                    ) : (
                      <EuiCheckbox
                        id={invitation.email}
                        label={'Send invitation'}
                        checked={optionalInvitesList.includes(invitation.email)}
                        onChange={e => {
                          // update invitation status for those email addresses which are optional
                          if (e.target.checked) {
                            updateOptionalInvitesList([...optionalInvitesList, invitation.email]);
                          } else {
                            updateOptionalInvitesList([
                              ...optionalInvitesList.filter(listEmail => listEmail !== invitation.email)
                            ]);
                          }
                        }}
                      />
                    )}
                  </EuiTableRowCellCheckbox>
                </EuiTableRow>
              ))}
            </EuiTableBody>
          </EuiTable>
        )}
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSpacer />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row">
          <EuiFlexItem>
            <EuiButton
              size="s"
              onClick={returnCallback}
              isDisabled={childrenData.length === 0 || isLoading}
              fullWidth={false}
            >
              Back
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButton
              size="s"
              color="primary"
              onClick={handleConfirm}
              isDisabled={childrenData.length === 0 || isLoading}
              fullWidth={false}
              fill
            >
              Confirm
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default ChildrenInvitation;
