import { EuiTabbedContent, EuiText } from '@elastic/eui';

const FormControlBarContent = () => {
  return (
    <EuiTabbedContent
      tabs={[
        {
          id: 'preview',
          name: 'Preview',
          content: <EuiText>TODO</EuiText>
        }
      ]}
    />
  );
};

export default FormControlBarContent;
