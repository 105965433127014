import { useQuery, UseQueryOptions } from 'react-query';
import { DraftScholarshipModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'unfinishedDraftScholarship';

function useUnfinishedDraftScholarshipEntry(
  options: UseQueryOptions<DraftScholarshipModelShape, Error, DraftScholarshipModelShape> = {}
) {
  const scholarshipClient = useScholarshipClient();
  const query = {
    ...useQuery<DraftScholarshipModelShape, Error>(
      [CURRENT_QUERY_KEY],
      () => scholarshipClient.getMostRecentUnfinishedDraftScholarship(),
      {
        ...options
      }
    )
  };

  return query;
}

const unfinishedDraftScholarshipQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useUnfinishedDraftScholarshipEntry };
export default unfinishedDraftScholarshipQuery;
