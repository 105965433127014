import { UserProfile } from '@sharedComponents/interfaces/UserData.interface';
import { selector, useRecoilValue, useSetRecoilState } from 'recoil';

import { loggedInUserState, LoggedInUserStateType } from '../atoms/loggedInUserState';

const loggedInUserSelector = selector({
  key: 'loggedInUserSelector',

  get: ({ get }) => {
    const { user } = get(loggedInUserState);
    return user;
  }
});

export function useLoggedInUser() {
  return useRecoilValue(loggedInUserSelector);
}

const currentUserJwtSelector = selector({
  key: 'authStatusSelector',

  get: ({ get }) => {
    const { jwt } = get(loggedInUserState);
    return jwt;
  }
});

export function useAuthStatus() {
  return !!useRecoilValue(currentUserJwtSelector);
}

export function useCurrentUserJWT() {
  return useRecoilValue(currentUserJwtSelector);
}

// composite for auth controll
export const authSelector = selector({
  key: 'authSelector',

  get: ({ get }) => {
    return get(loggedInUserState);
  },
  set: ({ set }, newValue: LoggedInUserStateType | any) => {
    if (!newValue?.user || !newValue?.jwt) {
      // logout - make it separated hook?
      set(loggedInUserState, {
        jwt: null,
        user: undefined
      });
    } else {
      // login
      set(loggedInUserState, {
        jwt: newValue.jwt,
        user: newValue.user
      });
    }
  }
});

// ex-onProfileChange
export function useLoggedInUserProfileUpdate() {
  const setAuthState = useSetRecoilState(authSelector);

  return (userData: UserProfile['data']) => {
    setAuthState((authState: LoggedInUserStateType) => {
      return {
        jwt: authState.jwt,
        user: { ...authState.user, data: userData }
      };
    });
  };
}
