import React, { ChangeEvent, FocusEvent } from 'react';
import './RangeInput.scss';

export default function RangeInput(props: {
  id: string;
  value: number[];
  onChange: (e: any) => any;
  onBlur: (e: FocusEvent<HTMLInputElement>) => any;
}) {
  const { id, value, onChange, onBlur } = props;

  function onElementChange(index: number) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = value.slice(0);
      const number = isNaN(parseFloat(e.target.value)) ? null : parseFloat(e.target.value);

      newValue[index] = number as number;

      onChange({ target: { value: newValue } });
    };
  }

  return (
    <div className="RangeInput">
      <div className="text">Between</div>
      <input
        id={id}
        type="number"
        value={value[0] != null ? value[0] : ''}
        onChange={onElementChange(0)}
        onBlur={onBlur}
      />
      <div className="text">and</div>
      <input
        id={id + 'To'}
        type="number"
        value={value[1] != null ? value[1] : ''}
        onChange={onElementChange(1)}
        onBlur={onBlur}
      />
    </div>
  );
}
