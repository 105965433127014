import { useState } from 'react';

import { EuiButton, EuiContextMenuItem, EuiContextMenuPanel, EuiPopover } from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';

const RecurringAwardsButton = ({ scholarshipId }) => {
  const router = useRouter();
  const [isPopoverOpen, setPopover] = useState(false);

  const onMassStandaloneApplicationClick = () => {
    closePopover();

    router.push('/recurring-awards/' + scholarshipId);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const items = [
    <EuiContextMenuItem key="share" icon="starPlusFilled" onClick={onMassStandaloneApplicationClick}>
      Upload recurring awards CSV
    </EuiContextMenuItem>
  ];

  const onButtonClick = () => {
    setPopover(true);
  };

  return (
    <EuiPopover
      button={
        <EuiButton
          color="accent"
          size="s"
          iconSide="right"
          iconType="boxesVertical"
          aria-label="More"
          onClick={onButtonClick}
        >
          Setup recurring awards
        </EuiButton>
      }
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel size="s" items={items} />
    </EuiPopover>
  );
};

export default RecurringAwardsButton;
