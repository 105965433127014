import { useState } from 'react';

import { postAsync } from '@donors/users/postAsync';
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { ApplicationClient } from '@sharedClients/ApplicationClient';
import { getServiceUrl } from '@sharedClients/getServiceUrl';
import track from '@sharedComponents/base/track';
import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';
import { useCurrentUserJWT } from '@sharedComponents/selectors/loggedInUserSelectors';
import UserModel, { DonorUserData } from '@sharedContract/UserModel';
import { USER_TYPE } from '@sharedComponents/constants';
import StandaloneApplicationButton from './Operations/StandaloneApplicationButton';
import useActiveCategory from './hooks/useActiveCategory';
import RecurringAwardsButton from './Operations/RecurringAwardsButton';

export default function Operations({
  applicationClient,

  scholarshipId,
  user,
  searchText,
  applicationCycle
}: {
  applicationClient: ApplicationClient;

  scholarshipId: number;
  user: UserModel;
  searchText: string;
  applicationCycle: number;
}) {
  const { value: activeCategory } = useActiveCategory();
  const jwt = useCurrentUserJWT();

  const showDownloadButtons =
    (user.type === USER_TYPE.ADMIN || (user.type === USER_TYPE.DONOR && (user.data as DonorUserData).isPremium)) &&
    (activeCategory === APPLICATION_TYPE.CANDIDATES ||
      activeCategory === APPLICATION_TYPE.NODOCUMENTS ||
      activeCategory === APPLICATION_TYPE.QUALIFIED ||
      activeCategory === APPLICATION_TYPE.NOTSUBMITTED);

  const [isCandidateDataLoading, setIsCandidateDataLoading] = useState(false);
  const [isScoresLoading, setIsScoresLoading] = useState(false);

  const showStandaloneApplicationsButton =
    user.type === USER_TYPE.ADMIN && activeCategory === APPLICATION_TYPE.CANDIDATES;

  const showRecurringAwardsButton =
    (user.type === USER_TYPE.ADMIN || (user.type === USER_TYPE.DONOR && (user.data as DonorUserData).isPremium)) &&
    activeCategory === APPLICATION_TYPE.RECURRING;

  return (
    <EuiFlexGroup justifyContent="flexEnd" gutterSize="s" responsive={false}>
      {showStandaloneApplicationsButton ? (
        <EuiFlexItem grow={true}>
          <StandaloneApplicationButton scholarshipId={scholarshipId} />
        </EuiFlexItem>
      ) : null}

      {showRecurringAwardsButton ? (
        <EuiFlexItem grow={false}>
          <RecurringAwardsButton scholarshipId={scholarshipId} />
        </EuiFlexItem>
      ) : null}

      {showDownloadButtons ? (
        <>
          <EuiFlexItem grow={false}>
            <EuiButton
              size="s"
              onClick={async () => {
                setIsCandidateDataLoading(true);
                track('applications-download');

                try {
                  await postAsync(
                    `${getServiceUrl(
                      'applications'
                    )}/v1/applications/to-scholarship/${scholarshipId}/${activeCategory.toLocaleLowerCase()}.tsv?applicationCycle=${applicationCycle}&searchText=${searchText}`,
                    { jwt: jwt || '' }
                  );
                  // eslint-disable-next-line no-empty
                } catch (e) {}

                setIsCandidateDataLoading(false);
              }}
              isLoading={isCandidateDataLoading}
            >
              Download Candidate Data
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={null}>
            <EuiButton
              onClick={() => {
                setIsScoresLoading(true);
                applicationClient.downloadScores(scholarshipId).finally(() => setIsScoresLoading(false));
              }}
              size="s"
              color={'text'}
              isLoading={isScoresLoading}
            >
              Download Review Round Data
            </EuiButton>
          </EuiFlexItem>
        </>
      ) : null}
    </EuiFlexGroup>
  );
}
