import { useQuery, UseQueryOptions } from 'react-query';

import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import { CounselorNotificationStatusModelShape, ScholarshipModelShape } from '@sharedComponents/models';

// unique key for this query
const CURRENT_QUERY_KEY = 'transcriptRequests';

export type TranscriptRequestsDataType = CounselorNotificationStatusModelShape & {
  scholarship?: ScholarshipModelShape; // scholarship may be removed
};

function useCounselorTranscriptsRequests(
  options: UseQueryOptions<TranscriptRequestsDataType[], Error, TranscriptRequestsDataType[]> = {}
) {
  const applicationClient = useApplicationClient();

  return {
    ...useQuery<TranscriptRequestsDataType[], Error>(
      [CURRENT_QUERY_KEY],
      () => applicationClient.getApplicantsNeedingTranscript(),
      {
        staleTime: 1000 * 15,
        ...options
      }
    )
  };
}

const transcriptRequestsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useCounselorTranscriptsRequests
};

export default transcriptRequestsQuery;
