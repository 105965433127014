import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { SELF_LISTING_LAST_STEP_NUMBER } from '@sharedComponents/interfaces/Scholarships.interface';

const stepsWithoutControls = [2, 7, 8, 9];
const SelfListingFormNavigation = ({
  isFreezed,
  isLoading,
  currentStep,
  returnBackHandler,
  nextStepHandler,
  previouslyPassedStep,
  isDraftView
}: {
  isFreezed: boolean;
  isLoading: boolean;
  currentStep: number;
  returnBackHandler;
  nextStepHandler;
  previouslyPassedStep?: number;
  isDraftView: boolean;
}) => {
  return (
    <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false}>
        {isDraftView ? (
          <EuiButtonEmpty onClick={returnBackHandler} iconType="arrowLeft" size="xs" isDisabled={isLoading}>
            Go Back
          </EuiButtonEmpty>
        ) : null}
      </EuiFlexItem>
      <EuiFlexItem>{/* Spacer */}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          isDisabled={
            isLoading ||
            isFreezed ||
            (stepsWithoutControls.includes(currentStep) &&
              (!previouslyPassedStep || currentStep > previouslyPassedStep))
          }
          color="primary"
          onClick={nextStepHandler}
          iconType={isDraftView ? 'arrowRight' : ''}
          iconSide="right"
          fill={currentStep === SELF_LISTING_LAST_STEP_NUMBER}
          isLoading={isLoading}
        >
          {currentStep === SELF_LISTING_LAST_STEP_NUMBER
            ? isDraftView
              ? 'Submit Scholarship'
              : 'Submit Changes as Draft'
            : 'Save & Continue'}
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default SelfListingFormNavigation;
