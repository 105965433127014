import React from 'react';
import * as yup from 'yup';

import { EuiCodeBlock, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';
import TextInputsList from '@sharedComponents/formElements/combined/TextInputsList';
import ComboboxField from '@sharedComponents/formElements/simpleFields/ComboboxField';
import TextField from '@sharedComponents/formElements/simpleFields/TextField';
import studyRates from '@sharedComponents/models/enumValues/study_rates';
import allAreas from '@sharedContract/areas';

import AbstractEntryStep from './AbstractEntryStep';

const EntryStepEligibilityThird: AbstractEntryStep = {
  validationSchema: yup.object().shape({
    data: yup.object({
      eligibility_raw: yup.string().optional(),
      eligibility_sentence: yup.string().optional(),
      studyRates: yup.array(yup.string().oneOf(studyRates)).optional().nullable(),
      areas: yup.array(yup.string().oneOf(allAreas)).optional().nullable()
    }),
    eligibility: yup.array(yup.string()).transform(arr => (arr?.length ? arr.filter(val => !!val) : []))
  }),

  render: function (rowProps, { watch }) {
    const { data } = watch(['data']);

    const { eligibility_raw } = data || {};

    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false}>
                  <EuiTitle>
                    <h3>Eligibility</h3>
                  </EuiTitle>
                </EuiFlexItem>
                {eligibility_raw ? (
                  <EuiFlexItem style={{ width: 600 }}>
                    <EuiCodeBlock paddingSize="s">{eligibility_raw}</EuiCodeBlock>
                  </EuiFlexItem>
                ) : null}
                <EuiFlexItem style={{ width: 600 }}>
                  <TextInputsList
                    fieldName={'eligibility'}
                    rowProps={{ label: 'To qualify, the applicant must ...', ...rowProps }}
                  />
                  <EuiText size="xs">
                    Using the text displayed above, refine the eligibility requirements into separate sentences. <br />{' '}
                    Only include 1 eligibility requirement in each sentence.
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem style={{ width: 600 }}>
                  <TextField
                    fieldName={'data.eligibility_sentence'}
                    fieldProps={{
                      fullWidth: true
                    }}
                    rowProps={{
                      label: 'Eligibility Sentence:',
                      labelAppend: <EuiText size="xs">We have students who...</EuiText>,
                      style: { alignItems: 'center' },
                      helpText:
                        'Write 1 sentence that summarizes all of the eligibility requirements. This sentence will be used for marketing materials.',
                      ...rowProps
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false}>
                  <EuiTitle>
                    <h3>Future Studies</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ComboboxField
                    fieldName={'data.studyRates'}
                    options={studyRates}
                    fieldProps={{ fullWidth: true, placeholder: 'Select one or more' }}
                    rowProps={{ label: 'Intended Study Rate', ...rowProps }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <ComboboxField
                    fieldName={'data.areas'}
                    options={allAreas}
                    fieldProps={{ fullWidth: true, placeholder: 'Select one or more' }}
                    rowProps={{ label: 'Intended Field of Study', ...rowProps }}
                  />
                </EuiFlexItem>
                {/* TODO: handling for past recipients
              <EuiFlexItem>
              <FieldList
                fieldName={'pastRecipients'}
                recordDefaultValue={{ name: '', file: '' }}
                minListItems={1}
                fieldFactoryFn={(fieldNamePrefix, register, field) => {
                  return (
                    <>
                      <EuiFieldText
                        type="text"
                        name={`${fieldNamePrefix}name`}
                        inputRef={register}
                        defaultValue={field['name']}
                        placeholder={'Name'}
                        fullWidth
                      />
                      <EuiSpacer size="xs" />
                      <EuiFilePicker
                        id={field.id}
                        initialPromptText="Select or drag and drop photo file"
                        onChange={files => {
                          console.log('FIles', files);
                        }}
                        accept="image/*"
                        compressed={true}
                        display="large"
                        fullWidth
                      />
                    </>
                  );
                }}
                rowProps={{ label: 'Past Recipients', ...rowProps }}
              />
            </EuiFlexItem> */}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default EntryStepEligibilityThird;
