import React from 'react';
import { object } from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiText } from '@elastic/eui';
import { CheckboxField, ComboboxField, NumberField } from '@sharedComponents/formElements';
import academic_years from '@sharedComponents/models/enumValues/academic_years';
import athletics from '@sharedComponents/models/enumValues/athletics';
import college_types from '@sharedComponents/models/enumValues/college_types';
import ethnicities from '@sharedComponents/models/enumValues/ethnicities';
import genders from '@sharedComponents/models/enumValues/genders';
import miscellaneous from '@sharedComponents/models/enumValues/miscellaneous';
import religious from '@sharedComponents/models/enumValues/religious';
import selfIdentification from '@sharedComponents/models/enumValues/selfIdentification';
import veteran_status from '@sharedComponents/models/enumValues/veteran_status';
import { ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep13 = () => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>Are there any personal traits required for eligibility?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiPanel className="ScholarshipListing-panel_inner" hasBorder={false} hasShadow={false}>
          <EuiFlexGroup direction="column">
            <EuiFlexItem>
              <EuiFormRow label={'GPA'} display="columnCompressed" fullWidth>
                <EuiFlexGroup direction="row">
                  <EuiFlexItem grow={false}>
                    <NumberField
                      fieldName={'data.gpaFrom'}
                      fieldProps={{
                        fullWidth: false,
                        step: 0.1
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem style={{ textAlign: 'center', lineHeight: '38px' }}>-</EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <NumberField fieldName={'data.gpaTo'} fieldProps={{ fullWidth: false, step: 0.1 }} />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </EuiFlexItem>

            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.genders'}
                options={genders}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the gender of a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Gender', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.ethnicities'}
                options={ethnicities}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the ethnicity or nationality of a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Ethnicity', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.religious'}
                options={religious}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the religious or spiritual identity of a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Religious / Spiritual Identity', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.selfIdentification'}
                options={selfIdentification}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the disability of a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{
                  fullWidth: true,
                  label: 'Self-Identification of Disability:',
                  display: 'columnCompressed'
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.veteranStatus'}
                options={veteran_status}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the veteran status or affiliation of a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Veteran Status/Affiliation:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.athletics'}
                options={athletics}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: 'Select the sport played by a student who can apply for this scholarship.',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Athletics:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.miscellaneous'}
                options={miscellaneous}
                fieldProps={{
                  placeholder: 'Select one or more',
                  title: '',
                  fullWidth: true
                }}
                rowProps={{ fullWidth: true, label: 'Other:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.academicYears'}
                options={academic_years}
                fieldProps={{ placeholder: 'Select one or more', fullWidth: true }}
                rowProps={{ fullWidth: true, label: 'Current Grade:', display: 'columnCompressed' }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <ComboboxField
                fieldName={'data.collegeTypes'}
                options={college_types}
                fieldProps={{
                  fullWidth: true,
                  placeholder: 'Select one or more'
                }}
                rowProps={{ fullWidth: true, label: 'College Study Rate', display: 'columnCompressed' }}
              />
            </EuiFlexItem>

            <EuiFlexItem>
              <CheckboxField
                fieldName="data.lowIncome"
                fieldProps={{ label: 'Low income' }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    "Click the checkbox if a student's family is required to have low income, to apply for this scholarship."
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <CheckboxField
                fieldName="data.communityService"
                fieldProps={{ label: 'Community service' }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    'Click the checkbox if a student is required to have participated in community service, to apply for this scholarship.'
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <CheckboxField
                fieldName="data.firstGen"
                fieldProps={{ label: 'First person in family to attend college' }}
                rowProps={{
                  fullWidth: true,
                  helpText:
                    'Click the checkbox if a student is required to be the first member of their family to attend college, to apply for this scholarship.'
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep13.validationRules = object().shape({
  data: ScholarshipModelDataSchema.pick([
    'genders',
    'ethnicities',
    'religious',
    'selfIdentification',
    'veteranStatus',
    'athletics',
    'miscellaneous',
    'academicYears',
    'gpaFrom',
    'gpaTo',
    'lowIncome',
    'communityService',
    'firstGen'
  ])
});

export default SelfListingScholarshipStep13;
