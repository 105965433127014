import React from 'react';

import { CancelIcon, SearchIcon } from '@sharedComponents/base/components/Icons';
import './SearchContainer.scss';

export default function SearchContainer({ value, onChange }) {
  return (
    <div className="SearchContainer">
      <input id="search" value={value} onChange={onChange} />

      {value ? <CancelIcon onClick={() => onChange({ target: { value: '' } })} /> : <SearchIcon />}
    </div>
  );
}
