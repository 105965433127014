import { capitalize } from 'lodash';

import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui';
import { UserModelShape } from '@sharedComponents/models';

function RecurrentAwardUserDetails({
  record,
  user
}: {
  record: Record<string, string>;
  user: UserModelShape | undefined;
}) {
  return (
    <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
      <EuiFlexItem grow={false}>
        {!user ? (
          <EuiCallOut title="This student is not yet signed with Scholar's App!" color="warning" iconType="help">
            <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
              <EuiFlexItem>
                <EuiFlexGroup direction="row" responsive={false}>
                  <EuiFlexItem>
                    <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
                      <EuiFlexItem grow={false}>
                        <strong>Name</strong>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>{capitalize(record.name)}</EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <strong>Last Name</strong>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>{capitalize(record.last_name)}</EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
                      <EuiFlexItem grow={false}>
                        <strong>Number of previous repayments</strong>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>{record.previous_repayments || '-'}</EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <strong>Amount of previous repayments</strong>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>{record.previous_amount || '-'}</EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCallOut>
        ) : (
          <EuiCallOut title="Student is signed up!" color="success" iconType="user">
            <EuiFlexGroup direction="row" responsive={false}>
              <EuiFlexItem>
                <p>
                  Profile:{' '}
                  <EuiLink external={true} href={'/users/' + user.id} target="_blank">
                    {user.fullName}
                  </EuiLink>
                </p>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup direction="column" gutterSize="xs" responsive={false}>
                  <EuiFlexItem grow={false}>
                    <strong>Number of previous repayments</strong>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>{record.previous_repayments || '-'}</EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <strong>Amount of previous repayments</strong>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>{record.previous_amount || '-'}</EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCallOut>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default RecurrentAwardUserDetails;
