import './RolesDescriptionsBlock.scss';

import React, { Fragment, useEffect, useState } from 'react';

import {
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiTabbedContent,
  EuiText,
  EuiTitle,
  EuiSpacer,
  EuiFlexGrid
} from '@elastic/eui';
import { USER_TYPE } from '@sharedComponents/constants';
import { capitalize, getPublicRoleName } from '@sharedComponents/utils/stringUtils';

const titleByRole = {
  [USER_TYPE.APPLICANT]: 'We connect students to real and verified scholarships.',
  [USER_TYPE.PARENT]: 'Let us help you keep up with all the financial needs of your student.',
  [USER_TYPE.DONOR]: 'Receive more qualified and completed applications using our platform.',
  [USER_TYPE.COUNSELOR]: 'Discover scholarships matched to your students and support their college journey.',
  [USER_TYPE.REVIEWER]: 'Let’s get started reviewing your scholarship applications.',
  [USER_TYPE.RECOMMENDER]: 'Sign up to help a student achieve their college dreams.',
  [USER_TYPE.PROFESSIONAL]: 'We connect professionals to real and verified grants.'
};

const descriptionByRole = {
  [USER_TYPE.APPLICANT]: (
    <>
      Scholar’s App scholarships are screened and verified by counselors before posting–to eliminate fraudulent
      listings. We never sell your data nor charge any fees. Your Student Dashboard keeps all of your scholarship
      information in one place, and only shows you the scholarships that you qualify for.
    </>
  ),
  [USER_TYPE.PARENT]: (
    <>
      The Scholar’s App Parent Dashboard allows you to track the entire application process–from start to finish. This
      allows you to see where to guide and assist your student to apply for all scholarships that they qualify for. Sign
      up to receive email and text notifications as the steps are completed.
    </>
  ),
  [USER_TYPE.DONOR]: (
    <>
      Easily post your scholarship application online, and let Scholar's App find the qualified candidates you need. We
      provide more completed applications than any other site–including essays, transcripts, and letters of
      recommendation. Our full suite of tools allows a seamless workflow with your reviewers.
    </>
  ),
  [USER_TYPE.COUNSELOR]: (
    <>
      Scholar’s App gives counselors time-saving tools to help students on their journey to college. Streamline tracking
      scholarship applications, in addition to other milestones necessary for your students to be college-ready. You can
      send email/text broadcasts, electronically sign forms, and submit transcripts.
    </>
  ),
  [USER_TYPE.REVIEWER]: (
    <>
      You've been invited by a scholarship foundation to evaluate and score submitted applications, please log in to
      view those assigned to you. With the Reviewer Dashboard–you can view all the applicant information and materials
      necessary for scoring. When completed, the score is automatically visible to the scholarship foundation.
    </>
  ),
  [USER_TYPE.RECOMMENDER]: (
    <>
      A student has requested that you submit a letter of recommendation via Scholar’s App. You might have received an
      email request regarding a specific application, or a student might have asked that you create an account before
      they send an email request. After signing up, you can quickly upload a PDF letter.
    </>
  ),
  [USER_TYPE.PROFESSIONAL]: (
    <>
      Scholar's App features grants awarded for a wide variety of uses. Ranging from continuing your education,
      administering educational programs, purchasing classroom supplies, and funding your child's K-12 schooling needs.
      Applying online is an easy way to help finance your dreams.
    </>
  )
};

const checkmarksByRole = {
  [USER_TYPE.APPLICANT]: ['No Fees', 'Scholarship Suggestions', 'Quickly Apply Online', 'Over $20M Received'],
  [USER_TYPE.PARENT]: ['No Fees', 'No Ads', 'Easy to Use', 'Over $20M Received'],
  [USER_TYPE.DONOR]: [
    'Easy to Use',
    'More Qualified Candidates',
    'More Completed Applications',
    'Collaborate with Reviewers'
  ],
  [USER_TYPE.COUNSELOR]: [
    'Discover More Scholarships',
    'Track Application Progress',
    'Support Student Success',
    'Over $20M Received'
  ],
  [USER_TYPE.REVIEWER]: [
    'Easy to Use',
    'More Completed Applications',
    'View Documents Electronically',
    'Score Applications Online'
  ],
  [USER_TYPE.RECOMMENDER]: ['Easy to Use', 'No Ads', 'Support Student Success', 'Upload Recommendations Online'],
  [USER_TYPE.PROFESSIONAL]: ['No Fees', 'No Ads', 'Easy to Use', 'Apply Online']
};

const roles = [
  USER_TYPE.APPLICANT,
  USER_TYPE.PARENT,
  USER_TYPE.DONOR,
  USER_TYPE.COUNSELOR,
  USER_TYPE.REVIEWER,
  USER_TYPE.RECOMMENDER,
  USER_TYPE.PROFESSIONAL
];

const tabContent = role => {
  return (
    <Fragment>
      <EuiTitle size="l" className="RolesDescriptionsBlock-title">
        <h3>{titleByRole[role]}</h3>
      </EuiTitle>
      <EuiText size="m" className="RolesDescriptionsBlock-description">
        {descriptionByRole[role]}
      </EuiText>
      <EuiSpacer size="m" />
      <EuiFlexGrid columns={2} gutterSize="s" className="RolesDescriptionsBlock-checkmarks">
        {checkmarksByRole[role].map((checkmarkText, index) => (
          <EuiFlexItem key={`${role}_${index}`}>
            <EuiText size="m">
              <EuiIcon size="l" type="check" color="success" /> <strong>{checkmarkText}</strong>
            </EuiText>
          </EuiFlexItem>
        ))}
      </EuiFlexGrid>
    </Fragment>
  );
};

const prefixTabID = '--id';
const getTabID = role => role + prefixTabID;

const tabs = [
  ...roles.map(role => ({
    id: getTabID(role),
    name: capitalize(getPublicRoleName(role)) + 's',
    content: tabContent(role)
  }))
];

export default function RolesDescriptionsBlock({ userType, setUserType }) {
  const getTabByRole = role => tabs[roles.indexOf(role)];
  const [selectedTab, setSelectedTab] = useState(getTabByRole(userType));

  const onTabClick = selectedTab => {
    setUserType(selectedTab.id.replace(prefixTabID, '')); // we change user role, tab will be changed once props will be changed
  };

  useEffect(() => {
    const newTab = getTabByRole(userType);
    if (selectedTab && selectedTab.id !== newTab.id) {
      setSelectedTab(newTab);
    }
  }, [userType]);

  return (
    <EuiPanel className="RolesDescriptionsBlock" grow={false} hasShadow={false} paddingSize="s">
      <EuiTabbedContent size="l" display="condensed" tabs={tabs} selectedTab={selectedTab} onTabClick={onTabClick} />
    </EuiPanel>
  );
}
