import { History } from 'history';
import UserModel from '@sharedContract/UserModel';
import { HeaderProps } from '@donors/components/Header';
import ScholarshipApplications from '@sharedComponents/ScholarshipApplications';

export default function ScholarshipApplicationsPage(
  props: {
    scholarshipId: number;
    history: History;
    user: UserModel;
    jwt: string;
  } & HeaderProps
) {
  const { scholarshipId } = props;

  return (
    <>
      <ScholarshipApplications scholarshipId={scholarshipId} />
    </>
  );
}
