/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useState } from 'react';
import 'react-slidedown/lib/slidedown.css';

import Field from '@sharedComponents/base/components/Field';
import NumberBubble from '@sharedComponents/base/components/NumberBubble';
import { MinusIcon, PlusIcon } from '@sharedComponents/base/PlusMinusIcon';
import useAnimatingList from '@sharedComponents/base/useAnimatingList';
import { UserError } from '@sharedComponents/base/useErrorReporter';

import './FaqQuestionAnswer.scss';
import './fileUpload.scss';

export interface IPastRecipients {
  file?: File | '';
  name: string;
  note: string;
  binary?: string | undefined;
  pastRecipientId?: number;
  metaType?: string;
  link?: string;
}
//fileupload
function UploadComp({
  accept,
  onChangeHandle,
  binary = true,
  value,
  onError,
  onRemove
}: {
  accept: Array<string>;
  onChangeHandle: (file: File, binary: string | ArrayBuffer | null) => void;
  binary: boolean;
  value: IPastRecipients;
  onError: (e: Error) => void;
  onRemove: () => void;
}) {
  const [imgsrc, setimgsrc] = useState<string>(value.link || '/images/defaultuser.png');

  const imagePreview = function (file: File) {
    const url = URL.createObjectURL(file);
    if (url) {
      setimgsrc(url);
    }
    // return;
    // setPreview(url);
  };
  function handleBinary(reader: FileReader, file: File) {
    reader.onloadend = () => {
      const binaryh = reader.result;
      onChangeHandle(file, binaryh);
    };
    reader.readAsBinaryString(file);
  }
  function handleBase64(reader: FileReader, file: File) {
    reader.onloadend = () => {
      const base64 = reader.result as string;
      onChangeHandle(file, base64);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) {
      return;
    }
    const file = e.target.files[0];
    if (file.size > 2097152) {
      onError(new UserError('File is too big!'));
      return;
    }
    const reader = new FileReader();
    if (binary) {
      handleBinary(reader, file);
    } else {
      handleBase64(reader, file);
    }
    imagePreview(file);
  };

  const handleRemove = () => {
    setimgsrc('/images/defaultuser.png');
    onRemove();
  };

  return (
    <>
      <div className="custom-upload-div">
        {imgsrc === '/images/defaultuser.png' ? null : (
          <button
            className="button invisible add"
            onClick={e => {
              e.preventDefault();
              handleRemove();
            }}
          >
            <div className="icon2 end">X</div>
          </button>
        )}
        <label className="custom-upload">
          <input type="file" onChange={handleChange} accept={accept.join(' ,')} />
          <img
            alt="useriamge"
            src={imgsrc}
            // style={{ width: '3rem', height: '3rem', margin: '0 2.3rem' }}
            className="pastreceitps-thumbanil"
          />
        </label>
        <label>Upload Photo (below 2MB)</label>
      </div>
    </>
  );
}

export default function UploadFile({
  value,
  setValue,
  onError
}: {
  value: Array<IPastRecipients>;
  setValue: (value: Array<IPastRecipients>) => void;
  onError: (e: Error) => void;
}) {
  const { renderListItem, animateListItem } = useAnimatingList(50);
  const add = async () => {
    value.push({ name: '', note: '' });
    setValue(value);
    animateListItem(value.length, true);
  };

  const remove = async (i: number) => {
    await animateListItem(i, false);

    let newValue = value.slice(0);
    newValue.splice(i, 1);

    setValue(newValue);
  };

  const label = '';
  return (
    <Field label={label} htmlFor="pastrecipients" help="">
      <h3>Past Recipients</h3>
      <div id="pastrecipients" className="faqquestionanswer">
        <div>
          {atLeastOne(value)
            .map((listItem: IPastRecipients, i: number) => (
              <div className="list-faqQA" key={i}>
                <NumberBubble number={i + 1} />
                <UploadComp
                  accept={['image/*']}
                  binary={false}
                  value={value[i]}
                  onError={onError}
                  onChangeHandle={(file, binary) => {
                    if (!binary) {
                      return;
                    }
                    value[i].file = file;
                    value[i].binary = binary as string;
                    value[i].metaType = file.type?.replace('image/', '');
                    setValue([...value]);
                  }}
                  onRemove={() => {
                    value[i].file = '';
                    value[i].binary = '';
                    value[i].metaType = '';
                    value[i].link = '';
                    setValue([...value]);
                  }}
                />

                <div className="faqQAfields faq-answer">
                  <label>Name</label>
                  <input
                    type="text"
                    value={listItem.name || ' '}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newValue = value.slice(0);
                      newValue[i].name = e.target.value;
                      setValue(newValue);
                    }}
                  />
                </div>
                <div className="faqQAfields faq-answer">
                  <label>Note</label>
                  <input
                    type="text"
                    value={listItem.note || ' '}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newValue = value.slice(0);
                      newValue[i].note = e.target.value;
                      setValue(newValue);
                    }}
                  />
                </div>

                {value.length > 1 ? (
                  <button
                    className="button invisible delete"
                    onClick={e => {
                      e.preventDefault();
                      remove(i);
                    }}
                  >
                    <div className="icon">
                      <MinusIcon />
                      <div className="label">Remove</div>
                    </div>
                  </button>
                ) : null}
              </div>
            ))
            .map(renderListItem)}
        </div>

        <button
          className="button invisible add"
          onClick={e => {
            e.preventDefault();
            add();
          }}
        >
          <div className="icon">
            <PlusIcon />
          </div>
          <div className="label">Add another</div>
        </button>
      </div>
    </Field>
  );
}

export function isList(object: any) {
  return typeof object == 'object' && object.length != null;
}

function atLeastOne(items: Array<IPastRecipients>) {
  if (items.length) {
    return items;
  } else {
    items.push({
      name: '',
      note: '',
      pastRecipientId: 0,
      link: '/images/defaultuser.png'
    });
    return items;
  }
}
