import './EditCounselorUser.scss';

import { CoreUserFields, CounselorUserData } from '@sharedContract/UserModel';
import React, { useState } from 'react';

/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete } from '@donors/base/Autocomplete';
import ButtonBar from '@donors/components/ButtonBar';
import DonorWidget from '../components/DonorWidget';
import Field from '@donors/components/Field';
import { SelectedDonor } from '../components/DonorWidget';
import UserClient from '@sharedClients/UserClient';
import UserModel from '@sharedContract/UserModel';
import preventDefault from '@donors/base/preventDefault';
import useSingleSubmit from '../useSingleSubmit';
import { useSubmittable } from '../components/ValidatingField';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function EditCounselorUser({
  onSave,
  onError,
  onCancel,
  currentUser,
  user,
  userClient,
  applicationClient
}: {
  onSave: (user: CounselorUserData, donor: SelectedDonor) => any;
  onCancel: () => void;
  onError: (e: Error) => void;
  currentUser: UserModel;
  user: CoreUserFields;
  userClient: UserClient;
  applicationClient: ApplicationClient;
}) {
  user = user || ({ data: {} } as CoreUserFields);

  const [data, setData] = useState<CounselorUserData>(user.data as CounselorUserData);

  const [singleSubmit, isSubmitting] = useSingleSubmit();
  const [newSchool, setNewSchool] = useState<School | null>(null);
  const [isPremium, setisPremium] = useState<boolean>((user.data as CounselorUserData).isPremium || false);
  const { validatingFieldDefaults } = useSubmittable();
  const [donor, setDonor] = useState<SelectedDonor>(
    user.donor && user.donor.name
      ? { isExisting: true, id: user.donor.id, name: user.donor.name || '' }
      : { isExisting: false }
  );

  async function onSubmit(e: Event) {
    e.preventDefault();
    data.isPremium = isPremium;

    let updatedDonor = { ...donor };

    if ((donor.name || donor.email) && !donor.isExisting) {
      const newDonor = await userClient.createDonor({
        name: donor.name || '',
        email: donor.email || ''
      });
      updatedDonor = { ...donor, id: newDonor.id, isExisting: true };
    }

    if (newSchool) {
      try {
        const { id } = await applicationClient.addSchool(newSchool);
        return onSave(
          {
            ...data,
            school: {
              name: newSchool.name + ', ' + newSchool.state,
              id
            },
            state: newSchool.state
          },
          updatedDonor
        );
      } catch (e: any) {
        onError(e);
      }
    } else {
      return onSave(data, updatedDonor);
    }
  }

  return (
    <form onSubmit={singleSubmit(onSubmit)} className="EditCounselorUser">
      <div className="pageTitle">
        <h4>Edit Useraaa</h4>
        <h2>{user.data.name || user.name || 'New Donor'}</h2>
      </div>
      <Field label="Email">
        <div className="value">{user && user.name}</div>
      </Field>

      <Field label="Name">
        <div className="value">{user && data.name}</div>
      </Field>

      <Field label="State">
        <div className="value">{user && data.state}</div>
      </Field>
      <div className="field">
        <div className="checkbox">
          <input id="ispremium" type="checkbox" checked={isPremium} onChange={e => setisPremium(e.target.checked)} />
          <label htmlFor="ispremium">Premium account</label>
        </div>
      </div>
      {!newSchool ? (
        <Field label="School" htmlFor="School">
          <Autocomplete
            id="school"
            value={data.school}
            onChange={option => {
              if (option && option.id != null) {
                const { id, name, state } = option;

                setData({
                  ...data,
                  school: { id, name: name + ', ' + state },
                  state
                });
              }
            }}
            applicationClient={applicationClient}
            autocomplete="schools"
          />

          <div className="newSchool">
            <button
              className="button link"
              onClick={preventDefault(() => setNewSchool({ name: '', city: '', state: data.state || '' }))}
            >
              Add a new school
            </button>
          </div>
        </Field>
      ) : (
        <AddSchool school={newSchool} setSchool={setNewSchool} />
      )}

      {currentUser.type === 'admin' ? (
        <div className="field">
          <DonorWidget
            initialValue={donor}
            onChange={setDonor}
            onError={onError}
            user={currentUser}
            userClient={userClient}
            type={user.type}
            {...validatingFieldDefaults}
          />
        </div>
      ) : null}

      <ButtonBar>
        <button className="button" disabled={isSubmitting}>
          Save
        </button>
        <button
          className="button"
          onClick={e => {
            onCancel();
            e.preventDefault();
          }}
        >
          Cancel
        </button>
      </ButtonBar>
    </form>
  );
}

interface School {
  name: string;
  state: string;
  city: string;
}

function AddSchool({ school, setSchool }: { school: School; setSchool: (school: School) => void }) {
  return (
    <Field label="School" htmlFor="School">
      <Field label="School name" htmlFor="schoolName">
        <input
          id="schoolName"
          type="text"
          value={school.name}
          onChange={e => setSchool({ ...school, name: e.target.value })}
        />
      </Field>
      <Field label="School city" htmlFor="schoolCity">
        <input
          id="schoolCity"
          type="text"
          value={school.city}
          onChange={e => setSchool({ ...school, city: e.target.value })}
        />
      </Field>
      <Field label="School state" htmlFor="schoolState">
        <input
          id="schoolState"
          type="text"
          value={school.state}
          onChange={e => setSchool({ ...school, state: e.target.value })}
        />
      </Field>
    </Field>
  );
}
