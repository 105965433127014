import React from 'react';
import { useMemo } from 'react';
import fieldValueToString from '../fieldValueToString';
import { getInputClass } from './FormFieldText';
import '../../base/components/SelectWidget.scss';
import { toString, isList } from '../../model/cast';
import { CaretDown } from '../../base/components/MultipleSelectWidget';
import FormNode from '../../model/FormNode';

export default function FormFieldSelect({
  node,
  application,
  customOptions,
  onFieldChange,
  onFormError
}: {
  application: any;
  node: any;
  customOptions?: any;
  onFieldChange: (value: any, field: FormNode) => void;
  onFormError: (e: Error) => void;
}) {
  const value = node.getValue(application);
  const valueString = fieldValueToString(value);

  const options = customOptions || toList(node.options, onFormError);

  const className = useMemo(() => {
    let maxLength = options.reduce((acc, option) => Math.max(acc, option.length), 0);
    return getInputClass({ width: maxLength });
  }, [options]);

  const isDisabled = application.submittedAt && node.freezedAfterSubmit;
  return (
    <div className={'FormFieldSelect ' + (className || '')} style={{ height: '50px' }}>
      <select
        id={node.getInputElementId()}
        value={valueString}
        disabled={node.disabled ? true : false}
        onChange={e => onFieldChange(e.target.value, node)}
      >
        {options.map(option => (
          <option className="m-bg-white" key={option} disabled={isDisabled}>
            {option}
          </option>
        ))}
      </select>
      <CaretDown />
    </div>
  );
}

export function toList(object: any, onError: (e: Error) => void) {
  if (isList(object)) {
    return object;
  } else {
    return toString(object, onError)
      .split(',')
      .map(s => s.trim());
  }
}
