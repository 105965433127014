import './SelfListingHeader.scss';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';

enum HEADER_VARIANTS {
  ABOUT,
  ELIGIBILITY,
  FINAL
}

const SelfListingHeader = ({ currentStep, nextStepHandler, isFreezed, isDraftView, scholarshipEntry }) => {
  if (!isDraftView) {
    return (
      <EuiFlexGroup direction="column" className="SelfListingHeader" responsive={false}>
        <EuiFlexItem>
          <EuiTitle size="l" className={`SelfListingHeader-title`}>
            <h4>{scholarshipEntry?.name}</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  const phase = SelfListingHeader.getPhase(currentStep);
  return (
    <EuiFlexGroup direction="column" className="SelfListingHeader" responsive={false}>
      <EuiFlexItem>
        <EuiTitle size="l" className={`SelfListingHeader-title_${phase}`}>
          <h4>{SelfListingHeader.getPhaseName(currentStep)}</h4>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size="m">
          {phase === HEADER_VARIANTS.ABOUT
            ? "The first step to getting started with Scholar's App is to get some basic information about your scholarship."
            : phase === HEADER_VARIANTS.ELIGIBILITY
            ? "We're going to walk you though a few steps to know more about what application materials students will need to provide you, as well as eligibility criteria."
            : isFreezed
            ? "You've entered all the required information need for Scholar's App to list your scholarship. You can review your answers."
            : "Great! You've entered all the required information need for Scholar's App to list your scholarship. You can submit your application now, or review your answers."}
        </EuiText>
      </EuiFlexItem>
      {phase === HEADER_VARIANTS.FINAL ? (
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={false}>
              <EuiButton
                size="s"
                iconType="arrowRight"
                iconSide="right"
                onClick={nextStepHandler}
                isDisabled={isFreezed}
              >
                Submit Scholarship
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ) : null}
    </EuiFlexGroup>
  );
};

SelfListingHeader.getPhase = currentStep => {
  return currentStep < 7
    ? HEADER_VARIANTS.ABOUT
    : currentStep === 14
    ? HEADER_VARIANTS.FINAL
    : HEADER_VARIANTS.ELIGIBILITY;
};

SelfListingHeader.getPhaseName = currentStep => {
  const phase = SelfListingHeader.getPhase(currentStep);

  return phase === HEADER_VARIANTS.ABOUT
    ? 'Tell us about your scholarship'
    : phase === HEADER_VARIANTS.ELIGIBILITY
    ? 'Scholarship Eligibility'
    : 'Last Step';
};

export default SelfListingHeader;
