import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { DraftScholarshipModelShape, ScholarshipModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'scholarshipEntry';

function useScholarshipEntry<T extends true | false>(
  scholarshipID: number,
  isDraft: T,
  options?: UseQueryOptions<
    T extends true ? DraftScholarshipModelShape : ScholarshipModelShape & { isApplicable: boolean },
    Error,
    T extends true ? DraftScholarshipModelShape : ScholarshipModelShape & { isApplicable: boolean }
  >
): UseQueryResult<T extends true ? DraftScholarshipModelShape : ScholarshipModelShape, Error>;

function useScholarshipEntry<T>(
  scholarshipID?: number | null,
  isDraft: boolean = false,
  options: UseQueryOptions<T, Error, T> = {}
) {
  const scholarshipClient = useScholarshipClient();

  return useQuery<T, Error>(
    [CURRENT_QUERY_KEY, scholarshipID],
    () => scholarshipClient.getScholarshipEntry(scholarshipID, isDraft),
    {
      ...options
    }
  );
}

const scholarshipEntryQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useScholarshipEntry
};

export default scholarshipEntryQuery;
