/* eslint-disable react-hooks/exhaustive-deps */
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import FormNode from '../../model/FormNode';
import React, { useState } from 'react';
import { toString } from 'lodash';
import { EuiFormControlLayoutDelimited } from '@elastic/eui';

const DEFAULT_MAX = 999999999999999;
const DEFAULT_MIN = -1 * DEFAULT_MAX;

const toNumberOrNull = v => {
  let parsedValue = parseInt(v);

  if (isNaN(parsedValue) || v !== parsedValue) {
    return null;
  }

  return parsedValue;
};

export const transformToNumberRangeFieldValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.map(v => toNumberOrNull(v));
  }

  let numValue = toNumberOrNull(value);

  return [numValue, numValue];
};

const isValidValue = (arrValue, nodeConfig) =>
  arrValue.every(v => v == null || ((nodeConfig.min || DEFAULT_MIN) <= v && (nodeConfig.max || DEFAULT_MAX) >= v));

export default function FormFieldNumberRange({
  node,
  application,
  onFieldChange
}: {
  node: FormNode;
  application: ApplicationModel;
  onFieldChange: (value: Array<number | null>, node: FormNode) => any;
}) {
  const nodeValue = node.getValue(application);
  const nodeConfig = node.getConfig();
  const min = nodeConfig.min || DEFAULT_MIN;
  const max = nodeConfig.max || DEFAULT_MAX;

  const [value, setValue] = useState<Array<number | null>>(transformToNumberRangeFieldValue(nodeValue));

  const onValueChanged = (index, v) => {
    // local value
    let newInnerValue = [...value];
    newInnerValue[index] = v;
    setValue([...newInnerValue]);

    // node value
    let newValue = [...transformToNumberRangeFieldValue(value)];
    newValue[index] = toNumberOrNull(v);
    if (isValidValue(newValue, nodeConfig)) {
      onFieldChange(newValue, node);
    }
  };

  return (
    <div className="FormFieldNumberRange">
      <EuiFormControlLayoutDelimited
        fullWidth
        isDisabled={node.disabled}
        startControl={
          <input
            value={toString(value[0])}
            min={min}
            max={max}
            type="number"
            placeholder="From"
            className="euiFieldNumber"
            onChange={e => onValueChanged(0, e.target.value)}
          />
        }
        endControl={
          <input
            value={toString(value[1])}
            min={min}
            max={max}
            type="number"
            placeholder="To"
            className="euiFieldNumber"
            onChange={e => onValueChanged(1, e.target.value)}
          />
        }
      />
    </div>
  );
}
