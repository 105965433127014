import { useQuery } from 'react-query';

import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { ScholarshipModelShape } from '@sharedComponents/models';
import { ScholarshipListingFilterType } from '@sharedComponents/schemas/ScholarshipListingFilter.schema';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

const CURRENT_QUERY_KEY = 'scholarshipsListing';

function useScholarshipsListing(filter: ScholarshipListingFilterType, page: number, pageSize = 50) {
  const scholarshipClient = useScholarshipClient();
  return {
    ...useQuery<Page<ScholarshipModelShape>, Error>(
      [CURRENT_QUERY_KEY, page, pageSize, filter],
      () => scholarshipClient.getScholarshipsListing(filter, page, pageSize),
      {
        staleTime: 1000 * 90
      }
    )
  };
}

const scholarshipsListingQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useScholarshipsListing };
export default scholarshipsListingQuery;
