import { useMutation, useQuery, UseQueryOptions } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';

import {
  RecurrentPaymentsConfigurationForm,
  RecurrentPaymentsVerificationResult
} from '../../RecurringAwardsSetup.interface';

const CURRENT_QUERY_KEY = 'recurrentPaymentFileValidation';

const SUBMIT_ENDPOINT = scholarshipID => `/v3/scholarships/${scholarshipID}/recurring-awards`;
const useSubmitRecurrentPaymentConfiguration = () => {
  const fetcher = useFetcher();

  return useMutation<
    boolean, // returned result result by our api
    Error, // error type
    { scholarshipID: number; configuration: RecurrentPaymentsConfigurationForm }
    // parameters accepted by mutation
  >(({ scholarshipID, configuration }) =>
    fetcher().url(SUBMIT_ENDPOINT(scholarshipID)).json({ configuration: configuration }).post()
  );
};

const VERIFICATION_ENDPOINT = fileID => `/v3/scholarships/recurring-verification/${fileID}`;
function useRecurrentPaymentFileValidation(
  fileID: number,
  options: UseQueryOptions<RecurrentPaymentsVerificationResult, Error, RecurrentPaymentsVerificationResult> = {}
) {
  const fetcher = useFetcher();

  return useQuery<RecurrentPaymentsVerificationResult, Error>(
    [CURRENT_QUERY_KEY, fileID],
    () => fetcher().url(VERIFICATION_ENDPOINT(fileID)).get(),
    {
      staleTime: 1000 * 60 * 15, // just in case we are navigating between steps, file content is quite stale info
      ...options
    }
  );
}

const recurrentPaymentsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useSubmitRecurrentPaymentConfiguration,
  useRecurrentPaymentFileValidation
};

export default recurrentPaymentsQuery;
