import React from 'react';
import { getColor } from './USMap';
import './BarChart.scss';

function sanitizeDistribution(distribution) {
  if (!distribution || !distribution.length) {
    return [];
  } else {
    return distribution.filter(value => typeof value === 'number');
  }
}

export default function BarChart({ distribution, labels }) {
  distribution = sanitizeDistribution(distribution);

  const max = Math.max(...distribution);
  const height = 148;
  const xScale = 192 / Math.max(distribution.length, 1);
  const yScale = height / Math.max(max, 1);

  return (
    <svg className="BarChart" width={distribution.length * xScale} height={height}>
      {distribution.map((value, i) => {
        const percentage = value / max;
        const barHeight = Math.max(yScale * value, 2);

        return (
          <g key={i}>
            <rect
              key={i}
              x={i * xScale}
              y={height - barHeight}
              width={xScale}
              height={barHeight}
              style={{ fill: getColor(Math.max(percentage, 0.05)) }}
            />
            {labels ? (
              <text
                x={i * xScale + xScale / 2}
                y={height - 4}
                textAnchor="middle"
                dominantBaseline="text-after-edge"
                className={percentage > 0.5 ? 'invert' : ''}
              >
                {labels[i]}
              </text>
            ) : null}
          </g>
        );
      })}
    </svg>
  );
}
