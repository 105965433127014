import React from 'react';

// TODO: These icons are the same that are in the /apply/components/Icons.tsx
// Refactor apply app to use icons from the SharedComponents
const LoadingIcon = () => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="loadingIcon">
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#7eadf3"
      strokeWidth="12"
      r="37"
      strokeDasharray="174.35839227423352 60.119464091411174"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.4492753623188404s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);

const SearchIcon = () => (
  <svg viewBox="0 0 100 100">
    <path d="M91.9,91.9c-3.5,3.5-9.2,3.5-12.7,0L62.7,75.4C56.9,79,50.1,81,42.7,81C21.6,81,4.5,63.9,4.5,42.7  S21.6,4.5,42.7,4.5S81,21.6,81,42.7c0,7.3-2.1,14.1-5.6,20l16.5,16.5C95.4,82.7,95.4,88.4,91.9,91.9z M42.7,18  C29.1,18,18,29.1,18,42.7s11.1,24.8,24.8,24.8s24.8-11.1,24.8-24.8S56.4,18,42.7,18z" />
  </svg>
);

const CancelIcon = ({ onClick }: { onClick?: () => void }) => (
  <svg viewBox="0 0 564 564" className="cancel" onClick={onClick}>
    <g>
      <line strokeLinecap="round" strokeWidth="90" x1="90" y1="474" x2="474" y2="90" />
      )
      <line strokeLinecap="round" strokeWidth="90" x1="90" y1="90" x2="474" y2="474" />
    </g>
  </svg>
);

export { SearchIcon, LoadingIcon, CancelIcon };
