import { ValidationError } from '../FormFieldText';
import Pattern from './Pattern';

const titles = ['ms', 'mrs', 'mr', 'dr'];

const fullNamePattern: Pattern = {
  correctPartial(value) {
    return value;
  },

  correctComplete(value) {
    value = value.trim() || '';

    const lowerName = value.toLowerCase();

    if (titles.find(t => lowerName.startsWith(t + ' ') || lowerName.startsWith(t + '. '))) {
      value = value.substr(value.indexOf(' ') + 1);
    }

    if (value.indexOf(' ') < 0) {
      throw new ValidationError('Please include both first and last name.');
    }

    if (value.length < 4) {
      throw new ValidationError('Please enter full name.');
    }

    const longComponents = value.split(' ').filter(s => s.length > 1);

    if (longComponents.length < 2) {
      throw new ValidationError('Replace initials with full name.');
    }

    if (!hasUpper(value)) {
      value = value
        .split(' ')
        .filter(w => !!w)
        .map(word => word[0].toUpperCase() + word.substr(1))
        .join(' ');
    }

    if (value === value.toUpperCase()) {
      value = value
        .split(' ')
        .filter(w => !!w)
        .map(word => word[0] + word.substr(1).toLowerCase())
        .join(' ');
    }

    return value;
  }
};

export default fullNamePattern;

function hasUpper(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (isUpper(str[i])) {
      return true;
    }
  }

  return false;
}

function isUpper(ch: string) {
  return ch === ch.toUpperCase() && ch !== ch.toLowerCase();
}
