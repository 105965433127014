import React from 'react';
import { EuiFormRow, EuiFormRowProps } from '@elastic/eui';

export interface FormRowProps {
  rowKey: string;
  isInvalid: boolean;
  errorMessage?: string;
  children: React.ReactElement;
  rowProps: Partial<EuiFormRowProps>;
}

export default function FormRow({ rowKey, isInvalid, errorMessage, rowProps, children }: FormRowProps) {
  return (
    <EuiFormRow key={rowKey} isInvalid={isInvalid} error={errorMessage} {...rowProps}>
      {children}
    </EuiFormRow>
  );
}
