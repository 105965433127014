import React from 'react';

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { TextAreaField, TextField } from '@sharedComponents/formElements';
import { ScholarshipModelSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep1 = () => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <TextField
          fieldName={'name'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Scholarship Name', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextAreaField
          fieldName={'description'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Description', fullWidth: true }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep1.validationRules = ScholarshipModelSchema.pick(['name', 'description']);

export default SelfListingScholarshipStep1;
