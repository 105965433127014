import './LoadingPrompt.scss';

import { cn } from '@bem-react/classname';
import {
  EuiEmptyPrompt,
  EuiEmptyPromptProps,
  EuiLoadingChart,
  EuiLoadingLogo,
  EuiPageTemplate,
  EuiText
} from '@elastic/eui';
import LogoVector from '@sharedAssets/logo_vector.svg';

const blockClass = cn('LoadingPrompt');
// we could attach this component to whole query object and handle states?
export default function LoadingPrompt({
  isError,
  isLoading,
  isEmptyPromptShown,
  emptyTitleMessage,
  emptyBodyMessage,
  errorMessage,
  useLogoAsLoader,
  children
}: {
  isLoading: boolean;

  isError: boolean;
  errorMessage?: string;

  isEmptyPromptShown?: boolean;
  emptyTitleMessage?: string;
  emptyBodyMessage?: string;

  useLogoAsLoader?: boolean;
  children;
}) {
  let promptProps: EuiEmptyPromptProps | null = null;
  if (isError) {
    promptProps = {
      iconType: 'alert',
      iconColor: 'danger',
      title: <h3>Error while loading</h3>,
      body: <p>{errorMessage ? errorMessage : 'There was an error loading your results. Please, try again.'}</p>
    };
  } else if (isLoading) {
    promptProps = {
      icon: useLogoAsLoader ? (
        <EuiLoadingLogo logo={LogoVector} size="xl" style={{ alignSelf: 'center' }} />
      ) : (
        <EuiLoadingChart size="xl" style={{ alignSelf: 'center' }} />
      )
    };
  } else if (isEmptyPromptShown) {
    promptProps = {
      iconType: 'editorStrike',
      iconColor: 'primary',
      title: (
        <EuiText color="black">
          <strong>{emptyTitleMessage ? emptyTitleMessage : ''}</strong>
        </EuiText>
      ),
      body: <EuiText>{emptyBodyMessage ? emptyBodyMessage : ''}</EuiText>
    };
  }

  return (
    <>
      {promptProps ? (
        <EuiPageTemplate
          className={blockClass()}
          template="centeredContent"
          pageContentProps={{ color: 'transparent' }}
        >
          <EuiEmptyPrompt {...promptProps} />
        </EuiPageTemplate>
      ) : (
        children
      )}
    </>
  );
}
