import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { EuiFlexGroup, EuiFlexItem, EuiRadio, EuiRadioProps, htmlIdGenerator } from '@elastic/eui';

import FormRow, { FormRowProps } from '../FormRow';
import { getValueByDottedPath } from '../util';

/** Represents radio group with Yes/No variants */
export default function BooleanRadioGroupField({
  fieldName,
  fieldProps,
  rowProps,
  labels = ['Yes', 'No']
}: {
  fieldName: string;
  fieldProps?: Omit<Partial<EuiRadioProps>, 'onChange'>;
  rowProps?: FormRowProps['rowProps'];
  labels?: [string, string];
}) {
  const { control, errors } = useFormContext();

  const errorObject = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!errorObject;
  const field = (
    <Controller
      control={control}
      name={fieldName}
      isInvalid={isInvalid}
      error={errorObject?.message || ''}
      render={({ onChange, value }) => (
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiRadio
              id={htmlIdGenerator()()}
              label={labels[0]}
              checked={value === true}
              onChange={() => {
                onChange(true);
              }}
              {...fieldProps}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiRadio
              id={htmlIdGenerator()()}
              label={labels[1]}
              checked={value === false}
              onChange={() => {
                onChange(false);
              }}
              {...fieldProps}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    />
  );

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? errorObject?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
