import React, { useEffect, useState } from 'react';

import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight, EuiFormRow, EuiFormRowProps } from '@elastic/eui';
import useDebounce from '@sharedComponents/hooks/useDebounce';
import { DonorModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import { useFormContext, FieldError } from 'react-hook-form';
import { getValueByDottedPath } from '@sharedComponents/formElements/util';

interface DonorLookupWidgetOption {
  label: string;
  entry?: DonorModelShape;
  disabled?: boolean;
}

interface DonorLookupWidgetOptions {
  placeholderLabel?: string;
  searchByNameOnly?: boolean;
  allowCreation?: boolean;
  customOptionText?: string;
}

const STARTING_OPTIONS_FN = widgetOptions => [
  {
    // That's quite hacky way to show placeholder before user started typing
    label: widgetOptions?.placeholderLabel || 'Type donor organization name or email.',
    disabled: true
  }
];

function DonorLookupWidget({
  fieldName,
  placeholder,
  onSelect,
  onCreate,
  widgetOptions,
  rowProps = {}
}: {
  fieldName?: string; // Only in case error validation supposed to be displayed for this field
  onSelect: (donor: DonorModelShape | null) => void;
  onCreate?: (orgName: string) => void;
  placeholder?: string;
  widgetOptions?: DonorLookupWidgetOptions;
  rowProps?: Partial<EuiFormRowProps>;
}) {
  const scholarshipClient = useScholarshipClient();
  const { errors } = useFormContext();

  const [selectedOptions, setSelected] = useState<DonorLookupWidgetOption[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<DonorLookupWidgetOption[]>(STARTING_OPTIONS_FN(widgetOptions));

  const onChange = selectedOptions => {
    setSelected([...selectedOptions]);

    if (selectedOptions[0]?.entry) {
      onSelect(selectedOptions[0].entry);
    } else if (!selectedOptions?.length) {
      onSelect(null);
    }
  };

  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(value, 500);

  useEffect(() => {
    if (debouncedValue.length) {
      setLoading(true);
      setOptions([]);

      scholarshipClient
        .dataLookup(debouncedValue, widgetOptions?.searchByNameOnly ? 'donorOrgName' : 'donors')
        .then(donors => {
          setLoading(false);

          if (donors.length) {
            setOptions(
              donors.map(donor => ({
                label: donor.name,
                entry: donor
              }))
            );
          }
        });
    }
  }, [debouncedValue]);

  const renderOption = (option, searchValue) => {
    const { entry, label } = option;
    return (
      <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
        <EuiFlexItem>
          <EuiHighlight search={searchValue}>{label}</EuiHighlight>
        </EuiFlexItem>
        {!widgetOptions?.searchByNameOnly && entry?.email ? (
          <EuiFlexItem>
            <EuiHighlight search={searchValue}>{entry?.email}</EuiHighlight>
          </EuiFlexItem>
        ) : null}
      </EuiFlexGroup>
    );
  };

  const onCreateOption = (searchValue: string) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      label: searchValue
    };

    // Select the option.
    setSelected([newOption]);

    // emit the event
    onCreate && onCreate(searchValue);

    setOptions(STARTING_OPTIONS_FN(widgetOptions));
  };

  const fieldError: FieldError | null = fieldName ? getValueByDottedPath<FieldError>(fieldName, errors) : { type: '' };
  const isInvalid = !!fieldError?.message || false;

  return (
    <EuiFormRow {...rowProps} isInvalid={isInvalid} error={fieldError?.message || ''}>
      <EuiComboBox
        style={{ minWidth: '325px' }}
        placeholder={placeholder || 'Donor Organization Name'}
        options={options}
        selectedOptions={selectedOptions}
        isLoading={isLoading}
        onChange={onChange}
        onSearchChange={setValue}
        singleSelection={{ asPlainText: true }}
        renderOption={renderOption}
        rowHeight={widgetOptions?.searchByNameOnly ? 28 : 50}
        isClearable={false}
        onCreateOption={widgetOptions?.allowCreation ? onCreateOption : undefined}
        customOptionText={widgetOptions?.customOptionText}
        async
      />
    </EuiFormRow>
  );
}

export default DonorLookupWidget;
