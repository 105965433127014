import { EuiTextArea, EuiTextAreaProps } from '@elastic/eui';
import React from 'react';
import FormRow, { FormRowProps } from '../FormRow';
import { useFormContext, FieldError } from 'react-hook-form';
import { getValueByDottedPath } from '../util';

/** Wrapped EuiTextArea to have support for hook-form context and inbound formRow rendering */
export default function TextAreaField({
  fieldName,
  fieldProps,
  rowProps
}: {
  fieldName: string;
  fieldProps?: Partial<EuiTextAreaProps>;
  rowProps?: FormRowProps['rowProps'];
}) {
  const { register, errors } = useFormContext();

  const fieldError = getValueByDottedPath<FieldError>(fieldName, errors);
  const isInvalid = !!fieldError;
  const field = <EuiTextArea name={fieldName} isInvalid={isInvalid} inputRef={register} {...fieldProps} />;

  return rowProps ? (
    <FormRow
      rowKey={fieldName}
      rowProps={{ ...rowProps }}
      isInvalid={isInvalid}
      errorMessage={isInvalid ? fieldError?.message : ''}
    >
      {field}
    </FormRow>
  ) : (
    field
  );
}
