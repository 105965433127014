import React from 'react';
import {
  EuiButton,
  EuiModalBody,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiDatePicker,
  EuiSpacer,
  EuiText
} from '@elastic/eui';
import { RequestReviewStepProps } from '../../FormReviewRequestConfirmationModal';
import moment from 'moment';

const ReviewDeadlineSelectionStep = ({
  deadline,
  setDeadline,
  nextStep,
  prevStep,
  isSubmittingRequest
}: RequestReviewStepProps) => {
  const onPrevStep = () => {
    setDeadline(moment().add(1, 'day').utcOffset(0));
    prevStep();
  };

  return (
    <>
      <EuiModalBody>
        <EuiFlexGroup direction={'column'} responsive={false}>
          <EuiFlexItem>
            <EuiFormRow label="Application Form Review Deadline" fullWidth>
              <>
                <EuiSpacer size={'s'} />
                <EuiDatePicker utcOffset={0} inline selected={deadline} onChange={v => setDeadline(v)} fullWidth />
                <EuiSpacer size={'s'} />
                <EuiText size={'xs'} style={{ maxWidth: '450px' }}>
                  Donors will have until this date, to request changes to the application form. After the deadline
                  expires, the "Super Donor" will only be able to approve the form.
                </EuiText>
              </>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onPrevStep}>Back</EuiButtonEmpty>
        <EuiButton onClick={nextStep} isDisabled={isSubmittingRequest} isLoading={isSubmittingRequest} fill>
          Send
        </EuiButton>
      </EuiModalFooter>
    </>
  );
};

export default ReviewDeadlineSelectionStep;
