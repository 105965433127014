import React from 'react';

import DonorLookupWidget from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepContact/DonorLookupWidget';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { ComboboxField, PhoneField, TextField } from '@sharedComponents/formElements';
import allStates from '@sharedComponents/models/enumValues/states';

const OrganizationInput = ({ onExistingDonorSelection, onNewDonorCreation, formMethods }) => {
  const isDonorExists = formMethods.watch('isDonorExists');

  const isFreezed = isDonorExists === undefined || isDonorExists === true;

  return (
    <EuiFlexGroup direction="column" gutterSize="l" responsive={false}>
      <EuiFlexItem grow={false}>
        <DonorLookupWidget
          fieldName="donor.name"
          onSelect={onExistingDonorSelection}
          onCreate={onNewDonorCreation}
          widgetOptions={{
            placeholderLabel: 'Type your organization name.',
            searchByNameOnly: true,
            customOptionText: 'Use {searchValue} as your organization name',
            allowCreation: true
          }}
          rowProps={{
            label: 'Organization Name *'
          }}
        />
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <TextField
          fieldName={'donor.email'}
          fieldProps={{ disabled: isFreezed }}
          rowProps={{ label: 'Organization Email *' }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PhoneField fieldProps={{ disabled: isFreezed }} fieldName="donor.phone" label="Organization Phone Number" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <TextField
          fieldName={'donor.street'}
          fieldProps={{ placeholder: 'Street', disabled: isFreezed }}
          rowProps={{ label: 'Organization Mailing Address' }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {/** label is not empty, since we still need FormRow displayed, just with empty title */}
        <TextField
          fieldName={'donor.street2'}
          fieldProps={{ placeholder: 'Street 2', disabled: isFreezed }}
          rowProps={{ label: '' }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <TextField
          fieldName={'donor.city'}
          fieldProps={{ placeholder: 'City', disabled: isFreezed }}
          rowProps={{ label: '' }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <ComboboxField
          options={Object.keys(allStates).map(shortState => ({
            label: allStates[shortState],
            key: shortState,
            value: shortState
          }))}
          fieldName={'donor.state'}
          fieldProps={{ placeholder: 'State', singleSelection: true, isDisabled: isFreezed }}
          rowProps={{ label: '' }}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <TextField
          fieldName={'donor.zip'}
          fieldProps={{ placeholder: 'ZIP', disabled: isFreezed }}
          rowProps={{ label: '' }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default OrganizationInput;
