import openPreviewWindow, { previewForm } from '@donors/form/openPreviewWindow';
import { getServiceUrl } from '@sharedClients/getServiceUrl';
import { AWARD_STATUS } from '@sharedComponents/interfaces/Applications.interface';
import { useState } from 'react';

// legacy way to preview form, eventually should be replaced with preview hosted on the same domain(via shared components)
export default function useFormPreview() {
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);

  async function formPreview(form, isAwardAcceptancePreview = false) {
    let formToPreview = isAwardAcceptancePreview
      ? { ...form, data: [...(form?.data as Array<any>).filter(page => page?.path === 'awardAcceptance')] }
      : form;

    if (!formToPreview) {
      return;
    }

    const applyDomain = getServiceUrl('apply');

    let preview = previewWindow;

    if (!preview || !preview.window) {
      preview = await openPreviewWindow(applyDomain);
      setPreviewWindow(preview);
    }

    previewForm({
      previewWindow: preview,
      form: { ...(formToPreview as any), standardFields: [] },
      standardForm: [] as any,
      applyDomain,
      previewReason: 'preview',
      awardStatus: isAwardAcceptancePreview ? AWARD_STATUS.AWARD_NOTIFIED : AWARD_STATUS.CANDIDATE
    });
  }

  return {
    previewWindow: previewWindow,
    formPreview
  };
}
