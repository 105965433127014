import { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { ApplicationClient } from '@sharedClients/main';
import { loggedInUserState } from '@sharedComponents/atoms/loggedInUserState';
import { wrapToCatch401s } from '@sharedComponents/utils/wrappedFetch';

export function useApplicationClient() {
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const applicationClientCallback = useMemo(() => {
    const wrappedFetch = wrapToCatch401s(window.fetch, () =>
      setLoggedInUser(() => ({
        jwt: null,
        user: undefined
      }))
    );

    const applicationClient = new ApplicationClient(wrappedFetch, null, loggedInUser.jwt, null);

    return applicationClient;
  }, [loggedInUser.jwt]);

  return applicationClientCallback;
}
