import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { object } from 'yup';

import ResidencyEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/ResidencyEligibilitySelection';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep12 = ({ nextStepHandler }) => {
  const { watch } = useFormContext();
  const residencyMatching = watch('residencyMatching');

  const [isSelectionShown, setIsSelectionShown] = useState(residencyMatching !== 'Any');

  const choiceOptions = (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton
              color={'text'}
              fullWidth={false}
              onClick={() => {
                setIsSelectionShown(true);
              }}
            >
              YES
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NO
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NOT SURE
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>Does the student need to reside in a specific state or county?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>{isSelectionShown ? <ResidencyEligibilitySelection /> : choiceOptions}</EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep12.validationRules = object().shape({
  data: ScholarshipModelDataSchema.pick(['residency', 'residencyCounties'])
});

export default SelfListingScholarshipStep12;
