import useAfterPublicationLocation from '@sharedComponents/hooks/useAfterPublicationLocation';
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText
} from '@elastic/eui';
import { useRouter } from '@sharedComponents/hooks/useRouter';

const PublicationModal = ({ closeModal }) => {
  const newLocation = useAfterPublicationLocation();
  const router = useRouter();
  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiText color="success">
            <strong>Thank You!</strong>
          </EuiText>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiText size="m">
          Your scholarship has been submitted and will be reviewed by the Scholar's App team. We will let you know via
          email when it's published.
        </EuiText>
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton
          size="s"
          color="success"
          onClick={() => {
            closeModal();
            router.push(newLocation);
          }}
        >
          Got it
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default PublicationModal;
