import { ApplicationGridDataEntry, AwardingApplicationDataEntry } from '../config';

export enum AWARD_STEPS {
  AWARD_SELECTION,
  AWARD_AMOUNT_SELECTION,
  AWARD_AMOUNT_CONFIRMATION,
  EMAIL_DRAFTING,
  EMAIL_PREVIEW,
  AWARD_COMPLETED
}

export interface AwardStepProps {
  applicationGridData: ApplicationGridDataEntry[];
  setSelection: (selectedRecords: ApplicationGridDataEntry[]) => void;
  selection: AwardingApplicationDataEntry[];
  onAmountChange: (index: number, amount: number) => void;
  emailDraft: string; // markdown
  setEmailDraft: React.Dispatch<React.SetStateAction<string>>;
  emailSubject: string; // text
  setEmailSubject: React.Dispatch<React.SetStateAction<string>>;
  compiledEmailContent: string; // html
}
