import './PageBuilder.scss';

import { isNil } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { cn } from '@bem-react/classname';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiText, EuiToolTip } from '@elastic/eui';
import { FormStateInternals } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationPageSchema, ApplicationPageType } from '@sharedComponents/schemas/FormNodesSchema';

import FormNodeBuilder from './FormNodeBuilder';
import PageChildrenBuilder from './FormNodeBuilder/PageChildrenBuilder';
import { useFormNodeAdding, useFormNodeEdit, useFormNodeRemoval } from './hooks/useFormNodeModifiers';
import formStateActivePageSelector from './selectors/formStateActivePageSelector';
import { formStatePagesSelector } from './selectors/formStatePagesSelector';

const blockClass = cn('PageBuilder');
const PageBuilder = () => {
  const formStatePages = useRecoilValue<(ApplicationPageType & FormStateInternals)[]>(formStatePagesSelector);
  const editNodeByPath = useFormNodeEdit();
  const removeNodeByPath = useFormNodeRemoval();
  const addFormNodeByPath = useFormNodeAdding();

  const [activePage, setActivePage] = useRecoilState(formStateActivePageSelector);

  if (isNil(formStatePages)) {
    return null;
  }

  const changePageHandler = page => {
    setActivePage(page);
  };

  const addNewPageHandler = () => {
    addFormNodeByPath(ApplicationPageSchema.getDefault(), '');
  };

  return (
    <EuiFlexGroup className={blockClass()} direction="row" responsive={false}>
      <EuiFlexItem grow={1}>
        <EuiFlexGroup direction="column" gutterSize="m" responsive={false}>
          {formStatePages?.length ? (
            formStatePages.map((page, index) => {
              const pageTitle = page.title || page.children[0]?.title || '';
              const { isValid, message } = PageChildrenBuilder.validatePage(page);

              // todo confirm
              const handleDeleteField = e => {
                e.preventDefault();
                e.stopPropagation();
                removeNodeByPath(page.internalPath);
              };

              const handleEditPage = e => {
                e.preventDefault();
                e.stopPropagation();
                editNodeByPath(page.internalPath);
              };

              return (
                <EuiFlexItem key={`formnode_${index}`} grow={false}>
                  <EuiPanel
                    className={blockClass('panel', {
                      isActive: index === activePage
                    })}
                    hasShadow={true}
                    onClick={() => changePageHandler(index)}
                  >
                    <EuiFlexGroup direction="row" responsive={false}>
                      <EuiFlexItem>
                        <EuiFlexGroup
                          direction="row"
                          justifyContent="flexStart"
                          alignItems="center"
                          gutterSize="xs"
                          responsive={false}
                        >
                          <EuiFlexItem grow={false}>
                            {!isValid ? (
                              <EuiToolTip content={`Validation failed for this page: "${message}"`}>
                                <EuiIcon type={'cloudStormy'} color="warning" />
                              </EuiToolTip>
                            ) : (
                              <EuiToolTip content={`This page is valid!`}>
                                <EuiIcon type="check" color="success" />
                              </EuiToolTip>
                            )}
                          </EuiFlexItem>
                          <EuiFlexItem>
                            <EuiText>
                              Page {index + 1}: {pageTitle}
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiIcon type="documentEdit" size="m" title="Edit field" onClick={handleEditPage} />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiIcon
                              type="cross"
                              size="m"
                              color="danger"
                              title="Delete field"
                              onClick={handleDeleteField}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiPanel>
                </EuiFlexItem>
              );
            })
          ) : (
            <EuiText>No pages yet added</EuiText>
          )}

          <EuiButton color="primary" size="s" onClick={addNewPageHandler}>
            Add new page
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={3}>
        {formStatePages[activePage] ? <FormNodeBuilder node={formStatePages[activePage]} /> : null}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default PageBuilder;
