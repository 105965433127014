import React from 'react';

export default function BrowserCheck({ children }) {
  const incompatible = typeof URLSearchParams === 'undefined';

  if (incompatible) {
    return (
      <div
        className="BrowserCheck full-height"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2em'
        }}
      >
        <div>
          Your browser is several years old and Scholar's App will unfortunately not function with it. <br />
          <br />
          Sorry. Updating your browser will solve this.
        </div>
      </div>
    );
  } else {
    return children;
  }
}
