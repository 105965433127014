import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from '@elastic/eui';
import React, { useState } from 'react';
import html from 'rehype-stringify';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import unified from 'unified';
import AwardStepAmountConfirmation from './AwardingModal/AwardStepAmountConfirmation';
import AwardStepAmountInput from './AwardingModal/AwardStepAmountInput';
import AwardStepApplicationsSelections from './AwardingModal/AwardStepApplicationsSelections';
import AwardStepAwardingCompleted from './AwardingModal/AwardStepAwardingCompleted';
import AwardStepEmailDrafting from './AwardingModal/AwardStepEmailDrafting';
import AwardStepFinalPreview from './AwardingModal/AwardStepFinalPreview';
import { AWARD_STEPS } from './AwardingModal/config';
import { ApplicationGridDataEntry, AwardingApplicationDataEntry } from './config';
import './AwardingModal.scss';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

interface AwardingModalProps {
  applicationGridData: ApplicationGridDataEntry[];
  applicationClient: ApplicationClient;
  onClose: () => void;
}

// ! FIXME: test open modal right after finishing process. It opens with all applications instead of last round applications
const AwardingModal: React.FunctionComponent<AwardingModalProps> = ({
  applicationGridData,
  applicationClient,
  onClose
}) => {
  // applications selected to move in further steps
  const [selection, setSelection] = useState<AwardingApplicationDataEntry[]>([]);

  // step machine state
  const [currentStep, setCurrentStep] = useState<AWARD_STEPS>(AWARD_STEPS.AWARD_SELECTION);

  // markdown representation of draft email
  const [emailDraft, setEmailDraft] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  // markdown to html converted variant of emailDraft
  const [compiledEmailContent, setCompiledEmailContent] = useState('');

  const currentStepObject = {
    [AWARD_STEPS.AWARD_SELECTION]: AwardStepApplicationsSelections,
    [AWARD_STEPS.AWARD_AMOUNT_SELECTION]: AwardStepAmountInput,
    [AWARD_STEPS.AWARD_AMOUNT_CONFIRMATION]: AwardStepAmountConfirmation,
    [AWARD_STEPS.EMAIL_DRAFTING]: AwardStepEmailDrafting,
    [AWARD_STEPS.EMAIL_PREVIEW]: AwardStepFinalPreview,
    [AWARD_STEPS.AWARD_COMPLETED]: AwardStepAwardingCompleted
  }[currentStep];

  const changeAwardingStep = async (step: AWARD_STEPS | null) => {
    if (step === null) {
      return onClose();
    }

    if (step === AWARD_STEPS.EMAIL_PREVIEW) {
      const stringified = await unified()
        .use(markdown as any)
        .use(remark2rehype)
        .use(html)
        .process(emailDraft);
      setCompiledEmailContent(stringified.contents as string);
    } else if (step === AWARD_STEPS.AWARD_COMPLETED) {
      let applicationsAwards = {};
      for (const entry of selection) {
        applicationsAwards[entry.id] = entry.awardAmount;
      }

      await applicationClient.awardApplications(applicationsAwards, emailSubject, compiledEmailContent);
    }

    setCurrentStep(step);
  };
  return (
    <EuiModal onClose={onClose} className="awardingModal">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {typeof currentStepObject.headerTitle === 'function'
            ? currentStepObject.headerTitle(selection)
            : currentStepObject.headerTitle}
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup gutterSize="none">
          <EuiFlexItem>
            {currentStepObject.render({
              applicationGridData,
              setSelection: selectedRecords => {
                // we transform ApplicationGridDataEntry into AwardingApplicationDataEntry by adding index and empty awardAmount
                setSelection(
                  selectedRecords.map((record, index) => ({
                    ...record,
                    index: index,
                    awardAmount: 0
                  }))
                );
              },
              selection,
              onAmountChange: (index, amount) => {
                const selectedRecords = [...selection];
                selectedRecords[index].awardAmount = amount;
                setSelection(selectedRecords);
              },
              emailDraft,
              setEmailDraft,
              emailSubject,
              setEmailSubject,
              compiledEmailContent
            })}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup alignItems="center">
          {currentStepObject.actions(changeAwardingStep, selection).map(action => action)}
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AwardingModal;
