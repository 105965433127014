import masker from './masker';

const phoneMask = masker({
  masked: {
    mask: [
      {
        mask: '(000) 000-0000',
        regex: /^[\d]+$/g
      }
    ],
    dispatch: (appended, dynamicMasked) => {
      var number = (dynamicMasked.value + appended).replace(/\D/g, '');

      return dynamicMasked.compiledMasks.find(function (m) {
        const regex = new RegExp(m.regex);
        return regex.test(number);
      });
    }
  }
});

export default phoneMask;
