import { useMutation } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';

const ENDPOINT = () => `/v3/scholarships-invite`;

function useScholarshipInvite() {
  const fetcher = useFetcher();

  return {
    ...useMutation<
      void, // returned result result by our api
      Error, // error type
      number[]
      // parameters accepted by mutation
    >((ids: number[]) => {
      return fetcher()
        .url(ENDPOINT())
        .json({
          scholarshipsToInvite: ids
        })
        .post()
        .catch(error => {
          // ? this error shall be not blocking, but we may log it to sentry
          // eslint-disable-next-line no-console
          console.error(error);
        });
    })
  };
}

const inviteScholarshipsQuery = { useScholarshipInvite };

export default inviteScholarshipsQuery;
