import './EditScholarship.scss';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SlideDown } from 'react-slidedown';

import DonorWidget, { SelectedDonor } from '@donors/components/DonorWidget';
import { validateLengthAtLeast, validateUrl } from '@donors/components/validations';
import { EuiDatePicker, EuiDatePickerRange, EuiSpacer, EuiText } from '@elastic/eui';
import { ApplicationMaterial, FormSelection, ScholarshipPost } from '@sharedClients/types/ScholarshipModel';
import UserClient from '@sharedClients/UserClient';
import { Autocomplete } from '@sharedComponents/base/components/Autocomplete';
import ButtonBar from '@sharedComponents/base/components/ButtonBar';
import Field from '@sharedComponents/base/components/Field';
import MultipleSelectWidget from '@sharedComponents/base/components/MultipleSelectWidget';
import ValidatingField, { useSubmittable } from '@sharedComponents/base/components/ValidatingField';
import { toFormSelection } from '@sharedComponents/base/ScholarshipTypeUtils';
import useCachedState from '@sharedComponents/base/useCachedState';
import { UserError } from '@sharedComponents/base/useErrorReporter';
import useSingleSubmit from '@sharedComponents/base/useSingleSubmit';
import warn from '@sharedComponents/base/warn';
import AwardAmount from '@sharedComponents/EditScholarship/AwardAmount';
import Eligibility, { EligibilityHighSchool } from '@sharedComponents/EditScholarship/Eligibility';
import FaqQuestionAnswer from '@sharedComponents/EditScholarship/FaqQuestionAnswer';
import UploadFile, { IPastRecipients } from '@sharedComponents/EditScholarship/fileUpload';
import FormSelect from '@sharedComponents/EditScholarship/FormSelect';
import MultipleSelectState, { stateNames } from '@sharedComponents/EditScholarship/MultipleSelectState';
import RangeInput from '@sharedComponents/EditScholarship/RangeInput';
import { ReviewCriteriaConfiguration } from '@sharedComponents/interfaces/Scholarships.interface';
import allAcademicYears from '@sharedComponents/models/enumValues/academic_years';
import allCollegeTypes from '@sharedComponents/models/enumValues/college_types';
import allGenders from '@sharedComponents/models/enumValues/genders';
import allStudyRates from '@sharedComponents/models/enumValues/study_rates';
import { getApplicationCycleFromDate } from '@sharedComponents/utils/applicationCycleUtils';
import allAreas from '@sharedContract/areas';
import allcategory from '@sharedContract/category';
import allEthnicities from '@sharedContract/ethnicities';
import UserModel, { RecommenderSchool } from '@sharedContract/UserModel';

import ApplicationCycleSelect from './EditScholarship/ApplicationCycleSelect';
import ReviewCriteriaWidget from './EditScholarship/ReviewCriteriaWidget';
import FormFieldPdf from '@apply/apply/fields/FormFieldUpload';
import BackgroundFileUploader from '@sharedComponents/common/BackgroundFileUploader';
import { FormsModelShape } from '@sharedComponents/models';
import { ApplicationClient } from '@sharedClients/ApplicationClient';
import { isEmpty } from 'lodash';

export const defaultRecommenderFormId = 6; // TODO:IMP

export default function EditScholarship({
  scholarship,
  onSave,
  onCancel,
  onError,
  scholarshipClient,
  userClient,
  user,
  canAddForm,
  applicationClient,
  animationTime,
  searchDelay
}: {
  scholarship?: ScholarshipPost;
  onSave: (scholarship: ScholarshipPost) => any;
  onCancel: () => void;
  onError: (e: Error) => void;
  scholarshipClient;
  userClient: UserClient;
  user: UserModel;
  canAddForm: boolean;
  applicationClient: ApplicationClient;
  animationTime?: number;
  searchDelay?: number;
}) {
  scholarship = scholarship || ({ data: {} } as ScholarshipPost);

  const isAdmin = user.type === 'admin' || user.type === 'dataEntry'; // ? isAdminOrDataEntry? :D

  const canSetDonor = isAdmin;
  const applicationmaterialselect = ['Yes', 'No'];

  const [name, setName] = useState<string>(scholarship.name || '');
  const [form, setForm] = useState<FormSelection | undefined>(scholarship.form || undefined);

  const [customRecommenderForm, setCustomRecommenderForm] = useState(
    scholarship.recommenderForm && scholarship.recommenderForm.id !== defaultRecommenderFormId
  );
  const [recommenderForm, setRecommenderForm] = useState<FormSelection | undefined>(
    scholarship.recommenderForm as FormSelection
  );
  const [recommenderInstructions, setRecommenderInstructions] = useState(scholarship.recommenderInstructions || '');
  const [allowedRecommenders, setAllowedRecommenders] = useState(scholarship.allowedRecommenders || '');
  const [essayInstructions, setEssayInstructions] = useState(scholarship.essayInstructions || '');
  const [minRecommenders, setMinRecommenders] = useState<string>(
    scholarship.minRecommenders ? scholarship.minRecommenders + '' : ''
  );

  const [reviewCriteria, setReviewCriteria] = useState<ReviewCriteriaConfiguration | undefined>(
    scholarship.reviewCriteriasConfiguration
  );

  const [reviewInstructions, setReviewInstructions] = useState<number | null>(scholarship.reviewInstructions || null);
  const [logo, setLogo] = useState<number | null>(scholarship.logo || null);

  const [description, setDescription] = useState(scholarship.description || '');

  const [matchingCount, setMatchingCount] = useState<number | undefined>();

  const [startDate, setStartDate] = useState(
    scholarship.data.startDate ? moment.utc(scholarship.data.startDate) : undefined
  );

  const [deadline, setDeadline] = useState(scholarship.deadline ? moment(scholarship.deadline) : undefined);

  const [applicationCycle, setApplicationCycle] = useState<number | null>(scholarship.application_cycle || null);
  const [applicationCycleError, setApplicationCycleError] = useState('');

  const [lowIncome, setLowIncome] = useState(scholarship.data.lowIncome || false);

  const [communityService, setCommunityService] = useState(scholarship.data.communityService || false);

  const [firstGen, setFirstGen] = useState(scholarship.data.firstGen || false);

  const [college, setCollege] = useState<{ name: string; id: number } | null>(scholarship.data.college);

  const [faqQuestionAnswer, setfaqQuestionAnswer] = useState(scholarship.data.faqQustionAnswer || []);

  const [isSpecificCollege, setSpecificCollege] = useState<boolean>(scholarship.data.college != null);

  const [schoolStates, setSchoolStates] = useState<string[]>(scholarship.data.schoolStates || []);
  const [collegeStates, setCollegeStates] = useState<string[]>(scholarship.data.collegeStates || []);
  const [residency, setResidency] = useState<string[]>(scholarship.data.residency || []);
  const [academicYears, setAcademicYears] = useState<string[]>(scholarship.data.academicYears || []);
  const [genders, setGenders] = useState<string[]>(scholarship.data.genders || []);
  const [ethnicities, setEthnicities] = useState<string[]>(scholarship.data.ethnicities || []);
  const [categories, setcategories] = useState<string>(scholarship.data.categories || allcategory[0]);
  const [areas, setAreas] = useState<string[]>(scholarship.data.areas || []);

  const [collegeTypes, setCollegeTypes] = useState<string[]>(scholarship.data.collegeTypes || []);
  const [studyRates, setStudyRates] = useState<string[]>(scholarship.data.studyRates || []);
  const [gpaRange, setGpaRange] = useState<[number, number]>([
    scholarship.data.gpaFrom || 0,
    scholarship.data.gpaTo || 4
  ]);

  const [donor, setDonor] = useState<SelectedDonor>(
    scholarship.donor
      ? {
          isExisting: true,
          id: scholarship.donor.id,
          name: scholarship.donor.name,
          email: scholarship.donor.email
        }
      : { isExisting: false }
  );

  const [eligibility, setEligibility] = useState(!isEmpty(scholarship.eligibility) ? scholarship.eligibility : []);
  const [eligibilityHighSchool, setEligibilityHighSchool] = useState<Array<RecommenderSchool>>(
    scholarship.data.schools || []
  );
  const [awardAmount, setAwardAmount] = useState<(number | null)[]>(getAwardAmountArray(scholarship));
  const [candidatesLimit, setCandidatesLimit] = useState<number | null | undefined>(scholarship.candidates_limit);
  const [originalUrl, setOriginalUrl] = useState(scholarship.originalUrl);
  const [guidestarUrl, setguidestarUrl] = useState(scholarship.data.guidestarUrl);
  const [applyUrl, setApplyUrl] = useState(scholarship.applyUrl);
  const [customApplyUrl, setCustomApplyUrl] = useState(!scholarship.form);
  const [requireTranscript, setRequireTranscript] = useState(!!scholarship.requireTranscript);
  const [allowQuestions, setAllowQuestions] = useState(!!scholarship.allowQuestions);
  const [isGrant, setIsGrant] = useState<boolean>(scholarship.is_grant);
  const [isPremium, setIsPremium] = useState<boolean>(scholarship.is_premium);
  const [isHidden, setIsHidden] = useState<boolean>(scholarship.data?.is_hidden || false);
  const [isUsingRecurrentPayments, setIsUsingRecurrentPayments] = useState<boolean>(
    scholarship.data?.is_using_recurrent_payments || false
  );
  const [hasHiddenDeadline, setHasHiddenDeadline] = useState<boolean>(scholarship.data.is_hidden_deadline || false);

  const [forms, setForms] = useCachedState<FormsModelShape[]>('forms', []);
  const [singleSubmit, isSubmitting] = useSingleSubmit();

  const [notes, setNotes] = useState(scholarship.data.notes);
  const [discardMessage, setDiscardMessage] = useState(scholarship.data.discardMessage);

  const [essay, setessay] = useState<string>(
    scholarship.data.ApplicationMaterial?.essay || applicationmaterialselect[0]
  );
  const [letterofrecommendation, setletterofrecommendation] = useState<string>(
    scholarship.data.ApplicationMaterial?.letterofRecommendation || applicationmaterialselect[0]
  );
  const [transcript, settranscript] = useState<string>(
    scholarship.data.ApplicationMaterial?.transcript || applicationmaterialselect[0]
  );

  const [applicationmaterialnotes, setapplicationmaterialnotes] = useState<string>(
    scholarship.data.ApplicationMaterial?.notes || ''
  );

  const [files, setFiles] = useState<Array<IPastRecipients>>(
    scholarship.data.pastRecipients || [
      {
        name: '',
        note: '',
        pastRecipientId: 0
      }
    ]
  );
  const canReview = (isAdmin || (user.donor && user.donor.canReview)) && !customApplyUrl;

  useEffect(() => {
    scholarshipClient.getForms().then(setForms).catch(onError); // TODO:IMP: This is fetching all forms. Might be a wrong approach
  }, [scholarshipClient]);

  const { validatingFieldDefaults, submittableFields, setSubmitted, hasSubmitted } = useSubmittable();

  function isSubmittable() {
    const fieldWithError = Object.entries(submittableFields.current).find(entry => {
      if (entry[0] === 'applyURL' && !customApplyUrl) {
        return false;
      } else {
        const isFieldSubmittable = entry[1];

        return !isFieldSubmittable;
      }
    });

    // TODO:IMP: eventually change to a proper validation >.<
    if (!applicationCycle) {
      setApplicationCycleError('Please, specify valid start date and deadline.');
      return false;
    }

    setApplicationCycleError('');

    return !fieldWithError;
  }

  // We have scholarship passed up here with params, I would prefer handling it via some model, without such unsafe `factory`
  function buildScholarship(): ScholarshipPost {
    let postForm: FormSelection | null = null,
      postRecommenderForm: FormSelection | null = null,
      postApplyUrl: string | null = null;

    if (customApplyUrl) {
      postApplyUrl = sanitizeUrl(applyUrl);
    } else {
      postForm = toFormSelection(form);
      postRecommenderForm = (
        customRecommenderForm
          ? toFormSelection(recommenderForm)
          : {
              id: defaultRecommenderFormId,
              name: 'Default recommender form'
            }
      ) as any;
    }
    const appmaterial: ApplicationMaterial = {
      essay: essay,
      letterofRecommendation: letterofrecommendation,
      transcript: transcript,
      notes: applicationmaterialnotes
    };

    return {
      name: name || 'Unnamed',
      logo: logo || null,
      recommenderInstructions: recommenderInstructions || null,
      allowedRecommenders: allowedRecommenders || null,
      essayInstructions: essayInstructions || null,
      minRecommenders:
        isNaN(parseInt(minRecommenders)) || parseInt(minRecommenders) === 0 ? null : parseInt(minRecommenders),
      description,
      form: postForm,
      recommenderForm: postRecommenderForm,
      eligibility: eligibility.filter(e => !!e),
      deadline: deadline ? deadline.format('YYYY-MM-DD') : '',
      awardAmountFrom: awardAmount[0] || 0,
      awardAmountTo: awardAmount[awardAmount.length - 1] || 0,
      applyUrl: postApplyUrl,
      requireTranscript,
      allowQuestions,
      // question,
      // answer,
      // guidestarUrl,
      originalUrl: originalUrl,
      reviewCriteria: reviewCriteria,
      reviewInstructions: reviewInstructions,
      data: {
        schoolStates: nullMeansAny(schoolStates, stateNames),
        collegeStates: nullMeansAny(collegeStates, stateNames),
        collegeTypes: nullMeansAny(collegeTypes, allCollegeTypes),
        residency: nullMeansAny(residency, stateNames),
        studyRates: nullMeansAny(studyRates, allStudyRates),
        academicYears: nullMeansAny(academicYears, allAcademicYears),
        ethnicities: nullMeansAny(ethnicities, allEthnicities),
        // categories: nullMeansAny(categories, allcategory),
        categories: categories,

        guidestarUrl: guidestarUrl,
        faqQustionAnswer: faqQuestionAnswer,
        genders: nullMeansAny(genders, allGenders),
        areas: nullMeansAny(areas, allAreas),
        gpaFrom: gpaRange[0] != null ? gpaRange[0] : 0,
        gpaTo: gpaRange[1] != null ? gpaRange[1] : 4,
        lowIncome,
        communityService,
        firstGen,
        college: isSpecificCollege ? college : null,
        startDate: startDate ? startDate.toString() : undefined,
        notes,
        ApplicationMaterial: appmaterial,
        pastRecipients: files.filter(recipient => recipient.name && recipient.note),
        schools: eligibilityHighSchool,
        discardMessage,
        is_hidden: isHidden,
        is_using_recurrent_payments: isUsingRecurrentPayments,
        is_hidden_deadline: hasHiddenDeadline,
      },
      donor: canSetDonor
        ? {
            id: donor.id as number,
            name: donor.name as string,
            email: donor.email as string
          }
        : undefined,
      scholarship_id: scholarship?.scholarship_id, // TODO verify this logic
      isDraft: scholarship?.isDraft, // mapped straight from property as this is not changeable field
      application_cycle: applicationCycle || getApplicationCycleFromDate(),
      is_grant: isGrant,
      candidates_limit: candidatesLimit,
      is_premium: isPremium
    };
  }

  const validateRewardAmount = value => {
    const maxValue = 999999999;
    const numberIsTooBigErrorMessage =
      'Maximum Amount Awarded is 9 digits. Please contact scholarships@scholarsapp.com if you would like to award a larger amount. Thank you!';
    const negativeNumberErrorMessage = 'Negative numbers are not allowed';

    if (value.length === 2 && (value[0] > value[1] || (value[0] == null) !== (value[1] == null))) {
      return 'Invalid range';
    }
    if (value.length === 1 && value[0] > maxValue) {
      return numberIsTooBigErrorMessage;
    }
    if (value.length === 2 && (value[0] > maxValue || value[1] > maxValue)) {
      return numberIsTooBigErrorMessage;
    }
    if ((value[0] && value[0] < 0) || (value[1] && value[1] < 0)) {
      return negativeNumberErrorMessage;
    }

    return null;
  };

  function onSubmit(e: Event) {
    e.preventDefault();

    setSubmitted(true);

    if (isSubmittable()) {
      /* TODO: this has to be reworked into something more complex...
       *  we cannot rely on lots of objects with straightforward checks to build database model
       */

      let validpastreceipts = true;
      for (let i = 0; i < files.length; i++) {
        // old hack which has to be reworked eventually
        let { name, note } = files[i];
        name = name.trim();
        note = note.trim();

        // both fields must be set or left empty
        if ((name !== '' && note === '') || (note !== '' && name === '')) {
          validpastreceipts = false;
        }
      }

      if (!validpastreceipts) {
        onError(new UserError('Please fill past recipients information'));
        return;
      }

      return onSave(buildScholarship()); // TODO Scholarship model class
    } else {
      onError(new UserError('Some fields contain invalid values.'));
    }
  }

  useEffect(() => {
    // TODO for drafts?
    if (
      schoolStates.length ||
      collegeTypes.length ||
      academicYears.length ||
      genders.length ||
      collegeStates.length ||
      ethnicities.length ||
      areas.length ||
      lowIncome ||
      communityService
    ) {
      try {
        userClient
          .getMatchingUserCount(buildScholarship().data)
          .then(r => setMatchingCount(r.count))
          .catch(warn);
      } catch (err: any) {
        onError(err);
      }
    } else {
      setMatchingCount(undefined);
    }
  }, [
    schoolStates,
    collegeTypes,
    academicYears,
    genders,
    lowIncome,
    communityService,
    collegeStates,
    ethnicities,
    areas
  ]);

  const applicationCycleEdit = (
    <>
      <div className="field">
        <label>Start Date and Deadline</label>
        <EuiDatePickerRange
          startDateControl={
            <EuiDatePicker
              selected={startDate?.utcOffset(0)}
              onChange={date => setStartDate(date || undefined)}
              maxDate={deadline}
              isInvalid={!startDate}
              autoComplete="off"
              id="start_date"
            />
          }
          endDateControl={
            <EuiDatePicker
              selected={deadline?.utcOffset(0)}
              onChange={date => setDeadline(date || undefined)}
              minDate={startDate}
              isInvalid={!deadline}
              autoComplete="off"
              id="deadline"
            />
          }
          fullWidth
        />
      </div>
      <div className="field">
        {startDate && deadline ? (
          <ApplicationCycleSelect
            startDate={startDate}
            endDate={deadline}
            onApplicationCycleSelect={setApplicationCycle}
            applicationCycle={applicationCycle}
          />
        ) : (
          <EuiText color="danger">{applicationCycleError}</EuiText>
        )}
      </div>
    </>
  );

  return (
    <form onSubmit={singleSubmit(onSubmit)} className="EditScholarship">
      <div className="pageTitle">
        <h4>Edit {isGrant ? 'Grant' : 'Scholarship'}</h4>
        <h2>{scholarship.name || 'New Scholarship'}</h2>
      </div>

      {matchingCount != null ? (
        <div className="matchingCount">
          {matchingCount}
          {matchingCount === 100 ? '+' : ''} matching applicant
          {matchingCount !== 1 ? 's' : ''}
        </div>
      ) : null}

      <div className="row">
        <div className="col">
          <div className="cols">
            <h3>General</h3>

            <ValidatingField
              label="Name"
              id="name"
              value={name}
              setValue={setName}
              validate={validateLengthAtLeast(3)}
              renderChildren={props => <input {...props} type="text" />}
              {...validatingFieldDefaults}
            />

            <div className="field">
              <div className="checkbox">
                <input
                  id="is_grant"
                  type="checkbox"
                  checked={isGrant}
                  onChange={e => {
                    setIsGrant(e.target.checked);
                  }}
                />
                <label htmlFor="is_grant">Is a Grant</label>
              </div>
              <div className="checkbox">
                <input
                  id="is_premium"
                  type="checkbox"
                  checked={isPremium}
                  onChange={e => {
                    setIsPremium(e.target.checked);
                  }}
                />
                <label htmlFor="is_premium">Premium Listing</label>
              </div>
              <div className="checkbox">
                <input
                  id="is_hidden"
                  type="checkbox"
                  checked={isHidden}
                  onChange={e => {
                    setIsHidden(e.target.checked);
                  }}
                />
                <label htmlFor="is_hidden">Hidden from public listings</label>
              </div>
              <div className="checkbox">
                <input
                  id="is_using_recurrent_payments"
                  type="checkbox"
                  checked={isUsingRecurrentPayments}
                  onChange={e => {
                    setIsUsingRecurrentPayments(e.target.checked);
                  }}
                />
                <label htmlFor="is_using_recurrent_payments">Using recurrent payments</label>
              </div>
              <div className="checkbox">
                <input
                  id="is_hidden_deadline"
                  type="checkbox"
                  checked={hasHiddenDeadline}
                  onChange={e => {
                    setHasHiddenDeadline(e.target.checked);
                  }}
                />
                <label htmlFor="is_hidden_deadline">Has hidden deadline</label>
              </div>
            </div>

            {user.type === 'admin' ? (
              <Field label="Category" htmlFor="categories">
                <div className="FormFieldSelect">
                  <select
                    id="categories"
                    multiple={false}
                    onChange={e => setcategories(e.target.value)}
                    value={categories}
                  >
                    {allcategory.map(cat => (
                      <option key={cat} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </select>
                  <svg viewBox="0 0 300 300" className="Caret">
                    <path d="M30,100L150,200L270,100"></path>
                  </svg>
                </div>

                {/* {
              <MultipleSelectWidget
                id="categories"
                onChange={setcategories}
                selected={categories}
                options={allcategory}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              /> } */}
              </Field>
            ) : null}

            {applicationCycleEdit}

            <ValidatingField
              label="Candidates Limit"
              id="candidates_limit"
              help="Close application cycle instantly when a certain number of completed applications are received."
              value={candidatesLimit}
              setValue={setCandidatesLimit}
              renderChildren={props => <input {...props} />}
              {...validatingFieldDefaults}
            />

            <ValidatingField
              label="Description"
              id="description"
              help="Tell students about the purpose of the scholarship."
              value={description}
              setValue={setDescription}
              validate={validateLengthAtLeast(50)}
              renderChildren={props => <textarea {...props} rows={4} />}
              {...validatingFieldDefaults}
            />

            <ValidatingField
              label="Amount awarded"
              id="awardAmount"
              help="Value of the scholarship."
              value={awardAmount}
              setValue={setAwardAmount}
              validate={validateRewardAmount}
              renderChildren={props => <AwardAmount {...props} />}
              {...validatingFieldDefaults}
            />

            <ValidatingField
              label="Donor listing URL"
              id="originalURL"
              help="A link to the scholarship listing on the donors' website (if available)."
              value={originalUrl || ''}
              setValue={setOriginalUrl}
              validate={str => (!str ? null : validateUrl(str))}
              renderChildren={props => <input {...props} placeholder="http://" />}
              {...validatingFieldDefaults}
            />

            <ValidatingField
              label="Guidestar URL"
              id="guidestarUrl"
              value={guidestarUrl || ''}
              setValue={setguidestarUrl}
              validate={str => (!str ? null : validateUrl(str))}
              renderChildren={props => <input {...props} placeholder="http://" />}
              {...validatingFieldDefaults}
            />
          </div>

          <div className="cols">
            <h3>Current studies</h3>

            <p className="help">At what level should the applicant currently be? Where should they be located?</p>

            <Field label="Current level" htmlFor="academicYears">
              <MultipleSelectWidget
                id="academicYears"
                onChange={setAcademicYears}
                selected={academicYears}
                options={allAcademicYears}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              />
            </Field>

            <div className="gpaRange">
              <ValidatingField
                label="GPA"
                id="gpa"
                value={gpaRange}
                setValue={range => setGpaRange(range)}
                validate={validateGpaRange}
                renderChildren={props => <RangeInput {...props} />}
                {...validatingFieldDefaults}
              />
            </div>

            <Field
              label="In state(s)"
              htmlFor="schoolStates"
              help='If only open to a certain counties/postcodes, both select the state and add the counties under "applicant must..."'
            >
              <MultipleSelectState
                id="schoolStates"
                onChange={setSchoolStates}
                selected={schoolStates}
                hasSubmitted={hasSubmitted}
              />
            </Field>

            <Field label="Resident of state(s)" htmlFor="residency">
              <MultipleSelectState
                id="residency"
                onChange={setResidency}
                selected={residency}
                hasSubmitted={hasSubmitted}
              />
            </Field>
          </div>

          <div className="cols">
            <FaqQuestionAnswer value={faqQuestionAnswer} setValue={setfaqQuestionAnswer} />
          </div>

          <div className="cols">
            {canSetDonor ? (
              <div>
                {canReview ? (
                  <>
                    <EuiSpacer size="s" />
                    <ReviewCriteriaWidget
                      reviewCriteriasConfiguration={reviewCriteria}
                      onChange={val => {
                        setReviewCriteria(val);
                      }}
                    />
                    <ValidatingField
                      label="Criteria Instructions"
                      id="reviewInstructions"
                      help="Upload a PDF of the criteria instructions provided by the donor. This document gives the reviewer details on how to score each criteria."
                      value={reviewInstructions}
                      setValue={setReviewInstructions}
                      renderChildren={props => (
                        <FormFieldPdf
                          node={{
                            getValue: () => ({
                              id: props.value
                            })
                          }}
                          onFieldChange={value => props.onChange({ target: { value: value && value.id } })}
                          applicationClient={applicationClient}
                          donorId={null}
                          application={null}
                          applicantId={null}
                        />
                      )}
                      {...validatingFieldDefaults}
                    />
                  </>
                ) : null}

                <h3>Donor Organization</h3>

                <DonorWidget
                  initialValue={donor}
                  onChange={setDonor}
                  onError={onError}
                  userClient={userClient}
                  user={user}
                  type={user.type}
                  {...validatingFieldDefaults}
                />
              </div>
            ) : null}
          </div>
          {user.type === 'admin' ? (
            <div className="cols">
              <h3>Notes</h3>

              <ValidatingField
                label="Notes"
                id="notes"
                help="Internal notes. These are not kept secret; do not write anything here that you would not want a user to see."
                value={notes}
                setValue={setNotes}
                validate={() => null}
                renderChildren={props => <textarea className="notes-area" {...props} rows={8} />}
                {...validatingFieldDefaults}
              />
            </div>
          ) : null}
          <div className="cols">
            <h3>Discard Message</h3>

            <ValidatingField
              label=""
              id="discardMessage"
              help="Message displayed to students if they consider that they are not eligible for this scholarship"
              value={discardMessage}
              setValue={setDiscardMessage}
              validate={() => null}
              renderChildren={props => <textarea className="notes-area" {...props} rows={8} />}
              {...validatingFieldDefaults}
            />
          </div>
        </div>

        <div className="col">
          <div className="cols">
            <h3>Eligibility</h3>

            <Field label="Gender" htmlFor="genders" help="Leave empty for any.">
              <MultipleSelectWidget
                id="genders"
                onChange={setGenders}
                selected={genders}
                options={allGenders}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              />
            </Field>

            <Field
              label="Group identities"
              htmlFor="ethniticies"
              help='Target a religious, ethnic or other minority such as disabled people. Add "other requirements" to be more specific.'
            >
              <MultipleSelectWidget
                id="ethniticies"
                onChange={setEthnicities}
                selected={ethnicities}
                options={allEthnicities}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              />
            </Field>

            <div className="field">
              <div className="checkbox">
                <input
                  id="lowIncome"
                  type="checkbox"
                  checked={lowIncome}
                  onChange={e => setLowIncome(e.target.checked)}
                />
                <label htmlFor="lowIncome">Only low-income applicants</label>
              </div>
            </div>

            <div className="field">
              <div className="checkbox">
                <input
                  id="communityService"
                  type="checkbox"
                  checked={communityService}
                  onChange={e => setCommunityService(e.target.checked)}
                />
                <label htmlFor="communityService">Must have done community service</label>
              </div>
            </div>

            <div className="field">
              <div className="checkbox">
                <input id="firstGen" type="checkbox" checked={firstGen} onChange={e => setFirstGen(e.target.checked)} />
                <label htmlFor="firstGen">Must be the first person in family to attend college</label>
              </div>
            </div>

            <Eligibility value={eligibility} setValue={setEligibility} />
            <EligibilityHighSchool
              value={eligibilityHighSchool}
              setValue={setEligibilityHighSchool}
              applicationClient={applicationClient}
            />
            <div>
              <h3>Application Materials</h3>
              <Field label="Essay" htmlFor="Essay">
                <div className="FormFieldSelect">
                  <select id="Essay" multiple={false} onChange={e => setessay(e.target.value)} value={essay}>
                    {applicationmaterialselect.map(am => (
                      <option key={am} value={am}>
                        {am}
                      </option>
                    ))}
                  </select>
                  <svg viewBox="0 0 300 300" className="Caret">
                    <path d="M30,100L150,200L270,100"></path>
                  </svg>
                </div>
              </Field>
              <Field label="Letter of Recommendation" htmlFor="LetterofRecommendation">
                <div className="FormFieldSelect">
                  <select
                    id="LetterofRecommendation"
                    multiple={false}
                    onChange={e => setletterofrecommendation(e.target.value)}
                    value={letterofrecommendation}
                  >
                    {applicationmaterialselect.map(am => (
                      <option key={am} value={am}>
                        {am}
                      </option>
                    ))}
                  </select>
                  <svg viewBox="0 0 300 300" className="Caret">
                    <path d="M30,100L150,200L270,100"></path>
                  </svg>
                </div>
              </Field>
              <Field label="Transcript" htmlFor="Transcript">
                <div className="FormFieldSelect">
                  <select
                    id="Transcript"
                    multiple={false}
                    onChange={e => settranscript(e.target.value)}
                    value={transcript}
                  >
                    {applicationmaterialselect.map(am => (
                      <option key={am} value={am}>
                        {am}
                      </option>
                    ))}
                  </select>
                  <svg viewBox="0 0 300 300" className="Caret">
                    <path d="M30,100L150,200L270,100"></path>
                  </svg>
                </div>
              </Field>

              <div className="field">
                <ValidatingField
                  label="Notes"
                  id="Notes"
                  help="Please add any additional notes you would like the applicant to read."
                  value={applicationmaterialnotes || ''}
                  setValue={setapplicationmaterialnotes}
                  renderChildren={props => <input {...props} placeholder="Notes" />}
                  {...validatingFieldDefaults}
                />
              </div>
            </div>
          </div>
          <div className="cols">
            <h3>Future studies</h3>

            <p className="help">
              Which type of college studies should the applicant be applying for? Leave empty if any.
            </p>

            <Field
              label="Field"
              htmlFor="areas"
              help='Major or area of work. Need a very specific field? Choose the closest one in the list and add the specific field under "applicant must..."'
            >
              <MultipleSelectWidget
                id="areas"
                onChange={setAreas}
                selected={areas}
                options={allAreas}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              />
            </Field>

            <Field label="Study rate" htmlFor="studyRates">
              <MultipleSelectWidget
                id="studyRates"
                onChange={setStudyRates}
                selected={studyRates}
                options={allStudyRates}
                warnOnMissingRequired={hasSubmitted}
                isMissingRequiredFields={() => true}
              />
            </Field>

            <Field label="College" className="college">
              <div className="radio-label">
                <input
                  type="radio"
                  id="specificCollege"
                  value="applicant"
                  checked={isSpecificCollege}
                  onChange={() => setSpecificCollege(true)}
                />

                <label className="radio" htmlFor="specificCollege">
                  A specific college
                </label>
              </div>

              {isSpecificCollege ? (
                <div className="radio-content">
                  <Field label="College" htmlFor="college">
                    <Autocomplete
                      id="college"
                      value={college}
                      onChange={option => {
                        if (option && option.id != null) {
                          const { name, id } = option;

                          setCollege({ name, id });
                          setCollegeTypes([]);

                          if (option.state) {
                            setCollegeStates([option.state]);
                          } else {
                            setCollegeStates([]);
                          }
                        } else {
                          setCollege(null);
                        }
                      }}
                      applicationClient={applicationClient}
                      autocomplete="colleges"
                      animationTime={animationTime}
                      searchDelay={searchDelay}
                    />
                  </Field>
                </div>
              ) : null}

              <div className="radio-label">
                <input
                  type="radio"
                  id="anyCollege"
                  value="recommender"
                  checked={!isSpecificCollege}
                  onChange={() => setSpecificCollege(false)}
                />
                <label className="radio" htmlFor="anyCollege">
                  Any college as long as it's...
                </label>
              </div>

              {!isSpecificCollege ? (
                <div className="radio-content">
                  <Field label="...in state(s)" htmlFor="collegeStates">
                    <MultipleSelectState
                      id="collegeStates"
                      onChange={setCollegeStates}
                      selected={collegeStates}
                      hasSubmitted={hasSubmitted}
                    />
                  </Field>

                  <Field label="...of type" htmlFor="collegeTypes">
                    <MultipleSelectWidget
                      id="collegeTypes"
                      onChange={setCollegeTypes}
                      selected={collegeTypes}
                      options={allCollegeTypes}
                      warnOnMissingRequired={hasSubmitted}
                      isMissingRequiredFields={() => true}
                    />
                  </Field>
                </div>
              ) : null}
            </Field>
          </div>

          <div className="cols">
            <h3>Application Process</h3>

            <div className="field">
              <div className="checkbox">
                <input
                  id="customApplyUrl"
                  type="checkbox"
                  checked={!customApplyUrl}
                  disabled={mustUseCustomApplyUrl(user)} // TODO test this when admin creates scholarship, and data entry edits
                  onChange={e => {
                    const isCustom = !e.target.checked;

                    setCustomApplyUrl(isCustom);
                  }}
                />
                <label htmlFor="customApplyUrl">Manage application process using Scholar's App</label>
              </div>
            </div>

            <SlideDown className="FormFieldConditional" transitionOnAppear={false}>
              {customApplyUrl ? (
                <div className="customApplyFields">
                  <ValidatingField
                    label="Application URL"
                    id="applyURL"
                    help="A link to the online application for the scholarship."
                    value={applyUrl || ''}
                    setValue={setApplyUrl}
                    validate={str => validateUrl(str, { mustHavePath: false })}
                    renderChildren={props => <input {...props} placeholder="http://" />}
                    {...validatingFieldDefaults}
                  />
                </div>
              ) : !mustUseCustomApplyUrl(user) ? (
                <div className="customApplyFields">
                  <Field label="Logo" htmlFor="logo">
                    <BackgroundFileUploader
                      value={logo}
                      onChange={value => setLogo(value as any)}
                      publicUpload={true}
                      accept="image/*"
                    />
                  </Field>

                  <Field
                    label="Application form"
                    htmlFor="form"
                    help="The set of fields to use in the application form."
                  >
                    <FormSelect
                      id="form"
                      value={form}
                      type="applicant"
                      forms={forms}
                      onChange={form => setForm(form)}
                      canAdd={canAddForm}
                    />
                  </Field>

                  <ValidatingField
                    label="Essay instructions"
                    id="essayInstructions"
                    help="Tell the applicant your requirements on the essay."
                    value={essayInstructions}
                    setValue={setEssayInstructions}
                    renderChildren={props => <textarea {...props} rows={3} />}
                    {...validatingFieldDefaults}
                  />

                  <ValidatingField
                    label="Instructions for recommender"
                    id="recommenderInstructions"
                    help="Tell the recommender what you require of a letter of recommendation."
                    value={recommenderInstructions}
                    setValue={setRecommenderInstructions}
                    renderChildren={props => <textarea {...props} rows={3} />}
                    {...validatingFieldDefaults}
                  />

                  <ValidatingField
                    label="Allowed recommenders"
                    id="allowedRecommenders"
                    help="Who may and may not act as recommender?"
                    value={allowedRecommenders}
                    setValue={setAllowedRecommenders}
                    renderChildren={props => <input {...props} />}
                    {...validatingFieldDefaults}
                  />

                  <ValidatingField
                    label="Required number of recommendations"
                    id="minRecommenders"
                    value={minRecommenders}
                    setValue={setMinRecommenders}
                    renderChildren={props => <input type="number" {...props} max={5} />}
                    {...validatingFieldDefaults}
                  />

                  <div className="field">
                    <div className="checkbox">
                      <input
                        id="customRecommender"
                        type="checkbox"
                        onChange={e => setCustomRecommenderForm(e.target.checked)}
                        checked={!!customRecommenderForm}
                      />
                      <label htmlFor="customRecommender">Customize recommender form</label>
                    </div>
                  </div>

                  <SlideDown className="FormFieldConditional" transitionOnAppear={false}>
                    {customRecommenderForm ? (
                      <Field
                        label="Recommender form"
                        htmlFor="recommenderForm"
                        help="The set of fields to display to the recommender when providing the letter of recommendation."
                      >
                        <FormSelect
                          id="recommenderForm"
                          value={recommenderForm}
                          type="recommender"
                          forms={forms}
                          onChange={form => setRecommenderForm(form)}
                          canAdd={canAddForm}
                        />
                      </Field>
                    ) : null}
                  </SlideDown>
                </div>
              ) : null}
              <div className="field">
                <div className="checkbox">
                  <input
                    id="requireTranscript"
                    type="checkbox"
                    checked={requireTranscript}
                    onChange={e => setRequireTranscript(!!e.target.checked)}
                  />
                  <label htmlFor="requireTranscript">
                    Request a transcript {customApplyUrl ? ' and forward it to the donor' : ''}
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="checkbox">
                  <input
                    id="allowQuestions"
                    type="checkbox"
                    checked={allowQuestions}
                    onChange={e => setAllowQuestions(!!e.target.checked)}
                  />
                  <label htmlFor="allowQuestions">Allow students to ask questions of the donor.</label>
                </div>
              </div>
            </SlideDown>
          </div>
        </div>
      </div>

      <div className="column">
        <UploadFile value={files} setValue={setFiles} onError={onError} />
      </div>

      <ButtonBar className="button-bar button-align-right">
        {user.type === 'dataEntry' ? (
          <button disabled={isSubmitting} className="button m-draft">
            Save as Draft
          </button>
        ) : (
          <button className="button" disabled={isSubmitting}>
            {user.type === 'admin' && scholarship?.isDraft ? 'Save and Publish' : 'Save'}
          </button>
        )}
        <button
          className="button"
          onClick={e => {
            onCancel();
            e.preventDefault();
          }}
        >
          Cancel
        </button>
      </ButtonBar>
    </form>
  );
}

export function sanitizeUrl(url: string | null) {
  if (url == null) {
    return null;
  }

  try {
    return new URL(url).toString();
  } catch (e) {
    return null;
  }
}

function validateGpaRange([from, to]) {
  if (isNaN(from) || isNaN(to)) {
    return 'Not a number.';
  } else if (from < 0 || from > to) {
    return 'Invalid range.';
  } else if (to > 4.0) {
    return 'At most 4.0.';
  } else {
    return null;
  }
}

function mustUseCustomApplyUrl(user: UserModel) {
  return user.type !== 'admin';
}

/**
 * Due to how Postgres searching works, we need to represent "any" as null, not as empty string.
 * However, this is also handled by the backend before inserting/updating the scholarship, so
 * we could remove it here.
 */
function nullMeansAny(list: string[] | null, options: any[]) {
  if (
    list &&
    (list.length === 0 ||
      // selecting all options also means any
      list.length === options.length)
  ) {
    return null;
  } else {
    return list;
  }
}

function getAwardAmountArray(scholarship: ScholarshipPost): (number | null)[] {
  if (scholarship.awardAmountTo === 0) {
    return [null];
  }

  if (scholarship.awardAmountFrom === scholarship.awardAmountTo) {
    return [scholarship.awardAmountFrom];
  } else {
    return [scholarship.awardAmountFrom, scholarship.awardAmountTo];
  }
}

export function capitalize(str: string) {
  if (str) {
    return str[0].toUpperCase() + str.substr(1);
  } else {
    return '';
  }
}
