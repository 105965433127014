export default [
  'High school freshman',
  'High school sophomore',
  'High school junior',
  'High school senior',
  'Undergraduate freshman',
  'Undergraduate sophomore',
  'Undergraduate junior',
  'Undergraduate senior',
  'Graduate student',
  'PhD student',
  '6th Grade',
  '7th Grade',
  '8th Grade'
];
