import React, { useEffect, useState } from 'react';
import { EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui';
import { FormReviewerOption } from '../../../FormReviewRequestConfirmationModal/steps/DonorRolesAssigningStep';
import { escapeRegExp } from 'lodash';

export default function ReviewerSelectField({
  userOptions,
  selectedIds,
  onChange,
  usedValues
}: {
  userOptions: any[];
  selectedIds: FormReviewerOption[];
  onChange: (v) => void;
  usedValues: Array<number | undefined>;
}) {
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const updateFilteredOptions = () => {
      let newFilteredOptions = [...userOptions];

      if (searchTerm) {
        const escapedSearchTerm = escapeRegExp(searchTerm || '');
        let searchRegExp = new RegExp(escapedSearchTerm, 'ig');

        newFilteredOptions = newFilteredOptions.filter(opt => {
          return (
            (searchRegExp.test(opt.label) || searchRegExp.test(opt.data.email)) &&
            (!usedValues.includes(opt.value) || (selectedIds.length && opt.value === selectedIds[0]))
          );
        });
      }

      setFilteredOptions(newFilteredOptions);
    };
    updateFilteredOptions();
  }, [searchTerm, selectedIds, usedValues]);

  const renderOption = (option, searchValue) => {
    const { data, label } = option as FormReviewerOption;
    return (
      <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
        <EuiFlexItem>
          <EuiHighlight search={searchValue}>{label}</EuiHighlight>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiHighlight search={searchValue}>{data.email}</EuiHighlight>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  return (
    <EuiComboBox
      placeholder={'Start typing donor name to view suggestions.'}
      options={searchTerm ? filteredOptions : []}
      noSuggestions={!searchTerm}
      selectedOptions={selectedIds}
      onChange={onChange}
      onSearchChange={v => setSearchTerm(v)}
      singleSelection={{ asPlainText: true }}
      renderOption={renderOption}
      rowHeight={50}
      isClearable={false}
      fullWidth
      async
    />
  );
}
