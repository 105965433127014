import React from 'react';

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';

import AbstractDeclineStep from './AbstractDeclineStep';
import { NOTIFY_DECLINED_STEPS } from './config';

const NotifyDeclinedStepConfirmRecipients: AbstractDeclineStep = {
  headerTitle: 'Confirm Recipients',
  actions(changeStep, affectedStudentsCount) {
    const actions: React.ReactNode[] = [];

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton onClick={() => changeStep(NOTIFY_DECLINED_STEPS.NOTIFY_CONFIRMATION)}>
          Back
        </EuiButton>
      </EuiFlexItem>
    );

    actions.push(
      <EuiFlexItem key={htmlIdGenerator()()}>
        <EuiButton
          isDisabled={!affectedStudentsCount}
          onClick={() => changeStep(NOTIFY_DECLINED_STEPS.EMAIL_DRAFTING)}
          fill
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    );

    return actions;
  },

  render({ affectedStudentsCount }) {
    return (
      <EuiFlexGroup direction="column" gutterSize="none">
        <EuiFlexItem>
          <EuiText>
            Since all available scholarship awards have been accepted for this application cycle, you can now notify
            students who did not receive an award.
            <br /> The email you will draft on the next screen, will be sent to {affectedStudentsCount}{' '}
            {affectedStudentsCount === 1 ? 'student' : 'students'} listed in the "Candidates", "Eliminated", and
            "Missing Documents" tabs.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default NotifyDeclinedStepConfirmRecipients;
