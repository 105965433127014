import { ADDRESS_PLACEMENT_OPTIONS, DELIVERABILITY_STATUS, MAILTYPE_OPTIONS } from '../interfaces/Mailing.interface';
import { string, mixed, boolean, object, InferType, number, array, date } from 'yup';

// response from lob
const AddressVerificationServiceResponseSchema = object({
  // thats schema for LOB integration service response
  id: string(),
  recipient: string(),
  primary_line: string(),
  secondary_line: string(),
  last_line: string(),
  deliverability: string().oneOf(Object.values(DELIVERABILITY_STATUS)),
  components: mixed(), // Record<string, unknown>; // A nested object containing a breakdown of each component of an address.
  // ? could be used for better errors highlighting?
  deliverability_analysis: mixed() // Record<string, unknown>; // A nested object containing a breakdown of the deliverability of an address.
});

export type AddressVerificationServiceResponseSchemaType = InferType<typeof AddressVerificationServiceResponseSchema>;

/**
 * Represents CSV record uploaded by admin for mailing
 */
export const MailingAddressSchema = object({
  city: string().required(),
  state: string().optional(),
  primary_line: string().required(),
  recipient: string().max(40).label('Recipient').required(),
  secondary_line: string().optional(),
  zip_code: string().optional()
});

export type MailingAddressType = InferType<typeof MailingAddressSchema>;

// extending this schema with fields added by our side
export const AddressVerificationSchema = AddressVerificationServiceResponseSchema.shape({
  // extra data we add on our side
  address: mixed(), // MailingAddressSchema type here, the original record from CSV parsed
  is_selected_for_sending: boolean()
});

export type AddressVerificationType = InferType<typeof AddressVerificationSchema>;

/**
 * Represents HTML template variables
 */
export const MailingTemplateVariablesSchema = object({
  letter_content: string().required(),
  logo_img_ID: number().typeError('Letter Logo is required').label('Letter Logo').required(),
  scholarship_name: string().required(),
  qr_code_url: string().url().required(),
  who_text: string().required(),
  eligibility_text: string().required()
});

export type MailingTemplateVariablesType = InferType<typeof MailingTemplateVariablesSchema>;

export const MailingModelSchema = object({
  id: number().optional(), // since this object might be not yet in database, id is not required

  // template related
  isHTMLtemplateUsed: boolean(), // virtual

  pdf_file_id: number()
    .nullable()
    .default(null)
    .when('isHTMLtemplateUsed', {
      is: false,
      then: number().required().label('PDF file').typeError('PDF file is required'),
      otherwise: number().nullable().default(null)
    }),
  pdf_page_size: number()
    .nullable()
    .default(null)
    .when('isHTMLtemplateUsed', {
      is: false,
      then: number().required().typeError('Pages amount is required').label('Number of Pages'),
      otherwise: number().nullable().default(null)
    }),

  html_variables: object()
    .nullable()
    .default(null)
    .when('isHTMLtemplateUsed', {
      is: true,
      then: MailingTemplateVariablesSchema.required(),
      otherwise: object().nullable().default(null)
    }),
  ///

  address_file_id: number().label('CSV file').typeError('Addresses file is required').required(),

  recipients: array(AddressVerificationSchema).required().min(1).default([]),

  color: boolean().default(false),
  description: string().max(255).nullable(),
  send_date: date().label('Send Date').nullable(),
  /**
   * A string designating the mail postage type:

usps_first_class - (default)
usps_standard - a cheaper option which is less predictable and takes longer to deliver. usps_standard cannot be used with 4x6 postcards or for any postcards sent outside of the United States.
   */
  mail_type: string().oneOf(MAILTYPE_OPTIONS).default(MAILTYPE_OPTIONS[0]),
  /**
   * Set this attribute to true for double sided printing, or false for for single sided printing.
   */
  double_sided: boolean().default(true),

  /**
   * Specifies the location of the address information that will show through the double-window envelope. To see how this will impact your letter design, view our letter template.

top_first_page - (default) print address information at the top of your provided first page
insert_blank_page - insert a blank address page at the beginning of your file (you will be charged for the extra page)
bottom_first_page_center - (deprecation planned within a few months) print address information at the bottom center of your provided first page
bottom_first_page - print address information at the bottom of your provided first page
   */
  address_placement: string().oneOf(ADDRESS_PLACEMENT_OPTIONS).default(ADDRESS_PLACEMENT_OPTIONS[0]),

  // after submitting mailing to server, we store response here
  service_response: mixed()
});

// yup magically creates TypeScript type definition here
export type MailingModelType = InferType<typeof MailingModelSchema>;
