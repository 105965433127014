import React from 'react';
import { object } from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { ButtonsChoiceField } from '@sharedComponents/formElements';
import { ScholarshipModelDataApplicationMaterialSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep7 = ({ nextStepHandler }) => {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>Does the student need to provide an essay?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <ButtonsChoiceField
          fieldName={'data.ApplicationMaterial.essay'}
          buttons={[
            {
              value: 'Yes',
              callback: () => nextStepHandler(),
              label: 'YES'
            },
            {
              value: 'No',
              callback: () => nextStepHandler(),
              label: 'NO'
            },
            {
              value: null,
              callback: () => nextStepHandler(),
              label: 'NOT SURE'
            }
          ]}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep7.validationRules = object().shape({
  data: object().shape({
    ApplicationMaterial: ScholarshipModelDataApplicationMaterialSchema.pick(['essay'])
  })
});

export default SelfListingScholarshipStep7;
