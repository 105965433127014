import './Applications.scss';

import { History } from 'history';
import React, { useEffect } from 'react';

import useCachedState from '@donors/base/useCachedState';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { EuiText } from '@elastic/eui';
import { filterSubmitted } from '@sharedClients/main';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import ApplicationList, { applicationListFields } from '@sharedComponents/ApplicationList/ApplicationList';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

import Distributions from './Distributions';

interface ApplicationsProps {
  filter?: string | null;
  history: History;
}

// ! FIXME this one re-renders couple of times while login, executing requests
export default function Applications(props: ApplicationsProps) {
  const applicationClient = useApplicationClient();
  const showDistributions = true;

  const [onError, errorMessage] = useErrorReporter();
  const [applications, setApplications] = useCachedState<ApplicationModel[]>('applications.' + filterSubmitted, []);

  useEffect(() => {
    applicationClient
      .getApplications(applicationListFields, `${filterSubmitted},limit:50`)
      .then(setApplications)
      .catch(onError);
  }, [applicationClient]);

  return (
    <div className="Applications">
      <div className="body">
        <div className="content">
          {errorMessage}

          <EuiText size="s" className="description">
            Most recent applications, including scholarships hosted by Scholar's App and externally hosted scholarships.
          </EuiText>

          <ApplicationList applications={applications} isLeads={false} />
        </div>

        {showDistributions ? (
          <div className="right">
            <Distributions applicationClient={applicationClient} onError={onError} {...props} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
