import {
  EuiBadge,
  EuiDataGrid,
  EuiDataGridControlColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  formatDate
} from '@elastic/eui';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  SelectionButton,
  SelectionContext,
  SelectionHeaderCell,
  SelectionRowCell,
  NumRowCell
} from './ApplicationsDataGrid/SelectionContext';

import { UserModelShape } from '@sharedComponents/models';
import { isNil } from 'lodash';
import { DATA_ACTIONS, SELECTION_ACTIONS } from './ApplicationsDataGrid/config';
import { ApplicationGridDataEntry } from './config';
import { DataContext, RenderCellValue } from './ApplicationsDataGrid/DataContext';
import ActionsRowCellRenderer from './ApplicationsDataGrid/ActionsRowCellRenderer';

const stringScoresToArray = field =>
  field
    .replace(/\(\D+\)/g, '')
    .trim()
    .split(' ')
    .map(str => parseInt(str))
    .filter(val => !isNaN(val));

enum TRAILING_CELL_OPTIONS {
  ASSIGNATIONS
}

// Because inMemory's level is set to `pagination` we still need to sort the data
const sorter = (data, sortingFields) => {
  return [...data].sort((a, b) => {
    for (let i = 0; i < sortingFields.length; i++) {
      const column: {
        id: string;
        direction: 'asc' | 'desc';
      } = sortingFields[i];

      let aValue, bValue;
      // per round stats are held into different columns than its in table
      if (column.id.startsWith('round_')) {
        const roundID = column.id.split('_')[1];

        const roundsStats_a = a['roundsStats'];
        const roundsStats_b = b['roundsStats'];
        if (roundsStats_a && roundsStats_a[roundID]) {
          aValue = roundsStats_a[roundID]?.score;
        } else {
          aValue = 0;
        }

        if (roundsStats_b && roundsStats_b[roundID]) {
          bValue = roundsStats_b[roundID]?.score;
        } else {
          bValue = 0;
        }
      } else {
        aValue = a[column.id];
        bValue = b[column.id];
      }

      if (aValue < bValue) {
        return column.direction === 'asc' ? -1 : 1;
      }

      if (aValue > bValue) {
        return column.direction === 'asc' ? 1 : -1;
      }
    }

    return 0;
  });
};

function ApplicationsDataGrid({
  applications,
  reviewers,
  gridActions,
  columns,
  hasSelect = true,
  trailingCellOptions
}: {
  applications: ApplicationGridDataEntry[];
  reviewers: UserModelShape[];
  gridActions: (assignedApplications) => JSX.Element[];
  columns;
  hasSelect: boolean;
  trailingCellOptions: TRAILING_CELL_OPTIONS[];
}) {
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns]
  );

  const [data, dispatch] = useReducer(
    (reviewRoundData, { action, context, value }: { action: DATA_ACTIONS; context: any; value: any }) => {
      if (action === DATA_ACTIONS.UPDATE_SORTING) {
        // value represents new sortingColumns
        return sorter(reviewRoundData, value);
      } else if (action === DATA_ACTIONS.UPDATE_DATA) {
        // value represents new data set
        return sorter(value, sortingColumns);
      }

      if (action === DATA_ACTIONS.CLEAR_ASSIGNMENTS) {
        const selection = context as Set<number>;
        if (!selection.size) {
          return reviewRoundData;
        }

        const _data = [...reviewRoundData];
        for (const applicationID of selection) {
          const index = _data.findIndex(app => app.id === applicationID);
          _data[index] = { ..._data[index], reviewers: [] };
        }

        return _data;
      } else if (action === DATA_ACTIONS.ASSIGN_SELECTED_TO_ALL) {
        // action for assigning multiple selected records to all organization reviewers
        const selection = context as Set<number>;
        if (!selection.size) {
          return reviewRoundData;
        }

        const _data = [...reviewRoundData];

        for (const applicationID of selection) {
          const index = _data.findIndex(app => app.id === applicationID);

          if (_data[index]) {
            _data[index] = { ..._data[index], reviewers: [...reviewers] };
          }
        }

        return _data;
      } else if (action === DATA_ACTIONS.ASSIGN_SELECTED) {
        // SelectionButton context action for multiple selected records
        const selection = context as Set<number>;
        if (!selection.size) {
          return reviewRoundData;
        }

        const _data = [...reviewRoundData];
        // at this point we should assign new reviewer to all selected rows OR unassign in case that reviewer is assigned to all the records
        let isUnassignation = true;
        for (const applicationID of selection) {
          if (!isUnassignation) {
            break;
          }

          const application = _data.find(app => app.id === applicationID);
          // this one is heavy
          // ? consider switching _data to Map
          if (!application?.reviewers.includes(value)) {
            isUnassignation = false;
          }
        }

        for (const applicationID of selection) {
          const index = _data.findIndex(app => app.id === applicationID);

          if (isUnassignation) {
            _data[index] = { ..._data[index], reviewers: [..._data[index].reviewers.filter(val => val !== value)] };
          } else {
            // only assign if not yet assigned
            if (!(_data[index]?.reviewers || []).includes(value)) {
              _data[index] = { ..._data[index], reviewers: [..._data[index].reviewers, value] };
            }
          }
        }

        return _data;
      } else if (action === DATA_ACTIONS.ASSIGN_SINGLE) {
        const _data = [...reviewRoundData];

        if (!isNaN(parseInt(context)) && context >= 0) {
          if (_data[context].reviewers.some(reviewer => reviewer.id === value.id)) {
            _data[context] = {
              ..._data[context],
              reviewers: [..._data[context].reviewers.filter(reviewer => reviewer.id !== value.id)]
            };
          } else {
            _data[context] = { ..._data[context], reviewers: [..._data[context].reviewers, value] };
          }
        }

        return _data;
      }

      return [...reviewRoundData];
    },
    applications
  );

  // ? test if memo here is actually needed
  const dataContext = useMemo(
    () => ({
      data,
      dispatch
    }),
    [data]
  );

  useEffect(() => {
    dispatch({
      action: DATA_ACTIONS.UPDATE_DATA,
      value: applications,
      context: null
    });
  }, [applications]);

  useEffect(() => {
    dispatch({
      action: DATA_ACTIONS.UPDATE_SORTING,
      value: sortingColumns,
      context: null
    });
  }, [sortingColumns]);

  // handling euigrid internals
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: data.length > 25 ? 25 : data.length
  });

  const [visibleColumns, setVisibleColumns] = useState(() =>
    columns.filter(({ hiddenByDefault }) => !hiddenByDefault).map(({ id }) => id)
  );

  const setPageIndex = useCallback(
    pageIndex => setPagination({ ...pagination, pageIndex }),
    [pagination, setPagination]
  );

  const setPageSize = useCallback(
    pageSize => setPagination({ ...pagination, pageSize, pageIndex: 0 }),
    [pagination, setPagination]
  );

  // ? any clever way to have payload type based on action?
  const [selection, dispatchSelection] = useReducer((rowSelection, { action, payload }) => {
    if (action === SELECTION_ACTIONS.ADD_SELECTION) {
      const _selection = payload as Array<number>;

      const _rowSelection = new Set([...rowSelection]);
      _selection.forEach(item => _rowSelection.add(item));
      return _rowSelection;
    } else if (action === SELECTION_ACTIONS.DELETE_SELECTION) {
      const _selection = payload as Array<number>;

      const _rowSelection = new Set([...rowSelection]);
      _selection.forEach(item => _rowSelection.delete(item));
      return _rowSelection;
    } else if (action === SELECTION_ACTIONS.CLEAR_ALL) {
      return new Set();
    } else if (action === SELECTION_ACTIONS.SELECT_ALL) {
      return new Set(data.map(application => application.id));
    }

    return rowSelection;
  }, new Set());

  const leadingControlColumns: EuiDataGridControlColumn[] = [];

  if (hasSelect) {
    leadingControlColumns.push(
      ...[
        {
          id: 'selection',
          width: 40,
          headerCellRender: () => SelectionHeaderCell(data.length),
          rowCellRender: SelectionRowCell
        },
        {
          id: 'num',
          width: 40,
          headerCellRender: () => <div style={{ textAlign: 'center' }}>#</div>,
          rowCellRender: NumRowCell
        }
      ]
    );
  }

  const trailingControlColumns: EuiDataGridControlColumn[] = [];

  if (trailingCellOptions.includes(TRAILING_CELL_OPTIONS.ASSIGNATIONS)) {
    trailingControlColumns.push({
      id: 'actions',
      width: 64,
      headerCellRender: () => <>Actions</>,
      rowCellRender: cellDetails => (
        <ActionsRowCellRenderer cellDetails={cellDetails} data={data} reviewers={reviewers} dispatch={dispatch} />
      )
    });
  }

  const footerCellValues = {
    name: `Total: ${data.length}`
  };

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <DataContext.Provider value={dataContext}>
          <SelectionContext.Provider value={{ selection, dispatchSelection }}>
            <EuiDataGrid
              className="applicationsDataGrid"
              height={undefined}
              aria-label="Review round setup"
              leadingControlColumns={leadingControlColumns}
              trailingControlColumns={trailingControlColumns}
              columns={columns}
              columnVisibility={{
                visibleColumns,
                setVisibleColumns
              }}
              toolbarVisibility={{
                showColumnSelector: {
                  allowHide: true,
                  allowReorder: false
                },
                showSortSelector: true,
                additionalControls: <SelectionButton reviewers={reviewers} dispatchActionForSelection={dispatch} />
              }}
              rowCount={data.length}
              renderCellValue={RenderCellValue}
              sorting={{ columns: sortingColumns, onSort }}
              inMemory={{ level: 'pagination' }}
              pagination={{
                ...pagination,
                pageSizeOptions: data.length > 25 ? [25, 50, 100] : undefined,
                onChangeItemsPerPage: setPageSize,
                onChangePage: setPageIndex
              }}
              schemaDetectors={[
                {
                  type: 'scores',
                  isSortable: true,
                  // Try to detect if column data is this schema. A value of 1 is the highest possible. A (mean_average - standard_deviation) of .5 will be good enough for the autodetector to assign.
                  detector() {
                    return 0;
                    // return typeof value === 'object' && Array.isArray(value) && (value[0] as any)?.score ? 1 : 0;
                  },
                  // How we should sort data matching this schema. Again, a value of 1 is the highest value.
                  comparator(a: string, b: string, direction) {
                    // a and b are string representations of scores. We should get averages by review and sort
                    const aScores = stringScoresToArray(a);
                    const bScores = stringScoresToArray(b);
                    const avgA = aScores.length ? aScores.reduce((prev, curr) => prev + curr, 0) / aScores.length : 0;
                    const avgB = bScores.length ? bScores.reduce((prev, curr) => prev + curr, 0) / bScores.length : 0;

                    if (avgA > avgB) {
                      return direction === 'asc' ? 1 : -1;
                    }

                    if (avgA < avgB) {
                      return direction === 'asc' ? -1 : 1;
                    }

                    return 0;
                  },
                  // Text for what the ASC sort does.
                  sortTextAsc: 'average round score low-high',
                  // Text for what the DESC sort does.
                  sortTextDesc: 'average round score high-low',
                  // EuiIcon or Token to signify this schema.
                  icon: 'filter',
                  defaultSortDirection: 'asc'
                },
                {
                  type: 'stringNumber',
                  isSortable: true,
                  detector() {
                    return 0;
                  },
                  // How we should sort data matching this schema. Again, a value of 1 is the highest value.
                  comparator(a, b, direction) {
                    const floatA = a ? parseFloat(a) : 0;
                    const floatB = b ? parseFloat(b) : 0;
                    if (floatA < floatB) {
                      return direction === 'desc' ? 1 : -1;
                    } else if (floatA > floatB) {
                      return direction === 'desc' ? -1 : 1;
                    }

                    return 0;
                  },
                  sortTextAsc: 'Low-High',
                  sortTextDesc: 'High-Low',
                  icon: 'filter'
                }
              ]}
              popoverContents={{
                scores: ({ children }) => {
                  const dataGridValue = data[(children as any).props.rowIndex];
                  const { columnId } = (children as any).props;

                  const roundsStats = dataGridValue['roundsStats']; // ? type from ApplicationWithScores
                  const roundID = columnId.split('_')[1];
                  if (!roundsStats || !roundsStats[roundID] || !roundsStats[roundID]?.reviews?.length) {
                    return <>No detailed information available</>;
                  }

                  const roundStats = roundsStats[roundID];
                  return roundStats.reviews.map(review => {
                    const reviewer = reviewers.find(u => u.id === review.user_id);
                    return !isNil(review?.score) ? (
                      <EuiText size="s" key={review.user_id}>
                        {review.created_at ? formatDate(review.created_at, 'LLL') : null}{' '}
                        <strong>
                          {reviewer?.data?.name
                            ? `${reviewer?.data?.name} ${reviewer?.data?.lastName || ''}`
                            : 'Unknown Reviewer'}
                        </strong>{' '}
                        - <EuiBadge>{review.score}</EuiBadge> <br />
                        {review.notes ? <i>{review.notes}</i> : null}
                      </EuiText>
                    ) : null;
                  });
                }
              }}
              renderFooterCellValue={({ columnId }) => footerCellValues[columnId] || null}
            />
          </SelectionContext.Provider>
        </DataContext.Provider>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          {gridActions(data).map((button, index) => (
            <EuiFlexItem key={index}>{button}</EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

ApplicationsDataGrid.TRAILING_CELL_OPTIONS = TRAILING_CELL_OPTIONS;

export default ApplicationsDataGrid;
