import React from 'react';

import StudentInvitations, { StudentInvitationsViewMode } from '@apply/content/StudentInvitations';
import Step from './AbstractSignupStep';
import { EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui';

const studentInvitationsStep: Step = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  render({ completeSignup }: { completeSignup: Function }) {
    return (
      <EuiFlexGroup direction="column" gutterSize="s" responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>Thanks for signing up for Scholar's App!</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <StudentInvitations
            confirmationCallback={async invitations => {
              await completeSignup({
                invitations: invitations
              });
            }}
            initialViewMode={StudentInvitationsViewMode.REGISTRATION_VIEW}
            user={null}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  },

  // has own continue flow
  async getNextStep() {
    return null;
  },

  renderControls() {
    return <></>;
  }
};

export default studentInvitationsStep;
