import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { useUserClient } from '@sharedComponents/selectors/useUserClient';
import { UserProfile } from '@sharedComponents/interfaces/UserData.interface';
import recommendedScholarshipsQuery from '@apply/queries/recommendedScholarshipsQuery';
import { useLoggedInUserProfileUpdate } from '@sharedComponents/selectors/loggedInUserSelectors';

// unique key for this query
const CURRENT_QUERY_KEY = 'profile';

// ? could gather user from hook
function useUserProfile(userID: number, options: UseQueryOptions<UserProfile, Error, UserProfile> = {}) {
  const userClient = useUserClient();
  const query = {
    ...useQuery<UserProfile, Error>([CURRENT_QUERY_KEY, userID], () => userClient.getUserProfile(userID), {
      staleTime: 1000 * 60 * 15, // we have to trust our query to not re-render while user might be changing data already
      refetchOnWindowFocus: false,
      ...options
    })
  };

  return query;
}

// mutation to upsert current(!!!) user profile
const useMutateUserProfileData = id => {
  const updateLoggedIeUserProfile = useLoggedInUserProfileUpdate();
  const queryClient = useQueryClient();
  const userClient = useUserClient();

  return useMutation<
    UserProfile, // returned result result by our api
    Error, // error type
    UserProfile['data']
    // parameters accepted by mutation
  >(updatedProfileData => userClient.updateUserProfileData(id, updatedProfileData), {
    onSuccess: userProfile => {
      // user profile update request returns updated profile, so we can refresh our query
      queryClient.setQueryData([CURRENT_QUERY_KEY, id], userProfile);

      // invalidate recommended scholarships
      queryClient.invalidateQueries(recommendedScholarshipsQuery.QUERY_KEY);

      // refresh atom data
      updateLoggedIeUserProfile(userProfile.data);
    }
  });
};

const userProfileQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useUserProfile, useMutateUserProfileData };
export default userProfileQuery;
