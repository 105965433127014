import './DonorHomepage.scss';
import '@elastic/charts/dist/theme_only_light.css';

import { mapKeys } from 'lodash';
import React, { useState } from 'react';

import { useErrorReporter, UserError } from '@donors/base/useErrorReporter';
import { EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import useInvitationConfirm from '@sharedComponents/hooks/useInvitationConfirm';
import { ScholarshipModelShape } from '@sharedComponents/models';
import myOrganizationScholarshipEntryQuery from '@sharedComponents/queries/myOrganizationScholarshipEntryQuery';

import Banner from './DonorHomepage/Banner';
import Controls from './DonorHomepage/Controls';
import NewQualifyingStudentsList from './DonorHomepage/NewQualifyingStudentsListBlock';
import NewQualifyingStudentsNumberBlock from './DonorHomepage/NewQualifyingStudentsNumberBlock';
import PageClicksBlock from './DonorHomepage/PageClicksBlock';
import PageSuggestionsBlock from './DonorHomepage/PageSuggestionsBlock';
import PageViewsBlock from './DonorHomepage/PageViewsBlock';
import eventsDataQuery from './DonorHomepage/queries/eventsDataQuery';
import QuestionsBlock from './DonorHomepage/QuestionsBlock';
import StudentDocumentationBlock from './DonorHomepage/StudentDocumentationBlock';

const dateRangeFilterOptionsMap = {
  1: { label: 'Last 1 day', value: 1 },
  7: { label: 'Last 7 days', value: 7 },
  30: { label: 'Last 30 days', value: 30 },
  3650: { label: 'Lifetime', value: 3650 }
};

export default function DonorHomepage() {
  const [onError] = useErrorReporter();

  // confirming invitations if any
  useInvitationConfirm();

  const [dateRangeFilter, setDateRangeFilter] = useState(30);
  const [scholarshipsMap, setScholarshipsMap] = useState<{ [key: number]: ScholarshipModelShape }>({});
  const [selectedScholarshipId, setSelectedScholarshipId] = useState<number | null>(null);

  const myScholarships = myOrganizationScholarshipEntryQuery.useMyOrganizationScholarships({
    onSuccess: scholarships => {
      setScholarshipsMap(mapKeys(scholarships, 'id'));

      if (scholarships.length) {
        setSelectedScholarshipId(scholarships[0].id);
      }
    }
  });

  const { data: eventsData, isLoading: isEventsDataLoading } = eventsDataQuery.useEventsData(
    selectedScholarshipId,
    dateRangeFilter
  );

  if (myScholarships.isError) {
    const errorMessage = 'Something went wrong. Please, try again later.';
    onError(new UserError(errorMessage));

    return (
      <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
        {errorMessage}
      </EuiCallOut>
    );
  }

  let selectedScholarship: ScholarshipModelShape | null = null;
  if (selectedScholarshipId) {
    selectedScholarship = scholarshipsMap![selectedScholarshipId];
  }

  return (
    <div className="DonorHomepage">
      <EuiFlexGroup direction={'column'} className="DonorHomepage__content" gutterSize={'none'} responsive={false}>
        <EuiFlexItem grow={false}>
          <Controls
            {...{
              scholarshipsMap,
              selectedScholarshipId,
              setSelectedScholarshipId,
              dateRangeFilter,
              dateRangeFilterOptionsMap,
              setDateRangeFilter,
              selectedScholarship,
              areScholarshipsLoading: myScholarships.isLoading
            }}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <Banner />
        </EuiFlexItem>

        <EuiFlexItem>
          <div className="DonorHomepage__grid">
            <NewQualifyingStudentsNumberBlock
              eventsData={eventsData}
              dateRangeFilter={dateRangeFilter}
              selectedScholarship={selectedScholarship}
              isLoading={isEventsDataLoading || myScholarships.isLoading}
            />
            <div>
              <PageViewsBlock
                eventsData={eventsData}
                selectedScholarship={selectedScholarship}
                dateRangeFilter={dateRangeFilter}
                isLoading={isEventsDataLoading || myScholarships.isLoading}
              />
              <PageSuggestionsBlock
                eventsData={eventsData}
                dateRangeFilter={dateRangeFilter}
                selectedScholarship={selectedScholarship}
                isLoading={isEventsDataLoading || myScholarships.isLoading}
              />
              <PageClicksBlock
                eventsData={eventsData}
                dateRangeFilter={dateRangeFilter}
                selectedScholarship={selectedScholarship}
                isLoading={isEventsDataLoading || myScholarships.isLoading}
              />
            </div>
            <NewQualifyingStudentsList
              selectedScholarshipId={selectedScholarshipId}
              dateRangeFilter={dateRangeFilter}
              areScholarshipsLoading={myScholarships.isLoading}
            />
            <QuestionsBlock
              selectedScholarshipId={selectedScholarshipId}
              areScholarshipsLoading={myScholarships.isLoading}
            />
            <StudentDocumentationBlock
              selectedScholarshipId={selectedScholarshipId}
              areScholarshipsLoading={myScholarships.isLoading}
            />
          </div>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
