import React from 'react';
import { EuiFlexItem, EuiLink, EuiText } from '@elastic/eui';
import { NumberField } from '@sharedComponents/formElements';
import FileField from '@sharedComponents/formElements/combined/FileField';

const PDFTemplateSetup = () => (
  <>
    <EuiFlexItem>
      <EuiText>
        Upload the PDF of the letter that will be mailed.All pages of the PDF must be size 8.5"x11".
        <br />
        For letter formatting requirements, see{' '}
        <EuiLink
          target="_blank"
          href="https://s3-us-west-2.amazonaws.com/public.lob.com/assets/templates/letter_template.pdf"
        >
          this PDF template
        </EuiLink>
        .
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem>
      <NumberField
        fieldName="pdf_page_size"
        rowProps={{
          label: 'Number of Pages',
          fullWidth: true
        }}
      />
    </EuiFlexItem>
    <EuiFlexItem>
      <FileField
        fieldName={'pdf_file_id'}
        rowProps={{
          label: 'PDF letter',
          helpText: '',
          fullWidth: true
        }}
        fieldProps={{
          accept: '.pdf',
          publicUpload: true // since it will be accessed by LOB service itself
        }}
      />
    </EuiFlexItem>
  </>
);

export default PDFTemplateSetup;
