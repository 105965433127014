import { number, object } from 'yup';

import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiText, EuiTitle } from '@elastic/eui';
import FileField from '@sharedComponents/formElements/combined/FileField';

function RecurrentPaymentsCSV() {
  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiTitle size="s">
          <h3>CSV upload</h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          Upload a .CSV file with existing student data for recurrent payments. First row is supposed to contain fields
          identificators.
        </EuiText>
        <EuiText>
          You can download example CSV here:{' '}
          <EuiLink target="_blank" href="/csv/RepaymentsTemplate.csv">
            Student data upload template
          </EuiLink>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <FileField
          fieldName={'file_id'}
          rowProps={{
            label: 'Student data spreadsheet',
            helpText: '',
            fullWidth: true
          }}
          fieldProps={{
            accept: '.csv',
            forcedMimeType: 'csv',
            isTempFile: true
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

RecurrentPaymentsCSV.validationRules = object({
  file_id: number().required().label('CSV file').typeError('CSV file is required')
});

export default RecurrentPaymentsCSV;
