export default [
  'Accounting',
  'Advertising / Marketing',
  'Aerospace / Aeronautics',
  'Aging / Gerontology',
  'Agriculture / Horticulture / Animals',
  'Animation / Special Effects',
  'Anthropology',
  'Architecture / Interiors',
  'Area / Regional Studies',
  'Automotive / Mechanics / Repair',
  'Biology / Zoology',
  'Business Administration / Management',
  'Chemistry & Biochemistry',
  'Child Care',
  'Cinema / Film',
  'City Planning / Urban Studies',
  'Civil Engineering / Construction',
  'Community / Advocacy',
  'Computer Science & IT',
  'Cosmetology / Hairstyling',
  'Culinary Arts / Chef',
  'Dance',
  'Dentistry / Dental Hygiene',
  'Early Childhood Education',
  'Earth Sciences / Geography / Climate',
  'Economics / Finance',
  'Electrical / Electronics Engineering',
  'Elementary / High School Education',
  'English Language / Literature / Writing',
  'Environment / Natural Resources / Conservation',
  'Ethnic / Minority Studies',
  'Exercise Science / Physiology / Kinesiology',
  'Fashion',
  'Fine Arts',
  'Firefighting',
  'Food',
  'Foreign Languages / Linguistics',
  'Forestry',
  'Funeral Services / Mortuary Science',
  'Gender / Women',
  'Graphic Design',
  'Physical Education / Fitness / Nutrition',
  'Health Administration',
  'History / Archaeology',
  'Human Resources',
  'Industrial & Mechanical Engineering',
  'Industrial Design',
  'International Relations',
  'Journalism',
  'Law / Legal Studies',
  'Law Enforcement / Corrections / Criminal Justice',
  'Library Science',
  'Logistics',
  'Mathematics / Statistics',
  'Media / Radio / Television',
  'Medical Technology',
  'Medicine / Pharmacy',
  'Mining / Oil',
  'Military',
  'Music',
  'Nursing',
  'Philosophy',
  'Photography',
  'Physical Sciences',
  'Physical Therapy / Rehabilitation',
  'Pilot / Aviation',
  'Political Science / Government',
  'Plumbing / Carpentry / Electrical / Skilled Trade',
  'Psychology / Psychiatry / Mental Health / Counseling',
  'Public Administration',
  'Public Relations / Communication',
  'Real Estate',
  'Recreation / Parks',
  'Religion / Theology',
  'Social Work',
  'Sociology',
  'Sports',
  'Theatre / Drama',
  'Transportation / Shipping / Maritime / Rail',
  'Travel / Tourism',
  'Veterinary Medicine'
];
