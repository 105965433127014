import React, { useState } from 'react';
import warn from '../base/warn.js';
import { CounselorNotificationStatus } from '@sharedClients/types/ApplicationModel';
import SupportModel from '@sharedClients/types/SupportModel';
import FormNode, { FormPageNode, isApplicationAcceptance } from '../model/FormNode';
import ChevronRight from './ChevronRight';
import DisappearingBanner from './DisappearingBanner';
import { FormNodeComponent } from './FormNodeComponent';
import PageNavigationDesktop from './PageNavigationDesktop';
import PageNavigationMobile from './PageNavigationMobile';
import './SubmissionForm.scss';
import SubmitBanner from './SubmitBanner';
import Warning from './Warning';
import { isScholarshipExpired } from '@sharedComponents/utils/scholarshipUtils';
import ImageRenderer from '@sharedComponents/common/ImageRenderer/ImageRenderer';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import AcceptanceFormHead from './SubmissionForm/AcceptanceFormHead';
import { ApplicationClient } from '@sharedClients/ApplicationClient.js';
import { FormChangeRequestModelShape } from '@sharedComponents/models';
import ReviewControls from './SubmissionForm/ReviewControls';

export default function SubmissionForm({
  form,
  submission,
  supports,
  setCounselorNotificationStatus,
  setRecommenderNotificationStatus,
  applicationClient,
  errorMessage,
  onSubmit,
  onChange,
  onError,
  onFormError = (e: Error) => warn(e),
  clearError,
  donorId,
  applicantId,
  submitBannerLabel,
  instructions,
  submissionName,
  showSubmittedMessage = true,
  isFormAvailable = () => submission && form,
  animationTime = 50,
  logoId,
  context,
  changeRequest,
  onChangeRequestUpdate,
  onSave
}: {
  form: any;
  submission: any;
  supports: SupportModel[] | null;
  setCounselorNotificationStatus: (status: CounselorNotificationStatus) => void;
  setRecommenderNotificationStatus: (status: any) => void;
  applicationClient: ApplicationClient;
  errorMessage: JSX.Element | string | null;
  onSubmit: () => void;
  onChange: (submission: any) => void;
  onError: (e: Error) => void;
  onFormError?: (e: Error) => void;
  clearError: () => void;
  donorId: number | null;
  applicantId: number;
  submitBannerLabel: string;
  instructions?: JSX.Element | string;
  submissionName?: string;
  showSubmittedMessage?: boolean;
  isFormAvailable?: () => boolean;
  animationTime?: number;
  logoId?: number | null;
  context?: string;
  changeRequest?: FormChangeRequestModelShape;
  onChangeRequestUpdate?: (FormChangeRequestModel) => void;
  onSave?;
}) {
  const [pageId, setPageId] = useState(getPageId());
  const [animationClass, setAnimationClass] = useState('');
  const [pagesVisited, setPagesVisited] = useState({});
  const [isShowLastPageMissingRequired, setShowLastPageMissingRequired] = useState(false);

  const formNode = new FormNode(form, onFormError);

  const pages = formNode.getPages(submission);

  const missingRequiredFields = formNode.isMissingRequiredFields(submission);
  const hasMissingNotifications = formNode.hasMissingNotifications(submission);

  const isFormComplete = !missingRequiredFields;

  const { currentPage, nextPage, previousPage } = findPage(pageId, pages);

  const showWarningForPage = page => pagesVisited[page.id] && page.isMissingRequiredFields(submission);

  const isSubmitted = submission && !!submission.submittedAt;

  const isExpired = submission && isScholarshipExpired(submission.scholarship);
  const isAcceptanceForm = isApplicationAcceptance(submission);

  function onFieldChange(value: any, node: FormNode | FormNode[]) {
    const changedApplication = setField(value, node, submission);
    onChange(changedApplication);
  }

  function setPageInUrl(page: FormPageNode) {
    const urlParams = new URLSearchParams(document.location.search);

    urlParams.set('page', page.id);

    window.history.pushState({}, '', '?' + urlParams.toString());
  }

  async function animatePageOut() {
    if (animationTime === 0) {
      return;
    }

    setAnimationClass('hide');

    await soon();

    setAnimationClass('hide animate');

    await soon(animationTime);
  }

  async function animatePageIn() {
    if (animationTime === 0) {
      return;
    }

    setAnimationClass('show');

    await soon();

    setAnimationClass('show animate');

    await soon(animationTime);

    setAnimationClass('');
  }

  async function goToPage(page: FormPageNode, opts: { isNext: boolean }) {
    const { isNext } = opts || { isNext: false };

    await animatePageOut();

    setPageInUrl(page);

    window.scrollTo(0, 0);

    setPagesVisited({ ...pagesVisited, [pageId || pages[0].id]: true });

    setPageId(page.id);

    if (isNext) {
      setShowLastPageMissingRequired(!!currentPage.isMissingRequiredFields(submission));
    }

    // intentionally not waiting
    animatePageIn();
  }

  return (
    <div className={'SubmissionForm' + (isFormComplete ? ' has-submit-banner' : '')}>
      <PageNavigationMobile
        pages={pages}
        onPageClick={goToPage}
        currentPage={currentPage}
        nextPage={nextPage}
        previousPage={previousPage}
        hideShadow={isFormComplete}
        showWarningForPage={showWarningForPage}
      />
      {isShowLastPageMissingRequired ? (
        <DisappearingBanner onHide={() => setShowLastPageMissingRequired(false)}>
          <Warning /> There are required fields you did not fill in.
        </DisappearingBanner>
      ) : null}
      <div className="drawer">
        <ImageRenderer fileId={logoId as any} applicationClient={applicationClient} />
        <PageNavigationDesktop
          pages={pages}
          onPageClick={goToPage}
          currentPage={currentPage}
          onSubmit={onSubmit}
          showWarningForPage={showWarningForPage}
        />
        {context === 'review' && changeRequest && !!onChangeRequestUpdate && (
          <ReviewControls changeRequest={changeRequest} onChangeRequestUpdate={onChangeRequestUpdate} />
        )}
      </div>
      <div className="main">
        {errorMessage}

        {isSubmitted && showSubmittedMessage && !isExpired ? (
          <div className="is-submitted">
            You have submitted this {submissionName || 'application'} already, but you can still edit it. The donor will
            see any changes made before the deadline.
          </div>
        ) : null}

        {instructions ? <div className="instructions">{instructions}</div> : null}

        <form className={animationClass}>
          {isFormAvailable() ? (
            <EuiFlexGroup direction="column">
              {isAcceptanceForm ? (
                <EuiFlexItem>
                  <AcceptanceFormHead submission={submission} />
                  <EuiSpacer />
                </EuiFlexItem>
              ) : null}
              <EuiFlexItem>
                <FormNodeComponent
                  application={submission}
                  supports={supports}
                  node={currentPage}
                  onFieldChange={onFieldChange}
                  onError={onError}
                  onFormError={onFormError}
                  clearError={clearError}
                  setCounselorNotificationStatus={setCounselorNotificationStatus}
                  setRecommenderNotificationStatus={setRecommenderNotificationStatus}
                  warnOnMissingRequired={currentPage?.id ? pagesVisited[currentPage.id] : false}
                  applicationClient={applicationClient}
                  donorId={donorId}
                  applicantId={applicantId}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : null}

          {/** ==== Controls */}

          {nextPage && !isFormComplete && isFormAvailable() && !isAcceptanceForm ? (
            <div className="next-page-button-container">
              <button
                className="button"
                type="submit"
                onClick={e => {
                  goToPage(nextPage, { isNext: true });
                  e.preventDefault();
                }}
              >
                Next: {nextPage.getTitle()}
                <ChevronRight />
              </button>
            </div>
          ) : null}

          {/* if there are mandatory fields i forgot to fill in on the last
              page, show a button that triggers displaying that they are missing */}
          {!nextPage &&
          currentPage &&
          !pagesVisited[currentPage.id] &&
          currentPage.isMissingRequiredFields(submission) &&
          !isAcceptanceForm ? (
            <div className="next-page-button-container">
              <button
                className="button"
                type="submit"
                onClick={e => {
                  // e.target.
                  setPagesVisited({
                    ...pagesVisited,
                    [currentPage.id]: true
                  });

                  setShowLastPageMissingRequired(false);

                  e.preventDefault();
                }}
              >
                Save
              </button>
            </div>
          ) : isFormComplete &&
            isFormAvailable() &&
            currentPage &&
            !currentPage.isMissingRequiredFields(submission) &&
            isSubmitted &&
            !isAcceptanceForm &&
            onSave ? (
            // thats hardcode shit in order to let editing already submitted applications
            <div className="next-page-button-container">
              <button
                className="button"
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  onSave();
                }}
              >
                Save
              </button>
            </div>
          ) : null}
        </form>
        {isFormComplete &&
        isFormAvailable() &&
        currentPage &&
        (!isSubmitted || isAcceptanceForm) &&
        !hasMissingNotifications ? (
          <SubmitBanner onSubmit={onSubmit} label={submitBannerLabel} />
        ) : null}
      </div>
    </div>
  );
}

export function findPage(pageId: string | null, pages: FormPageNode[]) {
  let i = Math.max(
    pages.findIndex(candidate => candidate.id === pageId),
    0
  );

  const currentPage = pages[i];
  const nextPage = pages[i + 1];
  const previousPage = pages[i - 1];

  return { currentPage, nextPage, previousPage };
}

function getPageId() {
  const urlParams = new URLSearchParams(document.location.search);
  return urlParams.get('page');
}

function soon(ms?: number) {
  return new Promise(resolve => setTimeout(resolve, ms || 0));
}

export function setField(value: any, node: FormNode | FormNode[], application) {
  let changedApplication = application;

  let nodes, values;

  // we support both single values and arrays of values
  if ((node as FormNode[]).length === undefined) {
    nodes = [node];
    values = [value];
  } else {
    nodes = node;
    values = value;
  }

  for (let i = 0; i < nodes.length; i++) {
    changedApplication = nodes[i].setValue(values[i], changedApplication);
  }

  return changedApplication;
}
