import { useRecoilState } from 'recoil';

import { useRouter } from '@sharedComponents/hooks/useRouter';

import reviewStateActiveCategory from '../selectors/reviewStateActiveCategory';
import { useLoggedInUser } from '@sharedComponents/selectors/loggedInUserSelectors';
import { APPLICATION_TYPE } from '@sharedComponents/ScholarshipApplications.interface';
import { USER_TYPE } from '@sharedComponents/constants';

function getStartingCategory(user) {
  const isReviewing = user.type === USER_TYPE.REVIEWER;
  const initialFilter = window.location.hash.substr(1);

  if (!Object.values(APPLICATION_TYPE).includes(initialFilter.toUpperCase() as APPLICATION_TYPE)) {
    return isReviewing ? APPLICATION_TYPE.UNREVIEWED : APPLICATION_TYPE.CANDIDATES;
  } else {
    return initialFilter.toUpperCase() as APPLICATION_TYPE;
  }
}

export default function useActiveCategory() {
  const user = useLoggedInUser()!;

  const router = useRouter();
  const [activeCategory, setActiveCategory] = useRecoilState(reviewStateActiveCategory);

  const changeCategoryHandle = newCategory => {
    router.push(`#${newCategory.toLowerCase()}`);

    setActiveCategory(newCategory);
  };

  return {
    value: activeCategory ? activeCategory : getStartingCategory(user),
    change: changeCategoryHandle
  };
}
