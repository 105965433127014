import { string, mixed, boolean, object, InferType, number, array } from 'yup';

export enum APPLICATION_NODE_TYPE {
  TYPE_PAGE = 'page',
  TYPE_SECTION = 'section',

  TYPE_INPUT = 'text',
  TYPE_PHONE = 'phone',
  TYPE_EMAIL = 'email',
  TYPE_SELECT = 'select',
  TYPE_ADDRESS = 'address', // ? any reason to not use section for this one instead?
  TYPE_STATE = 'state',
  TYPE_NUMBERINPUT = 'number',
  TYPE_LIST = 'list',
  TYPE_CHECKBOX = 'checkbox',
  TYPE_UPLOADER = 'upload',
  TYPE_PDF = 'pdf',
  TYPE_IMAGE = 'image',

  // ... more field nodes

  TYPE_RECOMMENDERS = 'recommenders-list',
  TYPE_RECOMMENDERSSTATUS = 'recommenderStatus',
  TYPE_COUNSELORSTATUS = 'counselorStatus',
  TYPE_ACCEPTAWARD = 'acceptAward',
  TYPE_MESSAGE = 'message',
  TYPE_USERTYPE = 'userType',
  TYPE_SCREENING = 'screening',
  TYPE_TEXTAREA = 'textarea',
  TYPE_RICHTEXT = 'richText',
  TYPE_NUMBERRANGE = 'numberRange',
  TYPE_AUTOCOMPLETE = 'autocomplete'

  // also theres actions
}

// contains all possible properties for further combinations
export const ApplicationFormNodeSchema = object({
  type: string().required().oneOf(Object.values(APPLICATION_NODE_TYPE)).required(),

  title: string(),
  field: string().matches(/^([A-Za-z0-9]){3,}$/, 'Must Contain min 3 characters, letters and numbers only'),
  label: string(),
  width: number(), // ? maybe should be omitted
  required: boolean().default(false),

  // * pages
  children: array(mixed()),

  // this is only used for award acceptance pages, also check this out
  // ? userType === 'awarded' is sucks and should be dropped
  userType: string(), // rework me

  // assuming its also used only for awardAcceptance page and could be omitted somehow
  path: string(), // dropme

  // I've seen exaxmples of this working, it adds a class on rendering, but not quite sure if it works correctly
  wide: boolean().default(false), // dropme

  // * sections
  help: string(),

  columns: array(mixed()),

  // * selects
  options: string(), // actually transformed into array by splitting
  multiple: boolean().default(false),

  // * checkboxes
  defaultChecked: boolean().default(false)
}).optional();

export type ApplicationFormNodeType = InferType<typeof ApplicationFormNodeSchema>;

// * ApplicationPage
/**
 *  this is only used for award acceptance pages, also check this out
  // ? userType === 'awarded' is sucks and should be dropped
  userType?: string; // rework me

  // assuming its also used only for awardAcceptance page and could be omitted somehow
  path?: string; // dropme

  // I've seen exaxmples of this working, it adds a class on rendering, but not quite sure if it works correctly
  wide?: boolean; // dropme
 */
export const ApplicationPageSchema = ApplicationFormNodeSchema.pick(['title', 'userType', 'path', 'wide']).shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_PAGE]).default(APPLICATION_NODE_TYPE.TYPE_PAGE), // any better way to forse type?
  children: array(mixed()).default([]) // can we have page with FormNodes children, skipping sections?
});

export type ApplicationPageType = InferType<typeof ApplicationPageSchema>;

// * ApplicationSection
/**
 * columns?: ApplicationFormNode[] | Array<ApplicationFormNode[]>; // there are cases when this is not being mandatory, but I'll better force this to be.
  children?: ApplicationFormNode[] | Array<ApplicationFormNode[]>; // funny thing, section can have children OR columns. I would rather fix this also
  wide?: boolean; // dropme
  help?: string; // refactor me
 */
export const ApplicationSectionSchema = ApplicationFormNodeSchema.pick([
  'children',
  'title',
  'path',
  'wide',
  'help'
]).shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_SECTION]).default(APPLICATION_NODE_TYPE.TYPE_SECTION),
  columns: array(mixed()).default([[], []]) // by default its two columns stages
});

export type ApplicationSectionType = InferType<typeof ApplicationSectionSchema>;

// * ApplicationNodeTextInput
export const ApplicationNodeTextInputSchema = ApplicationFormNodeSchema.pick(['label', 'width', 'required']).shape({
  field: string()
    .matches(/^([A-Za-z0-9]){4,}$/, 'Must Contain min 4 characters, letters and numbers only')
    .required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_INPUT]).default(APPLICATION_NODE_TYPE.TYPE_INPUT)
});

export type ApplicationNodeTextInputType = InferType<typeof ApplicationNodeTextInputSchema>;

// * ApplicationNodeEmail
export const ApplicationNodeEmailSchema = ApplicationNodeTextInputSchema.clone().shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_EMAIL]).default(APPLICATION_NODE_TYPE.TYPE_EMAIL)
});

export type ApplicationNodeEmailSchemaType = InferType<typeof ApplicationNodeEmailSchema>;

// * ApplicationNodePhone
export const ApplicationNodePhoneSchema = ApplicationNodeTextInputSchema.clone().shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_PHONE]).default(APPLICATION_NODE_TYPE.TYPE_PHONE)
});

export type ApplicationNodePhoneType = InferType<typeof ApplicationNodePhoneSchema>;

// * ApplicationNodeSelect
/**
 * ToDo allowUnknown and multiple selects
 */
export const ApplicationNodeSelectSchema = ApplicationFormNodeSchema.pick(['field', 'required', 'multiple']).shape({
  field: string()
    .matches(/^([A-Za-z0-9]){4,}$/, 'Must Contain min 4 characters, letters and numbers only')
    .required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_SELECT]).default(APPLICATION_NODE_TYPE.TYPE_SELECT),
  options: string()
    .required()
    .default('')
    .test('options', 'Options are not set', async _ => {
      // TODO?

      return true;
    })
});

export type ApplicationNodeSelectType = InferType<typeof ApplicationNodeSelectSchema>;

/**
 * * ApplicationNodeCheckbox
 *
 */
export const ApplicationNodeCheckboxSchema = ApplicationFormNodeSchema.pick([
  'label',
  'field',
  'required',
  'defaultChecked'
]).shape({
  field: string()
    .matches(/^([A-Za-z0-9]){4,}$/, 'Must Contain min 4 characters, letters and numbers only')
    .required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_CHECKBOX]).default(APPLICATION_NODE_TYPE.TYPE_CHECKBOX)
});

export type ApplicationNodeCheckboxType = InferType<typeof ApplicationNodeCheckboxSchema>;

/**
 * * ApplicationNodeAddress
 * works as a section, but contains children and has default value
 */
export const ApplicationNodeAddressSchema = ApplicationFormNodeSchema.pick(['title']).shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_ADDRESS]).default(APPLICATION_NODE_TYPE.TYPE_ADDRESS),
  children: array()
    .of(ApplicationFormNodeSchema)
    .default([
      {
        type: 'text',
        field: 'street'
      },
      { type: 'text', field: 'city' },
      {
        type: 'state',
        field: 'state'
      },
      {
        type: 'number',
        field: 'zip'
      }
    ])
});

export type ApplicationNodeAddressType = InferType<typeof ApplicationNodeAddressSchema>;

/**
 * * ApplicationNodeState
 */
export const ApplicationNodeStateSchema = ApplicationFormNodeSchema.pick(['field']).shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_STATE]).default(APPLICATION_NODE_TYPE.TYPE_STATE)
});

export type ApplicationNodeStateType = InferType<typeof ApplicationNodeStateSchema>;

/**
 * * ApplicationNodeNumber
 */
export const ApplicationNodeNumberSchema = ApplicationFormNodeSchema.pick(['field', 'width', 'required']).shape({
  field: string()
    .matches(/^([A-Za-z0-9]){4,}$/, 'Must Contain min 4 characters, letters and numbers only')
    .required(),
  type: string()
    .required()
    .oneOf([APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT])
    .default(APPLICATION_NODE_TYPE.TYPE_NUMBERINPUT)
});

export type ApplicationNodeNumberType = InferType<typeof ApplicationNodeNumberSchema>;

/**
 * * ApplicationNodeList
 */
export const ApplicationNodeListSchema = ApplicationFormNodeSchema.pick(['title', 'help', 'label']).shape({
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_LIST]).default(APPLICATION_NODE_TYPE.TYPE_LIST),
  path: string().required(),
  columns: array(mixed()).default([[], []])
});

export type ApplicationNodeListType = InferType<typeof ApplicationNodeListSchema>;

/**
 * * ApplicationNodeUpload
 */
export const ApplicationNodeUploadSchema = ApplicationFormNodeSchema.pick(['label', 'required']).shape({
  field: string().required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_UPLOADER]).default(APPLICATION_NODE_TYPE.TYPE_UPLOADER)
});

export type ApplicationNodeUploadType = InferType<typeof ApplicationNodeUploadSchema>;

/**
 * * ApplicationNodePDF
 */
export const ApplicationNodePDFSchema = ApplicationFormNodeSchema.pick(['label', 'required']).shape({
  field: string().required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_PDF]).default(APPLICATION_NODE_TYPE.TYPE_PDF)
});

export type ApplicationNodePDFType = InferType<typeof ApplicationNodePDFSchema>;

/**
 * * ApplicationNodeImage
 */
export const ApplicationNodeImageSchema = ApplicationFormNodeSchema.pick(['label', 'required']).shape({
  field: string().required(),
  type: string().required().oneOf([APPLICATION_NODE_TYPE.TYPE_IMAGE]).default(APPLICATION_NODE_TYPE.TYPE_IMAGE)
});

export type ApplicationNodeImageType = InferType<typeof ApplicationNodeImageSchema>;
