import { take } from 'lodash';
import React, { useState } from 'react';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiButtonEmpty, EuiFieldNumber, EuiPopover } from '@elastic/eui';
import { ApplicationGridDataEntry, AwardingApplicationDataEntry } from '../../config';

const SelectionWidget = ({
  selection,
  applicationGridDataWithSelection,
  setSelection,
  totalSize
}: {
  selection: AwardingApplicationDataEntry[];
  applicationGridDataWithSelection: AwardingApplicationDataEntry[];
  setSelection: (selectedRecords: ApplicationGridDataEntry[]) => void;
  totalSize: number;
}) => {
  const [selectionAmount, setSelectionAmount] = useState<number>();
  const isAllSelected = totalSize > 0 && selection.length === totalSize;
  const isNoneSelected = selection.length === 0;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleSelectionByAmount = () => {
    const applications = take(applicationGridDataWithSelection, selectionAmount);
    setSelection(applications);
  };

  return (
    <EuiPopover
      isOpen={isPopoverOpen}
      panelPaddingSize="s"
      button={
        <EuiButton
          aria-label="show selection options"
          size="s"
          iconType="arrowDown"
          color="primary"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          Select the number of candidates who will receive the scholarship
        </EuiButton>
      }
      closePopover={() => setIsPopoverOpen(false)}
      ownFocus={false}
    >
      <EuiFlexGroup direction="column" gutterSize="xs">
        <EuiFlexItem>
          <EuiFieldNumber
            value={selectionAmount || ''}
            max={totalSize}
            onChange={e => setSelectionAmount(parseInt(e.target.value))}
            fullWidth={false}
          />
          <EuiButtonEmpty
            isDisabled={!selectionAmount}
            iconType={'pagesSelect'}
            size="s"
            onClick={handleSelectionByAmount}
          >
            Select top {selectionAmount || ''} {selectionAmount === 1 ? 'candidate' : 'candidates'}
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem style={{ alignItems: 'flex-start' }}>
          <EuiFlexGroup
            gutterSize="s"
            alignItems="center"
            justifyContent="spaceAround"
            style={{ width: '100%', margin: '0px' }}
          >
            <EuiFlexItem>
              <EuiButtonEmpty
                isDisabled={isNoneSelected}
                size="s"
                onClick={() => {
                  setSelection([]);
                  setIsPopoverOpen(false);
                }}
              >
                Clear all
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                isDisabled={isAllSelected}
                size="s"
                onClick={() => {
                  setSelection(applicationGridDataWithSelection);
                  setIsPopoverOpen(false);
                }}
              >
                Select all
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export default SelectionWidget;
