import { cloneDeep, get, isArray, isNaN, isNil, parseInt, pullAt, set, toPath } from 'lodash';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import { FormStateInternals } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationFormNodeType, ApplicationPageType } from '@sharedComponents/schemas/FormNodesSchema';

import EditFormNodeModal from '../EditFormNodeModal';
import formStateIsDirtySelector from '../selectors/formStateIsDirtySelector';
import { formStatePagesSelector } from '../selectors/formStatePagesSelector';

function useFormNodeRemoval() {
  const setFormStatePages = useSetRecoilState(formStatePagesSelector);
  const [, setIsDirty] = useRecoilState(formStateIsDirtySelector);

  return (pathString?: string) => {
    if (!pathString) {
      // mostly here for types compatibility
      // eslint-disable-next-line no-console
      console.error('Wrong path(missing)');
      return;
    }

    setIsDirty(true);

    // node is always placed in array
    const path = toPath(pathString);
    const arrayPosition = parseInt(path.pop() || '');

    if (isNaN(arrayPosition)) {
      // eslint-disable-next-line no-console
      console.error('Wrong path', path);
    } else {
      setFormStatePages(prevState => {
        if (path.length > 0) {
          const arrayToModify = cloneDeep(get(prevState, path.join('.')));

          if (!isArray(arrayToModify)) {
            // eslint-disable-next-line no-console
            console.error('Wrong path (not array)', path);
            return prevState;
          }

          pullAt(arrayToModify, [arrayPosition]);

          const newState = cloneDeep(prevState);

          set(newState, path.join('.'), arrayToModify);

          return newState;
        } else {
          // special case for top level pages
          const newState = cloneDeep(prevState);

          if (!isArray(newState)) {
            // eslint-disable-next-line no-console
            console.error('Wrong path (not array/page)', path);
            return prevState;
          }

          pullAt(newState, [arrayPosition]);
          return newState;
        }
      });
    }
  };
}

function useFormNodeEdit() {
  const createModal = useModalCreate();
  const [, setIsDirty] = useRecoilState(formStateIsDirtySelector);
  const [_formStatePages, setFormStatePages] = useRecoilState(formStatePagesSelector);

  return (pathString?: string, isRawOnly?: boolean) => {
    if (!pathString) {
      // mostly here for types compatibility
      // eslint-disable-next-line no-console
      console.error('Wrong path(missing)');
      return;
    }

    const node = get(_formStatePages, pathString, null) as any;
    createModal(({ closeModal }) => {
      const onModalSubmitHandler = (changedNode?: ApplicationFormNodeType) => {
        closeModal();

        if (changedNode) {
          setIsDirty(true);

          setFormStatePages(prevState => {
            const newState = cloneDeep(prevState);
            set(newState, pathString, changedNode);
            return newState;
          });
        }
      };

      return <EditFormNodeModal onModalSubmit={onModalSubmitHandler} node={node} isForcedRaw={isRawOnly} />;
    });
  };
}

function useFormNodeAdding() {
  const [, setIsDirty] = useRecoilState(formStateIsDirtySelector);
  const setFormStatePages = useSetRecoilState(formStatePagesSelector);

  return (newNode: Partial<ApplicationPageType & FormStateInternals>, pathString?: string) => {
    if (isNil(pathString)) {
      // mostly here for types compatibility
      // eslint-disable-next-line no-console
      console.error('Wrong path(missing)');
      return;
    }

    setIsDirty(true);

    setFormStatePages(prevState => {
      if (pathString === '') {
        // pages adding
        const newState = cloneDeep(prevState);
        newState.push(newNode as ApplicationPageType & FormStateInternals);
        return newState;
      } else {
        const arrayToModify: any[] = cloneDeep(get(prevState, pathString, []));

        if (!isArray(arrayToModify)) {
          // eslint-disable-next-line no-console
          console.error('Wrong path (not array)', pathString);
          return prevState;
        }

        arrayToModify.push(newNode);

        const newState = cloneDeep(prevState);

        set(newState, pathString, arrayToModify);

        return newState;
      }
    });
  };
}

export { useFormNodeRemoval, useFormNodeEdit, useFormNodeAdding };
