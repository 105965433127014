import React, { useState } from 'react';

import { EuiButton, EuiComboBox, EuiComboBoxOptionOption, EuiPopover, EuiBadge, EuiIcon } from '@elastic/eui';
import {
  DRAFT_STEP_BADGE_COLOR,
  DRAFT_STEP_STATUSES,
  SCHOLARSHIP_ENTRY_STEPS
} from '@sharedComponents/interfaces/Scholarships.interface';

const optionsStatic: EuiComboBoxOptionOption[] = Object.keys(DRAFT_STEP_STATUSES)
  .map(draft_step => ({
    label: DRAFT_STEP_STATUSES[draft_step],
    color: DRAFT_STEP_BADGE_COLOR[draft_step],
    value: draft_step as unknown as SCHOLARSHIP_ENTRY_STEPS
  }))
  .filter(
    opt =>
      ![`${SCHOLARSHIP_ENTRY_STEPS.RESEARCH_SECOND}`, `${SCHOLARSHIP_ENTRY_STEPS.ELIGIBILITY_SECOND}`].includes(
        `${opt.value}`
      )
  );

function DraftStatusFilter({
  onDraftStatusFilterChange
}: {
  onDraftStatusFilterChange: (draftSteps: SCHOLARSHIP_ENTRY_STEPS[]) => void;
}) {
  const [selectedOptions, setSelected] = useState<EuiComboBoxOptionOption[]>([]);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const closePopover = () => {
    onDraftStatusFilterChange(selectedOptions.map(opt => opt.value as SCHOLARSHIP_ENTRY_STEPS));
    setPopoverOpen(false);
  };

  const button = (
    <EuiButton onClick={() => (isPopoverOpen ? closePopover() : setPopoverOpen(true))} size="s">
      Filter by draft status{' '}
      {selectedOptions.length ? (
        <>
          <EuiBadge color="primary">{selectedOptions.length}</EuiBadge>
        </>
      ) : (
        <EuiIcon type="arrowDown" />
      )}
    </EuiButton>
  );
  return (
    <EuiPopover id="popover" ownFocus button={button} isOpen={isPopoverOpen} closePopover={closePopover}>
      <div style={{ width: '300px' }}>
        <EuiComboBox
          placeholder="Select draft statuses to filter"
          options={optionsStatic}
          selectedOptions={selectedOptions}
          onChange={selectedOptions => {
            setSelected(selectedOptions);
          }}
        />
      </div>
    </EuiPopover>
  );
}

export default DraftStatusFilter;
