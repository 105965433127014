import warn from '../base/warn';
import { useEffect } from 'react';
import { OFFLINE_CAUSE } from '@sharedClients/main';

const DEFAULT_SAVE_INTERVAL = 5000;
const maxSaveInterval = 60000;

/**
 * Manages periodically saving a submission (application/support) in the background.
 */
export default class SubmissionSaver<T extends any[], R> {
  saveInterval: number;
  upsert: (...args: T) => Promise<R>;
  toSave: T | null;
  timer: any;
  onError: (e: Error) => void;
  onSaveStatusChange: (status: string) => void;
  upsertPromise: Promise<R | null> | null = null;

  constructor(
    upsert: (...args: T) => Promise<R>,
    onError: (e: Error) => void,
    { saveInterval, onSaveStatusChange }: { saveInterval?: number; onSaveStatusChange?: (status: string) => void }
  ) {
    this.saveInterval = saveInterval || DEFAULT_SAVE_INTERVAL;
    this.upsert = upsert;

    // invariant: (toSave != null) == (timer != null)
    this.toSave = null;
    this.timer = null;
    this.onError = onError;
    this.onSaveStatusChange = onSaveStatusChange || (() => {});

    // used by the system test
    window['submissionSaver'] = this;
  }

  setTimer(saveInterval: number) {
    if (!this.timer) {
      this.timer = setTimeout(
        () =>
          this.save(saveInterval).catch(() => {
            /*already logged*/
          }),
        saveInterval
      );
    }
  }

  /**
   * Returns null if we've never saved anything, otherwise the latest saved version.
   * Rejects if failing to save, but no need to log that.
   */
  async save(lastSaveInterval?: number): Promise<R | null> {
    const toSave = this.toSave;

    if (!toSave) {
      return this.upsertPromise || null;
    }
    // we can call save() to do a save immediately even if one is pending,
    // in which case the pending one is irrelevant
    clearTimeout(this.timer);

    this.timer = null;
    this.toSave = null;

    this.onSaveStatusChange('saving');

    this.upsertPromise = this.upsert(...toSave)
      .then(upsertResult => {
        this.onSaveStatusChange('saved');

        // the result is used by the system tests
        return upsertResult;
      })
      .catch(e => {
        this.onSaveStatusChange('error');

        // retry (with exponential backoff) unless there is a newer version anyway.
        if (!this.toSave && isWorthRetrying(e)) {
          this.push(
            Math.min((lastSaveInterval || this.saveInterval) * 1.3, maxSaveInterval),

            ...toSave
          );
        }

        if (e.cause !== OFFLINE_CAUSE) {
          this.onError(e);
        }

        throw e;
      });

    return this.upsertPromise;
  }

  abort() {
    if (this.timer) {
      clearTimeout(this.timer);

      warn('Left the page with unsaved changes');
    }
  }

  onEdit(...upsertParams: T) {
    this.onSaveStatusChange('edited');

    this.push(this.saveInterval, ...upsertParams);
  }

  push(saveInterval: number, ...upsertParams: T) {
    // there might already be a toSave, but we just got a newer version, so it's fine to overwrite it.
    this.toSave = upsertParams;

    this.setTimer(saveInterval);
  }

  useUnsavedChangesWarning() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!navigator.userAgent.includes('Cypress')) {
        window.onbeforeunload = () => {
          if (this.toSave) {
            this.save();

            return 'Still saving your last changes. Are you sure you want to cancel saving?';
          }
        };
      }

      return () => {
        this.abort();
        window.onbeforeunload = null;
      };
    }, []);
  }
}

function isWorthRetrying(e: any) {
  return e.cause !== 'invalid-jwt' && e.cause !== 'expired-jwt';
}
