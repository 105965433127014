const isInTest = process.env.NODE_ENV === 'test' || !!window['Cypress'];
const _hsq = (window._hsq = window._hsq || []);

export function identify(email: string) {
  if (!isInTest) {
    _hsq.push([
      'identify',
      {
        email: email
      }
    ]);
  }
}

export default function track(eventID: string) {
  _hsq.push([
    'trackEvent',
    {
      id: eventID
    }
  ]);
}

/**
 * Changes document title and triggers hubspot trackPageView
 */
export function onPageChange(pageTitle: string, pageHash?: string) {
  if (pageTitle) {
    document.title = `Scholar's App - ${pageTitle}`;

    if (pageHash) {
      // Track the page view for the new page
      _hsq.push(['setPath', '/' + pageHash]);
      _hsq.push(['trackPageView']);
    }
  }
}
