/* eslint-disable react/jsx-key */
import React from 'react';
import * as yup from 'yup';

import { ComboboxField, LookupField, TextField } from '@sharedComponents/formElements';
import allStates from '@sharedComponents/models/enumValues/states';
import { UserModelDataSchema } from '@sharedComponents/schemas/UserModelSchema';

// Represents array fields for students prodile address
function applicantAddressFields(applicationClient) {
  return [
    <TextField
      fieldName={'data.address.residency.street'}
      fieldProps={{ fullWidth: true }}
      rowProps={{ label: 'Street' }}
    />,

    <TextField
      fieldName={'data.address.residency.street2'}
      fieldProps={{ fullWidth: true }}
      rowProps={{ label: 'Street 2' }}
    />,

    <ComboboxField
      options={Object.keys(allStates).map(shortState => ({
        label: allStates[shortState],
        key: shortState,
        value: shortState
      }))}
      fieldName={'data.state'}
      fieldProps={{ fullWidth: true, singleSelection: true }}
      rowProps={{ label: 'State/Parish' }}
    />,

    <TextField fieldName={'data.address.residency.zip'} fieldProps={{ fullWidth: true }} rowProps={{ label: 'ZIP' }} />,

    <LookupField
      lookupFn={val => applicationClient.getAutocomplete('cities', val)}
      fieldName={'data.address.residency.city'}
      fieldProps={{ fullWidth: true }}
      rowProps={{ label: 'City' }}
    />,
    <LookupField
      lookupFn={val => applicationClient.getAutocomplete('counties', val)}
      fieldName={'data.address.residency.county'}
      fieldProps={{ fullWidth: true }}
      rowProps={{ label: 'County' }}
    />
  ];
}

applicantAddressFields.validationRules = yup.object().shape({
  data: UserModelDataSchema.pick(['state', 'address'])
});

export default applicantAddressFields;
