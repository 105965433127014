import { EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';

import { LookupField } from '../';

const SchoolLookupField = ({ formMethods, fieldName, isRequired }) => {
  const applicationClient = useApplicationClient();
  return (
    <LookupField
      lookupFn={val => applicationClient.getAutocomplete('schools', val)}
      fieldName={fieldName}
      fieldProps={{
        fullWidth: true,
        customOptionText: 'Use {searchValue} as your high school name',
        onCreateOption: schoolName => {
          formMethods.setValue(
            fieldName,
            {
              label: schoolName,
              name: schoolName
            },
            {
              shouldDirty: true
            }
          );
        },
        rowHeight: 50
      }}
      rowProps={{ label: isRequired ? 'High School *' : 'High School' }}
      customOptionRenderer={(option, searchValue) => {
        const { label, disabled, city, state } = option;
        return (
          <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
            <EuiFlexItem>{!disabled ? <EuiHighlight search={searchValue}>{label}</EuiHighlight> : label}</EuiFlexItem>
            {state && city ? <EuiFlexItem>{`${city}, ${state}`}</EuiFlexItem> : null}
          </EuiFlexGroup>
        );
      }}
    />
  );
};

export default SchoolLookupField;
