import React from 'react';
import { History } from 'history';
import './NextPrevNavigation.scss';

export default function NextPrevNavigation({
  children,
  history,
  next,
  prev
}: {
  children: React.ReactNode;
  history: History;
  next?: string;
  prev?: string;
}) {
  return (
    <div className={'NextPrevNavigation' + (prev || next ? ' has-buttons' : '')}>
      <div className="main">{children}</div>
      <div className="nav">
        {prev ? (
          <a
            href={prev}
            onClick={e => {
              e.preventDefault();
              history.push(prev);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z" />
            </svg>
          </a>
        ) : null}
        {next ? (
          <a
            href={next}
            onClick={e => {
              e.preventDefault();
              history.push(next);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
            </svg>
          </a>
        ) : null}
      </div>
    </div>
  );
}
