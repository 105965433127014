import React from 'react';

import './TemplatePreviewModal.scss';

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from '@elastic/eui';
import TemplatePreview from './TemplatePreview';
import { getServiceUrl } from '@sharedClients/getServiceUrl';

const TemplatePreviewModal = ({ onModalSubmit, previewData }) => {
  const onModalSubmitHandler = () => {
    onModalSubmit(true);
  };

  const onCancelHandler = () => {
    onModalSubmit(false);
  };

  const logoImg = fileId => `<img src="${getServiceUrl('applications')}/v2/temp_files/content/${fileId}" />`;

  return (
    <EuiModal className="TemplatePreviewModal" onClose={onCancelHandler}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Template Preview [Pre-rendered]</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <TemplatePreview
          variables={{
            letter_content: previewData.letter_content,
            eligibility_text: previewData.eligibility_text,
            logo_img: logoImg(previewData.logo_img_ID),
            scholarship_name: previewData.scholarship_name,
            who_text: previewData.who_text,
            qr_code: previewData.qr_code
          }}
        />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={onCancelHandler} size="s">
          Edit Template
        </EuiButtonEmpty>
        <EuiButton color="primary" onClick={onModalSubmitHandler} size="s">
          Submit Template to LOB
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default TemplatePreviewModal;
