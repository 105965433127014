import './LoginSignupFormLayout.scss';

import React, { useEffect, useState } from 'react';

import Header from '@apply/components/Header';
import { EuiFlexGroup, EuiFlexItem, EuiHideFor, EuiPanel, EuiSpacer } from '@elastic/eui';
import PageContentWrapper from '@sharedComponents/layout/PageContentWrapper';
import { SIGNUP_STEP } from '@sharedComponents/routes/auth/LoginSignupForm/config';

import RolesDescriptionsBlock from './LoginSignupFormLayout/RolesDescriptionsBlock';

export default function LoginSignupFormLayout({ currentStep, onReset, userType, setUserType, noHeader, children }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLeftBlockVisible, setIsLeftBlockVisible] = useState(!isExpanded);

  useEffect(() => {
    setIsExpanded(currentStep === SIGNUP_STEP.LOGIN_START);
  }, [currentStep]);

  return (
    <PageContentWrapper fullHeight restrictWidth={false}>
      <EuiFlexGroup direction="column" className="LoginSignupFormLayout">
        {noHeader ? null : (
          <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
            <EuiFlexItem>
              <EuiSpacer size="xxl" />
            </EuiFlexItem>
          </EuiHideFor>
        )}
        <EuiFlexItem>
          <EuiFlexGroup className="LoginSignupFormLayout-container" direction="row" gutterSize="l">
            <EuiFlexItem
              className="LoginSignupFormLayout-spacer"
              style={{ flexGrow: isExpanded ? 1 : 0 }}
            ></EuiFlexItem>
            <EuiHideFor sizes={['xs', 's']}>
              <EuiFlexItem
                style={{
                  maxWidth: isExpanded || isLeftBlockVisible ? 1200 : 0,
                  opacity: isExpanded ? 1 : 0
                }}
                onTransitionEnd={() => {
                  setIsLeftBlockVisible(isExpanded);
                }}
              >
                <RolesDescriptionsBlock userType={userType} setUserType={setUserType} />
              </EuiFlexItem>
            </EuiHideFor>

            <EuiFlexItem
              style={{ flexGrow: isExpanded ? 1 : 2.5 }}
              className={'LoginSignupFormLayout-loginForm_container'}
            >
              <EuiPanel
                id="loginForm" // tests purpouses
                className={`LoginSignupFormLayout-loginForm LoginSignupFormLayout-loginForm_${
                  currentStep > 0 ? 'signup' : 'login'
                }`}
              >
                {children}
              </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem
              className="LoginSignupFormLayout-spacer"
              style={{ flexGrow: isExpanded ? 1 : 0 }}
            ></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </PageContentWrapper>
  );
}
