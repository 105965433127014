/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useRef } from 'react';
import { useState, useEffect } from 'react';
import Field from '@donors/components/Field';

export default function ValidatingField({
  label,
  help,
  id,
  value,
  setValue,
  validate,
  onSubmittableChange,
  renderChildren,
  hasSubmitted
}: {
  label: string;
  help?: string;
  id: string;
  value: any;
  setValue: (value: any) => void;
  validate?: (value: any) => string | null;
  onSubmittableChange: (field: string, isSubmittable: boolean) => void;
  hasSubmitted: boolean;
  renderChildren: (props: any) => JSX.Element;
}) {
  const [error, setError] = useState<string | null>(null);
  const [hasChanged, setChanged] = useState(false);

  function updateOnSubmittable(value: any) {
    const newError = validate ? validate(value) : null;

    onSubmittableChange(id, !newError);

    setError(newError);

    return newError;
  }

  useEffect(() => {
    updateOnSubmittable(value);
  }, []);

  const showError = error && (hasChanged || hasSubmitted);

  return (
    <Field label={showError ? <span className="error">{label}</span> : label} htmlFor={id} help={help}>
      {renderChildren({
        id,
        value,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;

          setValue(value);
          setError(updateOnSubmittable(value));
        },
        onBlur: () => {
          setChanged(true);
        }
      })}

      {showError ? (
        <div className="error" key="invalid">
          {error}
        </div>
      ) : null}
    </Field>
  );
}

export function useSubmittable() {
  const [hasSubmitted, setSubmitted] = useState(false);
  const submittableFields = useRef({});

  function isSubmittable() {
    return !Object.values(submittableFields.current).includes(false);
  }

  function onSubmittableChange(field: string, isSubmittable: boolean) {
    submittableFields.current = {
      ...submittableFields.current,
      [field]: !!isSubmittable
    };
  }

  const validatingFieldDefaults = {
    onSubmittableChange,
    hasSubmitted
  };

  return {
    isSubmittable,
    validatingFieldDefaults,
    onSubmittableChange,
    submittableFields,
    hasSubmitted,
    setSubmitted
  };
}
