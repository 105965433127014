import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { object } from 'yup';

import SchoolEligibilitySelection from '@donors/scholarship-entry/ScholarshipEntryFormContainer/ScholarshipEntryFormSteps/EntryStepEligibility/SchoolEligibilitySelection';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { ScholarshipModelDataSchema } from '@sharedComponents/schemas/ScholarshipModelSchema';

const SelfListingScholarshipStep10 = ({ nextStepHandler }) => {
  const { watch } = useFormContext();
  const schoolsMatching = watch('schoolsMatching');

  const [isSelectionShown, setIsSelectionShown] = useState(schoolsMatching !== 'Any');

  const choiceOptions = (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton
              color={'text'}
              fullWidth={false}
              onClick={() => {
                setIsSelectionShown(true);
              }}
            >
              YES
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NO
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={false}>
            <EuiButton color={'text'} fullWidth={false} onClick={nextStepHandler}>
              NOT SURE
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <EuiFlexGroup direction="column">
      <EuiFlexItem>
        <EuiText>
          <strong>Does the student need to have attended a specific high school?</strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>{isSelectionShown ? <SchoolEligibilitySelection /> : choiceOptions}</EuiFlexItem>
    </EuiFlexGroup>
  );
};

SelfListingScholarshipStep10.validationRules = object().shape({
  data: ScholarshipModelDataSchema.pick(['schools', 'schoolStates', 'schoolCounties'])
});

export default SelfListingScholarshipStep10;
