import React from 'react';
import './FormList.scss';
import NumberBubble from '../NumberBubble';
import { PlusIcon, MinusIcon } from '../PlusMinusIcon';
import 'react-slidedown/lib/slidedown.css';
import useAnimatingList from '../useAnimatingList';
import { toString, isList, toNumber } from '../../model/cast';
import { digitToString } from '../../base/components/MultipleSelectWidget';
import { FormNodeProps } from '../../apply/FormNodeProps';
import { formatParagraphText } from '../../base/MarkdownLite';
import { renderChildren } from '../../apply/FormNodeComponent';

export default function FormList(
  props: FormNodeProps & {
    required?: number;
    canHaveZeroEntries?: boolean;
    help?: string;
  }
) {
  const { node, application, onFieldChange, onFormError, warnOnMissingRequired, canHaveZeroEntries } = props;

  const rawRequired = props.required || node.required;
  const required: number | null = rawRequired != null ? toNumber(rawRequired, onFormError) : null;
  const help = props.help || node.help;

  let list = node.getValue(application) as any[];

  // there are two kinds of empty: showing a single entry but no values entered
  // and showing no entries. the latter is confusing but currently the only option
  // if there are mandatory fields inside
  const canBeEmpty = !required && canHaveZeroEntries;

  if (!isList(list) || list.length === 0) {
    list = canBeEmpty ? [] : [{}];
  }

  const { renderListItem, animateListItem } = useAnimatingList(50);

  const add = async () => {
    onFieldChange(list.concat([{}]), node);

    animateListItem(list.length, true);
  };

  const remove = async (i: number) => {
    await animateListItem(i, false);

    list = list.slice(0);
    list.splice(i, 1);

    onFieldChange(list, node);
  };

  const skipChildren = true;
  const missing = warnOnMissingRequired && node.isMissingRequiredFields(application, skipChildren);

  return (
    <div className="FormList">
      {node.title ? <h2>{toString(node.title, onFormError)}</h2> : null}

      {help ? <div className="help">{formatParagraphText(toString(help, onFormError))}</div> : null}

      {missing ? <div className="error">Add at least {digitToString(required as number)}.</div> : null}

      <div>
        {list
          .map((listItem, i) => (
            <div className="list-item" key={i}>
              {canBeEmpty || list.length > 1 ? (
                <button
                  className="button invisible delete"
                  onClick={e => {
                    e.preventDefault();
                    remove(i);
                  }}
                >
                  <div className="icon">
                    <MinusIcon />
                    <div className="label">Remove</div>
                  </div>
                </button>
              ) : null}
              <NumberBubble number={i + 1} />
              <div className="fields">
                {renderChildren({
                  ...props,
                  node: node.getListEntryNode(i),
                  application
                })}
              </div>
            </div>
          ))
          .map(renderListItem)}
      </div>

      <button
        className="button invisible add"
        onClick={e => {
          e.preventDefault();
          add();
        }}
      >
        <div className="icon">
          <PlusIcon />
        </div>
        <div className="label">Add {list.length ? 'another' : ''}</div>
      </button>
    </div>
  );
}
