import { useQuery } from 'react-query';

// unique key for this query
const CURRENT_QUERY_KEY = 'prerender';

class FetchError extends Error {
  constructor(public message: string, public statusCode: number) {
    super(message);
  }
}

// request prerender url for some amount of time before dropping an error
function usePrerender(url: string) {
  return useQuery(
    [CURRENT_QUERY_KEY, url],
    () =>
      fetch(url).then(res => {
        if (!res?.ok) {
          throw new FetchError('Problem fetching preview', res.status);
        }

        return res.text();
      }),
    {
      // Will retry failed and 404 responses requests for 6 minutes
      retry: (failureCount, error: FetchError) => (error.statusCode === 404 && failureCount <= 4 * 6 ? true : false),
      retryDelay: 15 * 1000 // 15 seconds
    }
  );
}

const prerenderQuery = { QUERY_KEY: CURRENT_QUERY_KEY, usePrerender };
export default prerenderQuery;
