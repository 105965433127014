import React from 'react';
import FormNotificationStatus from './FormNotificationStatus';
import track from '@sharedComponents/base/track';
import { FormNodeProps } from '../FormNodeProps';
import ApplicationModel, {
  CounselorNotificationStatus,
  NotificationStatus,
  RecommenderNotificationStatus
} from '@sharedClients/types/ApplicationModel';
import SupportModel from '@sharedClients/types/SupportModel';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

export default function FormRecommenderStatus(props: FormNodeProps) {
  return (
    <FormNotificationStatus
      {...props}
      getStatus={getStatus}
      sendNotification={sendRecommenderNotification as any /** cuz of async, but that's changes nothing */}
      objectName={'recommendation'}
      actorName={'recommender'}
    />
  );
}

function getStatus(
  email: string,
  { supports, application }: { supports: SupportModel[] | null; application: ApplicationModel }
): NotificationStatus {
  const support =
    supports && supports.find(support => support.data.recommender && support.data.recommender.email === email);

  let status: NotificationStatus;

  if (support) {
    status = support.submittedAt ? 'submitted' : 'partial';
  } else {
    status = ((application.recommenderNotificationStatus || {})[email] || {}).status || 'unsent';
  }

  return status;
}

export function sendRecommenderNotification({
  name,
  email,
  phone,
  customContent,
  onError,
  application,
  applicationClient,
  setRecommenderNotificationStatus
}: {
  name: string;
  email: string;
  phone: string;
  customContent?: string;
  application: ApplicationModel;
  onError: (e: Error) => void;
  applicationClient: ApplicationClient;
  setCounselorNotificationStatus: (status: CounselorNotificationStatus) => void;
  setRecommenderNotificationStatus: (status: RecommenderNotificationStatus) => void;
}) {
  try {
    return applicationClient
      .notifyRecommender(application.id, {
        name,
        email,
        phone,
        content: customContent || ''
      })
      .then(result => {
        const { status } = result;
        // there's no status if the notification had already been sent
        if (status) {
          track('application-notifiedRecommender');
        }

        return status;
      })
      .then(s => setRecommenderNotificationStatus(s))
      .catch(onError);
  } catch (e: any) {
    onError(e);
  }
}
