import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import { openGoogleDocs } from '@donors/application/Thumbnail';
import {
  EuiButton,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiPopover,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTextArea,
  EuiTitle,
  EuiToolTip
} from '@elastic/eui';
import { EditableReviewModel } from '@sharedClients/types/ReviewModel';
import useDebounce from '@sharedComponents/hooks/useDebounce';
import useLocalStorage from '@sharedComponents/hooks/useLocalStorage';
import { ReviewCriteriaRoundConfiguration } from '@sharedComponents/interfaces/Scholarships.interface';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

enum VIEW_MODE {
  MODE_BUTTONS,
  MODE_INPUTS
}

export function ReviewFlyout({
  client,
  reviewInstructions,
  currentReview,
  reviewCriteria,
  isLoading,
  upsertReview,
  isUnsaved,
  onClose
}: {
  client: ApplicationClient;
  currentReview: EditableReviewModel;
  reviewCriteria: ReviewCriteriaRoundConfiguration;
  isLoading: boolean;
  isUnsaved: boolean;
  onClose: () => void;
  upsertReview: (review: EditableReviewModel, isSave: boolean) => void;
  reviewInstructions?: number | null;
}) {
  const [viewMode, setViewMode] = useLocalStorage<VIEW_MODE>('leaveReviewPanel.viewMode', VIEW_MODE.MODE_BUTTONS);
  const [isSettingsPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isReviewValid, setIsReviewValid] = useState(false);

  const [review, setReview] = useState<EditableReviewModel>({ ...currentReview, data: { ...currentReview.data } });
  const debouncedReview = useDebounce<EditableReviewModel>(review, 500);

  // updating review status in parent to keep review between panel closing/opening
  useEffect(() => {
    if (JSON.stringify(debouncedReview) !== JSON.stringify(currentReview)) {
      upsertReview(debouncedReview, false);
    }
  }, [debouncedReview]);

  const maxScore = Math.max(...Object.values(reviewCriteria).map(r => r.scale)) || 5; // 5 is default, TODO:IMP: constant
  const criteriaIds = Object.entries(reviewCriteria).sort();
  const isForcingViewMode = maxScore > 10; // for any criteria score higher than 10, force view_mode.MODE_INPUTS

  const isScoreValid = (score: number | string | null) => {
    const scoreInt = parseInt(score as any); // `as any` is needed to allow usage of parseInt with 'null' values
    return isNil(score) || (!isNaN(scoreInt) && scoreInt >= 0 && scoreInt <= maxScore && scoreInt.toString() == score);
  };

  const isReviewDataValid = (data: EditableReviewModel['data']) => {
    return Object.values(data).every(score => isScoreValid(score));
  };

  const openReviewInstructions = () => {
    if (client && reviewInstructions) {
      openGoogleDocs(reviewInstructions, {
        applicationClient: client,
        applicationId: currentReview.application_id,
        // eslint-disable-next-line no-console
        onError: err => console.error(err)
      });
    }
  };

  useEffect(() => {
    setIsReviewValid(isReviewDataValid(review.data));
  }, []);

  function setScore(score: string | null, key: string) {
    if (score == '') {
      score = null;
    }

    const newData = { ...review.data, [key]: score };
    if (isReviewDataValid(newData)) {
      if (!isReviewValid) {
        setIsReviewValid(true);
      }
    } else if (isReviewValid) {
      setIsReviewValid(false);
    }

    setReview({ ...review, data: newData });
  }

  return (
    <EuiFlyout
      onClose={onClose}
      size="m"
      maxWidth="500px"
      paddingSize="s"
      ownFocus={false}
      aria-labelledby="flyoutTitle"
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="xs">
          <h1 id="flyoutTitle">
            Review panel{' '}
            <EuiPopover
              ownFocus
              button={
                <EuiButtonIcon
                  iconType="gear"
                  onClick={() => setIsPopoverOpen(isSettingsPopoverOpen => !isSettingsPopoverOpen)}
                  aria-label="Open review panel settings"
                ></EuiButtonIcon>
              }
              isOpen={isSettingsPopoverOpen}
              closePopover={() => setIsPopoverOpen(false)}
            >
              <EuiFormRow label="Review panel settings">
                <EuiFlexGroup direction="column">
                  <EuiFlexItem>
                    <EuiSwitch
                      name="toggleNumeric"
                      label="Show numeric scores"
                      checked={isForcingViewMode || viewMode === VIEW_MODE.MODE_INPUTS}
                      disabled={isForcingViewMode}
                      onChange={() => {
                        setViewMode(
                          viewMode === VIEW_MODE.MODE_INPUTS ? VIEW_MODE.MODE_BUTTONS : VIEW_MODE.MODE_INPUTS
                        );
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            </EuiPopover>
          </h1>
        </EuiTitle>
        <EuiFlexGroup direction="row" gutterSize="s" justifyContent="spaceAround">
          <EuiFlexItem>
            {isUnsaved ? (
              <EuiToolTip position="top" content="Contains unsaved data!">
                <EuiButton onClick={onClose} iconType={'alert'} color={'danger'} fullWidth>
                  <EuiText size="s">Hide Review Panel</EuiText>
                </EuiButton>
              </EuiToolTip>
            ) : (
              <EuiButton onClick={onClose}>
                <EuiText size="s">Hide Review Panel</EuiText>
              </EuiButton>
            )}
          </EuiFlexItem>
          {!!reviewInstructions && (
            <EuiFlexItem>
              <EuiButton color={'text'} onClick={openReviewInstructions}>
                <EuiText size="s">Criteria Instructions</EuiText>
              </EuiButton>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiButton
              disabled={!isReviewValid}
              onClick={async () => await upsertReview(review, true)}
              isLoading={isLoading}
            >
              <EuiText size="s">Save</EuiText>
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiButtonIcon
          aria-label="Minimize review panel"
          className="leaveReviewPanel-hide"
          onClick={onClose}
          iconType="minimize"
          size="s"
          display="base"
        />
        <EuiFlexGrid columns={1} direction="column">
          {criteriaIds.map(([key, criterion]) => {
            const scoreSet = review.data[key];
            const rangeValues = range(criterion.scale + 1);
            const options = rangeValues.map(score => ({
              id: `${key}_${score}`,
              label: score
            }));

            const selectedOption = options.find(opt => opt.id === `${key}_${scoreSet}`);
            return (
              <EuiFlexItem key={key}>
                <>
                  {!isForcingViewMode && viewMode === VIEW_MODE.MODE_BUTTONS ? (
                    <EuiFlexItem>
                      <EuiFormRow label={criterion.name} fullWidth={true} id={key}>
                        <EuiButtonGroup
                          key={criterion.name}
                          buttonSize="s"
                          color="primary"
                          type="single"
                          legend={criterion.name}
                          name={criterion.name}
                          options={options}
                          idSelected={selectedOption?.id || ''}
                          onChange={optionId => {
                            setScore(optionId.split('_')[1], key);
                          }}
                          isFullWidth
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  ) : (
                    <>
                      <EuiToolTip content={criterion.name}>
                        <EuiText size="s" className="leaveReviewPanel-input_label">
                          <strong>{criterion.name}:</strong>
                        </EuiText>
                      </EuiToolTip>
                      <EuiFieldText
                        aria-label={criterion.name}
                        value={isNil(scoreSet) ? '' : scoreSet}
                        isInvalid={!isScoreValid(scoreSet)}
                        fullWidth
                        append={
                          <EuiText size="s">
                            <strong>/ {criterion.scale}</strong>
                          </EuiText>
                        }
                        onChange={e => {
                          setScore(e.target.value, key);
                        }}
                      />
                    </>
                  )}
                </>
              </EuiFlexItem>
            );
          })}
        </EuiFlexGrid>
        <EuiSpacer />
        <EuiFormRow label="Notes" fullWidth>
          <EuiTextArea
            id="review-notes"
            disabled={isLoading}
            value={review.notes || ''}
            onChange={e => {
              const newNotes = e.target.value;
              setReview({ ...review, notes: newNotes });
            }}
            fullWidth
          />
        </EuiFormRow>
      </EuiFlyoutBody>
    </EuiFlyout>
  );
}

function range(n: number) {
  return Array.from(Array(n), (x, i) => i);
}
