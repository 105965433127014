import '@apply/apply/ApplyExternal.scss';

import { isNil, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import ApplyExternal from '@apply/apply/ApplyExternal';
import SubmissionForm, { setField } from '@apply/apply/SubmissionForm';
import SubmissionSaver from '@apply/apply/SubmissionSaver';
import SubmitConfirmation from '@apply/apply/SubmitConfirmation';
import addStandardFields from '@apply/base/addStandardFields';
import { useErrorReporter, UserError } from '@apply/base/useErrorReporter';
import warn from '@apply/base/warn';
import { toList } from '@apply/model/cast';
import FormNode, { isApplicationAcceptance } from '@apply/model/FormNode';
import myApplicationsQuery from '@apply/queries/myApplicationsQuery';
import ApplicationModel, {
  CounselorNotificationStatus,
  RecommenderNotificationStatus
} from '@sharedClients/types/ApplicationModel';
import { FormData } from '@sharedClients/types/FormDataNode';
import IScholarshipClient from '@sharedClients/types/IScholarshipClient';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import SupportModel from '@sharedClients/types/SupportModel';
import track from '@sharedComponents/base/track';
import { USER_TYPE } from '@sharedComponents/constants';
import { useRouter } from '@sharedComponents/hooks/useRouter';
import { useApplicationClient } from '@sharedComponents/selectors/useApplicationClient';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';
import UserModel from '@sharedContract/UserModel';

/**
 * Handles fetching and storing of the scholarship and the application.
 * ! its super messy and has to be refactored %(
 */
const Apply = props => {
  const {
    scholarshipId,
    saveInterval,
    onSaveStatusChange,
    onScholarship,
    animationTime,
    user,
    applicationID,
    isRecurringAward, // recurring awards applications are separated from default flow
    onAfterSubmit
  } = props;

  const router = useRouter();

  const queryClient = useQueryClient();

  const scholarshipClient = useScholarshipClient();
  const applicationClient = useApplicationClient();
  const [onError, errorMessage, clearError] = useErrorReporter();
  const [applicationWithoutId, setApplication] = useState<ApplicationModel | null>(null);
  const [form, setForm] = useState<FormData>([]);

  const isStandAloneApplication = user.type === USER_TYPE.ADMIN;

  // the application ID is assigned after the first save;
  // if we update the application to include it we risk overwriting
  // what the user entered in the meantime, so it needs to be
  // separate
  const [applicationId, setApplicationId] = useState<number | null>(applicationID ? applicationID : null);
  const [scholarship, setScholarship] = useState<ScholarshipModel | null>(null);
  const [supports, setSupports] = useState<SupportModel[] | null>(null);
  const [isShowSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [recommenderNotificationStatus, setRecommenderNotificationStatus] =
    useState<RecommenderNotificationStatus | null>(null);
  const [counselorNotificationStatus, setCounselorNotificationStatus] = useState<CounselorNotificationStatus | null>(
    null
  );

  const application: ApplicationModel | null = applicationWithoutId
    ? {
        ...applicationWithoutId,
        scholarship: scholarship as ScholarshipModel,
        id: applicationId as number,
        ...(recommenderNotificationStatus ? { recommenderNotificationStatus } : null),
        ...(counselorNotificationStatus ? { counselorNotificationStatus } : null)
      }
    : null;

  useEffect(() => {
    if ([USER_TYPE.APPLICANT, USER_TYPE.ADMIN, USER_TYPE.PROFESSIONAL].includes(user.type as USER_TYPE)) {
      getScholarship(scholarshipId, scholarshipClient)
        .then(scholarship => {
          document.title = `Scholar's App - ${scholarship.name}`;

          if (onScholarship) {
            onScholarship(scholarship);
          }

          if (!isRecurringAward && scholarship.form) {
            setForm(
              addStandardFields(
                scholarship.form.standardFields,
                scholarship.form.data,
                scholarship.standardForm as FormData
              )
            );
          }

          return scholarship;
        })
        .then(setScholarship)
        .catch(onError);
    } else {
      onError(new Error(`This functionality is only available to applicants (you are a ${user.type}).`));
    }
  }, [scholarshipId, user.id]);

  useEffect(() => {
    if (isRecurringAward && applicationId) {
      applicationClient.getRecurringAwardApplication(applicationId).then(recurrentAwardEntry => {
        if (recurrentAwardEntry.submission && Object.keys(recurrentAwardEntry.submission).length) {
          // TODO:IMP type solving in this case
          setApplication({ ...(recurrentAwardEntry.submission as any), form: undefined, isNew: undefined });
        } else {
          setApplication({ form: undefined, isNew: undefined } as any);
        }

        if (recurrentAwardEntry.form?.data?.length) {
          setForm(recurrentAwardEntry.form.data);
        }
      });
    } else if (isStandAloneApplication && applicationId) {
      applicationClient.getApplication(applicationId).then(application => {
        setApplication({ ...application, form: undefined, isNew: undefined });
      });
    } else {
      applicationClient
        .getApplicationForScholarship(scholarshipId)
        .then(application => {
          application = { ...application };
          if (application.isNew) {
            track('application-' + (application['isExternal'] ? 'createExternal' : 'create'));
          }

          // this attribute (that we don't even need) is mushed up with the
          // form data in the response. might be good to change sometime.
          setApplication({ ...application, form: undefined, isNew: undefined });

          setApplicationId(application.id);

          // standalone application has no id at this time
          if (application.id) {
            applicationClient.getSupportsForApplication(application.id).then(setSupports).catch(onError);
          }
        })
        .catch(onError);
    }
  }, [scholarshipId, user.id]);

  // * ////////////

  function onSubmit() {
    if (!application) {
      return;
    }

    if (areRecommendersDuplicated({ application, onError })) {
      return;
    }

    handleApplicationChange({ ...application, submittedAt: new Date() }, true);

    track('application-submit');

    // need to wait for the application value to update
    return soon().then(() =>
      saver.save().then(
        _ => {
          setShowSubmitConfirmation(true);
          if (onAfterSubmit) {
            onAfterSubmit();
          }
        },
        _ => {
          /* save failure; already logged */
        }
      )
    );
  }

  /**
   * Prepares application for database update
   * Cleanups non-actual fields and do other magic
   */
  function handleApplicationChange(changedApplication: ApplicationModel, forceSave = false) {
    let updatedApplication; // instance of application which is supposed to be sent to backend

    const formNode: FormNode = new FormNode(form, (e: Error) => warn(e));
    const isSubmit = !applicationWithoutId?.submittedAt && changedApplication.submittedAt;

    // this also fires on edit for submitted applications [edits are autosaved, so its not possible to 'fix', but this is potentially a performance bottleneck]
    if (changedApplication.submittedAt) {
      // We are submitting our application or editing submitted one. At this point we have to clean out conditional and old values fields which still might be existing in our application data. We get all the available formfields and read their data
      const allFormFields = formNode.getAllActiveFieldNodes(changedApplication, true);
      updatedApplication = {
        id: changedApplication.id
      };

      // if wasnt submitted before
      if (isSubmit) {
        updatedApplication.submittedAt = changedApplication.submittedAt; // letting to submit application this way

        // Actions are only being collected and fired during first submit
        // ? [shall we calculate them on backend instead ?]
        const formActions = formNode.getActiveActions(changedApplication);
        if (formActions.length) {
          // ? type for actions?
          updatedApplication.actions = [
            ...formActions.map(actionNode => ({ action: actionNode.action, value: actionNode.value }))
          ];
        }
      }

      for (const availableFormField of allFormFields) {
        const fieldFullPath = availableFormField.getValuePath();
        let fieldValue;
        let formField = availableFormField;

        // ? this could be moveed into formNodes
        // if parent is a list or recommenders-list, getting value with normal way fails, we have to handle value of the list itself
        if (fieldFullPath?.length > 1 && availableFormField.parent) {
          const parents = availableFormField.getParents();
          // we have edge case of hardcoded macros which for now is being checked this way
          if (parents.some(parent => parent.type === 'list' || parent.type === 'recommenders-list')) {
            formField = parents.find(
              parent => parent.type === 'list' || parent.type === 'recommenders-list'
            ) as FormNode; // we excluded case with undefined by checking it above

            fieldValue = toList(formField.getValue(changedApplication), () => {});
          } else {
            fieldValue = availableFormField.getValue(changedApplication, fieldFullPath);
          }
        } else {
          fieldValue = availableFormField.getValue(changedApplication, fieldFullPath);
        }

        if (!isNil(fieldValue)) {
          updatedApplication = setField(fieldValue, formField, updatedApplication);
        }
      }
    } else {
      // in case applications is not yet submitted, we keep fields as they are with the aim of performance
      updatedApplication = { ...changedApplication };
    }

    if (isAwardAcceptance && changedApplication.awardStatus) {
      // when acceptance happens, we have one extra, non-listed field added into application and we have to transfer it this dirty way
      updatedApplication.awardStatus = changedApplication.awardStatus;
    }

    setApplication(changedApplication);

    // Not auto-saving award acceptance state and standalone applications
    if (forceSave || (!isAwardAcceptance && !isStandAloneApplication)) {
      saver.onEdit({ ...updatedApplication }, scholarshipId);
    }
  }

  // ? memo makes no sence here?
  const saver = useMemo(
    () =>
      new SubmissionSaver<[ApplicationModel, number], { application: ApplicationModel }>(
        (application: ApplicationModel, scholarshipId: number) => {
          if (isRecurringAward) {
            // this one handled separately
            // Im not sure why scholarship is being a part of application, but we dont want to break that logic now, just we dont need this for recurring awards
            return applicationClient
              .upsertRecurringAwardApplication(applicationId!, omit(application, 'scholarship') as any)
              .then(result => {
                return result;
              });
          }

          return applicationClient.upsertApplicationForScholarship(application, scholarshipId).then(result => {
            setApplicationId(result.applicationId);

            return result;
          });
        },
        (e: Error) => {
          if (e['cause'] === 'expired-jwt') {
            onError(
              new UserError(
                'Your session has expired. Reload the page and log in again. ' +
                  'Your past edits are saved (but any further changes will not be).'
              )
            );
          } else {
            onError(e);
          }
        },
        {
          saveInterval,
          onSaveStatusChange
        }
      ),
    [saveInterval]
  );

  saver.useUnsavedChangesWarning();

  const isAwardAcceptance = isApplicationAcceptance(application);

  function showSubmitConfirmation() {
    const message = isStandAloneApplication ? (
      <>Standalone application was successfully created.</>
    ) : (
      <>
        We will notify you by email when the scholarship organization of this{' '}
        {user.type === USER_TYPE.PROFESSIONAL ? 'grant' : 'scholarship'} has processed your application.
        <br />
        The donor or Scholar's App might contact you with questions about your application.
        <br /> You can go back and make changes to your application at any time before the submission deadline.
      </>
    );

    const onConfirmHandle = () => {
      // refresh applications query
      queryClient.invalidateQueries(myApplicationsQuery.QUERY_KEY);

      if (isStandAloneApplication) {
        router.push(`/scholarships/${scholarship!.id}/applications`);
      } else {
        router.push(`/`);
      }
    };

    return !isAwardAcceptance ? (
      <SubmitConfirmation onConfirm={onConfirmHandle} title="Application submitted successfully!" message={message} />
    ) : (
      <SubmitConfirmation
        onConfirm={onConfirmHandle}
        title="Successfully Submitted"
        message={<>Thank you for providing the acceptance information.</>}
      />
    );
  }

  function updateApplication(application: ApplicationModel) {
    return applicationClient
      .upsertApplicationForScholarship(application, scholarshipId)
      .then(({ application, applicationId }) => {
        setApplication(application);
        setApplicationId(applicationId);
      }, onError);
  }

  function markApplied(_scholarship: ScholarshipModel) {
    track('application-markedAsApplied');

    updateApplication({
      ...(applicationWithoutId as ApplicationModel),
      submittedAt: new Date()
    });
  }

  function notifyCounselorExternal() {
    const counselor = application!.counselor;

    return applicationClient
      .notifyCounselor(
        {
          name: counselor!.name as string,
          email: counselor!.email as string,
          phone: counselor!.phone as string,
          skipEmail: false
        },
        application!.id
      )
      .then(result => {
        if (result.status === 'sent') {
          track('application-notifiedCounselor');
        }

        return result;
      })
      .then(s => setCounselorNotificationStatus(s))
      .catch(onError);
  }

  return !scholarship || !scholarship.applyUrl ? (
    <div>
      {isShowSubmitConfirmation ? showSubmitConfirmation() : null}
      {application && form ? (
        <SubmissionForm
          {...props}
          submission={application}
          form={form}
          supports={supports}
          isFormAvailable={() => !!(form && application && scholarship)}
          errorMessage={errorMessage}
          setCounselorNotificationStatus={setCounselorNotificationStatus}
          setRecommenderNotificationStatus={setRecommenderNotificationStatus}
          onSubmit={onSubmit}
          onChange={handleApplicationChange}
          onError={onError}
          clearError={clearError}
          donorId={scholarship && scholarship.donor && scholarship.donor.id}
          logoId={scholarship && scholarship.logo}
          applicantId={user.id}
          animationTime={animationTime}
          submitBannerLabel={!isAwardAcceptance ? 'Submit Application' : 'Submit'}
          showSubmittedMessage={!isAwardAcceptance}
          applicationClient={applicationClient}
          onSave={async () => {
            handleApplicationChange(application, true);
            await saver.save();
          }}
        />
      ) : errorMessage}
    </div>
  ) : (
    <ApplyExternal
      scholarship={scholarship}
      application={application}
      user={user}
      updateApplication={updateApplication}
      onMarkApplied={() => markApplied(scholarship)}
      onNotifyCounselor={notifyCounselorExternal}
      errorMessage={errorMessage}
      onError={onError}
      onDiscardApplication={async () => {
        if (application && window.confirm('Are you sure you will not be applying to this scholarship?')) {
          applicationClient
            .deleteApplication(application.id)
            .then(() => router.push('/scholarship/' + scholarshipId))
            .catch(onError);
        }
      }}
    />
  );
};

function soon(ms?: number) {
  return new Promise(resolve => setTimeout(resolve, ms || 0));
}

export function getScholarship(
  scholarshipId: number,
  scholarshipClient: IScholarshipClient
): Promise<ScholarshipModel> {
  if (isNumber(scholarshipId)) {
    return scholarshipClient.getScholarship(scholarshipId, ['standardForm']);
  } else {
    return invalidAddress();
  }
}

export function isNumber(n: any) {
  return n != null && !isNaN(n) && typeof n === 'number';
}

export function invalidAddress() {
  return Promise.reject(new Error('This address is not valid.'));
}

function areRecommendersDuplicated({
  application,
  onError
}: {
  application: ApplicationModel;
  onError: (e: Error) => void;
}) {
  const emails = toList(application.recommenders, warn)
    .map(recommender => recommender.email)
    .filter(email => !!email);

  const areDuplicated = hasDuplicates(emails);

  if (areDuplicated) {
    onError(new UserError('The same recommender is mentioned multiple times.'));
  }

  return areDuplicated;
}

export default Apply;

export function addUserDataToSubmission<T>(user: UserModel, submissionData: T): T {
  if (user) {
    const email = user.name;

    return {
      email,
      ...user.data,
      ...submissionData
    } as any;
  } else {
    return submissionData;
  }
}

function hasDuplicates(list: string[]) {
  const dedup = Array.from(new Set(list));

  return dedup.length < list.length;
}
