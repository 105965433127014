import React from 'react';

import { useAuthStatus } from '@sharedComponents/selectors/loggedInUserSelectors';

import LoginSignupForm from './auth/LoginSignupForm';

// temporal implementation in order to have user atom resolved in time
// it has some issues, use carefully
const ProtectedRoute = ({ props, children }) => {
  // proper implementation for isLoggedIn
  const isLoggedIn = useAuthStatus();

  return <>{!isLoggedIn ? <LoginSignupForm {...props} noHeader /> : <>{children}</>}</>;
};

export default ProtectedRoute;
