export function toList(object: any, onError: (e: Error) => void): any[] {
  if (object == null) {
    return [];
  } else if (isList(object)) {
    return object;
  } else {
    onError(new Error(`Object "${JSON.stringify(object)}" found where list expected`));

    return [];
  }
}

export function isString(object: any) {
  return typeof object == 'string';
}

export function toString(object: any, onError: (e: Error) => void) {
  if (isString(object)) {
    return object;
  } else {
    onError(new Error(`${JSON.stringify(object)} found where string expected`));

    return (object && object.toString()) || '';
  }
}

export function isList(object: any) {
  return typeof object == 'object' && object != null && object.length != null;
}

export function toNumber(object: any, onError: (e: Error) => void) {
  if (typeof object == 'number') {
    return object;
  } else {
    onError(new Error(`${JSON.stringify(object)} found where number expected`));

    return 0;
  }
}
