import { parseInt } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText
} from '@elastic/eui';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';
import { PhoneField, TextField } from '@sharedComponents/formElements';
import FileField from '@sharedComponents/formElements/combined/FileField';
import transcriptForApplicantQuery from '@sharedComponents/queries/transcriptForApplicantQuery';
import {
  TranscriptSubmitModalSchema,
  TranscriptSubmitModalSchemaType
} from '@sharedComponents/schemas/TranscriptModelSchema';

// This modal supports multiple documents type, in order to avoid logic with determening of doc type we make them optional and send only one strictly typed
export function TranscriptSubmitModal({
  onModalSubmit,
  userID
}: {
  onModalSubmit: (isSave: boolean) => Promise<void>;
  userID: number;
}) {
  const {
    data: transcriptForApplicant,
    isError,
    error,
    isLoading,
    isSuccess
  } = transcriptForApplicantQuery.useTranscriptForApplicant(userID);

  const { mutate: submitTranscriptForApplicant, isLoading: isSubmitting } =
    transcriptForApplicantQuery.useMutateTranscriptForApplicant(userID);

  const formMethods = useForm<TranscriptSubmitModalSchemaType>({
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(TranscriptSubmitModalSchema)
  });

  useEffect(() => {
    if (isSuccess && transcriptForApplicant?.data?.transcript?.id) {
      formMethods.reset({
        transcript_file_id: parseInt(transcriptForApplicant.data.transcript.id),
        semester: transcriptForApplicant?.data?.semester || '',
        name: transcriptForApplicant?.data?.counselor?.name || undefined,
        email: transcriptForApplicant?.data?.counselor?.email || undefined,
        phone: transcriptForApplicant?.data?.counselor?.phone || undefined
      });
    }
  }, [isSuccess, transcriptForApplicant]);

  const closeModal = async (isSubmit = false) => {
    onModalSubmit(isSubmit);
  };

  const ctaButtonHandler = (validatedData: TranscriptSubmitModalSchemaType) => {
    submitTranscriptForApplicant(validatedData, {
      onSuccess: () => {
        closeModal(true);
      }
    });
  };

  const isEdit = transcriptForApplicant?.id && transcriptForApplicant?.submitted_at;

  let modalBody;
  if (isError) {
    modalBody = (
      <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
        {error?.message || DEFAULT_ERROR_MESSAGE}
      </EuiCallOut>
    );
  } else if (isLoading) {
    modalBody = (
      <EuiFlexGroup responsive={false} direction="column" style={{ height: '100%' }}>
        <EuiFlexItem style={{ justifyContent: 'center' }}>
          <EuiLoadingSpinner size="xl" style={{ alignSelf: 'center' }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    modalBody = (
      <EuiFlexGroup direction="column" gutterSize="none" responsive={false}>
        <EuiFlexItem>
          {/* margin:0 to avoid scrolls in modal when its possible */}
          <EuiFlexGroup direction="column" responsive={false} gutterSize="m" style={{ margin: 0 }}>
            <EuiFlexItem grow={false}>
              <FormProvider {...formMethods}>
                <EuiFlexGroup direction="column" responsive={false}>
                  <EuiFlexItem>
                    <EuiText size="s">Please upload their transcript below.</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <FileField
                      fieldName={'transcript_file_id'}
                      rowProps={{
                        label: 'Transcript',
                        helpText: 'Submit the transcript in PDF format.',
                        fullWidth: true
                      }}
                      fieldProps={{
                        accept: '.pdf'
                      }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <TextField
                      fieldName={'semester'}
                      fieldProps={{ fullWidth: true }}
                      rowProps={{ label: 'Semester', fullWidth: true }}
                    />
                    <EuiText size="xs">Which semester is the transcript from?</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <TextField
                      fieldName={'name'}
                      fieldProps={{ fullWidth: true }}
                      rowProps={{ label: 'Name', fullWidth: true }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <TextField
                      fieldName={'email'}
                      fieldProps={{ fullWidth: true }}
                      rowProps={{ label: 'Email', fullWidth: true }}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <PhoneField fieldName={'phone'} fieldProps={{ fullWidth: true, defaultValue: '' }} label="Phone" />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </FormProvider>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return (
    <EuiModal onClose={() => closeModal()}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {isEdit ? 'Edit' : 'Submit'} Transcript for user ID: {userID}
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>{modalBody}</EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup direction="column" responsive={false} gutterSize="s">
          <EuiFlexItem style={{ justifyContent: 'flex-end' }}>
            <EuiButton
              size="s"
              color="primary"
              // eslint-disable-next-line no-console
              onClick={formMethods.handleSubmit(ctaButtonHandler, e => console.error(e))}
              isDisabled={isLoading || !isSuccess}
              isLoading={isSubmitting}
              fill
              iconSide="right"
            >
              Submit Transcript
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
}
