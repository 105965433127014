/**
 * Application cycles is a requirement from T3557/T3555
 * "Application cycle"= range of dates when a scholarship opens and closes. This is the cycle in which the scholarship is active and accepting student applications. Ex: 10/1/2018 - 6/1/2019
 * "Application deadline"= the last date that a student can apply for the scholarship.
 * Oct of each year is generally when scholarship cycles open, with a deadline of next year. Ex: 10/1/2018 scholarship cycle opens, deadline 6/1/2019
However, sometimes donors open the scholarship cycle at the last min of same year. Ex: 3/1/2019 scholarship cycle opens, deadline 6/1/2019
 */
export function getApplicationCycleFromDate(date = new Date()) {
  if (!date) {
    date = new Date();
  }

  // October of each year is generally when scholarship cycles open, so we trait this as a guide to take this year or previous
  const SEPTEMBER = 8; // cuz starts from 0
  const dateYear = date.getFullYear();
  if (date.getMonth() >= SEPTEMBER) {
    // We are using september(instead of october as in requirements) based on PROD data on PB&J scholarship since it has first applications from september 2019, while first app cycle supposed to be 2020
    return dateYear + 1;
  }

  return dateYear;
}

/**
 * Since we only have applications starting on December 1, 2019
 * at the moment of 01.01.2021 we have 2 application cycles for old scholarships:
 * any date - 10/01/2020 = 2020
 * 10/01/2020 - 10/01/2021 = 2021
 * + New cases which will have application cycle listed in scholarships at the moment of submitting
 * @returns 'from' and 'to' dates representation for database instance to fetch app cycles
 */
const MIGRATION_DATE = '2020-10-01T00:00:00.001Z';
const OLDEST_DATE = '1970-01-01T00:00:00.001Z';

/**
 * @description This function is also used for cases when there's no app cycle set in database records,
 * but still need to guess app cycle.
 * However consider this is being a very randomed guess
 */
export function getLegacyApplicationDateDB(applicationCycle: number) {
  switch (applicationCycle) {
    case 2020: {
      return {
        from: OLDEST_DATE,
        to: MIGRATION_DATE
      };
    }

    case 2021: {
      return {
        from: MIGRATION_DATE,
        to: '2021-09-30T23:59:59.000Z'
      };
    }

    default: {
      // guessing date ranges for any year 2022+
      return {
        from: `${applicationCycle - 1}-10-01T00:00:00.001Z`,
        to: `${applicationCycle}-09-30T23:59:59.000Z`
      };
    }
  }
}

/**
 * Returns an array with application cycle years suitable for this scholarship
 * @param scholarship [Scholarship representation]
 */
export function getApplicationCycles(scholarship: {
  created_at: string | Date;
  deadline?: string | Date;
  application_cycle?: number;
}) {
  // supposed to have all the cycles from created_at > till current app_cycle or deadline
  const dateFrom = new Date(scholarship.created_at);

  const currentApplicationCycle =
    scholarship.application_cycle ||
    getApplicationCycleFromDate(scholarship.deadline ? new Date(scholarship.deadline) : new Date());

  const startingApplicationCycle = getApplicationCycleFromDate(dateFrom);

  if (currentApplicationCycle > startingApplicationCycle) {
    const appCycles: Array<number> = [];
    for (let index = startingApplicationCycle; index <= currentApplicationCycle; index++) {
      appCycles.push(index);
    }

    return appCycles;
  }

  return [currentApplicationCycle];
}
