import { useQuery } from 'react-query';

import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { DonorModelShape } from '@sharedComponents/models';
import { useScholarshipClient } from '@sharedComponents/selectors/useScholarshipClient';

// unique key for this query
const CURRENT_QUERY_KEY = 'forms';

function useForms(page: number, textFilter = '', fields: string[] = []) {
  const scholarshipClient = useScholarshipClient();
  const query = {
    ...useQuery<Page<DonorModelShape>, Error>(
      [CURRENT_QUERY_KEY, page, textFilter, fields],
      () => scholarshipClient.getForms({ page, textFilter, fields }),
      {
        staleTime: 1000 * 60 * 5
      }
    )
  };

  return query;
}

const formsQuery = { QUERY_KEY: CURRENT_QUERY_KEY, useForms };
export default formsQuery;
