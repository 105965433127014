import './ViewScholarship.scss';

import { History } from 'history';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import DraftIcon from '@sharedAssets/draft.png';
import ScholarshipModel from '@sharedClients/types/ScholarshipModel';
import Field from '@sharedComponents/base/components/Field';
import NextPrevNavigation from '@sharedComponents/base/components/NextPrevNavigation';
import formatAwardAmount from '@sharedComponents/utils/formatAwardAmount';
import formatGpaRange from '@sharedComponents/base/formatGpaRange';
import { formatParagraphText, formatSingleParagraph } from '@sharedComponents/base/MarkdownLite';
import stateByAbbreviation from '@sharedComponents/base/stateByAbbreviation';
import useCachedState, { getInMemoryStorage } from '@sharedComponents/base/useCachedState';
import { defaultRecommenderFormId } from '@sharedComponents/Scholarship/EditScholarship';
import { getScholarshipLink } from '@sharedComponents/ScholarshipList/ScholarshipList';
import formatDate from '@sharedComponents/utils/formatDate';
import { isScholarshipExpired } from '@sharedComponents/utils/scholarshipUtils';
import UserModel from '@sharedContract/UserModel';

const isInTest = process.env.NODE_ENV === 'test';
export default function ViewScholarship({
  scholarship,
  scholarshipClient,
  user,
  history
}: {
  scholarship: ScholarshipModel;
  scholarshipClient?;
  user?: UserModel;
  history: History;
}) {
  const permalink = scholarship.urlPath ? `https://scholarsapp.com/scholarship/${scholarship.urlPath}` : null;

  // might be found in the browser cache; remove again after a while.
  if (!scholarship.data) {
    scholarship.data = {} as any;
  }

  const [scholarshipList] = useCachedState<ScholarshipModel[]>('scholarships', []);

  const isAdmin = user && user.type === 'admin';
  const form = scholarship.form || { id: 0, name: 'n/a', data: [] };

  const scholarshipListIds = (scholarshipList || []).map(s => s.id);
  const currentIndex = scholarshipListIds.indexOf(scholarship.id);

  const gpaRange = formatGpaRange(scholarship);

  const nextId = currentIndex < scholarshipListIds.length - 1 ? scholarshipListIds[currentIndex + 1] : null;

  // preloading next scholarship
  useEffect(() => {
    if (scholarshipClient && nextId && !isInTest) {
      const isDraft = scholarshipList[currentIndex + 1].isDraft;
      const cacheKey = 'scholarship.' + nextId + (isDraft ? '.draft' : ''); // TODO:IMP: unify with Scholarship.tsx:56
      const storage = getInMemoryStorage();

      if (storage.getItem(cacheKey) == null) {
        setTimeout(() => {
          scholarshipClient.getScholarship(nextId, [], [], undefined, isDraft).then(
            scholarship => {
              storage.setItem(cacheKey, scholarship);
            },
            // eslint-disable-next-line no-console
            e => console.error(e)
          );
        }, 500);
      }
    }
  }, [nextId, currentIndex, scholarshipClient]);

  return (
    <div className="ViewScholarship">
      <NextPrevNavigation
        prev={currentIndex > 0 ? getScholarshipLink(scholarshipList[currentIndex - 1]) : undefined}
        next={nextId != null ? getScholarshipLink(scholarshipList[currentIndex + 1]) : undefined}
        history={history}
      >
        <div className="pageTitle">
          <h4>
            {`${scholarship.is_grant ? 'Grant' : 'Scholarship'} `}
            {isAdmin ? (
              <span>
                by <Link to={`/donors/${scholarship.donor.id}`}>{scholarship.donor.name}</Link>{' '}
              </span>
            ) : null}
          </h4>
          <h2>
            {scholarship.name}{' '}
            {scholarship?.isDraft ? (
              <img className="pageTitle-icon" src={DraftIcon} title="Is draft" alt="draft" />
            ) : null}
          </h2>
        </div>
      </NextPrevNavigation>

      {isScholarshipExpired(scholarship) ? (
        <div className="help">
          To relist the scholarship next year, update the fields "Application opens on" and "Deadline" to refer to next
          year.
        </div>
      ) : null}

      <div className="columns">
        <div className="column">
          <h3>General</h3>
          <Field label="Application opens on">
            <div className="form">{formatDate(scholarship.data.startDate || new Date(2019, 11, 1))}</div>
          </Field>
          <Field label="Deadline">
            <div className="form">{formatDate(scholarship.deadline)}</div>
            {scholarship.data?.is_hidden_deadline ? (
              <span className="help">(hidden deadline)</span>
            ) : null}
          </Field>
          <Field label="Candidates Limit">
            <div className="form">{scholarship.candidates_limit || '-'}</div>
          </Field>
          <Field label="Categories">
            <div className="form">{scholarship.data.categories ? scholarship.data.categories : '-'}</div>
          </Field>
          <Field label="Description">
            <div className="form">{formatParagraphText(scholarship.description)}</div>
          </Field>
          <Field label="Award amount">
            <div className="form">{formatAwardAmount(scholarship)}</div>
          </Field>
          {scholarship.originalUrl ? (
            <Field label="Donor listing URL">
              <div className="form">
                <a
                  href={scholarship.originalUrl}
                  className="clamp"
                  target={'_original_' + scholarship.id}
                  rel="noopener noreferrer"
                >
                  {scholarship.originalUrl}
                </a>
              </div>
            </Field>
          ) : null}
          {permalink ? (
            <Field label="Scholar's App URL">
              <div className="form">
                <a href={permalink} className="clamp" target={'_original_' + scholarship.id}>
                  {permalink}
                </a>
              </div>
            </Field>
          ) : null}
          {scholarship.nicheness && user && user.type === 'admin' ? (
            <Field label="Nicheness">
              <div className="form">{scholarship.nicheness}</div>
            </Field>
          ) : null}
        </div>
        <div className="column">
          <h3>Eligibility</h3>
          <Field label="Gender">
            <div className="form">{optionsToString(scholarship.data.genders)}</div>
          </Field>
          <Field label="Group identities">
            <div className="form">{optionsToString(scholarship.data.ethnicities)}</div>
          </Field>
          {scholarship.data.lowIncome ? <Field label="Low Income">Required</Field> : null}
          {scholarship.data.communityService ? <Field label="Community Service">Required</Field> : null}
          {scholarship.data.firstGen ? <Field label="First Generation at College">Required</Field> : null}
          {scholarship.eligibility && scholarship.eligibility.length ? (
            <Field label="Applicant must...">
              <ul>
                {(scholarship.eligibility || []).map((str, i) => (
                  <li key={i + ''}>{formatSingleParagraph(str)}</li>
                ))}
              </ul>
            </Field>
          ) : null}
          {scholarship.data.schools && scholarship.data.schools.length ? (
            <>
              <Field label="Application must attend the following High School">
                <ul>
                  {(scholarship.data.schools || []).map((str, i) => (
                    <li key={i + ''}>{formatSingleParagraph(str.name)}</li>
                  ))}
                </ul>
              </Field>
            </>
          ) : null}
        </div>
        <div className="column">
          <h3>Current Studies</h3>

          <Field label="Academic Year">
            <div className="form">{optionsToString(scholarship.data.academicYears)}</div>
          </Field>
          <Field label="GPA">
            <div className="form">{gpaRange === 'any' ? <span className="any">any</span> : gpaRange}</div>
          </Field>
          <Field label="Went to high school in">
            <div className="form">{statesToString(scholarship.data.schoolStates)}</div>
          </Field>
          <Field label="Resident of">
            <div className="form">{statesToString(scholarship.data.residency)}</div>
          </Field>
        </div>
        <div className="column">
          <h3>Future Studies</h3>

          <Field label="Field">
            <div className="form">{optionsToString(scholarship.data.areas)}</div>
          </Field>
          <Field label="Study rate">
            <div className="form">{optionsToString(scholarship.data.studyRates)}</div>
          </Field>
          {scholarship.data.college ? (
            <Field label="Will attend">
              <div className="form">
                {scholarship.data.college.name} ({(scholarship.data.collegeStates || []).join(', ')})
              </div>
            </Field>
          ) : (
            <React.Fragment>
              <Field label="Will attend college in">
                <div className="form">{statesToString(scholarship.data.collegeStates)}</div>
              </Field>
              <Field label="College type">
                <div className="form">{optionsToString(scholarship.data.collegeTypes)}</div>
              </Field>
            </React.Fragment>
          )}
        </div>
        <div className="column">
          <h3>Application process</h3>
          {scholarship.applyUrl ? (
            <React.Fragment>
              <Field label="Application URL">
                <a
                  className="clamp"
                  href={scholarship.applyUrl}
                  target={'_apply_' + scholarship.id}
                  rel="noopener noreferrer"
                >
                  <div className="form">{scholarship.applyUrl}</div>
                </a>
              </Field>
              <Field label="Request transcript">
                <div className="form">{scholarship.requireTranscript ? 'yes' : <span className="any">no</span>}</div>
              </Field>
            </React.Fragment>
          ) : (
            <div>
              <Field label="Essay instructions">
                <div className="instructions">
                  {formatParagraphText(scholarship.essayInstructions as string) || 'None'}
                </div>
              </Field>
              <Field label="Recommender instructions">
                <div className="instructions">
                  {formatParagraphText(scholarship.recommenderInstructions as string) || 'None'}
                </div>
              </Field>
              <Field label="Allowed recommenders">
                <div className="form">
                  {scholarship.allowedRecommenders ? scholarship.allowedRecommenders : 'Anyone'}
                </div>
              </Field>
              {scholarship.minRecommenders ? (
                <Field label="Required number of recommenders">
                  <div className="form">{scholarship.minRecommenders}</div>
                </Field>
              ) : null}
              <Field label="Application form" id="form">
                <Link to={`/forms/${form.id}`}>
                  <div className="form">{form.name}</div>
                </Link>
                {form?.data && !(form.data as any).length ? (
                  <div className="error">This form defines no fields; students will not be able to apply.</div>
                ) : null}
              </Field>
              {scholarship?.recommenderForm?.id && scholarship.recommenderForm.id !== defaultRecommenderFormId ? (
                <Field label="Recommender form">
                  <Link to={`/forms/${scholarship.recommenderForm.id}`}>
                    <div className="form">{scholarship.recommenderForm.name}</div>
                  </Link>
                </Field>
              ) : null}
              <Field label="Allow questions">
                <div className="form">{scholarship.allowQuestions ? 'yes' : <span className="any">no</span>}</div>
              </Field>
            </div>
          )}
        </div>
        {scholarship.reviewCriteria && !scholarship.applyUrl ? (
          <div className="column">
            <h3>Review process</h3>

            <div className="reviewCriteria">
              <div className="criterion header">
                <div className="name">Name</div>
                <div className="weight">Weight</div>
                <div className="scale">Scale</div>
              </div>
              {Object.values(scholarship.reviewCriteria).map((c, i) => (
                <div className="criterion" key={i}>
                  <div className="name">{c.name}</div>
                  <div className="weight">{c.weight}</div>
                  <div className="scale">0–{c.scale}</div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {scholarship.data.notes && user && user.type === 'admin' ? (
          <div className="column">
            <h3>Notes</h3>

            {scholarship.data.notes.split('\n').map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </div>
        ) : null}
        {/* {Boolean(scholarship.data.pastRecipients?.length) && <div className='column'>
					<h3>Past Recipients</h3>
				{scholarship.data.pastRecipients?.map((i, ind)=>
				<div key={i.name}>
				<div style={{padding: '1rem 0'}}>
					<span style={{padding: '0 .5rem'}}>{ind + 1}. Name: {i.name}</span>
					<span style={{padding: '0 .5rem'}}>Note: {i.note}</span>
					</div>
				<img style={{width: '100px'}} src={'data:image/png;base64,' + i.binary?.replace('data:image/png;base64,', '')} />
				</div>
				)}
				</div>
				} */}
      </div>
    </div>
  );
}

function statesToString(keys: string[] | null) {
  return optionsToString((keys || []).map(key => stateByAbbreviation[key]));
}

function optionsToString(options: string[] | null) {
  if (options && options.length) {
    return options.join(', ');
  } else {
    return <span className="any">any</span>;
  }
}
