import React, { useState } from 'react';

import { EuiButton, EuiConfirmModal, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';

const AcceptChoice = ({ onChoice, application }: { onChoice: (value: 'Yes' | 'No') => void; application: any }) => {
  const [isRejectionModalVisible, setIsRejectionModalVisible] = useState(false);

  const rejectionModal = isRejectionModalVisible ? (
    <EuiConfirmModal
      title="REJECT AWARD"
      onCancel={() => setIsRejectionModalVisible(false)}
      onConfirm={() => onChoice('No')}
      cancelButtonText="Cancel"
      confirmButtonText="Reject this award"
      buttonColor="danger"
      defaultFocusedButton="cancel"
    >
      <p>You're about to reject receiving this scholarship, and will not receive the award amount.</p>
      <p>Are you sure you want to do this?</p>
    </EuiConfirmModal>
  ) : null;

  return (
    <>
      <EuiFlexGroup direction="row" justifyContent="spaceEvenly">
        <EuiFlexItem grow={false}>
          <EuiButton color="danger" onClick={() => setIsRejectionModalVisible(true)}>
            Reject the award
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton fill onClick={() => onChoice('Yes')}>
            Accept the award
          </EuiButton>
        </EuiFlexItem>
        {rejectionModal}
      </EuiFlexGroup>
    </>
  );
};

export default AcceptChoice;
