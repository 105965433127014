import { slice } from 'lodash';

import applicantProfileFields from '@apply/profileFields/studentProfileFields';
import { EuiButton, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle } from '@elastic/eui';

import { SIGNUP_STEP } from '../config';
import Step from './AbstractSignupStep';

// Students details page(about you)
const aboutYouStep: Step = {
  validationSchema: () => applicantProfileFields.validationRules,

  render({ formMethods }) {
    const profileFields = applicantProfileFields(formMethods);

    return (
      <EuiFlexGroup direction="column" responsive={false} gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>Academic Information</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="s">
            To receive personalized scholarship recommendations, please fill out the following information. All fields
            are optional.
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGrid columns={2} direction="column" gutterSize="m">
            {slice(profileFields, 0, 11).map((item, key) => {
              return <EuiFlexItem key={key}>{item}</EuiFlexItem>;
            })}
          </EuiFlexGrid>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size="s" className={'m-dark'}>
            <h3>About You</h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGrid columns={2} direction="column" gutterSize="m">
            {slice(profileFields, 11).map((item, key) => {
              return <EuiFlexItem key={key}>{item}</EuiFlexItem>;
            })}
          </EuiFlexGrid>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  },

  async getNextStep() {
    return SIGNUP_STEP.ADDRESS_OF_RESIDENCY;
  },

  renderControls(props) {
    const { formMethods, changeStep } = props;

    // we validate our input and then processing to next step
    const stepSubmitHandler = formMethods.handleSubmit(async () => {
      const nextStep = await this.getNextStep(props);
      if (nextStep) {
        changeStep(nextStep);
      }
    });

    return (
      <EuiFlexGroup direction="row" gutterSize="s">
        <EuiFlexItem>
          <EuiButton size="s" onClick={() => changeStep(SIGNUP_STEP.NEW_USER_REGISTRATION)}>
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton color="primary" size="s" onClick={stepSubmitHandler} fill>
            Next
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
};

export default aboutYouStep;
