import React from 'react';
import { EuiFlexItem } from '@elastic/eui';
import { CheckboxField, NumberField, TextField } from '@sharedComponents/formElements';

export const NodeBodyNumber = (
  <>
    <EuiFlexItem>
      <TextField fieldName="field" rowProps={{ label: 'ID:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <NumberField fieldName="width" rowProps={{ label: 'Width:', fullWidth: true, display: 'columnCompressed' }} />
    </EuiFlexItem>
    <EuiFlexItem>
      <CheckboxField
        fieldName="required"
        rowProps={{ label: 'Is required:', fullWidth: true, display: 'columnCompressed' }}
      />
    </EuiFlexItem>
  </>
);
