import React, { ChangeEvent } from 'react';

import { FormSelection } from '@sharedClients/types/ScholarshipModel';
import Select from './Select';
import { FormsModelShape } from '@sharedComponents/models';

export default function FormSelect({
  id,
  value,
  onChange,
  type,
  forms,
  canAdd,
  className
}: {
  id: string;
  value: FormSelection | undefined | null;
  onChange: (form: FormSelection | undefined) => void;
  type: string;
  forms: FormsModelShape[];
  className?: string;
  canAdd: boolean;
}) {
  forms = forms.filter(form => form.user_type === type);

  return (
    <Select
      id={id}
      value={value && value.id}
      className={className}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        onChange(forms.find(form => form.id === parseInt(e.target.value)));
      }}
    >
      {canAdd ? (
        <option key="none" value="">
          Create new form (advanced)
        </option>
      ) : null}
      {forms.map(form => (
        <option key={form.id} value={form.id}>
          {form.name}
        </option>
      ))}
    </Select>
  );
}
