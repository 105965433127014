import React from 'react';
import * as yup from 'yup';

import { EuiFlexItem } from '@elastic/eui';
import { TextField, PhoneField, CheckboxField } from '@sharedComponents/formElements';

function ProfessionalFormFields() {
  return (
    <>
      <EuiFlexItem>
        <TextField
          fieldName={'data.name'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'First name', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.lastName'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Last name', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <PhoneField />
      </EuiFlexItem>
      <EuiFlexItem>
        <TextField
          fieldName={'data.occupation'}
          fieldProps={{ fullWidth: true }}
          rowProps={{ label: 'Occupation *', fullWidth: true }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.smsnofification'}
          fieldProps={{ label: 'Would you like to receive text message notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CheckboxField
          fieldName={'data.emailNotification'}
          fieldProps={{ label: 'Would you like to receive email notifications?', defaultChecked: true }}
          rowProps={{}}
        />
      </EuiFlexItem>
    </>
  );
}

ProfessionalFormFields.validationRules = yup.object().shape({
  data: yup.object().shape({
    name: yup.string().label('First Name').required(),
    lastName: yup.string().label('Last Name').required(),
    phone: yup.string().label('Phone').required(),
    occupation: yup.string().label('Occupation').required(),
    smsnofification: yup.boolean().default(true),
    emailNotification: yup.boolean().default(true)
  })
});

export default ProfessionalFormFields;
