/** Bitwise flag helper */
export function hasFlag(currentValue, flag) {
  return flag === (currentValue & flag);
}

export function hasFlags(currentValue, flags: Array<number>) {
  return flags.every(flag => hasFlag(currentValue, flag));
}

export function removeFlag(currentValue, flag) {
  return (currentValue &= ~flag);
}

export function addFlag(currentValue, flag) {
  return (currentValue |= flag);
}

export function toggleFlag(currentValue, flag) {
  return hasFlag(currentValue, flag) ? removeFlag(currentValue, flag) : addFlag(currentValue, flag);
}

/**
 * Use this very carefully as it may not work for bitwise enums which has no all combined values in enum(like ENUM.ALL/ ENUM.BOTH, etc)
 */
export function isValidFlagValue(FLAG_ENUM, value: number) {
  return !isNaN(+value) && value in FLAG_ENUM;
}
