import React, { ChangeEvent, useState, useEffect } from 'react';
import ValidatingField from './ValidatingField';
import { validateRequired, validateEmail } from './validations';
import UserClient from '@sharedClients/UserClient';
import './DonorWidget.scss';
import { Autocomplete } from '@donors/base/Autocomplete';
import UserModel from '@sharedContract/UserModel';

export interface SelectedDonor {
  isExisting: boolean;
  id?: number;
  name?: string;
  email?: string;
}

export default function DonorWidget(props: {
  initialValue?: SelectedDonor;
  onChange: (donor: SelectedDonor) => void;
  onError: (e: Error) => void;
  onSubmittableChange: (field: string, isSubmittable: boolean) => void;
  hasSubmitted: boolean;
  userClient: UserClient;
  user: UserModel;
  type: string;
}) {
  const initialValue = props.initialValue || { isExisting: false };

  const [isExisting, setExisting] = useState<boolean>(initialValue.isExisting);
  const [selectedDonor, setSelectedDonor] = useState<SelectedDonor>(initialValue);
  const [noSuchDonor, setNoSuchDonor] = useState<boolean>(false);

  function onTypeChange(e: ChangeEvent<HTMLInputElement>) {
    const isExisting = e.target.value === 'true';

    setExisting(isExisting);

    const newDonor: SelectedDonor = { ...selectedDonor, isExisting };

    setSelectedDonor(newDonor);

    props.onSubmittableChange(isExisting ? 'donorName' : 'donorId', true);
  }

  function loadDonorName(donor?: SelectedDonor) {
    donor = donor || selectedDonor;

    if (donor.id != null && !isNaN(donor.id) && donor.name == null) {
      props.userClient
        .getDonor(donor.id)
        .then(donor => {
          const select = {
            isExisting: true,
            id: donor.id,
            name: donor.name
          };

          setSelectedDonor(select);

          props.onChange(select);

          props.onSubmittableChange('donorId', true);
          setNoSuchDonor(false);
        })
        .catch(e => {
          if (e.result === 'not-found') {
            props.onSubmittableChange('donorId', false);

            setNoSuchDonor(true);
          } else {
            props.onError(e);
          }
        });
    }
  }
  useEffect(loadDonorName, [isExisting]);
  return (
    <div className="DonorWidget">
      <div className="radio-label">
        <input type="radio" id="newDonor" value="false" checked={!isExisting} onChange={onTypeChange} />

        <div className="label">
          <label className="radio" htmlFor="newDonor">
            Create a new donor
          </label>
        </div>
      </div>

      <div className="radio-label">
        <input type="radio" id="existingDonor" value="true" checked={isExisting} onChange={onTypeChange} />
        <div className="label">
          <label className="radio" htmlFor="existingDonor">
            Use an existing donor
          </label>
        </div>
      </div>
      {isExisting ? (
        <div>
          <div className="field">
            <label>Name</label>
            {noSuchDonor ? (
              <div className="value error">No such donor</div>
            ) : (
              <div className="flex">
                <div className="donor-autocomplete">
                  <Autocomplete
                    id="donorname"
                    value={selectedDonor || {}}
                    autocomplete="donors"
                    onChange={option => {
                      if (option && option.id != null) {
                        const { id, name, email } = option;
                        const model: SelectedDonor = { id, name: name, isExisting: true, email };
                        setSelectedDonor(model);
                        props.onChange(model);
                      }
                      // setValue([...(value ? value.filter(i=> i.id) : []), model]);
                    }}
                    userClient={props.userClient}
                  />
                </div>

                <div className="fieldContainer">
                  <input id="donorId" value={selectedDonor.id != null ? selectedDonor.id : ''} type="number" disabled />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="fieldContainer">
          <ValidatingField
            label="Donor name"
            id="donorName"
            value={selectedDonor.name || ''}
            setValue={value => {
              const newDonor = {
                isExisting: false,
                name: value,
                email: selectedDonor.isExisting ? undefined : selectedDonor.email
              };
              setSelectedDonor(newDonor);
              props.onChange(newDonor);
            }}
            validate={validateRequired}
            renderChildren={props => <input {...props} type="text" />}
            onSubmittableChange={props.onSubmittableChange}
            hasSubmitted={props.hasSubmitted}
          />

          <ValidatingField
            label="Donor email"
            id="donorEmail"
            value={selectedDonor.email || ''}
            setValue={value => {
              const newDonor = {
                isExisting: false,
                email: value,
                name: selectedDonor.isExisting ? undefined : selectedDonor.name
              };
              setSelectedDonor(newDonor);
              props.onChange(newDonor);
            }}
            validate={str => (str ? validateEmail(str) : null)}
            renderChildren={props => <input {...props} type="text" />}
            onSubmittableChange={props.onSubmittableChange}
            hasSubmitted={props.hasSubmitted}
          />
        </div>
      )}
    </div>
  );
}
