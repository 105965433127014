import { cloneDeep, isArray, set, forEach, isObject } from 'lodash';
import { DefaultValue, GetRecoilValue, selector, SetRecoilState } from 'recoil';
import { FormStateInternals, hasDescendants } from '@sharedComponents/interfaces/Forms.interface';
import { ApplicationPageType } from '@sharedComponents/schemas/FormNodesSchema';
import { FormbuilderState, formStateAtom } from '../atoms/formState';
import { FormsModelShape } from '@sharedComponents/models';

/**
 * This being executed over all form nodes in order to generate or refresh internalPath property.
 * as pages we may expect form model data(since its an array of pages by design) or already processed pages(we refresh paths since it might be changed with any action)
 * This func is executed oftenly, so avoid adding resource-hungry operations, its already N0 complexity iterating there
 */
function transformPagesForInternalUsage(pages) {
  const _pages = cloneDeep(pages);

  const processNode = (node, accumulatedPath: string) => {
    const isStringNode = typeof node === 'string'; // is form node declared as just a string[shortcut]
    if (isArray(node)) {
      let _index = 0;
      forEach(node, function (_node) {
        processNode(_node, accumulatedPath + '.[' + _index++ + ']');
      });
    } else if (isObject(node)) {
      set(_pages, accumulatedPath, { ...node, internalPath: accumulatedPath });
    } else if (isStringNode || typeof node.field === 'string') {
      if (isStringNode) {
        set(_pages, accumulatedPath, { field: node, internalPath: accumulatedPath });
      } else {
        set(_pages, accumulatedPath, { ...node, internalPath: accumulatedPath });
      }
    }

    if (hasDescendants(node)) {
      if (node.children?.length) {
        let _index = 0;
        forEach(node.children, function (_node) {
          processNode(_node, accumulatedPath + '.children.[' + _index++ + ']');
        });
      } else if (node.columns?.length) {
        let _index = 0;
        forEach(node.columns, function (column) {
          processNode(column, accumulatedPath + '.columns.[' + _index++ + ']');
        });
      }
    }
  };

  let _pageIndex = 0;
  forEach(_pages, function (page) {
    processNode(page, '[' + _pageIndex++ + ']');
  });

  return _pages;
}

/**
 * Instead of working with atom itself, we handle parts of form state via selector,
 * which gives extra functionality and ways to transform data between layers
 * used for getting and modifing/removing some form parts based on page
 */
const formStatePagesSelector = selector<FormbuilderState['pages']>({
  key: 'formStatePagesSelector',
  get: ({ get }) => {
    const { pages } = get(formStateAtom);
    return pages;
  },
  set: (
    { set: setRecoilState, get: getRecoilValue }: { get: GetRecoilValue; set: SetRecoilState },
    newPages: (ApplicationPageType & FormStateInternals)[] | FormsModelShape['data'] | DefaultValue
  ) => {
    const _formState = cloneDeep(getRecoilValue(formStateAtom));
    set(_formState, 'pages', transformPagesForInternalUsage(newPages));
    setRecoilState(formStateAtom, _formState);
  }
});

export { formStatePagesSelector, transformPagesForInternalUsage };
