import React, { useEffect, useState } from 'react';
import html from 'rehype-stringify';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import unified from 'unified';

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle
} from '@elastic/eui';
import { ScholarshipModelShape, UserModelShape } from '@sharedComponents/models';

import AbstractDeclineStep from './NotifyNonAwardedModal/AbstractDeclineStep';
import { NOTIFY_DECLINED_STEPS } from './NotifyNonAwardedModal/config';
import NotifyDeclinedStepConfirmation from './NotifyNonAwardedModal/NotifyDeclinedStepConfirmation';
import NotifyDeclinedStepConfirmRecipients from './NotifyNonAwardedModal/NotifyDeclinedStepConfirmRecipients';
import NotifyDeclinedStepEmailDrafting from './NotifyNonAwardedModal/NotifyDeclinedStepEmailDrafting';
import NotifyDeclinedStepEmailPreview from './NotifyNonAwardedModal/NotifyDeclinedStepEmailPreview';
import NotifyDeclinedStepNotifyCompleted from './NotifyNonAwardedModal/NotifyDeclinedStepNotifyCompleted';
import { ApplicationClient } from '@sharedClients/ApplicationClient';

const NotifyNonAwardedModal = ({
  applicationClient,
  scholarship,
  applicationCycle,
  openLatestReviewRound,
  onClose
}: {
  applicationClient: ApplicationClient;
  scholarship?: ScholarshipModelShape;
  applicationCycle: number;
  openLatestReviewRound: () => void;
  onClose: () => void;
}) => {
  if (!scholarship) {
    return null;
  }

  const [affectedStudentsCount, setAffectedStudentsCount] = useState(0);
  const [affectedStudentExample, setAffectedStudentExample] = useState<UserModelShape | null>(null);

  // markdown representation of draft email
  const [emailDraft, setEmailDraft] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  // markdown to html converted variant of emailDraft
  const [compiledEmailContent, setCompiledEmailContent] = useState('');

  useEffect(() => {
    applicationClient.getNonAwardedStudentsPreview(scholarship.id).then(studentsPreview => {
      setAffectedStudentsCount(studentsPreview.count);
      setAffectedStudentExample(studentsPreview.recordExample);
    });
  }, [scholarship.id, applicationCycle]);

  const [currentStep, setCurrentStep] = useState<NOTIFY_DECLINED_STEPS>(NOTIFY_DECLINED_STEPS.NOTIFY_CONFIRMATION);

  const currentStepObject: AbstractDeclineStep = {
    [NOTIFY_DECLINED_STEPS.NOTIFY_CONFIRMATION]: NotifyDeclinedStepConfirmation,
    [NOTIFY_DECLINED_STEPS.CONFIRM_RECIPIENTS]: NotifyDeclinedStepConfirmRecipients,
    [NOTIFY_DECLINED_STEPS.EMAIL_DRAFTING]: NotifyDeclinedStepEmailDrafting,
    [NOTIFY_DECLINED_STEPS.EMAIL_PREVIEW]: NotifyDeclinedStepEmailPreview,
    [NOTIFY_DECLINED_STEPS.NOTIFY_COMPLETED]: NotifyDeclinedStepNotifyCompleted
  }[currentStep];

  const changeStep = async (step: NOTIFY_DECLINED_STEPS | null, navigateToLastReviewRound = false) => {
    if (step === null) {
      onClose();

      if (navigateToLastReviewRound) {
        openLatestReviewRound();
      }

      return;
    }

    if (step === NOTIFY_DECLINED_STEPS.EMAIL_PREVIEW) {
      const stringified = await unified().use(markdown).use(remark2rehype).use(html as any).process(emailDraft);
      setCompiledEmailContent(stringified.contents as string);
    } else if (step === NOTIFY_DECLINED_STEPS.NOTIFY_COMPLETED) {
      await applicationClient.notifyNonAwardedStudents(scholarship.id, emailSubject, compiledEmailContent);
    }

    setCurrentStep(step);
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{currentStepObject.headerTitle}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiFlexGroup gutterSize="none">
          <EuiFlexItem>
            {currentStepObject.render({
              affectedStudentsCount,
              affectedStudentExample,
              emailDraft,
              setEmailDraft,
              emailSubject,
              setEmailSubject,
              compiledEmailContent
            })}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiFlexGroup alignItems="center">
          {currentStepObject.actions(changeStep, affectedStudentsCount).map(action => action)}
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default NotifyNonAwardedModal;
