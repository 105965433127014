import React from 'react';
import './PageNavigationDesktop.scss';
import NumberBubble from './NumberBubble';
import Warning from './Warning';

export default function PageNavigation(props) {
  const { pages, onPageClick, currentPage } = props;

  const showWarningForPage = props.showWarningForPage || (() => false);

  return (
    <div className="PageNavigationDesktop">
      {
        // if there are no pages in the form, the submission form will insert one,
        pages.length > 0
          ? pages.map((page, i) => {
              const isWarning = showWarningForPage(page);

              return (
                <div
                  key={i}
                  onClick={() => onPageClick(page)}
                  className={
                    'page' + (currentPage && page.id === currentPage.id ? ' active' : '') + (isWarning ? ' warn' : '')
                  }
                >
                  {isWarning ? <Warning /> : <NumberBubble number={i + 1} />}
                  <div className="name">{page.getTitle()}</div>
                </div>
              );
            })
          : null
      }
    </div>
  );
}
