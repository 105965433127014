import React, { ChangeEvent } from 'react';
import './AwardAmount.scss';

export type Amount = number | null;

/**
 * value is an array of one (value) or two (range) elements.
 * if the value is unknown it's an array of length 1 with value null.
 */
export default function AwardAmount({
  id,
  value,
  onChange
}: {
  id: string;
  value: Amount[];
  onChange?: (e: any) => void;
}) {
  const isRange = value.length === 2;

  function setValue(value: Amount[]) {
    if (onChange) {
      onChange({ target: { value } });
    }
  }

  function onElementChange(index: number) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = value.slice(0);
      const number = isNaN(parseInt(e.target.value)) ? null : parseInt(e.target.value);

      newValue[index] = number as number;

      let last = newValue.length - 1;

      if (newValue[last] === 0) {
        newValue[last] = null;
      }

      setValue(newValue);
    };
  }

  value = value.map(num => (num != null ? num : null));

  return (
    <div className="AwardAmount">
      {isRange ? (
        <div className="values">
          <div className="text">Between</div>
          <input id={id} type="number" value={toValue(value[0])} onChange={onElementChange(0)} />
          <div className="text">and</div>
          <input id={id + 'To'} type="number" value={toValue(value[1])} onChange={onElementChange(1)} />
          <div className="text">USD</div>
        </div>
      ) : (
        <div className="values">
          <input id={id} type="number" value={toValue(value[0])} onChange={onElementChange(0)} />
          <div className="text">USD</div>
        </div>
      )}
      <div className="checkbox">
        <input
          id={id + 'Range'}
          type="checkbox"
          defaultChecked={isRange}
          onChange={e => {
            if (e.target.checked) {
              setValue(value.concat([value[0]]));
            } else {
              setValue([value[0]]);
            }
          }}
        />
        <label htmlFor={id + 'Range'}>Use range</label>
      </div>
    </div>
  );
}

function toValue(amount: Amount): number | string {
  return amount == null ? '' : amount;
}
