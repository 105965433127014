import './User.scss';

import { History } from 'history';
import React, { useEffect, useState } from 'react';

import NextPrevNavigation from '@donors/base/components/NextPrevNavigation';
import preventDefault from '@donors/base/preventDefault';
import stateByAbbreviation from '@donors/base/stateByAbbreviation';
import { useMemoryCachedState } from '@donors/base/useCachedState';
import { useErrorReporter } from '@donors/base/useErrorReporter';
import { getUserHomeUrl } from '@sharedClients/main';
import ApplicationModel from '@sharedClients/types/ApplicationModel';
import { applicationListFields } from '@sharedComponents/ApplicationList/ApplicationList';
import track from '@sharedComponents/base/track';
import { USER_TYPE } from '@sharedComponents/constants';
import { useAllClients } from '@sharedComponents/selectors/useAllClients';
import UserModel, { CoreUserFields, CounselorUserData, DonorUserData } from '@sharedContract/UserModel';

import ButtonBar from '../components/ButtonBar';
import { SelectedDonor } from '../components/DonorWidget';
import { HeaderProps } from '../components/Header';
import { createDonorIfMissing } from './AddUser';
import EditCounselorUser from './EditCounselorUser';
import EditDonorUser from './EditDonorUser';
import { userListIds } from './UserList';
import ViewUser from './ViewUser';
import { useModalCreate } from '@sharedComponents/contexts/modalContext';
import useToast from '@sharedComponents/hooks/useToast';
import { htmlIdGenerator } from '@elastic/eui';
import { TranscriptSubmitModal } from './User/TranscriptSubmitModal';

export default function User(
  props: {
    userId: number;
    jwt: string;
    history: History;
  } & HeaderProps
) {
  let { userId, history } = props;
  const { userClient, applicationClient } = useAllClients();

  const currentUser: UserModel = props.user;
  const [onError, errorMessage] = useErrorReporter();
  const [user, setUser] = useMemoryCachedState<UserModel | null>('user.' + userId, null);
  const [applications, setApplications] = useState<ApplicationModel[] | null>(null);
  const [isEdit, setEdit] = useState(false);

  const [deleteState, setDeleteState] = useState<'not' | 'confirm' | 'gone'>('not');

  const [prevNonVerifiedCounselorId, setPrevNonVerifiedCounselorId] = useState<number | null>(null);

  const [nextNonVerifiedCounselorId, setNextNonVerifiedCounselorId] = useState<number | null>(null);

  const createModal = useModalCreate();
  const { addToast } = useToast();

  const currentIndex = userListIds.indexOf(userId);

  useEffect(() => {
    userClient
      .getUser(userId)
      .then(user => {
        setUser(user);

        if (user.type === USER_TYPE.APPLICANT) {
          applicationClient.getApplicationsOfUser(userId, applicationListFields).then(setApplications).catch(onError);
        }

        if (currentUser.type === USER_TYPE.ADMIN && user.type === USER_TYPE.COUNSELOR) {
          userClient.getSiblingNonVerifiedCounselors(userId).then(siblingCounselors => {
            setNextNonVerifiedCounselorId(siblingCounselors.next);
            setPrevNonVerifiedCounselorId(siblingCounselors.prev);
          });
        }

        document.title = `${user.data.name || user.name} – User at Scholar's App`;
      })
      .catch(e => {
        if (e['result'] === 'not-found') {
          e['isUserError'] = true;
        }

        onError(e);
        setUser(null);
      });
  }, [userId]);

  const buttons: JSX.Element[] = [];
  const isAdmin = currentUser.type === 'admin';

  if (
    (user && user.type === USER_TYPE.COUNSELOR && !(user.data as CounselorUserData).isVerified && isAdmin) ||
    (user && user.type === USER_TYPE.DONOR && (user.data as DonorUserData).isVerified === false && isAdmin)
  ) {
    buttons.push(
      <button
        className="button"
        key="verify"
        onClick={e => {
          applicationClient
            .verifyUser(user.id)
            .then(user => setUser(user))
            .catch(onError);

          e.preventDefault();
        }}
      >
        Mark verified
      </button>
    );

    buttons.push(
      <button
        className="button"
        key="google"
        onClick={e => {
          const query = `${user.name} ${user.data.name} ${(user.data as CounselorUserData).school!.name} ${
            stateByAbbreviation[(user.data as CounselorUserData).state as string]
          }`;

          window.open(`https://www.google.com/search?q=${encodeURIComponent(query)}`, '_counselor' + user.id);

          e.preventDefault();
        }}
      >
        Google
      </button>
    );
  }

  if (user && user.type === USER_TYPE.APPLICANT && isAdmin) {
    async function openTranscript() {
      createModal(({ closeModal }) => {
        const onModalSubmitHandler = async (isSave: boolean) => {
          closeModal();

          if (isSave) {
            addToast({
              id: htmlIdGenerator()(),
              color: 'success',
              title: `Transcript submitted!`
            });
          }
        };

        return <TranscriptSubmitModal onModalSubmit={onModalSubmitHandler} userID={userId} />;
      });
    }

    buttons.push(
      <button
        className="button"
        key="transcript"
        onClick={e => {
          openTranscript();

          e.preventDefault();
        }}
      >
        Transcript
      </button>
    );
  }

  if (user && ((isAdmin && user.type !== 'admin') || (currentUser.type === 'donor' && user.type === 'reviewer'))) {
    async function deleteUser() {
      if (deleteState === 'not') {
        setDeleteState('confirm');
      } else if (deleteState === 'confirm') {
        userClient
          .deleteUser(userId)
          .then(() => setDeleteState('gone'))
          .catch(onError);
      }
    }

    buttons.push(
      <button
        className="button"
        key="delete"
        onClick={e => {
          deleteUser();

          e.preventDefault();
        }}
      >
        {deleteState === 'not'
          ? 'Delete'
          : deleteState === 'confirm'
          ? "Really delete all this user's data?"
          : 'Deleted'}
      </button>
    );
  }

  const editableUserTypes = isAdmin ? ['donor', 'counselor', 'reviewer'] : ['reviewer'];

  const canEdit = user && editableUserTypes.includes(user.type);

  if (!isEdit && canEdit) {
    buttons.push(
      <button className="button" key="edit" onClick={() => setEdit(true)}>
        Edit
      </button>
    );
  }

  if (user && user.type !== 'admin' && isAdmin) {
    buttons.push(
      <button
        className="button"
        key="logInAs"
        onClick={e => {
          userClient
            .login(user.name, null)
            .then(({ jwt }) => {
              const url = `${getUserHomeUrl(user.type)}#jwt-` + jwt;

              if (url.startsWith(window.location.origin)) {
                document.location.href = url;
              } else {
                window.open(url, '_blank', 'noreferrer');
              }
            })
            .catch(onError);

          e.preventDefault();
        }}
      >
        Log in as
      </button>
    );

    buttons.push(
      <button
        className="button"
        key="resetPwd"
        onClick={e => {
          userClient
            .createResetPasswordUrl({ email: user.name })
            .then(({ url }) => alert(`Use the following URL to reset the password: ${url}`))
            .catch(onError);

          e.preventDefault();
        }}
      >
        Reset password
      </button>
    );
  }

  if (user && user.type === 'donor' && canEdit) {
    buttons.push(
      <button
        className="button"
        key="sendSignup"
        onClick={e => {
          userClient
            .sendSignupUrl(user.id)
            .then(() => alert(`Signup email with password set link sent.`))
            .catch(onError);

          e.preventDefault();
        }}
      >
        Send signup link
      </button>
    );
  }

  if (user && isAdmin && ['recommender', 'applicant'].includes(user.type)) {
    async function makeCounselor() {
      userClient
        .makeCounselor(userId)
        .then(() => setUser({ ...(user as UserModel), type: 'counselor' }))
        .catch(onError);
    }

    buttons.push(
      <button className="button" key="mc" onClick={preventDefault(makeCounselor)}>
        Make counselor
      </button>
    );
  }

  return (
    <div className="User">
      <div className="body">
        <div className="content">
          {errorMessage}

          {isEdit ? (
            ['donor', 'reviewer'].includes(user!.type) ? (
              <EditDonorUser
                user={user as UserModel}
                type={(user as UserModel).type}
                onSave={async (editedUser: CoreUserFields) => {
                  const createUserModel = await createDonorIfMissing({ ...editedUser, id: userId }, userClient);

                  await userClient.updateUser(createUserModel).catch(onError);

                  track('users-updated');

                  setUser({ ...(user as UserModel), ...editedUser });
                  setEdit(false);
                }}
                currentUser={currentUser}
                onCancel={() => setEdit(false)}
                onError={onError}
                userClient={userClient}
              />
            ) : (
              <EditCounselorUser
                user={user as UserModel}
                userClient={userClient}
                currentUser={currentUser}
                onSave={async (editedUserData: CounselorUserData, donor: SelectedDonor) => {
                  const user = await userClient.updateUser({ data: editedUserData, id: userId, donor }).catch(onError);

                  user.donor = donor;

                  setUser(user);

                  track('users-updated');

                  setUser({ ...(user as UserModel), data: editedUserData });
                  setEdit(false);
                }}
                onCancel={() => setEdit(false)}
                onError={onError}
                applicationClient={applicationClient}
              />
            )
          ) : (
            <React.Fragment>
              <NextPrevNavigation
                prev={
                  user?.type === 'counselor'
                    ? prevNonVerifiedCounselorId
                      ? '/users/' + prevNonVerifiedCounselorId
                      : undefined
                    : currentIndex > 0
                    ? '/users/' + userListIds[currentIndex - 1]
                    : undefined
                }
                next={
                  user?.type === 'counselor'
                    ? nextNonVerifiedCounselorId
                      ? '/users/' + nextNonVerifiedCounselorId
                      : undefined
                    : currentIndex < userListIds.length - 1
                    ? '/users/' + userListIds[currentIndex + 1]
                    : undefined
                }
                history={history}
              >
                <div className="pageTitle">
                  <h4>{capitalize((user && user.type) || 'user')}</h4>
                  <h2>
                    {(user &&
                      (`${user.data.name}${user.data.lastName ? ` ${user.data.lastName}` : ''}` || user.name)) ||
                      'User'}
                  </h2>
                </div>
              </NextPrevNavigation>

              {buttons.length ? <ButtonBar className="links">{buttons}</ButtonBar> : null}

              <ViewUser
                user={user as UserModel}
                applications={applications}
                onError={onError}
                currentUser={currentUser}
                history={history}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

function capitalize(str: string) {
  if (str) {
    return str[0].toUpperCase() + str.substr(1);
  } else {
    return '';
  }
}
