import { cloneDeep, set } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { QueryClient, useQuery, UseQueryOptions } from 'react-query';

import useFetcher from '@sharedComponents/hooks/useFetcher';
import { Page } from '@sharedComponents/interfaces/CommonData.interface';
import { ScholarshipModelShape } from '@sharedComponents/models';
import {
  ScholarshipListingFilterSchema,
  ScholarshipListingFilterType
} from '@sharedComponents/schemas/ScholarshipListingFilter.schema';

// unique key for this query
const CURRENT_QUERY_KEY = 'recommended_scholarships';
const ENDPOINT = '/v3/scholarships/recommended';

// Represents suggested scholarships for applicant/professional
function useRecommended(
  currentPage,
  scholarshipsFilter: ScholarshipListingFilterType = ScholarshipListingFilterSchema.getDefault(),
  options: UseQueryOptions<Page<ScholarshipModelShape>, Error, Page<ScholarshipModelShape>> = {}
) {
  const fetcher = useFetcher();
  const recommendedScholarships = useQuery<Page<ScholarshipModelShape>, Error>(
    [CURRENT_QUERY_KEY, scholarshipsFilter, currentPage],
    () => {
      const filter = cloneDeep(scholarshipsFilter);
      // ! qs having troubles operating with moment dates, so we have to transform those manually
      // todo better way
      if (filter.startDateFrom) {
        set(filter, 'startDateFrom', moment(filter.startDateFrom).toString());
      }

      if (filter.startDateTo) {
        set(filter, 'startDateTo', moment(filter.startDateTo).toString());
      }

      if (filter.deadlineDateFrom) {
        set(filter, 'deadlineDateFrom', moment(filter.deadlineDateFrom).toString());
      }
      if (filter.deadlineDateTo) {
        set(filter, 'deadlineDateTo', moment(filter.deadlineDateTo).toString());
      }

      return fetcher()
        .url(ENDPOINT)
        .query(
          qs.stringify({
            page: currentPage,
            filter: filter
          })
        )
        .get();
    },
    {
      staleTime: 1000 * 60, // This timeout could be increased once all dependencies will be within of react-query
      ...options
    }
  );

  return {
    ...recommendedScholarships
  };
}

/**
 * When some other query supposed to affect recommended scholarships data, we may optimisticly predict data changes in this hook
 * Like any optimistic function it returns rollback/reverse function
 */
function onOptimisticSubstracting(
  queryClient: QueryClient,
  scholarship: ScholarshipModelShape,
  recommendationsPage: number,
  substractRecommendationsPageCallback // dirty hack to go back in pagesWhen theres no results on this page
) {
  const recommendedScholarshipsCacheKey = [CURRENT_QUERY_KEY, recommendationsPage];

  const previousRecommendedState = queryClient.getQueryData<Page<ScholarshipModelShape>>(
    recommendedScholarshipsCacheKey
  );

  // Make sure that recommended query is actually affected
  if (previousRecommendedState && previousRecommendedState.results?.find(s => s.id === scholarship.id)) {
    const updatedResults = previousRecommendedState.results.filter(s => s.id !== scholarship.id);

    if (updatedResults.length === 0 && recommendationsPage > 0) {
      substractRecommendationsPageCallback();
    }

    queryClient.setQueryData<Page<ScholarshipModelShape>>(recommendedScholarshipsCacheKey, {
      total: previousRecommendedState.total - 1,
      results: updatedResults
    });
  }

  return () => {
    if (previousRecommendedState) {
      queryClient.setQueryData<Page<ScholarshipModelShape>>(recommendedScholarshipsCacheKey, previousRecommendedState);
    }
  };
}

/**
 * When some other query supposed to affect recommended scholarships data, we may optimisticly predict data changes in this hook
 * Like any optimistic function it returns rollback/reverse function
 */
function onOptimisticAppending(
  queryClient: QueryClient,
  scholarship: ScholarshipModelShape,
  recommendationsPage: number
) {
  const recommendedScholarshipsCacheKey = [CURRENT_QUERY_KEY, recommendationsPage];
  const previousRecommendedState = queryClient.getQueryData<Page<ScholarshipModelShape>>(
    recommendedScholarshipsCacheKey
  );

  if (previousRecommendedState && previousRecommendedState?.total >= 0) {
    queryClient.setQueryData<Page<ScholarshipModelShape>>(recommendedScholarshipsCacheKey, {
      total: previousRecommendedState.total + 1,
      results: [...previousRecommendedState.results, scholarship]
    });
  }

  return () => {
    if (previousRecommendedState) {
      queryClient.setQueryData<Page<ScholarshipModelShape>>(recommendedScholarshipsCacheKey, previousRecommendedState);
    }
  };
}

// exporting data type returned by that query
const recommendedScholarshipsQuery = {
  QUERY_KEY: CURRENT_QUERY_KEY,
  useRecommended,
  onOptimisticAppending,
  onOptimisticSubstracting
};

export default recommendedScholarshipsQuery;
