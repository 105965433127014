import React from 'react';
import NodeProps from './FormNodeProps';

export default function FormFieldValueUrl(props: NodeProps & { value: any }) {
  const { value } = props;

  if (!value) {
    return '—';
  } else {
    return (
      <a target="_blank" rel="noopener noreferrer" href={value}>
        {value}
      </a>
    );
  }
}
