import { RawDraftContentState } from 'react-draft-wysiwyg';

import ApplicationModel from '@sharedClients/types/ApplicationModel';
import RichTextEditor from '@sharedComponents/common/RichTextEditor/RichTextEditor';

import FormNode from '../../model/FormNode';

export default function FormFieldRichText({
  node,
  application,
  onFieldChange
}: {
  node: FormNode;
  application: ApplicationModel;
  onFieldChange: (value: RawDraftContentState, node: FormNode) => any;
}) {
  const value = node.getValue(application);

  return (
    <div className="FormFieldRichText">
      <RichTextEditor value={value} onContentStateChange={v => onFieldChange(v, node)} />
    </div>
  );
}
