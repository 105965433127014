import React, { useEffect, useState } from 'react';
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPagination,
  EuiTextArea,
  htmlIdGenerator
} from '@elastic/eui';
import questionsForScholarshipQuery from './queries/questionsForScholarshipQuery';
import answerQuestionQuery from './queries/answerQuestionQuery';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import useToast from '@sharedComponents/hooks/useToast';
import { DEFAULT_ERROR_TITLE } from '@sharedComponents/constants';

export default function QuestionsBlock({
  selectedScholarshipId,
  areScholarshipsLoading
}: {
  selectedScholarshipId: number | null;
  areScholarshipsLoading: boolean;
}) {
  const queryClient = useQueryClient();
  const {
    data,
    isLoading: isDataLoading,
    isError,
    error
  } = questionsForScholarshipQuery.useQuestionsForScholarship(selectedScholarshipId);
  const { mutate: mutateAnswerQuestion, isLoading: isAnswerLoading } = answerQuestionQuery.useMutateAnswerQuestion();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const { addToast } = useToast();

  const question = Array.isArray(data) ? data[selectedQuestionIndex] : null;

  let questionCreatedDaysAgo;
  if (question) {
    questionCreatedDaysAgo = moment().diff(moment(question.createdAt), 'days');
  }

  const selectQuestion = index => {
    if (data && data[index]) {
      setSelectedQuestionIndex(index);
    }
  };

  const sendAnswer = async () => {
    try {
      if (!question) {
        return;
      }

      mutateAnswerQuestion(
        { answer, questionId: question.id },
        {
          onSuccess: () => {
            queryClient.setQueryData(
              [questionsForScholarshipQuery.QUERY_KEY, selectedScholarshipId],
              data!.filter(q => q.id !== question.id)
            );
            queryClient.invalidateQueries(questionsForScholarshipQuery.QUERY_KEY);
            setAnswer('');
            addToast({
              id: htmlIdGenerator()(),
              color: 'success',
              title: 'The answer is sent!'
            });
          }
        }
      );
    } catch (e) {}
  };

  useEffect(() => {
    // selecting index of a next question
    if (data && !data[selectedQuestionIndex]) {
      if (data[selectedQuestionIndex - 1]) {
        setSelectedQuestionIndex(selectedQuestionIndex - 1);
      } else {
        setSelectedQuestionIndex(0);
      }
    }
  }, [data]);

  useEffect(() => {
    setSelectedQuestionIndex(0);
    setAnswer('');
  }, [selectedScholarshipId]);

  return (
    <div className={'DonorHomepage__statBlock questionsBlock'}>
      <EuiFlexGroup direction={'column'} responsive={false}>
        <EuiFlexItem className={'DonorHomepage__statBlockHeader'} grow={false}>
          <EuiFlexGroup responsive={false}>
            <EuiFlexItem>Open Questions</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className={'DonorHomepage__statBlockBody'}>
          {question ? (
            <EuiFlexGroup direction={'column'} justifyContent={'spaceBetween'} responsive={false}>
              <EuiFlexItem className={'questionsBlock__question'} grow={false}>
                <EuiFlexGroup direction={'column'} responsive={false}>
                  <EuiFlexItem className={'questionsBlock__author'} grow={false}>
                    {question.applicantName || 'A student'} asked:
                  </EuiFlexItem>
                  <EuiFlexItem className={'questionsBlock__text'} grow={false}>
                    <div>{question.text || ''}</div>
                  </EuiFlexItem>
                  <EuiFlexItem className={'questionsBlock__date'} grow={false}>
                    {questionCreatedDaysAgo
                      ? `${questionCreatedDaysAgo} day${questionCreatedDaysAgo === 1 ? '' : 's'} ago`
                      : 'Today'}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem className={'questionsBlock__answer'} grow={false}>
                <EuiFlexGroup direction={'column'} responsive={false}>
                  <EuiFlexItem className={'questionsBlock__replyText'}>Reply</EuiFlexItem>
                  <EuiFlexItem className={'questionsBlock__textareaWrapper'}>
                    <EuiTextArea
                      aria-label="answer"
                      value={answer}
                      onChange={e => setAnswer(e.target.value)}
                      resize={'none'}
                      disabled={isDataLoading || isAnswerLoading}
                      fullWidth
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFlexGroup responsive={false} alignItems={'center'}>
                      <EuiFlexItem className={'questionsBlock__replyButton'}>
                        <EuiButton
                          size="s"
                          fill
                          onClick={sendAnswer}
                          disabled={!answer.length || isDataLoading}
                          isLoading={isAnswerLoading}
                        >
                          Reply
                        </EuiButton>
                      </EuiFlexItem>
                      {!!data?.length && data.length > 1 && (
                        <EuiFlexItem grow={false}>
                          <EuiPagination
                            aria-label="pagination"
                            pageCount={data.length}
                            activePage={selectedQuestionIndex}
                            onPageClick={selectQuestion}
                            compressed={true}
                          />
                        </EuiFlexItem>
                      )}
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup justifyContent={'center'} alignItems={'center'} responsive={false}>
              {isError ? (
                <EuiFlexItem className={'DonorHomepage__statBlockError'} grow={false}>
                  <EuiCallOut title={DEFAULT_ERROR_TITLE} color="danger" iconType="alert">
                    {error?.message || ''}
                  </EuiCallOut>
                </EuiFlexItem>
              ) : areScholarshipsLoading || isDataLoading ? (
                <EuiFlexItem grow={false}>
                  <EuiLoadingSpinner size={'l'} />
                </EuiFlexItem>
              ) : (
                <EuiFlexItem className={'questionsBlock__noQuestion'} grow={false}>
                  No questions found
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
