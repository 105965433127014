import { useState } from 'react';
import { useRecoilState } from 'recoil';

import { EuiControlBar, htmlIdGenerator } from '@elastic/eui';
import useToast from '@sharedComponents/hooks/useToast';

import FormControlBarContent from './ControlBar/FormControlBarContent';
import useFormState from './hooks/useFormState';
import useFormUndoRedo from './hooks/useFormUndoRedo';
import formQuery from './queries/formQuery';
import formStateIsDirtySelector from './selectors/formStateIsDirtySelector';

function ControlBar({ formName }) {
  const { addToast } = useToast();
  const formState = useFormState();
  const [isDirty, setIsDirty] = useRecoilState(formStateIsDirtySelector);
  const { mutateAsync: upsertForm, isLoading } = formQuery.useUpsertForm();
  const [showControlContent, setShowControlContent] = useState(false);
  const formUndoRedo = useFormUndoRedo();

  const toggleShowContent = () => {
    setShowControlContent(!showControlContent);
  };

  const handleSaveForm = async () => {
    if (formState.formID) {
      await upsertForm({
        id: formState.formID,
        data: formState.pages
      });

      formUndoRedo.flush();
      setIsDirty(false);

      addToast({
        id: htmlIdGenerator()(),
        color: 'success',
        title: 'Form saved!'
      });
    }
  };

  const controls = [
    { id: 'form_name', controlType: 'text', text: formName },
    {
      controlType: 'spacer'
    },
    {
      controlType: 'button',
      id: 'raw_form',
      label: `${showControlContent ? 'Hide' : 'Show'} control bar`,
      color: 'ghost',
      onClick: toggleShowContent
    },
    {
      controlType: 'button',
      id: 'undo',
      label: 'Undo',
      color: 'warning',
      fill: false,
      disabled: !formUndoRedo.isAvailable,
      onClick: formUndoRedo.undo
    },
    // {
    //   controlType: 'button',
    //   id: 'redo',
    //   label: 'Redo',
    //   color: 'success',
    //   fill: false,
    //   disabled: true,
    //   onClick: () => {}
    // },
    // {
    //   controlType: 'icon',
    //   id: 'status_icon',
    //   iconType: 'alert',
    //   color: 'warning',
    //   'aria-label': 'Repo Status'
    // },
    {
      controlType: 'button',
      id: 'save_form',
      label: 'Save Form',
      color: 'primary',
      fill: true,
      disabled: !isDirty,
      isLoading: isLoading,
      onClick: handleSaveForm
    }
  ] as any[];

  return (
    <EuiControlBar showContent={showControlContent} controls={controls}>
      <FormControlBarContent />
    </EuiControlBar>
  );
}

export default ControlBar;
