import React from 'react';

import { EuiSpacer } from '@elastic/eui';
import { ComboboxField } from '@sharedComponents/formElements';
import counties from '@sharedComponents/models/enumValues/counties';
import states from '@sharedComponents/models/enumValues/states';

import StudyLookupWidget from './StudyLookupWidget';
import { useFormContext } from 'react-hook-form';

const schoolSelectionOptions = ['Any', 'Specific', 'State', 'County'];
const statesOptions = Object.keys(states).map(key => ({
  label: states[key],
  value: key
}));

const SchoolEligibilitySelection = ({ rowProps = {}, fieldProps = {} }: { rowProps?; fieldProps? }) => {
  const { watch, setValue } = useFormContext();

  // scholarship entry data field
  const { data } = watch(['data']);

  const { schoolsMatching = 'Any', schools = [] } = data || {};

  const onSpecificChangeCallback = entries => {
    if (entries.length) {
      const newSchools = entries.filter(entry => !!entry).map(entry => ({ id: entry.id, name: entry.name }));

      // we update whole data property, in order to have 'isDirty' status properly sync
      setValue('data', { ...data, schools: newSchools }, { shouldDirty: true });
    }
  };

  return (
    <>
      <ComboboxField
        fieldName={'data.schoolsMatching'}
        options={schoolSelectionOptions}
        fieldProps={{
          fullWidth: true,
          defaultValue: 'Any',
          isClearable: false,
          singleSelection: true,
          ...fieldProps
        }}
        rowProps={{
          label: 'High School(s) Matching',
          ...rowProps
        }}
      />
      <EuiSpacer size="s" />
      {schoolsMatching === 'Specific' ? (
        <StudyLookupWidget
          fieldName="data.schools"
          dataType={'schools'}
          placeholder="School Name"
          initialValues={schools}
          onChange={onSpecificChangeCallback}
          isDisabled={fieldProps?.isDisabled || false}
        />
      ) : null}
      {schoolsMatching === 'State' ? (
        <ComboboxField
          fieldName={'data.schoolStates'}
          options={statesOptions}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more', ...fieldProps }}
        />
      ) : null}
      {schoolsMatching === 'County' ? (
        <ComboboxField
          fieldName={'data.schoolCounties'}
          options={counties}
          fieldProps={{ fullWidth: true, placeholder: 'Select one or more', ...fieldProps }}
        />
      ) : null}
    </>
  );
};

SchoolEligibilitySelection.schoolSelectionOptions = schoolSelectionOptions;

export default SchoolEligibilitySelection;
